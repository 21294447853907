//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const UserGroupOutlined = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="UserGroupOutlined"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.00004 7.33333C7.4728 7.33333 8.66671 6.13943 8.66671 4.66667C8.66671 3.19391 7.4728 2 6.00004 2C4.52728 2 3.33337 3.19391 3.33337 4.66667C3.33337 6.13943 4.52728 7.33333 6.00004 7.33333Z"
            stroke="currentColor"
            strokeWidth={1.33333}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.7811C3.28115 10.2809 3.95943 10 4.66667 10H7.33333C8.04057 10 8.71885 10.2809 9.21895 10.7811C9.71905 11.2811 10 11.9594 10 12.6667V14"
            stroke="currentColor"
            strokeWidth={1.33333}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.6666 2.08658C11.2402 2.23345 11.7487 2.56704 12.1117 3.03479C12.4748 3.50252 12.6719 4.0778 12.6719 4.66991C12.6719 5.26203 12.4748 5.8373 12.1117 6.30504C11.7487 6.77278 11.2402 7.10638 10.6666 7.25324"
            stroke="currentColor"
            strokeWidth={1.33333}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 14V12.6666C13.9967 12.0781 13.7987 11.5071 13.4368 11.0429C13.0751 10.5786 12.5699 10.2471 12 10.0999"
            stroke="currentColor"
            strokeWidth={1.33333}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
