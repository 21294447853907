//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Link = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Link"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3332)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.71649 1.24977C10.3822 -0.41659 13.0843 -0.41659 14.7507 1.24977L14.7508 1.2498C16.4165 2.91552 16.4165 5.61699 14.7508 7.28271L12.1106 9.92354C10.4442 11.5899 7.74212 11.5899 6.07576 9.92354C5.77511 9.62165 5.52821 9.28538 5.33571 8.9291L6.03888 8.22531C6.2539 8.01091 6.53517 7.8859 6.83581 7.86903C6.95707 8.15404 7.1327 8.41594 7.35835 8.64033C7.82217 9.1041 8.43721 9.35913 9.09351 9.35913C9.7486 9.35913 10.3649 9.1041 10.8287 8.64033L13.4682 6.00078C13.9314 5.5376 14.1864 4.92131 14.1864 4.26626C14.1864 3.60994 13.9314 2.99489 13.4682 2.53173C13.0044 2.06796 12.3881 1.81293 11.7331 1.81293C11.0773 1.81293 10.461 2.06796 9.99789 2.53173L8.64904 3.88058C8.09393 3.69619 7.50826 3.60056 6.90572 3.60056C6.71446 3.60056 6.52445 3.60931 6.33568 3.62994L8.71649 1.24977ZM7.35895 12.1092C7.92654 12.3018 8.51785 12.3993 9.12163 12.3993L9.12336 12.3999C9.304 12.3999 9.48276 12.3906 9.66028 12.3743L7.28331 14.7513C5.61695 16.4176 2.91612 16.4176 1.24976 14.7513C-0.417238 13.0843 -0.417238 10.3834 1.24976 8.71709L3.88931 6.07626C5.55503 4.4099 8.25714 4.4099 9.9235 6.07626C10.2248 6.37817 10.4723 6.71381 10.6648 7.07134L9.96288 7.77451C9.74848 7.9889 9.46659 8.11516 9.16469 8.13079C9.04281 7.84578 8.86656 7.58389 8.64091 7.35824C8.17709 6.89571 7.56205 6.64068 6.90575 6.64068C6.25005 6.64068 5.635 6.89509 5.17122 7.35824L2.53168 9.99779C2.0679 10.4616 1.81287 11.0779 1.81287 11.733C1.81287 12.3886 2.0679 13.0043 2.53168 13.4675C2.99548 13.9313 3.61114 14.1863 4.2662 14.1863C4.92189 14.1863 5.53757 13.9313 6.00072 13.4675L7.35895 12.1092Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3332">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
