/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import { useMemo, Fragment } from "react";
import type { FC } from "react";
import { get as lodashGet } from "lodash-es";
import { getAvatarLetters } from "@certa/blocks/thanos";
import { Link } from "react-router";
import type { TaskViewProps } from "./utils";
import { calculateOverdueDuration, useTaskWaiting } from "./utils";
import { Task } from "@certa/blocks/src/upLevelComponents/Task";
import { AlertTooltip } from "@certa/processdetails/src/taskDetail/components/AlertTooltip";
import { TASK_VARIANTS } from "@certa/blocks/src/upLevelComponents/Task/types";
import { useSelector } from "react-redux";
import { AssignedToUserGroupsTooltip } from "@certa/processdetails/src/sidebar/components/UserGroupsTooltip";
import { UserDetailsTooltip } from "@certa/common/components/UserDetailsTooltip";

export type OverdueDayType = {
  days: number;
  isDeadlineCrossed: boolean;
};

type WrapperType = {
  Component: React.ElementType;
  props: {
    to?: string;
  };
};

const TaskView: FC<TaskViewProps> = ({
  task,
  isSelectedTask,
  displayDeadline = false,
  routeURL,
  processId,
  taskLaneId,
  onSelect,
  isAIFillingAnswers,
  hasAnyAIFilledAnswers,
  isLockedStepHighlight
}) => {
  const taskWaiting = useTaskWaiting(processId);
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );

  const shouldShowInlineLoader = !!(
    lodashGet(taskWaiting, `${task.id}`, null) || isAIFillingAnswers
  );

  const overdueDays = useMemo(() => {
    if (task.deadline) {
      return {
        duration: calculateOverdueDuration(task.deadline),
        isDeadlineCrossed: task.overdue
      };
    }
  }, [task]);

  const Wrapper: WrapperType =
    !onSelect && routeURL
      ? {
          Component: Link,
          props: { to: routeURL }
        }
      : { Component: Fragment, props: {} };

  const taskType = useMemo(() => {
    if (task.isLocked) {
      return TASK_VARIANTS.LOCKED;
    } else if (task.isHidden) {
      return TASK_VARIANTS.HIDDEN;
    } else if (task.stepType === TASK_VARIANTS.FYI) {
      return TASK_VARIANTS.FYI;
    } else if (displayDeadline && overdueDays) {
      return overdueDays.isDeadlineCrossed
        ? TASK_VARIANTS.DELAYED
        : TASK_VARIANTS.TO_BE_DELAYED;
    } else if (task.isCompleted) {
      return TASK_VARIANTS.COMPLETED;
    } else if (hasAnyAIFilledAnswers) {
      return TASK_VARIANTS.ASSISTED_BY_AI;
    } else if (!!task?.allUsers?.find(user => userId === user.user)) {
      return TASK_VARIANTS.MY_TASK;
    } else {
      return TASK_VARIANTS.NEUTRAL;
    }
  }, [
    displayDeadline,
    overdueDays,
    hasAnyAIFilledAnswers,
    task?.allUsers,
    task.isCompleted,
    task.isHidden,
    task.isLocked,
    task.stepType,
    userId
  ]);

  const usersToTaskInfo = useMemo(
    () =>
      task?.allUsers?.length
        ? task?.allUsers.map(user => {
            return {
              letters: getAvatarLetters(
                user.user_full_name.trim() || user.user_email || ""
              ),
              props: {
                user: {
                  id: user.user,
                  fullName: user.user_full_name.trim() || null,
                  email: user.user_email || null,
                  groups: user.groups || []
                },
                openInPortal: true,
                placement: "top"
              },
              secondary: userId === user.user
            };
          })
        : [],
    [task.allUsers, userId]
  );

  return (
    <Wrapper.Component {...Wrapper.props}>
      <Task
        onClick={onSelect?.(task.id, taskLaneId)}
        type={taskType}
        isSelectedTask={isSelectedTask}
        avatars={usersToTaskInfo}
        title={task.name}
        taskWidth={225}
        overdueDays={
          displayDeadline && task?.stepType !== "FYI"
            ? overdueDays?.duration
            : undefined
        }
        canShowInlineLoader={shouldShowInlineLoader}
        stepEnhancer={!!task?.allUsers?.find(user => userId === user.user)}
        avatarWrapper={UserDetailsTooltip as FC}
        alertCount={task.alerts.length > 0 ? task.alerts.length : undefined}
        alertColor={
          task.alerts && task.alerts.length > 0
            ? task.alerts[0].label
            : undefined
        }
        alertWrapper={AlertTooltip as FC}
        alertWrapperProps={{ alerts: task.alerts }}
        // @ts-expect-error TS5 upgrade
        extraAvatarWrapper={AssignedToUserGroupsTooltip}
        extraAvatarWrapperProps={{
          assignedTo:
            task?.allUsers?.map(user => ({
              label: user.user_full_name.trim() || user.user_email || "",
              value: user.user_full_name.trim() || user.user_email || ""
            })) || []
        }}
        isLockedStepHighlight={isLockedStepHighlight}
      />
    </Wrapper.Component>
  );
};

export { TaskView };
