import { css } from "emotion";
import { CatalystColors } from "@certa/catalyst/constants/styles";

export const createTableStyles = (yAxisRows: number) =>
  css({
    borderRadius: "8px",
    overflow: "hidden",
    fontSize: "14px",
    lineHeight: 1.4,
    width: "100%",

    "& thead th": {
      textAlign: "center",
      padding: "4px 4px",
      backgroundColor: CatalystColors.NEUTRAL_200,
      borderBottom: `1px solid ${CatalystColors.NEUTRAL_400}`,
      borderRight: `1px solid ${CatalystColors.NEUTRAL_400}`,
      fontWeight: 500,
      color: CatalystColors.NEUTRAL_700
    },

    "& td": {
      padding: "4px",
      backgroundColor: CatalystColors.NEUTRAL_100,
      borderBottom: `1px solid ${CatalystColors.NEUTRAL_400}`,
      borderRight: `1px solid ${CatalystColors.NEUTRAL_400}`,
      textAlign: "right",

      [`&:nth-child(-n + ${yAxisRows})`]: {
        background: CatalystColors.NEUTRAL_200,
        textAlign: "center",
        fontWeight: 500,
        color: CatalystColors.NEUTRAL_700
      }
    },

    "& td.summary": {
      "&&": {
        textAlign: "right"
      }
    },

    "& .heat-map-cell-div": {
      padding: "4px",
      borderRadius: "4px"
    }
  });
