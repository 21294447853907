import { useEffect } from "react";
import { RouterProvider } from "react-router/dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { router } from "@certa/routing/routes";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { IntlCapture } from "../modules/common/components/IntlCapture";
import { AsyncIntlProvider } from "../modules/common/components/AsyncIntlProvider";
import { store } from "../js/_helpers/store";
import { queryClient } from "@certa/queries/utils/utils";
import { GlobalStyle } from "@certa/common/components/GlobalStyle";

const App = () => {
  useEffect(() => {
    // Add JIRA widget if the env is UAT
    if (import.meta.env.REACT_APP_CERTA_ENV === "UAT") {
      const script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://thevetted.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-wkhsc1/b/45/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=5fcd3be7"
      );
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AsyncIntlProvider>
            <ErrorBoundary>
              <GlobalStyle />
              <RouterProvider router={router} />
              <IntlCapture />
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </ErrorBoundary>
          </AsyncIntlProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </Provider>
  );
};

const AppWithProfiler = Sentry.withProfiler(App, {
  name: "App"
});

export { AppWithProfiler as App };
