import React from "react";
import styled from "@emotion/styled";
import { css } from "emotion";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import { Timestamp } from "../../../js/components/Navbar/UTCTimestamp";
import type { UserMentionType } from "../interfaces";
import { Link } from "react-router";
import type { IntlShape } from "react-intl";
import { injectIntl } from "react-intl";
import { useMutation } from "react-query";
import { markAsRead as markAsReadAPI } from "../services";
import { Loader } from "@certa/blocks";
import { notification } from "@certa/blocks/thanos";
import { sanitizeHTML } from "@certa/common/utils/sanitizeHTML";

type UserMentionViewPropsInteface = UserMentionType & {
  intl: IntlShape;
  onMarkAsRead: (id: number) => void;
};

const UserMentionViewComponent = React.memo(
  (props: UserMentionViewPropsInteface): JSX.Element => {
    const {
      id,
      message,
      postedBy,
      createdAt,
      workflowName,
      threadURL,
      intl,
      fieldName,
      stepName,
      isRead,
      onMarkAsRead
    } = props;
    const { mutate: markAsRead, isLoading } = useMutation(markAsReadAPI, {
      onSuccess: () => {
        onMarkAsRead(id);
      },
      onError: () => {
        notification.error({
          message: intl.formatMessage({
            id: "userMentions.failedToMarkMessageRead",
            defaultMessage:
              "Oops some error occurred in marking message as read"
          })
        });
      }
    });

    let transformedMessage = message;

    // To Do : move it outside and make it common for  both comments message view and user mention view
    // Generic format regex only checks for the format of the tags,
    // regardless of data.
    const genericFormatRegEx = new RegExp(
      `~\\[([^\\]]*)\\]\\((u|g)\\d+\\)`,
      "gm"
    );

    transformedMessage = transformedMessage
      .replace(
        genericFormatRegEx,
        `<span class="consolidated-user-mentions">@$1</span>`
      )
      .replace(/\n/g, "<br />"); // new lines should be treated as breaks

    const ellipsisIcon = "…";
    return (
      <StyledMessageWrapper>
        {!isRead ? (
          isLoading ? (
            <Loader
              size="small"
              style={{
                position: "absolute",
                right: 38,
                top: 24
              }}
            />
          ) : (
            <Tooltip
              title={intl.formatMessage({
                id: "userMentions.markAsRead",
                defaultMessage: "Mark as Read"
              })}
              placement="topRight"
            >
              <UnreadDot onClick={() => markAsRead(id)} />
            </Tooltip>
          )
        ) : null}
        <Avatar
          size="small"
          icon={<UserOutlined />}
          style={{ float: "left" }}
        />
        <StyledMessageContainer>
          <StyledMessageRow>
            <StyledUserName>{postedBy}</StyledUserName>
            <StyledCommentTimestamp>
              <Timestamp timestamp={createdAt} />
            </StyledCommentTimestamp>
          </StyledMessageRow>
          <StyledMessageBody>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(transformedMessage)
              }}
            />
          </StyledMessageBody>
          <StyledMessageRow>
            <StyledDisplayName>{workflowName}</StyledDisplayName>
            {fieldName || stepName ? (
              <span
                className={css`
                  opacity: 0.3;
                  margin-left: 5px;
                  display: flex;
                  flex-direction: row;
                `}
              >
                <span
                  className={css`
                    margin-right: 5px;
                  `}
                >
                  {ellipsisIcon}
                </span>
                <StyledDisplayName>
                  {fieldName ? fieldName : stepName}
                </StyledDisplayName>
              </span>
            ) : null}
            <Link
              to={threadURL}
              className={css`
                text-decoration: none;
                margin-left: 15px;
                white-space: nowrap;
              `}
            >
              {intl.formatMessage({
                id: "dashboard.userMentions.viewConversation",
                defaultMessage: "View Conversation"
              })}
            </Link>
          </StyledMessageRow>
        </StyledMessageContainer>
      </StyledMessageWrapper>
    );
  }
);

export const UserMentionView = injectIntl(UserMentionViewComponent);

const UnreadDot = styled.div`
  position: relative;
  &::before {
    content: " ";
    border: 8px solid red;
    border-radius: 8px;
    position: absolute;
    z-index: 1000;
    right: 12px;
    top: 2px;
    cursor: pointer;
  }
`;

const StyledMessageWrapper = styled.div`
  word-wrap: break-word;
  padding: 15px 25px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  position: relative;
`;

const StyledMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

const StyledMessageRow = styled.div`
  font-size: 12px;
  padding: 2px 0px 3px;
  display: flex;
`;

const StyledCommentTimestamp = styled.span`
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
`;

const StyledMessageBody = styled.div`
  font-size: 14px;
  letter-spacing: -0.03px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledUserName = styled.div`
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.03px;
`;

const StyledDisplayName = styled.div`
  color: #000;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 15px;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
`;
