import {
  GLOBAL_SEARCH_OPERATOR_DETAILS,
  isValidAnswerQuery
} from "@certa/common/utils/filterUtils";
import type { ReportCustomFilter } from "@certa/types/src/filters";
import { useDebounceWithCancel } from "../../../../hooks/useDebounce";
import { ControlledAdvancedFilters } from "../../../AdvancedFilters";
import { useSearchStore } from "../../store/useSearchStore";

type AdvancedFiltersProps = {
  advancedFilterCustomFilter: ReportCustomFilter;
  setAdvancedFilterCustomFilter: (value: ReportCustomFilter) => void;
};

export const AdvancedFilters = (props: AdvancedFiltersProps) => {
  const { advancedFilterCustomFilter, setAdvancedFilterCustomFilter } = props;
  const {
    selectedKindIdForAdvancedFilters,
    setAdvancedFilterConditions,
    areAdvancedFiltersApplied
  } = useSearchStore();

  const debouncedSetAdvancedFilterConditions = useDebounceWithCancel(
    setAdvancedFilterConditions,
    300
  );

  const onChange = (value: ReportCustomFilter) => {
    setAdvancedFilterCustomFilter(value);
    if (value?.conditions) {
      const filterConditions = value.conditions.filter(isValidAnswerQuery);
      debouncedSetAdvancedFilterConditions(filterConditions);
    }
  };

  if (!areAdvancedFiltersApplied) return null;

  return (
    <ControlledAdvancedFilters
      value={advancedFilterCustomFilter}
      onChange={onChange}
      shouldEnableGroupQuery={false}
      kindId={Number(selectedKindIdForAdvancedFilters)}
      customOperators={GLOBAL_SEARCH_OPERATOR_DETAILS}
      shouldAppendButtons={false}
    />
  );
};
