import React from "react";
import { filterTriggerStyles } from "./FilterTrigger.styles";
import { Caret } from "@certa/icons/components/Caret";

export type FilterTriggerProps = {
  label: string;
  extraText?: string;
  isActive?: boolean;
  maxWidth?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const FilterTrigger = React.forwardRef<
  HTMLButtonElement,
  FilterTriggerProps
>((props, ref) => {
  const { label, extraText, isActive = false, maxWidth, ...rest } = props;

  return (
    <button
      ref={ref}
      className={filterTriggerStyles.trigger(maxWidth, isActive)}
      type="button"
      {...rest}
    >
      <span className="ellipsis">{label}</span>
      {extraText && (
        <span className={filterTriggerStyles.countText}>{extraText}</span>
      )}
      <Caret size={12} className={filterTriggerStyles.caret} />
    </button>
  );
});
