import { useEffect, useState } from "react";
import { UserGroup } from "@certa/icons/components/UserGroup";
import type { CommentChannel, CommentThreadMeta } from "@certa/types";

import {
  DropdownMenuItemSizes,
  DropdownMenuItemTypes,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@certa/catalyst/components/Dropdown";

import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";

import { Stack } from "@certa/catalyst/layouts/Stack";
import { ThreadTag } from "./ThreadTag";
import { MembersViewTooltip } from "./MembersViewTooltip";
import { hiddenCountPillStyles } from "./CommentsRedesigned.styles";
import { css } from "emotion";

import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";

type ThreadListRedesignedProps = {
  channel: CommentChannel;
  selectedThreadId: null | number;
  setActiveThread: (threadId: number) => void;
};

const ThreadListRedesigned = ({
  channel,
  selectedThreadId,
  setActiveThread
}: ThreadListRedesignedProps) => {
  const { allThreads: threadList, threads } = channel;

  const [displayThreads, setDisplayThreads] = useState<CommentThreadMeta[]>([]);
  const [hiddenThreads, setHiddenThreads] = useState<CommentThreadMeta[]>([]);

  const { isMobileResolution } = useScreenResolution();
  const MAX_THREADS_TO_DISPLAY = isMobileResolution ? 2 : 5;

  useEffect(() => {
    let threadsToDisplay =
      threadList.length > MAX_THREADS_TO_DISPLAY
        ? threadList.slice(0, MAX_THREADS_TO_DISPLAY)
        : threadList;
    let hiddenThreads =
      threadList.length > MAX_THREADS_TO_DISPLAY
        ? threadList.slice(MAX_THREADS_TO_DISPLAY)
        : [];
    // Check if activeThread is in threadsToDisplay
    const isActiveThreadPillVisible = threadsToDisplay.some(
      thread => thread.threadId === selectedThreadId
    );

    if (!isActiveThreadPillVisible && selectedThreadId != null) {
      // Find the active thread
      const activeThread = threadList.find(
        thread => thread.threadId === selectedThreadId
      );

      if (activeThread) {
        // Remove the active thread from hiddenThreads if it exists there
        hiddenThreads = hiddenThreads.filter(
          thread => thread.threadId !== selectedThreadId
        );
        const threadToSwap = threadsToDisplay[MAX_THREADS_TO_DISPLAY - 1];

        // Add the active thread to the MAX_THREADS_TO_DISPLAY position in threadsToDisplay
        threadsToDisplay = [
          ...threadsToDisplay.slice(0, MAX_THREADS_TO_DISPLAY - 1),
          activeThread
        ];

        // Move the previously last displayed thread to hiddenThreads
        if (threadList.length > MAX_THREADS_TO_DISPLAY) {
          hiddenThreads = [threadToSwap, ...hiddenThreads];
        }
      }
    }
    setDisplayThreads(threadsToDisplay);
    setHiddenThreads(hiddenThreads);
  }, [selectedThreadId, threadList, MAX_THREADS_TO_DISPLAY]);

  const handleHiddenThreadSelect = (thread: CommentThreadMeta) => {
    setDisplayThreads(prev => {
      const newDisplayedThreads = [
        ...prev.slice(0, MAX_THREADS_TO_DISPLAY - 1),
        thread
      ];
      setHiddenThreads([...hiddenThreads, prev[MAX_THREADS_TO_DISPLAY - 1]]);
      return newDisplayedThreads;
    });
    setHiddenThreads(prev =>
      prev.filter(hiddenThread => hiddenThread.threadId !== thread.threadId)
    );
    setActiveThread(thread.threadId);
  };

  useEffect(() => {
    const threadNode = document.getElementById(
      `thread-list-item-${selectedThreadId}`
    );

    threadNode?.scrollIntoView();
  }, [selectedThreadId]);

  const hiddenThreadsCountMessage = `+${hiddenThreads.length}`;

  return (
    <Stack direction="horizontal" gap="8">
      {displayThreads.map(thread => {
        const isSelectedThread = thread.threadId === selectedThreadId;
        return (
          <ThreadTag
            key={thread.threadId}
            thread={thread}
            threads={threads}
            isSelectedThread={isSelectedThread}
            setActiveThread={setActiveThread}
          />
        );
      })}
      {hiddenThreads.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div aria-label={hiddenThreadsCountMessage} tabIndex={0}>
              <Stack
                gap="4"
                padding="4 8"
                direction="horizontal"
                align="center"
                dangerouslySetClassName={hiddenCountPillStyles}
              >
                <UserGroup size={10} />
                <Typography
                  as="div"
                  color={TypographyColors.NEUTRAL_700}
                  variant={TypographyVariants.LABEL_SM}
                >
                  <Ellipsis>{hiddenThreadsCountMessage}</Ellipsis>
                </Typography>
              </Stack>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" width="12.5rem">
            {hiddenThreads.map(thread => (
              <DropdownMenuItem
                key={thread.threadId}
                value={thread.threadId.toString()}
                onSelect={() => handleHiddenThreadSelect(thread)}
                size={DropdownMenuItemSizes.BIG}
                type={DropdownMenuItemTypes.DEFAULT}
              >
                <MembersViewTooltip
                  placement={"left-start"}
                  threadUserGroups={threads[thread.threadId]?.threadUserGroups}
                >
                  <Typography
                    variant={TypographyVariants.BODY}
                    className={dropdownMenuItemStyles}
                  >
                    <Ellipsis>{thread.threadName}</Ellipsis>
                  </Typography>
                </MembersViewTooltip>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </Stack>
  );
};

const dropdownMenuItemStyles = css({
  maxWidth: "11.5rem",
  overflow: "hidden",
  textWrap: "ellipsis"
});

export { ThreadListRedesigned };
