import { Checkbox } from "@certa/catalyst/components/Checkbox";
import { Stack } from "@certa/catalyst/layouts/Stack";
import {
  useExcludedWorkflowKindTags,
  usePreSelectedWorkflowKindTags
} from "../../hooks/usePreSelectedWorkflowKinds";
import { useSearchStore } from "../../store/useSearchStore";
import { filtersStyles } from "./Filters.style";
import { Spinner } from "@certa/icons/components/Spinner";
import { classNames } from "@certa/catalyst/utils/common";

type KindFilterProps = {
  isDisabled?: boolean;
};

const KindFilter = ({ isDisabled }: KindFilterProps) => {
  const {
    filters,
    setFilters,
    kinds: kindsList,
    isLoadingKinds
  } = useSearchStore();
  const excludedWorkflowKindTags = useExcludedWorkflowKindTags();
  const preSelectedKindTags = usePreSelectedWorkflowKindTags();

  const kindOptions = kindsList
    ? kindsList
        .filter(kind => !excludedWorkflowKindTags.includes(kind.tag))
        .map(({ name, id, tag }) => ({
          label: name,
          value: String(id),
          isPreSelected: preSelectedKindTags.includes(tag)
        }))
        .sort((a, b) => {
          // Sort preselected items to the top
          if (a.isPreSelected && !b.isPreSelected) return -1;
          if (!a.isPreSelected && b.isPreSelected) return 1;
          // For items with same preselected status, maintain alphabetical order
          return a.label.localeCompare(b.label);
        })
    : [];

  const handleChange = (kindId: string) => {
    const kindIds = filters.kindIds.includes(kindId)
      ? filters.kindIds.filter(id => id !== kindId)
      : [...filters.kindIds, kindId];
    setFilters({ kindIds });
  };

  return (
    <Stack
      gap="4"
      dangerouslySetClassName={classNames(
        filtersStyles.kindFilterWrapper,
        "catalystScrollContainer"
      )}
    >
      {isLoadingKinds ? (
        <Stack
          direction="horizontal"
          align="center"
          justify="center"
          height="3rem"
        >
          <Spinner />
        </Stack>
      ) : (
        kindOptions.map(option => (
          <Checkbox
            key={option.value}
            label={option.label}
            value={option.value}
            checked={filters.kindIds.includes(option.value)}
            onChange={() => handleChange(option.value)}
            disabled={isDisabled}
          />
        ))
      )}
    </Stack>
  );
};

export { KindFilter };
