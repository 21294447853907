import { Gauge } from "@certa/icons/components/Gauge";
import { Boolean } from "@certa/icons/components/Boolean";
import { Badge } from "@certa/icons/components/Badge";
import { Sum } from "@certa/icons/components/Sum";
import { Progress } from "@certa/icons/components/Progress";
import { Text } from "@certa/icons/components/Text";
import { Number } from "@certa/icons/components/Number";
import { Tag } from "@certa/icons/components/Tag";
import { User } from "@certa/icons/components/User";
import { Envelope } from "@certa/icons/components/Envelope";
import { Date } from "@certa/icons/components/Date";
import type { IconProps } from "@certa/icons";
import type { ORMReportProcessAttr } from "./ormConstants";
import { FieldTypes } from "@certa/types/src/fieldTypes";

type ProcessAttrDataORMType = Record<
  ORMReportProcessAttr,
  {
    label: string;
    intlId: string;
    icon: React.ComponentType<IconProps>;
    fieldType: FieldTypes;
    /**
     * This is for fields such as status_id, business_unit_name, region_name which accepts data as integer while backend returns TEXT
     * Ref: PLAT-24110
     */
    outputFieldType?: FieldTypes;
    isHidden: boolean;
    value: ORMReportProcessAttr;
    hasFilter: boolean;
    hasColumnFilter: boolean;
    hasSorting: boolean;
    canAddToXAxis: boolean;
    canAddToYAxis: boolean;
    isDeprecated?: boolean;
  }
>;

export const processAttrDataORM: ProcessAttrDataORMType = {
  id: {
    label: "Id",
    intlId: "reports.id",
    icon: Number,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "id",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  name: {
    label: "Name",
    intlId: "reports.name",
    icon: Text,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "name",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  created_at: {
    label: "Created At",
    intlId: "reports.createdAt",
    icon: Date,
    fieldType: FieldTypes.DATE,
    isHidden: false,
    value: "created_at",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  kind_id: {
    label: "Kind Id",
    intlId: "reports.kindId",
    icon: Number,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "kind_id",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  kind_tag: {
    label: "Kind Tag",
    intlId: "reports.kindTag",
    icon: Text,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "kind_tag",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  /** @deprecated Use status_id instead */
  status_label: {
    label: "Status",
    intlId: "reports.status",
    icon: Tag,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "status_label",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false,
    isDeprecated: true
  },
  status_tag: {
    label: "Status Tag",
    intlId: "report.status_tag",
    icon: Tag,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "status_tag",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  status_id: {
    label: "Status",
    intlId: "reports.status",
    icon: Tag,
    fieldType: FieldTypes.INTEGER,
    outputFieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "status_id",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  created_by_email: {
    label: "Created By (Email)",
    intlId: "reports.createdByEmail",
    icon: Envelope,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "created_by_email",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  created_by_name: {
    label: "Created By (Name)",
    intlId: "reports.createdByName",
    icon: User,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "created_by_name",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  ageing: {
    label: "Ageing",
    intlId: "reports.ageing",
    icon: Gauge,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "ageing",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  cycle_time: {
    label: "Process cycle time",
    intlId: "reports.processCycleTime",
    icon: Gauge,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "cycle_time",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  is_cycle_running: {
    label: "Is cycle running",
    intlId: "reports.isCycleRunning",
    icon: Boolean,
    fieldType: FieldTypes.BOOL,
    isHidden: false,
    value: "is_cycle_running",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  cycle_start: {
    label: "Cycle Start",
    intlId: "reports.CycleStart",
    icon: Date,
    fieldType: FieldTypes.DATE,
    isHidden: false,
    value: "cycle_start",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  cycle_end: {
    label: "Cycle End",
    intlId: "reports.cycleEnd",
    icon: Date,
    fieldType: FieldTypes.DATE,
    isHidden: false,
    value: "cycle_end",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  /** @deprecated Use business_unit_id instead */
  business_unit_name: {
    label: "Business Unit",
    intlId: "report.business_unit",
    icon: Text,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "business_unit_name",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false,
    isDeprecated: true
  },
  business_unit_id: {
    label: "Business Unit",
    intlId: "report.business_unit",
    icon: Text,
    fieldType: FieldTypes.INTEGER,
    outputFieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "business_unit_id",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  /** @deprecated Use region_id instead */
  region_name: {
    label: "Region",
    intlId: "workflowFiltersTranslated.filterPlaceholders.region",
    icon: Text,
    fieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "region_name",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false,
    isDeprecated: true
  },
  region_id: {
    label: "Region",
    intlId: "workflowFiltersTranslated.filterPlaceholders.region",
    icon: Text,
    fieldType: FieldTypes.INTEGER,
    outputFieldType: FieldTypes.TEXT,
    isHidden: false,
    value: "region_id",
    hasFilter: true,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  logo: {
    label: "logo",
    intlId: "reports.log",
    icon: Text,
    fieldType: FieldTypes.TEXT,
    isHidden: true,
    value: "logo",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  lc_data: {
    label: "LC Data",
    intlId: "reports.lcData",
    icon: Text,
    fieldType: FieldTypes.ARRAY,
    isHidden: true,
    value: "lc_data",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  definition_id: {
    label: "Definition Id",
    intlId: "reports.definitionId",
    icon: Number,
    fieldType: FieldTypes.INTEGER,
    isHidden: true,
    value: "definition_id",
    hasFilter: true,
    hasColumnFilter: true,
    hasSorting: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  parents: {
    label: "Family Tree",
    intlId: "reports.parents",
    icon: Text,
    fieldType: FieldTypes.ARRAY,
    isHidden: true,
    value: "parents",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  alerts: {
    label: "Field Alerts",
    intlId: "dashboards.fieldAlerts",
    icon: Sum,
    fieldType: FieldTypes.ARRAY,
    isHidden: false,
    value: "alerts",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  progress: {
    label: "Progress",
    intlId: "dashboards.processProgress",
    icon: Progress,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "progress",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  badges: {
    label: "Badges",
    intlId: "tasks.tableColumn.badges",
    icon: Badge,
    fieldType: FieldTypes.ARRAY,
    isHidden: false,
    value: "badges",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  my_tasks_count: {
    label: "My Tasks",
    intlId: "tasks.tableColumn.myTasks",
    icon: Number,
    fieldType: FieldTypes.INTEGER,
    isHidden: false,
    value: "my_tasks_count",
    hasFilter: false,
    hasColumnFilter: false,
    hasSorting: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  }
};
