import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { css } from "emotion";
import * as Sentry from "@sentry/react";
import { useIntl } from "react-intl";
import {
  Navbar,
  NavbarDropdownMenu,
  NavbarDropdownMenuTrigger,
  NavbarFooter,
  NavbarItem,
  NavbarLogo,
  NavbarUserProfile,
  NavbarUserProfileTrigger,
  UserProfileMenuItem,
  UserProfileSubMenuItemTrigger
} from "@certa/catalyst/layouts/Navbar";

import { getAvatarLetters } from "@certa/blocks/thanos";
import {
  Avatar,
  AvatarColors,
  AvatarSizes
} from "@certa/catalyst/components/Avatar";
import {
  DropdownSubMenu,
  DropdownSubMenuTrigger
} from "@certa/catalyst/components/Dropdown";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";

import { prefetchObjectTypesQuery } from "@certa/queries/hooks/dataObjects.hooks";

import { SearchDropdown } from "@certa/common/components/SearchDropdown/SearchDropdown";
import {
  TABLEAU_PATH,
  getSearchRoute,
  getStudioAuditLogsRoute
} from "@certa/common/utils/routes";
import { Book } from "@certa/icons/components/Book";
import { Envelope } from "@certa/icons/components/Envelope";
import { Eye } from "@certa/icons/components/Eye";
import { Gear } from "@certa/icons/components/Gear";
import { Language } from "@certa/icons/components/Language";
import { OpenInWindow } from "@certa/icons/components/OpenInWindow";
import { QuestionOutline } from "@certa/icons/components/QuestionOutline";
import { User } from "@certa/icons/components/User";
import { ChevronRightLight } from "@certa/icons/components/ChevronRightLight";
import { useGetUnReadMentionCount } from "@certa/queries/hooks/mentions.hooks";
import { useGetTaskCount } from "@certa/queries/hooks/tasks.hooks";
import { useUnreadMentionsCountComms } from "@certa/queries/queries/comms";
import { useFlexibleMatch } from "@certa/routing/hooks";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  checkLinkVisibility,
  handleMixPanelEventsForNav,
  mixpanelHandler
} from "./utils";
import { headerNavLinks, studioNavLink } from "./NavigationSidebar.constants";
import { useMentionsContext } from "@certa/mentions";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { MySubscriptions } from "./components/MySubscriptions";
import { useLanguages } from "./hooks/useLanguages";
import { userLogout } from "main/src/modules/LoginPage/loginSlice";
import { InstanceSettings } from "./components/InstanceSettings";
import { PermissionTypes, type OptionItem } from "@certa/types";
import { ImpersonationUsersList } from "../Header/components/UserProfile/components/ImpersonationUsersList";
import { useNavigationDrawer } from "./DrawerContext";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";
import { useHomePage } from "../../hooks/useHomePage";
import { useCanViewTasks } from "../../hooks/useCanViewTasks";
import {
  useCheckPermission,
  useCheckPermissionFn
} from "../../utils/permissions/Chowkidar";
import { useHeaderReportLinkText } from "../../hooks/useHeaderReportLinkText";
import { useEnableUserImpersonation } from "../../hooks/useEnableUserImpersonation";
import { toCapital } from "../../utils/string";
import { getUsersByKind } from "../../utils/helper";
import { useShowHeaderDropdownOptions } from "../../hooks/useShowHeaderDropdownOptions";
import { useCanSeeCreateSupportButton } from "../../hooks/useCanSeeCreateSupportButton";
import { useCreateNewWorkflow } from "../../hooks/useCreateNewWorkflow";
import { CERTA_SUPPORT } from "../../constants";
import type { DropdownOption } from "../../hooks/useFilterHeaderOptionBasedOnUserGroup";
import { useCommsNewEndpoints } from "../../toggles";
import { useEnableDataObjects } from "../../hooks/useEnableDataObjects";
import { DataObjectTypesDrawer } from "./DataObjectTypesDrawer/DataObjectTypesDrawer";
import { basename } from "main/src/config";
import type { NavItemId } from "../Header/types";
import { isDev } from "../../utils/isDev";

type Link = {
  label: string;
  url: string;
}[];

const NavigationSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const authentication = useAppSelector(state => state?.authentication);

  const isDataObjectsEnabled = useEnableDataObjects();

  if (isDataObjectsEnabled) {
    prefetchObjectTypesQuery();
  }

  const currentRoute = window.location.pathname;
  useEffect(() => {
    if (currentRoute === "/home") {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [currentRoute]);
  const navigate = useNavigate();

  const isSupplierUser = authentication?.user?.kind === 2;
  const isHomePageEnabled = useHomePage();
  const canViewTasks = useCanViewTasks();
  const isStudioEnabled = useAppSelector(
    state => state.config.configuration.enable_studio
  );
  const canViewStudio2Permissions = useCheckPermission(
    PermissionTypes.CAN_ACCESS_STUDIO
  );
  const canViewStudio2 = canViewStudio2Permissions && isStudioEnabled;
  const checkPermissions = useCheckPermissionFn();

  const {
    setIsDashboardDrawerOpen,
    setIsSearchModalOpen,
    isDataObjectTypesDrawerOpen,
    setIsDataObjectTypesDrawerOpen
  } = useNavigationDrawer();

  const filterNavLinks = checkLinkVisibility(checkPermissions, {
    isSupplierUser,
    isHomePageEnabled,
    canViewTasks,
    canViewStudio2,
    canViewDataObjects: isDataObjectsEnabled
  });

  const navLinks = [...headerNavLinks, studioNavLink];

  const activeRouteLabel = navLinks.find(
    nav =>
      currentRoute.includes(nav?.route || "") ||
      nav?.alias?.some(aliasRoute => aliasRoute.test(currentRoute))
  )?.label;

  const { toggleShow: toggleNotificationDrawer } = useMentionsContext();
  const reportText = useHeaderReportLinkText();
  const intl = useIntl();
  const config = useAppSelector(state => state.config);

  // Adding `!!` so that TS understands it as boolean automatically without typecasting since the authentication and user in the reducer are of type `any`
  const shouldShowTableauReports =
    !!authentication?.user?.features?.includes("view_reports") &&
    !!config?.report_embed_url;

  const { data: myTaskCount } = useGetTaskCount();
  const [shouldShowMySubscriptions, setShouldShowMySubscriptions] =
    useState(false);
  const [shouldShowInstanceSettings, setShouldShowInstanceSettings] =
    useState(false);
  const tableauLinkLabel =
    reportText ||
    intl.formatMessage({
      id: "navigation.tableau",
      defaultMessage: "Open Tableau"
    });

  const handleNavItemsClick = (args: {
    label: string;
    id: NavItemId;
    route?: string;
  }) => {
    const { id, label, route = "" } = args;
    handleMixPanelEventsForNav(label);
    switch (id) {
      case "notifications":
        // Close dashboard drawer if open and then open the notifications drawer
        setIsDataObjectTypesDrawerOpen(false);
        setIsDashboardDrawerOpen(false);
        toggleNotificationDrawer();
        break;
      case "search":
        if (!isSearchPage) {
          toggleNotificationDrawer(false);
          setIsDashboardDrawerOpen(false);
          setIsSearchModalOpen(true);
          setIsDataObjectTypesDrawerOpen(false);
        }
        break;
      case "dashboards":
        // Close notifications drawer if open and then open the dashboard drawer
        toggleNotificationDrawer(false);
        setIsDashboardDrawerOpen(prevState => !prevState);
        setIsDataObjectTypesDrawerOpen(false);
        if (activeRouteLabel !== "Dashboards") {
          navigate(route);
        }
        break;
      case "records":
        toggleNotificationDrawer(false);
        setIsDashboardDrawerOpen(false);
        setIsDataObjectTypesDrawerOpen(prevState => !prevState);
        break;
      default:
        toggleNotificationDrawer(false);
        setIsDashboardDrawerOpen(false);
        setIsDataObjectTypesDrawerOpen(false);
        navigate(route);
    }
  };

  const handleStudioClick = () => {
    mixpanelHandler(studioNavLink.label);
    window.open(`${basename}${studioNavLink.route}`, "_blank");
  };

  const handleTableauClick = () => {
    mixpanelHandler("Tableau");
    window.open(`${window.location.origin}${TABLEAU_PATH}`, "_blank");
  };

  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const { data: userMentionsCount } = useGetUnReadMentionCount();
  const { data: userMentionsCountComms } = useUnreadMentionsCountComms();

  const showCountBadges = (key: string) => {
    switch (key) {
      case "Notifications":
        return isCommsNewEndpointEnabled
          ? userMentionsCountComms
          : userMentionsCount;
      case "Tasks":
        return myTaskCount;
      default:
        return undefined;
    }
  };
  const userName =
    authentication?.user?.first_name && authentication?.user?.last_name
      ? `${authentication.user.first_name} ${authentication.user.last_name}`
      : "";

  const userEmail = authentication?.user?.email || "";
  const dispatch = useAppDispatch();
  const handleLogout = useCallback(() => dispatch(userLogout()), [dispatch]);

  const canAccessInstanceSettings = useCheckPermission(
    PermissionTypes.CAN_ACCESS_ADMIN_SETTINGS
  );

  const {
    supportedLanguages,
    preferredLanguage,
    handleLanguageChange,
    renderLanguageMenuItem
  } = useLanguages();

  const canUserViewAs = useEnableUserImpersonation();
  const hasPermissionToImpersonate = useCheckPermission(
    PermissionTypes.CAN_IMPERSONATE_OTHER_USERS
  );

  const tenantName = toCapital(
    (useAppSelector(state => state.config.name) as string) || "certa"
  ) as Capitalize<string>;

  const usersByKind = getUsersByKind(tenantName);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleSubmenuOpenChange = (isOpen: boolean) => {
    setIsSubmenuOpen(isOpen);
  };
  const isSearchPage = useFlexibleMatch(getSearchRoute());

  const userProfileId = authentication?.user?.user_profile_workflow || null;
  const showHeaderDropdownOptions = useShowHeaderDropdownOptions();
  const { supportTicketData } = useCanSeeCreateSupportButton();
  const { onSelect: createWorkflowOnSelect } = useCreateNewWorkflow();

  const headerLinks: Link =
    useAppSelector(state => state.config.custom_ui_labels.header_links) || [];

  const footerLinks: Link =
    useAppSelector(state => state.config.custom_ui_labels.footer_links) || [];

  const goToUserProfile = useCallback(() => {
    handleMixPanelEventsForNav("My Profile");
    navigate(`/process/${userProfileId}`);
  }, [navigate, userProfileId]);

  const goToScheduledReports = () => {
    handleMixPanelEventsForNav("My Scheduled Reports");
    setShouldShowMySubscriptions(prev => !prev);
  };

  const goToActivityLogs = () => {
    handleMixPanelEventsForNav("Activity Logs");
    navigate(getStudioAuditLogsRoute());
  };

  const goToInstanceSettings = () => {
    handleMixPanelEventsForNav("Instance Settings");
    setShouldShowInstanceSettings(prev => !prev);
  };

  const myProfileText = intl.formatMessage({
    id: "userProfileMenu.myProfileText",
    defaultMessage: "My Profile"
  });

  const logOutMessage = intl.formatMessage({
    id: "userProfileMenu.logOutMessage",
    defaultMessage: "Log out"
  });

  const languageText = intl.formatMessage({
    id: "userProfileMenu.languageText",
    defaultMessage: "Language"
  });

  const instanceSettingsText = intl.formatMessage({
    id: "loginPageInstances.InstanceSettingsText",
    defaultMessage: "Instance Settings"
  });

  const helpText = intl.formatMessage({
    id: "userProfileMenu.help",
    defaultMessage: "Help"
  });

  const onSelectLink = (value: string, index: number) => {
    MixPanelActions.track(
      MixPanelEvents.navbar.NAV_PROFILE_HELP_CLICK_OPEN_USER_HELP_LINK,
      // TODO: https://thevetted.atlassian.net/browse/PLAT-21478
      // Passing index to track which link is clicked does not
      // give any useful information to the event
      // To check with the product team if we need this data or not
      { helpLinkIndex: index }
    );
    window.open(value);
  };

  const handleUserProfileMenuClick = () => {
    toggleNotificationDrawer(false);
    setIsDashboardDrawerOpen(false);
  };

  /* We don't show header links to supplier users */
  const shouldShowHeaderLinks = headerLinks?.length > 0 && !isSupplierUser;
  const shouldShowFooterLinks = footerLinks?.length > 0;

  const shouldShowHelpNavMenu =
    showHeaderDropdownOptions.length > 0 ||
    !!supportTicketData ||
    shouldShowHeaderLinks ||
    shouldShowFooterLinks;

  // If mini_logo is available, use it when the navbar is collapsed
  // Otherwise, use the logo
  const logoImageSrc = isExpanded
    ? config.logo
    : config?.mini_logo || config.logo;

  const onSelectLanguage = (language: OptionItem) => {
    MixPanelActions.track(
      MixPanelEvents.navbar.NAV_PROFILE_LANGUAGE_CLICK_SWITCH_LANGUAGE,
      { language: language.value }
    );
    handleLanguageChange(language.value);
  };

  const languageMenuItem = (
    <NavbarDropdownMenu id="language-menu" dropdownContentWidth="16.68rem">
      <NavbarDropdownMenuTrigger>
        <NavbarItem
          id="Language"
          as="div"
          icon={<Language size={16} />}
          label={`${languageText}: ${preferredLanguage}`}
          onClick={() => {}}
          rightIcon={<ChevronRightLight size={16} />}
        />
      </NavbarDropdownMenuTrigger>
      <div className={languageDropdownStyles}>
        {supportedLanguages.map(language => (
          <UserProfileMenuItem
            key={language.value}
            checked={preferredLanguage === language.value}
            onSelect={() => onSelectLanguage(language)}
            text={renderLanguageMenuItem(language)}
            value={language.value}
          />
        ))}
      </div>
    </NavbarDropdownMenu>
  );

  return (
    <>
      <Navbar expanded={isExpanded} activeNavItem={activeRouteLabel}>
        <NavbarLogo path="/" imageAlt={config.name} imageSrc={logoImageSrc} />
        {filterNavLinks(headerNavLinks).map((nav, index) => {
          const { route, label, icon: Icon, id } = nav;
          return (
            <NavbarItem
              id={id}
              onClick={() => handleNavItemsClick({ label, id, route })}
              icon={<Icon size={16} />}
              key={index}
              label={intl.formatMessage({
                id: `navigation.${label}`,
                defaultMessage: label
              })}
              badgeCount={showCountBadges(label)}
            />
          );
        })}
        <NavbarFooter>
          {canViewStudio2 && (
            <NavbarItem
              id={studioNavLink.label}
              onClick={handleStudioClick}
              icon={<studioNavLink.icon size={16} />}
              label={intl.formatMessage({
                id: `navigation.Studio`,
                defaultMessage: studioNavLink.label
              })}
            />
          )}
          {shouldShowTableauReports && (
            <NavbarItem
              id="Tableau"
              onClick={handleTableauClick}
              icon={<OpenInWindow size={16} />}
              label={tableauLinkLabel}
            />
          )}
          {languageMenuItem}
          {/* Help Dropdown Menu */}
          {shouldShowHelpNavMenu ? (
            <NavbarDropdownMenu id="help-menu">
              <NavbarDropdownMenuTrigger>
                <NavbarItem
                  id="Help"
                  as="div"
                  icon={
                    <QuestionOutline size={16} className={helpIconStyles} />
                  }
                  label={helpText}
                  onClick={() => {}}
                  rightIcon={<ChevronRightLight size={16} />}
                />
              </NavbarDropdownMenuTrigger>
              {!!supportTicketData && (
                <>
                  <UserProfileMenuItem
                    onSelect={value => createWorkflowOnSelect(value)}
                    icon={<Book size={12} />}
                    text={supportTicketData.label}
                    value={CERTA_SUPPORT}
                  />
                </>
              )}
              {showHeaderDropdownOptions.length > 0
                ? showHeaderDropdownOptions.map(
                    (option: DropdownOption, index: number) => (
                      <UserProfileMenuItem
                        key={index}
                        text={option.label}
                        value={option?.url || ""}
                        onSelect={value => window.open(value)}
                      />
                    )
                  )
                : null}
              {shouldShowHeaderLinks
                ? headerLinks.map((link, index) => (
                    <UserProfileMenuItem
                      key={link.url}
                      text={
                        getIntlBody(link, "label") ||
                        getIntlBody(link, "label_en")
                      }
                      value={link.url}
                      onSelect={value => onSelectLink(value, index)}
                    />
                  ))
                : null}
              {shouldShowFooterLinks
                ? footerLinks.map((link, index) => (
                    <UserProfileMenuItem
                      key={link.url}
                      text={
                        getIntlBody(link, "label") ||
                        getIntlBody(link, "label_en")
                      }
                      value={link.url}
                      onSelect={value => onSelectLink(value, index)}
                    />
                  ))
                : null}
            </NavbarDropdownMenu>
          ) : null}
          {/* User profile Dropdown Menu */}
          <NavbarUserProfile
            onClick={handleUserProfileMenuClick}
            id="userprofile-menu"
          >
            <NavbarDropdownMenuTrigger>
              <NavbarUserProfileTrigger
                email={userEmail}
                name={userName}
                onClick={() => handleMixPanelEventsForNav("Profile")}
                key={"user-profile-menu"}
                data-testid="user-profile-menu"
                avatar={
                  <Avatar
                    aria-label={userName || userEmail}
                    size={AvatarSizes.SMALL}
                    color={AvatarColors.NEUTRAL_600}
                  >
                    {getAvatarLetters(userName || userEmail)}
                  </Avatar>
                }
              />
            </NavbarDropdownMenuTrigger>
            {userProfileId ? (
              <UserProfileMenuItem
                icon={<User size={12} />}
                onSelect={goToUserProfile}
                text={myProfileText}
                value={"profile"}
              />
            ) : null}
            <UserProfileMenuItem
              icon={<Envelope size={12} />}
              onSelect={goToScheduledReports}
              text={intl.formatMessage({
                id: "report.mySubscriptions",
                defaultMessage: "My Scheduled Reports"
              })}
              value="my-scheduled-reports"
            />
            {isDev && (
              <UserProfileMenuItem
                icon={<Book size={12} />}
                onSelect={goToActivityLogs}
                // TODO: Add intl
                text={"Activity Logs"}
                value="activity-logs"
              />
            )}
            {canAccessInstanceSettings && (
              <UserProfileMenuItem
                icon={<Gear size={12} />}
                onSelect={goToInstanceSettings}
                text={instanceSettingsText}
                value="instance-settings"
              />
            )}
            {canUserViewAs && hasPermissionToImpersonate && (
              <UserProfileMenuItem
                icon={<Eye size={12} />}
                onSelect={() => {}}
                text={intl.formatMessage({
                  id: "loginPageInstances.viewAs",
                  defaultMessage: "View As"
                })}
                value="view-as"
              >
                <UserProfileSubMenuItemTrigger
                  icon={<Eye size={12} />}
                  text={intl.formatMessage({
                    id: "loginPageInstances.viewAs",
                    defaultMessage: "View As"
                  })}
                />
                {usersByKind.map(user => (
                  <UserProfileMenuItem
                    onSelect={() => {
                      if (user.kind === "Client") {
                        handleMixPanelEventsForNav("Impersonate Internal User");
                      } else {
                        handleMixPanelEventsForNav("Impersonate External User");
                      }
                    }}
                    text={toCapital(user.name)}
                    value={user.name}
                    key={user.name}
                  >
                    <DropdownSubMenu onOpenChange={handleSubmenuOpenChange}>
                      <DropdownSubMenuTrigger>
                        {user.name}
                      </DropdownSubMenuTrigger>
                      <ImpersonationUsersList
                        kind={user.kind}
                        isOpen={isSubmenuOpen}
                      />
                    </DropdownSubMenu>
                  </UserProfileMenuItem>
                ))}
              </UserProfileMenuItem>
            )}
            <UserProfileMenuItem
              onSelect={() => {}}
              text={logOutMessage}
              value="logout"
              preventDropdownClose // Req on logout action, if dropdown closes then it cause double redirection
            >
              <>
                <div className={dividerStyles} />
                <div onClick={handleLogout} className={logOutStyles}>
                  <Typography
                    variant={TypographyVariants.LABEL_SM}
                    color={TypographyColors.NEUTRAL_600}
                  >
                    {logOutMessage}
                  </Typography>
                </div>
              </>
            </UserProfileMenuItem>
          </NavbarUserProfile>
        </NavbarFooter>
      </Navbar>
      <MySubscriptions
        showMySubscriptionsDialog={shouldShowMySubscriptions}
        setShowMySubscriptionsDialog={setShouldShowMySubscriptions}
      />
      <InstanceSettings
        showInstanceSettingsDialog={shouldShowInstanceSettings}
        setShowInstanceSettingsDialog={setShouldShowInstanceSettings}
      />
      {!isSearchPage ? <SearchDropdown /> : null}
      {isDataObjectTypesDrawerOpen && <DataObjectTypesDrawer />}
    </>
  );
};

// Explicitly defining the width and height of the icons
// to avoid the icon size changing when the text is long
const helpIconStyles = css({
  "> svg": {
    width: "16px",
    height: "16px"
  }
});

const languageDropdownStyles = css({
  maxHeight: "25rem",
  width: "100%",
  overflowY: "auto",
  overflowX: "hidden"
});

const dividerStyles = css({
  height: "1px",
  width: "100%",
  margin: "var(--space-4) 0",
  backgroundColor: "var(--colors-neutral-400)"
});

const logOutStyles = css({
  cursor: "pointer",
  marginBottom: "var(--space-4)",
  padding: "var(--space-12) var(--space-12)",
  "&:hover": {
    backgroundColor: "var(--colors-neutral-300)"
  }
});

const NavigationSidebarWithProfiler = Sentry.withProfiler(NavigationSidebar, {
  name: "NavigationSidebar"
});

export { NavigationSidebarWithProfiler as NavigationSidebar };
