import { useGetBUV2 } from "@certa/queries/hooks/common.hook";
import { BUSINESS_UNIT } from "@certa/search/src/constants";
import type { SelectOption } from "@certa/types/src/common";
import { castArray } from "lodash-es";
import React from "react";
import { useDefinedProcessLabel } from "../../../../hooks/useDefinedProcessLabels";
import { useSearchStore } from "../../store/useSearchStore";
import { FilterDropdown } from "../FilterDropdown";

export const BusinessUnitFilter: React.FC = () => {
  const { filters, setFilters } = useSearchStore();
  const regions = castArray(filters.regionIds).map((item: string) =>
    Number(item)
  );
  const { data: businessUnitList, status: businessUnitQueryStatus } =
    useGetBUV2({
      limit: "none",
      regions
    });
  const businessUnits = businessUnitList
    ? (businessUnitList as SelectOption[])
    : [];
  const definedLabels = useDefinedProcessLabel();
  const businessUnitLabel = definedLabels[BUSINESS_UNIT];

  const options = businessUnits.map(bu => ({
    label: bu.label,
    value: bu.value.toString()
  }));

  return (
    <FilterDropdown
      label={businessUnitLabel}
      options={options}
      selectedValues={filters.businessUnitIds}
      onChange={businessUnitIds => setFilters({ businessUnitIds })}
      areOptionsLoading={businessUnitQueryStatus === "loading"}
    />
  );
};
