import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger
} from "@certa/catalyst/components/Dropdown";
import { FilterTrigger } from "./FilterTrigger";
import { Spinner3 } from "@certa/icons/components/Spinner3";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { useIntl } from "react-intl";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { useEffect, useState } from "react";

type FilterDropdownProps = {
  label: string;
  options: { label: string; value: string }[];
  selectedValues: string[];
  onChange: (value: string[]) => void;
  areOptionsLoading: boolean;
};

const FilterDropdown = (props: FilterDropdownProps) => {
  const { label, options, selectedValues, onChange, areOptionsLoading } = props;

  const intl = useIntl();
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const resetOptions = () => {
    setFilteredOptions(options);
  };

  const shouldShowEmptyState =
    !areOptionsLoading && filteredOptions.length === 0;
  const shouldShowOptions = !areOptionsLoading && filteredOptions.length > 0;
  const isActive = selectedValues.length > 0;
  const extraText =
    selectedValues.length > 0 ? `(${selectedValues.length})` : "";

  const handleItemChange = (checked: boolean, value: string) => {
    const newValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter(v => v !== value);

    onChange(newValues);
  };
  const emptyStateText = intl.formatMessage({
    id: "list.options.empty",
    defaultMessage: "No options"
  });

  return (
    <DropdownMenu
      onOpenChange={open => {
        if (open) {
          resetOptions();
        }
      }}
    >
      <DropdownMenuTrigger>
        <FilterTrigger
          label={label}
          extraText={extraText}
          isActive={isActive}
          maxWidth="11.25rem"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        width="11.25rem"
        height="12.5rem"
        shouldStopEventPropagation
        showInlineSearch
        onInlineSearch={value => {
          setFilteredOptions(
            options.filter(option =>
              option.label.toLowerCase().includes(value.toLowerCase())
            )
          );
        }}
        onClearInlineSearch={resetOptions}
      >
        {areOptionsLoading ? (
          <Stack height="5rem" justify="center" align="center">
            <Spinner3 />
          </Stack>
        ) : null}

        {shouldShowEmptyState ? (
          <Stack height="5rem" justify="center" align="center">
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {emptyStateText}
            </Typography>
          </Stack>
        ) : null}

        {shouldShowOptions
          ? filteredOptions.map(option => (
              <DropdownMenuCheckboxItem
                key={option.value}
                value={option.value}
                preventDropdownClose
                checked={selectedValues.includes(option.value)}
                onChange={checked => handleItemChange(checked, option.value)}
              >
                {option.label}
              </DropdownMenuCheckboxItem>
            ))
          : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export { FilterDropdown };
