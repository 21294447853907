import { useIntl } from "react-intl";
import type {
  WithinTheLastTimePeriod as TIME_PERIOD,
  WithinTheLastErrorCode as ErrorCode
} from "@certa/types";
import { withinTheLastMaxLimit as maxLimit } from "@certa/common/utils/withinTheLastUtils";

type TimePeriodOptionType = {
  value: TIME_PERIOD;
  label: string;
};

export const useTimePeriodOptions = (): TimePeriodOptionType[] => {
  const intl = useIntl();
  return [
    {
      value: "m",
      label: intl.formatMessage({
        id: "savedSearch.minuts",
        defaultMessage: "Minutes"
      })
    },
    {
      value: "h",
      label: intl.formatMessage({
        id: "savedSearch.hours",
        defaultMessage: "Hours"
      })
    },
    {
      value: "d",
      label: intl.formatMessage({
        id: "constraintsui.reductionConstraints.diffParamDays",
        defaultMessage: "Days"
      })
    },
    {
      value: "w",
      label: intl.formatMessage({
        id: "savedSearch.weeks",
        defaultMessage: "Weeks"
      })
    },
    {
      value: "M",
      label: intl.formatMessage({
        id: "savedSearch.months",
        defaultMessage: "Months"
      })
    }
  ];
};

export const useErrorMessageMapping = (errorCode: ErrorCode | undefined) => {
  const intl = useIntl();
  const errorMessageMapping: Record<ErrorCode, string> = {
    negativeNumberNotAllowed: intl.formatMessage({
      id: "withinTheLast.negativeNumberNotAllowed",
      defaultMessage: "A negative number cannot be used."
    }),
    decimalNumberNotAllowed: intl.formatMessage({
      id: "withinTheLast.decimalNumberNotAllowed",
      defaultMessage: "A decimal number cannot be used."
    }),
    onlyDigitsAllowed: intl.formatMessage({
      id: "withinTheLast.onlyDigitsAllowed",
      defaultMessage: "Only digits are allowed."
    }),
    maxLimitForMinute:
      intl.formatMessage({
        id: "withinTheLast.maxLimitForMinute",
        defaultMessage: "Maximum limit for minutes is: "
      }) + maxLimit.maxLimitForMinute,
    maxLimitForHours:
      intl.formatMessage({
        id: "withinTheLast.maxLimitForHours",
        defaultMessage: "Maximum limit for hours is: "
      }) + maxLimit.maxLimitForHours,
    maxLimitForDays:
      intl.formatMessage({
        id: "withinTheLast.maxLimitForDays",
        defaultMessage: "Maximum limit for days is: "
      }) + maxLimit.maxLimitForDays,
    maxLimitForWeeks:
      intl.formatMessage({
        id: "withinTheLast.maxLimitForWeeks",
        defaultMessage: "Maximum limit for weeks is: "
      }) + maxLimit.maxLimitForWeeks,
    maxLimitForMonths:
      intl.formatMessage({
        id: "withinTheLast.maxLimitForMonths",
        defaultMessage: "Maximum limit for months is: "
      }) + maxLimit.maxLimitForMonths
  };
  return errorCode ? errorMessageMapping[errorCode] : "";
};
