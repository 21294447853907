import { APIFetchV3, RequestHeaders } from "@certa/network";
import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";

type SelectableFieldOptionResponse = {
  options: { label: string | null; value: string }[];
};

type SelectableFieldOption = { label: string; value: string }[];

const getSelectableFieldOptions = async (
  kindId: string | undefined,
  fieldTag: string
): Promise<SelectableFieldOption> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `selectable-field-option?kind_id=${kindId}&field_tag=${fieldTag}`;
  return APIFetchV3<SelectableFieldOptionResponse>(url, requestOptions).then(
    response =>
      (response?.options || []).map(option => ({
        label: option.label ?? option.value,
        value: option.value
      }))
  );
};

type UseGetSelectableFieldOptionParams = {
  kindId: string | undefined;
  fieldTag: string;
  config?: UseQueryOptions<SelectableFieldOption>;
};

export const useGetSelectableFieldOption = ({
  kindId,
  fieldTag,
  config
}: UseGetSelectableFieldOptionParams) =>
  useQuery<SelectableFieldOption>({
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...config,
    queryKey: ["selectable-field-option", kindId, fieldTag],
    queryFn: () => getSelectableFieldOptions(kindId, fieldTag)
  });
