import { useRemoveSubscription } from "@certa/queries/hooks/threadSubscription.hooks";
import type { Subscription } from "@certa/types";
import { CommentObjectTypes } from "@certa/types";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import { useIntl } from "react-intl";
import { queryClient } from "@certa/queries/utils/utils";
import { ToastTypes, showToast } from "@certa/catalyst/components/Toast";
import { COPY_TEXT } from "@certa/comments/src/util/copyText";

/*
  - Why this hook?
  - This hook is used to perform optimistic update on removing subscription.
*/

export const useRemoveSubscriptionOptUpdate = () => {
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );
  const intl = useIntl();

  const { mutate: removeSubscription } = useRemoveSubscription(
    0,
    CommentObjectTypes.FIELD,
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    userId,
    {
      onMutate: async ({ threadId }: { threadId: number; enable: boolean }) => {
        // Performing optimistic update for better UX in next couple of lines
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["subscriptions"]);
        // Snapshot the previous value
        const previousSubscriptions = queryClient.getQueryData([
          "subscriptions"
        ]);
        // Optimistically update to the new value
        queryClient.setQueryData(["subscriptions"], (oldData: any) => {
          return {
            pages: oldData.pages.map((page: any) => ({
              ...page,
              results: page.results.filter(
                (subscription: Subscription) => subscription.id !== threadId
              )
            }))
          };
        });

        // This is to handle error case. When we return this, we get previous data in context in onError
        return { previousSubscriptions };
      },
      onError: (error, variables, context) => {
        let errorMessage = intl.formatMessage({
          id: "comments.errors.changeSubscriptionFailed",
          defaultMessage: COPY_TEXT.DEFAULT_MESSAGES.UNABLE_TO_UNSUBSCRIBE
        });
        if (error && error.text) {
          error.text().then((text: string) => {
            try {
              const parsedText = JSON.parse(text);
              errorMessage = parsedText.detail;
            } catch (e) {
              // Do nothing
            }
            showToast({
              title: "Error",
              description: errorMessage,
              type: ToastTypes.ERROR
            });
          });
        } else {
          showToast({
            title: "Error",
            description: errorMessage,
            type: ToastTypes.ERROR
          });
        }
        // Reverting optimistic update
        queryClient.setQueryData(
          ["subscriptions"],
          context.previousSubscriptions
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptions"]);
      }
    }
  );
  return removeSubscription;
};
