import { MAX_Y_AXIS_VALUE_LABEL_LENGTH } from "../../customAxesTick/CustomAxesTick";
import { xAxisFormatter } from "../../../utils/chartUtils";
import type { ChartData, XAxisOtherChartConfig } from "@certa/common/types";

type GetYAxesWidthMappingParams = {
  xAxisDataLabels: string[];
  dataTypes: XAxisOtherChartConfig["dataTypes"];
  labelOutputTypes: XAxisOtherChartConfig["labelOutputTypes"];
  data: Record<string, ChartData>[];
};

const MAX_Y_AXIS_WIDTH = 150;
const WIDTH_TO_MAX_Y_AXIS_VALUE_LABEL_RATIO =
  MAX_Y_AXIS_WIDTH / MAX_Y_AXIS_VALUE_LABEL_LENGTH;

export const getYAxesWidthMapping = ({
  data,
  dataTypes,
  labelOutputTypes,
  xAxisDataLabels
}: GetYAxesWidthMappingParams) => {
  const widthMapping = xAxisDataLabels.reduce(
    (mapping: Record<string, number>, label, index) => {
      let maxLength = 0;
      const formatterFunction = xAxisFormatter(
        dataTypes?.[index],
        labelOutputTypes?.[index],
        label,
        data,
        xAxisDataLabels[xAxisDataLabels.length - 1]
      );

      data.forEach(value => {
        const formattedValue = formatterFunction(value[label], index);
        maxLength = Math.max(maxLength, formattedValue.length);
      });

      mapping[label] =
        maxLength > MAX_Y_AXIS_VALUE_LABEL_LENGTH
          ? MAX_Y_AXIS_WIDTH
          : WIDTH_TO_MAX_Y_AXIS_VALUE_LABEL_RATIO * maxLength;

      const isLastXAxis = xAxisDataLabels.length === index + 1;
      if (isLastXAxis) {
        // adding extra padding to the last x-axis width, to get the space for the y-axis label.
        mapping[label] = mapping[label] + 5;
      }
      return mapping;
    },
    {}
  );
  return widthMapping;
};
