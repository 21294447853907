import React, { useRef, useLayoutEffect, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import type { Instance as PopperInstance } from "@popperjs/core";
import { createPopper } from "@popperjs/core";

import { classNames } from "../../utils/common";
import { Typography, TypographyVariants } from "../Typography/Typography";

import styles from "./Tooltip.module.css";

// To avoid creating a different test snapshots when using unique ids (like nanoid)
let _tooltipCount = 0;

// Based on the tooltip position
const TOOLTIP_SKIDDING = 0;
const TOOLTIP_DISTANCE = 8;

const TOOLTIP_SHOW_DELAY = 100; // Milliseconds

export const SHOW_TOOLTIP_EVENTS = ["mouseenter", "focusin"];
export const HIDE_TOOLTIP_EVENTS = ["mouseleave", "focusout"];

export enum TooltipVariants {
  "LIGHT" = "Light",
  "DARK" = "Dark",
  "RED" = "Red",
  "LIGHT_BRAND" = "LightBrand"
}

export enum TooltipPlacements {
  TOP_START = "top-start",
  TOP = "top",
  TOP_END = "top-end",
  BOTTOM_START = "bottom-start",
  BOTTOM = "bottom",
  BOTTOM_END = "bottom-end",
  RIGHT_START = "right-start",
  RIGHT = "right",
  RIGHT_END = "right-end",
  LEFT_START = "left-start",
  LEFT = "left",
  LEFT_END = "left-end"
}

export type TooltipProps = {
  targetClassName?: string;
  /**
   * @default true
   * @description
   * - If true, tooltip will be enabled by default.
   * - If false, tooltip will not be disabled by default.
   * - This is not to control the visibility of the tooltip.
   * But to enable/disable the tooltip.
   * */
  show?: boolean;
  placement?: TooltipPlacements;
  variant?: TooltipVariants;
  showArrow?: boolean;
  content: string | React.ReactNode;
  children: React.ReactElement;
  maxWidth?: string;
  strategy?: "absolute" | "fixed";
  /**
   * @default false
   * @description
   *  If true, hides the tooltip content from screen readers when the associated element is focused.
   *  Use case: when the tooltip content is already read by screen readers when the element is focused.
   *  For example: when the tooltip content is a label for the element.
   */
  hideTooltipFromScreenReaders?: boolean;
};

export const Tooltip = (props: TooltipProps) => {
  const {
    targetClassName,
    show: shouldShow = true,
    content,
    children,
    maxWidth,
    showArrow: shouldShowArrow = false,
    placement = TooltipPlacements.TOP,
    variant = TooltipVariants.DARK,
    strategy = "fixed",
    hideTooltipFromScreenReaders: shouldHideTooltipFromScreenReaders = false
  } = props;

  const tooltipTargetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipInstance = useRef<PopperInstance>();

  // Due to unpredictable order of Focus and Mouse events, there was
  // a condition in which tooltip show was called after hide but hide
  // was not called after that. To fix this, we are using timeouts to
  // to cancel any pending timeouts
  const showTooltipTimeoutRef = useRef<NodeJS.Timeout | null>();
  const hideTooltipTimeoutRef = useRef<NodeJS.Timeout | null>();

  // tooltip id is used for a11y - "aria-describedby"
  const tooltipId = useMemo(() => "tooltip-" + ++_tooltipCount, []);

  useLayoutEffect(() => {
    if (tooltipTargetRef.current && tooltipRef.current) {
      tooltipInstance.current = createPopper(
        tooltipTargetRef.current,
        tooltipRef.current,
        {
          strategy,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [TOOLTIP_SKIDDING, TOOLTIP_DISTANCE]
              }
            },
            {
              name: "arrow",
              options: {
                // https://popper.js.org/docs/v2/modifiers/arrow/#padding
                padding: 4
              }
            }
          ],
          placement
        }
      );
    }
  }, [placement, strategy]);

  const hideTooltip = useCallback(() => {
    if (showTooltipTimeoutRef.current) {
      clearTimeout(showTooltipTimeoutRef.current);
      showTooltipTimeoutRef.current = null;
    }

    hideTooltipTimeoutRef.current = setTimeout(() => {
      const tooltipContentElement = tooltipRef.current;

      tooltipContentElement?.removeAttribute("data-show");

      // Disable the event listeners
      tooltipInstance.current?.setOptions(options => ({
        ...options,
        modifiers: options.modifiers?.length
          ? [...options.modifiers, { name: "eventListeners", enabled: false }]
          : [{ name: "eventListeners", enabled: false }]
      }));
    }, TOOLTIP_SHOW_DELAY);
  }, []);

  useLayoutEffect(() => {
    const tooltipContentElement = tooltipRef.current;
    if (!shouldShow) {
      tooltipContentElement?.setAttribute("data-popper-reference-hidden", "");
    } else {
      const tooltipElement = tooltipTargetRef.current;

      const showTooltip = () => {
        if (hideTooltipTimeoutRef.current) {
          clearTimeout(hideTooltipTimeoutRef.current);
          hideTooltipTimeoutRef.current = null;
        }

        showTooltipTimeoutRef.current = setTimeout(() => {
          tooltipContentElement?.setAttribute("data-show", "");

          // Enable the event listeners
          tooltipInstance.current?.setOptions(options => ({
            ...options,
            modifiers: options.modifiers?.length
              ? [
                  ...options.modifiers,
                  { name: "eventListeners", enabled: true }
                ]
              : [{ name: "eventListeners", enabled: true }]
          }));

          // Update its position
          tooltipInstance.current?.update();
        }, TOOLTIP_SHOW_DELAY);
      };

      SHOW_TOOLTIP_EVENTS.forEach(showTooltipEvent => {
        tooltipElement?.addEventListener(showTooltipEvent, showTooltip);
      });

      HIDE_TOOLTIP_EVENTS.forEach(hideTooltipEvent => {
        tooltipElement?.addEventListener(hideTooltipEvent, hideTooltip);
      });

      return () => {
        SHOW_TOOLTIP_EVENTS.forEach(showTooltipEvent => {
          tooltipElement?.removeEventListener(showTooltipEvent, showTooltip);
        });

        HIDE_TOOLTIP_EVENTS.forEach(hideTooltipEvent => {
          tooltipElement?.removeEventListener(hideTooltipEvent, hideTooltip);
        });

        clearTimeout(showTooltipTimeoutRef.current!);
        clearTimeout(hideTooltipTimeoutRef.current!);
      };
    }
  }, [hideTooltip, shouldShow]);

  useLayoutEffect(() => {
    const tooltipTargetElement = tooltipTargetRef.current;

    const onKeyDownEscapeHandler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        hideTooltip();
      }
    };

    tooltipTargetElement?.addEventListener("keydown", onKeyDownEscapeHandler);

    return () => {
      tooltipTargetElement?.removeEventListener(
        "keydown",
        onKeyDownEscapeHandler
      );
    };
  }, [hideTooltip]);

  const rootElement = document.getElementById("catalyst-tooltips-wrapper");

  return (
    <>
      <div
        ref={tooltipTargetRef}
        className={classNames(
          styles.catalystTooltipTargetWrapper,
          targetClassName
        )}
      >
        {React.cloneElement(children, {
          "aria-describedby": shouldHideTooltipFromScreenReaders
            ? null
            : tooltipId
        })}
      </div>
      {ReactDOM.createPortal(
        <div
          id={tooltipId}
          role="tooltip"
          className={classNames({
            [styles.catalystTooltip]: true,
            [styles["catalystTooltip" + variant]]: true
          })}
          ref={tooltipRef}
          style={{ maxWidth }}
        >
          <Typography variant={TypographyVariants.LABEL_SM_BOLD}>
            {content}
          </Typography>
          {shouldShowArrow && (
            <div
              aria-describedby=""
              className={styles.catalystTooltipArrow}
              data-popper-arrow
            ></div>
          )}
        </div>,
        rootElement || document.body
      )}
    </>
  );
};
