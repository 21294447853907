import { Drawer, DrawerHeader } from "@certa/catalyst/components/Drawer";
import { CommentsNew } from "../components/commentsNew/CommentsNew";
import { DrawerTitle } from "../components/DrawerTitle/DrawerTitle";
import type { CommentChannel, ConversationThread } from "@certa/types";

import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { getDrawerPositionInMobile } from "@certa/common/utils/getPositionUtils";
import { FullHeightSpinner } from "@certa/common/components/FullHeightSpinner";
import type { ConversationCommsModel } from "@certa/queries/queries/comms";

type CommentsDrawerViewProps = {
  shouldShow: boolean;
  status: string;
  channels: CommentChannel[] | undefined;
  onStepClick?: (groupId: number, stepId: number, workflowId: number) => void;
  isReadOnly?: boolean;
  extra?: any;
  threadId?: number;
  header: React.ReactChild;
  onClose: () => void;
  activeChannel?: any;
  setActiveChannel: (channel: any) => void;
  threadsComms?: ConversationCommsModel;
  threadsCommsStatus?: "loading" | "error" | "idle" | "success";
  messageId?: string;
  defaultStatus?: any;
  defaultAdjudicationCode?: any;
  adjudicationRiskCodes?: string[];
  workflowFamily?: any;
  isMultiChannel: boolean;
  multiChannelWithTabs: boolean;
  activeFieldName?: string;
  activeConversationThreadComms: ConversationThread | null;
  setActiveConversationThreadComms: (
    conversationThread: ConversationThread | null
  ) => void;
};

export const CommentsDrawerView = ({
  shouldShow,
  status,
  channels,
  onStepClick,
  isReadOnly,
  extra,
  threadId,
  header,
  onClose,
  activeChannel,
  setActiveChannel,
  threadsComms,
  threadsCommsStatus,
  messageId,
  defaultStatus,
  defaultAdjudicationCode,
  adjudicationRiskCodes,
  workflowFamily,
  isMultiChannel,
  multiChannelWithTabs,
  activeFieldName,
  activeConversationThreadComms,
  setActiveConversationThreadComms
}: CommentsDrawerViewProps) => {
  const { isMobileResolution } = useScreenResolution();

  return (
    <Drawer
      width={isMobileResolution ? "100%" : "45rem"}
      height={isMobileResolution ? "unset" : "100%"}
      getPositioning={
        isMobileResolution ? getDrawerPositionInMobile : () => ({})
      }
      title={""}
      aria-label={"Comments drawer"}
      show={shouldShow}
      padding={"0"}
      onClose={onClose}
      showCloseIcon={false}
      onBack={undefined}
      canShowDimmer={!isMobileResolution}
    >
      {status !== "loading" && (
        <DrawerHeader>
          <DrawerTitle
            isMultiChannel={!!isMultiChannel}
            channels={channels}
            workflowFamily={workflowFamily}
            fieldName={activeFieldName}
            activeChannel={activeChannel}
            activeConversationThreadComms={activeConversationThreadComms}
            setActiveChannel={setActiveChannel}
            setActiveConversationThreadComms={setActiveConversationThreadComms}
            onClose={onClose}
            showBackButton={isMultiChannel}
          />
        </DrawerHeader>
      )}
      {status === "loading" || channels === undefined ? (
        <FullHeightSpinner />
      ) : (
        <CommentsNew
          channels={channels}
          status={status}
          onStepClick={onStepClick}
          readOnly={!!isReadOnly}
          threadsComms={threadsComms}
          threadsCommsStatus={threadsCommsStatus}
          visible={shouldShow}
          multiChannel={isMultiChannel}
          extra={extra}
          defaultThreadId={threadId}
          header={header}
          onClose={onClose}
          activeChannel={activeChannel}
          setActiveChannel={setActiveChannel}
          activeConversationThreadComms={activeConversationThreadComms}
          setActiveConversationThreadComms={setActiveConversationThreadComms}
          messageId={messageId}
          defaultStatus={defaultStatus}
          defaultAdjudicationCode={defaultAdjudicationCode}
          adjudicationRiskCodes={adjudicationRiskCodes}
          workflowFamily={workflowFamily}
          multiChannelWithTabs={multiChannelWithTabs}
        />
      )}
    </Drawer>
  );
};
