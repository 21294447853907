import React, { useMemo, useState, useRef } from "react";
import { useSubscriptions } from "@certa/queries/hooks/threadSubscription.hooks";
import {
  useGetSubscriptionsCommsInfiniteQuery,
  type SubscriptionComms
} from "@certa/queries/queries/comms";
import type { Subscription } from "@certa/types";
import { useMentionsContext } from "../../MentionsContext";
import type { CommentData, CommentDataComms } from "../../MentionsContext";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { useNavigate, useLocation } from "react-router";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { SubscriptionItem } from "./SubscriptionItem";
import { Spinner3 } from "@certa/icons/components/Spinner3";
import { useIntl } from "react-intl";
import {
  MixPanelActions,
  MixPanelEvents
} from "../../../../../main/src/js/_helpers/mixpanel";
import {
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common/utils/routes";
import { SomethingWentWrong } from "@certa/common/components/SomethingWentWrong";
import { ListWrapper } from "@certa/common/components/ListWrapper";
import { LoadingAndEmptyStatesWrapper } from "@certa/common/components/LoadingAndEmptyStatesWrapper";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import {
  SubscriptionItemComms,
  type SubscriptionItemHandle
} from "./SubscriptionItemComms";
import { useObjectDetailsMap } from "../../hooks/useObjectDetailsMap";

// Type for subscription refs map
type SubscriptionRefsMap = Record<
  string,
  React.RefObject<SubscriptionItemHandle>
>;

const getKey = (subscription: Subscription) => subscription.id.toString();
const getLabel = (subscription: Subscription) => subscription.name;

const renderSubscriptionItem = (subscription: Subscription) => (
  <SubscriptionItem subscription={subscription} />
);

const getKeyComms = (subscription: SubscriptionComms) =>
  subscription.id?.toString();
const getLabelComms = (subscription: SubscriptionComms) =>
  subscription.conversation.name;

const getObjectIdAsPerType = (subscription: Subscription) => {
  const { type } = subscription;
  switch (type) {
    case "workflow":
      return subscription?.workflow?.id;
    case "integrationresult":
      return subscription?.integrationResult?.uid;
    case "step":
      return subscription?.step?.id;
    case "field":
      return subscription?.field?.id;
    default:
      return null;
  }
};

// This file is identical to SubscriptionItemComms.tsx.
// It uses krypton endpoints tho instead of comms.
// Once we are live with comms, we can remove the krypton version.
const SubscriptionsContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const { updateMode, updateCommentData } = useMentionsContext();

  const [workflowId, setWorkflowId] = useState<number | null>(null);

  // Store refs for all subscription items
  const subscriptionRefsMap = useRef<SubscriptionRefsMap>({});

  const {
    data: subscriptionsListDataComms,
    isFetching: isFetchingComms,
    hasNextPage: hasNextPageComms,
    fetchNextPage: fetchNextPageComms
  } = useGetSubscriptionsCommsInfiniteQuery();
  const { status, data, isFetching, hasNextPage, fetchNextPage } =
    useSubscriptions();

  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(workflowId);

  const subscriptions = data?.pages.flatMap(page => page.results);

  const subscriptionsComms = useMemo(() => {
    return (
      subscriptionsListDataComms?.pages.flatMap(page => page.results) || []
    );
  }, [subscriptionsListDataComms?.pages]);

  const { isItemLoading, getObjectDetailsForItem } =
    useObjectDetailsMap(subscriptionsComms);

  const intl = useIntl();
  const noSubscriptionsMessage = intl.formatMessage({
    id: "notifications.noSubscriptionsMessage",
    defaultMessage: "You have no subscriptions!"
  });

  const noSubscriptionsSecondaryMessage = intl.formatMessage({
    id: "notifications.noSubscriptionsSecondaryMessage",
    defaultMessage:
      "Comment threads you subscribe to will appear here for quick access"
  });

  const loadingSubscriptionsMessage = intl.formatMessage({
    id: "notifications.loadingSubscriptionsMessage",
    defaultMessage: "Loading your subscriptions, please wait..."
  });

  if (status === "error") return <SomethingWentWrong />;

  const handleOnSubscriptionClick = (subscription: Subscription) => {
    MixPanelActions.track(
      MixPanelEvents.notificationEvents
        .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_SUBSCRIBED_THREAD
    );

    setWorkflowId(subscription?.workflow?.id);
    if (processDetailsStatus === "loading") {
      return;
    }
    const processName = subscription?.workflow?.name;

    // TODO: fix this to use proper constants REPORT_KIND_NAME and DASHBOARD_KIND_NAME
    // currently using them from dashboards package throws error
    const isDashboardMention = processName === "report_dashboard";
    const isReportMention = processName === "report_workflow";

    const objectId = getObjectIdAsPerType(subscription) || 0;
    const { type: objectType } = subscription;

    const [, type, id] = location?.pathname?.split("/") || [];
    const subscriptionProcessId = subscription?.workflow?.id;
    const subscriptionStepGroupId = subscription?.stepGroup?.id;
    const stepId = subscription?.step?.id;
    const commentData: CommentData = {
      objectId,
      objectType,
      threadId: subscription?.id,
      processId: subscription?.workflow?.id,
      processName: subscription?.workflow?.name,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        subscriptionProcessId &&
        subscriptionProcessId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        navigate(
          `${getDashboardsRoute(
            subscriptionProcessId
          )}?redirectFromComments=true`
        );
        return;
      }
      if (isReportMention) {
        if (isReportMention) {
          navigate(
            `${getFullScreenReportRoute(
              subscriptionProcessId
            )}?redirectFromComments=true`
          );
          return;
        }
      }

      navigate(
        `${getProcessDetailRoute(
          subscriptionProcessId
        )}?group=${subscriptionStepGroupId}&step=${stepId}&redirectFromComments=true&redirectCommentData=${JSON.stringify(
          commentData
        )}`
      );
    }

    updateCommentData(commentData);

    // changing drawer mode to comments drawer
    updateMode("comments");
  };

  //TODO get proper hierarchy paths and handle click on fields and steps
  const handleOnSubscriptionClickComms = (subscription: SubscriptionComms) => {
    MixPanelActions.track(
      MixPanelEvents.notificationEvents
        .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_SUBSCRIBED_THREAD
    );

    const subscriptionId = subscription.id?.toString();
    const subscriptionRef = subscriptionId
      ? subscriptionRefsMap.current[subscriptionId]
      : null;

    // Try to get process details from the ref
    const subscriptionRefData = subscriptionRef?.current?.getDetails();
    if (
      !subscriptionRefData ||
      subscriptionRefData.processDetailsStatus === "loading"
    )
      return;

    const processName = subscriptionRefData.processDetails?.definition?.name;

    // TODO: fix this to use proper constants REPORT_KIND_NAME and DASHBOARD_KIND_NAME
    // currently using them from dashboards package throws error
    const isDashboardMention = processName === "report_dashboard";
    const isReportMention = processName === "report_workflow";

    const { objectId, objectType } = subscription?.conversation;

    const [, type, id] = location?.pathname?.split("/") || [];
    const subscriptionProcessId = subscription?.conversation?.kryptonWorkflowId;

    const commentData: CommentDataComms = {
      objectId,
      objectType,
      conversationId: subscription?.conversationId,
      processId: subscription?.conversation?.kryptonWorkflowId,
      workflowFamily:
        isDashboardMention || isReportMention
          ? subscriptionRefData?.processDetails?.workflowFamily || []
          : []
    };

    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        subscriptionProcessId &&
        subscriptionProcessId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        navigate(
          `${getDashboardsRoute(
            subscriptionProcessId
          )}?redirectFromComments=true`
        );
        return;
      }
      if (isReportMention) {
        navigate(
          `${getFullScreenReportRoute(
            subscriptionProcessId
          )}?redirectFromComments=true`
        );
        return;
      }

      const redirectCommentData = encodeURIComponent(
        JSON.stringify(commentData)
      );

      const isObjectDetailsLoading = isItemLoading(subscription);
      const objectDetails = getObjectDetailsForItem(subscription);

      // If we have object details, include them in the URL
      if (!isObjectDetailsLoading && objectDetails) {
        navigate(
          `${getProcessDetailRoute(
            subscriptionProcessId
          )}?group=${objectDetails.stepGroupId}&step=${objectDetails.stepId}&redirectFromComments=true&redirectCommentData=${redirectCommentData}`
        );
      } else {
        // Otherwise navigate without the group and step params
        navigate(
          `${getProcessDetailRoute(
            subscriptionProcessId
          )}?redirectFromComments=true&redirectCommentData=${redirectCommentData}`
        );
      }
    }

    updateCommentData(commentData);

    // changing drawer mode to comments drawer
    updateMode("comments");
  };

  const renderSubscriptionItemComms = (subscription: SubscriptionComms) => {
    // Create and store ref for this subscription
    const id = subscription.id?.toString();
    if (id && !subscriptionRefsMap.current[id]) {
      subscriptionRefsMap.current[id] =
        React.createRef<SubscriptionItemHandle>();
    }

    return (
      <SubscriptionItemComms
        ref={id ? subscriptionRefsMap.current[id] : undefined}
        subscription={subscription}
        isObjectDetailLoading={isItemLoading(subscription)}
        objectDetails={getObjectDetailsForItem(subscription)}
      />
    );
  };

  if (isCommsNewEndpointEnabled) {
    return (
      <Stack direction="vertical">
        {subscriptionsComms && subscriptionsComms.length > 0 ? (
          <ListWrapper
            options={subscriptionsComms}
            aria-label="subcriptions list box"
            getKey={getKeyComms}
            getLabel={getLabelComms}
            onClick={subscriptionItem =>
              handleOnSubscriptionClickComms(subscriptionItem)
            }
            render={renderSubscriptionItemComms}
            loadMore={fetchNextPageComms}
            hasMore={!!hasNextPageComms}
          />
        ) : (
          <LoadingAndEmptyStatesWrapper
            primaryMessage={!isFetchingComms ? noSubscriptionsMessage : ""}
            secondaryMessage={
              !isFetchingComms
                ? noSubscriptionsSecondaryMessage
                : loadingSubscriptionsMessage
            }
            secondaryIcon={isFetchingComms ? <Spinner3 size={12} /> : undefined}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="vertical">
      {subscriptions && subscriptions.length > 0 ? (
        <ListWrapper
          options={subscriptions}
          aria-label="subscriptions list box"
          getKey={getKey}
          getLabel={getLabel}
          onClick={subscriptionItem =>
            handleOnSubscriptionClick(subscriptionItem)
          }
          render={renderSubscriptionItem}
          loadMore={fetchNextPage}
          hasMore={hasNextPage || false}
        />
      ) : (
        <LoadingAndEmptyStatesWrapper
          primaryMessage={!isFetching ? noSubscriptionsMessage : ""}
          secondaryMessage={
            !isFetching
              ? noSubscriptionsSecondaryMessage
              : loadingSubscriptionsMessage
          }
          secondaryIcon={isFetching ? <Spinner3 size={12} /> : undefined}
        />
      )}
    </Stack>
  );
};

export { SubscriptionsContainer };
