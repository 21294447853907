import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Button as AntButton } from "antd";
import { Caret } from "@certa/icons/components/Caret";
import { Spinner } from "@certa/icons/components/Spinner";
import type { ButtonProps } from "./types";
import {
  getButtonSizeStyles,
  getButtonFontStyles,
  getButtonIconStyles,
  getButtonDisclosureStyles
} from "./utils";
import { Text } from "../Typography/Text";
import { Stack } from "../Stack";

const ButtonCustom: FC<ButtonProps> = props => {
  const {
    size,
    icon,
    iconAsSuffix = false,
    type,
    disclosure,
    children,
    loading,
    disabled,
    className,
    destruct,
    success,
    iconOnly,
    isActive,
    hideRipple,
    ...otherProps
  } = props;
  return (
    <AntButton
      type={type}
      disabled={disabled}
      loading={loading}
      className={
        className +
        (loading ? " button-is-loading" : "") +
        (disabled ? " button-is-disabled" : "") +
        (destruct ? " button-is-destruct" : "") +
        (success ? " button-is-success" : "") +
        (iconOnly && icon
          ? ` button-is-icon ${
              iconOnly === "circular" ? " button-circular" : ""
            } ${iconOnly === "square" ? " button-square" : ""}`
          : "") +
        (isActive ? " button-is-active" : "")
      }
      {...otherProps}
    >
      <Stack align="center" justify="center">
        {loading && <Spinner className="button-spinner" />}
        {icon && !iconAsSuffix && <Text className="button-icon">{icon}</Text>}
        <Text className="button-text">{children}</Text>
        {icon && iconAsSuffix && (
          <Text className="button-icon button-icon-suffix">{icon}</Text>
        )}
        {disclosure && (
          <Caret
            className="button-disclosure"
            style={{
              transform:
                disclosure === "open" ? "rotate(180deg)" : "rotate(0deg)"
            }}
            data-testid="buttonCaretIcon"
          />
        )}
      </Stack>
    </AntButton>
  );
};

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Button } from '@certa/catalyst';
 */
export const Button = styled(ButtonCustom)`
  &.ant-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border-radius: var(--small-border-radius) !important;
    border: none;
    box-shadow: none;
    text-shadow: none;
    ${props => getButtonFontStyles(props.size, props.type)}
    line-height: unset;

    ${props =>
      props.hideRipple &&
      css`
        &[ant-click-animating-without-extra-node]:after {
          display: none;
        }
      `}
    &[ant-click-animating-without-extra-node]:after {
      --antd-wave-shadow-color: var(--colors-certa-brand-400);
    }
    &.button-is-destruct[ant-click-animating-without-extra-node]:after {
      --antd-wave-shadow-color: var(--red);
    }
    &.button-is-sucess[ant-click-animating-without-extra-node]:after {
      --antd-wave-shadow-color: var(--green);
    }

    &:focus-visible {
      box-shadow:
        0 0 0 1px #fff,
        0 0 0 3px var(--colors-certa-brand-300) !important;
    }
    & .button-icon {
      margin-right: ${props => (props.children ? "var(--s2)" : "var(--s00)")};
      svg {
        display: block !important;
      }
    }
    & .button-icon-suffix {
      margin-left: ${props => (props.children ? "var(--s2)" : "var(--s00)")};
    }
    & .button-text,
    & .button-icon {
      font-size: inherit;
      line-height: unset;
      font-weight: inherit;
      color: inherit;
      display: inline-flex;
      align-items: center;
    }
    & .button-spinner,
    & .button-icon > span,
    & .button-icon > svg {
      color: inherit;
      width: 1em;
      height: 1em;
      ${props => getButtonIconStyles(props.size)};
    }
    & .button-spinner {
      position: absolute;
    }
    & .button-disclosure {
      width: 1em;
      height: 1em;
      font-size: var(--h2-regular-font-size);
      ${props => getButtonDisclosureStyles(props.size)};
    }
    & .ant-btn-loading-icon {
      display: none !important;
    }
    &.ant-btn-loading::before {
      background: transparent;
    }
    &.button-is-disabled.ant-btn-loading::before {
      background: transparent;
    }
    &.button-is-loading {
      & .button-icon,
      & .button-text,
      & .button-disclosure {
        visibility: hidden;
      }
    }

    /*** Size ***/
    ${props => getButtonSizeStyles(props.size)}

    /*** Types ***/
    /* Primary */
    &.ant-btn-primary {
      color: var(--neutral-0);
      background: var(--colors-certa-brand-400);
      &:hover {
        background: var(--colors-certa-brand-500);
      }
      &:focus-visible {
        color: var(--neutral-0);
        background: var(--colors-certa-brand-400);
      }
      &:active,
      &.button-is-active {
        background: var(--colors-certa-brand-600);
      }
      & .button-disclosure {
        color: var(--colors-certa-brand-200);
      }
      &.button-is-destruct {
        color: var(--neutral-0);
        background: var(--red);
        &:hover {
          color: var(--neutral-0);
          background: var(--red-d10);
        }
        &:active,
        &.button-is-active {
          color: var(--neutral-0);
          background: var(--red-d15);
        }
        &:disabled,
        &.button-is-disabled {
          color: var(--neutral-70);
          background: var(--red-40);
        }
      }
      &:disabled,
      &.button-is-disabled {
        background: var(--neutral-20);
        color: var(--neutral-70);

        .button-disclosure {
          color: var(--neutral-70);
        }
      }
    }
    /* Secondary */
    &.ant-btn-ghost {
      color: var(--colors-certa-brand-400);
      background-color: var(--neutral-0);
      border: 1px solid var(--colors-certa-brand-400);
      &:hover {
        color: var(--colors-certa-brand-400);
        background: var(--colors-certa-brand-200);
      }
      &:focus-visible {
        background: var(--neutral-0);
      }
      &:active,
      &.button-is-active {
        color: var(--colors-certa-brand-300);
        background: var(--neutral-0);
      }
      &:disabled,
      &.button-is-disabled {
        color: var(--neutral-70);
        background: transparent;
        border: var(--s0) solid var(--neutral-50);
      }
    }
    /* Tertiary */
    &.ant-btn-default {
      color: var(--colors-certa-brand-300);
      background: transparent;
      &:hover {
        color: var(--colors-certa-brand-400);
        background: var(--colors-certa-brand-200);
      }
      &:focus-visible {
        color: var(--colors-certa-brand-300);
        background: transparent;
      }
      &:active,
      &.button-is-active {
        color: var(--colors-certa-brand-400);
        background: transparent;
      }
      &.button-is-destruct {
        color: var(--red);
        background: transparent;
        &:hover {
          color: var(--red);
          background: var(--red-40);
        }
        &:active,
        &.button-is-active {
          color: var(--red);
          background: transparent;
        }
        &:disabled,
        &.button-is-disabled {
          color: var(--neutral-70);
          background: transparent;
        }
      }
      &.button-is-success {
        color: var(--green);
        background: transparent;
        &:hover {
          color: var(--green);
          background: var(--green-40);
        }
        &:active,
        &.button-is-active {
          color: var(--green);
          background: transparent;
        }
        &:disabled,
        &.button-is-disabled {
          color: var(--neutral-70);
          background: transparent;
        }
      }
      &:disabled,
      &.button-is-disabled {
        color: var(--neutral-70);
        background: transparent;
      }
    }
    /* Plain */
    &.ant-btn-link {
      color: var(--colors-certa-brand-300);
      background: transparent;
      &:hover {
        color: var(--colors-certa-brand-400);
        background: transparent;
      }
      &:focus-visible {
        color: var(--colors-certa-brand-300);
        background: transparent;
      }
      &:active,
      &.button-is-active {
        color: var(--colors-certa-brand-500);
        background: transparent;
      }
      &:disabled,
      &.button-is-disabled {
        color: var(--colors-certa-brand-200);
        background: transparent;
      }
    }

    &.button-is-icon {
      padding: var(--s1);
      line-height: 0;
      height: auto;
      & .button-icon {
        margin-right: var(--s00);
      }
      &.button-circular {
        border-radius: 50% !important;
      }
      &.button-square {
        border-radius: 0 !important;
      }
    }
  }
  --label: Button;
`;

Button.defaultProps = {
  type: "default", // tertiary
  size: "large",
  disabled: false,
  loading: false,
  destruct: false,
  block: false,
  iconOnly: false,
  hideRipple: false
};
