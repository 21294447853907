import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import type { SearchItem } from "../GlobalSearchNext.schema";

export const transformHighlights = (
  result: ModelledAPIResponse.SearchResult
): {
  text: string;
  matches: string[];
  shouldShow: boolean;
} => {
  if (result.type !== "file")
    return { text: "", matches: [], shouldShow: false };

  // In case of documents matches, if a part of document was matched
  // we need to show first light of the match in search results (this is)
  // not case with workflows and fields
  const text = (result.highlights?.[0] || "").replace(/<strong>/g, "");
  return {
    text,
    matches: result.highlights || [],
    shouldShow: Boolean(
      result.document_data_match && result.highlights?.length > 0
    )
  };
};

export const transformSearchResult = (
  result: ModelledAPIResponse.SearchResult
): SearchItem | null => {
  if (result.type === "workflow") {
    return {
      id: result.id,
      type: "workflow",
      title: result.name,
      description: result.kind?.name,
      logo: result.logo ?? undefined
    };
  }

  if (result.type === "file") {
    const kindName = result.workflow.kind?.name;
    const workflowName = result.workflow.name;
    const description =
      kindName && workflowName ? `${kindName}: ${workflowName}` : undefined;

    return {
      id: result.id,
      type: "file",
      title: result.fileName,
      description,
      workflowId: result.workflow.id,
      stepId: result.step.id
    };
  }

  return null;
};
