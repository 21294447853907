export const GRAND_SUB_TOTAL_LABEL = "Grand subtotal";
export const GRAND_AVERAGE_LABEL = "Grand average";

export const SUB_TOTAL_LABEL = "Sub total";
export const AVERAGE_LABEL = "Average";
export const Y_AXIS_GROUP_LABEL = "yAxisGroup";

export const FIRST_COLUMN_WIDTH = 200;
export const MAX_COLUMN_WIDTH = 100;
export const DEFAULT_HEIGHT = 392;

export const SUB_TOTAL_INDEX = 0;
export const AVERAGE_INDEX = 1;
