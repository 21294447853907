import { colord } from "colord";
import type { ScaleLinear } from "d3-scale";
import {
  commonXAxisLabel,
  ONE,
  INDEX_ZERO,
  ZERO
} from "@certa/common/constants";
import type {
  OtherChartConfigColors,
  ActiveColorType,
  SingleToneColorType,
  RGBColorString
} from "@certa/common/types";

export const generateRandomColor = (index: number) => {
  const colors = [
    "var(--brand)",
    "var(--orange)",
    "var(--green)",
    "var(--red)",
    "var(--teal)",
    "var(--purple)",
    "var(--yellow)",
    "var(--cyan)",
    "var(--lime)",
    "var(--indigo)",
    "var(--amber)",
    "var(--lightBlue)",
    "var(--lightGreen)",
    "var(--brown)",
    "var(--grey)",
    "var(--blueGrey)",
    "var(--limeGreen)",
    "var(--salmon)",
    "var(--skyBlue)",
    "var(--lavender)",
    "var(--maroon)",
    "var(--olive)",
    "var(--deepPurple)",
    "var(--deepOrange)",
    "var(--pink)"
  ];
  return colors[index % (colors.length - ONE)];
};

export const getColorSource = (
  legendsLabel: string[],
  xAxisKey: string,
  colors?: OtherChartConfigColors
) => {
  const isCycleTimeReport = Object.values(commonXAxisLabel).includes(xAxisKey);
  const defaultActiveColorType: ActiveColorType = isCycleTimeReport
    ? "custom"
    : "singleTone";
  const colorSourceSwitch: Record<
    ActiveColorType,
    SingleToneColorType | undefined
  > = {
    singleTone: colors?.singleTone,
    // Due to the fact that the cycle Time and Stack bar chart is the only one that supports custom colors,
    // and multiple Y-axises cannot be used there (as of now), [INDEX_ZERO] is being used bellow.
    // * for the PIE chart custom colors and multiple Y-axises are possible
    // but for that we are not using useGetLegendDataCommon function.
    custom: colors?.custom?.[Object.keys(colors?.custom ?? {})?.[INDEX_ZERO]],
    // to stop unnecessary calling of function
    spectrum:
      colors?.activeColorType === "spectrum"
        ? generateColorsFronSpectrum(
            colors?.spectrum?.[
              Object.keys(colors?.spectrum ?? {})?.[INDEX_ZERO]
            ],
            legendsLabel
          )
        : {}
  };
  return (
    colorSourceSwitch[colors?.activeColorType ?? defaultActiveColorType] ?? {}
  );
};

const generateColorsFronSpectrum = (
  colorsArray: RGBColorString[] | undefined,
  labels: string[]
) => {
  if (!colorsArray || colorsArray?.length === ZERO) {
    return {};
  }
  const incrementBy =
    labels.length === ONE ? ZERO : ONE / (labels.length - ONE);
  const firstRGBAColor = colord(colorsArray[INDEX_ZERO]).toRgb();
  const lastRGBAColor = colord(colorsArray[colorsArray.length - ONE]).toRgb();
  const rDiff = lastRGBAColor.r - firstRGBAColor.r;
  const gDiff = lastRGBAColor.g - firstRGBAColor.g;
  const bDiff = lastRGBAColor.b - firstRGBAColor.b;
  const aDiff = lastRGBAColor.a - firstRGBAColor.a;

  return labels.reduce<Record<string, RGBColorString>>(
    (spectrumColorsByLabel, label, index) => {
      const multiplier = incrementBy * index;
      spectrumColorsByLabel[label] = `rgba(${
        firstRGBAColor.r + rDiff * multiplier
      }, ${firstRGBAColor.g + gDiff * multiplier}, ${
        firstRGBAColor.b + bDiff * multiplier
      }, ${firstRGBAColor.a + aDiff * multiplier})`;
      return spectrumColorsByLabel;
    },
    {}
  );
};

export const getColorSourceForPieChart = (
  legendsLabel: string[],
  colors: OtherChartConfigColors | undefined,
  yAxisLabel: string,
  scaleOpacity: ScaleLinear<number, number, never>,
  isCycleTimeReport: boolean
) => {
  const colorSourceSwitch: Record<
    ActiveColorType,
    SingleToneColorType | undefined
  > = {
    singleTone:
      colors?.activeColorType === "singleTone"
        ? generateColorsFronSingleTone(
            colors?.singleTone?.[yAxisLabel],
            legendsLabel,
            scaleOpacity
          )
        : {},
    custom: isCycleTimeReport
      ? generateColorsFronSingleTone(
          colors?.custom?.[Object.keys(colors?.custom ?? {})?.[INDEX_ZERO]]?.[
            yAxisLabel
          ],
          legendsLabel,
          scaleOpacity
        )
      : colors?.custom?.[yAxisLabel],
    spectrum:
      colors?.activeColorType === "spectrum"
        ? generateColorsFronSpectrum(
            colors?.spectrum?.[yAxisLabel],
            legendsLabel
          )
        : {}
  };
  return colors?.activeColorType
    ? colorSourceSwitch[colors.activeColorType]
    : {};
};

const generateColorsFronSingleTone = (
  color: RGBColorString | undefined,
  labels: string[],
  scaleOpacity: ScaleLinear<number, number, never>
) => {
  if (!color) {
    return {};
  }

  return labels.reduce<Record<string, RGBColorString>>(
    (singleToneData, label, index) => {
      const fillOpacity =
        scaleOpacity(labels.length - ONE - index) * colord(color).alpha();
      singleToneData[label] = colord(color)
        .alpha(fillOpacity)
        .toRgbString() as RGBColorString;
      return singleToneData;
    },
    {}
  );
};
