/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { useRef, useEffect, useMemo } from "react";
import { getAvatarLetters } from "@certa/blocks/thanos";
import type { CommentMention, ConversationThread } from "@certa/types";
import { format, parseISO } from "date-fns";
import { useCommentMessageFormat } from "../../hooks/useCommentMessageFormat";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst/components/Typography";
import {
  AvatarSizes,
  Avatar as CatalystAvatar,
  AvatarColors
} from "@certa/catalyst/components/Avatar";
import { AttachmentView } from "./AttachmentView";

import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import { useGetConversationMessagesComms } from "@certa/queries/queries/comms";
import { useAppSelector } from "@certa/common/hooks";
import { FullHeightSpinner } from "@certa/common/components/FullHeightSpinner";
import { DateHeader } from "./DateHeader";

type MessageProps = {
  postedBy: string;
  createdAt: string;
  attachment: string;
  message: string;
  postedById: number;
  messageId: number | null;
};

const MessageViewIdentifier = ({
  activeConversationThreadComms,
  groupedMessages,
  mentions
}: {
  activeConversationThreadComms: ConversationThread | null;
  groupedMessages: { [date: string]: MessageProps[] };
  mentions: CommentMention[];
}) => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  return isCommsNewEndpointEnabled ? (
    <MessageViewNewComms
      activeConversationThreadComms={activeConversationThreadComms}
      mentions={mentions}
    />
  ) : (
    <MessageViewNew groupedMessages={groupedMessages} mentions={mentions} />
  );
};

const NewMessage = ({
  attachment,
  postedBy,
  createdAt,
  mentions,
  message,
  userId,
  postedById
}: MessageProps & { mentions: CommentMention[]; userId: number }) => {
  const formattedMessage = useCommentMessageFormat(message, mentions, true);

  const postedAt = format(new Date(createdAt), "hh:mm a");

  return (
    <div
      css={css({
        textAlign: postedById === userId ? "right" : "left"
      })}
    >
      {/* Title */}
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          marginBottom: "var(--space-12)"
        })}
      >
        <div
          css={css({
            display: "flex",
            alignItems: "center",
            gap: "var(--space-8)",
            marginLeft: postedById === userId ? "auto" : "unset"
          })}
        >
          <div>
            <CatalystAvatar
              size={AvatarSizes.SMALL}
              color={AvatarColors.DARK}
              aria-label={postedBy}
            >
              {getAvatarLetters(postedBy)}
            </CatalystAvatar>
          </div>
          <Typography
            className={css({ marginTop: "var(--space-2)" }).styles}
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.LABEL_SM_BOLD}
          >
            {postedBy}
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
          >
            {postedAt}
          </Typography>
        </div>
      </div>
      <div
        css={css({
          textAlign: postedById === userId ? "right" : "left",
          marginLeft: postedById === userId ? "0" : "var(--space-4)",
          overflowWrap: "break-word"
        })}
      >
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_700}
        >
          <div dangerouslySetInnerHTML={{ __html: formattedMessage }}></div>
        </Typography>
        <AttachmentView
          attachment={attachment}
          isPostedByCurrentUser={postedById === userId}
        />
      </div>
    </div>
  );
};

const MessageViewNewComms = ({
  activeConversationThreadComms,
  mentions
}: {
  activeConversationThreadComms: ConversationThread | null;
  mentions: CommentMention[];
}) => {
  const { data: messages, isLoading: isLoadingMessages } =
    useGetConversationMessagesComms(
      activeConversationThreadComms?.conversationId!
    );

  const containerRef = useRef(null);
  const userId = useAppSelector(state => state.authentication.user.id);
  const groupedMessages = useMemo(() => {
    if (!messages?.results) {
      return {};
    }
    const sortedMessages = [...messages.results].sort(
      (a, b) => new Date(a.postedAt).getTime() - new Date(b.postedAt).getTime()
    );
    return sortedMessages.reduce<{ [date: string]: MessageProps[] }>(
      (acc, message) => {
        const date = format(parseISO(message.postedAt), "MMMM d, yyyy");
        if (!acc[date]) acc[date] = [];
        acc[date].push({
          postedBy: message.postedBy.fullName || message.postedBy.email,
          createdAt: message.postedAt,
          attachment: message.attachments[0]?.path || "",
          message: message.body,
          postedById: message.postedBy.id,
          messageId: message.kryptonId
        });
        return acc;
      },
      {}
    );
  }, [messages]);

  const scrollToBottom = (container: HTMLElement) => {
    container.scrollTo(0, container.scrollHeight);
  };

  useEffect(() => {
    if (containerRef?.current) {
      scrollToBottom(containerRef.current);
    }
  }, [groupedMessages]);

  const { isMobileResolution } = useScreenResolution();

  return isLoadingMessages ? (
    <FullHeightSpinner />
  ) : (
    <div
      css={css({
        maxHeight: "100%",
        overflowY: "auto",
        padding: isMobileResolution
          ? "0 var(--space-8)"
          : "0 var(--space-12) 0 0",
        marginBottom: "auto",
        borderTop: "1px solid var(--colors-neutral-400)"
      })}
      ref={containerRef}
    >
      {Object.entries(groupedMessages).map(([date, messages]) => (
        <div key={date}>
          <DateHeader date={date} />
          <div>
            {messages.map(message => (
              <div key={message.messageId}>
                <NewMessage {...message} mentions={mentions} userId={userId!} />
                <div
                  css={{
                    marginBlock: "var(--space-16)"
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const MessageViewNew: FC<{
  groupedMessages: { [date: string]: MessageProps[] };
  mentions: CommentMention[];
}> = ({ groupedMessages, mentions }) => {
  const containerRef = useRef(null);
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );
  const scrollToBottom = (container: HTMLElement) => {
    container.scrollTo(0, container.scrollHeight);
  };

  useEffect(() => {
    if (containerRef?.current) {
      scrollToBottom(containerRef.current);
    }
  }, [groupedMessages]);

  const { isMobileResolution } = useScreenResolution();

  return (
    <div
      css={css({
        maxHeight: "100%",
        overflowY: "auto",
        padding: isMobileResolution
          ? "0 var(--space-8)"
          : "0 var(--space-12) 0 0",
        marginBottom: "auto",
        borderTop: "1px solid var(--colors-neutral-400)"
      })}
      ref={containerRef}
    >
      {Object.entries(groupedMessages).map(([date, messages]) => (
        <div key={date}>
          <DateHeader date={date} />
          <div>
            {messages.map((message, index) => (
              <div key={`${message.messageId || index}`}>
                <NewMessage
                  {...message}
                  mentions={mentions}
                  // @ts-expect-error - use useAppSelector from common package instead of useSelector
                  userId={userId}
                />
                <div
                  css={{
                    marginBlock: "var(--space-16)"
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export { MessageViewIdentifier, MessageViewNewComms };
