//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Sum = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Sum"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 12.6463V16H2V15.0419L7.15614 8.04814L2 0.639373V0H14V3.64063L12.0619 1.18192L5.53105 1.11817L10.0627 7.12182L5.53105 13.5087V14.2437H12.1874L14 12.6463Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
