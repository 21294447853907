import styled from "@emotion/styled";
import { Form as AntForm } from "antd";

export const Form = styled(AntForm)`
  .ant-form-item-label {
    padding: 0 0 var(--s2);
    label {
      font-size: 12px !important;
      font-weight: bold !important;
      line-height: 20px !important;
      color: var(--neutral-100) !important;
      font-family: "Inter", "system-ui" !important;
    }
  }
  .ant-form-item-explain {
    font-size: 12px;
  }
`;

export const FormItem = styled(AntForm.Item)`
  /* 
    TODO: Following error state styles are being duplicated here from the components.
    Find better solution so that these styles only stay in component.
  */

  /* Inputs */
  &.ant-form-item-has-error {
    .ant-form-item-explain {
      color: var(--red);
    }

    .ant-input {
      color: var(--red);
      background-color: var(--red-60);
      border: 1px solid var(--red);

      &:focus {
        box-shadow: 0 0 0 2px var(--red-40);
      }
    }

    .ant-input-affix-wrapper {
      color: var(--red);
      background-color: var(--red-60);
      border: 1px solid var(--red);

      .ant-input-prefix {
        color: var(--red);
      }

      &.ant-input,
      .ant-input {
        color: var(--red);
        background-color: var(--red-60);
      }
      .ant-input {
        border: none;
      }
      .ant-input-group-addon {
        border: 1px solid var(--red);
      }
      &:focus {
        box-shadow: 0 0 0 2px var(--red-40);
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px var(--red-40);
      }
      .ant-input-group-addon {
        & + .ant-input-affix-wrapper-focused {
          &:focus-within {
            box-shadow: 0 0 0 2px var(--red-40);
          }
        }
      }
    }

    .ant-input-number {
      color: var(--red);
      background-color: var(--red-60);
      border: 1px solid var(--red);

      & .ant-input-number-input {
        color: var(--red);
        background-color: var(--red-60);
      }
      .ant-input-group-addon {
        border: 1px solid var(--red);
      }
      &.ant-input-number-focused {
        box-shadow: 0 0 0 2px var(--red-40);
      }
      .ant-input-group-addon {
        & + .ant-input-affix-wrapper-focused {
          &:focus-within {
            box-shadow: 0 0 0 2px var(--red-40);
          }
        }
      }
    }

    /* Select */
    .ant-select {
      .ant-select-selector {
        background-color: var(--red-60);
        border: 1px solid var(--red);

        .ant-select-selection-item {
          color: var(--red);
        }

        &:hover,
        &:focus,
        &:focus-visible {
          border: 1px solid var(--red);
        }
      }
      .ant-select-arrow {
        color: var(--red);
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: 0 0 0 2px var(--red-40);
          border: 1px solid var(--red);
        }
      }
      &:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--red);
      }
    }
    .ant-select-multiple {
      .ant-select-selector {
        .ant-select-selection-item {
          background-color: var(--red-40);

          .ant-select-selection-item-content {
            color: var(--red);
          }
          .ant-select-selection-item-remove {
            color: var(--red);
          }
          &:hover {
            .ant-select-selection-item-content {
              color: var(--red);
            }
          }
        }
      }
    }

    /* DatePicker & RangePicker */
    .ant-picker {
      &.ant-picker-focused {
        box-shadow: 0 0 0 2px var(--red-40);
      }
    }
  }
`;
export const useForm = AntForm.useForm;
