import moment from "moment";
import type {
  AggregateModelledResponse,
  ChartData,
  OperationsOutputDataType
} from "../types";
import { INDEX_ZERO, LENGTH_ZERO } from "../constants/common";
import type { AggregateResponse } from "./aggregateModelCreator";

type ChartDataORM = ChartData | true | false;

export const aggregateModelCreatorORM =
  (
    isStackedByLegend: boolean,
    outputDataTypes: OperationsOutputDataType[] = [],
    xAxisLength: number = LENGTH_ZERO,
    xAxisKey: string
  ) =>
  (data: AggregateResponse | undefined): AggregateModelledResponse => {
    const probableDataType = getYAxisProbableDataTypeORM(outputDataTypes);

    let chartData = [{}];
    if (!data) {
      return { xAxisKey, probableDataType, data: chartData };
    }
    const [labels, ...dataPoints] = data;
    if (isStackedByLegend) {
      const X_AXIS_KEY_INDEX = 0;
      const VALUE_KEY_INDEX = 1;
      const VALUE_INDEX = 2;
      const transformedChartData = dataPoints.reduce(
        (dataPoint, item, index) => {
          const valueKey = item[VALUE_KEY_INDEX] as string;
          const existingXAxisData = dataPoint.find(
            data => data[xAxisKey] === item[X_AXIS_KEY_INDEX]
          );
          if (existingXAxisData) {
            Object.assign(existingXAxisData, {
              [xAxisKey]: parseChartData(item[X_AXIS_KEY_INDEX], true, true),
              [valueKey]: parseChartData(item[VALUE_INDEX])
            });
            return dataPoint;
          } else {
            return [
              ...dataPoint,
              {
                [xAxisKey]: parseChartData(item[X_AXIS_KEY_INDEX], true, true),
                [valueKey]: parseChartData(item[VALUE_INDEX])
              }
            ];
          }
        },
        [] as Record<string, ChartData>[]
      );
      chartData = transformedChartData;
    } else if (
      dataPoints.length === 0 ||
      Array.isArray(dataPoints?.[INDEX_ZERO])
    ) {
      // This condition executes for normal report charts

      chartData = dataPoints.reduce(
        (validDataPoints, dataPoint) => {
          const newPoint = dataPoint.reduce(
            (chartData, item, index) => {
              const isFormatAndNullConvertAllowed = index < xAxisLength;
              return {
                ...chartData,
                [labels[index]]: parseChartData(
                  item,
                  isFormatAndNullConvertAllowed,
                  isFormatAndNullConvertAllowed
                )
              };
            },
            {} as Record<string, ChartData>
          );
          return [...validDataPoints, newPoint];
        },
        [] as Record<string, ChartData>[]
      );
    }

    return {
      xAxisKey,
      probableDataType,
      data: chartData
    };
  };

// when data is in ISO date/string format, it is required to convert into number especially for Y-axis
// +moment(data) converts date into milliseconds
const DATE_FORMAT = "YYYY-MM-DD";
const parseChartData = (
  data: ChartDataORM,
  format = false,
  convertNull = false
) => {
  if (data === true) return "true";
  else if (data === false) return "false";
  else if (Number.isNaN(Number(data)) && moment(data, true).isValid()) {
    if (format) {
      if (data?.toString?.length === DATE_FORMAT.length) {
        return moment(data)?.format(DATE_FORMAT);
      }
    } else {
      return +moment(data);
    }
  } else if (data === null && convertNull) return "null";
  return data;
};

const getYAxisProbableDataTypeORM = (
  outputDataTypes: OperationsOutputDataType[]
) => {
  if (outputDataTypes?.length) {
    return outputDataTypes.every(item => item === "date")
      ? "date"
      : outputDataTypes.every(item => item === "cycle-time")
        ? "cycle-time"
        : outputDataTypes.every(item => item === "integer")
          ? "integer"
          : "decimal";
  }

  return "decimal";
};
