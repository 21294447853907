import {
  ButtonSizes,
  ButtonTypes,
  Button
} from "@certa/catalyst/components/Button";

import {
  DropdownMenuItemSizes,
  DropdownMenuItemTypes,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@certa/catalyst/components/Dropdown";

import {
  TypographyColors,
  TypographyVariants,
  Typography,
  Ellipsis
} from "@certa/catalyst/components/Typography";
import { showAlertDialog } from "@certa/catalyst/components/AlertDialog";

import { Stack } from "@certa/catalyst/layouts/Stack";
import type { Subscription } from "@certa/types";
import { useIntl } from "react-intl";
import {
  bellIconCustomStyles,
  notificationsListItemCustomStyles,
  subscriptionItemTitleStyles
} from "./notificationsItem.styles";
import { Bell2 } from "@certa/icons/components/Bell2";
import { Dots } from "@certa/icons/components/Dots";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { useRemoveSubscriptionOptUpdate } from "@certa/common/hooks/useRemoveSubscriptionOptUpdate";

type SubscriptionItemProps = {
  subscription: Subscription;
};

const SubscriptionItem = ({ subscription }: SubscriptionItemProps) => {
  const removeSubscription = useRemoveSubscriptionOptUpdate();
  const intl = useIntl();

  const dashboardText = intl.formatMessage({
    id: "notifications.dashboard",
    defaultMessage: "Dashboard"
  });

  const reportText = intl.formatMessage({
    id: "notifications.report",
    defaultMessage: "Report"
  });

  const { data: processDetails } = useProcessDetails(
    subscription?.workflow?.id
  );

  const workflowType = processDetails?.definition?.name;
  const isDashboardSubscription = workflowType === "report_dashboard";
  const isReportSubscription = workflowType === "report_workflow";

  const unSubscribeMessage = intl.formatMessage({
    id: "mentions.unsubscribe.confirm.okBtn",
    defaultMessage: "Unsubscribe"
  });

  const onUnsubscribe = (subscription: Subscription) => {
    const onConfirmUnsubscribe = () => {
      const unsubscribingItemId = subscription?.id;
      if (unsubscribingItemId) {
        removeSubscription({
          enable: false,
          threadId: unsubscribingItemId
        });
      }
    };

    showAlertDialog({
      title: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.title",
        defaultMessage: "Unsubscribe from this thread"
      }),
      children: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.content",
        defaultMessage:
          "You will not receive any notifications for future comments unless explicitly tagged. "
      }),
      onPrimaryAction: onConfirmUnsubscribe,
      primaryActionText: unSubscribeMessage,
      type: "error"
    });
  };

  const { title, subtitle, workflowName } = getDetailsToShowFromSubscription(
    subscription,
    isDashboardSubscription,
    isReportSubscription,
    dashboardText,
    reportText
  );

  return (
    <Stack
      key={subscription.id}
      data-testid="subscription-list-item"
      direction="horizontal"
      justify="center"
      align="center"
      dangerouslySetClassName={notificationsListItemCustomStyles}
    >
      <Stack
        direction="horizontal"
        align="center"
        justify="space-between"
        width="100%"
        gap="8"
      >
        <Stack
          direction="horizontal"
          align="center"
          justify="space-between"
          gap="8"
          overflow="hidden"
        >
          <Stack
            direction="horizontal"
            align="center"
            justify="center"
            dangerouslySetClassName={bellIconCustomStyles}
          >
            <Bell2 />
          </Stack>
          <Stack direction="vertical" overflow="hidden">
            <Typography
              as="p"
              variant={TypographyVariants.LABEL_SM_BOLD}
              color={TypographyColors.NEUTRAL_800}
              className={subscriptionItemTitleStyles}
            >
              {title}
            </Typography>
            <Typography
              as="p"
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {subtitle}
            </Typography>
            {workflowName && (
              <Typography
                as="p"
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_700}
              >
                <Ellipsis>{workflowName}</Ellipsis>
              </Typography>
            )}
          </Stack>
        </Stack>
        <DropdownMenu shouldStopEventPropagation>
          <DropdownMenuTrigger>
            <Button
              aria-label="unsubscribe"
              leftIcon={<Dots />}
              size={ButtonSizes.DEFAULT}
              type={ButtonTypes.ICON}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={-2}>
            <DropdownMenuItem
              value="unsubscribe"
              onSelect={() => onUnsubscribe(subscription)}
              size={DropdownMenuItemSizes.BIG}
              type={DropdownMenuItemTypes.DEFAULT}
            >
              <Typography variant={TypographyVariants.BODY}>
                {unSubscribeMessage}
              </Typography>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Stack>
    </Stack>
  );
};

const getDetailsToShowFromSubscription = (
  subscription: Subscription,
  isDashboardSubscription: boolean,
  isReportSubscription: boolean,
  dashboardText: string,
  reportText: string
) => {
  if (isDashboardSubscription || isReportSubscription) {
    return {
      title: subscription?.workflow?.name,
      subtitle: isDashboardSubscription ? dashboardText : reportText,
      workflowName: null // we don't show workflow name for dashboard and report subscriptions
    };
  }

  if (subscription?.type === "field") {
    return {
      title: subscription?.field?.name,
      subtitle: `${subscription?.stepGroup?.name}: ${subscription?.step?.name}`,
      workflowName: subscription?.workflow?.name
    };
  }

  if (subscription?.type === "step") {
    return {
      title: subscription?.step?.name,
      subtitle: subscription?.stepGroup?.name,
      workflowName: subscription?.workflow?.name
    };
  }

  // default case
  return {
    title: subscription?.name,
    subtitle: "",
    workflowName: subscription?.workflow?.name
  };
};

export { SubscriptionItem };
