import { css } from "emotion";

const advancedFilterStyles = {
  container: css({
    borderBottom: "1px solid var(--colors-neutral-300)",
    maxHeight: "15vh",
    overflowY: "auto"
  })
};

export { advancedFilterStyles };
