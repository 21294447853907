import type {
  WithinTheLastTimePeriod,
  WithinTheLastErrorCode
} from "@certa/types";
import {
  DAYS_IN_YEAR,
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
  MONTHS_IN_YEAR,
  WEEKS_IN_YEAR
} from "@certa/common/constants/common";

const maximumYears = 1000;
const maxLimitForMinute =
  maximumYears * DAYS_IN_YEAR * HOURS_IN_DAY * MINUTES_IN_HOUR;
const maxLimitForHours = maximumYears * DAYS_IN_YEAR * HOURS_IN_DAY;
const maxLimitForDays = maximumYears * DAYS_IN_YEAR;
const maxLimitForWeeks = maximumYears * WEEKS_IN_YEAR;
const maxLimitForMonths = maximumYears * MONTHS_IN_YEAR;

export const withinTheLastMaxLimit = {
  maxLimitForMinute,
  maxLimitForHours,
  maxLimitForDays,
  maxLimitForWeeks,
  maxLimitForMonths
};
export const DEFAULT_TIME_PERIOD_VALUE: WithinTheLastTimePeriod = "d";

export const withinTheLastValidation = (
  value: number | undefined,
  timePeriod: WithinTheLastTimePeriod
) => {
  let isValid = true;
  let errorCode: WithinTheLastErrorCode | undefined;
  if (value !== undefined) {
    if (value?.toString().includes("-")) {
      isValid = false;
      errorCode = "negativeNumberNotAllowed";
    } else if (value?.toString().includes(".")) {
      isValid = false;
      errorCode = "decimalNumberNotAllowed";
    } else if (new RegExp(/\D/).test(value.toString())) {
      isValid = false;
      errorCode = "onlyDigitsAllowed";
    } else if (timePeriod === "m") {
      isValid = value <= maxLimitForMinute;
      errorCode = isValid ? undefined : "maxLimitForMinute";
    } else if (timePeriod === "h") {
      isValid = value <= maxLimitForHours;
      errorCode = isValid ? undefined : "maxLimitForHours";
    } else if (timePeriod === "d") {
      isValid = value <= maxLimitForDays;
      errorCode = isValid ? undefined : "maxLimitForDays";
    } else if (timePeriod === "w") {
      isValid = value <= maxLimitForWeeks;
      errorCode = isValid ? undefined : "maxLimitForWeeks";
    } else if (timePeriod === "M") {
      isValid = value <= maxLimitForMonths;
      errorCode = isValid ? undefined : "maxLimitForMonths";
    } else {
      isValid = true;
      errorCode = undefined;
    }
  }
  return { isValid, errorCode };
};

export const getRelativeTimeString = (
  value: number,
  timePeriod: WithinTheLastTimePeriod,
  isBefore = false
) => {
  if (isBefore) {
    return `-${value}${timePeriod}`;
  }
  return `${value}${timePeriod}`;
};

const FIRST_PATTERN_INDEX = 1;
export const destructureRelativeTime = (relativeDate: string | undefined) => {
  const hasNegativeSign = !!relativeDate?.startsWith("-");
  const pattern = /^(\d+(\.\d+)?)(\w)$/;
  const matches = relativeDate?.replace("-", "")?.match(pattern);
  if (matches && !isNaN(Number(matches[FIRST_PATTERN_INDEX]))) {
    const value = Number(matches[FIRST_PATTERN_INDEX]);
    // matches.length - 1 gives the index of the last element in the matches array
    // which will always correspond to the time period
    const timePeriod = matches[matches.length - 1] as WithinTheLastTimePeriod;
    return { value, timePeriod, hasNegativeSign };
  } else {
    return {};
  }
};
