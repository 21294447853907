import { css } from "emotion";
import { useIntl } from "react-intl";
import type { InputFieldProps } from "./ValueFieldInput.types";
import { Typography } from "@certa/catalyst/components/Typography";
import { NumberInput } from "@certa/catalyst/components/Inputs";
import { Stack } from "@certa/catalyst/layouts/Stack";

export const RelativeDateInputField = ({
  value,
  disabled,
  onChange
}: InputFieldProps) => {
  const intl = useIntl();

  const placeholderText = intl.formatMessage({
    id: "common.typeAValue",
    defaultMessage: "Type a value..."
  });

  const daysFromTodayText = intl.formatMessage({
    id: "common.daysFromToday",
    defaultMessage: "days from today"
  });

  return (
    <Stack
      align="center"
      direction="horizontal"
      dangerouslySetClassName={relativeDateInputStyle}
      gap="8"
      width="100%"
    >
      <NumberInput
        label={daysFromTodayText}
        disabled={disabled}
        placeholder={placeholderText}
        value={value === "" || Number.isNaN(value) ? undefined : Number(value)}
        onChange={value => onChange(value?.toString() || "")}
        width="50%"
      />
      <Typography className={daysFromTodayTextStyle} width="50%">
        {daysFromTodayText}
      </Typography>
    </Stack>
  );
};

const relativeDateInputStyle = css({
  minWidth: "10rem"
});

const daysFromTodayTextStyle = css({
  maxWidth: "max-content"
});
