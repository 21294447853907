//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronRightLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronRightLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.81441 3.52574C4.46632 3.17665 4.46632 2.61015 4.81441 2.26107C5.1635 1.91298 5.73 1.91298 6.07909 2.26107L11.1857 7.36766C11.5338 7.71675 11.5338 8.28325 11.1857 8.63234L6.07909 13.7389C5.73 14.087 5.1635 14.087 4.81441 13.7389C4.46632 13.3898 4.46632 12.8233 4.81441 12.4743L9.29162 7.99807L4.81441 3.52574Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
