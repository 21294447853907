//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const AccordionExpand = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="AccordionExpand"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3448)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 10.4874L3 5.51983C3 5.06606 2.51592 4.82613 2.2397 5.14743L0.120792 7.60411C-0.0402639 7.79083 -0.0402639 8.11109 0.120792 8.32494L2.2397 10.8348C2.51592 11.1822 3 10.9412 3 10.4874ZM13 10.4874V5.51983C13 5.06606 13.4841 4.82613 13.7603 5.14743L15.8792 7.60411C16.0403 7.79083 16.0403 8.11109 15.8792 8.32494L13.7603 10.8348C13.4841 11.1822 13 10.9412 13 10.4874Z"
              fill="currentColor"
            />
            <rect
              opacity={0.5}
              x={5}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 5 16)"
              fill="currentColor"
            />
            <rect
              opacity={0.5}
              x={9}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 9 16)"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3448">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
