import { ReportViewType } from "@certa/types";
import moment from "moment";
import { getFormattedCycleTime } from "@certa/common/components/TaskView";
import {
  CYCLE_TIME_OPTION_LABEL,
  commonXAxisLabel,
  ZERO,
  INDEX_ZERO,
  PROCESS_SWIMLANES_TAG,
  ALL_STEPS_TAG,
  getAggregateMetricType,
  ONE
} from "@certa/common/constants";
import type {
  MetricAttributes,
  YAxisChart,
  ChartConfig,
  ChartIdentifiers,
  XAxisDataTypes,
  GroupByDateLabels,
  ValueLabelAngle,
  ChartData,
  MetricDataTypes,
  AxisFormatterResult,
  OtherChartConfig
} from "@certa/common/types";
import { isStatsColumn } from "../components/charts/HeatMap/utils/cellUtils";

const MIN_VALUE_LABEL_ANGLE = 0;
export const MIN_CHART_HEIGHT = 500;
const REDUCE_WINDOW_HEIGHT_BY = 400;

export const getValueLabelTextAnchor = (
  valueLabelAngle: ValueLabelAngle | undefined
) => {
  return valueLabelAngle
    ? valueLabelAngle < MIN_VALUE_LABEL_ANGLE
      ? "start"
      : "end"
    : undefined;
};

export const getLabelsFromChartData = (data: Record<string, ChartData>[]) => {
  const stackedLabels = new Set<string>();
  data.forEach(barData => {
    Object.keys(barData).forEach(barKeys => {
      stackedLabels.add(barKeys);
    });
  });
  return Array.from(stackedLabels);
};

export const axisFormatter =
  (
    dataType?: MetricDataTypes,
    dateFormat: string = "MMM DD"
  ): AxisFormatterResult =>
  data => {
    if (dataType && ["float", "integer", "decimal"].includes(dataType)) {
      return formatUsingSISymbols(Number(data), ZERO);
    }

    if (dataType && ["date", "datetime"].includes(dataType)) {
      return moment(data).format(dateFormat);
    }

    if (dataType === "cycle-time") {
      return getFormattedCycleTime(data);
    }

    return data;
  };

// https://en.wikipedia.org/wiki/International_System_of_Units
const PREFIXES: Record<string, string> = {
  "24": "Y",
  "21": "Z",
  "18": "E",
  "15": "P",
  "12": "T",
  "9": "G",
  "6": "M",
  "3": "k",
  "0": "",
  "-3": "m",
  "-6": "µ",
  "-9": "n",
  "-12": "p",
  "-15": "f",
  "-18": "a",
  "-21": "z",
  "-24": "y"
};
const DIGITS_POWER_GAP_BETWEEN_SI_SYMBOL = 3;
const SI_MULTIPLIER = 1000;
const MAX_EXPONENT = 24;
const MIN_EXPONENT = -24;
const PRECISION = 3;
export const formatUsingSISymbols = (number: number, digits: number) => {
  if (number === ZERO) {
    return "0";
  }
  let significand = Math.abs(number);
  let exponent = ZERO;

  // Adjust the significand and exponent based on the SI_MULTIPLIER
  // until the significand is less than the SI_MULTIPLIER
  while (significand >= SI_MULTIPLIER && exponent < MAX_EXPONENT) {
    significand /= SI_MULTIPLIER;
    exponent += DIGITS_POWER_GAP_BETWEEN_SI_SYMBOL;
  }
  // Adjust the significand and exponent
  // until the significand is greater than or equal to 1
  while (significand < ONE && exponent > MIN_EXPONENT) {
    significand *= SI_MULTIPLIER;
    exponent -= DIGITS_POWER_GAP_BETWEEN_SI_SYMBOL;
  }

  const signPrefix = number < ZERO ? "-" : "";
  if (significand > SI_MULTIPLIER) {
    return signPrefix + significand.toFixed(digits) + PREFIXES[exponent];
  }
  return (
    signPrefix +
    parseFloat(significand.toPrecision(PRECISION)) +
    PREFIXES[exponent]
  );
};

export const xAxisFormatter =
  (
    dataType?: XAxisDataTypes,
    labelOutputType?: GroupByDateLabels,
    xAxisKey?: string,
    sortedDataPoints?: Record<string, ChartData>[],
    groupByLabel?: string
  ): AxisFormatterResult =>
  (data, index) => {
    const hasGroupChanged =
      groupByLabel &&
      sortedDataPoints?.[index - ONE]?.[groupByLabel] !==
        sortedDataPoints?.[index]?.[groupByLabel];
    if (
      index &&
      xAxisKey &&
      !hasGroupChanged &&
      sortedDataPoints?.[index - ONE]?.[xAxisKey] === data
    ) {
      return "";
    } else if (data === "null") {
      return data;
    } else if (labelOutputType && data) {
      return labelOutputFormatter(labelOutputType, data);
    } else if (data && dataType && ["date", "datetime"].includes(dataType)) {
      return moment(data)?.format("YYYY-MM-DD");
    } else if (dataType && ["float", "integer", "decimal"].includes(dataType)) {
      if (data === "") {
        return "";
      }

      return formatUsingCommas(Number(data));
    }
    return data;
  };

const SHOW_EXPONENT_FORM_AFTER = 1e-3;
const formatUsingCommas = (number: number) => {
  // NOTE: Intl.formatNumber is 70 times faster but
  // only when you have a lot of numbers
  if (number === ZERO) {
    return "0";
  }
  return Math.abs(number) < SHOW_EXPONENT_FORM_AFTER
    ? number.toExponential()
    : number.toLocaleString();
};

const yearMap: { [key: number]: string } = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
};

const labelOutputFormatter = (
  labelOutputType: GroupByDateLabels,
  data: string
): string => {
  // Rule out invalid data
  if (labelOutputType !== "year" && typeof data === "number") {
    return "";
  }

  // Do not need label formatter for year and week
  if (labelOutputType === "week" || labelOutputType === "year") {
    return data;
  }

  const [year, unit] = data.split("-");
  if (!unit) return data;
  const numberedUnit = parseInt(unit);
  if (labelOutputType === "month") {
    return `${year}-${yearMap[numberedUnit]}`;
  }
  if (labelOutputType === "quarter") {
    return `${year}-Q${numberedUnit}`;
  }

  return data;
};

export const tooltipFormatter =
  (
    chartType: ChartIdentifiers,
    dataType: MetricDataTypes
    // data will be any since this data comes from graph
    // and they don't have proper typings
    // its partly also because
    // we are making generic graphs
    // hence the data passed to the graph does not have
    // strict typings
    // hence its better to keep it any and test for
    // every possible types
  ) =>
  (data: any, name?: any, payload?: any) => {
    let value: any;

    if (["float", "integer", "decimal"].includes(dataType)) {
      value = formatUsingCommas(Number(data));
    } else if (["date", "datetime"].includes(dataType)) {
      value = moment(data).format("MMM DD, YYYY");
    } else if (dataType === "cycle-time") {
      value = getFormattedCycleTime(data);
    } else {
      value = data;
    }

    return value;
  };

export const percentageFormatter = (data: any, name: any, payload: any) => {
  const percentage = payload?.percentage?.[name];
  const isSubTotalOrAverage = isStatsColumn(name);
  let value = data;
  if (data && percentage && !isSubTotalOrAverage) {
    value = `${percentage}%`;
  }
  return value;
};

export const valueFormatter = (data: any, dataType: MetricDataTypes) => {
  let value: any;
  if (dataType === "float") {
    value = formatUsingCommas(Number(data));
  } else if (["date", "datetime"].includes(dataType)) {
    value = moment(data).format("MMM DD, YYYY");
  } else if (dataType === "cycle-time") {
    value = getFormattedCycleTime(data);
  } else {
    value = data;
  }
  return value;
};

type GetDefaultXAxisLabel = (
  xAxisChartConfig?: ChartConfig["x-axis"],
  cycleTimePath?: OtherChartConfig["cycleTimePath"],
  isStackedBarChartOrHeadMap?: boolean,
  isGroupedBarChart?: boolean
) => string;

export const getDefaultXAxisLabel: GetDefaultXAxisLabel = (
  xAxisChartConfig,
  cycleTimePath,
  isStackedBarChartOrHeadMap,
  isGroupedBarChart = false
) => {
  let label = "";
  if (
    xAxisChartConfig?.[INDEX_ZERO]?.stepgroups ||
    cycleTimePath === PROCESS_SWIMLANES_TAG
  )
    label = commonXAxisLabel.PROCESS_SWIMLANES;
  else if (
    xAxisChartConfig?.[INDEX_ZERO]?.steps ||
    cycleTimePath?.includes(`${ALL_STEPS_TAG}-of-`)
  )
    label = commonXAxisLabel.ALL_STEPS;
  else if (isStackedBarChartOrHeadMap)
    label = xAxisChartConfig?.[INDEX_ZERO]?.label ?? "";
  else if (isGroupedBarChart) {
    const xAxisLabels = xAxisChartConfig
      ?.map(xAxis => xAxis?.label)
      .filter(Boolean);
    // remove the second axis
    xAxisLabels?.pop();
    label = xAxisLabels?.join(", ") ?? "";
  } else
    label =
      xAxisChartConfig
        ?.map(xAxis => xAxis?.label)
        .filter(Boolean)
        .join(", ") ?? "";
  return label;
};

export const getDefaultYAxisLabel = (
  yAxis: YAxisChart | undefined,
  isCycleTimeReport = false
) => {
  let yAxislabel = "";
  if (yAxis) {
    if (isCycleTimeReport) {
      yAxislabel = getDefaultYAxisLabelForCycleTime(yAxis);
    } else {
      yAxislabel = (yAxis as MetricAttributes[])
        .map(metricAttribute => metricAttribute?.label)
        .join(", ");
    }
  }
  return yAxislabel;
};

export const getDefaultYAxisLabelForCycleTime = (yAxis: YAxisChart) =>
  `${CYCLE_TIME_OPTION_LABEL} (${getAggregateMetricType(yAxis)})`;

export const getChartHeight = (viewType?: ReportViewType) => {
  return viewType === ReportViewType.FULLSCREEN
    ? Math.max(window.innerHeight - REDUCE_WINDOW_HEIGHT_BY, MIN_CHART_HEIGHT) // Clamp min height at 500px
    : undefined;
};

// Groups the original data based on the specified X-axis fields
// and calculates the sum of the Y-axis field for each group.
export const groupDataForChart = (
  data: Record<string, ChartData>[],
  config: {
    xAxisFields: string[];
    yAxisField: string;
    groupField?: string;
  }
) => {
  const { xAxisFields, groupField } = config;
  const chartData = xAxisFields.map(xAxisField => {
    const entry: any = { xAxisField, sum: 0 };

    data.forEach(report => {
      let key;

      if (groupField) {
        // #KEYS_FOR_GROUPED_BAR_CHART
        // Search for the above key to find references for the below key
        key = `${report[groupField]}-${report[xAxisField]}`;
      } else {
        key = `${report[xAxisField]}`;
      }

      entry[key] = (entry[key] ?? 0) + report[config.yAxisField];
      entry.sum += Number(report[config.yAxisField]);
    });

    return entry;
  });
  return chartData;
};

// Generates unique keys for each combination of X-axis and group fields,
// ensuring there are no duplicate labels.
export const generateUniqueKeys = (
  data: Record<string, ChartData>[],
  config: {
    xAxisFields: string[];
    yAxisField: string;
    groupField?: string;
  }
) => {
  const uniqueKeys: string[] = [];

  data.forEach(entry => {
    const groupValue = config.groupField ? entry[config.groupField] : ""; // Adjust the groupValue logic
    const xAxisValues = config.xAxisFields.map(field => entry[field]);

    xAxisValues.forEach(xAxisValue => {
      // #KEYS_FOR_GROUPED_BAR_CHART
      // Search for the above key to find references for the below key
      const uniqueKey = config.groupField
        ? `${groupValue}-${xAxisValue}`
        : `${xAxisValue}`;
      if (!uniqueKeys.includes(uniqueKey)) {
        uniqueKeys.push(uniqueKey);
      }
    });
  });

  return uniqueKeys;
};
