import { useMemo } from "react";
import type { ChartViewORMProps } from "../types";
import { useConvertToChartDataORM } from "./useConvertToChartDataORM";
import { useSetTotalCount } from "../../../hooks/useSetTotalCount";
import type { OrderByORM, ChartConfigORM, GroupByORM } from "@certa/types";
import { getChartHeight } from "../../../utils/chartUtils";
import {
  getOtherConfigXAxis,
  getSortingFromChartConfigORM
} from "../../../utils/chartORMUtils";
import type {
  ChartSorting,
  MetricDataTypes,
  OtherChartConfig
} from "@certa/common/types";
import { useLabelsInReport } from "@certa/common/toggles";
export const useChartViewORM = (props: ChartViewORMProps) => {
  const {
    chartConfigORM,
    apiData,
    viewType,
    setTotalCount,
    isLoading,
    setChartConfigORM
  } = props;
  const isShowLabelsInReportEnabled = useLabelsInReport();

  const chartData = useConvertToChartDataORM({
    chartConfigORM,
    apiData
  });
  const xAxisKey = chartData?.xAxisKey;
  const isCycleTimeReport =
    chartConfigORM?.otherConfigurations?.isCycleTimeReport;
  const xAxis = (chartConfigORM?.groupBy ?? []).filter(
    groupBy => !groupBy.extraJSON.isForSecondaryXAxis
  );
  const canShowSorting = !isCycleTimeReport && setChartConfigORM;

  const xAxisDataLabels = useMemo(() => {
    // when isCycleTimeReport = true at that time, xAxisKey will provide the correct label.
    const defaultxAxis = xAxisKey ? [{ extraJSON: { label: xAxisKey } }] : [];
    const xAxisData = isCycleTimeReport ? defaultxAxis : xAxis;
    return xAxisData.map(x => x.extraJSON.label);
  }, [xAxis, xAxisKey, isCycleTimeReport]);

  const chartHeight = getChartHeight(viewType);
  const yAxisKey = chartConfigORM?.operations?.[0]?.label ?? "";

  // create otherConfig for chart frp, chartConfigORM.otherConfigurations
  const otherConfig: OtherChartConfig = {
    ...chartConfigORM?.otherConfigurations,
    dataOrder: chartConfigORM?.otherConfigurations?.dataOrder ?? [],
    xAxis: getOtherConfigXAxis(chartConfigORM),
    yAxis: getOtherConfigYAxis(chartConfigORM),
    shouldShowPercentagesInValueLabels:
      chartConfigORM?.otherConfigurations?.shouldShowPercentagesInValueLabels
  };

  const onReorder = (newOrder: string[]) => {
    if (setChartConfigORM) {
      setChartConfigORM(prev => ({
        ...prev,
        otherConfigurations: {
          ...prev.otherConfigurations,
          dataOrder: newOrder
        }
      }));
    }
  };

  useSetTotalCount({ chartData, isLoading, setTotalCount });

  const handleSorterChange = (data: ChartSorting) => {
    const sorter: GroupByORM | undefined = chartConfigORM?.groupBy?.find(
      item => item.extraJSON.customTag === data.tag
    );
    if (!sorter) {
      return;
    }

    const hasFieldSorting = !!chartConfigORM?.orderBy?.find(
      item => item.tag === sorter.tag
    );
    let newOrderBy: OrderByORM[];
    if (hasFieldSorting) {
      newOrderBy =
        chartConfigORM?.orderBy?.map(orderBy => {
          if (orderBy.tag === sorter.tag) {
            return {
              ...orderBy,
              dataType: sorter.extraJSON.dataType,
              ordering: orderBy.ordering === "ASC" ? "DESC" : "ASC"
            };
          }
          return orderBy;
        }) || [];
    } else {
      newOrderBy = [
        ...(chartConfigORM?.orderBy || []),
        {
          kindId: sorter.kindId,
          type: sorter.type,
          dataType: sorter.extraJSON.dataType,
          ordering: "ASC",
          source: sorter.source,
          tag: sorter.tag,
          extraJSON: {
            customTag: sorter.extraJSON.customTag
          }
        }
      ];
    }
    setChartConfigORM?.(prev => ({
      ...prev,
      orderBy: newOrderBy
    }));
  };

  const sorting = canShowSorting
    ? getSortingFromChartConfigORM(
        chartConfigORM?.groupBy,
        chartConfigORM?.orderBy,
        isShowLabelsInReportEnabled
      )
    : undefined;

  return {
    chartData,
    hasData: !!chartData?.data?.length,
    xAxisDataLabels: xAxisDataLabels,
    chartHeight,
    otherConfig,
    handleSorterChange,
    sorting,
    yAxisKey,
    onReorder: setChartConfigORM ? onReorder : undefined
  };
};

const getOtherConfigYAxis = (chartConfigORM: ChartConfigORM | undefined) => {
  const yAxis = {
    outputDataType: [] as MetricDataTypes[]
  };
  // when axisName = xAxis at that time only outputDataType = "text"
  // that is why after filtering, outputDataType = MetricDataTypes only
  chartConfigORM?.operations
    ?.filter(operation => operation.extraJSON.axisName === "yAxis")
    ?.forEach(operation => {
      const { outputDataType } = operation.extraJSON;
      yAxis.outputDataType.push(outputDataType as MetricDataTypes);
    });
  return yAxis;
};
