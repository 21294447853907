import { useGetStatusListForSearch } from "@certa/queries/hooks/search.hooks";
import { PROCESS_STATUS } from "@certa/search/src/constants";
import React from "react";
import { useDefinedProcessLabel } from "../../../../hooks/useDefinedProcessLabels";
import { useSearchStore } from "../../store/useSearchStore";
import { FilterDropdown } from "../FilterDropdown";

export const StatusFilter: React.FC = () => {
  const { data: processStatusList, status: statusQueryStatus } =
    useGetStatusListForSearch();
  const processStatuses = processStatusList ? processStatusList : [];
  const { filters, setFilters } = useSearchStore();
  const definedLabels = useDefinedProcessLabel();
  const statusLabel = definedLabels[PROCESS_STATUS];

  const options = processStatuses.map(status => ({
    label: status.label,
    value: status.value.toString()
  }));

  return (
    <FilterDropdown
      label={statusLabel}
      options={options}
      selectedValues={filters.statusIds}
      onChange={statusIds => setFilters({ statusIds })}
      areOptionsLoading={statusQueryStatus === "loading"}
    />
  );
};
