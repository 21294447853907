import { css } from "emotion";
import type { FC } from "react";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { Stack } from "@certa/blocks/thanos";
import { Button, ButtonVariants } from "@certa/catalyst/components/Button";
import type { FormRef } from "@certa/catalyst/components/Form";
import {
  TextInputFormField,
  Form,
  SelectFormField
} from "@certa/catalyst/components/Form";
import type { SelectOption } from "@certa/catalyst/components/Select";
import { useIntl } from "react-intl";
import { useChannelContext } from "../../comments.context";
import { useSelector } from "react-redux";
import { getCurrentUserGroupIDs } from "main/src/modules/common/selectors";
import { CommentObjectTypes } from "@certa/types";
import type {
  NewThreadParams,
  NewThreadResponse
} from "@certa/queries/types/comments.types";
import { useNewThreadMutation } from "@certa/queries/hooks/comments.hooks";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { showMessage } from "@certa/common/components/showMessage";

type NewThreadTabProps = {
  onNewThread: (payload: NewThreadResponse) => void;
  onCancel: () => void;
};

/**
 * NewThreadTab is a form to create a new thread within a channel context.
 * User may pick thread name and the user-groups that thread.
 */

const NewThreadForm: FC<NewThreadTabProps> = props => {
  const containerRef = useRef<HTMLDivElement>(null);

  const channel = useChannelContext();
  const { onNewThread, onCancel } = props;

  const { channelUserGroups } = channel;

  useEffect(() => {
    if (containerRef.current !== null) {
      containerRef.current.scrollTo(0, 100);
    }
  }, []);

  const allUserGroupIds = useMemo(
    () => channelUserGroups.map(userGroup => userGroup.id),
    [channelUserGroups]
  );
  const [selectedUserGroups, setSelectedUserGroups] = useState(allUserGroupIds);
  const { mutate: createNewThread, isLoading } = useNewThreadMutation(
    channel.fieldId || channel.objectId, // Refer to comment.hooks on why we did this.
    channel.objectType
  );
  const intl = useIntl();

  const { isMobileResolution } = useScreenResolution();

  const newThreadFormRef = useRef<FormRef>(null);

  const currentUserGroupIDs: Set<number> = useSelector(getCurrentUserGroupIDs);

  const userGroups = useMemo(
    () =>
      channelUserGroups.map(userGroup => ({
        label: userGroup.name,
        value: userGroup.id.toString()
      })),
    [channelUserGroups]
  );

  const userGroupOptions = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "commonTextInstances.selectAll",
          defaultMessage: "Select All"
        }),
        value: "__all__"
      },
      {
        label: intl.formatMessage({
          id: "comments.userOwnGroups",
          defaultMessage: "User Own Groups"
        }),
        options: userGroups.filter(option =>
          currentUserGroupIDs.has(Number(option.value))
        )
      },
      {
        label: intl.formatMessage({
          id: "comments.userGroups",
          defaultMessage: "User Groups"
        }),
        options: userGroups.filter(
          option => !currentUserGroupIDs.has(Number(option.value))
        )
      }
    ];
  }, [userGroups, intl, currentUserGroupIDs]);

  /**
   * When the create thread CTA is clicked on, the method prepares the payload
   * and sends to the thunk that initiates the thread creation. When that is completed
   * we call a callback passed in from the parent component (ChannelView) so it can
   * hide the NewThreadTab view and select the newly created thread.
   */
  const handleCreateThread = useCallback(
    async (formData: any) => {
      const payload: Partial<NewThreadParams> = {
        object_id: channel.objectId,
        type: channel.objectType,
        threadName: formData.groupName,
        threadUserGroups: formData.userGroups
      };

      /**
       * Pretty ugly stuff below. Maybe when backend refactors we can get rid of these
       * magical props that don't work the same way in all cases.
       */
      if (channel.objectType === CommentObjectTypes.INTEGRATION_RESULT) {
        // These are the same props as you pass when you're opening comments
        // for any integration. A common mistake is to pass ObjectID in field ID
        // which makes the API throw a 500.
        payload["field_id"] = channel.fieldId;
        payload["object_id"] = 1;
        payload["uid"] = channel.integrationUID;
      }

      MixPanelActions.track(
        MixPanelEvents.workflowDetailEvents.COMMENT_DETAIL_PANE_CLICK_ADD_GROUP
      );

      createNewThread(payload as NewThreadParams, {
        onError: () => {
          showMessage(
            "error",
            intl.formatMessage({ id: "comments.errors.threadCreationFailed" })
          );
        },
        onSuccess: (response: NewThreadResponse) => {
          onNewThread(response);
        }
      });
    },
    [channel, onNewThread, createNewThread, intl]
  );

  const handleChange = (options: readonly string[] | null) => {
    if (options && options.length >= 0) {
      const allUserGroupsSelected = options.find(
        (option: string) => option === "__all__"
      );
      if (allUserGroupsSelected) {
        setSelectedUserGroups([...allUserGroupIds]);
        newThreadFormRef.current?.setValue(
          "userGroups",
          allUserGroupIds.map((groupId: number) => groupId.toString())
        );
      } else {
        setSelectedUserGroups(options.map((option: string) => Number(option)));
      }
    }
  };

  const handleFilterOptions = (option: SelectOption, inputValue: string) => {
    if (inputValue === "") {
      return true;
    }

    if (option.value === "__all__") {
      return false;
    }

    return typeof option.label === "string"
      ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      : false;
  };

  const groupNameFieldRules = useMemo(
    () => ({
      required: {
        value: true,
        message: intl.formatMessage({
          id: "fieldErrors.err_field_mandatory",
          defaultMessage: "This field is required"
        })
      },
      minLength: {
        value: 3,
        message: intl.formatMessage({
          id: "comments.errors.threadNameInvalid"
        })
      },
      maxLength: {
        value: 64,
        message: intl.formatMessage({
          id: "comments.errors.threadNameInvalid"
        })
      }
    }),
    [intl]
  );

  const userGroupFieldRules = {
    validate: {
      atleastOneUserGroup: (value: string[]) => {
        if (value === null || value.length === 0) {
          return intl.formatMessage({
            id: "fieldErrors.err_field_mandatory",
            defaultMessage: "This field is required"
          });
        }
        return undefined;
      },
      atleastOneUserOwnGroup: (value: string[]) => {
        if (
          !value.find((option: string) =>
            currentUserGroupIDs.has(Number(option))
          )
        ) {
          if (value?.includes("__all__")) {
            return true;
          }
          return intl.formatMessage({
            id: "comments.userGroupAccessHelp",
            defaultMessage:
              "At least one of your own user groups must be included."
          });
        }
      }
    }
  };

  return (
    <Stack
      ref={containerRef}
      className={css({
        padding: isMobileResolution
          ? "var(--space-16) var(--space-8)"
          : "var(--space-16) 0",
        width: "100%",
        overflow: "auto",
        flex: "1",
        borderTop: "1px solid var(--colors-neutral-400)"
      })}
    >
      <Form
        ref={newThreadFormRef}
        className={css({
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        })}
        initialValues={{
          groupName: "",
          userGroups: selectedUserGroups.map((groupId: number) =>
            groupId.toString()
          )
        }}
        onSubmit={handleCreateThread}
      >
        <Stack direction="vertical" gap="s4">
          <TextInputFormField
            label={intl.formatMessage({
              id: "comments.groupName",
              defaultMessage: "Group name"
            })}
            name="groupName"
            required={true}
            rules={groupNameFieldRules}
            width="100%"
            disabled={isLoading}
          />
          <SelectFormField
            name="userGroups"
            label={intl.formatMessage({
              id: "comments.members",
              defaultMessage: "Members"
            })}
            placeholder={intl.formatMessage({
              id: "comments.userGroupAccessPlaceholder",
              defaultMessage: "User groups"
            })}
            required={true}
            options={userGroupOptions}
            isMulti={true}
            onChange={handleChange}
            width="100%"
            closeMenuOnSelect={false}
            rules={userGroupFieldRules}
            filterOption={handleFilterOptions}
            disabled={isLoading}
          />
        </Stack>
        <Stack gap="s3" gutter="s2 s00" align="end" justify="end">
          <Button htmlType="button" onClick={onCancel} disabled={isLoading}>
            {intl.formatMessage({
              id: "comments.cancelGroupCreation",
              defaultMessage: "Cancel"
            })}
          </Button>
          <Button
            variant={ButtonVariants.FILLED}
            disabled={isLoading}
            loading={isLoading}
            htmlType="submit"
          >
            {intl.formatMessage({
              id: "comments.createNewGroup",
              defaultMessage: "Create Group"
            })}
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export { NewThreadForm };
