import { UNIQUE_X_AXIS_IDENTIFIER } from "./constants";
import type { ChartData } from "@certa/common/types";

export const createUniqueXAxis = (chartData: Record<string, ChartData>[]) => {
  return chartData.map(data => {
    const uniqueIdentifier = Object.values(data).join("~");
    return {
      ...data,
      [UNIQUE_X_AXIS_IDENTIFIER]: uniqueIdentifier
    };
  });
};

export const getOrderedData = (
  initialOrder: string[],
  uniqueXAxis: string[]
): string[] => {
  const uniqueSet = new Set(uniqueXAxis);
  const validOrderedItems = initialOrder.filter(id => uniqueSet.has(id));
  const seenItems = new Set(validOrderedItems);
  const remainingItems = uniqueXAxis.filter(id => !seenItems.has(id));

  return [...validOrderedItems, ...remainingItems];
};
