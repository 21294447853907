import type { HashMap } from "@certa/types";

export const DEFAULT_INTEGRATION_TITLE_PATH: HashMap = {
  dnb_ubo: "row_json.PrimaryName",
  dnb_ubo1: "row_json.PrimaryName",
  dnb_directors: "row_json.PrincipalName.FullName.$",
  google_search: "row_json.title",
  ln_search: "row_json.EntityName.$",
  thomson_reuters_screenresult: "row_json.matchedTerm",
  rdc_event_details: "row_json.EventTypeText",
  dnb_rdc1: "row_json.EntityName",
  dnb_rdc: "row_json.EntityName"
};

export const FORMATTED_DATE_VALUES = {
  TODAY: "Today",
  YESTERDAY: "Yesterday"
};
