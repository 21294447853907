import { Dialog } from "@certa/catalyst/components/Dialog";
import { useIntl } from "react-intl";
import { InstanceSettingsComponent } from "../../InstanceSettingsAsyncComponent";
const INSTANCE_SETTINGS_MODAL_WIDTH = "80rem";
const INSTANCE_SETTINGS_MODAL_HEIGHT = "80rem";

type InstanceSettingsProps = {
  showInstanceSettingsDialog: boolean;
  setShowInstanceSettingsDialog: (value: boolean) => void;
};

export const InstanceSettings = ({
  showInstanceSettingsDialog,
  setShowInstanceSettingsDialog
}: InstanceSettingsProps) => {
  const intl = useIntl();
  const handleClose = () => {
    setShowInstanceSettingsDialog(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      preventCloseOnClickOutside={false}
      width={`min(90%, ${INSTANCE_SETTINGS_MODAL_WIDTH})`}
      height={`min(90%, ${INSTANCE_SETTINGS_MODAL_HEIGHT})`}
      show={showInstanceSettingsDialog}
      title={intl.formatMessage({
        id: "loginPageInstances.InstanceSettingsText",
        defaultMessage: "Instance settings"
      })}
    >
      <InstanceSettingsComponent />
    </Dialog>
  );
};
