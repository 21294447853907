import { css } from "emotion";

export const notificationsListItemCustomStyles = css({
  cursor: "pointer",
  minHeight: "var(--space-80)",
  padding: "var(--space-12)",
  borderRadius: "var(--space-4)",
  backgroundColor: "var(--neutral-0)",
  color: "var(--colors-neutral-800)",
  "&:hover": {
    backgroundColor: "var(--colors-neutral-300)"
  }
});

export const bellIconCustomStyles = css({
  borderRadius: "50%",
  height: "2.75rem",
  width: "2.75rem",
  backgroundColor: "var(--colors-neutral-700)",
  color: "var(--colors-neutral-100)",
  flexShrink: 0
});

export const mentionsPathStyles = css({
  overflow: "hidden"
});

export const hierarchyItemStyles = css({
  maxWidth: "10rem",
  overflow: "hidden",
  whiteSpace: "nowrap"
});

export const dashboardOrReportItemStyles = css({
  maxWidth: "20rem",
  overflow: "hidden",
  whiteSpace: "nowrap"
});

export const subscriptionItemTitleStyles = css({
  wordBreak: "break-all"
});
