//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const DoubleChevronRight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="DoubleChevronRight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.26107 3.52574C1.91298 3.17665 1.91298 2.61016 2.26107 2.26107C2.61016 1.91298 3.17666 1.91298 3.52574 2.26107L8.63234 7.36766C8.98043 7.71675 8.98043 8.28325 8.63234 8.63234L3.52574 13.7389C3.17666 14.087 2.61016 14.087 2.26107 13.7389C1.91298 13.3898 1.91298 12.8233 2.26107 12.4743L6.73828 7.99807L2.26107 3.52574Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1545 3.52574C6.80641 3.17665 6.80641 2.61016 7.1545 2.26107C7.50359 1.91298 8.07009 1.91298 8.41918 2.26107L13.5258 7.36766C13.8739 7.71675 13.8739 8.28325 13.5258 8.63234L8.41918 13.7389C8.07009 14.087 7.50359 14.087 7.1545 13.7389C6.80641 13.3898 6.80641 12.8233 7.1545 12.4743L11.6317 7.99807L7.1545 3.52574Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
