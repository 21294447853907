import { ProcessLogo } from "@certa/blocks/thanos";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { classNames } from "@certa/catalyst/utils/common";
import { RelatedProcesses } from "@certa/icons/components/RelatedProcesses";
import { css } from "emotion";
import { useState } from "react";

const styles = {
  logoContainer: css({
    backgroundColor: "var(--colors-neutral-300)",
    color: "var(--colors-neutral-700)",
    borderRadius: "50%"
  })
};

type IconContainerProps = {
  icon: React.ReactNode;
};

export const IconContainer = ({ icon }: IconContainerProps) => {
  return (
    <Stack
      align="center"
      justify="center"
      width="2rem"
      height="2rem"
      padding="8"
      dangerouslySetClassName={classNames(
        styles.logoContainer,
        "listing-item-icon"
      )}
    >
      {icon}
    </Stack>
  );
};

export const WorkflowLogo = ({
  logoUrl,
  name
}: {
  logoUrl?: string | null;
  name: string;
  style?: React.CSSProperties;
}) => {
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {logoUrl && !hasError ? (
        <ProcessLogo
          logoURL={logoUrl}
          name={name}
          height={32}
          width={32}
          onError={() => setHasError(true)}
        />
      ) : (
        <IconContainer icon={<RelatedProcesses size={16} />} />
      )}
    </>
  );
};
