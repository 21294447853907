/* eslint-disable @typescript-eslint/naming-convention */
import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";
import { z } from "zod";

export const CHILD_SHORTCUT_ACTIONS = {
  CREATE_NEW_WORKFLOW: "create_new_workflow",
  NAVIGATE_TO_PARENT_STEP: "navigate_to_parent_step"
} as const;

export type ShortcutUseCase =
  (typeof CHILD_SHORTCUT_ACTIONS)[keyof typeof CHILD_SHORTCUT_ACTIONS];

// Define a base schema with all fields optional
const WorkflowMappingEntrySchema = z
  .object({
    creation_kind_tag: z.string().optional(),
    allowed_status_ids: z.array(z.number()).optional(),
    ui: z
      .object({
        create_button_label: z.string().optional(),
        status_filtering_fallback_message: z.string().optional()
      })
      .catchall(z.string())
  })
  .superRefine((data, ctx) => {
    // If allowed_status_ids exists and is not empty, status_filtering_fallback_message must exist
    if (
      data.allowed_status_ids &&
      Array.isArray(data.allowed_status_ids) &&
      data.allowed_status_ids.length > 0
    ) {
      if (!data.ui.status_filtering_fallback_message) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "status_filtering_fallback_message is required when allowed_status_ids is provided with values",
          path: ["ui", "status_filtering_fallback_message"]
        });
      }
    }

    // If creation_kind_tag exists, create_button_label must exist
    if (data.creation_kind_tag) {
      if (!data.ui.create_button_label) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "create_button_label is required when creation_kind_tag is provided",
          path: ["ui", "create_button_label"]
        });
      }
    }
  });

const WorkflowMappingConfigSchema = z.record(
  z.string(), // key is the kind_tag
  WorkflowMappingEntrySchema
);

const BaseShortcutConfigSchema = z.object({
  kind_tag: z.string(),
  step_tag: z.string(),
  start_from_kind_tag: z.string().optional(),
  ui: z
    .object({
      title: z.string().optional(),
      description: z.string().optional()
    })
    .catchall(z.string()), // This allows any additional string fields in ui
  workflow_mapping_config: WorkflowMappingConfigSchema.optional()
});

// For creating new workflow, we need fieldTag
const CreateNewWorkflowConfigSchema = BaseShortcutConfigSchema.extend({
  use_case: z.literal(CHILD_SHORTCUT_ACTIONS.CREATE_NEW_WORKFLOW),
  field_tag: z.string()
});

// For navigating to parent, we don't need fieldTag
const NavigateToParentConfigSchema = BaseShortcutConfigSchema.extend({
  use_case: z.literal(CHILD_SHORTCUT_ACTIONS.NAVIGATE_TO_PARENT_STEP)
});

// Union schema for all possible configs
const SingleConfigSchema = z.discriminatedUnion("use_case", [
  CreateNewWorkflowConfigSchema,
  NavigateToParentConfigSchema
]);

// Schema for the entire record of shortcuts
const ProcessShortcutConfigSchema = z.record(z.string(), SingleConfigSchema);

type SingleShortcutConfig = z.infer<typeof SingleConfigSchema>;

// Base type for workflow creation feature
type WorkflowCreationConfig = {
  creationKindTag: string;
  createButtonLabel: string;
};

// Base type for status filtering feature
type StatusFilteringConfig = {
  allowedStatusIds: number[];
  statusFilteringFallbackMessage: string;
};

// Types to represent different combinations of features
type WorkflowMappingCreationOnly = WorkflowCreationConfig & {
  allowedStatusIds?: undefined;
  statusFilteringFallbackMessage?: undefined;
};

type WorkflowMappingFilteringOnly = StatusFilteringConfig & {
  creationKindTag?: undefined;
  createButtonLabel?: undefined;
};

type WorkflowMappingBothFeatures = WorkflowCreationConfig &
  StatusFilteringConfig;

// Empty mapping with no features
type WorkflowMappingEmpty = {
  creationKindTag?: undefined;
  createButtonLabel?: undefined;
  allowedStatusIds?: undefined;
  statusFilteringFallbackMessage?: undefined;
};

// Union of all possible feature combinations
type WorkflowMapping =
  | WorkflowMappingCreationOnly
  | WorkflowMappingFilteringOnly
  | WorkflowMappingBothFeatures
  | WorkflowMappingEmpty;

type ParsedWorkflowMappingConfig = Record<string, WorkflowMapping>;

type BaseCamelCaseConfig = {
  kindTag: string;
  stepTag: string;
  startFromKindTag?: string;
  title?: string;
  description?: string;
  workflowMapping?: ParsedWorkflowMappingConfig;
};

export type CreateNewWorkflowCamelConfig = BaseCamelCaseConfig & {
  useCase: "create_new_workflow";
  fieldTag: string;
};

export type NavigateToParentCamelConfig = BaseCamelCaseConfig & {
  useCase: "navigate_to_parent_step";
};

export type ChildProcessShortcutConfig =
  | CreateNewWorkflowCamelConfig
  | NavigateToParentCamelConfig;

export type ProcessShortcutsConfig = Record<string, ChildProcessShortcutConfig>;

const isValidString = (value: unknown): value is string => {
  return typeof value === "string" && value.trim() !== "";
};

const transformWorkflowMappingConfig = (
  config: z.infer<typeof WorkflowMappingConfigSchema>
): ParsedWorkflowMappingConfig => {
  return Object.entries(config).reduce((acc, [kindTag, kindConfig]) => {
    const { creation_kind_tag, allowed_status_ids, ui } = kindConfig;

    // Process creation kind feature if present
    const hasCreationFeature = !!creation_kind_tag;
    const createButtonLabel = hasCreationFeature
      ? getIntlBody(ui, "create_button_label")
      : undefined;

    // Process status filtering feature if present with values
    const hasFilteringFeature =
      !!allowed_status_ids && allowed_status_ids.length > 0;
    const statusFilteringFallbackMessage = hasFilteringFeature
      ? getIntlBody(ui, "status_filtering_fallback_message")
      : undefined;

    acc[kindTag] = {
      ...(hasCreationFeature
        ? {
            creationKindTag: creation_kind_tag,
            createButtonLabel
          }
        : {}),

      ...(hasFilteringFeature
        ? {
            allowedStatusIds: allowed_status_ids,
            statusFilteringFallbackMessage
          }
        : {})
    };

    return acc;
  }, {} as ParsedWorkflowMappingConfig);
};

const convertToCamelCase = (
  config: SingleShortcutConfig
): ChildProcessShortcutConfig => {
  const {
    kind_tag,
    step_tag,
    start_from_kind_tag,
    use_case,
    ui,
    workflow_mapping_config
  } = config;

  const title = getIntlBody(ui, "title");
  const description = getIntlBody(ui, "description");

  const baseConfig: BaseCamelCaseConfig = {
    kindTag: kind_tag,
    stepTag: step_tag,
    startFromKindTag: start_from_kind_tag,
    title: isValidString(title) ? title : undefined,
    description: isValidString(description) ? description : undefined,
    workflowMapping: workflow_mapping_config
      ? transformWorkflowMappingConfig(workflow_mapping_config)
      : undefined
  };

  if (use_case === "create_new_workflow") {
    return {
      ...baseConfig,
      useCase: use_case,
      fieldTag: config.field_tag
    };
  }

  return {
    ...baseConfig,
    useCase: use_case
  };
};

type HomePageConfig = {
  processShortcutsConfig: ProcessShortcutsConfig;
  processShortcuts: Record<string, string[]>;
  myProcessReport: { dashboard_name?: string; report_name?: string };
  createNewMessage: string | null;
};

// Shows the homepage by default to all users
export const useHomePage = (): boolean => {
  return true;
};

export const useHomePageConfig = (): HomePageConfig => {
  const homepageConfig = useSelector((state: ReduxState) =>
    lodashGet(state, "config.custom_ui_labels.c2_home_page_config", null)
  );

  const rawConfig = lodashGet(
    homepageConfig,
    "child_workflow_shortcut_config",
    {}
  );

  // Validate the entire config object at once
  const result = ProcessShortcutConfigSchema.safeParse(rawConfig);

  const validatedShortcuts: ProcessShortcutsConfig = {};

  if (result.success) {
    // Only add valid configs to the output
    Object.entries(result.data).forEach(([wfKindTag, config]) => {
      try {
        validatedShortcuts[wfKindTag] = convertToCamelCase(config);
      } catch (error) {
        console.error(`Failed to convert config for ${wfKindTag}:`, error);
      }
    });
  } else {
    console.error("Invalid shortcut configs:", {
      error: result.error.format(),
      rawConfig
    });
  }

  return {
    processShortcuts: lodashGet(homepageConfig, "process_shortcuts", {}),
    processShortcutsConfig: validatedShortcuts,
    myProcessReport: lodashGet(homepageConfig, "my_process_report", {}),
    createNewMessage:
      (homepageConfig && getIntlBody(homepageConfig, "create_new_message")) ||
      null
  };
};
