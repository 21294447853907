import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { useVirtualList } from "@certa/catalyst/hooks/useVirtualList";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { classNames } from "@certa/catalyst/utils/common";
import { NewPage } from "@certa/icons/components/NewPage";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import React from "react";
import { useIntl } from "react-intl";
import { SearchHighlighter } from "../../GlobalSearch/SearchHighlighter";
import { SEARCH_LISTBOX_ID } from "../GlobalSearchNext.constants";
import { useGlobalSearch } from "../hooks/useGlobalSearch";
import { useSearchStore } from "../store/useSearchStore";
import {
  transformHighlights,
  transformSearchResult
} from "../utils/searchTransformers";
import { IconContainer, WorkflowLogo } from "./Logo";
import { mainContentStyles as styles } from "./MainContent.styles";
import {
  SearchResultItem,
  SearchResultItemSkeleton,
  SearchResultsLoading
} from "./SearchResultItem";
import { SkeletonGroup } from "@certa/catalyst/components/Skeleton";
import type { ListInteractionMode } from "@certa/catalyst/hooks/useListInteraction";

const ITEM_HEIGHT = 57;

const Item = ({
  item,
  isSelected
}: {
  item: ModelledAPIResponse.SearchResult;
  isSelected: boolean;
}) => {
  if (!item) return null;

  const searchItem = transformSearchResult(item);
  const { text, matches, shouldShow } = transformHighlights(item);

  if (!searchItem) return null;

  const icon =
    searchItem.type === "workflow" ? (
      <WorkflowLogo logoUrl={searchItem.logo} name={searchItem.title} />
    ) : (
      <IconContainer icon={<NewPage size={16} />} />
    );
  const subDescription = shouldShow ? (
    <SearchHighlighter
      textToHighlight={text}
      highlights={matches}
      className={styles.highlight}
    />
  ) : null;

  return (
    <SearchResultItem
      title={searchItem.title}
      description={searchItem.description}
      icon={icon}
      isSelected={isSelected}
      subDescription={subDescription}
    />
  );
};

type MainContentProps = {
  onResultClick: (
    result: ModelledAPIResponse.SearchResult,
    index: number,
    interactionMode: ListInteractionMode
  ) => void;
};

export const getSearchResultId = (index: number) => `search-result-${index}`;

export const MainContent: React.FC<MainContentProps> = ({ onResultClick }) => {
  const { selectedItem, setSelectedItem, interactionMode, setInteractionMode } =
    useSearchStore();
  const intl = useIntl();

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    status,
    flatData: allItems
  } = useGlobalSearch();

  const {
    getListboxProps,
    getViewportProps,
    getOffsetterProps,
    getRowProps,
    state: { list }
  } = useVirtualList({
    totalItemsCount: allItems.length,
    hasMore: hasNextPage,
    isLoading: isFetchingNextPage,
    onLoadMore: fetchNextPage,

    isCombobox: false,
    activeIndex: selectedItem,
    selectedIndex: selectedItem,
    setActiveIndex: setSelectedItem,
    interactionMode,
    setInteractionMode,
    rowHeight: ITEM_HEIGHT,
    overscan: 30,
    onSelect: (index: number, interactionMode: ListInteractionMode) => {
      onResultClick(allItems[index], index, interactionMode);
    },
    getOptionId: getSearchResultId
  });

  const listBoxProps = getListboxProps({ label: "Search results" });
  const viewportProps = getViewportProps();
  const offsetterProps = getOffsetterProps();

  const noResultsMessage = intl.formatMessage({
    id: "globalSearch.noResults",
    defaultMessage: "No results found."
  });

  const trySearchingMessage = intl.formatMessage({
    id: "globalSearch.trySearching",
    defaultMessage: "Try different keywords or filters."
  });

  if (status === "idle" && !isLoading) return null;

  if (status === "loading" || isLoading) {
    return (
      <div
        className={classNames(styles.container, styles.loadingLayout)}
        data-testid="search-loading-skeleton"
        ref={listBoxProps.ref}
      >
        <SkeletonGroup label="Loading next page">
          <SearchResultsLoading />
        </SkeletonGroup>
      </div>
    );
  }

  if (allItems.length === 0 && !isLoading) {
    return (
      <Stack
        height="100%"
        justify="center"
        align="center"
        width="100%"
        ref={listBoxProps.ref}
      >
        <Typography
          variant={TypographyVariants.BODY}
          color={TypographyColors.NEUTRAL_700}
        >
          {noResultsMessage}
        </Typography>
        <Typography
          variant={TypographyVariants.BODY}
          color={TypographyColors.NEUTRAL_700}
        >
          {trySearchingMessage}
        </Typography>
      </Stack>
    );
  }

  return (
    <div
      {...listBoxProps}
      className={classNames(
        styles.container,
        listBoxProps.className,
        "catalystScrollContainer"
      )}
      id={SEARCH_LISTBOX_ID}
    >
      <div {...viewportProps}>
        <div {...offsetterProps}>
          {list.map(row => {
            const { rowProps, optionProps, rowKey, isLoaderRow } =
              getRowProps(row);
            const rowData = allItems[row.index];
            const isSelected = row.index === selectedItem;

            return (
              <div key={rowKey} {...rowProps} className={styles.row}>
                {isLoaderRow ? (
                  hasNextPage ? (
                    <SkeletonGroup label="Loading next page">
                      <SearchResultItemSkeleton />
                    </SkeletonGroup>
                  ) : null
                ) : (
                  <div {...optionProps} className={styles.option}>
                    <Item item={rowData} isSelected={isSelected} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
