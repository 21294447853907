//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Section = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Section"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 2V4C2 5.10457 2.89543 6 4 6H12C13.1046 6 14 5.10457 14 4V2H12V4H4V2H2ZM2 14V12C2 10.8954 2.89543 10 4 10H12C13.1046 10 14 10.8954 14 12V14H12V12H4V14H2Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
