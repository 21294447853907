//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const PDF = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="PDF"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M2.55995 4.5C3.46662 4.5 4.16329 4.69667 4.64995 5.09C5.13662 5.47667 5.37995 6.04667 5.37995 6.8C5.37995 7.58 5.14662 8.17667 4.67995 8.59C4.21328 8.99667 3.57328 9.2 2.75995 9.2H2.25995V11.45H0.449951V4.5H2.55995ZM2.58995 7.91C2.91662 7.91 3.15328 7.82333 3.29995 7.65C3.45328 7.47667 3.52995 7.19333 3.52995 6.8C3.52995 6.45333 3.45662 6.19667 3.30995 6.03C3.16328 5.85667 2.92328 5.77 2.58995 5.77H2.25995V7.91H2.58995Z"
            fill="currentColor"
          />
          <path
            d="M7.92776 4.5C8.5811 4.5 9.14776 4.59333 9.62776 4.78C10.1078 4.96 10.4978 5.30333 10.7978 5.81C11.0978 6.31667 11.2478 7.03 11.2478 7.95C11.2478 9.24333 10.9578 10.15 10.3778 10.67C9.79776 11.19 9.01776 11.45 8.03776 11.45H5.95776V4.5H7.92776ZM7.76776 5.79V10.16H8.14776C8.43443 10.16 8.66443 10.0967 8.83776 9.97C9.01776 9.83667 9.15443 9.61 9.24776 9.29C9.3411 8.96333 9.38776 8.51667 9.38776 7.95C9.38776 7.38333 9.33776 6.94333 9.23776 6.63C9.14443 6.31 9.00776 6.09 8.82776 5.97C8.65443 5.85 8.4311 5.79 8.15776 5.79H7.76776Z"
            fill="currentColor"
          />
          <path
            d="M11.9343 11.45V4.5H15.8743L15.6943 5.78H13.7443V7.49H15.4543V8.77H13.7443V11.45H11.9343Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
