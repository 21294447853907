import type { FC } from "react";
import React, { memo, useMemo } from "react";
import type { BackgroundProps } from "react-flow-renderer";
import { useViewport } from "react-flow-renderer";

const createRectangularDots = (
  size: number,
  fill: string
): React.ReactElement => {
  return <rect height={size} width={size} fill={fill} />;
};

const Background: FC<BackgroundProps> = ({ style, gap = 10, size = 3 }) => {
  const { x, y } = useViewport();

  // when there are multiple flows on a page we need to make sure that every background gets its own pattern.
  const patternId = useMemo(
    () => `pattern-${Math.floor(Math.random() * 100000)}`,
    []
  );

  const scaledGap = gap;
  const xOffset = x % scaledGap;
  const yOffset = y % scaledGap;

  const path = createRectangularDots(size, "#eeefef");

  return (
    <svg
      className="react-flow__background react-flow__container"
      style={{
        ...style,
        width: "100%",
        height: "100%"
      }}
    >
      <pattern
        id={patternId}
        x={xOffset}
        y={yOffset}
        width={scaledGap}
        height={scaledGap}
        patternUnits="userSpaceOnUse"
      >
        {path}
      </pattern>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${patternId})`}
      />
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(Background);
