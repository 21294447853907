import { MOBILE_HEADER_ID } from "../components/MobileHeader/MobileHeader";

/**
 * Used to calculate the position for the comments drawer on mobile devices.
 *
 * We use the MOBILE_HEADER_ID to find the main mobile header element.
 * This ensures that the top of the notification drawer aligns perfectly
 * with the bottom of the mobile header, regardless of any dynamic content
 * (like banners) that might appear above the header.
 *
 * @returns An object with the 'top' position for the drawer, or an empty object if the header is not found.
 */
export const getDrawerPositionInMobile = () => {
  const element = document.getElementById(MOBILE_HEADER_ID);
  if (!element) return {};
  const boundingRect = element.getBoundingClientRect();
  return {
    top: boundingRect.bottom + "px"
  };
};

export const getUserProfileDrawerPositionInMobile = () => {
  return {
    top: "0px"
  };
};

export const getDrawerPositionInDesktop = () => {
  const element = document.getElementById("catalyst-positioned-drawer-wrapper");
  if (!element) return {};
  const boundingRect = element.getBoundingClientRect();
  return {
    left: boundingRect.left + "px"
  };
};
