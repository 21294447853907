//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Upload = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Upload"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3344)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.44355 9.35216C3.88729 9.79711 4.60602 9.79774 5.05037 9.35216L6.85346 7.54348V14.8612C6.85346 15.4903 7.36157 16 7.98905 16C8.61653 16 9.12526 15.4897 9.12526 14.8612V7.52272L10.9489 9.35216C11.3926 9.79711 12.112 9.79774 12.5557 9.35216C12.9988 8.90779 12.9994 8.18631 12.5557 7.74137L8.53584 3.70923C8.23961 3.41259 7.75963 3.41196 7.46339 3.70923L3.44355 7.74137C2.99982 8.18636 2.99982 8.90721 3.44355 9.35216ZM14.9332 2.14559H1.06684C0.477485 2.14559 0 1.66544 0 1.0728C0 0.592652 0.477485 1.14441e-05 1.06684 1.14441e-05H14.9332C15.5225 1.14441e-05 16 0.480161 16 1.0728C16 1.55295 15.5225 2.14559 14.9332 2.14559Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3344">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
