import type { ReportChartFieldTagType, ReportCommonAnswer } from "../types";
import { ReportColumnFieldTags } from "../types";
import type {
  ChildWorkflow,
  ChartConfigORM,
  ColumnED,
  ColumnIC,
  ColumnORM,
  FilterED,
  FilterIC,
  FilterORM,
  OrderByORM
} from "@certa/types";
import { FieldTypes, UnreachableError } from "@certa/types";
import { omitBy, isNil } from "lodash-es";

import type {
  CSVData,
  CSVDataForSLASource,
  CSVDataType1,
  WorkflowFiltersQuery,
  ReportWorkflows
} from "@certa/queries/types/workflow.types";

import {
  filterPayloadCreatorORM,
  attributesPayloadCreatorORM,
  chartConfigPayloadCreatorORM,
  orderByPayloadCreatorORM
} from "@certa/queries/models/ormReportPayload.model";

import type { XAxisSystemAttributes } from "../constants";
import {
  defaultExportColumnsTags,
  xAxisSystemAttributes,
  CREATED_AT_TAG,
  CREATED_BY_EMAIL_TAG,
  CREATED_BY_TAG,
  UPDATED_AT_TAG,
  STATUS_TAG,
  ALL_STEPS_LABEL,
  REPORT_FAVORABLE_FIELD_TAG,
  REPORT_CHART_FIELD_TAG,
  REPORT_COLUMNS_FIELD_TAG,
  REPORT_DESCRIPTION_FIELD_TAG,
  REPORT_EDITABLE_FIELD_TAG,
  REPORT_DELETABLE_FIELD_TAG,
  REPORT_EXPORTABLE_FIELD_TAG,
  REPORT_FILTERS_FIELD_TAG,
  REPORT_GRID,
  REPORT_IS_RE_ORDERABLE,
  REPORT_NAME_FIELD_TAG,
  REPORT_ORDER,
  hiddenColumnsTags,
  defaultColumnTags,
  REPORT_TYPE,
  REPORT_COMMON
} from "../constants";

import type { FormattedMetric } from "../queries";
import { useSelector } from "react-redux";
import {
  businessUnitPlaceholderSelector,
  regionPlaceholderSelector
} from "main/src/modules/dashboard/selectors";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { labelWrapper } from "main/src/modules/dashboard/workflowToolbar/components/AdvancedFilter";
import type { AddColumnsCascaderOptions } from "../createReport/addColumn/addColumn.types";
import { TaskLaneTaskType } from "@certa/processdetails/src/types";
import { getMergedChartData } from "./graphUtils";
import type { FieldHierarchyReturnType } from "@certa/queries/models/workflow.model";
import {
  getYAxisOtherConfig,
  getOperationFromXAxis
} from "./chartConfigurationUtils";
import { transformOldRelativeDateRange } from "../utils/filterUtils";
import { getLastArrayElement } from "@certa/common/utils/helper";
import { toCapital } from "@certa/common/utils/string";
import {
  createStepGroupValueTag,
  createStepValueTag,
  getCustomAttributeTag,
  destructureCustomAttributeTag,
  createProcessValueTag,
  createStepGroupTagLabel,
  createStepTagLabel
} from "@certa/common/utils/report";
import {
  chartConfigModelCreator,
  filterModalCreatorED,
  columnsModelCreatorED,
  filterModalCreatorIC,
  columnsModelCreatorIC,
  filterModelCreatorORM,
  attributesModelCreatorORM
} from "@certa/common/modelCreators";
import type { ReportTableType, ORMReportQuery } from "@certa/common/constants";
import {
  ALL_FIELDS_TAG,
  FETCH_STEP_ATTR,
  FETCH_STEPGROUP_ATTR,
  ALL_STEPS_TAG,
  stepLevelAttributeDetails,
  stepGroupLevelAttributeDetails,
  STATUS_ID,
  commonXAxisLabel,
  INDEX_ZERO,
  INDEX_ONE,
  CYCLE_TIME_METRICS,
  PROCESS_SWIMLANES_TAG,
  dateFilterTypes,
  LENGTH_ZERO,
  ONE,
  WORKFLOW_REPORT,
  ReportTypesList
} from "@certa/common/constants";
import type {
  FieldLabelMapper,
  FieldMap,
  FieldMetricMapper,
  SLAAndSGLAMapping,
  FieldHierarchyTree,
  CascaderOptionMapping
} from "@certa/common/utils/fieldHierarchy";
import type {
  SGLAMetrics,
  SLAGroupBy,
  SLAMetrics,
  StepGroupLevelAttribute,
  StepLevelAttributesExpanded,
  FormattedProcess,
  ProcessColTypes,
  ProcessGroupByAttributes,
  ProcessMetricAttributes,
  ParsedChartFieldAnswer,
  XAxisDataTypes,
  MetricAttributes,
  ChartConfig,
  ChartIdentifiers,
  YAxisChart,
  XAxisOtherChartConfig
} from "@certa/common/types";
import { Metric } from "@certa/common/types";

export const createFiltersAnswer = (
  filters: WorkflowFiltersQuery,
  filtersORM: FilterORM | undefined,
  isORMReport: boolean
) => {
  if (isORMReport && filtersORM) {
    return JSON.stringify(filterPayloadCreatorORM(filtersORM));
  }
  return JSON.stringify(filters);
};

export const createColumnsAnswer = (
  fieldTags: string[],
  ormFieldTags: ColumnORM[],
  isORMReport: boolean
) => {
  if (isORMReport) {
    return JSON.stringify(attributesPayloadCreatorORM(ormFieldTags));
  }
  const withHiddenColumns = Array.from(
    new Set([...hiddenColumnsTags, ...fieldTags])
  );
  return withHiddenColumns.join("~");
};

export const createFieldTagsFromAnswer = (
  fieldTags: string[] | undefined,
  isORMReport: boolean
) => {
  return isORMReport ? [] : fieldTags || [...defaultColumnTags];
};

type CreateChartAnswer = {
  chartType: ChartIdentifiers;
  chartConfig: ChartConfig | undefined;
  chartConfigORM: ChartConfigORM;
  isORMReport: boolean;
};
export const createChartAnswer = ({
  chartType,
  chartConfig,
  chartConfigORM,
  isORMReport
}: CreateChartAnswer) => {
  return JSON.stringify({
    chartType,
    chartConfig: isORMReport
      ? chartConfigPayloadCreatorORM(chartConfigORM)
      : chartConfig
  });
};

type CreateReportCommonAnswerORM = {
  kindId?: number;
  queryORM: Partial<ORMReportQuery>;
  isORMReport: boolean;
};

export const createReportDataOrder = (orderByORM: OrderByORM[]) => {
  return JSON.stringify(orderByPayloadCreatorORM(orderByORM));
};

export const createReportCommonAnswerORM = ({
  kindId,
  queryORM,
  isORMReport
}: CreateReportCommonAnswerORM) => {
  const commonAnswer: ReportCommonAnswer = {
    kind_id: kindId
  };
  if (isORMReport) {
    commonAnswer.children_kind_ids = queryORM.children_kind_ids?.map(idString =>
      Number(idString)
    );
    commonAnswer.grandchildren_kind_ids = queryORM.grandchildren_kind_ids?.map(
      idString => Number(idString)
    );
  }
  return JSON.stringify(omitBy(commonAnswer, isNil));
};

// TODO: Check if this might throw errors at some point
// because of passing a bad object to JSON.parse
// Ideally it should not, unless someone fiddles with the data
// from config / BE or FE saved bad data
// all of these cases are `ProgrammingError` and hence
// should be highlighted, but should our application crash
// because if this???
// ONE SOLUTION: is to throw sentry error and handle it here
// This will also let the user fix the report if it has some bad data
// But wait, there is no way to check of the answer is a good JSON!!!
// obviously without try/catch
export function getReportBEQuery(report: ChildWorkflow) {
  try {
    return JSON.parse(
      report.fields[REPORT_FILTERS_FIELD_TAG]?.answer
    ) as WorkflowFiltersQuery;
  } catch (e) {
    // TODO: Send this on sentry
    // Also, will this be fine???
    return { kind_id: 0 };
  }
}

export const getReportType = (
  report: Partial<ChildWorkflow> | undefined
): ReportTableType => {
  const reportType = report?.fields?.[REPORT_TYPE]?.answer;
  if (reportType && ReportTypesList.includes(reportType)) {
    return reportType as ReportTableType;
  }
  return WORKFLOW_REPORT;
};

export const getReportBEQueryFromConfig = (report: ChildWorkflow) => {
  const beQueryData = {
    beQuery: undefined as WorkflowFiltersQuery | undefined,
    beQueryORM: undefined as FilterORM | undefined,
    beQueryED: undefined as FilterED | undefined,
    beQueryIC: undefined as FilterIC | undefined
  };
  try {
    const reportType = getReportType(report);
    const isEDReport = reportType === "Email Delivery Report";
    const isICReport = reportType === "Integration Call Report";
    const isORMReport = reportType === "ORM Workflow Report";
    const isWorkflowReport = reportType === "Workflow Report";

    const query = JSON.parse(
      report.fields[REPORT_FILTERS_FIELD_TAG]?.answer || "{}"
    );

    if (!isWorkflowReport && (!query?.conditions || !query?.type)) {
      return beQueryData;
    }
    beQueryData.beQueryED = isEDReport
      ? filterModalCreatorED(query)
      : undefined;

    beQueryData.beQueryIC = isICReport
      ? filterModalCreatorIC(query)
      : undefined;

    beQueryData.beQueryORM = isORMReport
      ? filterModelCreatorORM(query)
      : undefined;
    beQueryData.beQuery = isWorkflowReport
      ? transformOldRelativeDateRange(query)
      : undefined;
    return beQueryData;
  } catch (e) {
    return beQueryData;
  }
};

export type GetReportBEQueryFromConfig = ReturnType<
  typeof getReportBEQueryFromConfig
>;

const MAX_TITLE_LENGTH = 75;
export function getReportName(report: Partial<ChildWorkflow> | undefined) {
  let reportName = report?.fields?.[REPORT_NAME_FIELD_TAG]?.answer || "";
  if (reportName?.length > MAX_TITLE_LENGTH) {
    reportName = reportName.substring(0, MAX_TITLE_LENGTH) + "...";
  }
  return reportName;
}

export function getReportDescription(report: ChildWorkflow | undefined) {
  return report?.fields?.[REPORT_DESCRIPTION_FIELD_TAG]?.answer ?? "";
}

// add default export columns if not present
const addDefaultExportColumns = (fieldTags: string[]) => {
  if (!defaultExportColumnsTags.every(tag => fieldTags.includes(tag))) {
    return Array.from(new Set([...defaultExportColumnsTags, ...fieldTags]));
  }
  return fieldTags;
};

export function getReportColumns(report: ChildWorkflow) {
  const columnStr = report.fields[REPORT_COLUMNS_FIELD_TAG]?.answer;
  return addDefaultExportColumns(columnStr ? columnStr.split("~") : []);
}

export function getReportChildrenKindIds(report: ChildWorkflow) {
  const columnStr = report.fields[REPORT_COMMON]?.answer;
  try {
    return JSON.parse(columnStr).children_kind_ids ?? [];
  } catch (e) {
    return [];
  }
}

export function getShowCount(report: ChildWorkflow) {
  return Boolean(!getReportChartType(report));
}

export const getExtraJsonFromField = (
  report: ChildWorkflow | undefined,
  fieldTag: string
): Record<string, string> => {
  const extraJson = report?.fields?.[fieldTag]?.integrationJSON;
  if (typeof extraJson === "object" && !Array.isArray(extraJson) && extraJson) {
    return extraJson;
  }
  return {};
};

export function getIsReportFavorable(report: ChildWorkflow) {
  return convertToBoolean(report.fields[REPORT_FAVORABLE_FIELD_TAG]?.answer);
}

export function getIsReportEditable(report: ChildWorkflow) {
  return convertToBoolean(report.fields[REPORT_EDITABLE_FIELD_TAG]?.answer);
}
export function getIsReportDeletable(report: ChildWorkflow) {
  return convertToBoolean(report.fields[REPORT_DELETABLE_FIELD_TAG]?.answer);
}
export function getIsReportExportable(report: Partial<ChildWorkflow>) {
  // geo_map_chart is not exportable
  return (
    convertToBoolean(report.fields?.[REPORT_EXPORTABLE_FIELD_TAG]?.answer) &&
    getReportChartType(report) !== "GEO_MAP_CHART"
  );
}

function getReportChartType(report: Partial<ChildWorkflow>) {
  try {
    const { chartType } = JSON.parse(
      report.fields?.[REPORT_CHART_FIELD_TAG]?.answer || ""
    ) as ParsedChartFieldAnswer;
    return chartType;
  } catch (err) {
    return undefined;
  }
}

export function getReportGrid(report: ChildWorkflow) {
  return report.fields[REPORT_GRID]?.answer || "100%";
}
export function canExportToPDFAndPNG(report: Partial<ChildWorkflow>) {
  try {
    const { chartConfig } = JSON.parse(
      report.fields?.report_graphs.answer || ""
    ) as ReportChartFieldTagType;
    // geo_map_chart is not exportable
    return !!chartConfig && getReportChartType(report) !== "GEO_MAP_CHART";
  } catch (err) {
    return false;
  }
}
export function getReportGridFieldId(report: ChildWorkflow) {
  return report.fields[REPORT_GRID]?.id;
}
export function getReportOrder(report: Partial<ChildWorkflow>) {
  return report.fields?.[REPORT_ORDER]?.answer || 10000;
}
export function getReportOrderFieldId(report: Partial<ChildWorkflow>) {
  return report.fields?.[REPORT_ORDER]?.id;
}
export function getIsReportReOrderable(report: Partial<ChildWorkflow>) {
  if (report.fields?.[REPORT_IS_RE_ORDERABLE]) {
    return convertToBoolean(report.fields[REPORT_IS_RE_ORDERABLE].answer);
  } else {
    return true;
  }
}

export const getReportChartData = (
  report: Partial<ChildWorkflow> | undefined
): ParsedChartFieldAnswer => {
  try {
    const reportType = getReportType(report);
    const { chartType, chartConfig } = JSON.parse(
      report?.fields?.[REPORT_CHART_FIELD_TAG]?.answer || "{}"
    );
    return {
      chartType: (chartType || "LINE_CHART") as ChartIdentifiers,
      ...chartConfigModelCreator(reportType, chartConfig)
    };
  } catch (e) {
    return {
      chartType: "LINE_CHART",
      chartConfig: undefined,
      chartConfigIC: undefined,
      chartConfigORM: undefined
    };
  }
};

export const getReportColumnsData = (report: ChildWorkflow) => {
  const columnsData = {
    fieldTags: [] as string[],
    columnsDataORM: [] as ColumnORM[],
    columnsDataED: [] as ColumnED[],
    columnsDataIC: [] as ColumnIC[]
  };
  try {
    const reportType = getReportType(report);

    if (reportType === "Workflow Report") {
      const columnStr = report.fields[REPORT_COLUMNS_FIELD_TAG]?.answer;
      columnsData.fieldTags = addDefaultExportColumns(
        columnStr ? columnStr.split("~") : []
      );
    } else {
      const data = JSON.parse(
        report.fields[REPORT_COLUMNS_FIELD_TAG]?.answer || "[]"
      );
      if (reportType === "Email Delivery Report") {
        columnsData.columnsDataED = columnsModelCreatorED(data);
      } else if (reportType === "Integration Call Report") {
        columnsData.columnsDataIC = columnsModelCreatorIC(data);
      } else if (reportType === "ORM Workflow Report") {
        columnsData.columnsDataORM = attributesModelCreatorORM(data);
      }
    }

    return columnsData;
  } catch (error) {
    return columnsData;
  }
};

export const getNameFromTag = (
  process: ReportWorkflows["results"][0] | undefined,
  tag: string,
  noTrim?: boolean,
  fieldTagLabelmapping?: FieldMap
) => {
  const MAX_LENGTH = 15;
  const fullLabel =
    fieldTagLabelmapping?.[tag]?.fieldLabel ||
    process?.fields?.[tag]?.body ||
    tag.split("_").map(toCapital).join(" ");
  if (fullLabel.length > MAX_LENGTH && !noTrim) {
    return fullLabel.slice(0, 15) + "...";
  }
  return fullLabel;
};

const commonExportColumns: CommonExportColumn[] = [
  {
    tagName: ReportColumnFieldTags.UPDATED_AT,
    intlId: "dashboards.updatedAt",
    defaultMessage: "Updated At",
    source: "updated_at.isoformat",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.CREATED_BY_EMAIL,
    intlId: "dashboards.createdByEmail",
    defaultMessage: "Created By (Email)",
    source: "created_by.email",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.CREATED_BY_NAME,
    intlId: "dashboards.createdByName",
    defaultMessage: "Created By (Name)",
    source: "created_by.name",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.CREATED_AT,
    intlId: "savedSearch.createdAt",
    defaultMessage: "Created At",
    source: "created_at.isoformat",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.PROCESS_STATUS_COLUMN,
    intlId: "savedSearch.workflowStatus",
    defaultMessage: "Status",
    source: "status.label",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.AGEING,
    intlId: "reports.ageing",
    defaultMessage: "Ageing",
    source: "ageing",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.PROCESS_CYCLE_TIME_COLUMN,
    intlId: "reports.processCycleTime",
    defaultMessage: "Process cycle time",
    source: "cycle_time",
    type: "workflow"
  },
  {
    tagName: ReportColumnFieldTags.ALERTS_COLUMN,
    intlId: "dashboards.fieldAlerts",
    defaultMessage: "Field Alerts",
    source: "tag",
    type: "alert"
  },
  {
    tagName: "id",
    intlId: "reportExportField.reportID",
    defaultMessage: "ID",
    source: "id",
    type: "workflow"
  },
  {
    tagName: "name",
    intlId: "savedSearch.workflowName",
    defaultMessage: "Name",
    source: "name",
    type: "workflow"
  }
];

export const getExportColumns = (
  processes: ReportWorkflows["results"],
  columnTags: string[],
  intl: any,
  fieldLabelMappings: Record<string, string> | undefined,
  fieldsDataMapping: FieldMap | undefined
) => {
  const commonExportColumnsIntl =
    commonExportColumns.reduce<CommonExportColumnsIntl>(
      (intlExportColumns, exportColumn) => {
        intlExportColumns[exportColumn.tagName] = {
          display:
            fieldLabelMappings?.[exportColumn.tagName] ??
            intl.formatMessage({
              id: exportColumn.intlId,
              defaultMessage: exportColumn.defaultMessage
            }),
          source: exportColumn.source,
          type: exportColumn.type
        };
        return intlExportColumns;
      },
      {}
    );

  const fieldColumns = columnTags.reduce<CSVData[]>((columns, tagName) => {
    // Removing this column from now since BE doesn't support this in export
    if (tagName === ReportColumnFieldTags.PROCESS_PROGRESS_COLUMN)
      return columns;
    const customLabel = fieldLabelMappings?.[tagName];
    if (
      tagName?.includes(FETCH_STEP_ATTR) ||
      tagName?.includes(FETCH_STEPGROUP_ATTR)
    ) {
      const column = getCSVDataForSLA(
        processes[INDEX_ZERO],
        tagName,
        customLabel
      );
      if (column) return columns.concat(column);
      else return columns;
    } else {
      const column = commonExportColumnsIntl[
        tagName as ReportColumnFieldTags
      ] ?? {
        display:
          customLabel ??
          getNameFromTag(processes[0], tagName, true, fieldsDataMapping),
        source: tagName,
        type: "field_tag"
      };
      return columns.concat(column);
    }
  }, []);

  return fieldColumns;
};

// Not sure if we really need this
// CAUTION: Never export!
const convertToBoolean = (string: string | undefined = "") => {
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
    case null:
      return false;
    default:
      return Boolean(string);
  }
};

export const metricProcessAttributes: Omit<
  ProcessMetricAttributes,
  "mtr_type" | "path"
>[] = [
  {
    col: "created_at",
    col_type: "attr",
    label: "Created At",
    data_type: "date",
    iconType: "date"
  },
  {
    col: "last_activity",
    col_type: "attr",
    label: "Last Updated At",
    data_type: "date",
    iconType: "date"
  }
];

const getMetricReadableName = (metric: Metric) => {
  switch (metric) {
    case Metric.AVG:
      return "Average";
    case Metric.MAX:
      return "Max";
    case Metric.MIN:
      return "Min";
    case Metric.SUM:
      return "Sum";
    case Metric.COUNT:
      return "Count";
    case Metric.ALL:
      return "All";
    default:
      throw new UnreachableError(metric, `Unhandled Metric type: ${metric}`);
  }
};

const getAggregateDataType = (fieldType: FieldTypes) => {
  switch (fieldType) {
    case FieldTypes.DATE:
      return "datetime";
    case FieldTypes.NAIVE_DATE:
      return "date";
    case FieldTypes.INTEGER:
    case FieldTypes.DECIMAL:
    case FieldTypes.CURRENCY:
      return "float";
    default:
      console.log(
        "User it trying to aggregate unknown data_type: " + fieldType
      );
      return "float";
  }
};

export const getDataTypeForXAxisOld = (
  fieldType: FieldTypes
): XAxisDataTypes => {
  switch (fieldType) {
    case FieldTypes.DATE:
      return "datetime";
    case FieldTypes.NAIVE_DATE:
      return "date";
    case FieldTypes.INTEGER:
    case FieldTypes.DECIMAL:
    case FieldTypes.CURRENCY:
    case FieldTypes.SLIDER:
      return "float";
    default:
      return "char";
  }
};

const isCycleTime = (tag: string) =>
  // The 'cycle-time' tag is present for the Process swimlane and All steps options on the y axis,
  // The 'cycle_time' tag is present for the Step and Swimlane cycle time options on the y axis.
  tag === "cycle-time" || tag.endsWith("cycle_time");

export const createMetricOptions = (
  fieldPathList: string[],
  stepGroupAndStepMapping: SLAAndSGLAMapping | undefined,
  mapping: any
) => {
  const fieldTag = getLastArrayElement(fieldPathList);
  if (!fieldTag) return [];

  let fieldType: FieldTypes = FieldTypes.DECIMAL;
  if (
    fieldTag?.includes(FETCH_STEP_ATTR) ||
    fieldTag?.includes(FETCH_STEPGROUP_ATTR)
  ) {
    fieldType =
      getDetailsForSLA(fieldTag, stepGroupAndStepMapping).field_type ??
      FieldTypes.DECIMAL;
  } else {
    fieldType = (mapping[fieldTag]?.data_type ||
      mapping[fieldTag]?.dataType ||
      mapping[fieldTag]?.fieldType) as FieldTypes;
  }
  const isIdField = fieldTag === "id";
  const options = fieldTag
    ? getMetricOptions(fieldTag, fieldType, isIdField)
    : [];
  return options;
};

export const createMetricOptionsORM = (
  fieldPathList: string[],
  optionMapping: CascaderOptionMapping
) => {
  const customTag = getLastArrayElement(fieldPathList);
  if (!customTag) return [];
  const { fieldTag } = destructureCustomAttributeTag(customTag);

  const isIdField = fieldTag === createProcessValueTag("id");
  const fieldType =
    optionMapping[customTag]?.option?.fieldType ?? FieldTypes.DECIMAL;
  const options = fieldTag
    ? getMetricOptions(fieldTag, fieldType, isIdField)
    : [];
  return options;
};

const getMetricOptions = (
  tag: string,
  fieldType: FieldTypes,
  isIdField: boolean
) => {
  return Object.values(Metric)
    .filter(metric =>
      isCycleTime(tag)
        ? isApplicableCycleTimeMetric(tag, metric)
        : isApplicableMetric(getAggregateDataType(fieldType), metric, isIdField)
    )
    .map(metric => ({
      label: getMetricReadableName(metric),
      value: metric as string
    }));
};

export const isValidYAxisMatricType = (metric: string | undefined) => {
  return metric ? Object.values(Metric).includes(metric as Metric) : false;
};

const isApplicableMetric = (
  fieldType: "date" | "datetime" | "float",
  metric: Metric,
  isIdField: boolean
) => {
  switch (metric) {
    case Metric.AVG:
    case Metric.SUM:
      return fieldType === "float" && !isIdField;
    case Metric.MAX:
    case Metric.MIN:
      return !isIdField;
    case Metric.COUNT:
      return isIdField;
    case Metric.ALL:
      return false;
    default:
      throw new UnreachableError(metric, "");
  }
};

const isApplicableCycleTimeMetric = (tag: string, metric: Metric) => {
  switch (metric) {
    case Metric.AVG:
    case Metric.MAX:
    case Metric.MIN:
      return true;
    case Metric.ALL:
      return tag === "cycle-time";
    case Metric.COUNT:
    case Metric.SUM:
      return false;
    default:
      throw new UnreachableError(metric, "");
  }
};

export const useProcessAttributesTree = () => {
  const intl = useIntl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processAttributesMapper: FieldLabelMapper = {};

  const businessUnitLabelFromConfig = useSelector(
    businessUnitPlaceholderSelector
  );
  const businessUnitLabel =
    businessUnitLabelFromConfig ||
    intl.formatMessage({
      id: "workflowFiltersTranslated.filterPlaceholders.business_unit",
      defaultMessage: "Business"
    });

  const regionLabelFromConfig = useSelector(regionPlaceholderSelector);
  const regionLabel =
    regionLabelFromConfig ||
    intl.formatMessage({
      id: "workflowFiltersTranslated.filterPlaceholders.region",
      defaultMessage: "Region"
    });

  const processAttributes: ProcessGroupByAttributes[] = useMemo(
    () => [
      {
        col_type: "attr",
        col: "business_unit_id",
        label: businessUnitLabel
      },
      {
        col_type: "attr",
        col: "region_id",
        label: regionLabel
      },
      {
        col_type: "attr",
        col: STATUS_ID,
        label: "Process Status"
      }
    ],
    [businessUnitLabel, regionLabel]
  );

  const options = useMemo(() => {
    const processAttributesOptions = processAttributes.map(attribute => {
      processAttributesMapper[attribute.col] = {
        fieldLabel: attribute.label
      };
      return {
        label: attribute.label,
        value: attribute.col
      };
    });
    return processAttributesOptions;
  }, [processAttributes, processAttributesMapper]);

  return [options, processAttributesMapper] as const;
};

export const MetricFieldTypes = [
  FieldTypes.DATE,
  FieldTypes.NAIVE_DATE,
  FieldTypes.INTEGER,
  FieldTypes.DECIMAL,
  FieldTypes.CURRENCY
];

export const NumericFieldTypes = [
  FieldTypes.INTEGER,
  FieldTypes.DECIMAL,
  FieldTypes.CURRENCY
];

export const NotSupportedFieldTypesInChart = [
  FieldTypes.FILE,
  FieldTypes.MULTI_FILE
];

/**
 * filters out metric fields in deep hierarchy of fields list.
 * Also makes sure to remove complete hierarchy if no fields exist.
 * creates the new mapper for fields that exist plus hardcoded metric fields
 */
export const getMetricAttributesTree = (
  rawHierarchy: any[],
  // NOTE: if it contains any NotSupportedFieldTypesInChart then it will be excluded
  allowedFieldTypes: FieldTypes[],
  includeLabel: boolean = true
): [any, FieldMetricMapper] => {
  const metricAttributesMapper: FieldMetricMapper = {};
  metricProcessAttributes.forEach(item => {
    metricAttributesMapper[item.col] = {
      fieldLabel: item.label,
      dataType: item.data_type,
      colType: item.col_type
    };
  });
  const filteredOptionTree = rawHierarchy
    ?.map((swimlane: SwimlaneHierarchyType) => {
      const stepGroupChildren = getStepGroupsChildren(
        swimlane.value,
        isSwimlaneFyiOrReadonly(swimlane)
          ? FYIReadonlyMetricsSGLA
          : matricsSGLAForChart,
        allowedFieldTypes,
        false,
        includeLabel
      ) as any;
      stepGroupChildren.push(
        ...swimlane.children
          ?.map((step: StepHierarchyType) => ({
            ...step,
            label: includeLabel ? labelWrapper(step.label, "step") : step.label,
            labelText: step.label,
            children: [
              ...getStepChildren(
                step.value,
                isStepFyiOrReadonly(step)
                  ? FYIReadonlyMetricsSLA
                  : matricsSLAForChart,
                allowedFieldTypes,
                false,
                includeLabel
              )
            ].concat(
              ...(step.children
                ?.map((field: FieldHierarchyType) => {
                  if (
                    !NotSupportedFieldTypesInChart.includes(field.field_type) &&
                    allowedFieldTypes.includes(field.field_type)
                  ) {
                    return {
                      ...field,
                      label: includeLabel
                        ? labelWrapper(field.label, field.field_type)
                        : field.label,
                      labelText: field.label
                    };
                  } else return null;
                })
                .filter(Boolean) as any)
            )
          }))
          .filter(step => step.children?.length)
      );
      return {
        ...swimlane,
        label: includeLabel
          ? labelWrapper(swimlane.label, "stepGroup")
          : swimlane.label,
        labelText: swimlane.label,
        children: stepGroupChildren
      };
    })
    .filter(stepGroupChildren => stepGroupChildren.children.length);

  return [filteredOptionTree, metricAttributesMapper];
};

export const getFieldHierarchyLabels = (mapping?: {
  pathLabels: string[];
  fieldLabel: string;
}) => {
  let hierarchy: string[] = [];
  if (mapping?.pathLabels?.length) {
    hierarchy = [...mapping.pathLabels];
  }
  if (mapping?.fieldLabel) {
    hierarchy.push(mapping.fieldLabel);
  }
  return hierarchy;
};

export function formatToProcessAttributesList(
  mapping: FieldMap,
  tag: string,
  sLAMapping: SLAAndSGLAMapping | undefined
) {
  let attributesItem: FormattedProcess | null = null;
  let fieldType: FieldTypes = FieldTypes.TEXT;
  if (xAxisSystemAttributes.includes(tag as XAxisSystemAttributes)) {
    const data = processAttributesXAxisDetails[tag as XAxisSystemAttributes];
    if (data) {
      fieldType = data.fieldType;
      attributesItem = {
        col_type: "attr",
        col: data.col,
        label: data.label
      };
    }
  } else if (
    tag?.includes(FETCH_STEP_ATTR) ||
    tag?.includes(FETCH_STEPGROUP_ATTR)
  ) {
    const {
      label,
      systemAttributes,
      stepTag,
      field_type: fieldTypeFromSLADetails
    } = getDetailsForSLA(tag, sLAMapping);
    if (systemAttributes) {
      attributesItem = {
        col_type: "step",
        label,
        col: systemAttributes as SLAGroupBy,
        step_tag: stepTag
      };
      fieldType = fieldTypeFromSLADetails;
    }
  } else {
    const {
      path,
      fieldLabel,
      fieldType: fieldTypeFromFieldMapping
    } = mapping[tag] || {};
    const secondLevelPath = path?.slice(1);
    const newPath = secondLevelPath?.length ? [...secondLevelPath, tag] : [tag];
    attributesItem = {
      col_type: mapping[tag]?.hasOwnProperty("fieldType") ? "field" : "attr",
      col: tag,
      label: fieldLabel,
      path: newPath
    } as FormattedProcess;
    fieldType = fieldTypeFromFieldMapping;
  }
  return {
    groupBy: attributesItem,
    fieldType
  };
}

export function formatToMetricAttributesList(
  mapping: FieldMetricMapper,
  tag: string,
  sLAMapping: SLAAndSGLAMapping | undefined,
  mtrType?: Metric
) {
  let attributesItem: null | FormattedMetric = null;
  const mtrTypeString = mtrType ? ` (${mtrType})` : "";
  if (tag?.includes(FETCH_STEP_ATTR) || tag?.includes(FETCH_STEPGROUP_ATTR)) {
    const {
      label,
      systemAttributes,
      stepTag,
      field_type: fieldType,
      isStepGroup
    } = getDetailsForSLA(tag, sLAMapping);
    if (systemAttributes && fieldType) {
      if (isStepGroup) {
        attributesItem = {
          col_type: "step_group",
          col: systemAttributes as SGLAMetrics,
          step_group_tag: stepTag,
          label: label + mtrTypeString,
          data_type: getAggregateDataType(fieldType)
        };
      } else {
        attributesItem = {
          col_type: "step",
          col: systemAttributes as SLAMetrics,
          step_tag: stepTag,
          label: label + mtrTypeString,
          data_type: getAggregateDataType(fieldType)
        };
      }
    }
  } else {
    const fieldItem = mapping[tag] || {};
    const secondLevelPath = mapping[tag]?.path?.slice(1);
    const newPath = secondLevelPath?.length ? [...secondLevelPath, tag] : [tag];
    attributesItem = {
      col_type: fieldItem.colType || "field",
      col: tag,
      label: fieldItem.fieldLabel + mtrTypeString,
      data_type: fieldItem.fieldType
        ? getAggregateDataType(fieldItem.fieldType)
        : fieldItem.dataType,
      path: newPath
    };
  }
  return attributesItem;
}

const getIsCycleTimeReport = (chartConfig: ChartConfig) => {
  const xAxis = chartConfig?.["x-axis"];
  const yAxis = chartConfig?.["y-axis"];
  // condition for new cycle time
  if (
    xAxis?.length === LENGTH_ZERO &&
    yAxis?.every((yAxis: any) => yAxis.col === "cycle_time")
  ) {
    return true;
    // condition for old cycle time
  } else if (xAxis?.length === ONE) {
    const tag = getLastArrayElement(xAxis[INDEX_ZERO]?.path ?? []);
    return !!(
      tag?.includes(PROCESS_SWIMLANES_TAG) ||
      tag?.includes(`${ALL_STEPS_TAG}-of-`)
    );
  }
  return false;
};

export const transformOldCycleTimeChartConfig = ({
  chartConfig,
  chartType,
  fieldsData
}: {
  chartConfig?: ChartConfig;
  chartType: ChartIdentifiers;
  fieldsData: FieldHierarchyReturnType | undefined;
}) => {
  let newChartConfig = chartConfig;

  // for table Report's chartConfig is undefined
  // in that case it is required to return the same chartConfig
  if (!newChartConfig) return { chartConfig, chartType };

  // Initialize x-axis and y-axis if they don't exist
  if (!newChartConfig["x-axis"]) {
    newChartConfig["x-axis"] = [];
  }

  if (!newChartConfig["y-axis"]) {
    newChartConfig["y-axis"] = [];
  }
  // some of the old ChartConfig has x-axis as object instead of array
  if (newChartConfig && !Array.isArray(newChartConfig?.["x-axis"])) {
    newChartConfig["x-axis"] = [newChartConfig?.["x-axis"]];
  }

  newChartConfig.isCycleTimeReport = getIsCycleTimeReport(newChartConfig);
  //condition for old cycle time.
  if (
    newChartConfig &&
    newChartConfig["x-axis"]?.length &&
    newChartConfig.isCycleTimeReport &&
    newChartConfig.otherConfig?.cycleTimePath === undefined &&
    fieldsData?.hierarchy?.length
  ) {
    const xAxis = newChartConfig["x-axis"];
    const yAxis = newChartConfig["y-axis"];
    const isStepGroupCycleTime =
      !!xAxis[INDEX_ZERO]?.path &&
      xAxis[INDEX_ZERO].path[INDEX_ZERO] === PROCESS_SWIMLANES_TAG;
    const cycleTimePath = getLastArrayElement(
      xAxis[INDEX_ZERO]?.path ?? []
    ) as string;
    let swimlaneOrStepsData: FieldHierarchyTree[] | undefined = [];
    if (isStepGroupCycleTime) {
      swimlaneOrStepsData = fieldsData?.hierarchy;
    } else if (isStepGroupCycleTime === false) {
      const swimlaneTag = xAxis[INDEX_ZERO].path
        ?.slice(-1)
        [INDEX_ZERO].split(`${ALL_STEPS_TAG}-of-`)[1];
      swimlaneOrStepsData = fieldsData?.hierarchy?.find(
        (swimlane: { value: string; label: string }) =>
          swimlane.value === swimlaneTag
      )?.children;
    }

    if (
      swimlaneOrStepsData &&
      (typeof yAxis[INDEX_ZERO] === "string" || yAxis[INDEX_ZERO]?.mtr_type)
    ) {
      newChartConfig["y-axis"] =
        typeof yAxis[INDEX_ZERO] === "string"
          ? yAxis
          : [yAxis[INDEX_ZERO]?.mtr_type];
      const formatterChartConfig = {
        ...newChartConfig,
        "x-axis": [],
        "y-axis": getNewCycleTimeYAxis(
          swimlaneOrStepsData,
          newChartConfig["y-axis"],
          isStepGroupCycleTime
        ),
        otherConfig: {
          ...newChartConfig.otherConfig,
          dataOrder: newChartConfig.otherConfig?.dataOrder ?? [],
          cycleTimePath
        }
      };

      newChartConfig = formatterChartConfig;
    }
  }

  // handle derived and default variable
  const otherConfigXAxis = getOtherConfigXAxis(newChartConfig, fieldsData);
  const otherConfigYAxis = getYAxisOtherConfig(newChartConfig["y-axis"]);
  if (newChartConfig.otherConfig) {
    newChartConfig.otherConfig.xAxis = otherConfigXAxis;
    newChartConfig.otherConfig.yAxis = otherConfigYAxis;
  } else {
    newChartConfig.otherConfig = {
      xAxis: otherConfigXAxis,
      yAxis: otherConfigYAxis,
      dataOrder: []
    };
  }
  newChartConfig = getMergedChartData(newChartConfig, chartType);
  return { chartConfig: newChartConfig, chartType };
};

const getOtherConfigXAxis = (
  chartConfig: ChartConfig,
  fieldsData: FieldHierarchyReturnType | undefined
) => {
  const otherConfigXAxis: XAxisOtherChartConfig = {
    fieldTypes: [],
    dataTypes: [],
    labelOutputTypes: []
  };
  chartConfig?.["x-axis"]?.forEach(xAxis => {
    let fieldType = FieldTypes.TEXT;
    if (xAxis.col_type === "attr") {
      // date type options have operation in the col. e.g. created_at__week
      const colWithoutOperation = xAxis.col.split("__")[INDEX_ZERO];
      const { fieldType: fieldTypeFromProcessAttr } =
        processAttrXAxisDetailsByCol[colWithoutOperation] ?? {};
      if (fieldTypeFromProcessAttr) fieldType = fieldTypeFromProcessAttr;
    } else if (xAxis.col_type === "step") {
      // date type options have operation in the col. e.g. created_at__week
      const colWithoutOperation = xAxis.col.split("__")[INDEX_ZERO];
      const tag = createStepValueTag(xAxis.step_tag, colWithoutOperation);
      const { field_type: fieldTypeFromSLA } =
        getDetailsForSLA(tag, fieldsData?.stepGroupAndStepMapping) ?? {};
      if (fieldTypeFromSLA) fieldType = fieldTypeFromSLA;
    } else {
      const { fieldType: fieldTypeFromFieldsData } =
        fieldsData?.mapping?.[xAxis.col] ?? {};
      if (fieldTypeFromFieldsData) fieldType = fieldTypeFromFieldsData;
    }

    otherConfigXAxis.fieldTypes?.push(fieldType);
    otherConfigXAxis.dataTypes?.push(getDataTypeForXAxisOld(fieldType));
    if (dateFilterTypes.includes(fieldType)) {
      const operation = getOperationFromXAxis(xAxis);
      otherConfigXAxis.labelOutputTypes?.push(operation ? operation : "");
    } else otherConfigXAxis.labelOutputTypes?.push("");
  });
  return otherConfigXAxis;
};

export type FieldHierarchyType = {
  label: string;
  value: string;
  field_type: FieldTypes;
  children?: FieldHierarchyType[];
};
export type StepHierarchyType = {
  step_type?: TaskLaneTaskType;
  field_type?: FieldTypes;
  label: string;
  value: string;
  children: FieldHierarchyType[];
};
export type SwimlaneHierarchyType = {
  label: string;
  value: string;
  children: StepHierarchyType[];
};

type CommonExportColumn = {
  tagName: ReportColumnFieldTags | "id" | "name";
  intlId: string;
  defaultMessage: string;
  source: CSVDataType1["source"];
  type: CSVDataType1["type"];
};

type CommonExportColumnsIntl = {
  [key in CommonExportColumn["tagName"]]?: CSVDataType1;
};

type ProcessAttributesXAxisDetails = Record<
  XAxisSystemAttributes,
  {
    label: string;
    fieldType: FieldTypes;
    value: XAxisSystemAttributes;
    col: ProcessColTypes;
    iconType: string;
  }
>;
export const processAttributesXAxisDetails: ProcessAttributesXAxisDetails = {
  [CREATED_AT_TAG]: {
    label: "Created At",
    fieldType: FieldTypes.DATE,
    value: CREATED_AT_TAG,
    col: "created_at",
    iconType: "date"
  },
  [UPDATED_AT_TAG]: {
    label: "Updated At",
    fieldType: FieldTypes.DATE,
    value: UPDATED_AT_TAG,
    col: "updated_at",
    iconType: "date"
  },
  [CREATED_BY_TAG]: {
    label: "Created By (Name)",
    fieldType: FieldTypes.TEXT,
    value: CREATED_BY_TAG,
    col: "created_by_id",
    iconType: "user"
  },
  [CREATED_BY_EMAIL_TAG]: {
    label: "Created By (Email)",
    fieldType: FieldTypes.EMAIL,
    value: CREATED_BY_EMAIL_TAG,
    col: "created_by_email",
    iconType: "email"
  },
  [STATUS_TAG]: {
    label: "Status",
    fieldType: FieldTypes.TEXT,
    value: STATUS_TAG,
    col: "status_id",
    iconType: "tag"
  }
};

const processAttrXAxisDetailsByCol = Object.values(
  processAttributesXAxisDetails
).reduce(
  (mapping, details) => {
    mapping[details.col] = details;
    return mapping;
  },
  {} as {
    [
      key: string
    ]: (typeof processAttributesXAxisDetails)[typeof CREATED_AT_TAG];
  }
);

export type GetXAxisOptionsReturnType = ReturnType<typeof getXAxisOptions>;

export const getXAxisOptions = (
  hierarchy: any[],
  canHaveProcessSwimlaneAndAllSteps: boolean,
  // This supportedFieldTypes paramter is to handle and
  // differentiate the x-axis options from the 'legend for stacks' options
  // NOTE: if it contains any NotSupportedFieldTypesInChart then it will be excluded
  supportedFieldTypes: FieldTypes[] = [],
  shouldDisplaySystemAttributes: boolean = true,
  includeLabel: boolean = true
) => {
  const processSwimlanes = hierarchy || [];

  const xAxisOptions = [];

  if (canHaveProcessSwimlaneAndAllSteps) {
    // Plots all swimlanes of the kind on x-axis
    const processSwimlaneOption = {
      label: includeLabel
        ? labelWrapper(commonXAxisLabel.PROCESS_SWIMLANES, "stepGroup")
        : commonXAxisLabel.PROCESS_SWIMLANES,
      value: PROCESS_SWIMLANES_TAG,
      labelText: commonXAxisLabel.PROCESS_SWIMLANES
    };
    xAxisOptions.push(processSwimlaneOption);
  }

  // Lists all the steps and "All Fields" option
  const allFieldsOption = {
    label: "All Fields",
    labelText: "All Fields",
    value: ALL_FIELDS_TAG,
    children: processSwimlanes
      ?.map((swimlane: SwimlaneHierarchyType) => {
        let stepGroupChildren = [];
        if (
          canHaveProcessSwimlaneAndAllSteps &&
          // do not include all steps
          // if swimlane has steps only of FYI or Readonly
          !isSwimlaneFyiOrReadonly(swimlane)
        ) {
          stepGroupChildren.push({
            label: includeLabel
              ? labelWrapper("All Steps", ALL_STEPS_TAG)
              : ALL_STEPS_LABEL,
            labelText: ALL_STEPS_LABEL,
            value: `${ALL_STEPS_TAG}-of-${swimlane.value}`
          });
        }
        stepGroupChildren.push(
          ...(swimlane.children?.map((step: StepHierarchyType) => ({
            ...step,
            label: includeLabel ? labelWrapper(step.label, "step") : step.label,
            labelText: step.label,
            children: [
              ...getStepChildren(
                step.value,
                isStepFyiOrReadonly(step)
                  ? FYIReadonlyGroupBySLA
                  : groupBySLAForChart,
                supportedFieldTypes,
                false,
                includeLabel
              )
            ].concat(
              ...(step.children
                .map((field: FieldHierarchyType) => {
                  if (NotSupportedFieldTypesInChart.includes(field.field_type))
                    return null;
                  if (supportedFieldTypes?.length) {
                    if (supportedFieldTypes.includes(field.field_type)) {
                      return {
                        ...field,
                        label: includeLabel
                          ? labelWrapper(field.label, field.field_type)
                          : field.label,
                        labelText: field.label,
                        value: field.value
                      };
                    }
                    return null;
                  }
                  return {
                    ...field,
                    label: includeLabel
                      ? labelWrapper(field.label, field.field_type)
                      : field.label,
                    labelText: field.label
                  };
                })
                .filter(Boolean) as any)
            )
          })) || [])
        );

        // Remove empty steps
        stepGroupChildren = stepGroupChildren.filter(hasChildren);
        return {
          ...swimlane,
          label: includeLabel
            ? labelWrapper(swimlane.label, "stepGroup")
            : swimlane.label,
          labelText: swimlane.label,
          children: stepGroupChildren
        };
      })
      // Remove empty stepGroups
      .filter(hasChildren)
  };

  const systemAttributesOptions = shouldDisplaySystemAttributes
    ? xAxisSystemAttributes.map(tag => {
        const { label, value, iconType } =
          processAttributesXAxisDetails[tag as XAxisSystemAttributes];
        return {
          label: includeLabel ? labelWrapper(label, iconType) : label,
          value: value,
          labelText: label
        };
      })
    : [];

  xAxisOptions.push(...systemAttributesOptions);

  // Add "All Fields" option only if it has children
  if (allFieldsOption?.children?.length) {
    xAxisOptions.push(allFieldsOption);
  }

  return xAxisOptions;
};

export const isStepFyiOrReadonly = (
  step: Pick<StepHierarchyType, "step_type">
) => {
  return (
    step?.step_type === TaskLaneTaskType.FYI ||
    step?.step_type === TaskLaneTaskType.READONLY
  );
};
export const isStepFyiReadonlyOrInternal = (
  step: Pick<StepHierarchyType, "step_type">
) => {
  return (
    step?.step_type === TaskLaneTaskType.FYI ||
    step?.step_type === TaskLaneTaskType.READONLY ||
    step?.step_type === TaskLaneTaskType.INTERNAL
  );
};

export const isSwimlaneFyiOrReadonly = (swimlane: {
  children?: Pick<StepHierarchyType, "step_type">[];
}) => {
  const swimlaneChildren = swimlane?.children;

  // returns true if all children are FYI or Readonly
  return (
    swimlaneChildren &&
    swimlaneChildren?.filter(step => isStepFyiOrReadonly(step)).length ===
      swimlaneChildren.length
  );
};
export const isSwimlaneFyiReadonlyOrInternal = (swimlane: {
  children?: Pick<StepHierarchyType, "step_type">[];
}) => {
  const swimlaneChildren = swimlane?.children;

  // returns true if all children are FYI or Readonly or Internal
  return swimlaneChildren?.every(step => isStepFyiReadonlyOrInternal(step));
};

const getStepGroupsChildren = (
  stepGroupTeg: string,
  stepGroupFields: readonly StepGroupLevelAttribute[],
  supportedFieldTypes: FieldTypes[] = [],
  includeSelectAll: boolean = false,
  includeLabel: boolean = true
) => {
  const source = supportedFieldTypes.length
    ? stepGroupFields.filter(key =>
        supportedFieldTypes.includes(
          stepGroupLevelAttributeDetails[key].systemFieldType
        )
      )
    : stepGroupFields;

  let stepGroupChildren: AddColumnsCascaderOptions[] = [];

  if (source.length) {
    stepGroupChildren = source.map(key => {
      const { labelText, iconText, value, systemFieldType } =
        stepGroupLevelAttributeDetails[key];
      return {
        label: includeLabel ? labelWrapper(labelText, iconText) : labelText,
        labelText: labelText,
        value: createStepGroupValueTag(stepGroupTeg, value),
        field_type: systemFieldType
      };
    });

    if (includeSelectAll) {
      stepGroupChildren.unshift({
        label: includeLabel
          ? labelWrapper("Select All", "all-steps")
          : "Select All",
        labelText: "Select All",
        value: stepGroupChildren.map(
          // #ADD_COLUMNS_SELECT_ALL_TAG
          // adding index since value should be unique
          // and previous values would have already been added
          (child, index) => `${child.value}-${index}`
        ),
        field_type: FieldTypes.TEXT
      });
    }
  }

  return source.length
    ? [
        {
          label: includeLabel
            ? labelWrapper("Swimlanes attributes", "alert")
            : "Swimlanes attributes",
          labelText: "Swimlanes attributes",
          value: `${FETCH_STEPGROUP_ATTR}__${stepGroupTeg}`,
          children: stepGroupChildren
        }
      ]
    : [];
};

const getStepChildren = (
  stepTeg: string,
  stepFields: readonly StepLevelAttributesExpanded[],
  supportedFieldTypes: FieldTypes[] = [],
  includeSelectAll: boolean = false,
  includeLabel: boolean = true
): AddColumnsCascaderOptions[] => {
  const source = supportedFieldTypes.length
    ? stepFields.filter(key =>
        supportedFieldTypes.includes(
          stepLevelAttributeDetails[key].systemFieldType
        )
      )
    : stepFields;

  let stepChildren: AddColumnsCascaderOptions[] = [];

  if (source.length) {
    stepChildren = source.map(key => {
      const { labelText, iconText, value, systemFieldType } =
        stepLevelAttributeDetails[key];
      return {
        label: includeLabel ? labelWrapper(labelText, iconText) : labelText,
        labelText: labelText,
        value: createStepValueTag(stepTeg, value),
        field_type: systemFieldType
      };
    });

    if (includeSelectAll) {
      stepChildren.unshift({
        label: includeLabel
          ? labelWrapper("Select All", "all-steps")
          : "Select All",
        labelText: "Select All",
        value: stepChildren.map(
          // #ADD_COLUMNS_SELECT_ALL_TAG
          // adding index since value should be unique
          // and previous values would have already been added
          (child, index) => `${child.value}-${index}`
        ),
        field_type: FieldTypes.TEXT
      });
    }
  }
  return source.length
    ? [
        {
          label: includeLabel
            ? labelWrapper("Step attributes", "alert")
            : "Step attributes",
          labelText: "Step attributes",
          value: `${FETCH_STEP_ATTR}__${stepTeg}`,
          children: stepChildren
        }
      ]
    : [];
};

const hasChildren = (
  item: { labelText: string } | { children: AddColumnsCascaderOptions[] }
) => {
  if ("labelText" in item && item.labelText === ALL_STEPS_LABEL) {
    return true;
  }
  if ("children" in item) {
    if (item.children?.length) {
      return true;
    }
  }
  return false;
};

export const getDetailsForSLA = (
  tag: string,
  mapping: SLAAndSGLAMapping | undefined,
  kindId?: string
) => {
  const isStepGroup = tag.includes(FETCH_STEPGROUP_ATTR);
  const splitTag = tag.split("__");
  if (splitTag.length < 3 || !mapping) return { label: "" };
  const systemAttributes = splitTag.slice(-1)[0];
  let systemAttributesLabel = "";
  let fieldType = FieldTypes.CHAR;
  if (isStepGroup) {
    const { labelText, systemFieldType } =
      stepGroupLevelAttributeDetails[
        systemAttributes as StepGroupLevelAttribute
      ] ?? {};
    systemAttributesLabel = labelText;
    fieldType = systemFieldType;
  } else {
    const { labelText, systemFieldType } =
      stepLevelAttributeDetails[
        systemAttributes as StepLevelAttributesExpanded
      ] ?? {};
    systemAttributesLabel = labelText;
    fieldType = systemFieldType;
  }
  // if step_tag is "Update__by" then it will split as well
  // due to this possibility we can not use splitTag[1] directly
  const stepTag = splitTag.slice(1, -1).join("__");
  const stepLabel =
    mapping[getCustomAttributeTag(kindId, stepTag)]?.label ?? "";
  let label = systemAttributesLabel;
  if (isStepGroup) {
    label = createStepGroupTagLabel(stepLabel, systemAttributesLabel);
  } else {
    label = createStepTagLabel(stepLabel, systemAttributesLabel);
  }

  return {
    label: toCapital(label),
    stepTag,
    stepLabel,
    systemAttributes,
    systemAttributesLabel,
    field_type: fieldType,
    isStepGroup
  };
};

const getCSVDataForSLA = (
  process: ReportWorkflows["results"][0] | undefined,
  colTag: string,
  customLabel: string | undefined
): CSVData | null => {
  const splitTag = colTag.split("__");
  if (splitTag.length < 3) return null;
  const isForStepGroup = colTag.includes(FETCH_STEPGROUP_ATTR);
  const key = isForStepGroup ? "stepGroups" : "steps";

  let systemAttributes = splitTag.slice(-1)[0];
  // for export API completed_at = completion_date,
  // this should be changed first in API then in FE
  if (systemAttributes === "completed_at") systemAttributes = "completion_date";
  const systemAttributesLabel = systemAttributes.replaceAll("_", " ");

  // if step_tag is "Update__by" then it will split as well
  // due to this possibility we can not use splitTag[1] directly
  const stepTag = splitTag.slice(1, -1).join("__");

  const stepLabel =
    process?.[key]?.[stepTag]?.definition?.name ||
    stepTag.split("_").map(toCapital).join(" ");

  let label = stepLabel + " - " + systemAttributesLabel;
  if (isForStepGroup) {
    label = label + " (step group)";
  }

  return {
    display:
      customLabel ??
      label.charAt(INDEX_ZERO).toUpperCase() +
        label.slice(INDEX_ONE).toLowerCase(),
    tag: stepTag,
    type: isForStepGroup ? "stepgroup" : "step",
    source: systemAttributes as CSVDataForSLASource
  };
};

const groupBySLAForChart: readonly SLAGroupBy[] = [
  "completed_by_id",
  "completed_by_email",
  "initiated_at",
  "completed_at",
  "updated_at",
  "is_locked",
  "is_enabled"
];
const FYIReadonlyGroupBySLA: readonly SLAGroupBy[] = [
  "initiated_at",
  "is_locked",
  "is_enabled"
];

const matricsSLAForChart: readonly SLAMetrics[] = [
  "initiated_at",
  "completed_at",
  "updated_at",
  "cycle_time",
  "cycle_start",
  "cycle_end",
  "submit_count"
];
const FYIReadonlyMetricsSLA: readonly SLAMetrics[] = ["initiated_at"];

const matricsSGLAForChart: readonly SGLAMetrics[] = [
  "cycle_time",
  "cycle_start",
  "cycle_end"
];
const FYIReadonlyMetricsSGLA: readonly SGLAMetrics[] = [];

export const getNewCycleTimeYAxis = (
  swimlaneOrStepsData:
    | {
        label: string;
        value: string;
      }[]
    | SwimlaneHierarchyType[]
    | StepHierarchyType[],
  yAxis: YAxisChart,
  isStepGroup: boolean
) => {
  return swimlaneOrStepsData.reduce(
    (acc: MetricAttributes[], swimlaneOrStep): MetricAttributes[] => {
      if (
        isStepGroup &&
        (swimlaneOrStep as SwimlaneHierarchyType).children?.length
      ) {
        if (
          isSwimlaneFyiReadonlyOrInternal(
            swimlaneOrStep as SwimlaneHierarchyType
          )
        ) {
          return [...acc];
        }
      } else if (
        isStepFyiReadonlyOrInternal(swimlaneOrStep as StepHierarchyType)
      ) {
        return [...acc];
      }
      const metric = {
        col_type: isStepGroup ? "step_group" : "step",
        col: "cycle_time",
        label: swimlaneOrStep.label,
        [isStepGroup ? "step_group_tag" : "step_tag"]: swimlaneOrStep.value
      };
      const mtrType =
        typeof yAxis[INDEX_ZERO] === "string"
          ? yAxis[INDEX_ZERO]
          : yAxis[INDEX_ZERO].mtr_type;
      if (mtrType === "all") {
        return [
          ...acc,
          ...CYCLE_TIME_METRICS.map(mtr => ({
            ...metric,
            mtr_type: mtr
          }))
        ] as MetricAttributes[];
      }
      return [...acc, { ...metric, mtr_type: mtrType }] as MetricAttributes[];
    },
    [] as MetricAttributes[]
  );
};

type GetMergedChartDataReturnType = {
  isVisModeChart: boolean;
  chartConfig: ChartConfig;
  chartConfigORM: ChartConfigORM;
  isORMReport: boolean;
};

/**
 * This function is used to get new isVisModeChart value
 * based on the chartConfig | chartConfigORM
 * if x-axis and y-axis are empty and isCycleTimeReport is false
 * then we want to override isVisModeChart to false
 * and will not save the chartConfig | chartConfigORM
 */

export const getIsVisModeChart = ({
  isVisModeChart,
  chartConfig,
  chartConfigORM,
  isORMReport
}: GetMergedChartDataReturnType) => {
  if (!isVisModeChart) return false;

  const xAxis = isORMReport ? chartConfigORM.groupBy : chartConfig["x-axis"];
  const yAxis = isORMReport
    ? chartConfigORM.operations.filter(
        operations => operations.extraJSON.axisName === "yAxis"
      )
    : chartConfig["y-axis"];
  const isCycleTimeReport = isORMReport
    ? chartConfigORM.otherConfigurations.isCycleTimeReport
    : chartConfig.isCycleTimeReport;

  if (!xAxis.length && !yAxis.length && !isCycleTimeReport) {
    return false;
  }
  return true;
};

export const getIsChartTypeReport = (
  report: Partial<ChildWorkflow> | undefined
): boolean => {
  return report ? !!getReportChartType(report) : false;
};
