//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Code = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Code"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3588)">
            <path
              d="M9.32528 3.00041C8.93625 3.00996 8.59847 3.27135 8.49165 3.64646L5.82403 12.5385C5.7563 12.7652 5.78148 13.0092 5.89437 13.2176C6.00639 13.426 6.19743 13.5806 6.42407 13.6483C6.65157 13.7152 6.89559 13.69 7.104 13.5762C7.31154 13.4633 7.46611 13.2723 7.53298 13.0457L10.2006 4.1536C10.2857 3.87919 10.2336 3.58048 10.0599 3.35122C9.88625 3.12285 9.61272 2.99171 9.32528 3.00041V3.00041ZM4.41375 3.66732C4.18189 3.68208 3.96481 3.78628 3.80935 3.95909L0.252526 7.73822C-0.0678994 8.08036 -0.0678994 8.6118 0.252526 8.95394L3.80935 12.7331C3.96566 12.9241 4.19405 13.0422 4.43978 13.0578C4.6864 13.0743 4.9278 12.9875 5.10755 12.8182C5.2873 12.6497 5.3889 12.4135 5.38803 12.1669C5.38629 11.9194 5.28296 11.6841 5.10146 11.5173L2.11417 8.34955L5.10146 5.17485C5.35675 4.91174 5.42449 4.51924 5.27253 4.18579C5.12056 3.85234 4.77928 3.64653 4.41371 3.66738L4.41375 3.66732ZM11.5899 3.66732C11.2278 3.65603 10.8952 3.8653 10.7484 4.19615C10.6017 4.52787 10.6711 4.91429 10.923 5.17479L13.9102 8.34948L10.923 11.5173C10.7415 11.684 10.6381 11.9193 10.6364 12.1668C10.6355 12.4134 10.7371 12.6496 10.9169 12.8181C11.0966 12.9874 11.338 13.0743 11.5846 13.0578C11.8304 13.0421 12.0588 12.924 12.2151 12.733L15.7719 8.95387C16.0923 8.61173 16.0923 8.08029 15.7719 7.73815L12.2151 3.95902C12.0544 3.78101 11.8286 3.67594 11.5898 3.66725L11.5899 3.66732Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3588">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
