//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Download = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Download"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3348)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.44355 6.64784C3.88729 6.20289 4.60602 6.20226 5.05037 6.64784L6.85346 8.45652V1.13877C6.85346 0.509688 7.36157 0 7.98905 0C8.61653 0 9.12526 0.510315 9.12526 1.13877V8.47728L10.9489 6.64784C11.3926 6.20289 12.112 6.20226 12.5557 6.64784C12.9988 7.09221 12.9994 7.81369 12.5557 8.25863L8.53584 12.2908C8.23961 12.5874 7.75963 12.588 7.46339 12.2908L3.44355 8.25863C2.99982 7.81364 2.99982 7.09279 3.44355 6.64784ZM14.9332 13.8544H1.06684C0.477485 13.8544 0 14.3346 0 14.9272C0 15.4073 0.477485 16 1.06684 16H14.9332C15.5225 16 16 15.5198 16 14.9272C16 14.447 15.5225 13.8544 14.9332 13.8544Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3348">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
