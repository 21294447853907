import {
  useLocation,
  createBrowserRouter,
  type RouteObject,
  redirect,
  useNavigate
} from "react-router";
import * as Sentry from "@sentry/react";
import { ServiceUnavailableError } from "main/src/js/components/common/ServiceUnavailableError";
import { UserInteractionRequired } from "main/src/js/components/common/UserInteractionRequired";
import { ReloadRequiredPage } from "main/src/js/components/common/ReloadRequired";
import { Header as LegacyHeader } from "main/src/modules/header/components/Header";
import { GenericNotFound } from "main/src/js/components/notfound";
import { ChartExport } from "@certa/chartreport";
import type { HeaderMode } from "@certa/common/constants";
import { RELOAD_REQUIRED_ROUTE } from "@certa/common/constants";
import {
  getDashboardsGenericRoute,
  getDashboardsRoute,
  getTasksRoute,
  getProcessDetailRoute,
  getSearchRoute,
  getCreateNewReportRoute,
  getEditReportRoute,
  getFullScreenReportRoute,
  getProcessWizardRoute,
  getHomeRoute,
  getStudioGenericRoute,
  getStudioAppDetailsRoute,
  getStudioProcessEditorRoute,
  getStudioAppSettingsRoute,
  getStudioProcessDesignerRoute,
  SERVICE_UNAVAILABLE_PATH,
  getOldFullScreenReportRoute,
  USER_INTERACTION_REQUIRED_PATH,
  getOldEditReportRoute,
  TABLEAU_PATH,
  getDataObjectTypeRoute,
  getDataObjectRoute,
  getDataObjectRouteWithoutTab,
  getStudioObjectsRoute,
  getStudioObjectsDesignerRoute,
  getStudioProcessEditorRouteWithoutProcessSavepointId,
  getStudioAppDetailsRouteWithoutSavepoint,
  getStudioAppDetailsRouteWithoutBranchAndSavepoint,
  getStudioProcessDesignerRouteWithoutProcessSavepointId,
  getStudioObjectsRouteWithoutStage,
  getFilePreviewRoute,
  WORKFLOW_DEF_JSON_EDITOR_PATH,
  getStudioAuditLogsRoute,
  getStudioPreviewRoute
} from "@certa/common/utils/routes";
import { RedirectToCertaLogin } from "./routes/public/RedirectToCertaLogin";
import { withSentryProfiler } from "./hoc/withSentryProfiler";
import { basename, siteOrigin } from "main/src/config";
import { AnonOnlyRouteWrapper } from "./wrappers/AnonOnlyRoute";
import { AppLoader } from "main/src/modules/router/AppLoader";
import { PrivateRedirect } from "./routes/PrivateRedirect";
import { AppRouter } from "main/src/modules/router/AppRouter";
import { RouteErrorBoundary } from "./wrappers/RouteErrorBoundary";

export type CustomRouteObject = Omit<RouteObject, "children"> & {
  children?: CustomRouteObject[];
  extraProps: {
    routeType: "private" | "public";
    headerMode?: HeaderMode;
    isLazy?: boolean;
    shouldPreload?: boolean;
  };
};

const dashboardSliceIndex = 3;

const RedirectToNewReportRoute = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const redirectUrl = `${document.location.origin}/${pathname
    .split("/")
    // Removes dashboard part from the url
    .slice(dashboardSliceIndex)
    // Add back search query and hash
    .join("/")}${search}${hash}`;
  redirect(redirectUrl);
  return null;
};

const RedirectToAdminPanel = () => {
  window.location.href = siteOrigin + "/admin/";
  return null;
};

const RedirectToStudioApps = () => {
  const navigate = useNavigate();
  navigate("/studio/apps/");
  return null;
};

// Components to profile with Sentry
const ChartExportWithSentryProfiler = withSentryProfiler(
  ChartExport,
  "ChartExportRoute"
);

/**
 * Routes served to non-authenticated users
 */
const publicRoutes: CustomRouteObject[] = [
  {
    path: "/login",
    lazy: () => import("./routes/public/LoginRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: "/login/certa",
    element: <RedirectToCertaLogin />,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: "/login/:loginType",
    lazy: () => import("./routes/public/LoginRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: "/login/export-chart",
    element: (
      <AnonOnlyRouteWrapper>
        <ChartExportWithSentryProfiler />
      </AnonOnlyRouteWrapper>
    ),
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: "/admin",
    element: <RedirectToAdminPanel />,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: SERVICE_UNAVAILABLE_PATH,
    element: (
      <>
        <LegacyHeader />
        <ServiceUnavailableError />
      </>
    ),
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: USER_INTERACTION_REQUIRED_PATH,
    element: <UserInteractionRequired />,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: RELOAD_REQUIRED_ROUTE,
    element: <ReloadRequiredPage />,
    extraProps: {
      routeType: "public"
    }
  },
  {
    path: "*",
    element: (
      <>
        <LegacyHeader />
        <GenericNotFound />
      </>
    ),
    extraProps: {
      routeType: "public"
    }
  }
];

/**
 * Routes served to authenticated user
 */
const privateRoutes: CustomRouteObject[] = [
  /////////////////////////////////////////////////////
  // HOME ROUTES
  /////////////////////////////////////////////////////
  {
    path: getHomeRoute(),
    lazy: () => import("./routes/private/HomeRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // DASHBOARDS ROUTES
  /////////////////////////////////////////////////////
  {
    // NOTE: This route is for general navigation when the user
    // or the code doesn't know the ID & just want to land on the dashboard
    path: getDashboardsGenericRoute(),
    lazy: () => import("./routes/private/DashboardsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getDashboardsRoute(),
    lazy: () => import("./routes/private/DashboardsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // REPORTS ROUTES
  /////////////////////////////////////////////////////
  {
    path: getCreateNewReportRoute(),
    lazy: () => import("./routes/private/CreateReportRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getEditReportRoute(),
    lazy: () => import("./routes/private/EditReportRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getFullScreenReportRoute(),
    lazy: () => import("./routes/private/FullScreenReportRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getOldEditReportRoute(),
    element: <RedirectToNewReportRoute />,
    extraProps: {
      routeType: "private",
      shouldPreload: false
    }
  },
  {
    path: getOldFullScreenReportRoute(),
    element: <RedirectToNewReportRoute />,
    extraProps: {
      routeType: "private",
      shouldPreload: false
    }
  },

  /////////////////////////////////////////////////////
  // TASKS ROUTES
  /////////////////////////////////////////////////////
  {
    path: getTasksRoute(),
    lazy: () => import("./routes/private/TasksRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // WORKFLOW ROUTES
  /////////////////////////////////////////////////////
  {
    path: getProcessDetailRoute(),
    lazy: () => import("./routes/private/ProcessDetailsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getProcessWizardRoute(),
    lazy: () => import("./routes/private/ProcessWizardRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getFilePreviewRoute(),
    lazy: () => import("./routes/private/FilePreviewerPageRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // SEARCH ROUTES
  /////////////////////////////////////////////////////
  {
    path: getSearchRoute(),
    lazy: () => import("./routes/private/SearchRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // DATA OBJECTS ROUTES
  /////////////////////////////////////////////////////
  {
    path: getDataObjectTypeRoute(),
    lazy: () => import("./routes/private/DataObjectTypeRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getDataObjectRoute(),
    lazy: () => import("./routes/private/DataObjectRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getDataObjectRouteWithoutTab(),
    lazy: () => import("./routes/private/DataObjectRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // STUDIO ROUTES
  /////////////////////////////////////////////////////
  {
    path: "/studio",
    element: <RedirectToStudioApps />,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: "/studio/",
    element: <RedirectToStudioApps />,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioGenericRoute(),
    lazy: () => import("./routes/private/StudioRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioProcessEditorRoute(),
    lazy: () => import("./routes/private/StudioProcessEditorRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioProcessEditorRouteWithoutProcessSavepointId(),
    lazy: () => import("./routes/private/StudioProcessEditorRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioAppSettingsRoute(),
    lazy: () => import("./routes/private/StudioAppDetailsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioAppDetailsRoute(),
    lazy: () => import("./routes/private/StudioAppDetailsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioAppDetailsRouteWithoutSavepoint(),
    lazy: () => import("./routes/private/StudioAppDetailsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioAppDetailsRouteWithoutBranchAndSavepoint(),
    lazy: () => import("./routes/private/StudioAppDetailsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioProcessDesignerRoute(),
    lazy: () => import("./routes/private/StudioWebGLProcessEditorRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioProcessDesignerRouteWithoutProcessSavepointId(),
    lazy: () => import("./routes/private/StudioWebGLProcessEditorRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioPreviewRoute(),
    lazy: () => import("./routes/private/StudioPreviewRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioObjectsDesignerRoute(),
    lazy: () => import("./routes/private/StudioObjectsDesignerRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioObjectsRoute(),
    lazy: () => import("./routes/private/StudioObjectsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioObjectsRouteWithoutStage(),
    lazy: () => import("./routes/private/StudioObjectsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: getStudioAuditLogsRoute(),
    lazy: () => import("./routes/private/StudioAuditLogsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // ECA EDITOR ROUTES
  /////////////////////////////////////////////////////
  {
    path: WORKFLOW_DEF_JSON_EDITOR_PATH,
    lazy: () => import("./routes/private/WorkflowDefJSONEditorRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },

  /////////////////////////////////////////////////////
  // MISCELLANEOUS ROUTES
  /////////////////////////////////////////////////////
  {
    path: TABLEAU_PATH,
    lazy: () => import("./routes/private/TableauWrapperRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: "/download/",
    lazy: () => import("./routes/private/DownloadFileRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: "/api/v1/attachment/",
    lazy: () => import("./routes/private/AttachmentViewRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: "/mysubscriptions/",
    lazy: () => import("./routes/private/MySubscriptionsRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  },
  {
    path: `/paypal/connect`,
    lazy: () => import("./routes/private/PaypalRoute"),
    HydrateFallback: AppLoader,
    extraProps: {
      routeType: "private"
    }
  }
];

export const routeList: CustomRouteObject[] = [
  {
    path: "/",
    element: <AppRouter />,
    errorElement: <RouteErrorBoundary />,
    extraProps: {
      routeType: "public"
    },
    children: [
      {
        element: <PrivateRedirect />,
        index: true,
        extraProps: {
          routeType: "public"
        }
      },
      ...publicRoutes,
      ...privateRoutes
    ]
  }
];

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routeList, {
  basename
});
