//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const MultipleFiles = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="MultipleFiles"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 2.66667C2.75 2.17575 3.14175 1.77778 3.625 1.77778H8.51256C8.74463 1.77778 8.96719 1.87143 9.13128 2.03813L12.9937 5.96187C13.1578 6.12857 13.25 6.35466 13.25 6.59041V15.1111C13.25 15.602 12.8582 16 12.375 16H3.625C3.14175 16 2.75 15.602 2.75 15.1111V2.66667ZM11.9375 7.11111V14.6667H4.0625V3.11111H8V7.11111H11.9375Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.69595 8.40339C7.96625 8.1451 8.33285 8 8.71511 8C9.09737 8 9.46398 8.1451 9.73428 8.40339C10.0046 8.66168 10.1564 9.012 10.1564 9.37727C10.1564 9.74255 10.0046 10.0929 9.73428 10.3511L8.00114 12.0073C7.83692 12.1642 7.61418 12.2524 7.38194 12.2524C7.14969 12.2524 6.92695 12.1642 6.76273 12.0073C6.5985 11.8503 6.50624 11.6375 6.50624 11.4156C6.50624 11.1936 6.5985 10.9808 6.76273 10.8239L8.49586 9.16776C8.61695 9.05205 8.81328 9.05205 8.93437 9.16776C9.05546 9.28347 9.05546 9.47107 8.93437 9.58678L7.20124 11.2429C7.15331 11.2887 7.12639 11.3508 7.12639 11.4156C7.12639 11.4803 7.15331 11.5424 7.20124 11.5882C7.24916 11.634 7.31416 11.6598 7.38194 11.6598C7.44971 11.6598 7.51471 11.634 7.56263 11.5882L9.29576 9.93212C9.44976 9.78497 9.53628 9.58538 9.53628 9.37727C9.53628 9.16916 9.44976 8.96957 9.29576 8.82242C9.14177 8.67526 8.9329 8.59259 8.71511 8.59259C8.49733 8.59259 8.28846 8.67526 8.13446 8.82242L6.40133 10.4785C6.14126 10.7271 5.99515 11.0641 5.99515 11.4156C5.99515 11.767 6.14126 12.1041 6.40133 12.3526C6.6614 12.6011 7.01414 12.7407 7.38194 12.7407C7.74973 12.7407 8.10247 12.6011 8.36254 12.3526L10.0957 10.6965C10.2168 10.5808 10.4131 10.5808 10.5342 10.6965C10.6553 10.8122 10.6553 10.9998 10.5342 11.1155L8.80105 12.7716C8.42468 13.1313 7.91421 13.3333 7.38194 13.3333C6.84966 13.3333 6.33919 13.1313 5.96282 12.7716C5.58645 12.412 5.375 11.9242 5.375 11.4156C5.375 10.907 5.58645 10.4192 5.96282 10.0595L7.69595 8.40339Z"
            fill="currentColor"
          />
          <path
            d="M13.6875 4.99673V12.8889H12.4393V14.2222H14.125C14.6083 14.2222 15 13.8243 15 13.3333V4.81263C15 4.57689 14.9078 4.35079 14.7437 4.1841L10.8813 0.26035C10.7172 0.0936505 10.4946 0 10.2626 0H5.375C4.89175 0 4.5 0.397969 4.5 0.888889V2.40632H5.8125V1.33333H10.0813L13.6875 4.99673Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
