import { Select } from "@certa/catalyst/components/Select";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { useIntl } from "react-intl";
import { useExcludedWorkflowKindTags } from "../../hooks/usePreSelectedWorkflowKinds";
import { useSearchStore } from "../../store/useSearchStore";
import { advancedFilterStyles as styles } from "./AdvancedFilter.styles";
import { AdvancedFilters } from "./AdvancedFilters";
import { useEffect, useMemo, useState } from "react";
import type { ReportCustomFilter } from "@certa/types/src/filters";
import { CompareType } from "@certa/types/src/filters";
import { classNames } from "@certa/catalyst/utils/common";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { usePrevious } from "../../../../hooks";

export const AdvancedFilterWrapper = () => {
  const intl = useIntl();

  const {
    areAdvancedFiltersVisible,
    kinds: kindsList,
    isLoadingKinds,
    selectedKindIdForAdvancedFilters,
    setSelectedKindIdForAdvancedFilters,
    areAdvancedFiltersApplied,
    advancedFilterConditions
  } = useSearchStore();
  const excludedWorkflowKindTags = useExcludedWorkflowKindTags();
  const [advancedFilterCustomFilter, setAdvancedFilterCustomFilter] =
    useState<ReportCustomFilter>({
      groups: [],
      conditions: advancedFilterConditions,
      compareType: CompareType.AND
    });

  const kindOptions = kindsList
    ? kindsList
        .filter(kind => !excludedWorkflowKindTags.includes(kind.tag))
        .map(({ name, id }) => ({
          label: name,
          value: String(id)
        }))
    : [];

  const userTypedAdvancedFilters = useMemo(() => {
    if (!advancedFilterConditions || !advancedFilterConditions.length)
      return "";

    return advancedFilterConditions.map(condition => condition.value).join(",");
  }, [advancedFilterConditions]);

  const previousUserTypedAdvancedFilters = usePrevious(
    userTypedAdvancedFilters
  );

  useEffect(() => {
    if (
      !userTypedAdvancedFilters ||
      previousUserTypedAdvancedFilters === userTypedAdvancedFilters
    )
      return;

    MixPanelActions.track(
      MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_ADVANCED_FILTER_TYPED,
      { filter_value: userTypedAdvancedFilters }
    );
  }, [userTypedAdvancedFilters, previousUserTypedAdvancedFilters]);

  const inLabel = intl.formatMessage({
    id: "common.in",
    defaultMessage: "In"
  });
  const kindTagLabel = intl.formatMessage({
    id: "workflowFiltersTranslated.filterPlaceholders.advancedFilters",
    defaultMessage: "Process Type"
  });
  const whereLabel = intl.formatMessage({
    id: "common.where",
    defaultMessage: "Where"
  });

  const onChange = (value: string) => {
    setSelectedKindIdForAdvancedFilters(value);
    if (value !== selectedKindIdForAdvancedFilters) {
      setAdvancedFilterCustomFilter({
        groups: [],
        conditions: [],
        compareType: CompareType.AND
      });
    }
  };

  if (!areAdvancedFiltersVisible) return null;

  return (
    <Stack
      direction="horizontal"
      align="start"
      padding="0 0 0 16"
      dangerouslySetClassName={classNames(
        styles.container,
        "catalystScrollContainer"
      )}
    >
      <Stack direction="horizontal" gap="8" padding="8 0" align="center">
        <Typography
          color={TypographyColors.NEUTRAL_700}
          variant={TypographyVariants.OVERLINE_SMALL}
        >
          {inLabel}
        </Typography>

        <Select
          options={kindOptions}
          value={selectedKindIdForAdvancedFilters}
          onChange={onChange}
          isLoading={isLoadingKinds}
          label={kindTagLabel}
          width="12.875rem"
        />
        {areAdvancedFiltersApplied ? (
          <Typography
            color={TypographyColors.NEUTRAL_700}
            variant={TypographyVariants.OVERLINE_SMALL}
          >
            {whereLabel}
          </Typography>
        ) : null}
      </Stack>
      {areAdvancedFiltersApplied ? (
        <AdvancedFilters
          advancedFilterCustomFilter={advancedFilterCustomFilter}
          setAdvancedFilterCustomFilter={setAdvancedFilterCustomFilter}
        />
      ) : null}
    </Stack>
  );
};
