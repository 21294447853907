import { css } from "emotion";

export const groupTagStyles = (isSelectedThread?: boolean) =>
  css({
    cursor: "pointer",
    borderRadius: "var(--small-border-radius)",
    border: `1px solid ${
      isSelectedThread ? "transparent" : "var(--colors-neutral-500)"
    }`,
    backgroundColor: isSelectedThread
      ? "var(--colors-neutral-700)"
      : "var(--colors-neutral-100)",
    "&:hover": {
      border: "1px solid transparent",
      backgroundColor: isSelectedThread
        ? "var(--colors-neutral-700)"
        : "var(--colors-neutral-300)"
    }
  });

export const memberTagStyles = css({
  width: "6.25rem",
  borderRadius: "var(--small-border-radius)",
  padding: "var(--space-4) var(--space-8)",
  backgroundColor: "var(--colors-neutral-300)"
});

export const hiddenCountPillStyles = css({
  borderRadius: "var(--small-border-radius)",
  color: "var(--colors-neutral-700)",
  cursor: "pointer",
  backgroundColor: "var(--colors-neutral-300)"
});

export const globalIconStyles = css({
  paddingBottom: "0.125rem"
});

export const overflowStyles = css({
  overflow: "hidden",
  maxWidth: "5rem",
  textOverflow: "ellipsis"
});

export const hierarchyItemStyles = (isMobileResolution: boolean) =>
  css({
    maxWidth: "max-content",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flexBasis: "0%",
    textOverflow: "ellipsis",
    flexGrow: 1
  });

export const separatorStyles = css({
  maxWidth: "min-content"
});

export const messageStyles = css({
  padding: "2px 0",
  lineClamp: 3,
  overflowWrap: "anywhere"
});

export const hierarchyFlexItemStyles = css({
  flexBasis: "0%",
  maxWidth: "max-content",
  overflow: "hidden",
  flexGrow: 1
});
