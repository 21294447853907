import lodashGet from "lodash-es/get";
// TODO check TypoGraphy
import { Text } from "@certa/blocks/thanos";
import { Select } from "@certa/catalyst/components/Select";
import { INDEX_ZERO, INDEX_ONE } from "../../constants/common";
import type { ReportCustomFilter } from "@certa/types";
import { CompareType } from "@certa/types";
import { useIntl } from "react-intl";
import type { HandleChange } from "./AdvancedFilters.types";

type ConditionTypeProps = (
  | {
      groupIndex: number;
      conditionIndex?: never;
    }
  | {
      conditionIndex: number;
      groupIndex?: never;
    }
) & {
  parentPath: (string | number)[];
  customFilterValue: ReportCustomFilter;
  onChange: HandleChange;
};

const compareTypeOptions = Object.values(CompareType).map(value => ({
  label: value,
  value
}));

export const ConditionType = ({
  conditionIndex,
  customFilterValue,
  groupIndex,
  parentPath,
  onChange
}: ConditionTypeProps) => {
  const intl = useIntl();
  const whereText = intl.formatMessage({
    id: "common.where",
    defaultMessage: "Where"
  });
  const compareTypeText = intl.formatMessage({
    id: "common.compareType",
    defaultMessage: "Compare Type"
  });

  const group = parentPath.length
    ? lodashGet<ReportCustomFilter, string, ReportCustomFilter>(
        customFilterValue,
        parentPath.join("."),
        { conditions: [], groups: [], compareType: CompareType.AND }
      )
    : customFilterValue;

  const shouldShowWhere =
    conditionIndex === INDEX_ZERO ||
    (groupIndex === INDEX_ZERO && !group.conditions.length);

  const shouldShowSelectField =
    conditionIndex === INDEX_ONE ||
    (groupIndex === INDEX_ZERO && group.conditions.length === 1) ||
    (groupIndex === INDEX_ONE && group.conditions.length === 0);

  const handleChange = (value: string) => {
    // value should have CompareType type, but Select component is not providing it
    // once it is fixed, we can remove the type assertion
    onChange(
      [...parentPath, "compareType"],
      value as ReportCustomFilter["compareType"]
    );
  };

  if (shouldShowWhere) {
    return <Text className="conditionTypeText">{whereText}</Text>;
  } else if (shouldShowSelectField) {
    return (
      <Select
        menuPortalTarget={document.body}
        label={compareTypeText}
        options={compareTypeOptions}
        width="90px"
        isClearable={false}
        onChange={handleChange}
        value={group.compareType}
      />
    );
  }
  return <Text className="conditionTypeText">{group.compareType}</Text>;
};
