//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Dots = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Dots"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99935 8C3.99935 9.10455 3.1039 10 1.99935 10C0.894802 10 0 9.10455 0 8C0 6.89545 0.894818 6 1.99935 6C3.1039 6 3.99935 6.89545 3.99935 8ZM9.96503 8C9.96503 9.10455 9.06957 10 7.96503 10C6.86048 10 5.96503 9.10455 5.96503 8C5.96503 6.89545 6.86048 6 7.96503 6C9.06957 6 9.96503 6.89545 9.96503 8ZM13.9307 10C15.0352 10 15.93 9.10455 15.93 8C15.93 6.89545 15.0352 6 13.9307 6C12.8261 6 11.9307 6.89545 11.9307 8C11.9307 9.10455 12.8261 10 13.9307 10Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
