import { BaseDialog } from "@certa/catalyst/components/Dialog/BaseDialog";
import { ErrorBoundary } from "@certa/common/components/ErrorBoundary";
import { useNavigationDrawer } from "../NavigationSidebar";
import { SearchLayout } from "./components/SearchLayout";
import { useSearchStore } from "./store/useSearchStore";
import { invalidateGlobalSearch } from "@certa/queries/hooks/globalSearch.hook";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useDebouncedValue } from "../../hooks/useDebounce";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

const KEYWORD_SEARCH_TIME = 1000;

const GlobalSearchNext = () => {
  const { isSearchModalOpen, setIsSearchModalOpen } = useNavigationDrawer();
  const { isChildDialogOpen, setSelectedItem, query } = useSearchStore();
  const location = useLocation();
  const debouncedQuery = useDebouncedValue(query, KEYWORD_SEARCH_TIME);
  const searchCountRef = useRef(0);

  const handleSearchDialogClose = useCallback(() => {
    setIsSearchModalOpen(false);
    setSelectedItem(0);
    searchCountRef.current = 0;
  }, [setIsSearchModalOpen, setSelectedItem]);

  useEffect(() => {
    if (!isSearchModalOpen) return;
    setSelectedItem(0);
    invalidateGlobalSearch();
  }, [isSearchModalOpen, setSelectedItem]);

  // Close the search modal when the user navigates away from the page
  useLayoutEffect(() => {
    if (!isSearchModalOpen) return;
    handleSearchDialogClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!debouncedQuery || !debouncedQuery.trim()) return;
    searchCountRef.current += 1;
    MixPanelActions.track(
      MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_KEYWORD_SEARCH,
      {
        keyword: debouncedQuery,
        keyword_count: searchCountRef.current
      }
    );
  }, [debouncedQuery]);

  return (
    <BaseDialog
      show={isSearchModalOpen}
      onClose={handleSearchDialogClose}
      width="90%"
      maxWidth="1200px"
      height="80%"
      shouldDisableDialogClose={isChildDialogOpen}
      aria-label="Global Search"
    >
      <ErrorBoundary>
        <SearchLayout onClose={handleSearchDialogClose} />
      </ErrorBoundary>
    </BaseDialog>
  );
};

export { GlobalSearchNext };
