import {
  useListInteraction,
  type ListInteractionMode
} from "./useListInteraction";
import styles from "./useVirtualList/Style.module.css";
import { useEffect } from "react";
import { useCallbackRef } from "./useCallbackRef";

export type UseListProps = {
  isCombobox?: boolean;
  onSelect?: (index: number, interactionMode: ListInteractionMode) => void;
  activeIndex: number;
  selectedIndex: number;
  setActiveIndex: (index: number) => void;
  interactionMode?: ListInteractionMode;
  setInteractionMode: (mode: ListInteractionMode) => void;
  getOptionId: (index: number) => string;
  totalItemsCount: number;
};

export function useList({
  isCombobox = false,
  onSelect,
  activeIndex,
  setActiveIndex,
  selectedIndex,
  interactionMode = "mouse",
  setInteractionMode,
  getOptionId,
  totalItemsCount
}: UseListProps) {
  const getOptionIdCallbackRef = useCallbackRef(getOptionId);

  const { handleKeyDown, handleMouseMove, handleItemMouseEnter, handleSelect } =
    useListInteraction({
      totalItemsCount,
      activeIndex,
      setActiveIndex,
      interactionMode,
      setInteractionMode,
      getOptionId,
      onSelect
    });

  // Scroll selected item into view only when using keyboard nav
  useEffect(() => {
    if (activeIndex >= 0) {
      const element = document.getElementById(
        getOptionIdCallbackRef(activeIndex)
      );
      if (!element) return;
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }, [activeIndex, getOptionIdCallbackRef]);

  // Get the ID of the currently focused option
  const getActiveDescendantId = () =>
    activeIndex >= 0 ? getOptionId(activeIndex) : undefined;

  const getItemProps = (index: number) => {
    const isSelected = index === selectedIndex;
    const isActive = index === activeIndex;

    return {
      isSelected,
      optionProps: {
        role: "option",
        id: getOptionId(index),
        "aria-selected": isSelected,
        onMouseEnter: () => handleItemMouseEnter(index),
        onClick: () => handleSelect(index, "mouse"),
        tabIndex: isActive ? 0 : -1
      }
    };
  };

  const getListboxProps = ({ label }: { label: string }) => ({
    role: "listbox",
    "aria-label": label,
    ...(isCombobox
      ? {}
      : {
          "aria-activedescendant": getActiveDescendantId()
        }),
    onKeyDown: handleKeyDown,
    onMouseMove: handleMouseMove,
    tabIndex: -1,
    className: interactionMode === "keyboard" ? styles.listboxCursorNone : ""
  });

  return {
    getListboxProps,
    getItemProps,
    state: {
      activeDescendantId: getActiveDescendantId()
    }
  };
}
