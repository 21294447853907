/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC, ReactNode } from "react";
import { memo } from "react";
import { useIntl } from "react-intl";
import type { OnChangeHandlerFunc, SuggestionDataItem } from "react-mentions";
import { Mention, MentionsInput } from "react-mentions";
import type { CommentMention } from "@certa/types";
import { MentionListItem } from "./MentionListItem";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";

const MENTIONS_MARKUP = "~[__display__](__id__)";

// Below markup is used to show invalid mentions in red color
const INVALID_MENTIONS_MARKUP = "-[__display__](__id__)";

const fontStylings = `
  font-weight: 500 !important;
  font-family: "Inter", sans-serif, "system-ui" !important;
  font-size: 14px !important;
  line-height: 22px;
`;

const commonLayoutStylings = `
  max-height: 30vh;
  min-height: 40px;
  padding: var(--s2) var(--s4);
  border: 2px solid transparent !important;
`;

const INPUT_STYLES = (
  isMobileResolution: boolean,
  customHeight?: number
) => css`
  .comments-textarea-new__control {
    height: ${isMobileResolution && "2.625rem !important"};
    ${customHeight && `height: ${customHeight}px;`};
    &:focus-within {
      .comments-textarea-new__highlighter {
        background-color: white !important;
      }
    }
  }

  /* Suggestions input + highlighter stylings */
  .comments-textarea-new__input {
    margin: 0px;
    border-radius: var(--s1) !important;
    border: none !important;
    transition: border 0s !important;
    color: var(--neutral-100) !important;
    /* width: 100% !important; */
    overflow-y: auto !important;
    ${commonLayoutStylings}
    ${fontStylings}
    &::placeholder {
      font-size: 12px !important;
      font-weight: 400 !important;
      color: var(--neutral-70) !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:placeholder-shown {
      text-overflow: ellipsis;
    }
    &:focus {
      border: 2px solid var(--colors-brand-400) !important;
      outline: 0px !important;
    }
  }
  .comments-textarea-new__highlighter {
    background-color: var(--colors-neutral-100) !important;
    border-radius: var(--s1) !important;
    ${commonLayoutStylings}
    ${fontStylings}
  }

  /* Suggestions list stylings */
  .comments-textarea-new__suggestions__list {
    background-color: var(--neutral-0);
    box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);
    border-radius: var(--s2);
    max-height: 323px;
    width: ${isMobileResolution ? "18rem" : "26rem"};
    overflow-y: scroll;
    padding: var(--s4) !important;
  }
  .comments-textarea-new__suggestions__item--focused {
    background-color: var(--brand-35);
    border-radius: var(--s2);
    span {
      font-weight: var(--bold);
    }
  }
`;

export type MenionSupportedInputProps = {
  onChange?: OnChangeHandlerFunc;
  disabled: boolean;
  mentions: CommentMention[];
  message: string;
  onSubmit?: Function;
  placeholder?: string;
  customHeight?: number;
  autoFocus?: boolean;
};

/**
 * CommentMentionsInput houses the MentionsInput component with props set
 * according to the needs to comment feature, plugged in with available
 * mentions and ability to post comment on Enter (or not).
 */
export const MentionSupportedInput: FC<MenionSupportedInputProps> = memo(
  props => {
    const intl = useIntl();
    const {
      onChange,
      onSubmit,
      disabled: isDisabled,
      mentions,
      message,
      placeholder = intl.formatMessage({
        id: "comments.commentsMessageInputPlaceholder",
        defaultMessage: "Type your comment here... Use @ to mention people."
      })
    } = props;

    const { isMobileResolution } = useScreenResolution();
    /**
     * handleOnKeypress is responsible for posting message when user presses
     * the Shift key + enter key. To add a new line, user can use Enter, as usual.
     */
    const handleOnKeypress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!e.shiftKey && e.key === "Enter") {
        onSubmit?.();
      }
    };

    const renderSuggestionList = (
      entry: SuggestionDataItem,
      search: any,
      highlightedDisplay: ReactNode,
      index: number
    ) => (
      <MentionListItem
        label={entry.display}
        isUserGroup={String(entry.id).includes("g")}
      />
    );

    return (
      <MentionsInput
        value={message}
        onChange={onChange}
        allowSpaceInQuery
        allowSuggestionsAboveCursor
        placeholder={placeholder}
        disabled={isDisabled}
        onKeyPress={handleOnKeypress}
        autoFocus={props.autoFocus}
        aria-label={"mention-input"}
        className="comments-textarea-new"
        css={INPUT_STYLES(isMobileResolution, props?.customHeight)}
      >
        <Mention
          appendSpaceOnAdd={true}
          trigger="@"
          displayTransform={(id: string, display: string) => `@${display}`}
          data={mentions}
          markup={MENTIONS_MARKUP}
          renderSuggestion={renderSuggestionList}
          css={css`
            border-radius: var(--s1);
            background-color: var(--colors-blueberry-200);
          `}
        />
        {/* 
          Below mention is used to show invalid mentions in red color 
          We know that this is not the right way to do it.
          But With given react-mentions library, we are not able to find any other way to do it.
        */}
        <Mention
          appendSpaceOnAdd={true}
          trigger="@"
          displayTransform={(id: string, display: string) => {
            return `@${display}`;
          }}
          data={[]}
          markup={INVALID_MENTIONS_MARKUP}
          renderSuggestion={renderSuggestionList}
          css={css`
            border-radius: var(--s1);
            background-color: var(--colors-red-200);
            border: 1px dotted var(--colors-red-600);
          `}
        />
      </MentionsInput>
    );
  }
);
