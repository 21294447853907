import type { CellData, Data } from "../types";
import {
  AVERAGE_LABEL,
  GRAND_AVERAGE_LABEL,
  GRAND_SUB_TOTAL_LABEL,
  SUB_TOTAL_LABEL,
  SUB_TOTAL_INDEX,
  AVERAGE_INDEX
} from "../constants";
import { cloneDeep } from "lodash-es";
import { generateColumnKey } from "./dataColumns";
import { isStatsColumn } from "./cellUtils";

const FIRST_X_AXIS_LABELS = 0;
const ROUND_UP = 2;
const DEFAULT_VALUE_ZERO = 0;
const INCREMENT_BY_ONE = 1;

export const getAxesLabels = (axiskeys: string[], data: Data) => {
  const sets = axiskeys.reduce<Record<string, Set<string>>>((newSets, key) => {
    newSets[key] = new Set<string>();
    return newSets;
  }, {});
  data.forEach(cellData => {
    axiskeys.forEach(key => {
      if (cellData[key] !== undefined) sets[key].add(`${cellData[key]}`);
    });
  });
  return Object.keys(sets).map(setKey => Array.from(sets[setKey]));
};

/**
 * Determines if a header should be shown based on its type and group depth
 */
export const shouldShowHeader = (
  columnId: string,
  groupDepth: number,
  yAxisKeys: string[],
  xAxisKeys: string[]
) => {
  // Stats columns should only show at depth 0
  if (isStatsColumn(columnId)) {
    return groupDepth === 0;
  }

  // Y-axis columns should only show at depth 1
  if (yAxisKeys.includes(columnId)) {
    return groupDepth === 1;
  }

  // For single level X-axis, hide middle row except for y-axis and stats
  if (xAxisKeys.length === 1 && groupDepth === 1) {
    return yAxisKeys.includes(columnId) || isStatsColumn(columnId);
  }

  return true;
};

type GetTranslatedDataParams = {
  data: Data;
  xAxisKeys: string[];
  yAxisKeys: string[];
  multipleXAxisLabels: string[][];
  valueKey: string;
  shouldDisplayValueAsPercentage?: boolean;
  percentageCalculationType?: "row" | "column" | "table";
};

type RowWithPercentage = Record<string, CellData> & {
  percentage: Record<string, number>;
};

const calculatePercentage = (
  value: number,
  calculationType: "row" | "column" | "table",
  rowSubTotal: number,
  columnSubTotal: number,
  grandSubTotal: number
): number => {
  switch (calculationType) {
    case "row":
      return Number(((value / rowSubTotal) * 100).toFixed(ROUND_UP));
    case "column":
      return Number(((value / columnSubTotal) * 100).toFixed(ROUND_UP));
    case "table":
      return Number(((value / grandSubTotal) * 100).toFixed(ROUND_UP));
    default:
      return value;
  }
};

export const getTranslatedData = ({
  data,
  xAxisKeys,
  yAxisKeys,
  multipleXAxisLabels,
  valueKey,
  shouldDisplayValueAsPercentage,
  percentageCalculationType = "row"
}: GetTranslatedDataParams) => {
  if (!multipleXAxisLabels[FIRST_X_AXIS_LABELS])
    return {
      transformData: [],
      minValue: undefined,
      maxValue: undefined,
      columnSubTotalAndAvg: [{}, {}]
    };

  const yAxesLabelsData = data.map(eachData => {
    const newObj: Record<string, CellData> = {}; // Add index signature
    yAxisKeys.forEach(yAxisKey => {
      newObj[yAxisKey] = eachData[yAxisKey];
    });
    return newObj;
  });
  const yAxesLabelsDataWithUniqueValues = [
    ...new Set(yAxesLabelsData.map(obj => JSON.stringify(obj)))
  ].map(str => JSON.parse(str));

  const transformData: Record<string, CellData>[] = [];
  yAxesLabelsDataWithUniqueValues?.forEach(eachRowRecord => {
    const innerObj: Record<string, CellData> = {};
    data.forEach(eachData => {
      let keyIndex: string | undefined;
      if (
        yAxisKeys.every(
          yAxisKey => eachRowRecord[yAxisKey] === eachData[yAxisKey]
        )
      ) {
        // Generate a unique key using the updated logic
        const reversedXAxisKeys = xAxisKeys.slice().reverse();
        const hierarchy = reversedXAxisKeys.map(
          xAxisKey => `${eachData[xAxisKey]}`
        );
        const label = hierarchy.pop();
        if (label) {
          keyIndex = generateColumnKey(label, hierarchy);
          innerObj[keyIndex] = eachData[valueKey];
          yAxisKeys.forEach(yAxisKey => {
            innerObj[yAxisKey] = eachData[yAxisKey];
          });
        }
      }
    });
    if (innerObj) transformData.push(innerObj);
  });

  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;

  const emptyColumnSubTotal: Record<string, CellData> = {};
  cloneDeep(transformData).forEach(eachRowRecord => {
    Object.keys(eachRowRecord).forEach(label => {
      if (label.startsWith("$$$") && label.endsWith("$$$")) {
        emptyColumnSubTotal[label] = DEFAULT_VALUE_ZERO;
      }
    });
  });

  const columnSubTotal = [
    cloneDeep(emptyColumnSubTotal),
    cloneDeep(emptyColumnSubTotal)
  ];

  transformData.forEach(row => {
    let numberValueCount = DEFAULT_VALUE_ZERO;
    row[SUB_TOTAL_LABEL] = DEFAULT_VALUE_ZERO;

    Object.keys(row).forEach(label => {
      if (
        label.startsWith("$$$") &&
        label.endsWith("$$$") &&
        !Number.isNaN(row[label])
      ) {
        const value = Number(row[label]);
        columnSubTotal[SUB_TOTAL_INDEX][label] =
          Number(columnSubTotal[SUB_TOTAL_INDEX][label]) + value;
        columnSubTotal[AVERAGE_INDEX][label] =
          Number(columnSubTotal[AVERAGE_INDEX][label]) + INCREMENT_BY_ONE;

        numberValueCount += INCREMENT_BY_ONE;
        row[SUB_TOTAL_LABEL] = Number(row[SUB_TOTAL_LABEL]) + value;

        minValue = minValue === undefined ? value : Math.min(minValue, value);
        maxValue = maxValue === undefined ? value : Math.max(maxValue, value);
      }
    });

    row[AVERAGE_LABEL] = Number(
      (numberValueCount
        ? row[SUB_TOTAL_LABEL] / numberValueCount
        : DEFAULT_VALUE_ZERO
      ).toFixed(ROUND_UP)
    );
  });

  let grandSubTotal = DEFAULT_VALUE_ZERO;
  let totalNumberValueCount = DEFAULT_VALUE_ZERO;

  // calculate grandSubTotal and totalNumberValueCount
  Object.keys(columnSubTotal[SUB_TOTAL_INDEX]).forEach(xAxesLabel => {
    const subTotal = Number(columnSubTotal[SUB_TOTAL_INDEX][xAxesLabel]);
    const numberValueCount = Number(columnSubTotal[AVERAGE_INDEX][xAxesLabel]);
    grandSubTotal = grandSubTotal + subTotal;
    totalNumberValueCount = totalNumberValueCount + numberValueCount;
  });

  const updatedTransformedData = shouldDisplayValueAsPercentage
    ? transformData.map(row => {
        const rowWithPercentage = {
          ...row,
          percentage: {} as Record<string, number>
        } as RowWithPercentage;

        Object.keys(row).forEach(label => {
          if (
            label.startsWith("$$$") &&
            label.endsWith("$$$") &&
            !Number.isNaN(row[label])
          ) {
            const value = Number(row[label]);
            const percentage = calculatePercentage(
              value,
              percentageCalculationType,
              Number(row[SUB_TOTAL_LABEL]),
              Number(columnSubTotal[SUB_TOTAL_INDEX][label]),
              grandSubTotal
            );
            rowWithPercentage.percentage[label] = percentage;
          }
        });
        return rowWithPercentage;
      })
    : transformData;

  const columnAvgRow = Object.keys(columnSubTotal[SUB_TOTAL_INDEX]).reduce<
    Record<string, number>
  >((rows, xAxesLabel) => {
    const subTotal = Number(columnSubTotal[SUB_TOTAL_INDEX][xAxesLabel]);
    const numberValueCount = Number(columnSubTotal[AVERAGE_INDEX][xAxesLabel]);
    grandSubTotal = grandSubTotal + subTotal;
    totalNumberValueCount = totalNumberValueCount + numberValueCount;
    rows[xAxesLabel] = Number(
      (numberValueCount
        ? subTotal / numberValueCount
        : DEFAULT_VALUE_ZERO
      ).toFixed(ROUND_UP)
    );

    return rows;
  }, {});

  const yAxisSubTotalKeys = yAxisKeys.reduce((newObj, key) => {
    return { ...newObj, [key]: SUB_TOTAL_LABEL };
  }, {});

  const yAxisAvgKeys = yAxisKeys.reduce((newObj, key) => {
    return { ...newObj, [key]: AVERAGE_LABEL };
  }, {});

  const columnSubTotalAndAvg = [
    {
      ...columnSubTotal[SUB_TOTAL_INDEX],
      ...yAxisSubTotalKeys,
      [GRAND_SUB_TOTAL_LABEL]: grandSubTotal
    },
    {
      ...columnAvgRow,
      ...yAxisAvgKeys,
      [GRAND_AVERAGE_LABEL]: Number(
        (totalNumberValueCount
          ? grandSubTotal / totalNumberValueCount
          : DEFAULT_VALUE_ZERO
        ).toFixed(ROUND_UP)
      )
    }
  ];

  return {
    transformData: updatedTransformedData,
    dataSource: updatedTransformedData,
    minValue,
    maxValue,
    columnSubTotalAndAvg
  };
};
