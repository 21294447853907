import styles from "./Alert.module.css";

import { classNames } from "../../utils/common";

import { Info } from "../../icons/Info";
import { Error } from "../../icons/Error";
import { Warning } from "../../icons/Warning";
import { Tick } from "../../icons/Tick";
import { Close } from "@certa/icons/components/Close";

export enum AlertTypes {
  SUCCESS = "Success",
  WARNING = "Warning",
  ERROR = "Error",
  INFO = "Info"
}

type AlertDefaultProps = {
  message: string | JSX.Element;
  type?: AlertTypes;
  hasIcon?: boolean;
  width?: string;
  align?: "left" | "center";
  actionText?: string;
};

export type AlertActionProps =
  | {
      onAction?: undefined;
      showAction?: undefined;
      align?: "left" | "center";
    }
  | {
      showAction?: boolean;
      onAction: () => void;
      align?: never;
    };

export type AlertProps = AlertDefaultProps & AlertActionProps;

const getAlertContentAlignment = (align?: "left" | "center") => {
  switch (align) {
    case "left":
      return "flex-start";
    default:
      return "center";
  }
};

const getAlertTypeIcon = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return <Tick />;
    case AlertTypes.WARNING:
      return <Warning />;
    case AlertTypes.INFO:
      return <Info />;
    case AlertTypes.ERROR:
      return <Error />;
  }
};

export const Alert = (props: AlertProps) => {
  const {
    width,
    type = AlertTypes.INFO,
    message,
    hasIcon = false,
    actionText,
    showAction: shouldShowAction,
    onAction,
    align
  } = props;

  return (
    <div
      role="alert"
      className={classNames({
        [styles.catalystAlert]: true,
        [styles["catalystAlert" + type]]: true
      })}
      style={{
        width,
        justifyContent: shouldShowAction
          ? "space-between"
          : getAlertContentAlignment(align)
      }}
    >
      <div className={styles.catalystAlertContent}>
        {hasIcon && (
          <div
            className={styles.catalystAlertIcon}
            data-testid="catalyst-alert-icon"
          >
            {getAlertTypeIcon(type)}
          </div>
        )}
        <div>{message}</div>
      </div>
      {shouldShowAction && (
        <button
          className={classNames(
            styles.catalystAlertAction,
            actionText && styles.catalystAlertActionWithText
          )}
          onClick={onAction}
          aria-label={actionText || "Close"}
        >
          {actionText || <Close className={styles.catalystActionIcon} />}
        </button>
      )}
    </div>
  );
};
