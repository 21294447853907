import { useEnableV3SearchAPI } from "../../toggles";
import { SearchDropdownWrapper } from "./SearchDropdownWrapper";
import { GlobalSearch } from "../GlobalSearch";
import { useEnableGlobalSearchNext } from "../../toggles/useEnableGlobalSearchNext";
import { GlobalSearchNext } from "../GlobalSearchNext/GlobalSearchNext";

const SearchSwitch = () => {
  const enableV3SearchAPI = useEnableV3SearchAPI();
  const shouldUseGlobalSearchNext = useEnableGlobalSearchNext();

  if (shouldUseGlobalSearchNext) {
    return <GlobalSearchNext />;
  } else if (enableV3SearchAPI) {
    return <GlobalSearch />;
  } else {
    return <SearchDropdownWrapper />;
  }
};

export { SearchSwitch as SearchDropdown };
