import React, { forwardRef } from "react";

import { classNames } from "../../utils/common";
import { isBackgroundColorDark } from "../../utils/styles";

import { Close } from "@certa/icons/components/Close";

import styles from "./Tag.module.css";

export enum TagColors {
  NEUTRAL = "Neutral",
  TEAL = "Teal",
  RED = "Red",
  GREEN = "Green",
  PURPLE = "Purple",
  ORANGE = "Orange",
  BLUEBERRY = "Blueberry",
  BRICK = "Brick",
  BLUE = "Blue",
  PINK = "Pink",
  // TODO: Rename key to BRAND - https://thevetted.atlassian.net/browse/PLAT-20977
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Brand = "Brand"
}

export enum TagVariants {
  OUTLINE = "Outline",
  FILLED = "Filled"
}

export type TagSizes = "Small" | "Medium";

export type TagTypes = "Text" | "Icon";

type TagIconProps =
  | {
      type: "Icon";
      icon: React.ReactElement;
    }
  | {
      type: "Text";
      icon?: React.ReactElement;
    }
  | {
      type?: TagTypes;
      icon?: React.ReactElement;
    };

type TagProps = {
  label: string;
  children: React.ReactNode;
  color?: TagColors;
  variant?: TagVariants;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  multiline?: boolean;
  onRemove?: () => void;
  tabIndex?: number;
  "aria-label"?: string;
  "aria-describedby"?: string;
  onMouseEnter?: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (evt: React.MouseEvent<HTMLElement>) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
  onPointerDown?: (
    evt: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
  onKeyDown?: (
    evt: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
  backgroundColor?: string;
  hasBorder?: boolean;
  size?: TagSizes;
} & TagIconProps;

export const Tag = forwardRef<HTMLButtonElement | HTMLDivElement, TagProps>(
  (props, ref) => {
    const {
      label,
      children,
      color = TagColors.NEUTRAL,
      type = "Text",
      variant = TagVariants.OUTLINE,
      size = "Small",
      icon,
      onClick,
      multiline: isMultiline,
      onRemove,
      tabIndex,
      "aria-label": ariaLabel,
      "aria-describedby": ariaDescribedBy,
      onMouseEnter,
      onMouseLeave,
      onKeyDown,
      onPointerDown,
      backgroundColor,
      hasBorder = true,
      onBlur,
      onFocus
    } = props;

    const isClickable = Boolean(onClick) || Boolean(onPointerDown);

    // TODO: To make clickable tags with remove icon accessible.
    // Also add styling for hover and focus states. (Waiting for designs)

    const customColor =
      backgroundColor &&
      // TODO: To use WCAG based contrast colors
      (isBackgroundColorDark(backgroundColor)
        ? "var(--colors-neutral-100)"
        : "var(--colors-neutral-800)");

    return isClickable ? (
      <button
        aria-label={label}
        aria-describedby={ariaDescribedBy}
        className={classNames({
          [styles.catalystTag]: true,
          [styles["catalystTag" + variant]]: true,
          [styles["catalystTag" + color]]: true,
          [styles.catalystTagSelectable]: isClickable,
          [styles.catalystTagMultiline]: isMultiline,
          [styles["catalystTag" + size]]: true,
          [styles["catalystTagType" + type]]: true
        })}
        style={{
          backgroundColor,
          color: customColor,
          border: hasBorder ? undefined : "none"
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onKeyDown={onKeyDown}
        onPointerDown={onPointerDown}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        {icon && <div className={styles.catalystTagIcon}>{icon}</div>}
        {type === "Text" && (
          <>
            {children}
            <CatalystTagRemoveIcon
              label={label}
              onRemove={onRemove}
              backgroundColor={backgroundColor}
              color={customColor}
            />
          </>
        )}
      </button>
    ) : (
      <div
        className={classNames({
          [styles.catalystTag]: true,
          [styles["catalystTag" + variant]]: true,
          [styles["catalystTag" + color]]: true,
          [styles.catalystTagMultiline]: isMultiline,
          [styles["catalystTag" + size]]: true,
          [styles["catalystTagType" + type]]: true
        })}
        style={{
          backgroundColor,
          color: customColor,
          border: hasBorder ? undefined : "none"
        }}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedBy}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {icon && <div className={styles.catalystTagIcon}>{icon}</div>}
        {children}
        <CatalystTagRemoveIcon
          label={label}
          onRemove={onRemove}
          backgroundColor={backgroundColor}
          color={customColor}
          aria-describedby={ariaDescribedBy}
        />
      </div>
    );
  }
);

type CatalystTagRemoveIconProps = {
  label: string;
  onRemove?: () => void;
  backgroundColor?: string;
  color?: string;
  "aria-describedby"?: string;
};

const CatalystTagRemoveIcon = (props: CatalystTagRemoveIconProps) => {
  const {
    label,
    onRemove,
    backgroundColor,
    color,
    "aria-describedby": ariaDescribedBy
  } = props;

  return !!onRemove ? (
    <button
      aria-label={"Remove tag " + label}
      aria-describedby={ariaDescribedBy}
      className={styles.catalystTagRemoveIcon}
      onClick={onRemove}
      style={{ backgroundColor, color }}
    >
      <Close size={10} />
    </button>
  ) : null;
};

export const isTagColor = (color: string): color is TagColors => {
  return Object.values(TagColors).includes(color as TagColors);
};
