//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Array = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Array"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3H2C0.895431 3 0 3.89543 0 5V11C0 12.1046 0.895431 13 2 13H3V11.5H2C1.72386 11.5 1.5 11.2761 1.5 11V5C1.5 4.72386 1.72386 4.5 2 4.5H3V3ZM13 3H14C15.1046 3 16 3.89543 16 5V11C16 12.1046 15.1046 13 14 13H13V11.5H14C14.2761 11.5 14.5 11.2761 14.5 11V5C14.5 4.72386 14.2761 4.5 14 4.5H13V3ZM4.1 8.95C4.70751 8.95 5.2 8.45751 5.2 7.85C5.2 7.24249 4.70751 6.75 4.1 6.75C3.49249 6.75 3 7.24249 3 7.85C3 8.45751 3.49249 8.95 4.1 8.95ZM9.2 7.85C9.2 8.45751 8.70751 8.95 8.1 8.95C7.49249 8.95 7 8.45751 7 7.85C7 7.24249 7.49249 6.75 8.1 6.75C8.70751 6.75 9.2 7.24249 9.2 7.85ZM12.1 8.95C12.7075 8.95 13.2 8.45751 13.2 7.85C13.2 7.24249 12.7075 6.75 12.1 6.75C11.4925 6.75 11 7.24249 11 7.85C11 8.45751 11.4925 8.95 12.1 8.95Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
