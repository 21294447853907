import type { TDateRangeValue } from "@certa/catalyst/components/DatePicker/DateRangePicker/DateRangePicker";
import { DateRangePicker } from "@certa/catalyst/components/DatePicker/DateRangePicker/DateRangePicker";
import { PopoverDialog } from "@certa/catalyst/components/PopoverDialog";
import { PopperPlacements } from "@certa/catalyst/components/types";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { SEARCH_FILTER_DATE_FORMAT } from "@certa/queries/services/globalSearch.services";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useSearchStore } from "../../store/useSearchStore";
import { FilterTrigger } from "../FilterTrigger";

const DISPLAY_FORMAT = "D MMM YYYY"; // e.g. "8 Oct 2024"

export const DateFilter = () => {
  const { filters, setFilters } = useSearchStore();
  const { startDate, endDate } = filters;
  const intl = useIntl();

  const handleDateRangeChange = (dateRange: TDateRangeValue) => {
    const updatedStartDate = dateRange?.[0]
      ?.startOf("day")
      .utc()
      .format(SEARCH_FILTER_DATE_FORMAT);
    const updatedEndDate = dateRange?.[1]
      ?.endOf("day")
      .utc()
      .format(SEARCH_FILTER_DATE_FORMAT);

    setFilters({
      startDate: updatedStartDate,
      endDate: updatedEndDate
    });
  };

  const formattedDateRange = React.useMemo(() => {
    const beforeLabel = intl.formatMessage({
      id: "common.before",
      defaultMessage: "Before"
    });
    const afterLabel = intl.formatMessage({
      id: "common.after",
      defaultMessage: "After"
    });
    const toLabel = intl.formatMessage({
      id: "common.to",
      defaultMessage: "To"
    });
    const startMoment = startDate ? moment(startDate) : null;
    const endMoment = endDate ? moment(endDate) : null;

    if (startMoment?.isValid() && endMoment?.isValid()) {
      return `${startMoment.format(DISPLAY_FORMAT)} ${toLabel} ${endMoment.format(DISPLAY_FORMAT)}`;
    }

    if (startMoment?.isValid() && !endMoment) {
      return `${afterLabel} ${startMoment.format(DISPLAY_FORMAT)}`;
    }

    if (!startMoment && endMoment?.isValid()) {
      return `${beforeLabel} ${endMoment.format(DISPLAY_FORMAT)}`;
    }

    return null;
  }, [startDate, endDate, intl]);

  const currentValue = React.useMemo((): TDateRangeValue => {
    const startMoment = startDate ? moment(startDate) : null;
    const endMoment = endDate ? moment(endDate) : null;
    return [
      startMoment?.isValid() ? startMoment : null,
      endMoment?.isValid() ? endMoment : null
    ];
  }, [startDate, endDate]);

  const startDateText = intl.formatMessage({
    id: "report.startDate",
    defaultMessage: "Start date"
  });
  const endDateText = intl.formatMessage({
    id: "report.endDate",
    defaultMessage: "End date"
  });
  const dateLabel = intl.formatMessage({
    id: "workflowFiltersTranslated.filterPlaceholders.dateRange",
    defaultMessage: "Date"
  });
  const displayText = `${dateLabel}${formattedDateRange ? `: ${formattedDateRange}` : ""}`;

  return (
    <PopoverDialog.Root>
      <PopoverDialog.Trigger>
        <FilterTrigger
          label={displayText}
          isActive={Boolean(formattedDateRange)}
        />
      </PopoverDialog.Trigger>
      <PopoverDialog.Content label="Date Range Filter">
        <Stack padding="8" gap="8">
          <DateRangePicker
            ariaLabel={[startDateText, endDateText]}
            placeholder={[startDateText, endDateText]}
            value={currentValue}
            onChange={handleDateRangeChange}
            maxDate={moment()}
            placement={[PopperPlacements.AUTO, PopperPlacements.AUTO]}
          />
        </Stack>
      </PopoverDialog.Content>
    </PopoverDialog.Root>
  );
};
