import type { ShowAxisLabel } from "@certa/common/types";

type Props = {
  label?: string;
  showAxisLabel?: ShowAxisLabel;
  viewBox?: any;
};

export const CustomXAxesLabel = (props: Props) => {
  const { label = "", showAxisLabel, viewBox } = props;

  // This is magic number, any changes in chart's left, right margin,
  // or CustomLegend position requires this to be updated
  const offset = 20;
  const x = (viewBox.width + viewBox.x) / 2 + 3 * offset;
  const ellipsis = label.length > 240 ? "..." : "";
  if (showAxisLabel === "none") return null;
  return label.length <= 100 ? (
    <text
      x={x}
      y={viewBox.y + viewBox.height + offset}
      className="recharts-text recharts-label"
      textAnchor={"middle"}
      style={{ lineHeight: "15px", fontWeight: 700, fontSize: 12 }}
    >
      <tspan x={x} dy="0em">
        {label}
      </tspan>
    </text>
  ) : (
    <text
      x={x}
      y={viewBox.y + viewBox.height + offset}
      textAnchor={"middle"}
      style={{ lineHeight: "15px", fontWeight: 700, fontSize: 12 }}
    >
      <tspan x={x} dy="-0.8em">
        {label.substring(0, 120)}
      </tspan>
      <tspan x={x} dy="1.0em">
        {label.substring(120, 240) + ellipsis}
      </tspan>
    </text>
  );
};

export const CustomYAxesLabel = (props: Props) => {
  const { label = "", showAxisLabel, viewBox } = props;
  const y = viewBox.height / 2 + viewBox.y;
  // Think of this as font line height + some padding.
  const x = 20;
  const ellipsis = label.length > 137 ? "..." : "";

  if (showAxisLabel === "none") return null;
  return label.length <= 70 ? (
    <text
      x={x}
      y={y}
      transform={`rotate(-90, ${x}, ${y})`}
      className="recharts-text recharts-label"
      textAnchor="middle"
      style={{ fontWeight: 700, lineHeight: "15px", fontSize: 12 }}
    >
      <tspan dy="0.2em" x="10">
        {label}
      </tspan>
    </text>
  ) : (
    <text
      x={x}
      y={y}
      transform={`rotate(-90, ${x}, ${y})`}
      className="recharts-text recharts-label"
      textAnchor="middle"
      style={{ fontWeight: 700, lineHeight: "15px", fontSize: 12 }}
    >
      <tspan dy="-0.9em" x={x}>
        {label.substring(0, 70)}
      </tspan>
      <tspan dy="1.4em" x={x}>
        {label.substring(70, 137) + ellipsis}
      </tspan>
    </text>
  );
};
