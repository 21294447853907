import { useState, useMemo, useEffect, forwardRef } from "react";
import { css } from "emotion";
import { getAvatarLetters, Stack } from "@certa/blocks/thanos";

import { showToast, ToastTypes } from "@certa/catalyst/components/Toast";
import {
  DropdownMenuItem,
  DropdownSubMenuContent
} from "@certa/catalyst/components/Dropdown";
import {
  Avatar,
  AvatarColors,
  AvatarSizes
} from "@certa/catalyst/components/Avatar";
import { Tag, TagColors, TagVariants } from "@certa/catalyst/components/Tag";
import {
  Typography,
  TypographyVariants,
  Ellipsis,
  TypographyColors
} from "@certa/catalyst/components/Typography";
import { Tooltip } from "@certa/catalyst/components/Tooltip";

import { CatalystColors } from "@certa/catalyst/constants/styles";
import { User as UserIcon } from "@certa/icons/components/User";
import { UserGroupOutlined } from "@certa/icons/components/UserGroupOutlined";
import { Empty } from "antd";
import type { ForwardRefComponent } from "framer-motion";
import { useMutation } from "react-query";
import { userImpersonation } from "main/src/js/services";
import { IMPERSONATE_USER_LIST_HEIGHT } from "./constants";
import { DEBOUNCE_TIME } from "../../../../GlobalSearch/constants";
import { DesignTheme, Loader } from "@certa/blocks";
import { useImpersonationIntlMessages } from "../hooks/useImpersonationIntlMessages";
import { useNewVerticalSideNav } from "@certa/common/toggles";
import { useIntersectionObserver } from "@certa/common/hooks/useIntersectionObserver";
import { useGetUsersToImpersonate } from "@certa/common/hooks/useGetUsersToImpersonate";
import { useDebouncedValue } from "@certa/common/hooks/useDebounce";
import { UserDetailsTooltip } from "@certa/common/components/UserDetailsTooltip";
import type {
  ImpersonateeUserKindType,
  ImpersonateeUserType
} from "@certa/common/components/Header/types";

const LIST_WIDTH = "21.125rem";

const ImpersonationUsersList = ({
  kind,
  isOpen
}: {
  kind: ImpersonateeUserKindType;
  isOpen: boolean;
}) => {
  const [searchString, setSearchString] = useState<string>();
  const debouncedSearchKeyword = useDebouncedValue(
    searchString ?? "",
    DEBOUNCE_TIME
  );
  const {
    data: impersonateeUsersData,
    isFetching: isUserListLoading,
    fetchNextPage,
    hasNextPage,
    error,
    isFetchingNextPage
  } = useGetUsersToImpersonate({
    params: {
      kind,
      search: debouncedSearchKeyword
    },
    config: {
      enabled: isOpen
    }
  });

  const {
    noUsersText,
    searchUsersPlaceholderText,
    usersErrorText,
    specific434ErrorText
  } = useImpersonationIntlMessages();

  const { ref } = useIntersectionObserver({
    loadMore: fetchNextPage
  });

  const users = useMemo(
    () =>
      impersonateeUsersData?.pages?.reduce(
        (users: ImpersonateeUserType[], page) => {
          const pageUsers: ImpersonateeUserType[] = page?.results ?? [];
          return [...users, ...pageUsers];
        },
        []
      ) ?? [],
    [impersonateeUsersData]
  );

  const isDataEmpty = users?.length === 0;
  const isLoading = isDataEmpty && isUserListLoading;
  const isEmptyState = isDataEmpty && !isUserListLoading && !error;
  const showErrorState = error && !isUserListLoading;

  const { mutate: triggerImpersonation } = useMutation(
    userImpersonation.startImpersonation,
    {
      onError: () => {
        showToast({
          type: ToastTypes.ERROR,
          title: usersErrorText
        });
      },
      onSuccess: response => {
        if (response.status === 434) {
          showToast({
            type: ToastTypes.ERROR,
            title: specific434ErrorText({
              errorTag: response?.error_tag,
              impersonator: response?.impersonator,
              impersonatee: response?.impersonatee
            })
          });
        }
        if (response.status === 200) {
          window.location.reload();
        }
      }
    }
  );

  const handleInlineSearch = (searchText: string) => {
    setSearchString(searchText);
  };

  const handleClearInlineSearch = () => {
    setSearchString("");
  };

  useEffect(() => {
    handleClearInlineSearch();
  }, [isOpen]);

  return (
    <DropdownSubMenuContent
      height={IMPERSONATE_USER_LIST_HEIGHT}
      width={LIST_WIDTH}
      showInlineSearch
      onInlineSearch={handleInlineSearch}
      onClearInlineSearch={handleClearInlineSearch}
      searchPlaceholder={searchUsersPlaceholderText}
    >
      <DesignTheme // TODO: remove this once UserDetailTooltip uses catalyst tooltip or popover
        id="ImpersonationUsersList"
        className={css({
          minHeight: IMPERSONATE_USER_LIST_HEIGHT
        })}
      >
        {isEmptyState && (
          <Empty
            description={noUsersText}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}

        {showErrorState && (
          <Empty
            description={usersErrorText}
            className={css({
              padding: "var(--s4)",
              color: "var(--colors-red-500)"
            })}
          />
        )}

        {isLoading && <ImpersonateeLoader />}

        {!isDataEmpty &&
          users.map(user => {
            const isImpersonationAllowed =
              user.impersonation_data?.is_impersonation_allowed;

            return (
              <DropdownMenuItem
                key={user.id}
                value={String(user.id)}
                onSelect={() =>
                  isImpersonationAllowed && triggerImpersonation(user.id)
                }
                disabled={!isImpersonationAllowed}
              >
                <ImpersonateeUser user={user} />
              </DropdownMenuItem>
            );
          })}

        {(hasNextPage || isFetchingNextPage) && (
          <ImpersonateeLoader ref={ref} size="sm" />
        )}
      </DesignTheme>
    </DropdownSubMenuContent>
  );
};

function ImpersonateeUser({ user }: { user: ImpersonateeUserType }) {
  const impersonatorUsername = user.name;
  const isNewVerticalSidenavEnabled = useNewVerticalSideNav();
  const hasTags = user.groups?.length > 0;
  return (
    <Stack
      justify="center"
      gap="s3"
      align="center"
      className={css({
        width: "100%",
        position: "relative",
        boxSizing: "border-box"
      })}
    >
      <Avatar
        color={
          isNewVerticalSidenavEnabled ? AvatarColors.DARK : AvatarColors.NEUTRAL
        }
        size={isNewVerticalSidenavEnabled ? AvatarSizes.SMALL : AvatarSizes.BIG}
        aria-label={impersonatorUsername}
      >
        {getAvatarLetters(impersonatorUsername)}
      </Avatar>
      <Stack
        justify="center"
        direction="vertical"
        gap="s0"
        className={css({
          flexGrow: 1,
          width: "calc(100% - 32px - 8px - 20px)"
        })}
      >
        <Typography
          variant={TypographyVariants.LABEL_SM_BOLD}
          width="100%"
          className={css({
            color: "inherit"
          })}
          title={user.name}
        >
          <Ellipsis>{user.name}</Ellipsis>
        </Typography>
        <Typography
          variant={TypographyVariants.LABEL_SM}
          width="100%"
          className={css({
            color: "inherit"
          })}
          title={user.email}
        >
          <Ellipsis>{user.email}</Ellipsis>
        </Typography>

        {hasTags && (
          <Stack
            direction="horizontal"
            gap="s2"
            className={css({
              marginTop: "var(--s2) !important"
            })}
          >
            <Tag
              key={`${user.groups[0].id}`}
              label={user.groups[0].name}
              multiline
              icon={
                isNewVerticalSidenavEnabled ? (
                  <UserGroupOutlined
                    className={css({
                      width: "var(--s3)",
                      height: "var(--s3)"
                    })}
                    color={CatalystColors.NEUTRAL_700}
                  />
                ) : (
                  <UserIcon
                    className={css({
                      width: "var(--s3)",
                      height: "var(--s3)"
                    })}
                    color="neutral-100"
                  />
                )
              }
            >
              <Tooltip content={user.groups[0].name}>
                <Typography
                  variant={TypographyVariants.LABEL_SM}
                  color={TypographyColors.NEUTRAL_700}
                >
                  {user.groups[0].name.length > 12
                    ? user.groups[0].name.slice(0, 12) + "..."
                    : user.groups[0].name}
                </Typography>
              </Tooltip>
            </Tag>

            {user.groups?.length > 1 && (
              <UserDetailsTooltip
                user={{
                  ...user,
                  fullName: user.name
                }}
              >
                <Tag
                  key={`${user.groups[0].id}`}
                  color={
                    isNewVerticalSidenavEnabled
                      ? TagColors.NEUTRAL
                      : TagColors.TEAL
                  }
                  variant={
                    isNewVerticalSidenavEnabled
                      ? TagVariants.OUTLINE
                      : TagVariants.FILLED
                  }
                  label={`+ ${String(user.groups?.length - 1)} more`}
                >
                  {`+ ${String(user.groups?.length - 1)} more`}
                </Tag>
              </UserDetailsTooltip>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

const ImpersonateeLoader: ForwardRefComponent<
  HTMLDivElement,
  {
    size?: "sm" | "lg";
  }
> = forwardRef((props, ref) => {
  const { size = "lg" } = props;
  return (
    <Stack
      justify="center"
      align="center"
      ref={ref}
      itemWidth="100%"
      className={css(
        size === "lg"
          ? {
              height: "200px"
            }
          : {
              padding: "20px"
            }
      )}
    >
      <Loader />
    </Stack>
  );
});

export { ImpersonationUsersList };
