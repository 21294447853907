//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const QuestionOutline = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="QuestionOutline"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_4240)">
            <path
              d="M7.96 12.8C8.24 12.8 8.47667 12.7033 8.67 12.51C8.86333 12.3167 8.96 12.08 8.96 11.8C8.96 11.52 8.86333 11.2833 8.67 11.09C8.47667 10.8967 8.24 10.8 7.96 10.8C7.68 10.8 7.44333 10.8967 7.25 11.09C7.05667 11.2833 6.96 11.52 6.96 11.8C6.96 12.08 7.05667 12.3167 7.25 12.51C7.44333 12.7033 7.68 12.8 7.96 12.8ZM7.24 9.72H8.72C8.72 9.28 8.77 8.93333 8.87 8.68C8.97 8.42667 9.25333 8.08 9.72 7.64C10.0667 7.29333 10.34 6.96333 10.54 6.65C10.74 6.33667 10.84 5.96 10.84 5.52C10.84 4.77333 10.5667 4.2 10.02 3.8C9.47333 3.4 8.82667 3.2 8.08 3.2C7.32 3.2 6.70333 3.4 6.23 3.8C5.75667 4.2 5.42667 4.68 5.24 5.24L6.56 5.76C6.62667 5.52 6.77667 5.26 7.01 4.98C7.24333 4.7 7.6 4.56 8.08 4.56C8.50667 4.56 8.82667 4.67667 9.04 4.91C9.25333 5.14333 9.36 5.4 9.36 5.68C9.36 5.94667 9.28 6.19667 9.12 6.43C8.96 6.66333 8.76 6.88 8.52 7.08C7.93333 7.6 7.57333 7.99333 7.44 8.26C7.30667 8.52667 7.24 9.01333 7.24 9.72ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_4240">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
