import { Stack } from "@certa/blocks/thanos";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { ChevronDown } from "@certa/icons/components/ChevronDown";
import { ChevronUp } from "@certa/icons/components/ChevronUp";
import { css } from "emotion";
import type { ChartIdentifiers, ChartSorting } from "@certa/common/types";

type CustomSorterProps = {
  sorting?: ChartSorting[];
  chartType?: ChartIdentifiers;
  handleSorterChange?: (sorter: ChartSorting) => void;
};

const SorterIcon = ({
  sorting,
  onClick,
  isDisabled
}: {
  sorting: ChartSorting["order"];
  onClick: () => void;
  isDisabled: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={css({
        border: "none",
        background: "none",
        display: "flex",
        flexDirection: "column",
        cursor: isDisabled ? "not-allowed" : "pointer"
      })}
    >
      <ChevronUp
        color={sorting === "ASC" && !isDisabled ? "brand" : "neutral-70"}
        autoSize
      />
      <ChevronDown
        color={sorting === "DESC" && !isDisabled ? "brand" : "neutral-70"}
        autoSize
      />
    </button>
  );
};

export const CustomSorter = ({
  sorting,
  chartType,
  handleSorterChange
}: CustomSorterProps) => {
  const isPieOrSpiderChart = ["PIE_CHART", "SPIDER_CHART"].includes(
    chartType ?? ""
  );

  if (!sorting || !handleSorterChange) {
    return null;
  }

  return (
    <>
      {sorting.map(item => (
        <Stack
          key={item.tag}
          gap="s2"
          direction="horizontal"
          justify="center"
          align="center"
          gutter={isPieOrSpiderChart ? "s0" : "s0 s0 s0 s20"}
        >
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
          >
            {item.label}
          </Typography>
          <SorterIcon
            onClick={() => handleSorterChange(item)}
            sorting={item.order}
            isDisabled={item.isDisabled}
          />
        </Stack>
      ))}
    </>
  );
};
