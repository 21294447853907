import React from "react";
import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Box } from "@certa/catalyst/layouts/Box";
import { Skeleton, SkeletonGroup } from "@certa/catalyst/components/Skeleton";
import { classNames } from "@certa/catalyst/utils/common";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { searchResultItemStyles as styles } from "./SearchResultItem.styles";

const getRandomWidth = (min: number, max: number): `${number}%` => {
  return `${Math.floor(Math.random() * (max - min + 1) + min)}%`;
};

export const SearchResultItemSkeleton = () => {
  const titleWidth = getRandomWidth(65, 95);
  const descriptionWidth = getRandomWidth(40, 60);
  return (
    <Box
      width="100%"
      borderBottom="1px solid var(--colors-neutral-400)"
      padding="4 0 4 0"
    >
      <div className={styles.container()}>
        <Skeleton width="2rem" height="2rem" variant="circular" />
        <Stack
          direction="vertical"
          gap="4"
          dangerouslySetClassName={styles.titleContainer}
        >
          <Skeleton width={titleWidth} height="0.75rem" />
          <Skeleton width={descriptionWidth} height="0.75rem" />
        </Stack>
      </div>
    </Box>
  );
};

const InternalSearchResultsLoading = () => (
  <SkeletonGroup label="Loading search results">
    {Array.from({ length: 40 }).map((_, index) => (
      <SearchResultItemSkeleton key={index} />
    ))}
  </SkeletonGroup>
);

export const SearchResultsLoading = React.memo(InternalSearchResultsLoading);

type SearchResultItemProps = {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  isSelected?: boolean;
  subDescription?: React.ReactNode;
};

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  title,
  description,
  icon,
  isSelected,
  subDescription
}) => {
  return (
    <Box width="100%">
      <div
        className={classNames(
          styles.container(isSelected),
          isSelected ? "selected" : ""
        )}
      >
        {icon}
        <div className={styles.titleContainer}>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_800}
          >
            <Ellipsis>{title}</Ellipsis>
          </Typography>

          {description && (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
            >
              <Ellipsis>{description}</Ellipsis>
            </Typography>
          )}
          {subDescription && (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
            >
              {subDescription}
            </Typography>
          )}
        </div>
      </div>
    </Box>
  );
};
export { SearchResultItem };
