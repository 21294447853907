//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const SectionJump = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="SectionJump"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4C3 3.44772 3.44772 3 4 3H7C7.55228 3 8 3.44772 8 4C8 4.55228 7.55228 5 7 5H4C3.44772 5 3 4.55228 3 4ZM2 4.0004C2 4.55249 1.5525 5.0004 1 5.0004C0.447907 5.0004 0 4.55249 0 4.0004C0 3.44789 0.447907 3.0004 1 3.0004C1.5525 3.0004 2 3.44789 2 4.0004ZM2 8.0004C2 8.5529 1.5525 9.0004 1 9.0004C0.447907 9.0004 0 8.5529 0 8.0004C0 7.44789 0.447907 7.0004 1 7.0004C1.5525 7.0004 2 7.44789 2 8.0004ZM1 13.0004C1.5525 13.0004 2 12.5529 2 12.0004C2 11.4483 1.5525 11.0004 1 11.0004C0.447907 11.0004 0 11.4483 0 12.0004C0 12.5529 0.447907 13.0004 1 13.0004ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9H9C9.55228 9 10 8.55228 10 8C10 7.44772 9.55228 7 9 7H4ZM3 12C3 11.4477 3.44772 11 4 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H4C3.44772 13 3 12.5523 3 12ZM11.4101 3H15.3841C15.7472 3 15.9391 3.48408 15.6821 3.7603L13.7167 5.87921C13.5673 6.04026 13.3111 6.04026 13.14 5.87921L11.1321 3.7603C10.8542 3.48408 11.047 3 11.4101 3Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
