//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Database = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Database"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M2.00054 9.99249C2.00054 11.1067 4.82112 12.0135 8.28843 12.0135C11.7527 12.0135 14.5705 11.1083 14.5758 9.99519V9.99045C14.5753 9.91519 14.5758 8.38156 14.5758 8.38156C13.2832 9.3284 10.78 9.76787 8.2879 9.76787C5.7958 9.76787 3.29159 9.3284 2 8.37994C2 8.37994 2.00053 9.91621 2.00053 9.99261L2.00054 9.99249Z"
            fill="currentColor"
          />
          <path
            d="M14.5753 12.3664C13.2832 13.3137 10.78 13.7532 8.2879 13.7532C5.7958 13.7532 3.29159 13.3138 2 12.3658V14.2027C2.34789 15.2132 5.05152 15.999 8.28844 15.999C11.5248 15.999 14.2279 15.2132 14.5763 14.2027L14.5753 12.3664Z"
            fill="currentColor"
          />
          <path
            d="M2.00054 6.00648C2.00054 7.12069 4.82112 8.02753 8.28843 8.02753C11.7527 8.02753 14.5705 7.12226 14.5758 6.00918V6.00444C14.5753 5.92918 14.5758 4.39555 14.5758 4.39555C13.2832 5.34238 10.78 5.78239 8.2879 5.78239C5.7958 5.78239 3.29159 5.3424 2 4.39447C2 4.39447 2.00053 5.93074 2.00053 6.0066L2.00054 6.00648Z"
            fill="currentColor"
          />
          <path
            d="M14.5759 2.02105C14.5759 3.13737 11.7605 4.0421 8.28797 4.0421C4.8154 4.0421 2.00061 3.13737 2.00061 2.02105C2.00061 0.904731 4.8154 0 8.28797 0C11.7605 0 14.5759 0.904731 14.5759 2.02105Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
