import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useIntl } from "react-intl";
import capitalize from "lodash-es/capitalize";

import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes
} from "@certa/catalyst/components/Button";
import {
  DrawerHeader,
  DrawerPlacements,
  Drawer
} from "@certa/catalyst/components/Drawer";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { TextInput } from "@certa/catalyst/components/Inputs/Text";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst/components/Typography";
import { classNames } from "@certa/catalyst/utils/common";
import { Reduce } from "@certa/icons/components/Reduce";
import { Search } from "@certa/icons/components/Search";
import { useGetDataObjectTypes } from "@certa/queries/hooks/dataObjects.hooks";
import { Spinner3 } from "@certa/icons/components/Spinner3";

import { useNavigationDrawer } from "../DrawerContext";
import { ListWrapper } from "../../ListWrapper";
import { LoadingAndEmptyStatesWrapper } from "../../LoadingAndEmptyStatesWrapper";

import { useEnableDataObjects } from "../../../hooks/useEnableDataObjects";
import { pluralizeWord } from "../../../utils/pluralize";
import { getDataObjectTypeRoute } from "../../../utils/routes";
import { getDrawerPositionInDesktop } from "../../../utils/getPositionUtils";

import styles from "./DataObjectTypesDrawer.module.css";

// TODO: This component should be made into a reusable component
// and moved to the common package especially the ListWrapper component.
// This is exactly similar to the DashboardDrawer component.
export const DataObjectTypesDrawer = () => {
  const { objectType } = useParams<{ objectType: string }>();
  const intl = useIntl();
  const navigate = useNavigate();

  const { isDataObjectTypesDrawerOpen, setIsDataObjectTypesDrawerOpen } =
    useNavigationDrawer();
  const isDataObjectsEnabled = useEnableDataObjects();

  const [searchQuery, setSearchQuery] = useState("");
  const isNavbarExpanded = window.location.pathname === "/home";

  const { data: dataObjectTypes = [], isLoading } = useGetDataObjectTypes({
    enabled: isDataObjectsEnabled
  });

  // Memoize handlers
  const handleClose = () => {
    setIsDataObjectTypesDrawerOpen(false);
  };

  const handleDataObjectsClick = (tag: string) => {
    setIsDataObjectTypesDrawerOpen(false);
    navigate(getDataObjectTypeRoute(tag));
  };

  // Memoize derived values
  const dataObjectNavItems = dataObjectTypes
    ?.filter(
      dataObjectType => dataObjectType.uiSchema?.navigation?.enabled !== false
    )
    .sort(
      (a, b) =>
        (a.uiSchema?.navigation?.order ?? Infinity) -
        (b.uiSchema?.navigation?.order ?? Infinity)
    )
    .map(dataObjectType => {
      const label =
        dataObjectType.uiSchema?.display_names?.plural ||
        capitalize(pluralizeWord(dataObjectType.tag));

      return {
        id: dataObjectType.id,
        label,
        tag: dataObjectType.tag,
        onClick: () => handleDataObjectsClick(dataObjectType.tag)
      };
    });

  const filteredDataObjectNavItems = dataObjectNavItems?.filter(dataObject =>
    dataObject.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchQueryChange = (value: string) => setSearchQuery(value);

  const hasNoSearchResults =
    !isLoading &&
    filteredDataObjectNavItems?.length === 0 &&
    searchQuery !== "";

  const loadingDataObjectTypesMessage = intl.formatMessage({
    id: "dataObjectTypesDrawer.loadingObjectTypes",
    defaultMessage: "Loading records, please wait..."
  });

  const noSearchResultsMessage = intl.formatMessage({
    id: "homepage.common.noSearchResultsFound",
    defaultMessage: "We couldn't find any results"
  });

  const tryAnotherSearchMessage = intl.formatMessage({
    id: "homepage.common.tryAnotherSearch",
    defaultMessage: "Please try another search"
  });

  return (
    <>
      <Drawer
        width="25rem"
        title=""
        aria-label="Records"
        show={isDataObjectTypesDrawerOpen}
        onClose={handleClose}
        showCloseIcon={false}
        getPositioning={getDrawerPositionInDesktop}
        placement={DrawerPlacements.LEFT}
        canShowDimmer={false}
      >
        <DrawerHeader>
          <Stack
            direction="horizontal"
            justify="space-between"
            align="center"
            padding="20 24"
            dangerouslySetClassName={styles.dataObjectTypesDrawerHeader}
          >
            <Typography
              variant={TypographyVariants.BODY_BOLD}
              color={TypographyColors.NEUTRAL_800}
            >
              Records
            </Typography>
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.ICON}
              color={ButtonColors.BRAND}
              leftIcon={<Reduce />}
              aria-label="collapse records"
              onClick={handleClose}
            />
          </Stack>
          <Stack direction="horizontal" align="center" padding="20 24 4 24">
            <TextInput
              label="Search records"
              width="100%"
              placeholder={
                intl.formatMessage({
                  id: "commonTextInstances.search",
                  defaultMessage: "Search"
                }) + "..."
              }
              autoFocus={true}
              leftIcon={<Search color="neutral-70" />}
              value={searchQuery}
              onChange={handleSearchQueryChange}
              allowClear
            />
          </Stack>
        </DrawerHeader>
        {filteredDataObjectNavItems?.length > 0 ? (
          <ListWrapper
            options={filteredDataObjectNavItems}
            aria-label="Records list"
            getKey={dataObject => dataObject.id}
            getLabel={dataObject => dataObject.label}
            onClick={dataObject => handleDataObjectsClick(dataObject.tag)}
            render={dataObject => (
              <Stack
                gap="16"
                direction="vertical"
                justify="center"
                padding="0 16 0 16"
                dangerouslySetClassName={classNames({
                  [styles.dataObjectTypeItem]: true,
                  [styles.dataObjectTypeItemSelected]:
                    dataObject.tag === objectType
                })}
              >
                <Typography
                  as="span"
                  variant={TypographyVariants.LABEL_SM_BOLD}
                >
                  {dataObject.label}
                </Typography>
              </Stack>
            )}
          />
        ) : (
          <LoadingAndEmptyStatesWrapper
            primaryMessage={hasNoSearchResults ? noSearchResultsMessage : ""}
            secondaryMessage={
              hasNoSearchResults
                ? tryAnotherSearchMessage
                : isLoading
                  ? loadingDataObjectTypesMessage
                  : ""
            }
            secondaryIcon={isLoading ? <Spinner3 size={12} /> : undefined}
          />
        )}
      </Drawer>
      <div
        className={styles.dataObjectTypesDrawerOverlay}
        style={{
          left: isNavbarExpanded ? "230px" : "65px"
        }}
        onClick={handleClose}
      />
    </>
  );
};
