import { APIFetchKryptonForComms, RequestHeaders } from "@certa/network";
import { useQuery } from "react-query";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import { z } from "zod";

export type CommentsObjectType = "step" | "field" | "integrationresult";

const stepResponseSchema = z.object({
  name: z.string(),
  step_group_id: z.number(),
  step_group_name: z.string(),
  workflow_id: z.number(),
  workflow_name: z.string()
});

const fieldResponseSchema = z.object({
  body: z.string(),
  step_id: z.number(),
  step_name: z.string(),
  step_group_id: z.number(),
  step_group_name: z.string(),
  workflow_id: z.number(),
  workflow_name: z.string()
});

const integrationResultResponseSchema = z.object({
  field_id: z.number(),
  field_body: z.string(),
  step_id: z.number(),
  step_name: z.string(),
  step_group_id: z.number(),
  step_group_name: z.string(),
  workflow_id: z.number(),
  workflow_name: z.string()
});

type StepResponse = z.infer<typeof stepResponseSchema>;
type FieldResponse = z.infer<typeof fieldResponseSchema>;
type IntegrationResultResponse = z.infer<
  typeof integrationResultResponseSchema
>;

type ObjectNameRawResponse = {
  step?: Record<string, StepResponse>;
  field?: Record<string, FieldResponse>;
  integrationresult?: Record<string, IntegrationResultResponse>;
};

export type StepDetails = {
  type: "step";
  stepId: string;
  stepName: string;
  stepGroupId: string;
  stepGroupName: string;
  workflowId: string;
  workflowName: string;
};

export type FieldDetails = {
  type: "field";
  fieldId: string;
  fieldName: string;
  stepId: string;
  stepName: string;
  stepGroupId: string;
  stepGroupName: string;
  workflowId: string;
  workflowName: string;
};

export type IntegrationResultDetails = {
  type: "integrationresult";
  fieldId: string;
  fieldName: string;
  stepId: string;
  stepName: string;
  stepGroupId: string;
  stepGroupName: string;
};

export type ObjectDetails =
  | StepDetails
  | FieldDetails
  | IntegrationResultDetails;

export const hasFieldDetails = (
  details: ObjectDetails | null
): details is FieldDetails | IntegrationResultDetails =>
  details?.type === "field" || details?.type === "integrationresult";

type ObjectNamePayload = {
  [key in CommentsObjectType]?: string[];
};

const objectNameModelCreator = (
  response: ObjectNameRawResponse,
  objectType: CommentsObjectType,
  objectId: string
): ObjectDetails | null => {
  const data = response[objectType]?.[objectId];
  if (!data) return null;

  try {
    if (objectType === "step") {
      const validData = stepResponseSchema.parse(data);

      return {
        type: "step",
        stepId: objectId,
        stepName: validData.name,
        stepGroupId: validData.step_group_id.toString(),
        stepGroupName: validData.step_group_name,
        workflowId: validData.workflow_id.toString(),
        workflowName: validData.workflow_name
      };
    }

    if (objectType === "field") {
      const validData = fieldResponseSchema.parse(data);

      return {
        type: "field",
        fieldId: objectId,
        fieldName: validData.body,
        stepId: validData.step_id.toString(),
        stepName: validData.step_name,
        stepGroupId: validData.step_group_id.toString(),
        stepGroupName: validData.step_group_name,
        workflowId: validData.workflow_id.toString(),
        workflowName: validData.workflow_name
      };
    }

    if (objectType === "integrationresult") {
      const validData = integrationResultResponseSchema.parse(data);

      return {
        type: "integrationresult",
        fieldId: validData.field_id.toString(),
        fieldName: validData.field_body,
        stepId: validData.step_id.toString(),
        stepName: validData.step_name,
        stepGroupId: validData.step_group_id.toString(),
        stepGroupName: validData.step_group_name
      };
    }
  } catch (error) {
    console.error(`Error parsing ${objectType} data:`, error);
  }

  return null;
};

const getObjectNameFromKrypton = async (
  objectType: CommentsObjectType,
  objectId: string
): Promise<ObjectDetails | null> => {
  const payload: ObjectNamePayload = {
    [objectType]: [objectId]
  };

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({ data: payload })
  };

  return APIFetchKryptonForComms<ObjectNameRawResponse>(
    `comms/object_names/`,
    requestOptions
  ).then(response => objectNameModelCreator(response, objectType, objectId));
};

const getMultipleObjectNamesFromKrypton = async (
  items: { objectType: CommentsObjectType; objectId: string }[]
): Promise<Record<string, ObjectDetails | null>> => {
  // Group items by object type to optimize the API calls
  const groupedByType: Record<CommentsObjectType, string[]> = {} as Record<
    CommentsObjectType,
    string[]
  >;

  items.forEach(({ objectType, objectId }) => {
    if (!groupedByType[objectType]) {
      groupedByType[objectType] = [];
    }
    groupedByType[objectType].push(objectId);
  });

  const payload: ObjectNamePayload = groupedByType;

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({ data: payload })
  };

  const response = await APIFetchKryptonForComms<ObjectNameRawResponse>(
    `comms/object_names/`,
    requestOptions
  );

  // Create a map of results using a composite key of objectType:objectId
  const results: Record<string, ObjectDetails | null> = {};

  items.forEach(({ objectType, objectId }) => {
    const key = `${objectType}:${objectId}`;
    results[key] = objectNameModelCreator(response, objectType, objectId);
  });

  return results;
};

export const useObjectNameFromKrypton = ({
  objectType,
  objectId
}: {
  objectType: CommentsObjectType;
  objectId: string;
}) => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  return useQuery<ObjectDetails | null>(
    ["getObjectNameFromKrypton", objectType, objectId],
    () => getObjectNameFromKrypton(objectType, objectId),
    {
      refetchOnWindowFocus: false,
      enabled: isCommsNewEndpointEnabled
    }
  );
};

export const useMultipleObjectNamesFromKrypton = (
  items: { objectType: CommentsObjectType; objectId: string }[] | undefined
) => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  return useQuery<Record<string, ObjectDetails | null>>(
    ["getMultipleObjectNamesFromKrypton", items],
    () => getMultipleObjectNamesFromKrypton(items || []),
    {
      refetchOnWindowFocus: false,
      enabled: isCommsNewEndpointEnabled && !!items && items.length > 0
    }
  );
};

// Helper function to get a key for the object details map
export const getObjectDetailsKey = (
  objectType: CommentsObjectType,
  objectId: string
): string => `${objectType}:${objectId}`;
