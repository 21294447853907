import { DateRangePicker } from "@certa/catalyst/components/DatePicker";
import type { TDateRangeValue } from "@certa/catalyst/components/DatePicker";
import { PopperPlacements } from "@certa/catalyst/components/types";
import moment from "moment";
import { useIntl } from "react-intl";
import { isValidDate } from "@certa/common/utils/dateFormatter";
import {
  START_DATE_INDEX,
  END_DATE_INDEX,
  POPPER_MODIFIER_TOP_BOTTOM_FLIP
} from "@certa/common/constants";

type Props = {
  value?: string | string[];
  onChange?: (value: string | string[] | undefined) => void;
  disabled: boolean;
};

export const IsBetweenDateInputField = (props: Props) => {
  const { value, onChange, disabled: isDisabled } = props;

  const dateRange: TDateRangeValue | undefined = Array.isArray(value)
    ? [
        isValidDate(value[START_DATE_INDEX])
          ? moment(value[START_DATE_INDEX])
          : null,
        isValidDate(value[END_DATE_INDEX])
          ? moment(value[END_DATE_INDEX])
          : null
      ]
    : undefined;

  const handleChange = (dateRange: TDateRangeValue) => {
    const dates: string[] = [];
    dateRange?.forEach(date => {
      if (date) {
        dates.push(date.format("YYYY-MM-DD"));
      } else {
        dates.push("");
      }
    });
    onChange?.(dates);
  };

  const placeholder = usePlaceholderText();

  return (
    <DateRangePicker
      ariaLabel={placeholder}
      disabled={isDisabled}
      placeholder={placeholder}
      value={dateRange}
      onChange={handleChange}
      inputWidth={["8rem", "8rem"]} // 128px
      placement={[PopperPlacements.AUTO, PopperPlacements.AUTO]}
      popperModifiers={[
        POPPER_MODIFIER_TOP_BOTTOM_FLIP,
        POPPER_MODIFIER_TOP_BOTTOM_FLIP
      ]}
    />
  );
};

const usePlaceholderText = (): [string, string] => {
  const intl = useIntl();

  const startDateText = intl.formatMessage({
    id: "report.startDate",
    defaultMessage: "Start date"
  });
  const endDateText = intl.formatMessage({
    id: "report.endDate",
    defaultMessage: "End date"
  });
  return [startDateText, endDateText];
};
