import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { WorkflowStatus } from "@certa/processdetails/src/taskDetail/components/ProcessDetailsSecondaryNavbar/components/WorkflowStatus";
import { useGetTaskLaneList } from "@certa/queries/hooks/workflow.hooks";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import format from "date-fns/format";
import { Fragment, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ChokidarWFStatusPermissionBasedOnToggle } from "../../ChokidarWFStatusPermissionBasedOnToggle";
import { formatLCData } from "../../LCData";
import { useSearchStore } from "../store/useSearchStore";
import { BreadcrumbPathDisplay } from "./BreadcrumbPathDisplay";
import { OverviewListRow } from "./OverviewListRow";
import { RatingPreview } from "./RatingPreview";
import { resultPreviewStyles as styles } from "./ResultPreview.styles";

const PreviewLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      width="50%"
      height="100%"
      padding="12 16"
      gap="8"
      overflowX="hidden"
      overflowY="auto"
      dangerouslySetClassName={styles.previewLayoutWrapper}
    >
      {children}
    </Stack>
  );
};

const PreviewTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant={TypographyVariants.TITLE_MD}
      color={TypographyColors.NEUTRAL_800}
    >
      <Ellipsis>{title}</Ellipsis>
    </Typography>
  );
};

const WorkflowPreview = ({
  selectedWorkflow
}: {
  selectedWorkflow: ModelledAPIResponse.WorkflowSearchResult;
}) => {
  const { setIsChildDialogOpen, workflowFamilyData } = useSearchStore();
  const workflowId = selectedWorkflow.id;
  const workflowFamily = workflowFamilyData.get(workflowId) || [];
  const intl = useIntl();

  // Since we do show name of current workflow in the title, we don't need to show it in the breadcrumbs
  const breadcrumbs = workflowFamily.map(member => member.name).slice(0, -1);

  const processedLcData = useMemo(() => {
    const lcData = selectedWorkflow?.formattedLcData || [];

    if (!lcData) return [];
    return lcData
      .map(lcDataItem => ({
        ...lcDataItem,
        formattedValue: formatLCData(lcDataItem)
      }))
      .filter(lcDataItem => lcDataItem.formattedValue !== null)
      .slice(0, 4);
  }, [selectedWorkflow]);

  return (
    <PreviewLayout>
      <PreviewTitle title={selectedWorkflow.name} />
      <BreadcrumbPathDisplay items={breadcrumbs} />

      <ChokidarWFStatusPermissionBasedOnToggle processId={selectedWorkflow.id}>
        <OverviewListRow
          label={intl.formatMessage({
            id: "savedSearch.workflowStatus",
            defaultMessage: "Status"
          })}
          value={
            <div className={styles.workflowStatus}>
              <WorkflowStatus
                status={selectedWorkflow.status?.label}
                statusColorCode={selectedWorkflow.status?.colorCode}
                processId={selectedWorkflow.id}
                width={120}
                onDialogOpenChange={setIsChildDialogOpen}
              />
            </div>
          }
        />
      </ChokidarWFStatusPermissionBasedOnToggle>

      {processedLcData.map(lcDataItem => (
        <OverviewListRow
          key={lcDataItem.label}
          label={lcDataItem.label}
          value={
            lcDataItem.format === "rating" ? (
              <RatingPreview value={String(lcDataItem.value)} />
            ) : (
              lcDataItem.formattedValue
            )
          }
          shouldWrapReactNodeWithTypography={lcDataItem.format !== "rating"}
        />
      ))}
    </PreviewLayout>
  );
};

const FilePreview = ({
  selectedFile
}: {
  selectedFile: ModelledAPIResponse.FileSearchResult;
}) => {
  const { workflowFamilyData } = useSearchStore();
  const intl = useIntl();
  const workflowId = selectedFile.workflow.id;
  const workflowFamily = workflowFamilyData.get(workflowId) || [];
  const breadcrumbs = workflowFamily.map(member => member.name);
  const creationDate = selectedFile.workflow.createdAt;
  const formattedDate = creationDate
    ? format(new Date(creationDate), "MMM d, yyyy")
    : "";
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { data: stepGroups, status: stepGroupsFetchStatus } =
    useGetTaskLaneList(workflowId, {
      enabled: isTooltipOpen // Only fetch when tooltip is open
    });

  const createdLabel = intl.formatMessage({
    id: "workflowReport.created",
    defaultMessage: "Created"
  });
  const fieldText = intl.formatMessage({
    id: "commentsDrawer.fieldText",
    defaultMessage: "Field"
  });
  const loadingText = intl.formatMessage({
    id: "designAI.chatInput.loadingStatusText.loading",
    defaultMessage: "Loading..."
  });
  const currentStep = selectedFile.step;
  // stepgroups has results, in which we have swimlanes, each swimlane has steps
  // we need to find the current step in the swimlane
  const currentSwimlane = stepGroups?.results.find(swimlane =>
    swimlane.steps.find(step => step.id === currentStep.id)
  );

  const tooltipHierarchy = [
    { id: currentSwimlane?.id, name: currentSwimlane?.name },
    { id: currentStep.id, name: currentStep.name },
    { id: selectedFile.id, name: selectedFile.body }
  ];

  const content =
    stepGroupsFetchStatus === "loading" ? (
      loadingText
    ) : (
      <div className={styles.tooltip}>
        {tooltipHierarchy.map((item, index) => (
          <Fragment key={item.id}>
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {item.name}
            </Typography>
            {index !== tooltipHierarchy.length - 1 && (
              <Typography
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_700}
              >
                &rarr;
              </Typography>
            )}
          </Fragment>
        ))}
      </div>
    );

  return (
    <PreviewLayout>
      <PreviewTitle title={selectedFile.fileName} />
      <BreadcrumbPathDisplay items={breadcrumbs} />
      <OverviewListRow label={createdLabel} value={formattedDate} />

      <OverviewListRow
        label={fieldText}
        onHover={() => setIsTooltipOpen(true)}
        shouldShowCustomTooltip
        tooltip={content}
        value={selectedFile.body}
      />
    </PreviewLayout>
  );
};

const ResultPreview = () => {
  const { selectedItem, allSearchResults, hasSearchQuery } = useSearchStore();
  const selectedResult = allSearchResults[selectedItem];

  if (!selectedResult || !selectedResult.type || !hasSearchQuery) {
    return null;
  }

  switch (selectedResult.type) {
    case "workflow":
      return <WorkflowPreview selectedWorkflow={selectedResult} />;
    case "file":
      return <FilePreview selectedFile={selectedResult} />;
    default:
      return null;
  }
};

export { ResultPreview };
