import { tenant } from "./config";

/**
 * Runs and gets you sweet-sweet cookie!
 * @param name Name of cookie to be retrieved
 */
const getValueFromCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (const value of cookies) {
      const cookie = value.trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

/**
 * Request headers for different kind of method types.
 */
const RequestHeaders = {
  /**
   * Common headers are mandatory and will be sent along with all requests
   * regardless of their method type
   */
  get common() {
    return {
      "X-DTS-SCHEMA": tenant,
      "Content-Type": "application/json"
    } as HeadersInit;
  },

  /**
   * Request headers for PUT requests
   */
  get PUT() {
    return {
      ...this.common,
      "X-CSRFToken": getValueFromCookie("csrftoken")
    } as HeadersInit;
  },

  /**
   * Request headers for GET requests
   */
  get GET() {
    return this.common;
  },

  /**
   * Request headers for POST requests
   */
  get POST() {
    return {
      ...this.common,
      "X-CSRFToken": getValueFromCookie("csrftoken")
    } as HeadersInit;
  },

  /**
   * Request headers for DELETE requests
   */
  get DELETE() {
    return {
      ...this.common,
      "X-CSRFToken": getValueFromCookie("csrftoken")
    } as HeadersInit;
  },

  /**
   *
   */
  get PRASARAN() {
    return {
      "X-CSRFToken": getValueFromCookie("csrftoken"),
      "X-DTS-SCHEMA": tenant
    };
  },

  /**
   * GET and POST request headers for PANO APIs
   * using hardcoded token till auth is implemented via hermes
   */
  get PANO_API() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${import.meta.env.REACT_APP_PANO_API_AUTH_TOKEN}`
    };
  }
};

export { RequestHeaders };
