import React, { useState, useEffect } from "react";
import moment from "moment";
import type {
  DatePickerPopperStrategy,
  PopperModifiers
} from "../DatePicker/DatePicker";
import { DatePicker } from "../DatePicker/DatePicker";
import type { PopperPlacements } from "../../types";

export type TDateRangeValue =
  | [moment.Moment | null, moment.Moment | null]
  | null;

export type TDateRangeOnChange = (
  dateRange: TDateRangeValue,
  dateString: string
) => void;

type DateRangePickerProps = {
  ariaLabel: [string, string];
  placeholder?: [string, string];
  format?: string;
  disabled?: boolean;
  value?: TDateRangeValue;
  onChange?: TDateRangeOnChange;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  inputWidth?: [string, string];
  strategy?: DatePickerPopperStrategy;
  placement?: [PopperPlacements, PopperPlacements];
  popperModifiers?: [PopperModifiers, PopperModifiers];
};

const START_DATE_INDEX = 0;
const END_DATE_INDEX = 1;

export const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const {
    ariaLabel,
    placeholder,
    format = "YYYY-MM-DD",
    disabled: isDisabled,
    value,
    onChange,
    minDate,
    maxDate,
    inputWidth = ["100%", "100%"],
    strategy = "absolute",
    placement = [],
    popperModifiers = []
  } = props;

  const [startDate, setStartDate] = useState<moment.Moment | null>(
    value ? value[START_DATE_INDEX] : null
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    value ? value[END_DATE_INDEX] : null
  );

  useEffect(() => {
    setStartDate(value ? value[START_DATE_INDEX] : null);
    setEndDate(value ? value[END_DATE_INDEX] : null);
  }, [value]);

  const handleStartDateChange = (selectedDate: moment.Moment | null) => {
    const newStartDate = selectedDate ? moment(selectedDate) : null;

    // start date gets more than end date
    if (newStartDate && endDate && newStartDate.isAfter(endDate)) {
      // TODO: better to add error handling
      onChange?.([newStartDate, null], formatDateRange(newStartDate, null));
      setStartDate(newStartDate);
      setEndDate(null);
    } else {
      onChange?.(
        [newStartDate, endDate],
        formatDateRange(newStartDate, endDate)
      );
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (selectedDate: moment.Moment | null) => {
    const newEndDate = selectedDate ? moment(selectedDate) : null;
    onChange?.([startDate, newEndDate], formatDateRange(startDate, newEndDate));
    setEndDate(newEndDate);
  };

  const formatDateRange = (
    start: moment.Moment | null,
    end: moment.Moment | null
  ): string => {
    if (!start || !end) {
      return "";
    }
    return `${start.format(format)} - ${end.format(format)}`;
  };

  return (
    <>
      <DatePicker
        aria-label={ariaLabel[START_DATE_INDEX]}
        placeholder={placeholder?.[START_DATE_INDEX]}
        format={format}
        disabled={isDisabled}
        value={startDate}
        onChange={handleStartDateChange}
        minDate={minDate}
        maxDate={maxDate}
        inputWidth={inputWidth[START_DATE_INDEX]}
        strategy={strategy}
        placement={placement[START_DATE_INDEX]}
        popperModifiers={popperModifiers[START_DATE_INDEX]}
      />
      <DatePicker
        aria-label={ariaLabel[END_DATE_INDEX]}
        placeholder={placeholder?.[END_DATE_INDEX]}
        format={format}
        disabled={isDisabled}
        value={endDate}
        onChange={handleEndDateChange}
        minDate={startDate}
        maxDate={maxDate}
        inputWidth={inputWidth[END_DATE_INDEX]}
        strategy={strategy}
        placement={placement[END_DATE_INDEX]}
        popperModifiers={popperModifiers[END_DATE_INDEX]}
      />
    </>
  );
};
