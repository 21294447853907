import { css } from "emotion";

export const resultPreviewStyles = {
  previewLayoutWrapper: css({
    borderLeft: "1px solid var(--colors-neutral-300)",
    maxWidth: "25rem"
  }),
  row: css({
    minHeight: "2.625rem",
    overflow: "hidden",
    "&:last-child": {
      borderBottom: "1px solid var(--colors-neutral-300)"
    }
  }),
  valueContainer: css({
    textAlign: "right",
    ">div": {
      justifySelf: "flex-end"
    }
  }),
  valueWrapper: css({
    width: "55%",
    overflow: "hidden"
  }),
  ellipsis: css({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }),
  workflowStatus: css({
    margin: "3px"
  }),
  tooltip: css({
    maxWidth: 600,
    padding: "var(--space-4) var(--space-12)",
    "> *": {
      display: "inline",
      verticalAlign: "middle",
      marginRight: 4
    }
  })
};
