import { useIntl } from "react-intl";

export const useGetBoolOptions = () => {
  const intl = useIntl();
  const boolOptions = [
    {
      label: intl.formatMessage({
        id: "commonTextInstances.yes",
        defaultMessage: "Yes"
      }),
      value: "True"
    },
    {
      label: intl.formatMessage({
        id: "commonTextInstances.no",
        defaultMessage: "No"
      }),
      value: "False"
    }
  ];

  return { boolOptions };
};
