const DEFAULT_API_ORIGIN = import.meta.env.REACT_APP_API_ORIGIN;
const HERMES_API_ORIGIN = import.meta.env.REACT_APP_HERMES_API_BASE;
const isPreviewEnabled = import.meta.env.REACT_APP_PREVIEW_ENABLED;
const apiStudioActivityLogs = import.meta.env
  .REACT_APP_STUDIO_ACTIVITY_LOGS_API_URL;
const isProdEnv = import.meta.env.PROD;
const trustedDomains = new Set(
  (import.meta.env.REACT_APP_TRUSTED_DOMAINS || "").split(",")
);

const getPreviewTenant = () => {
  const hostName = window.location.hostname;
  const previewTenant = hostName && hostName.split(".")[0];
  return previewTenant || "walmart";
};

function getTenant() {
  const url = new URL(window.location.href);
  if (isPreviewEnabled) {
    return getPreviewTenant();
  }
  return url.hostname.split(".")[0];
}
export function getHostName() {
  const url = new URL(window.location.href);
  const splitURL = url.hostname.split(".");
  return splitURL.slice(1).join(".");
}

export function getAPIOrigin() {
  //  preview is only available on slackcart
  if (isPreviewEnabled) {
    return "https://previewapi.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) return `https://api.${hostname}`;
  else return "https://"; // any API calls will simply fail and show the service-unavailable page
}

export function getMimirAPIOrigin() {
  //  preview is only available on slackcart
  if (isPreviewEnabled) {
    return "https://mimir.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) {
    return `https://mimir.${hostname}`;
  } else {
    return "https://"; // any API calls will simply fail and show the service-unavailable page
  }
}

export function getActivityLogsOrigin() {
  const hostname = getHostName();
  return `https://activitylogs.${hostname}`;
}

export function getDesignAIAPIOrigin() {
  //  preview is only available on slackcart
  if (isPreviewEnabled) {
    return "https://heycerta.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) return `https://heycerta.${hostname}`;
  else return "https://"; // any API calls will simply fail and show the service-unavailable page
}

export function getDesignAIv3APIOrigin() {
  //  preview is only available on slackcart
  if (isPreviewEnabled) {
    return "https://design-ai.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) return `https://design-ai.${hostname}`;
  else return "https://"; // any API calls will simply fail and show the service-unavailable page
}

export function getHermesAPIOrigin() {
  return HERMES_API_ORIGIN;
}

export function getCommsAPIOrigin() {
  //  preview is only available on slackcart
  if (isPreviewEnabled) {
    return "https://comms.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) {
    return `https://comms.${hostname}`;
  } else {
    return "https://"; // any API calls will simply fail and show the service-unavailable page
  }
}

// Building via scripts keeps the environment as production which can be confusing
// hence the choice of this variable name to be more verbose

const tenant = getTenant();
const apiBaseURL = isProdEnv
  ? `${getAPIOrigin()}/api/v1/`
  : `${DEFAULT_API_ORIGIN}/api/v1/`;
const apiBaseURLExt = `${apiBaseURL}ext/`;
const apiBaseURLV2 = isProdEnv
  ? `${getAPIOrigin()}/api/v2/`
  : `${DEFAULT_API_ORIGIN}/api/v2/`;
const apiBaseURLV3 = isProdEnv
  ? `${getAPIOrigin()}/api/v3/`
  : `${DEFAULT_API_ORIGIN}/api/v3/`;
const apiBaseURLKronos = isProdEnv
  ? `${getAPIOrigin()}/kronos/api/v1/`
  : `${DEFAULT_API_ORIGIN}/kronos/api/v1/`;
const apiBaseStudio = isProdEnv
  ? `${getAPIOrigin()}/studio/v1/`
  : `${DEFAULT_API_ORIGIN}/studio/v1/`;
const apiBaseStudioV2 = isProdEnv
  ? `${getAPIOrigin()}/studio/v2/`
  : `${DEFAULT_API_ORIGIN}/studio/v2/`;
const apiBaseBadgesV1 = isProdEnv
  ? `${getAPIOrigin()}/badges/api/v1/`
  : `${DEFAULT_API_ORIGIN}/badges/api/v1/`;

const apiBaseURLMimir = `${getMimirAPIOrigin()}/api/v1/`;

// Note:
// Template V2 uses templates/v1 api endpoints
// Template V1 uses certa workflows to serve templates.
const apiBaseTemplatesV2 = isProdEnv
  ? `${getAPIOrigin()}/templates/v1/`
  : `${DEFAULT_API_ORIGIN}/templates/v1/`;

const apiBaseKryptonForComms = isProdEnv
  ? `${getAPIOrigin()}/`
  : `${DEFAULT_API_ORIGIN}/`;

const apiBaseURLActivityLog = `${getActivityLogsOrigin()}/v1/`;
const apiBaseURLAuditLogs = `${getActivityLogsOrigin()}/audit-logs/`;

const apiBaseURLKryptonAuditLogs = `${getAPIOrigin()}/audit-logs/`;

const apiBaseScreening = isProdEnv
  ? `${getAPIOrigin()}/screening/`
  : `${DEFAULT_API_ORIGIN}/screening/`;

export {
  tenant,
  isPreviewEnabled,
  apiBaseURL,
  apiBaseURLExt,
  apiBaseURLMimir,
  apiBaseURLV2,
  apiBaseURLV3,
  apiBaseURLKronos,
  apiBaseURLActivityLog,
  apiBaseStudio,
  apiStudioActivityLogs,
  apiBaseStudioV2,
  apiBaseTemplatesV2,
  apiBaseKryptonForComms,
  apiBaseBadgesV1,
  apiBaseScreening,
  apiBaseURLAuditLogs,
  apiBaseURLKryptonAuditLogs
};
