import type { LegacyRef } from "react";
import { useState, useMemo, useEffect, forwardRef } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart as ReScatterChart,
  CartesianGrid,
  Cell,
  LabelList,
  ReferenceLine,
  ZAxis
} from "recharts";
import { TYPOGRAPHY_VARIANTS } from "@certa/blocks/thanos";
import { CustomAxesTick } from "../customAxesTick/CustomAxesTick";
import { css } from "emotion";
import {
  useGetLegendDataCommon,
  type LegendData,
  CustomLegendWrapper
} from "../customLegend/CustomLegend";
import {
  X_AXIS_DATA_TYPE_INDEX,
  Y_AXIS_WIDTH,
  commonChartMargin
} from "../../constants";
import {
  CustomXAxesLabel,
  CustomYAxesLabel
} from "../customAxesLabel/CustomAxesLabel";
import { CustomTooltip } from "../tooltip/Tooltip";
import {
  axisFormatter,
  tooltipFormatter,
  xAxisFormatter
} from "../../utils/chartUtils";

import type { ChartClickEvent, ChartComponentProps } from "@certa/common/types";

const lineStyle = css({
  cursor: "pointer",
  "& .recharts-scatter:not(:hover) .recharts-label-list text": {
    display: "none"
  },
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});

const activeXLabel = css({
  "& .recharts-reference-line:not(:hover) .recharts-label tspan": {
    visibility: "hidden"
  }
});
export const ScatterChart = forwardRef(
  (
    props: ChartComponentProps,
    ref: LegacyRef<typeof ReScatterChart.prototype>
  ) => {
    const {
      isAnimationActive = false,
      chartData,
      height = 500,
      onClick,
      xActiveIdentifier,
      otherConfig,
      sorting,
      handleSorterChange
    } = props;
    const { data, probableDataType, xAxisKey } = chartData;

    const [showValueFor, setShowValueFor] = useState<string | null>(null);
    const labels = useMemo(
      () => Object.keys(data[0] || {}).filter(label => label !== xAxisKey),
      [data, xAxisKey]
    );
    const scatters = useMemo(
      () =>
        labels.map(label => {
          return data
            .filter(data => data[chartData.xAxisKey] && data[label] !== null)
            .map(data => ({
              x: data[xAxisKey],
              y: data[label],
              xLabel: xAxisKey,
              yLabel: label
            }));
        }),
      [labels, data, chartData.xAxisKey, xAxisKey]
    );

    const handleScatterClick = (
      data: {
        payload: { x: string; y: number; yLabel: string };
      },
      index: number
    ) => {
      setShowValueFor(data.payload.yLabel.toString());
      if (onClick && data) {
        const payload = chartData.data.find(
          entry => entry[chartData.xAxisKey] === data.payload.x
        );
        if (payload) {
          const _data: ChartClickEvent = {
            activeLabel: data.payload.x,
            activeXIdentifier: data.payload.x,
            activePayload: [
              {
                payload
              }
            ]
          };

          onClick(_data);
        }
      }
    };
    const {
      colors,
      shouldShowValueLabels,
      showValueOn,
      showLegend,
      xAxis,
      xAxisLabel,
      yAxisLabel,
      showXAxisLabel,
      showYAxisLabel
    } = otherConfig;

    useEffect(() => {
      setShowValueFor(null);
    }, [showValueOn]);

    const legendData = useGetLegendDataCommon({
      colors: colors,
      labels: Object.keys(data[0]),
      xAxisKey,
      fillOpacity: 0.6
    });

    const legendDataByLabel = legendData.reduce<Record<string, LegendData[0]>>(
      (legends, legend) => {
        legends[legend.label] = legend;
        return legends;
      },
      {}
    );
    const xAxisLabelFormatter = xAxisFormatter(
      xAxis?.dataTypes?.[X_AXIS_DATA_TYPE_INDEX],
      xAxis?.labelOutputTypes?.[X_AXIS_DATA_TYPE_INDEX]
    );

    return (
      <CustomLegendWrapper
        data={legendData}
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        showLegend={showLegend}
      >
        <ResponsiveContainer
          width="100%"
          height={height}
          className={showValueOn === "hover" ? lineStyle : activeXLabel}
        >
          <ReScatterChart ref={ref} margin={commonChartMargin}>
            <CartesianGrid strokeDasharray="4" stroke="#DFE2E7" />
            <XAxis
              fontSize="12"
              dataKey={"x"}
              tickSize={15}
              tickLine={false}
              fontWeight={400}
              tickFormatter={xAxisLabelFormatter}
              interval="preserveStartEnd"
              stroke="#DFE2E7"
              label={
                <CustomXAxesLabel
                  label={xAxisLabel}
                  showAxisLabel={showXAxisLabel}
                />
              }
              tick={CustomAxesTick}
              allowDuplicatedCategory={false}
            />

            <YAxis
              fontSize="12"
              tickLine={false}
              tickSize={15}
              tickFormatter={axisFormatter(probableDataType)}
              fontWeight={400}
              interval="preserveStartEnd"
              stroke="#DFE2E7"
              width={Y_AXIS_WIDTH}
              tick={{ fill: "var(--neutral-70)" }}
              label={
                <CustomYAxesLabel
                  label={yAxisLabel}
                  showAxisLabel={showYAxisLabel}
                />
              }
            />
            <ZAxis range={[120, 120]} />
            {/* increases radius of dots */}

            <Tooltip
              formatter={tooltipFormatter("SCATTER_CHART", probableDataType)}
              content={
                <CustomTooltip
                  chartType="SCATTER_CHART"
                  xAxisLabelFormatter={xAxisLabelFormatter}
                />
              }
              cursor={{
                stroke: "var(--teal)",
                strokeDasharray: "4"
              }}
            />
            <ReferenceLine
              x={xActiveIdentifier}
              stroke="var(--brand)"
              label={{
                value: "",
                position: "insideLeft",
                angle: -90,
                offset: 10,
                style: TYPOGRAPHY_VARIANTS["p2-medium-upper"] as {}
              }}
            />
            {scatters.map((scatter, index) => (
              <Scatter
                key={index}
                dataKey="y"
                className=""
                data={scatter}
                fill={legendDataByLabel[scatter?.[0]?.yLabel]?.background}
                onClick={handleScatterClick}
                isAnimationActive={isAnimationActive}
                style={showValueOn === "click" ? { cursor: "pointer" } : {}}
              >
                {shouldShowValueLabels &&
                (showValueOn !== "click" ||
                  showValueFor === scatter?.[0]?.yLabel) ? (
                  <LabelList
                    offset={10}
                    position="top"
                    dataKey={"y"}
                    formatter={tooltipFormatter(
                      "SCATTER_CHART",
                      probableDataType
                    )}
                  />
                ) : null}
                {scatter.map(entry => (
                  <Cell
                    key={entry.x}
                    cursor={onClick ? "pointer" : ""}
                    fill={
                      entry.x === xActiveIdentifier
                        ? "var(--brand)"
                        : legendDataByLabel[scatter?.[0]?.yLabel]?.background
                    }
                    fillOpacity={
                      legendDataByLabel[scatter?.[0]?.yLabel]?.fillOpacity
                    }
                  />
                ))}
              </Scatter>
            ))}
          </ReScatterChart>
        </ResponsiveContainer>
      </CustomLegendWrapper>
    );
  }
);
