import {
  Stack,
  Tooltip,
  Text,
  MAX_PROGRESS_PERCENTAGE
} from "@certa/blocks/thanos";
import { Checkmark } from "@certa/icons/components/Checkmark";
import { Clock } from "@certa/icons/components/Clock";
import { Kanban } from "@certa/icons/components/Kanban";
import type { StepGroup } from "@certa/types";
import type { ReactElement } from "react";
import React from "react";
import { useGetTasklaneStepProgressCount } from "../../sidebar/hooks/utils";
import { getTaskListColor, getStepOverdue } from "../utils";
import { Progress } from "@certa/common/components/Progress";

type Props = {
  group: StepGroup;
  showProgress?: boolean;
  noEllipsis?: boolean;
  isProcessFlow?: boolean;
  overdueDays?: string;
  progressWidth?: number;
  shouldCalcPercentageOfAllSteps?: boolean;
};

export const TaskGroupHeader = ({
  group,
  shouldCalcPercentageOfAllSteps = true,
  showProgress = false,
  noEllipsis = false,
  isProcessFlow = false,
  overdueDays,
  progressWidth = 18
}: Props): ReactElement => {
  const { percent } = useGetTasklaneStepProgressCount(
    group.steps,
    shouldCalcPercentageOfAllSteps
  );
  const listColor = getTaskListColor(group, false, isProcessFlow, percent);

  return (
    <Stack
      gap="s2"
      justify="space-between"
      itemWidth="100% !important"
      align="center"
    >
      <Stack gap="s2" align="center">
        <Kanban
          size={16}
          // @ts-expect-error: suppressed type error
          color={listColor.textColor}
          className="group-header-icon"
        />
        {noEllipsis ? (
          <Text
            variant="h3-bold"
            as="span"
            useMaxWidth={true}
            className="group-header-title"
            color={listColor.textColor}
          >
            {group.name}
          </Text>
        ) : (
          <Tooltip title={group.name}>
            <Text
              variant="h3-bold"
              as="span"
              ellipsis={250}
              useMaxWidth={true}
              className="group-header-title"
              color={listColor.textColor}
            >
              {group.name}
            </Text>
          </Tooltip>
        )}
      </Stack>

      {group.isCompleted ||
      (percent === MAX_PROGRESS_PERCENTAGE && isProcessFlow) ? (
        <Checkmark color="green" />
      ) : (
        showProgress && (
          <Stack
            style={{
              marginTop: isProcessFlow ? "unset" : -8
            }}
            justify="center"
          >
            {getStepOverdue(group) && isProcessFlow ? (
              <Stack align="center" gap="s2">
                <Stack align="center" gap="s1" justify="space-between">
                  <Clock color="red" />
                  <Text
                    variant="p1-regular"
                    color="red"
                    ellipsis="auto"
                    style={{ marginRight: "4px !important" }}
                  >
                    {overdueDays}
                  </Text>
                </Stack>
                <Progress
                  progressPercentage={percent}
                  width={progressWidth}
                  strokeWidth={18}
                  showInfo={false}
                  isToolTip
                  strokeColor="red"
                  type="line"
                />
              </Stack>
            ) : (
              <Progress
                progressPercentage={percent}
                width={progressWidth}
                strokeWidth={18}
                showInfo={false}
                isToolTip
                strokeColor={
                  getStepOverdue(group)
                    ? "red"
                    : isProcessFlow
                      ? "brand"
                      : "teal"
                }
                type={isProcessFlow ? "line" : "circle"}
                hideIfNoProgress={isProcessFlow}
              />
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};
