import { FilterTypes } from "@certa/queries/types/workflow.types";
import type { CascaderOption, ReportCustomFilter } from "@certa/types";
import { FieldTypes, CompareType } from "@certa/types";
import type { StepType } from "@certa/types/src/step";
import type { PopperModifiers } from "@certa/catalyst/components/DatePicker";

export const integrationStatuses = [
  { text: "In Progress", value: "in_progress" } as const,
  { text: "Not Reviewed", value: "not_reviewed" } as const,
  { text: "Reviewed", value: "reviewed" } as const
];

// TODO: Would be removed when the OLD UI is depericated
/* OLD UI status color scheme */
export const eventStatus: {
  [key: string]: {
    label: string;
    class: string;
  };
} = {
  //backward compatiblility
  open: { label: "Open", class: "#D40000" },
  closed: { label: "Closed", class: "#00C89B" },
  //new statuses
  in_progress: { label: "In Progress", class: "#148CD6" },
  reviewed: { label: "Reviewed", class: "#00C89B" },
  not_reviewed: { label: "Not Reviewed", class: "#808080" }
};

/* New UI status color scheme */
export const eventStatusNew: {
  [key: string]: {
    label: string;
    class: string;
  };
} = {
  //backward compatiblility
  open: { label: "Open", class: "var(--red)" },
  closed: { label: "Closed", class: "var(--green)" },
  //new statuses
  in_progress: { label: "In Progress", class: "var(--teal)" },
  reviewed: { label: "Reviewed", class: "var(--green)" },
  not_reviewed: { label: "Not Reviewed", class: "var(--orange)" }
};

export const supportedFieldFormats: Record<string, string> = {
  duns: "##-###-####"
};

export enum HeaderMode {
  "NONE" = "none",
  "LEGACY" = "legacy",
  "DEFAULT" = "default",
  "APP_BUILDER" = "app_builder",
  "STUDIO" = "studio"
}

export const DATE_RANGE = FilterTypes.START_DATE + "~" + FilterTypes.END_DATE;
export const ADVANCED_FILTERS = "advanced_filters";
export const RELATIVE_DAYS_PREFIX = "@@DAYS_FROM_TODAY@@";

export const userMentionRegEx = new RegExp(
  `~\\[([^\\]]*)\\]\\((u|g)\\d+\\)`,
  "gm"
);

export const TAGGABLE_STEPS: StepType[] = ["doable"];

export const USER_PREFERENCES = "user_preferences";

export enum PreferenceKeys {
  FAVORITE_REPORTS = "favorite_reports",
  PINNED_WORKFLOWS = "pinned_workflows",
  SAVED_FILTERS = "saved_filters"
}

export const CERTA_SUPPORT = "certa-support";

export type ProcessActionTypes =
  | "relatedProcesses"
  | "comments"
  | "metaActions";

export const PROCESS_CYCLE_TIME = {
  IN_PROGRESS: "In progress",
  NOT_AVAILABLE: "N/A"
};

export const urlWithDependentFieldsRegEx = new RegExp(/{([^}]*)}/g);

export const THIRDPARTY_INTEGRATIONS_URL = {
  SCORM: "/thirdparty-integrations/scorm/"
};

export const STEP_SUBMIT_POST_MESSAGE_TYPE_KEY =
  "send_step_submitted_post_message_type";

export const POST_MESSAGE_DOMAIN_KEY = "post_message_domain";

export const DYNAMIC_GROUP_REGEX = /^.*?-\d+-(.+)$/;

/**
 * Keep different timer durations here for single source of truth
 */
export const TIMEOUTS = {
  MESSAGE_HIGHLIGHT: 2000,
  DEBOUNCE_STANDARD: 650,
  ELEMENT_STANDARD_HIGHLIGHT: 3500
} as const;

export const UNTITLED_FILE_NAME = "untitled-file";
export const RADIAN = Math.PI / 180;

export const INTEGRATION_CALL_REPORT = "Integration Call Report";
export const EMAIL_DELIVERY_REPORT = "Email Delivery Report";
export const WORKFLOW_REPORT = "Workflow Report";
export const ORM_WORKFLOW_REPORT = "ORM Workflow Report";
export const ReportTypesList = [
  INTEGRATION_CALL_REPORT,
  EMAIL_DELIVERY_REPORT,
  ORM_WORKFLOW_REPORT,
  WORKFLOW_REPORT
];

export type ReportTableType =
  | typeof INTEGRATION_CALL_REPORT
  | typeof EMAIL_DELIVERY_REPORT
  | typeof ORM_WORKFLOW_REPORT
  | typeof WORKFLOW_REPORT;

export const MULTIPLE_ANSWER_FIELD_TYPES: readonly FieldTypes[] = [
  FieldTypes.MULTI_SELECT,
  FieldTypes.CHECKBOX,
  FieldTypes.GROUPED_CHECKBOX
];
export const CERTA_BOT_EMAIL = "certa_bot@certa.in";

export const defaultContryCode = "us";

export const defaultReportAnswerQuery: ReportCustomFilter = {
  groups: [],
  conditions: [],
  compareType: CompareType.AND
};

export enum SortChange {
  NO_CHANGE = 0,
  ASCENDING = 1,
  DESCENDING = -1
}

export const INTERNAL_SERVER_ERROR = 500;
export const COMPLETE_DIVISION = 0;
export const INDEX_ZERO = 0;
export const INDEX_ONE = 1;
export const INDEX_TWO = 2;
export const LAST_INDEX = -1;
export const STATUS_COLUMN_PATH = "status.displayName";
export const PROCESS_TYPE_COLUMN_PATH = "processType";
export const STATUS_ID = "status_id";
export const ZERO = 0;
export const ONE = 1;
export const LENGTH_ZERO = 0;

export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const WEEKS_IN_YEAR = 52;
export const MONTHS_IN_YEAR = 12;
export const DAYS_IN_YEAR = 365;

export const RELATIVE_DATE_VALUE_START_INDEX = 1;

export const NO_DATA_CASCADER_OPTION: CascaderOption = {
  label: "No Data",
  value: "no_data",
  disabled: true
};
export const PROCESS_SWIMLANES_TAG = "process-swimlanes";
export const ALL_STEPS_TAG = "all-steps";
export const EPHEMERAL_TOKEN_QUERY_PARAM = "ephemeral_auth_token";
export const IFRAME_PARENT_EVENTS = {
  TOKEN_EXPIRED_INVALID: "TOKEN_EXPIRED_INVALID"
};
export const RELOAD_REQUIRED_ROUTE = "/reload-required";
export const VERSION_LABEL_COLORS = {
  green: "var(--green)",
  amber: "var(--orange)",
  red: "var(--red)",
  blue: "var(--teal)",
  grey: "var(--neutral-60)"
};
export const FIELD_SIZE_NUMBER_TO_NAME = {
  1: "extra-large",
  2: "large",
  3: "medium",
  4: "small"
};

export const START_DATE_INDEX = 0;
export const END_DATE_INDEX = 1;

export const POPPER_MODIFIER_TOP_BOTTOM_FLIP: PopperModifiers = [
  {
    name: "flip",
    options: {
      allowedAutoPlacements: ["top", "bottom"],
      flipVariations: true
    }
  }
];

export const ENUM_TO_OPERATOR = {
  in: { id: "tasks.keyFieldFilterOperator.in", defaultMessage: "IN" },
  eq: { id: "tasks.keyFieldFilterOperator.eq", defaultMessage: "EQUALS" },
  nt: { id: "tasks.keyFieldFilterOperator.nt", defaultMessage: "NOT EQUAL" },
  not_eq: {
    id: "tasks.keyFieldFilterOperator.nt",
    defaultMessage: "NOT EQUAL"
  },
  gt: { id: "tasks.keyFieldFilterOperator.gt", defaultMessage: "GREATER THAN" },
  lt: { id: "tasks.keyFieldFilterOperator.lt", defaultMessage: "LESS THAN" },
  lte: {
    id: "tasks.keyFieldFilterOperator.lte",
    defaultMessage: "LESS THAN OR EQUAL"
  },
  gte: {
    id: "tasks.keyFieldFilterOperator.gte",
    defaultMessage: "GREATER THAN OR EQUAL"
  },
  except: {
    id: "tasks.keyFieldFilterOperator.except",
    defaultMessage: "EXCEPT"
  },
  contains: {
    id: "tasks.keyFieldFilterOperator.contains",
    defaultMessage: "CONTAINS"
  },
  not_contains: {
    id: "tasks.keyFieldFilterOperator.not_contains",
    defaultMessage: "DOES NOT CONTAIN"
  },
  is_empty: {
    id: "tasks.keyFieldFilterOperator.is_empty",
    defaultMessage: "IS EMPTY"
  },
  is_set: {
    id: "tasks.keyFieldFilterOperator.is_set",
    defaultMessage: "HAS VALUE"
  }
};
export const CERTA_AI = "Certa AI";
export const DATA_AI = "Data AI";
export const TNC_PDF_URL =
  "https://krypton-integration-files.s3.amazonaws.com/internal/Third_Party_Usage_Agreement_06.06.2024.pdf";
