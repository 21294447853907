import { authHeader, handleResponse } from "../../js/_helpers";
import { APIFetch, APIFetchV2 } from "../../js/utils/request";
import {
  myStuffFilteredWorkflows$MC,
  statusModelCreator,
  kindModelCreator
} from "./modelCreators";
import { RequestHeaders } from "../common/utils/RequestHeaders";
import { getUserLanguage } from "../../js/components/common/intlLanguages";

/**
 * @deprecated
 */
export async function getStatusesList$$(queryParams = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  const searchParams = new URLSearchParams(queryParams);
  const url = `available-workflow-statuses/?${searchParams}`;

  const response = await APIFetchV2(url, requestOptions);
  return handleResponse(response).then(response =>
    statusModelCreator(response)
  );
}

/**
 * @deprecated
 * Moved to @certa/queries => src/services/search.services
 */
export async function getBusinessUnitsList$$(region) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };
  const url = region
    ? `business-unit/extra-data/?regions__code=${region}`
    : "fields/export-business-json/";

  const response = await APIFetch(url, requestOptions);
  return handleResponse(response).then(response => {
    const sortedResults =
      response?.results?.sort((a, b) =>
        a.label
          .toUpperCase()
          .localeCompare(b.label.toUpperCase(), getUserLanguage())
      ) || [];
    return { results: sortedResults };
  });
}

/**
 * @deprecated
 * Moved to @certa/queries => src/services/search.services
 */
export function getRegionsList$$() {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };
  const url = "fields/export-region-json/";

  return APIFetch(url, requestOptions)
    .then(handleResponse)
    .then(response => {
      const sortedResults = response.results.sort((a, b) =>
        a.label > b.label ? 1 : -1
      );
      return { results: sortedResults };
    });
}

/**
 * @deprecated
 * New Location: @certa/queries => src/services/kinds.services/getAllKinds
 */
export function getAllKinds$$() {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  // Essentially this API shouldn't have limit param because we always need
  // all the workflow kinds that are available within the system.
  return APIFetchV2("workflow-kinds/?limit=200", requestOptions)
    .then(handleResponse)
    .then(kindModelCreator);
}

export function getAllAlerts$$(kindTag) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return APIFetch(
    "workflow-kinds/" + kindTag + "/alert-count/",
    requestOptions
  ).then(handleResponse);
}

export function getAllTaskQueues$$(kindTag) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return APIFetch(
    "workflow-kinds/" + kindTag + "/count/?type=stepgroup",
    requestOptions
  ).then(handleResponse);
}

// TODO: REMOVE soon if not in use.
// export function getWorkflowList$$(params = {}) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader.get(),
//     credentials: "include"
//   };
//   const queryParams = new URLSearchParams({
//     ...params,
//     lean: true
//   });

//   // TODO: Find a better place for this
//   history.push({ search: queryParams.toString() });

//   const url = `workflows-list/?${queryParams}`;
//   return APIFetch(url, requestOptions).then(handleResponse);
// }

export async function searchWorkflow$$(
  { page, ...otherProps },
  // Adding a noop controller here
  // to use this function separate from thunk
  controller = new AbortController()
) {
  const requestOptions = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include",
    signal: controller.signal
  };

  const searchParams = new URLSearchParams(otherProps);

  const url = `workflows/?${searchParams}&page=${page}`;

  const response = await APIFetchV2(url, requestOptions);
  if (response.ok) {
    const respData = await response.json();

    return myStuffFilteredWorkflows$MC(respData);
  } else {
    if (response.status === 400) {
      const error = await response.json();
      throw new Error(error);
    } else {
      throw new Error(response.statusText);
    }
  }
}

/**
 * @deprecated
 */
export function getAdvancedFilterData$$(kindTag) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  const url = `fields/export-json/?active_kind=${kindTag}`;
  return APIFetch(url, requestOptions).then(handleResponse);
}

// useQuery from react-query, calls this function passing `key`
// as the first argument
export function getMyStuffFilteredWorkflows$$(key, filter) {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };
  const searchParams = new URLSearchParams(filter);
  return APIFetchV2(`workflows/?${searchParams}`, requestOptions)
    .then(handleResponse)
    .then(response => myStuffFilteredWorkflows$MC(response));
}
