import { authHeader } from "../../js/_helpers";
import { tenant } from "../../config";
import { APIFetch } from "../../js/utils/request";
import Godaam from "../../js/utils/storage";
import { APIFetchV2 } from "@certa/network";
import { intl } from "../common/components/IntlCapture";
import type { CaptchaService } from "./types";
import { getCaptchaServiceNameforBE } from "./utils/login.utils";

export const logout$$ = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      ...authHeader.get()
    },
    credentials: "include"
  };
  return await APIFetch("users/logout/", requestOptions).then(handleResponse);
};

export const login$$ = async (
  username: string,
  password: string,
  captchaToken: string | null,
  captchaService: CaptchaService
) => {
  const requestOptions: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-DTS-SCHEMA": tenant
    },
    credentials: "include",
    body: JSON.stringify({
      email: username,
      password: password,
      captcha_token: captchaToken,
      captcha_service: getCaptchaServiceNameforBE(captchaService)
    })
  };
  return await APIFetch("users/login/", requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user && !user.mfa?.requires_mfa_verification) {
        saveDataToGodaam(user);
      }
      return user;
    });
};

export const loginOtp$$ = async (
  username: string,
  password: string,
  captchaToken: string | null,
  captchaService: CaptchaService
) => {
  const requestOptions: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-DTS-SCHEMA": tenant !== "test" ? tenant : "vetted"
    },
    credentials: "include",
    body: JSON.stringify({
      email: username,
      token: password,
      captcha_token: captchaToken,
      captcha_service: getCaptchaServiceNameforBE(captchaService)
    })
  };
  return await APIFetch("users/submit_otp/", requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user && !user.mfa?.requires_mfa_verification) {
        saveDataToGodaam(user);
      }

      return user;
    });
};

export const mfaLogin$$ = async (pin: string) => {
  const requestOptions: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-DTS-SCHEMA": tenant !== "test" ? tenant : "vetted"
    },
    credentials: "include",
    body: JSON.stringify({
      token: pin
    })
  };
  return await APIFetch("users/login_using_mfa/", requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user) {
        saveDataToGodaam(user);
      }

      return user;
    });
};

export const checkAuth$$ = async () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader.get(),
    credentials: "include"
  };

  return await APIFetch("users/me/?format=json", requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user) {
        saveDataToGodaam(user);
      }
      return user;
    });
};

async function handleResponse(response: any) {
  const jsonFromBackend = await response.json();

  /**
   *  When a concurrent user impersonation session already exists
   *  BE should return a status of 434 along with 3 params
   *  { error_tag, impersonator, impersonatee }
   */
  if (response.status === 434) {
    const message = intl.formatMessage(
      {
        id: `impersonation.${jsonFromBackend?.error_tag}`,
        defaultMessage: "An error occurred! Please contact the administrator."
      },
      {
        // eslint-disable-next-line formatjs/enforce-placeholders
        impersonator: jsonFromBackend?.impersonator,
        // eslint-disable-next-line formatjs/enforce-placeholders
        impersonatee: jsonFromBackend?.impersonatee
      }
    );
    throw new Error(message);
  }

  if (!response.ok) {
    throw new Error(jsonFromBackend.detail);
  }
  return jsonFromBackend;
}

function saveDataToGodaam(user: any) {
  const userData = user;
  userData.tenant = tenant;
  userData.csrf = document.cookie;
  return (Godaam.user = JSON.stringify(userData));
}

export const claimTokenForAutoLogin$$ = async (
  token: string,
  config?: Omit<RequestInit, "method" | "body">
) => {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      token
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-DTS-SCHEMA": tenant !== "test" ? tenant : "vetted"
    },
    ...config
  };
  return await APIFetchV2("ephemeral-auth/claim-token/", requestOptions).then(
    user => {
      if (user) {
        saveDataToGodaam(user);
      }
      return user;
    }
  );
};
