import React from "react";
import { menuFooterWrapper, focusVisible } from "./MenuFooter.styles";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { useIntl } from "react-intl";
import { cx } from "emotion";
import type { MenuFooterProps as GlobalSelectMenuFooterProps } from "../GlobalSelect/GlobalSelect.types";

type MenuFooterProps = GlobalSelectMenuFooterProps & {
  onFooterClick: () => void;
};

// ZIndex = 4 because it's 5th element in tab index order
// (3 for category buttons, 1 for option, 1 for footer)
// Update if other elements are added in tab navigation
const FOOTER_FOCUS_INDEX = 4;

const MenuFooter = ({
  currentFocusTabIndex,
  onFooterClick
}: MenuFooterProps) => {
  const intl = useIntl();
  const advancedSearchText = intl.formatMessage({
    id: "homepage.searchbar.advancedSearch",
    defaultMessage: "Advanced Search"
  });

  return (
    <div
      className={cx(menuFooterWrapper)}
      onClick={onFooterClick}
      tabIndex={FOOTER_FOCUS_INDEX}
      // div can have role link, since we handling custom navigation and
      // this is a tabbable element
      role="link"
      aria-labelledby={advancedSearchText}
    >
      <Typography
        color={TypographyColors.NEUTRAL_700}
        variant={TypographyVariants.LABEL_SM}
        className={cx(
          currentFocusTabIndex === FOOTER_FOCUS_INDEX ? focusVisible : ""
        )}
      >
        {`${advancedSearchText} →`}
      </Typography>
    </div>
  );
};

export { MenuFooter };
