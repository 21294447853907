import type { FC } from "react";
import { ChevronDown } from "@certa/icons/components/ChevronDown";
import { Messages } from "@certa/icons/components/Messages";
import { Paper, Stack, Text, Divider, Tooltip } from "@certa/blocks/thanos";
import type {
  CommentChannel,
  CommentMention,
  CommentThreadMeta
} from "@certa/types";
import {
  ButtonSizes,
  ButtonTypes,
  Button
} from "@certa/catalyst/components/Button";
import { HierarchyBreadcrumbs } from "./HierarchyBreadcrumbs";
import { useChannelContext } from "../../comments.context";
import moment from "moment";
import { useCommentMessageFormat } from "../../hooks/useCommentMessageFormat";
import { prepareMentionOptions } from "../../comments.utils";
import { css } from "emotion";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { ABSOLUTE_DATE_TIME_FORMAT } from "@certa/common/utils/time";

const getAllThreadsCounts = (allThreads: CommentThreadMeta[]) =>
  allThreads.reduce((acc, thread) => acc + thread.messageCount, 0);

export const ChannelListItem: FC<{
  handleChannelSelect: (
    channel: CommentChannel,
    threadWithLatestMessage: number
  ) => void;
}> = ({ handleChannelSelect }) => {
  const channel = useChannelContext();

  /**
   * Get all the latest messages from all the threads of the current channel
   * The latest message from every thread will be last array element
   *
   * Also extracting complete thread data here to use it for getting mentions
   */
  const latestMessageListFromAllThreads = channel.allThreads
    .filter(thread => thread.messageCount > 0)
    .map(thread => ({
      thread: thread,
      message:
        channel.threads[thread.threadId].messages[
          channel.threads[thread.threadId].messages.length - 1
        ]
    }));

  /**
   * Compare the dates of every latest message of all the threads
   * and get the greatest date, that will be out latest message
   */
  const latestMessage = latestMessageListFromAllThreads.reduce((a, b) =>
    new Date(a.message.createdAt) > new Date(b.message.createdAt) ? a : b
  );

  const mentions: CommentMention[] = prepareMentionOptions({
    threadUserGroups: latestMessage.thread.threadUserGroups || [],
    threadUsers: latestMessage.thread.threadUsers || []
  });

  const formattedMessage = useCommentMessageFormat(
    latestMessage.message.message,
    mentions,
    true
  );

  const { isMobileResolution } = useScreenResolution();

  return (
    <Paper
      direction="vertical"
      gutter="s2 s4"
      className={css({
        ...(isMobileResolution && { height: "84vh" }),
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "var(--teal-60)",
          ".message-count": {
            backgroundColor: "var(--teal)",
            span: {
              color: "var(--neutral-0)"
            },
            path: {
              fill: "var(--neutral-0)"
            }
          }
        }
      })}
      onClick={() =>
        handleChannelSelect(channel, latestMessage.thread.threadId)
      }
    >
      <Stack align="center" justify="space-between">
        <HierarchyBreadcrumbs containerWidth="86%" isReadOnly={true} />
        <Stack align="center" gap="s4">
          <Stack
            align="center"
            gap="s1"
            className={
              css({
                padding: "4px 6px",
                backgroundColor: "var(--neutral-20)",
                borderRadius: "var(--small-border-radius)"
              }) + " message-count"
            }
          >
            <Messages size={12} color="neutral-70" />
            <Text variant="p1-semibold" color="neutral-70">
              {getAllThreadsCounts(channel.allThreads)}
            </Text>
          </Stack>
          <Button
            rightIcon={<ChevronDown style={{ transform: `rotate(-90deg)` }} />}
            type={ButtonTypes.ICON}
            size={ButtonSizes.SMALL}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack align="center" justify="space-between">
        <Stack
          direction="vertical"
          gap="s1"
          className={css({
            width: "86%"
          })}
        >
          <Text variant="p1-bold" color="black">
            {latestMessage.message.postedBy}
          </Text>
          <Text
            variant="p1-regular"
            color="neutral-70"
            lineClamp={1}
            className={css({ padding: "2px 0" })}
            dangerouslySetInnerHTML={{
              __html: formattedMessage
            }}
          />
        </Stack>
        <Tooltip
          title={moment(latestMessage.message.createdAt).format(
            ABSOLUTE_DATE_TIME_FORMAT
          )}
        >
          <Text variant="p1-regular" color="neutral-70">
            {moment(latestMessage.message.createdAt)
              .startOf("minutes")
              .fromNow()}
          </Text>
        </Tooltip>
      </Stack>
    </Paper>
  );
};
