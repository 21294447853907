import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useKinds } from "@certa/queries/hooks/kinds.hooks";
import { useCreateWorkflowMutation } from "@certa/queries/hooks/workflow.hooks";
import { useNavigate, useLocation } from "react-router";
import { useIntl } from "react-intl";
import { useGetOrphanKinds } from "./useGetOrphanKinds";
import type { Kind } from "@certa/types";
import {
  ToastTypes,
  dismissToast,
  showToast
} from "@certa/catalyst/components/Toast";
/**
 * Used to notify components using this hook about any global process creation
 * No state is maintained, works on the basis Observer Pattern
 */
function Observable(this: any) {
  this.observers = [];
}

Observable.prototype = {
  subscribe: function (fn: any) {
    this.observers.push(fn);
  },
  unsubscribe: function (fn: any) {
    this.observers.filter((_fn: any) => {
      if (_fn !== fn) {
        return _fn;
      }
      return null;
    });
  },
  trigger: function (arg: boolean) {
    this.observers.forEach((fn: any) => {
      fn.call(this, arg);
    });
  }
};

const observable = new (Observable as any)();

type UseCreateNewWorkflowConfig = {
  blockMultipleProcessCreation?: boolean;
  allowOrphanCreation?: boolean;
};

const getCreatableKinds = (
  data?: Kind[],
  allowOrphanCreation?: boolean,
  orphanKinds: string[] = []
) => {
  /*
  1. If kind is child then
    1. If it is one of orphan kind than show it
    2. else not
  2. Whether child/parent, show if below conditions are met:
      1. whether you have permission to create new workflow or not
      2. it is not users/entity-id workflow 
  */
  return (
    data?.filter(
      kind =>
        (!kind.isRelatedKind ||
          (allowOrphanCreation && orphanKinds.includes(kind.tag))) &&
        kind.features.includes("add_workflow") &&
        !["users", "entity-id"].includes(kind.tag)
    ) || []
  );
};

const getAllCreatableKinds = (unfilteredKinds?: Kind[]) => {
  if (!unfilteredKinds) return [];

  return unfilteredKinds?.filter(
    kind =>
      kind.features.includes("add_workflow") &&
      !["users", "entity-id"].includes(kind.tag)
  );
};

export function useCreateNewWorkflow(args?: UseCreateNewWorkflowConfig) {
  const {
    blockMultipleProcessCreation: shouldBlockMultipleProcessCreation = true,
    allowOrphanCreation: shouldAllowOrphanCreation = true
  } = args || {};
  const {
    data = [],
    status,
    refetch: refetchKinds
  } = useKinds({ hideInternal: true });
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();
  const [isGlobalProcessPending, setGlobalProcessPending] = useState(
    !!window?.localStorage?.getItem("isGlobalProcessPending")
  );
  const orphanKinds = useGetOrphanKinds();

  const creatableKinds = useMemo(
    () => getCreatableKinds(data, shouldAllowOrphanCreation, orphanKinds),
    [data, orphanKinds, shouldAllowOrphanCreation]
  );

  const allCreatableKinds = useMemo(() => getAllCreatableKinds(data), [data]);

  // for cancelling request if user switches routes in-between process creation.
  const controller = useRef(new AbortController());

  const { mutate: createWorkflow, isLoading: isLoadingCreatingNewProcess } =
    useCreateWorkflowMutation();

  const toastIdRef = useRef<string | null>(null);

  const removeProcessPendingFlagInStorage = useCallback(() => {
    window?.localStorage?.removeItem("isGlobalProcessPending");
    window?.dispatchEvent(new Event("storage"));
  }, []);

  const addProcessPendingFlagInStorage = useCallback(() => {
    window?.localStorage?.setItem("isGlobalProcessPending", "true");
    window?.dispatchEvent(new Event("storage"));
  }, []);

  useEffect(() => {
    if (window.onbeforeunload == null) {
      window.onbeforeunload = removeProcessPendingFlagInStorage;
    }
    const fn = (x: boolean) => setGlobalProcessPending(x);
    observable.subscribe(fn);
    const triggerObserver = () => {
      const isGlobalProcessPending =
        window?.localStorage?.getItem("isGlobalProcessPending") === "true";
      observable.trigger(isGlobalProcessPending);
    };
    window.addEventListener("storage", triggerObserver);
    return () => {
      observable.unsubscribe(fn);
      window.removeEventListener("storage", triggerObserver);
    };
  }, [shouldBlockMultipleProcessCreation, removeProcessPendingFlagInStorage]);

  /**
   * If user changes routes when process creation is going on set processCreation to false
   * and cancel ongoing request
   */
  useEffect(() => {
    controller.current.abort("useCreateNewWorkflow aborted API call");
    removeProcessPendingFlagInStorage();

    /**
     *  on-every re-render (parent route change) controller get's resets new object with abort(false).
     *  But If sub-paths changes eg- dashboard/1/2 or tab-switches which wont neccessarily
     *  trigger a re-render and hence controller will have last aborted object
     *  which is already aborted.
     */
    controller.current = new AbortController();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, removeProcessPendingFlagInStorage]);

  const handleKindSelect = useCallback(
    (
      kindTag: string,
      name: string = "Draft",
      parent: number | null = null,
      objectId?: string
    ) => {
      /**
       *  if config instructs to block creation and a lock exists then block the call
       *  no need to handle call blocking at component level
       */
      if (
        shouldBlockMultipleProcessCreation &&
        window?.localStorage?.getItem("isGlobalProcessPending") === "true"
      ) {
        return;
      }

      toastIdRef.current = showToast({
        title:
          intl.formatMessage({
            id: "commonTextInstances.preparingProcess",
            defaultMessage: "Preparing process"
          }) + "...",
        type: ToastTypes.LOADING,
        shouldAutoDismiss: false
      });

      if (shouldBlockMultipleProcessCreation) {
        // set a lock so that no process can be created unless the lock is reset
        addProcessPendingFlagInStorage();
      }

      const updateGlobalProcessCreationLoader = () => {
        if (shouldBlockMultipleProcessCreation) {
          // reset lock once mutation is settled in a non blocking way
          setTimeout(removeProcessPendingFlagInStorage, 0);
        }
      };

      createWorkflow(
        {
          kind: kindTag,
          name,
          parent,
          signal: controller.current.signal,
          object_id: objectId
        },

        {
          onSuccess: response => {
            if (toastIdRef.current) {
              dismissToast(toastIdRef.current);
              toastIdRef.current = null;
            }
            navigate(`/process/${response.id}?new=true`);
            updateGlobalProcessCreationLoader();
          },
          onError: () => {
            if (toastIdRef.current) {
              dismissToast(toastIdRef.current);
              toastIdRef.current = null;
            }
            updateGlobalProcessCreationLoader();
          }
        }
      );
    },
    // no need of abort controller deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createWorkflow,
      navigate,
      intl,
      shouldBlockMultipleProcessCreation,
      addProcessPendingFlagInStorage,
      removeProcessPendingFlagInStorage
    ]
  );
  return {
    kinds: creatableKinds,
    allCreatableKinds,
    status,
    refetchKinds,
    onSelect: handleKindSelect,
    creatingNewProcess: isLoadingCreatingNewProcess,
    isGlobalProcessPending,
    buttonLabel: intl.formatMessage({
      id: "commonTextInstances.newRequest",
      defaultMessage: "New Request"
    })
  };
}

export const useCreatableWorkflowTypeForInstanceSettings = () => {
  const { kinds, ...rest } = useCreateNewWorkflow({
    allowOrphanCreation: true
  });
  const options = useMemo(
    () =>
      kinds?.map(kind => ({
        label: kind.name,
        value: kind.tag
      })),
    [kinds]
  );
  return {
    data: options,
    ...rest
  };
};
