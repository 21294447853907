import { useMemo } from "react";
import { X_AXIS_DATA_TYPE_INDEX } from "../../../constants";
import { valueFormatter, xAxisFormatter } from "../../../utils/chartUtils";
import { INDEX_ZERO } from "@certa/common/constants";
import type { ChartComponentProps } from "@certa/common/types";

type UsePieChartProps = {
  chartData: ChartComponentProps["chartData"];
  shouldShowPercentagesInValueLabels: ChartComponentProps["otherConfig"]["shouldShowPercentagesInValueLabels"];
  xAxis: ChartComponentProps["otherConfig"]["xAxis"];
};

export const usePieChart = ({
  chartData,
  shouldShowPercentagesInValueLabels,
  xAxis
}: UsePieChartProps) => {
  const labels = useMemo(
    () =>
      Object.keys(chartData?.data?.[INDEX_ZERO] || {}).filter(
        label => label !== chartData.xAxisKey
      ),
    [chartData.data, chartData.xAxisKey]
  );

  const pies = useMemo(
    () =>
      labels.map(label => {
        let total = 0;
        return chartData.data
          .filter(data => {
            if (data[chartData.xAxisKey] && data[label] !== null) {
              if (!isNaN(data[label] as any))
                total = total + (data[label] as number);
              return true;
            }
            return false;
          })
          .map(data => ({
            name: data[chartData.xAxisKey],
            [label]: data[label],
            xLabel: data[chartData.xAxisKey],
            yLabel: label,
            total: total,
            formattedValue: valueFormatter(
              data[label],
              chartData.probableDataType
            ),
            shouldShowPercentagesInValueLabels
          }));
      }),
    [
      chartData?.data,
      chartData?.xAxisKey,
      labels,
      shouldShowPercentagesInValueLabels,
      chartData.probableDataType
    ]
  );

  const xAxisLabelFormatter = xAxisFormatter(
    xAxis?.dataTypes?.[X_AXIS_DATA_TYPE_INDEX],
    xAxis?.labelOutputTypes?.[X_AXIS_DATA_TYPE_INDEX]
  );

  return { pies, xAxisLabelFormatter };
};
