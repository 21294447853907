import { get as lodashGet } from "lodash-es";
import { FILTERS_ENUM } from "./constants";
import { getIntlBody } from "../../js/_helpers/intl-helpers";

export const selectedBasicFiltersSelector = state => {
  return [
    {
      label: "status",
      name: FILTERS_ENUM.STATUS_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.STATUS_FILTER.name}].value`
      )
    },
    {
      label: "region",
      name: FILTERS_ENUM.REGION_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.REGION_FILTER.name}].value`
      )
    },
    {
      label: "businessUnit",
      name: FILTERS_ENUM.BUSINESS_UNIT_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.BUSINESS_UNIT_FILTER.name}].value`
      )
    },
    {
      label: "startDateFilter",
      name: FILTERS_ENUM.START_DATE_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.START_DATE_FILTER.name}].value`
      )
    },
    {
      label: "endDateFilter",
      name: FILTERS_ENUM.END_DATE_FILTER.name,
      value: lodashGet(
        state,
        `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.END_DATE_FILTER.name}].value`
      )
    }
  ];
};

export const selectedAdvancedFiltersSelector = state => {
  return {
    label: "advancedFitlers",
    name: FILTERS_ENUM.ADVANCED_FILTER.name,
    value: lodashGet(
      state,
      `workflowList.selectedWorkflowFilters[${FILTERS_ENUM.ADVANCED_FILTER.name}].value`,
      ""
    )
  };
};

/**
 * Returns the selector that retrives the kind definition of the specified
 * kind tag
 * @param {string} kindTag
 */

export const regionPlaceholderSelector = state => {
  const custom_ui_labels = lodashGet(state, "config.custom_ui_labels", null);
  return custom_ui_labels
    ? getIntlBody(custom_ui_labels, "filterPlaceholders.Region")
    : null;
};
export const businessUnitPlaceholderSelector = state => {
  const custom_ui_labels = lodashGet(state, "config.custom_ui_labels", null);
  return custom_ui_labels
    ? getIntlBody(custom_ui_labels, "filterPlaceholders.Business")
    : null;
};
