import { useGetRegionsV2 } from "@certa/queries/hooks/common.hook";
import { REGION } from "@certa/search/src/constants";
import type { SelectOption } from "@certa/types/src/common";
import React from "react";
import { useDefinedProcessLabel } from "../../../../hooks/useDefinedProcessLabels";
import { useSearchStore } from "../../store/useSearchStore";
import { FilterDropdown } from "../FilterDropdown";

export const RegionFilter: React.FC = () => {
  const { data: regionList, status: regionQueryStatus } = useGetRegionsV2({
    limit: "none"
  });
  const regions = regionList ? (regionList as SelectOption[]) : [];
  const definedLabels = useDefinedProcessLabel();
  const regionLabel = definedLabels[REGION];

  const { filters, setFilters } = useSearchStore();

  const options = regions.map(region => ({
    label: region.label,
    value: region.value.toString()
  }));

  return (
    <FilterDropdown
      label={regionLabel}
      options={options}
      selectedValues={filters.regionIds}
      onChange={regionIds => setFilters({ regionIds, businessUnitIds: [] })}
      areOptionsLoading={regionQueryStatus === "loading"}
    />
  );
};
