import type {
  RawAPIResponse,
  ModelledAPIResponse
} from "../types/globalSearch.types";
import { uniqBy } from "lodash-es";

// For API Response info refer to https://www.notion.so/certa/Global-Search-API-10b6781da99446f8b32708ec0569f4ef?pvs=4

/**
 * Backend uses PSQL's ts_vector to tokenize the query (when PSQL enabled).
 * This function tries to mimic the same behaviour to tokenize the query.
 * We need this to show highlighted text in the search results because
 * highlights are not returned in response when PSQL is enabled.
 *
 * Source: https://github.com/lblblong/to-tsvector/blob/master/src/index.ts
 */
function tokenizeQuery(query: string): string[] {
  if (query === undefined || query === null) return [];

  const result = query
    .replace(
      /[ |~|`|!|@|#|$|%|^|&|*|(|)|\-|_|+|=|||\\|[|\]|{|}|;|:|"|'|,|<|.|>|/|?]/g,
      " "
    )
    .replace(/\s+/g, " ")
    .trim()
    .toLowerCase()
    .split(" ");

  return result;
}

/**
 * Mimic the ElasticSearch like highlights for the query.
 */
function createElasticSearchLikeHighlights(query: string): string[] {
  return tokenizeQuery(query).map(token => `<strong>${token}<strong>`);
}

function processResultsForPsql(
  searchResults: ModelledAPIResponse.SearchResult[],
  query: string
): ModelledAPIResponse.SearchResult[] {
  const queryHighlights = createElasticSearchLikeHighlights(query);

  const processedSearchResults = searchResults.map(result => {
    // If highlights are not returned in the response, we use the highlights
    // from the query itself
    if (result.highlights.length === 0) {
      result.highlights = queryHighlights;
    }

    // Score is not returned in PSQL results
    if (result.score === undefined) {
      result.score = 0;
    }

    if ("workflow" in result && result.workflow.score === undefined) {
      result.workflow.score = 0;
    }

    return result;
  });

  return processedSearchResults;
}

/**
 * Groups fields/files with their processes so that they appear together
 * Limits results based to product requirement
 * Sorts results using score
 */
function createGroupedSearchResults(
  searchResults: ModelledAPIResponse.SearchResult[]
): ModelledAPIResponse.SearchResult[] {
  // When user has only fields and filed selected, workflows itself are not
  // returned in matched objects. Thus, we have to collect all workflows from
  // all results and form an array of unique workflows to display
  const allUniqueWorkflows = uniqBy(
    searchResults.map(result =>
      result.type === "workflow" ? result : result.workflow
    ),
    result => result.id
  );

  const sortedWorkflows = allUniqueWorkflows
    .sort((w1, w2) => {
      if (w1.score !== w2.score) {
        // Sort by score if they are not equal
        return w2.score - w1.score;
      } else {
        // If equal sort by date
        return (
          new Date(w2.createdAt).getTime() - new Date(w1.createdAt).getTime()
        );
      }
    })
    .slice(0, 20); // Allow max 20 results

  const groupedResults: ModelledAPIResponse.SearchResult[] =
    sortedWorkflows.reduce(
      (
        acc: ModelledAPIResponse.SearchResult[],
        workflowResult: ModelledAPIResponse.SearchResult
      ) => {
        const lcData = searchResults.filter(
          (result: ModelledAPIResponse.SearchResult) =>
            result.type === "lc" && result.workflow.id === workflowResult.id
        ) as ModelledAPIResponse.LCDataSearchResult[];
        const fieldsData = searchResults.filter(
          (result: ModelledAPIResponse.SearchResult) =>
            result.type === "field" && result.workflow.id === workflowResult.id
        ) as ModelledAPIResponse.FieldResponseSearchResult[];
        const filesData = searchResults.filter(
          (result: ModelledAPIResponse.SearchResult) =>
            result.type === "file" && result.workflow.id === workflowResult.id
        ) as ModelledAPIResponse.FileSearchResult[];

        const l2Data = [...lcData, ...fieldsData, ...filesData]
          .sort((item1, item2) => {
            if (item1.score !== item2.score) {
              return item2.score - item1.score;
            } else {
              return (
                new Date(item2.submittedAt).getTime() -
                new Date(item1.submittedAt).getTime()
              );
            }
          })
          .slice(0, 5);

        return [...acc, workflowResult, ...l2Data];
      },
      [] as ModelledAPIResponse.SearchResult[]
    );

  return groupedResults;
}

export function globalSearchModelCreator(
  rawAPIResponse: RawAPIResponse.GlobalSearchResponse,
  query: string
): ModelledAPIResponse.SearchResult[] {
  const searchResults: ModelledAPIResponse.SearchResult[] = [];
  const normalizedResponse = normalizeResponse(rawAPIResponse);

  for (const matchedObject of rawAPIResponse.results.matched_objects) {
    if (isMatchedWorkflowOnName(matchedObject)) {
      const { result } = createWorkflowSearchResult(
        matchedObject,
        normalizedResponse
      );
      if (result) {
        searchResults.push(result);
      }
    }

    if (isMatchedWorkflowOnLCData(matchedObject)) {
      const results = createLCDataSearchResults(
        matchedObject,
        normalizedResponse
      );
      for (const { result } of results) {
        if (result) {
          searchResults.push(result);
        }
      }
    }

    if (isMatchedFieldResponse(matchedObject)) {
      const answers = createFieldResponseSearchResult(
        matchedObject,
        normalizedResponse
      );

      answers.forEach(answer => {
        if (answer.result) {
          searchResults.push(answer.result);
        }
      });
    }

    if (
      isMatchedMultiFile(matchedObject) ||
      isMatchedAttachment(matchedObject)
    ) {
      const files = createFileSearchResult(matchedObject, normalizedResponse);

      files.forEach(file => {
        if (file.result) {
          searchResults.push(file.result);
        }
      });
    }
  }
  const processedResults = processResultsForPsql(searchResults, query);
  const groupedResults = createGroupedSearchResults(processedResults);

  return groupedResults;
}

export function globalSearchModelCreatorNext(
  rawAPIResponse: RawAPIResponse.GlobalSearchResponse,
  query: string,
  selectedCategories: string[]
): ModelledAPIResponse.SearchResult[] {
  const searchResults: ModelledAPIResponse.SearchResult[] = [];
  const normalizedResponse = normalizeResponse(rawAPIResponse);

  for (const matchedObject of rawAPIResponse.results.matched_objects) {
    if (
      selectedCategories.includes("workflows") &&
      isMatchedWorkflowOnName(matchedObject)
    ) {
      const { result } = createWorkflowSearchResult(
        matchedObject,
        normalizedResponse
      );
      if (result) {
        searchResults.push(result);
      }
    }

    if (
      selectedCategories.includes("files") &&
      (isMatchedMultiFile(matchedObject) || isMatchedAttachment(matchedObject))
    ) {
      const files = createFileSearchResult(matchedObject, normalizedResponse);

      files.forEach(file => {
        if (file.result) {
          searchResults.push(file.result);
        }
      });
    }
  }
  const processedResults = processResultsForPsql(searchResults, query);

  return processedResults;
}

type ExtractedSearchResult<T> = { result: T | null; error: string | null };
function createWorkflowSearchResult(
  matchedWorkflow: RawAPIResponse.MatchedWorkflowName,
  normalizedResponse: RawAPIResponse.NormalizedResponse
): ExtractedSearchResult<ModelledAPIResponse.WorkflowSearchResult> {
  const workflowId = matchedWorkflow._source.id;
  const score = matchedWorkflow._score;
  const workflow = normalizedResponse.workflows.get(workflowId);

  if (workflow) {
    return {
      result: {
        type: "workflow",
        ...createWorkflowModel(workflow, normalizedResponse),
        score,
        highlights: matchedWorkflow?.highlight?.name || []
      },
      error: null
    };
  } else {
    return {
      result: null,
      error: `Workflow with id ${workflowId} not found`
    };
  }
}

function createFieldResponseSearchResult(
  matchedFieldResponse: RawAPIResponse.MatchedFieldResponse,
  normalizedResponse: RawAPIResponse.NormalizedResponse
): ExtractedSearchResult<ModelledAPIResponse.FieldResponseSearchResult>[] {
  const { id: workflowId } = matchedFieldResponse._source;

  return matchedFieldResponse.inner_hits.answers.map(answer => {
    const fieldResponseId = answer._source.id;

    const fieldResponse = normalizedResponse.responses.get(fieldResponseId);
    const fieldId = fieldResponse?.field;
    const field = normalizedResponse.fields.get(fieldId || NaN);
    const workflow = normalizedResponse.workflows.get(workflowId);
    const fieldDefinition = normalizedResponse.fieldDefs.get(
      field?.definition || NaN
    );
    const step = normalizedResponse.steps.get(field?.step || NaN);
    const stepDefinition = normalizedResponse.stepDefs.get(
      step?.definition || NaN
    );

    if (
      field &&
      workflow &&
      fieldDefinition &&
      step &&
      fieldResponse &&
      stepDefinition
    ) {
      return {
        result: {
          type: "field",
          id: field.id,
          body: fieldDefinition.body,
          answer: fieldResponse.answer,
          submittedAt: fieldResponse.submitted_at,
          step: {
            id: step.id,
            name: stepDefinition.name,
            tag: stepDefinition.tag
          },
          workflow: {
            ...createWorkflowModel(workflow, normalizedResponse),
            score: matchedFieldResponse._score,
            type: "workflow",
            highlights: []
          },
          highlights: answer?.highlight?.["answers.answer"] || [],
          score: answer._score
        },
        error: null
      };
    } else {
      return {
        result: null,
        error: `Field response with id ${fieldResponseId} or workflow id ${workflowId} or field id ${fieldId} or field definition id ${field?.definition} or step id ${step?.id} not found`
      };
    }
  });
}

function createLCDataSearchResults(
  matchedLCDataResponse: RawAPIResponse.MatchedWorkflowLCData,
  normalizedResponse: RawAPIResponse.NormalizedResponse
): ExtractedSearchResult<ModelledAPIResponse.LCDataSearchResult>[] {
  const { id: workflowId } = matchedLCDataResponse._source;
  const { lc_data: lcData } = matchedLCDataResponse.inner_hits;

  const workflow = normalizedResponse.workflows.get(workflowId);
  const responseIds = workflow?.answers || [];
  const searchHitMetaData = responseIds.map(responseId => {
    const response = normalizedResponse.responses.get(responseId);
    const field = normalizedResponse.fields.get(response?.field || NaN);
    const fieldDefinition = normalizedResponse.fieldDefs.get(
      field?.definition || NaN
    );
    return {
      workflow,
      fieldDefinition,
      responseId,
      fieldId: field?.id
    };
  });

  return lcData.map(lc => {
    const fieldDefinitionWithMeta = searchHitMetaData.find(
      def => def.fieldDefinition?.workflow_mapping === lc._source.key
    );
    const field = normalizedResponse.fields.get(
      fieldDefinitionWithMeta?.fieldId || NaN
    );
    const fieldResponse = normalizedResponse.responses.get(
      fieldDefinitionWithMeta?.responseId || NaN
    );
    const fieldDefinition = fieldDefinitionWithMeta?.fieldDefinition;
    const step = normalizedResponse.steps.get(field?.step || NaN);
    const stepDefinition = normalizedResponse.stepDefs.get(
      step?.definition || NaN
    );

    if (
      fieldDefinition &&
      field &&
      fieldResponse &&
      step &&
      stepDefinition &&
      workflow &&
      !isAttachmentResponse(fieldResponse)
    ) {
      return {
        result: {
          type: "lc",
          id: field.id,
          // TODO: what happens when the field being found here has HTML body?
          body: fieldDefinition.body,
          answer: fieldResponse.answer,
          submittedAt: fieldResponse.submitted_at,
          step: {
            id: step.id,
            name: stepDefinition.name,
            tag: stepDefinition.tag
          },
          workflow: {
            ...createWorkflowModel(workflow, normalizedResponse),
            score: matchedLCDataResponse._score,
            type: "workflow",
            highlights: []
          },
          highlights: lc?.highlight?.["lc_data.value"] || [],
          score: lc._score
        },
        error: null
      };
    } else {
      return {
        result: null,
        error: `Field definition with workflow mapping ${lc._source.key} or field with definition id ${fieldDefinition?.id} not found`
      };
    }
  });
}

function createFileSearchResult(
  matchedFile:
    | RawAPIResponse.MatchedMultiFile
    | RawAPIResponse.MatchedAttachment,
  normalizedResponse: RawAPIResponse.NormalizedResponse
): ExtractedSearchResult<ModelledAPIResponse.FileSearchResult>[] {
  const { id: workflowId } = matchedFile._source;
  const filesData = matchedFile.inner_hits.files || [];

  return filesData.map(fileData => {
    const { id: fieldResponseId } = fileData._source;
    const fieldResponse = normalizedResponse.responses.get(fieldResponseId);
    const field = normalizedResponse.fields.get(fieldResponse?.field || NaN);
    const workflow = normalizedResponse.workflows.get(workflowId);
    const step = normalizedResponse.steps.get(field?.step || NaN);
    const user = normalizedResponse.users.get(
      fieldResponse?.submitted_by || NaN
    );
    const fieldDefinition = normalizedResponse.fieldDefs.get(
      field?.definition || NaN
    );
    const stepDefinition = normalizedResponse.stepDefs.get(
      step?.definition || NaN
    );

    if (
      fieldResponse &&
      workflow &&
      fieldDefinition &&
      step &&
      stepDefinition &&
      isAttachmentResponse(fieldResponse)
    ) {
      return {
        result: {
          type: "file",
          id: fieldResponse.id,
          body: fieldDefinition.body,
          answer: fieldResponse.answer,
          fileName:
            fieldResponse.attachment
              .split("#")[0]
              .split("?")[0]
              .split("/")
              .pop() || fieldResponse.attachment,
          uploadedAt: fieldResponse.submitted_at,
          uploadedBy: user
            ? // Some results are not returning user info
              {
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email
              }
            : undefined,
          step: {
            id: step.id,
            name: stepDefinition.name,
            tag: stepDefinition.tag
          },
          submittedAt: fieldResponse.submitted_at,
          workflow: {
            ...createWorkflowModel(workflow, normalizedResponse),
            score: matchedFile._score,
            type: "workflow",
            highlights: []
          },
          url: fieldResponse.attachment,
          document_data_match: fileData?.highlight?.document_data !== undefined,
          highlights: [
            ...(fileData?.highlight?.document_data || []),
            ...(fileData?.highlight?.document_name || [])
          ],
          score: fileData._score
        },
        error: null
      };
    } else {
      return {
        result: null,
        error: `File with field response id ${fieldResponseId} or workflow id ${workflowId} not found`
      };
    }
  });
}

function createWorkflowModel(
  workflow: RawAPIResponse.Workflow | RawAPIResponse.ParentWorkflow,
  normalizedResponse: RawAPIResponse.NormalizedResponse
): ModelledAPIResponse.Workflow {
  const hasParents = "parents" in workflow && workflow.parents.length > 0;
  const parentWorkflows: ModelledAPIResponse.Workflow[] = [];
  if (hasParents) {
    for (const parentWorkflowId of workflow.parents) {
      const parentWorkflow =
        normalizedResponse.parentWorkflows.get(parentWorkflowId);
      if (parentWorkflow) {
        parentWorkflows.push(
          createWorkflowModel(parentWorkflow, normalizedResponse)
        );
      }
    }
  }

  let kind: ModelledAPIResponse.Kind | undefined = undefined;

  if ("definition" in workflow && workflow.definition !== undefined) {
    const workflowDefinition = normalizedResponse.workflowDefs.get(
      workflow.definition
    );
    if (workflowDefinition) {
      kind = normalizedResponse.workflowKinds.get(workflowDefinition.kind);
    }
  }

  const workflowStatus = normalizedResponse.workflowStatuses.get(
    workflow.status
  );

  return {
    id: workflow.id,
    name: workflow.name,
    createdAt: workflow.created_at,
    logo: workflow.logo,
    kind,
    parentWorkflows,
    formattedLcData: workflow.formatted_lc_data,
    status: workflowStatus
      ? {
          id: workflowStatus.id,
          label: workflowStatus.display_name,
          colorCode: workflowStatus.color_code
        }
      : undefined
  };
}

function normalizeResponse(
  rawAPIResponse: RawAPIResponse.GlobalSearchResponse
): RawAPIResponse.NormalizedResponse {
  const {
    users = [],
    steps = [],
    step_defs: stepDefs = [],
    field_defs: fieldDefs = [],
    fields = [],
    responses = [],
    workflows = [],
    workflow_defs: workflowDefs = [],
    workflow_kinds: workflowKinds = [],
    workflow_statuses: workflowStatuses = []
  } = rawAPIResponse.results;
  const parentWorkflows = rawAPIResponse.results["+workflows"] || [];
  const normalizedResponse = {
    users: new Map(users.map(user => [user.id, user])),
    steps: new Map(steps.map(step => [step.id, step])),
    stepDefs: new Map(stepDefs.map(stepDef => [stepDef.id, stepDef])),
    fieldDefs: new Map(fieldDefs.map(fieldDef => [fieldDef.id, fieldDef])),
    fields: new Map(fields.map(field => [field.id, field])),
    responses: new Map(responses.map(response => [response.id, response])),
    workflows: new Map(workflows.map(workflow => [workflow.id, workflow])),
    workflowDefs: new Map(
      workflowDefs.map(workflowDef => [workflowDef.id, workflowDef])
    ),
    workflowKinds: new Map(
      workflowKinds.map(workflowKind => [workflowKind.id, workflowKind])
    ),
    parentWorkflows: new Map(
      parentWorkflows.map(workflow => [workflow.id, workflow])
    ),
    workflowStatuses: new Map(
      workflowStatuses.map(workflowStatus => [
        workflowStatus.id,
        workflowStatus
      ])
    )
  };
  return normalizedResponse;
}

function isAttachmentResponse(
  fieldResponse: RawAPIResponse.FieldResponse
): fieldResponse is RawAPIResponse.AttachmentResponse {
  return "attachment" in fieldResponse && fieldResponse.attachment !== null;
}

function isMatchedWorkflowOnName(
  matchedObject: RawAPIResponse.MatchedObject
): matchedObject is RawAPIResponse.MatchedWorkflowName {
  return (
    // For ES
    // For PSQL
    (matchedObject._source.response_to_workflow === "workflow" &&
      "highlight" in matchedObject &&
      matchedObject.highlight !== undefined &&
      "name" in matchedObject.highlight) ||
    (matchedObject._source.response_to_workflow === "workflow" &&
      !("highlight" in matchedObject))
  );
}

function isMatchedWorkflowOnLCData(
  matchedObject: RawAPIResponse.MatchedObject
): matchedObject is RawAPIResponse.MatchedWorkflowLCData {
  return (
    "inner_hits" in matchedObject &&
    "lc_data" in matchedObject.inner_hits &&
    matchedObject.inner_hits.lc_data.length > 0 &&
    matchedObject.inner_hits.lc_data[0].highlight["lc_data.value"].length > 0
  );
}

function isMatchedFieldResponse(
  matchedObject: RawAPIResponse.MatchedObject
): matchedObject is RawAPIResponse.MatchedFieldResponse {
  return (
    "inner_hits" in matchedObject &&
    "answers" in matchedObject.inner_hits &&
    matchedObject.inner_hits.answers.length > 0
  );
}

function isMatchedMultiFile(
  matchedObject: RawAPIResponse.MatchedObject
): matchedObject is RawAPIResponse.MatchedMultiFile {
  return (
    "inner_hits" in matchedObject &&
    "files" in matchedObject.inner_hits &&
    matchedObject.inner_hits.files.length > 0
  );
}

function isMatchedAttachment(
  matchedObject: RawAPIResponse.MatchedObject
): matchedObject is RawAPIResponse.MatchedAttachment {
  return (
    "inner_hits" in matchedObject &&
    "files" in matchedObject.inner_hits &&
    matchedObject.inner_hits.files.length > 0
  );
}
