import { css } from "emotion";
import { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useOverlay } from "@certa/blocks";
import { Envelope } from "@certa/icons/components/Envelope";
import { WrapperWithIcon } from "../common";
import { Stack, Text } from "@certa/blocks/thanos";

import { DropdownMenuItem } from "@certa/catalyst/components/Dropdown";
import { isSupplierUser } from "main/src/modules/common/selectors";
import { useAppSelector } from "@certa/common/hooks";
import { MY_SUBSCRIPTIONS_MODAL_ID } from "@certa/common/constants";
const MY_SUBSCRIPTIONS_MODAL_WIDTH = "1280px";
const TOAST_CONTAINER_HEIGHT = 60;
const DEFAULT_FRAME_HEIGHT = 200;

export const MySubscriptionsIframe = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [contentHeight, setContentHeight] =
    useState<number>(DEFAULT_FRAME_HEIGHT);

  // This useEffect is responsible for dynamically adjusting the height of the iframe based on the content inside it,
  // ensuring that all content is visible without the need for scrolling.
  useEffect(() => {
    const iframe = iframeRef.current;

    const updateHeight = () => {
      const newContentHeight =
        iframe?.contentWindow?.document.getElementById(
          MY_SUBSCRIPTIONS_MODAL_ID
        )?.scrollHeight || 0;

      if (newContentHeight && newContentHeight !== contentHeight) {
        setContentHeight(newContentHeight + TOAST_CONTAINER_HEIGHT);
      }
    };

    const checkHeightPeriodically = setInterval(updateHeight, 1000);

    // Cleanup event listener on component unmount
    return () => {
      clearInterval(checkHeightPeriodically);
    };
  }, [contentHeight]);

  // Updates the iFrame height when the `contentHeight` changes
  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe && contentHeight !== null) {
      const iframeHeight = contentHeight + "px";
      iframe.style.height = iframeHeight;
    }
  }, [contentHeight]);

  return (
    <iframe
      ref={iframeRef}
      src="/mysubscriptions/"
      name="mySubscriptions"
      title="My Subscriptions"
      className={iFrameStyles}
    />
  );
};
//TODO: remove this component after we migrate to new nav sidebar
// https://thevetted.atlassian.net/browse/PLAT-19789
export const MySubscriptions = () => {
  const intl = useIntl();
  const [showMySubscriptions] = useOverlay(
    "mysubscriptions-global",
    <MySubscriptionsIframe />,
    {
      width: `min(90%, ${MY_SUBSCRIPTIONS_MODAL_WIDTH})`,
      hideFooter: true,
      title: (
        <Stack align="center" gap="s2">
          <Text variant="h1-bold">
            {intl.formatMessage({
              id: "report.mySubscriptions",
              defaultMessage: "My Scheduled Reports"
            })}
          </Text>
        </Stack>
      ),

      className: modalCss,
      footer: null,
      centered: true,
      destroyOnClose: true
    }
  );

  /**
   * If the user is a third-party user (supplier),
   * the user shouldn't be shown the MySubscriptions component.
   * We check if the user is a supplier user and if so, we return null,
   * else we return the MySubscriptions component.
   */
  const shouldHideMySubscriptionsOption = useAppSelector(isSupplierUser);

  if (shouldHideMySubscriptionsOption) {
    return null;
  }

  return (
    <DropdownMenuItem
      key="mySubscriptionsModal"
      value={intl.formatMessage({
        id: "report.mySubscriptions",
        defaultMessage: "My Scheduled Reports"
      })}
      onSelect={showMySubscriptions}
    >
      <WrapperWithIcon icon={<Envelope />}>
        {intl.formatMessage({
          id: "report.mySubscriptions",
          defaultMessage: "My Scheduled Reports"
        })}
      </WrapperWithIcon>
    </DropdownMenuItem>
  );
};

const modalCss = css({
  "& .ant-modal-header": {
    margin: "var(--s1) var(--s00) var(--s5) var(--s00) !important"
  }
});

const iFrameStyles = css({
  border: "none",
  width: "100%",
  maxHeight: "80vh"
});
