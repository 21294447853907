//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Caret = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Caret"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.3071 6H4.68343C4.07839 6 3.75707 6.80679 4.22025 7.26716L7.56675 10.7987C7.85189 11.0671 8.27889 11.0671 8.52786 10.7987L11.8034 7.26716C12.2318 6.80679 11.9119 6 11.3069 6H11.3071Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
