import type { AggregateModelledResponse } from "@certa/common/types";

export const commonChartMargin = {
  top: 32,
  right: 38,
  left: 32,
  bottom: 24
};

export const Y_AXIS_WIDTH = 90;

export const X_AXIS_DATA_TYPE_INDEX = 0;

export const SHOW_ALL_TICKS = 0;

export const noChartData: AggregateModelledResponse = {
  xAxisKey: "",
  data: [],
  probableDataType: "float"
};

export const MIN_PRECISION = 0;
export const MAX_PRECISION = 10;
export const MIN_PERCENTAGE = -1;
export const MAX_PERCENTAGE = 100;

export const mapBoxThemeOptions = {
  streets: {
    label: "Streets",
    value: "streets",
    url: "mapbox://styles/mapbox/streets-v11"
  },
  outdoors: {
    label: "Outdoors",
    value: "outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11"
  },
  light: {
    label: "Light",
    value: "light",
    url: "mapbox://styles/mapbox/light-v10"
  },
  light_globe: {
    label: "Light Globe",
    value: "light_globe",
    url: "mapbox://styles/mapbox/light-v11"
  },
  dark: {
    label: "Dark",
    value: "dark",
    url: "mapbox://styles/mapbox/dark-v10"
  },
  dark_globe: {
    label: "Dark Globe",
    value: "dark_globe",
    url: "mapbox://styles/mapbox/dark-v11"
  },
  satellite: {
    label: "Satellite",
    value: "satellite",
    url: "mapbox://styles/mapbox/satellite-v9"
  },
  satellite_streets: {
    label: "Satellite Streets",
    value: "satellite_streets",
    url: "mapbox://styles/mapbox/satellite-streets-v11"
  },
  navigation_day: {
    label: "Navigation Day",
    value: "navigation_day",
    url: "mapbox://styles/mapbox/navigation-day-v1"
  },
  navigation_night: {
    label: "Navigation Night",
    value: "navigation_night",
    url: "mapbox://styles/mapbox/navigation-night-v1"
  }
} as const;

export const DEFAULT_BUBBLE_COLOR = "#cfcfcf";
export const DEFAULT_SPECTRUM_OPACITY = 0.7;
export const DEFAULT_SCALE = 5;
export const DEFAULT_PRECISION = 0;

export const MAX_TOOLTIP_WIDTH = 300;
export const DEFAULT_AXIS_HEIHGT = 50;
