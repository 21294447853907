import Highlighter from "react-highlight-words";
import { css } from "emotion";
import { ArrowRightLight } from "@certa/icons/components/ArrowRightLight";
import { FieldToIcon } from "./fieldIcon";
import { Text, Stack } from "@certa/blocks/thanos";
import { removeHTMLTagsAndTruncate } from "@certa/common/utils/sanitizeHTML";

export const labelWrapper = (label, type, labelColor = "inherit") => {
  const Icon = FieldToIcon(String(type));
  const isLabelString = typeof label === "string";
  const displayLabel = isLabelString ? removeHTMLTagsAndTruncate(label) : label;
  return (
    <Stack gap="s2" inline align="center" itemWidth="100%">
      <Icon size={16} color={labelColor} style={{ flexShrink: 0 }} />
      <Text
        variant="p1-regular"
        color={labelColor}
        title={displayLabel}
        className={css`
          width: 100% !important;
        `}
      >
        {isLabelString ? (
          <Text
            dangerouslySetInnerHTML={{
              __html: displayLabel
            }}
          />
        ) : (
          label
        )}
      </Text>
    </Stack>
  );
};

export const FieldTreeWrapper = (fieldsTree, includeLabel = true) =>
  fieldsTree?.map(stepGroup => {
    return {
      ...stepGroup,
      label: includeLabel
        ? labelWrapper(stepGroup.label, "stepGroup")
        : stepGroup.label,
      labelText: stepGroup.label,
      children: stepGroup.children?.map(step => {
        return {
          ...step,
          label: includeLabel ? labelWrapper(step.label, "step") : step.label,
          labelText: step.label,
          children: step.children.map(field => {
            return {
              ...field,
              label: includeLabel
                ? labelWrapper(field.label, field.field_type)
                : field.label,
              labelText: field.label
            };
          })
        };
      })
    };
  });

export const cascaderSearchConfig = {
  filter: (inputValue, path) => {
    return path.some(option => {
      // 'Select All' option will be an array with field tags
      // all other options will have field tag as string
      // hence checking if the option's value is an array or not
      // before processing string search and excluding 'Select All' option
      // while searching in field tags(value).
      // Note that select all option is made from options that are already available in the list
      // so including it in the search result is not necessary.
      const optionValue = Array.isArray(option.value) ? "" : option.value;
      return (
        option.labelText?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >
          -1 ||
        // Search on field tags too
        optionValue?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) > -1
      );
    });
  },
  sort: (a, b, inputValue) => {
    const labelA = a.labelText?.toLowerCase();
    const labelB = b.labelText?.toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  },
  render: (inputValue, path) => {
    return path.map(function (option, index) {
      const label = option.labelText;
      const node =
        label?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ? (
          <Highlighter
            highlightClassName={css`
              color: #ff4d4f;
              background-color: white;
              padding: 0;
            `}
            searchWords={[inputValue]}
            autoEscape={true}
            textToHighlight={label}
          />
        ) : (
          label
        );
      const labelColor = index > 1 ? "inherit" : "neutral-70";

      return [
        <ArrowRightLight
          key="arrow-icon"
          color={"neutral-70"}
          className={css`
            margin: 0 var(--s2);
            display: ${index === 0 && "none"};
          `}
        />,
        <Stack
          key="stack"
          inline
          align={"center"}
          gap="s1"
          className={css`
            max-width: 180px;
          `}
        >
          {labelWrapper(
            node,
            option.field_type || (index === 0 ? "stepGroup" : "step"),
            labelColor
          )}
        </Stack>
      ];
    });
  }
};
