//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronLeftLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronLeftLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1856 12.4743C11.5337 12.8233 11.5337 13.3898 11.1856 13.7389C10.8365 14.087 10.27 14.087 9.92091 13.7389L4.81432 8.63234C4.46623 8.28325 4.46623 7.71675 4.81432 7.36766L9.92091 2.26107C10.27 1.91298 10.8365 1.91298 11.1856 2.26107C11.5337 2.61016 11.5337 3.17666 11.1856 3.52574L6.70838 8.00193L11.1856 12.4743Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
