import { Checkbox } from "@certa/catalyst/components/Checkbox";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { NewPage } from "@certa/icons/components/NewPage";
import { RelatedProcesses } from "@certa/icons/components/RelatedProcesses";
import { useIntl } from "react-intl";
import { SEARCH_CATEGORIES } from "../GlobalSearchNext.constants";
import type { SearchCategory } from "../GlobalSearchNext.types";
import { useSearchStore } from "../store/useSearchStore";
import { leftPanelStyles } from "./LeftPanel.styles";
import { KindFilter } from "./filters/KindFilter";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
const MINIMUM_SELECTED_CATEGORIES = 1;

type CategoryProps = {
  title: string;
  icon: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  shouldDisableSelection?: boolean;
  shouldShowBorder?: boolean;
};
const Category = ({
  title,
  icon,
  checked,
  onChange,
  shouldDisableSelection,
  shouldShowBorder
}: CategoryProps) => {
  return (
    <Stack
      gap="8"
      padding="8 0"
      dangerouslySetClassName={leftPanelStyles.category(shouldShowBorder)}
      direction="horizontal"
      align="center"
    >
      <div className={leftPanelStyles.iconWrapper}>{icon}</div>
      <Typography
        variant={TypographyVariants.LABEL_SM}
        color={TypographyColors.NEUTRAL_800}
        className={leftPanelStyles.flexGrow}
      >
        {title}
      </Typography>
      <Checkbox
        aria-label={title}
        checked={checked}
        onChange={onChange}
        disabled={shouldDisableSelection}
      />
    </Stack>
  );
};

export const LeftPanel = () => {
  const {
    selectedCategories,
    setSelectedCategories,
    areAdvancedFiltersApplied
  } = useSearchStore();
  const intl = useIntl();
  const areMinimumCategoriesSelected =
    selectedCategories.length === MINIMUM_SELECTED_CATEGORIES;
  const isWorkflowsSelected = selectedCategories.includes(
    SEARCH_CATEGORIES.WORKFLOWS
  );
  const isFilesSelected = selectedCategories.includes(SEARCH_CATEGORIES.FILES);

  const searchingForLabel = intl.formatMessage({
    id: "globalSearch.searchingFor",
    defaultMessage: "Searching for"
  });
  const workflowsLabel = intl.formatMessage({
    id: "globalSearch.identifier.workflows",
    defaultMessage: "Workflows"
  });
  const filesLabel = intl.formatMessage({
    id: "globalSearch.identifier.files",
    defaultMessage: "Files"
  });

  const handleCategoryChange = (category: SearchCategory, checked: boolean) => {
    const updatedCategories = checked
      ? [...selectedCategories, category]
      : selectedCategories.filter(c => c !== category);
    setSelectedCategories(updatedCategories);

    MixPanelActions.track(
      MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_CATEGORY_SELECT,
      {
        categories: updatedCategories.join(",")
      }
    );
  };

  // When we select a kind for advanced filters, then search only happen on that workflow,
  // and not on file categories or any other workflows, hence left panel should not be rendered
  if (areAdvancedFiltersApplied) return null;

  return (
    <Stack
      gap="8"
      padding="4 0 8 0"
      dangerouslySetClassName={leftPanelStyles.wrapper}
    >
      <Typography
        variant={TypographyVariants.OVERLINE_SMALL}
        color={TypographyColors.NEUTRAL_700}
        className={leftPanelStyles.topSection}
      >
        {searchingForLabel}
      </Typography>
      <Category
        title={workflowsLabel}
        icon={<RelatedProcesses size={12} />}
        checked={isWorkflowsSelected}
        shouldDisableSelection={
          areMinimumCategoriesSelected && isWorkflowsSelected
        }
        onChange={checked =>
          handleCategoryChange(SEARCH_CATEGORIES.WORKFLOWS, checked)
        }
        shouldShowBorder={true}
      />
      <KindFilter isDisabled={!isWorkflowsSelected} />
      <Category
        title={filesLabel}
        icon={<NewPage size={12} />}
        checked={isFilesSelected}
        shouldDisableSelection={areMinimumCategoriesSelected && isFilesSelected}
        onChange={checked =>
          handleCategoryChange(SEARCH_CATEGORIES.FILES, checked)
        }
      />
    </Stack>
  );
};
