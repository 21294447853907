//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Send = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Send"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3347)">
            <path
              d="M11.1814 6.05275C11.2986 5.90194 11.2837 5.68578 11.1466 5.55307C11.0105 5.42036 10.7968 5.41332 10.6518 5.53799L4.03797 11.2315L0.545372 9.86416C0.235337 9.74452 0.0227034 9.45194 0.00184447 9.11616C-0.0200159 8.78136 0.153875 8.46365 0.447004 8.30482L14.9465 0.086694C15.178 -0.0399856 15.4602 -0.0269159 15.6798 0.119873C15.9004 0.267665 16.0217 0.526044 15.9968 0.791479L14.7249 14.2064C14.6991 14.4848 14.5441 14.7352 14.3086 14.881C14.0721 15.0268 13.7809 15.0519 13.5246 14.9473L9.27369 13.2824L6.60476 15.8119C6.40404 16.002 6.10992 16.0532 5.85753 15.9416C5.60514 15.829 5.44318 15.5757 5.44516 15.2972V13.3105L11.1814 6.05275Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3347">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
