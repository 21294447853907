//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Close = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Close"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.675 4.23972L9.91176 8.00112L13.6723 11.7644C14.1063 12.1966 14.1063 12.8918 13.6741 13.3259L13.3247 13.6762C13.1193 13.8869 12.8384 14.0013 12.543 14.0013H12.5412C12.2458 14.0013 11.9685 13.8851 11.7613 13.6771L7.99901 9.91201L4.23576 13.6725C3.80441 14.1047 3.1101 14.1065 2.67605 13.6743L2.32576 13.3276C2.11503 13.1205 1.99976 12.8395 1.99976 12.545C1.99976 12.2488 2.11592 11.9705 2.32393 11.7625L6.08903 8.00016L2.32762 4.2369C1.89357 3.80465 1.89357 3.10945 2.32582 2.6745L2.67522 2.3251C2.88234 2.11438 3.16241 2 3.45777 2C3.75133 2 4.03231 2.11707 4.23942 2.32418L8.00083 6.08835L11.7632 2.32856C11.9766 2.11333 12.2602 2.00438 12.5448 2.00438C12.8267 2.00438 13.1085 2.11154 13.3247 2.32676L13.6741 2.67526C13.8857 2.88058 14.0001 3.16155 14.0001 3.45691C14.0019 3.75227 13.8857 4.03145 13.6777 4.23946L13.675 4.23972Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
