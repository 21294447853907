import { forwardRef } from "react";
import { SimpleStackedBarChart } from "./SimpleStackedBarChart";
import { GroupedBarChart } from "./GroupedBarChart";
import { SimpleStackedBarChartHorizantal } from "./SimpleStackedBarChartHorizantal";
import type { ChartComponentProps } from "@certa/common/types";

export const BarChart = forwardRef((props: ChartComponentProps, ref) => {
  if (props.otherConfig.isGroupedBarChart) {
    return <GroupedBarChart {...props} ref={ref} />;
  } else if (props.otherConfig.chartOrientation === "horizontal") {
    return <SimpleStackedBarChartHorizantal {...props} ref={ref} />;
  } else {
    return <SimpleStackedBarChart {...props} ref={ref} />;
  }
});
