import styles from "./MobileUserDrawer.module.css";

type MobileMenuItemProps = {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  key?: string | number;
};

export const MobileMenuItem = ({
  onClick,
  children,
  className,
  key
}: MobileMenuItemProps) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.mobileUserDrawerButton} ${className || ""}`}
      key={key ? key : ""}
    >
      {children}
    </button>
  );
};
