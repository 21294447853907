import color from "color";

export const getBrandColors = (brandColor?: string) => {
  if (!brandColor) return null;

  const brandColorObj = color(brandColor);

  if (Math.round(brandColorObj.black()) > 65) {
    const darkBrandColors = {
      "brand-50": brandColorObj.gray(90).hex(),
      "brand-100": brandColorObj.gray(70).hex(),
      "brand-200": brandColorObj.gray(50).hex(),
      "brand-300": brandColorObj.gray(30).hex(),
      "brand-400": brandColorObj.hex(),
      "brand-500": brandColorObj.darken(0.2).hex(),
      "brand-600": brandColorObj.darken(0.4).hex()
    };

    return darkBrandColors;
  }

  const brandColors = {
    "brand-50": brandColorObj.lighten(1).hex(),
    "brand-100": brandColorObj.lighten(0.8).hex(),
    "brand-200": brandColorObj.lighten(0.6).hex(),
    "brand-300": brandColorObj.lighten(0.4).hex(),
    "brand-400": brandColorObj.hex(),
    "brand-500": brandColorObj.darken(0.2).hex(),
    "brand-600": brandColorObj.darken(0.4).hex()
  };

  return brandColors;
};

/**
 * Returns true if the background color is dark based on WCAG contrast guidelines
 * @param backgroundColor hexcode or CSS color name of background color
 * @returns boolean indicating if the color is considered dark
 * @example
 * isBackgroundColorDark("#000000") // true
 * isBackgroundColorDark("#ffffff") // false
 * isBackgroundColorDark("rgb(30, 30, 30)") // true
 */
export const isBackgroundColorDark = (backgroundColor: string) => {
  let backgroundColorObj;
  try {
    backgroundColorObj = color(backgroundColor);
  } catch (err) {
    // console.error("Provide a correct color format ", err);
    return false;
  }

  // Using luminance which is more accurate for perceived brightness
  // WCAG guidelines consider 0.5 as the midpoint between light and dark
  const luminance = backgroundColorObj.luminosity();

  // Calculate relative luminance according to WCAG 2.0
  return luminance < 0.5;
};
