import React from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { languageActions } from "../actions";
import { languages } from "../intlLanguages";
import { languageConstants } from "../constants";
import { css } from "emotion";
import { FormattedMessage } from "react-intl";
import { get as lodashGet } from "lodash-es";
import { MenuItem, SubMenu } from "@certa/blocks/thanos";
import { Language } from "@certa/icons/components/Language";
import { useGetSupportedLanguages } from "@certa/common/hooks/useGetSupportedLanguages";
import { stringConstants } from "../../../constants/stringConstants";
class SelectLanguageComponent extends React.Component {
  handleLanguageChange = event => {
    const { title } = event.item.props;
    this.props.dispatch(languageActions.updateUserLanguage(title));
  };

  setLanguage = title => () =>
    this.props.dispatch(languageActions.updateUserLanguage(title));

  get preferredLanguage() {
    return lodashGet(
      this.props,
      "authentication.user.prefered_language",
      languageConstants.DEFAULT_LOCALE
    );
  }

  renderLanguageMenuTitle = () => {
    return (
      <span>
        <FormattedMessage id={"loginPageInstances.languageText"} />
        {stringConstants.colonWithSpace}
        <span style={{ textTransform: "uppercase" }}>
          {this.preferredLanguage}
        </span>
      </span>
    );
  };

  renderLanguageName = languageSymbol => {
    const languageEndonym = languages.endonyms[languageSymbol];
    return languageEndonym
      ? `${languageSymbol}(${languageEndonym})`
      : languageSymbol;
  };
  get sanitizedProps() {
    const otherProps = { ...this.props };
    delete otherProps.authentication;
    delete otherProps.dispatch;
    delete otherProps.config;
    delete otherProps.renderNewUI;
    return otherProps;
  }

  render() {
    const { supportedLanguages } = this.props;
    const { renderNewUI = false } = this.props;
    if ((supportedLanguages || []).length <= 1) return null;
    const sanitizedProps = this.sanitizedProps;

    if (renderNewUI) {
      return (
        <SubMenu
          {...sanitizedProps}
          title={this.renderLanguageMenuTitle()}
          key="language-menu"
          icon={Language}
          popupOffset={[14, -14]}
        >
          {supportedLanguages.map(language => {
            return (
              <MenuItem
                key={language.value}
                title={language.label}
                onClick={this.setLanguage(language.value)}
              ></MenuItem>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.SubMenu
        {...sanitizedProps}
        title={this.renderLanguageMenuTitle()}
        className="header-menu"
        onClick={this.handleLanguageChange}
        triggerSubMenuAction={this.props.trigger}
      >
        {supportedLanguages.map(language => (
          <Menu.Item
            {...sanitizedProps}
            key={language.value}
            title={language.value}
            className={css`
              cursor: pointer;
              padding: 10px;
              &:hover {
                background-color: #eee !important;
              }
            `}
          >
            {this.renderLanguageName(language.value)}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    );
  }
}

// Made a HOC to use hook i.e. languages should come from one source only
const SelectLanguageHOC = props => {
  const supportedLanguages = useGetSupportedLanguages();
  return (
    <SelectLanguageComponent
      {...props}
      supportedLanguages={supportedLanguages}
    />
  );
};

function mapStateToProps(state) {
  const { config, authentication } = state;
  return {
    config,
    authentication
  };
}

export const SelectLanguage = connect(mapStateToProps)(SelectLanguageHOC);
