import { css } from "emotion";

export const filtersStyles = {
  kindFilterWrapper: css({
    maxHeight: "100%",
    overflowY: "auto",
    paddingLeft: "var(--space-16)",
    paddingRight: "var(--space-16)",
    "&:focus-visible": {
      outline: "none"
    }
  })
};
