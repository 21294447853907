import type {
  StepGroupsData,
  TaskDetails
} from "@certa/queries/types/workflow.types";
import type { TaskCategoryTypes } from "@certa/tasks/src/types";
import type {
  Field,
  StepUpdateActions,
  TaskVersionDetails
} from "@certa/types";
import type { StepGroupSourceType } from "main/src/js/components/WorkflowDetails/StepBodyContext";
import type { OnFieldChange } from "main/src/js/components/WorkflowDetails/StepBodyForm/StepBodyForm.helper";
import type { Dispatch, SetStateAction } from "react";

export enum ActiveTaskPanel {
  MY_TASKS = "MY_TASKS",
  ALL_TASKS = "ALL_TASKS",
  MY_GROUPS = "MY_GROUP",
  BOTH_ACTIVE = "BOTH_ACTIVE",
  NONE = "NONE"
}

export enum TaskLaneTaskType {
  FYI = "FYI",
  DOABLE = "doable",
  INTERNAL = "internal",
  READONLY = "readonly"
}

export const submittableSteps: TaskLaneTaskType[] = [
  TaskLaneTaskType.DOABLE,
  TaskLaneTaskType.INTERNAL
];

export type TaskChangeArgs = {
  taskLaneId: number;
  taskId: number;
  activeTab?: string | null;
};

export type SectionChangeArgs = {
  fieldId: number;
};

export type SidebarProps = {
  processDetails: TaskDetails;
  taskLanesData: StepGroupsData;
};

export type SidebarContextTypes = {
  processId: number;
  selectedTask: number | null;
  selectedTaskLane: number | null;
  isProgressMapVisible: boolean;
  toggleProgressMap: () => void;
  handleTaskClick: (taskArgs: TaskChangeArgs) => void;
  isSidebarPinned: boolean;
  setIsSidebarPinned: (value: boolean) => void;
};

export type TaskBodyProps = {
  selectedTask: number;
  processId: number;
  selectedField: number | null;
  handleSectionChange: (payload: SectionChangeArgs) => void;
};

export type TaskCompletionStates = "all" | "mine";

export enum TFilterTab {
  ALL = "all",
  MY_GROUPS = "todoStepGroups",
  ASSIGNED_TO_ME = "taggedSteps"
}

export enum TOverviewMode {
  BASIC = "basic",
  ADVANCED = "advanced"
}

export type TaskCountConfig = {
  [TaskCategoryTypes.ME]: number;
  [TaskCategoryTypes.MY_GROUPS]: number;
};

export type UseTaskCategoryFiltersProps = {
  initialFilter: TaskCategoryTypes;
  tasklane: StepGroupsData | undefined;
};

export type UseTaskCategoryFiltersConfig = {
  taskCategoryFilter: TaskCategoryTypes;
  setTaskCategoryFilter: Dispatch<SetStateAction<TaskCategoryTypes>>;
  tasklaneFilteredByCategory?: StepGroupsData;
  taskCounts: TaskCountConfig;
  taggedStepGroups: number[];
};

export type NodeState = "expanded" | "collapsed" | "mixed";

type WizardSaveResponse = (payload: {
  answer: string;
  field: Field;
  workflowId: number;
  extraJSON?: JSON;
}) => void;

export type WizardStepParams = {
  clearResponse: (args: {
    responseId: any;
    field: Field;
    workflowId: number;
  }) => void;
  completed: boolean;
  currentStepFields: any;
  error: Record<string, any>;
  fieldInProgressData: any;
  handleStepUpdateSideEffects: (
    isActive?: boolean | undefined,
    silently?: boolean | undefined,
    actionType?: StepUpdateActions | undefined
  ) => Promise<void>;
  isReadOnlyWorkflow: boolean;
  isWorkflowBeingMigrated: boolean;
  is_locked: boolean;
  onFieldChange: OnFieldChange;
  permission: any[];
  refetchCurrentStepFields: () => void;
  resetMultiFileUploadInProgress: any;
  saveResponse: WizardSaveResponse;
  setMultiFileUploadInProgress: () => void;
  source: StepGroupSourceType;
  workflowId: number;
  validateAnswer: (field: Field, ans: any) => boolean;
};

export type WizardFieldsStepParams = WizardStepParams & {
  stepVersionFields: TaskVersionDetails | Record<string, never>;
  isCERTA2Enabled: boolean;
  dynamicUserPerms: any;
  setFieldError: (field: Field, fieldError?: string) => void;
  // The following props are not needed. Might be used in the older fields.
  // Can be removed once the older fields are removed.
  formProps: any;
  dispatch: any;
  intl: any;
};
