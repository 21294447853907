import { useEffect, useCallback, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  NavigationType,
  Outlet
} from "react-router";
import { css } from "emotion";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import Godaam from "../../js/utils/storage";
import { isCERTA2Enabled as isCERTA2EnabledSelector } from "../../modules/common/selectors";
import { UnreachableError } from "@certa/types";
import {
  canSeeCERTA2,
  getDashboardsGenericRoute,
  getSearchRoute,
  getTasksRoute
} from "@certa/common/utils/routes";
import { usePrevious } from "@certa/common/hooks";
import { useNewVerticalSideNav } from "@certa/common/toggles";
import {
  preloadAuthModules,
  preloadPrivateModules
} from "@certa/routing/utils";
import { basename } from "../../config";

// const activeOpacity = { opacity: 1 };
// const inActiveOpacity = { opacity: 0 };

const CERTA2_PERSIST_PATHS = [
  getTasksRoute(),
  getDashboardsGenericRoute(),
  getDashboardsGenericRoute().substring(
    0,
    getDashboardsGenericRoute().length - 1
  ),
  getSearchRoute()
];

/**
 * @param props
 * - fallbackLoader is the loading UI to be served until loadable/lazy component is loaded
 */
export const Routes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const previousProps = usePrevious(location);
  const user = useSelector(
    state => !!lodashGet(state, "authentication.user.csrf", null)
  );
  /** @deprecated This flag is no longer in use. */
  const isCERTA2Enabled = useSelector(isCERTA2EnabledSelector);

  const getIdFromPath = useCallback(
    path => {
      const pathArray = path.split("/");
      const id =
        isCERTA2Enabled && canSeeCERTA2() ? pathArray[2] : pathArray[3];
      return id;
    },
    [isCERTA2Enabled]
  );

  const calculateNav = useCallback(
    prevLocation => {
      const customHistory = JSON.parse(Godaam.customHistory);

      const currentWFId = getIdFromPath(location.pathname);
      const prevWFId = getIdFromPath(prevLocation.pathname);

      if (
        location.pathname === "/workflows/instances/" ||
        location.pathname === "/workflows/instances"
      ) {
        setInitialHistoryState(location);
      }

      if (
        isCERTA2Enabled &&
        canSeeCERTA2() &&
        CERTA2_PERSIST_PATHS.some(certa2Path =>
          location.pathname.includes(certa2Path)
        )
      ) {
        setInitialProcessHistoryState(location);
      }

      if (prevWFId && currentWFId && prevWFId !== currentWFId) {
        // check if user came back, if so, remove the workflow link
        // that they returned from
        if (doesPathExists(customHistory, currentWFId)) {
          removeFromCustomHistory();
        } else {
          // Removing this since history.push in useWorkflowOverlay.hook.tsx OpenInWindow
          // gives history.action as `REPLACE` not `PUSH` which created back navigation bugs
          // Redirected routes will not be pushed to customHistory
          // if (history.action === "REPLACE") return;

          const histObj = {
            id: prevWFId,
            pathname: prevLocation.pathname,
            search: prevLocation.search
          };
          pushToCustomHisotry(histObj);
        }
      }
    },

    [getIdFromPath, isCERTA2Enabled, location]
  );

  /**
   * Keeping a clone of the browser history
   * only for the paths that are related to this application
   *
   * Why do we need this?
   * In the current customHistory only the urls with different wf ids are being stored
   * but when navigating back from a wizard step, instead of ignoring steps
   * (like we do when navigating back from a child wf)
   * in wizard we need to ignore sections.
   * So if a user goes from WF1S1(non wizard) to WF1S2(wizard) and then press back
   * he should be take to WF1S1(non wizard)
   *
   * How navigation to a wizard step works now?
   * Following is the user navigation history stack when landing on a wizard step
   * wf/step=s0&group=g0 => (PUSH) => wf/?step=s1&group=g1 => (REPLACE) => wf/wizard/?step=s1&group=g1 => (REPLACE) => wf/wizard/?step=s2&group=g1&field=f1
   *
   * What should happen now?
   * When pressed back from wizard user should go to wf/step=s0&group=g0
   * hence we need access to not just previous location but many location before that
   * hence a history clone
   */

  const previousStoredHistory = JSON.parse(Godaam.historyClone) || [];
  // Removing the last element as on refresh new route is pushed again and will create a duplicate route.
  const historyClone = useRef(previousStoredHistory.slice(0, -1));

  const createLocationObjectWithParsedSearch = location => {
    const search = new URLSearchParams(location.search);
    return {
      location,
      pathname: location.pathname,
      extra: {
        step: search.get("step"),
        group: search.get("group"),
        field: search.get("field")
      }
    };
  };

  const saveHistoryInGodaam = () => {
    Godaam.historyClone = JSON.stringify(historyClone.current.slice(-25));
  };

  useEffect(() => {
    // Add the landing url to the history clone
    // Since this hooks only runs on the first render, only the landing url will be added
    if (location) {
      historyClone.current.push(createLocationObjectWithParsedSearch(location));
      saveHistoryInGodaam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (navigationType) {
      case NavigationType.Pop:
        historyClone.current.pop();
        saveHistoryInGodaam();
        break;
      case NavigationType.Push:
        historyClone.current.push(
          createLocationObjectWithParsedSearch(location)
        );
        saveHistoryInGodaam();
        break;
      case NavigationType.Replace:
        historyClone.current.pop();
        historyClone.current.push(
          createLocationObjectWithParsedSearch(location)
        );
        saveHistoryInGodaam();
        break;
      default:
        throw new UnreachableError(navigationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // Syncing the changes from local storage to the ref var
    historyClone.current = JSON.parse(Godaam.historyClone || "[]");
  }, []);

  useEffect(() => {
    /**
     * This will only run for PR Previews
     * This is removing #! from the URL as it's append to handle s3 404 not found error
     * when virtual path URL get reloaded
     */
    if (basename) {
      const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
      if (path) {
        navigate(path, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialHistoryState = initialLocation => {
    const customHistory = [
      {
        id: null,
        pathname: "/workflows/instances/",
        search: initialLocation.search
      }
    ];
    Godaam.customHistory = JSON.stringify(customHistory);
  };

  const setInitialProcessHistoryState = initialLocation => {
    const customHistory = [
      {
        id: null,
        pathname: initialLocation.pathname,
        search: initialLocation.search
      }
    ];
    Godaam.customHistory = JSON.stringify(customHistory);
  };

  const doesPathExists = (hist, id) =>
    hist ? hist.some(item => item.id === id) : false;

  const pushToCustomHisotry = item => {
    const arr = JSON.parse(Godaam.customHistory) || [];
    arr.push(item);
    Godaam.customHistory = JSON.stringify(arr);
  };

  const removeFromCustomHistory = () => {
    const arr = JSON.parse(Godaam.customHistory);
    arr.splice(arr.length - 1, 1);
    Godaam.customHistory = JSON.stringify(arr);
  };

  useEffect(() => {
    if (previousProps !== location) {
      if (!previousProps) {
        setInitialProcessHistoryState(location);
      } else {
        calculateNav(previousProps);
      }
    }
  }, [calculateNav, previousProps, location]);

  /**
   * This is used to preload modules depending
   * upon authentication.
   */
  useEffect(() => {
    if (user) {
      preloadPrivateModules();
      Sentry.setTag("authenticated", true);
    } else {
      preloadAuthModules();
      Sentry.setTag("authenticated", false);
    }
  }, [user]);

  useEffect(() => {
    // if user is logged in successfully using sso, then redirect to ssoRedirectUrl
    if (user) {
      // Note: ssoRedirectUrl exists only if user performed an sso
      const ssoRedirectUrl = sessionStorage.getItem("ssoRedirectUrl");
      if (ssoRedirectUrl) {
        sessionStorage.removeItem("ssoRedirectUrl");
        if (ssoRedirectUrl !== "/") {
          navigate(ssoRedirectUrl);
        }
      }
    }
  }, [navigate, user]);

  return (
    <RoutesAppRedesignUILayoutSwitcher>
      <Outlet />
    </RoutesAppRedesignUILayoutSwitcher>
  );
};

const styles = {
  container: css`
    position: relative;
    & > div {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }
  `
};
/**
 * This component is used to switch between the new and old layout of the routes
 *
 * TODO: Remove this component once the migration to new UX designs is completed
 * and enabled on production by default.
 */
const RoutesAppRedesignUILayoutSwitcher = props => {
  const { children } = props;
  const isNewAppRedesignUI = useNewVerticalSideNav();

  return isNewAppRedesignUI ? (
    <>{children}</>
  ) : (
    <div className={styles.container}>
      <div>{children}</div>
    </div>
  );
};
