import type { Alert, SelectOption } from "@certa/types";
import { getUserLanguage } from "main/src/js/components/common/intlLanguages";
import type {
  SearchedTask,
  SearchFileResults,
  SearchResults
} from "../types/search.types";
import type { WorkflowLCData } from "../types/workflow.types";
import { queryClient } from "@certa/queries/utils/utils";

export const searchStatusListModelCreator = (response: any): SelectOption[] => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return (
    response
      ?.map((status: any) => ({
        label: status.display_name,
        value: status.id,
        colorCode: availableStatusesKeyedById[status.id]?.color_code
      }))
      ?.sort((a: SelectOption, b: SelectOption) =>
        a.label
          .toUpperCase()
          .localeCompare(b.label.toUpperCase(), getUserLanguage())
      ) || []
  );
};

const processSearchModelCreator = (task: any): SearchedTask => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return {
    id: task.id,
    name: task.name,
    createdAt: task.created_at,
    sortingPrimaryField: task.sorting_primary_field,
    lcData: task.lc_data.map(
      (data: any): WorkflowLCData => ({
        value: data.value,
        label: data.translated_label || data.label,
        displayType: data.display_type,
        format: data.format,
        hideFromWorkflow: !!data.hide_from_workflow
      })
    ),
    processFamily: task?.workflow_family || [],
    alerts: task.alerts.map(
      (alert: any): Alert => ({
        id: alert.id,
        fieldId: alert.field_id,
        stepId: alert.step_id,
        stepGroupId: alert.step_group_id,
        workflowId: alert.workflow_id,
        catColorLabel: alert.cat_color_label,
        alertTag: alert.alert_tag
      })
    ),
    status: {
      id: task.status.id,
      displayName: task.status.display_name,
      colorCode: availableStatusesKeyedById[task.status.id]?.color_code
    },
    progress: task.progress,
    rank: task.rank,
    logo: task.logo,
    isWorkflowBeingMigrated: !!task.is_being_migrated
  };
};

export const searchResultsModelCreator = (response: any): SearchResults => {
  return {
    count: response.count,
    results: response.results.map(processSearchModelCreator),
    next: response.next,
    previous: response.previous
  };
};

export const searchFileResultsModelCreator = (
  response: any
): SearchFileResults => {
  return {
    count: response.count,
    results: response.results.map((result: any) => {
      return {
        id: result.id,
        submittedBy: {
          id: result.submitted_by.id,
          firstName: result.submitted_by.first_name,
          lastName: result.submitted_by.last_name,
          email: result.submitted_by.email
        },
        submittedAt: result.submitted_at,
        workflow: {
          id: result.workflow_id,
          name: result.workflow_name,
          logo: result.workflow_logo
        },
        attachment: {
          url: result.attachment_url,
          name: result.attachment_name
        },
        isEncrypted: result.is_encrypted
      };
    }),
    next: response.next,
    previous: response.previous
  };
};
