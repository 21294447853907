//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Edit = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Edit"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3454)">
            <path
              d="M11.6509 0C11.8668 0 12.0847 0.0844506 12.248 0.250663L12.2493 0.252004L14.7571 2.83242C14.7591 2.83443 14.7611 2.83577 14.7624 2.83778C15.0783 3.1796 15.0823 3.72451 14.7558 4.05695L6.00402 12.9685C5.84539 13.1301 5.6262 13.2192 5.40833 13.2192L2.87554 13.2326H2.87422C2.65634 13.2326 2.43783 13.1435 2.2792 12.9819C2.11201 12.8117 2.03303 12.5845 2.03303 12.3626V12.3613L2.04619 9.76888C2.04619 9.5276 2.15151 9.32586 2.2792 9.17773C2.28381 9.17237 2.28842 9.16701 2.29302 9.16231L9.29388 2.04701L11.0435 0.252093C11.0461 0.249412 11.0481 0.247401 11.0507 0.245391C11.2185 0.084533 11.4351 0.00141898 11.651 0.00141898L11.6509 0ZM11.6529 2.08317L11.0934 2.65287L12.4237 4.00742L12.9832 3.43772L11.6529 2.08317ZM9.88952 3.87877L3.7418 10.1387L3.73193 11.4899L5.07206 11.4792L11.2191 5.23263L9.88952 3.87877Z"
              fill="currentColor"
            />
            <rect
              y={14.5}
              width={16}
              height={1.5}
              rx={0.75}
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath
              style={{
                visibility: "visible",
                display: "block"
              }}
              id="prefix__clip0_21705_3454"
            >
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
