import React from "react";
import {
  DropdownMenuCheckboxItem,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownSubMenu,
  DropdownSubMenuContent,
  DropdownSubMenuTrigger
} from "../../../../components/Dropdown";
import styles from "./UserProfileMenuItem.module.css";

type UserProfileMenuItemProps = {
  text: string;
  value: string;
  icon?: React.ReactElement;
  onSelect: (value: string, checked?: boolean) => void;
  preventDropdownClose?: boolean;
  checked?: boolean;
  divider?: boolean;
  children?: React.ReactNode;
};

export const UserProfileMenuItem = (props: UserProfileMenuItemProps) => {
  const {
    text,
    icon,
    value,
    checked: isChecked,
    divider: hasDivider,
    onSelect,
    preventDropdownClose: shouldPreventDropdownClose,
    children
  } = props;

  if (children) {
    let subMenuItemTrigger = null;
    const subMenuItems: React.ReactElement[] = [];
    React.Children.forEach(children, (child, index) => {
      if (index === 0) {
        subMenuItemTrigger = child;
      } else {
        subMenuItems.push(<>{child}</>);
      }
    });

    return (
      <>
        <DropdownSubMenu>
          <>
            {subMenuItemTrigger}
            <DropdownSubMenuContent
              height="300px"
              minWidth="200px"
              width="100%"
            >
              {subMenuItems}
            </DropdownSubMenuContent>
          </>
        </DropdownSubMenu>
        {hasDivider && (
          <DropdownMenuDivider
            className={styles.userProfileMenuItemSeparator}
          />
        )}
      </>
    );
  }

  return (
    <MenuItem
      icon={icon}
      text={text}
      checked={isChecked}
      key={value}
      value={value}
      onSelect={onSelect}
      preventDropdownClose={shouldPreventDropdownClose}
      divider={hasDivider}
    />
  );
};

type UserProfileSubMenuItemTriggerProps = {
  icon: UserProfileMenuItemProps["icon"];
  text: UserProfileMenuItemProps["text"];
};
export const UserProfileSubMenuItemTrigger = ({
  icon,
  text
}: UserProfileSubMenuItemTriggerProps) => {
  return (
    <DropdownSubMenuTrigger>
      <div className={styles.userProfileMenuItem}>
        {icon}
        {text}
      </div>
    </DropdownSubMenuTrigger>
  );
};

type MenuItemProps = Omit<UserProfileMenuItemProps, "children">;
const MenuItem = (props: MenuItemProps) => {
  const {
    onSelect,
    preventDropdownClose: shouldPreventDropdownClose = false,
    text,
    value,
    checked: isChecked,
    divider: hasDivider,
    icon
  } = props;

  if (isChecked) {
    return (
      <>
        <DropdownMenuCheckboxItem
          checked={isChecked}
          value={value}
          onChange={(_checked, _value) => onSelect(_value, _checked)}
        >
          <div className={styles.userProfileMenuItem}>
            {icon}
            {text}
          </div>
        </DropdownMenuCheckboxItem>
        {hasDivider && (
          <DropdownMenuDivider
            className={styles.userProfileMenuItemSeparator}
          />
        )}
      </>
    );
  }

  return (
    <>
      <DropdownMenuItem
        value={value}
        onSelect={onSelect}
        preventDropdownClose={shouldPreventDropdownClose}
      >
        <div className={styles.userProfileMenuItem}>
          {icon}
          {text}
        </div>
      </DropdownMenuItem>
      {hasDivider && (
        <DropdownMenuDivider className={styles.userProfileMenuItemSeparator} />
      )}
    </>
  );
};
