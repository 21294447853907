import { useCallback, useRef } from "react";

/**
 * A custom hook that returns a memoized callback which always has access to the latest props/state,
 * without adding them as dependencies to useCallback.
 *
 * @param callback The function to be memoized
 * @returns A memoized version of the callback that always has access to latest props
 */
export function useCallbackRef<T extends (...args: any[]) => any>(
  callback: T
): T {
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    return callbackRef.current.apply(null, args);
  }, []) as T;
}
