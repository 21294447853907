import type {
  CreateDataObjectModelResponse,
  CreateDataObjectModelReturn,
  CreateDataObjectDetailsModelResponse,
  CreateDataObjectDetailsModelReturn,
  CreateDataObjectTypesModelResponse,
  CreateDataObjectTypesModelReturn,
  CreateDataObjectWorkflowRelationActionsModelResponse,
  CreateDataObjectWorkflowRelationActionsModelReturn,
  CreateDataObjectActivityLogsModelReturn,
  CreateDataObjectActivityLogsModelResponse,
  CreateDataObjectRelatedObjectsModelReturn,
  CreateDataObjectRelatedObjectsModelResponse,
  CreateDataObjectStatusesModelResponse,
  CreateDataObjectStatusesModelReturn
} from "../types/dataObjects.types";

export const createDataObjectTypesModel = (
  response: CreateDataObjectTypesModelResponse
): CreateDataObjectTypesModelReturn => {
  return response.results.map(dataObject => ({
    id: dataObject.id.toString(),
    tag: dataObject.tag,
    schema: dataObject.schema,
    uiSchema: dataObject.ui_schema
  }));
};

export const createDataObjectModel = (
  response: CreateDataObjectModelResponse
): CreateDataObjectModelReturn => {
  return {
    objects: Object.fromEntries(
      Object.entries(response.objects).map(([objectType, dataObject]) => [
        objectType,
        {
          id: dataObject.id,
          name: dataObject.name,
          kindId: dataObject.object_kind_id,
          data: dataObject.data
        }
      ])
    ),
    definitions: response.definitions
  };
};

export const createDataObjectDetailsModel = (
  response: CreateDataObjectDetailsModelResponse
): CreateDataObjectDetailsModelReturn => {
  const {
    object_instances: objectInstances = [],
    object_types: objectTypes = [],
    object_workflow_mappings: objectWorkflowMappings = [],
    badges: objectBadges = [],
    object_statuses: objectStatuses = []
  } = response.results;

  return {
    next: response.next,
    previous: response.previous,
    count: response.count,
    badges: objectBadges.map(badge => ({
      tag: badge.tag,
      name: badge.name,
      helpText: badge.help_text,
      fieldTag: badge.field_tag,
      uiSpecs: badge.ui_specs
    })),
    objectInstances: objectInstances.map(instance => {
      const status = objectStatuses?.find(
        status => status.id === instance.status
      );
      return {
        id: instance.id, // TODO: Remove this once create workflow supports uid for object
        uid: instance.uid,
        name: instance.name,
        logo: instance.logo,
        data: instance.data,
        createdAt: instance.created_at,
        updatedAt: instance.updated_at,
        objectType: instance.object_type,
        workflowMappings: instance.workflow_mappings,
        outgoingRelationships: instance.outgoing_relationships,
        incomingRelationships: instance.incoming_relationships,
        appliedBadges: instance.applied_badges.map(badge => ({
          tag: badge.tag,
          createdAt: badge.created_at
        })),
        status: status
          ? {
              id: status.id,
              tag: status.tag,
              name: status.name,
              colorCode: status.color_code
            }
          : undefined
      };
    }),
    objectWorkflowMappings: objectWorkflowMappings.map(mapping => ({
      id: mapping.id,
      workflow: mapping.workflow,
      objectReference: mapping.object_reference
    })),
    objectTypes: objectTypes.map(objectType => ({
      id: objectType.id,
      tag: objectType.tag,
      schema: objectType.schema,
      createdAt: objectType.created_at,
      updatedAt: objectType.updated_at,
      uiSchema: objectType.ui_schema
    }))
  };
};

export const createDataObjectWorkflowRelationActionsModel = (
  response: CreateDataObjectWorkflowRelationActionsModelResponse
): CreateDataObjectWorkflowRelationActionsModelReturn => {
  const { results = [] } = response;

  return results.map(result => ({
    objectTypeTag: result.object_type_tag,
    relation: result.relation,
    workflowKindTag: result.workflow_kind_tag,
    allowedStatusTags: result.extra?.allowed_status_tags
  }));
};

export const createDataObjectActivityLogsModel = (
  response: CreateDataObjectActivityLogsModelResponse
): CreateDataObjectActivityLogsModelReturn => {
  return response.map(result => ({
    id: result.id,
    objectUid: result.object_uid,
    operationType: result.operation_type,
    actorType: result.actor_type,
    actorDetails: result.actor_details,
    timestamp: result.timestamp,
    oldData: result.old_data,
    newData: result.new_data
  }));
};

export const createDataObjectRelatedObjectsModel = (
  response: CreateDataObjectRelatedObjectsModelResponse
): CreateDataObjectRelatedObjectsModelReturn => {
  return response.map(result => ({
    id: result.id,
    name: result.name,
    status: !!result.status
      ? {
          id: result.status.id,
          tag: result.status.tag,
          name: result.status.name,
          colorCode: result.status.color_code
        }
      : null,
    data: result.data
  }));
};

export const createDataObjectStatusesModel = (
  response: CreateDataObjectStatusesModelResponse
): CreateDataObjectStatusesModelReturn => {
  const { results = [], count, next, previous } = response;

  return {
    count,
    next,
    previous,
    results: results.map(result => ({
      id: result.id,
      tag: result.tag,
      name: result.name,
      colorCode: result.color_code
    }))
  };
};
