//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Bell = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Bell"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3239)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7207 2.75401C14.9774 4.0728 15.4053 6.08938 14.5645 8.20484L14.5646 8.20478C14.5646 8.20478 13.0653 11.4888 13.958 12.8602C14.0002 12.9085 14.4469 13.4571 14.2435 14.0468C14.037 14.6449 13.0737 14.7958 12.2015 14.4198L2.74536 10.3445C1.8726 9.96851 1.37043 9.18626 1.61427 8.60201C1.86712 7.99849 2.6811 7.96416 2.76476 7.96063C2.76678 7.96054 2.76837 7.96047 2.76952 7.96042C4.34967 7.6979 5.78859 4.3939 5.78859 4.3939C6.76034 2.44373 8.61931 1.29693 10.5025 1.33496C10.5048 1.32614 10.507 1.31732 10.5092 1.3085C10.5291 1.22912 10.5491 1.14954 10.5828 1.0724C10.9805 0.167047 12.0374 -0.246399 12.9433 0.151355C13.8493 0.548613 14.2615 1.6042 13.8638 2.50957C13.8303 2.5887 13.7862 2.6551 13.7412 2.72298C13.7344 2.73327 13.7276 2.7436 13.7207 2.75401ZM6.05409 15.8486C5.14692 15.4514 4.73529 14.394 5.13304 13.488L5.13298 13.488C5.23981 13.2453 5.39614 13.0413 5.58083 12.8772L8.56127 14.1839C8.56429 14.4326 8.52084 14.6861 8.414 14.9275C8.0169 15.8341 6.96006 16.2463 6.05409 15.8486Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3239">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
