//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Word = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Word"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect
            x={1.75}
            y={0.75}
            width={12.5}
            height={14.5}
            rx={0.75}
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M6.52652 14.5L5.16742 9.41071L4.5 9.3489V8.5H7.15753V9.3489L6.50225 9.44368L7.14539 12.1264L7.23843 12.5137H7.2627L7.35978 12.1223L8.51258 8.5H9.49146L10.7049 12.2912L10.7899 12.5508H10.8142L10.8829 12.2912L11.5706 9.44368L10.8951 9.3489V8.5H13.5V9.3489L12.873 9.41071L11.4533 14.5H10.1872L9.17191 11.397L9.04652 10.9684H9.02225L8.86449 11.4217L7.83303 14.5H6.52652Z"
            fill="currentColor"
          />
          <rect
            x={3.5}
            y={2.5}
            width={7}
            height={1.5}
            rx={0.75}
            fill="currentColor"
          />
          <rect
            x={3.5}
            y={5}
            width={4}
            height={1.5}
            rx={0.75}
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
