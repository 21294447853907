//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Characters = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Characters"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.05844 11.5L3.78788 10.1248H2.00462L1.73406 11.5H0L1.77096 3.89462H4.00925L5.8294 11.5H4.05844ZM2.25059 8.88117H3.52961L2.8901 5.66442L2.25059 8.88117Z"
            fill="currentColor"
          />
          <path
            d="M9.06875 5.58072H9.37621C10.3355 5.58072 10.8151 6.01918 10.8151 6.89611V10.1726C10.8151 11.0575 10.3355 11.5 9.37621 11.5H9.06875C8.52763 11.5 8.19967 11.2768 8.08489 10.8303V11.5H6.40002V3.5H8.10948V6.16667C8.23247 5.77603 8.55222 5.58072 9.06875 5.58072ZM9.09335 9.94544V7.13528C9.09335 7.02367 9.06875 6.94793 9.01956 6.90807C8.97036 6.86024 8.88428 6.83632 8.76129 6.83632H8.46614C8.22837 6.83632 8.10948 6.96388 8.10948 7.21898V9.86173C8.10948 10.1168 8.22837 10.2444 8.46614 10.2444H8.76129C8.98266 10.2444 9.09335 10.1447 9.09335 9.94544Z"
            fill="currentColor"
          />
          <path
            d="M16 7.70927H14.3274V7.13528C14.3274 6.93597 14.2167 6.83632 13.9954 6.83632H13.6756C13.4543 6.83632 13.3436 6.93597 13.3436 7.13528V9.94544C13.3436 10.1447 13.4543 10.2444 13.6756 10.2444H13.9954C14.2167 10.2444 14.3274 10.1447 14.3274 9.94544V9.37145H16V10.1726C16 11.0575 15.4794 11.5 14.4381 11.5H13.1837C12.1424 11.5 11.6218 11.0575 11.6218 10.1726V6.90807C11.6218 6.02317 12.1424 5.58072 13.1837 5.58072H14.4381C15.4794 5.58072 16 6.02317 16 6.90807V7.70927Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
