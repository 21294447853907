//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Thunder = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Thunder"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3727)">
            <path
              d="M12.6539 6.96275L8.15184 15.8333C8.08514 15.9333 7.9851 16 7.85172 16H7.75167C7.58493 15.9333 7.48489 15.7999 7.51822 15.6332L8.6187 8.99682L4.35014 8.93013C4.21674 8.93013 4.11669 8.86343 4.05001 8.76338C3.98334 8.66334 3.98332 8.52994 4.05001 8.4299L9.05235 0.159558C9.15239 0.0261653 9.31913 -0.040531 9.45252 0.0261653C9.61926 0.0928616 9.68597 0.259613 9.65261 0.426343L8.25199 6.49582H12.3539C12.4539 6.49582 12.5873 6.56251 12.654 6.66256C12.6873 6.72925 12.6872 6.86272 12.6539 6.96275Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3727">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
