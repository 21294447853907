import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Godaam } from "@certa/common/utils/Godaam";
import { useHomePage } from "@certa/common/hooks/useHomePage";
import { useCheckPermission } from "@certa/common/utils/permissions/Chowkidar";
import { PermissionTypes } from "@certa/types/src/permissionTypes";
import { getNextURL } from "main/src/modules/LoginPage/utils/login.utils";
import { AppLoader } from "main/src/modules/router/AppLoader";

export const PrivateRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isHomePageEnabled = useHomePage();
  const canViewDashboard = useCheckPermission(
    PermissionTypes.CAN_VIEW_DASHBOARD
  );

  useEffect(() => {
    if (Godaam.user) {
      navigate(
        getNextURL(location.search, true, isHomePageEnabled, canViewDashboard),
        {
          replace: true
        }
      );
    } else {
      navigate("/login", {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppLoader />;
};
