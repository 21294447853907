//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Tag = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Tag"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.5935 8.17954L7.81921 1.40521C7.59311 1.13424 7.23239 0.998749 6.8708 0.998749H2.35487C1.58682 0.998749 1 1.58557 1 2.35362V6.86955C1 7.23114 1.13549 7.59186 1.40646 7.81796L8.18079 14.5923C8.45177 14.8633 8.81335 14.9987 9.1292 14.9987C9.44505 14.9987 9.80663 14.8633 10.0776 14.5923L14.5935 10.0764C15.1355 9.57928 15.1355 8.67662 14.5935 8.17954ZM4.16107 5.06335C3.66399 5.06335 3.25753 4.65689 3.25753 4.15982C3.25753 3.66274 3.66399 3.25628 4.16107 3.25628C4.65814 3.25628 5.0646 3.66274 5.0646 4.15982C5.0646 4.65689 4.65814 5.06335 4.16107 5.06335Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
