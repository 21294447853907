import {
  useGetWorkflowFamily,
  type WorkflowFamilyResponse
} from "@certa/queries/workflows/workflowFamily";
import { useGlobalSearch } from "@certa/queries/hooks/globalSearch.hook";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { getSearchResult } from "./resultComponents/utils";
import { createRecentSearchGroup, getWorkflowIdsToFetchFamily } from "./utils";
import type { SelectProps } from "@certa/catalyst/components/Select";
import { MIN_CHAR_LENGTH_TO_SEARCH } from "./constants";

// Stale time to queries, so that the results do not get immediately
// refreshed when coming back to new category or keyword. This prevents
// fluctuation in the results as BE is returning different results for
// the same query sometimes
// 10 mins - keeps recent cached, but refreshes if user comes back after a while
// to ensure we get freshly indexed results
const SEARCH_QUERY_STALE_TIME = 10 * 60 * 1000; // 10 mins;

type UseOptionsReturnType = {
  options: SelectProps["options"];
  count: number;
  isLoading: boolean;
};
export function useOptions(
  query: string,
  selectedCategories: string[],
  recentSearches: string[]
): UseOptionsReturnType {
  const globalSearchQuery = useGlobalSearch(query, selectedCategories, {
    enabled:
      query.length > MIN_CHAR_LENGTH_TO_SEARCH && selectedCategories.length > 0,

    staleTime: SEARCH_QUERY_STALE_TIME
  });

  const leafWorkflowIds = getWorkflowIdsToFetchFamily(
    globalSearchQuery.data || []
  );
  const workflowFamily = useGetWorkflowFamily(leafWorkflowIds, {
    enabled: leafWorkflowIds.length > 0
  });

  const isLoading = globalSearchQuery.isLoading || workflowFamily.isLoading;

  const options = buildOptions({
    query,
    isLoading,
    isSearchQuerySuccess: globalSearchQuery.isSuccess,
    isWorkflowFamilySuccess: workflowFamily.isSuccess,
    searchQueryData: globalSearchQuery.data,
    workflowFamilyData: workflowFamily.data,
    recentSearches
  });

  return {
    options,
    count: globalSearchQuery?.data?.length || 0,
    isLoading
  };
}

type BuildOptionsParams = {
  query: string;
  isLoading: boolean;
  isSearchQuerySuccess: boolean;
  isWorkflowFamilySuccess: boolean;
  searchQueryData: ModelledAPIResponse.SearchResult[] | undefined;
  workflowFamilyData: WorkflowFamilyResponse.FamilyMap | undefined;
  recentSearches: string[];
};

const buildOptions = ({
  query,
  isLoading,
  isSearchQuerySuccess,
  isWorkflowFamilySuccess,
  searchQueryData,
  workflowFamilyData,
  recentSearches
}: BuildOptionsParams) => {
  return (() => {
    if (isLoading) {
      return [];
    } else if (
      query &&
      isSearchQuerySuccess &&
      isWorkflowFamilySuccess &&
      searchQueryData &&
      workflowFamilyData
    ) {
      return [
        ...searchQueryData.map((searchResult, index) => {
          const {
            component: Component,
            value,
            type
          } = getSearchResult(searchResult, workflowFamilyData);
          return {
            label: <Component searchQuery={query} />,
            value,
            type,
            index,
            searchResult
          };
        })
      ];
    } else if (query) {
      return [];
    }

    return [createRecentSearchGroup(recentSearches)];
  })();
};
