import React, { useState, useCallback, useRef, useEffect } from "react";

import { Tooltip } from "../Tooltip";

import styles from "./Typography.module.css";

const ellipsisStyles: React.CSSProperties = {
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  minWidth: "0"
};

type EllipsisProps = {
  children: string | number;
};

// Multiline ellipsis is not supported yet in this component
// Because the box-orient: vertical; is non-standard and deprecated.
// So line-clamp does not without the box-orient property.
// If multiline ellipsis is required, will have to use a different approach.
export const Ellipsis = (props: EllipsisProps) => {
  const { children } = props;

  const [isOverflowing, setIsOverflowing] = useState(false);
  const ellipsisContainerRef = useRef<HTMLDivElement | null>(null);

  const checkChildrenOverflow = useCallback(() => {
    if (ellipsisContainerRef.current) {
      if (
        ellipsisContainerRef.current.scrollWidth >
        ellipsisContainerRef.current.clientWidth
      ) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    }
  }, []);

  useEffect(() => {
    checkChildrenOverflow();
  }, [children, checkChildrenOverflow]);

  return (
    <Tooltip
      showArrow={true}
      content={children}
      show={isOverflowing}
      targetClassName={styles.catalystTypographyTooltipTarget}
    >
      <div style={ellipsisStyles} ref={ellipsisContainerRef}>
        {children}
      </div>
    </Tooltip>
  );
};
