//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Exclamation = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Exclamation"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3333)">
            <path
              d="M8 0C12.416 0.00486116 15.9952 3.584 16 8C16 12.4181 12.4181 16 8 16C3.58187 16 0 12.4181 0 8C0 3.58187 3.58187 0 8 0ZM7.98611 10C7.68958 10 7.43264 10.1007 7.21528 10.3028C6.99792 10.5049 6.8889 10.7743 6.8889 11.1111C6.8889 11.4285 6.99793 11.693 7.21528 11.9049C7.43265 12.1167 7.6896 12.2222 7.98611 12.2222C8.28263 12.2222 8.54375 12.1188 8.77084 11.9118C8.99792 11.7049 9.11112 11.4382 9.11112 11.1111C9.11112 10.7646 8.99515 10.493 8.76391 10.2958C8.53265 10.0986 8.27294 10 7.98613 10H7.98611ZM9.11111 4.44444C9.11111 3.11111 6.88888 3.11111 6.88888 4.44444C6.88888 5.77778 7.37223 8.88889 8 8.88889C8.62776 8.88889 9.11111 5.77778 9.11111 4.44444Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3333">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
