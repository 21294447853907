/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from "react";
import { Drawer } from "antd";
import { css } from "emotion";
import { UserMentionList } from "./UserMentionList";
import type { UserMentionType } from "../interfaces";
import { getUserMentions, getUnreadMessageCount } from "../services";
import { userMentionsModelCreator } from "../modelCreator";
import { to } from "await-to-js";
import type { IntlShape } from "react-intl";
import { injectIntl } from "react-intl";
import { isMobile } from "../../common/viewPort";
import InfiniteScroll from "react-infinite-scroller";
import { Loader } from "@certa/blocks";

type UserMentionsPropsInterface = {
  intl: IntlShape;
  shouldShowUserMentions: boolean;
  toggleUserMentions: Function;
  setUserMentionsCount: Function;
};

const PAGE_START = 1;
type UserMentionsStateInterface = {
  isLoading: boolean;
  userMentions: UserMentionType[];
  unreadMessageCount: number | null;
  hasMore: boolean;
};
class UserMentionsComponent extends PureComponent<UserMentionsPropsInterface> {
  state: UserMentionsStateInterface = {
    isLoading: true,
    userMentions: [],
    unreadMessageCount: null,
    hasMore: false
  };
  _Mounted = true;

  componentDidMount() {
    this.getUnreadMessageCount();
    this.getUserMentions(PAGE_START);
  }

  componentWillUnmount() {
    this._Mounted = false;
  }

  getUserMentions = async (page: number, isSilent: boolean = false) => {
    !isSilent && this.setState({ isLoading: true });
    const [error, response] = await to(getUserMentions(page));
    if (this._Mounted) {
      if (error) {
        !isSilent && this.setState({ isLoading: false });
      } else {
        this.setState({
          isLoading: false,
          userMentions: this.state.userMentions.concat(
            userMentionsModelCreator(response.results)
          ),
          hasMore: !!response.next
        });
      }
    }
  };

  getUnreadMessageCount = async () => {
    const [, response] = await to(getUnreadMessageCount());
    if (this._Mounted && response) {
      this.setState({
        unreadMessageCount: response.count
      });
      this.props.setUserMentionsCount(response.count);
    }
  };

  // On every mark as read, re-fetching to
  // get the latest unread count
  handleMarkAsReadSuccess = (id: number) => {
    // Updating it optimistically!
    // Since this functions is called after
    // successfully marking the message as read
    // there will be no false data representation on the UI
    // Purpose: Display feedback on the UI ASAP
    // CAUTION: Since we cannot refetch the this.getUserMentions
    // because of the pagination, optimistic update becomes more imp
    // and the only source of truth,
    // once the user updates the is_read status of any mesage
    this.setState({
      userMentions: this.state.userMentions.map(mention => {
        if (mention.id === id) {
          return {
            ...mention,
            isRead: true
          };
        } else {
          return mention;
        }
      })
    });
    this.getUnreadMessageCount();
  };

  render() {
    const { isLoading, userMentions, hasMore } = this.state;
    const { intl, shouldShowUserMentions, toggleUserMentions } = this.props;
    return (
      <Drawer
        placement="right"
        visible={shouldShowUserMentions}
        width={isMobile() ? "100vw" : 570}
        zIndex={1069}
        drawerStyle={{
          marginTop: "60px",
          height: "calc(100vh - 70px)",
          display: "flex",
          flexDirection: "column"
        }}
        title={intl.formatMessage({
          id: "dashboard.userMentions.headerTitle",
          defaultMessage: "My Mentions"
        })}
        className={css`
          .ant-drawer-content-wrapper {
            box-shadow: none !important;
          }
          .ant-drawer-title {
            font-size: 20px !important;
            font-weight: 600 !important;
            letter-spacing: -0.04px;
          }
          .ant-drawer-content {
            box-shadow: none;
          }
        `}
        headerStyle={{
          backgroundColor: "#F2F2F2",
          borderBottom: "1px solid rgba(151,151,151,0.2)"
        }}
        maskStyle={{
          marginTop: "60px"
        }}
        closable={false}
        maskClosable={true}
        onClose={() => toggleUserMentions()}
        bodyStyle={{
          padding: 0,
          height: "100%"
        }}
      >
        <InfiniteScroll
          pageStart={PAGE_START}
          loadMore={page => this.getUserMentions(page, true)}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <div
              style={{ textAlign: "center", marginTop: -50, paddingBottom: 50 }}
            >
              <Loader />
            </div>
          }
          threshold={250}
        >
          <UserMentionList
            isLoading={isLoading}
            userMentions={userMentions}
            onMarkAsRead={this.handleMarkAsReadSuccess}
          />
        </InfiniteScroll>
      </Drawer>
    );
  }
}

export const UserMentions = injectIntl(UserMentionsComponent);
