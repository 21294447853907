import React, { useContext } from "react";
import type { Workflow, Badge } from "@certa/types";
import type { TaskDetails } from "@certa/queries/types/workflow.types";
import { useGetDataObjectsDynamicAPI } from "@certa/queries/hooks/dataObjects.hooks";

import { useEnableDataObjects } from "@certa/common/hooks/useEnableDataObjects";
import type { QueryObserverResult } from "react-query";
import type { CreateDataObjectDetailsModelReturn } from "@certa/queries/types/dataObjects.types";

/**
 * Workflow contexts holds the current workflow that's loaded
 * and the refetch callback reference that will update the
 * workflow with latest data.
 */
type WorkflowContextProps = {
  workflow: Workflow | TaskDetails | null;
  fetchWorkflow: () => Promise<
    QueryObserverResult<Workflow | TaskDetails, unknown>
  >;
  readOnly?: boolean;
  workflowDataObjects?: {
    objectInstances: CreateDataObjectDetailsModelReturn["objectInstances"];
    objectTypes: CreateDataObjectDetailsModelReturn["objectTypes"];
  };
  refetchWorkflowDataObjects?: () => Promise<any | undefined>;
  badges?: Badge[];
};

/**
 * @deprecated use WorkflowContextProvider instead
 */
export const WorkflowContext = React.createContext<WorkflowContextProps>({
  workflow: null,
  fetchWorkflow: () =>
    Promise.resolve({} as QueryObserverResult<Workflow | TaskDetails, unknown>),
  readOnly: false,
  badges: [],
  workflowDataObjects: {
    objectInstances: [],
    objectTypes: []
  },
  refetchWorkflowDataObjects: () => Promise.resolve(undefined)
});

type WorkflowContextProviderProps = Omit<
  WorkflowContextProps,
  "refetchWorkflowDataObjects" | "workflowDataObjects"
> & {
  children: React.ReactNode;
};

export const WorkflowContextProvider = (
  props: WorkflowContextProviderProps
) => {
  const {
    workflow,
    fetchWorkflow,
    readOnly: isReadOnly = false,
    children,
    badges
  } = props;

  const workflowId = workflow?.id || "";

  const isDataObjectsEnabled = useEnableDataObjects();

  const { data, refetch } = useGetDataObjectsDynamicAPI(
    {
      filters: {
        workflowMappingWorkflowId: [workflowId.toString()]
      }
    },
    0,
    {
      enabled: isDataObjectsEnabled && !!workflowId
    }
  );

  // objectWorkflowMappings is not needed here.
  // Reason: objectInstances contains the objects that are linked to the workflow.
  const { objectInstances = [], objectTypes = [] } = data ?? {};

  const workflowDataObjects = {
    objectInstances,
    objectTypes
  };

  const refetchWorkflowDataObjects = async () => {
    const result = await refetch();
    return result.data;
  };

  return (
    <WorkflowContext.Provider
      value={{
        workflow,
        fetchWorkflow,
        readOnly: isReadOnly,
        workflowDataObjects,
        refetchWorkflowDataObjects,
        badges: badges
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflowContext = () => {
  const contextValue = useContext(WorkflowContext);

  if (typeof contextValue === "undefined")
    throw Error(
      "useWorkflowContext is being used outside of WorkflowContext's range"
    );

  return contextValue;
};
