import {
  useQuery,
  useInfiniteQuery,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import {
  getCompletedProcessCount,
  getDelayedTaskLaneCount,
  getInProgressTaskLaneCount,
  getMyTaskTaskLaneCount,
  getMyGroupsByTaskLane,
  getAllTasksByTaskLane,
  getMyUserGroupProcessList,
  getMyGroupsByStatus,
  getAllTasksByStatus,
  getNonABProcessType,
  getMyTaskByTaskLane,
  getMyTaskByStatus,
  getMyTaskProcessList,
  getAllTaskProcessList,
  getMyGroupTaskProcessCount,
  getMyTaskProcessCount,
  getMyTasksProcesses,
  getTaskCount,
  getTaskDependencyData,
  getDistinctAttributes
} from "../services/tasks.services";
import type {
  TaskHeaderListParams,
  MyTaskProcessListParams,
  AllTaskProcessListParams
} from "../types/tasks.types";
import type { ProcessFilterSet } from "@certa/types";
import { fetchStatusesListConditionally, queryClient } from "../utils/utils";
import type {
  UseInfiniteQueryExtraConfigType,
  UseQueryExtraConfigType,
  UseQueryReturnType
} from "@certa/common/types";

// TODO: Remove this in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetMyTaskTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "mytask-stepgroups",
    queryFn: getMyTaskTaskLaneCount
  });

// TODO: Remove this in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetMyTaskProcessCount = (
  processTypeId: number | null,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskProcessCount>>
) =>
  useQuery({
    enabled: !!processTypeId,
    ...config,
    queryKey: ["my-task-process-count", processTypeId] as QueryKey,
    queryFn: () => getMyTaskProcessCount(processTypeId as number)
  });

// TODO: Remove this in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetMyGroupTaskProcessCount = (
  processTypeId: number | null,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof getMyGroupTaskProcessCount>
  >
) =>
  useQuery({
    enabled: !!processTypeId,
    ...config,
    queryKey: ["my-group-task-process-count", processTypeId] as QueryKey,
    queryFn: () => getMyGroupTaskProcessCount(processTypeId as number)
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetDelayedTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "delayed-stepgroups",
    queryFn: getDelayedTaskLaneCount
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetInProgressTaskLaneCount = () =>
  useQuery({
    retry: 0,
    queryKey: "inprogress-stepgroups",
    queryFn: getInProgressTaskLaneCount
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetCompletedProcessCount = () =>
  useQuery({
    retry: 0,
    queryKey: "completed-tasks",
    queryFn: getCompletedProcessCount
  });

// TODO: Move this to `packages/queries/queries` folder when refactoring to new queries package structure
export const getNonABProcessTypeQuery = (
  ...params: Parameters<typeof getNonABProcessType>
) => ({
  queryKey: ["non-ab-kinds", ...params],
  queryFn: () => getNonABProcessType(...params),
  refetchOnWindowFocus: false
});

export const useGetNonABProcessType = (
  ...params: Parameters<typeof getNonABProcessType>
) => useQuery(getNonABProcessTypeQuery(...params));

// My Groups header(status + taskLane) and list hooks
export const useGetMyGroupsByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyGroupsByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["mygroups-by-tasklane", params] as QueryKey,
    queryFn: () => getMyGroupsByTaskLane(params)
  });

// TODO: Remove this in future
export const useGetMyGroupsByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyGroupsByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["mygroups-by-status", params] as QueryKey,
    queryFn: () => getMyGroupsByStatus(params)
  });

// TODO: Move this to `packages/queries/queries` folder when refactoring to new queries package structure
export const getMyUserGroupProcessListQuery = (
  offset: number,
  params?: ProcessFilterSet
) => ({
  queryKey: ["my-group-tasks-process-table-list-section", params, offset],
  queryFn: () => {
    fetchStatusesListConditionally();
    return getMyUserGroupProcessList(params || {}, offset);
  }
});

export const useGetMyUserGroupProcessList = (
  offset: number,
  params?: ProcessFilterSet,
  config?: UseQueryExtraConfigType
) =>
  useQuery({
    ...getMyUserGroupProcessListQuery(offset, params),
    ...config
  });

// All Tasks header(status + taskLane) and list hooks
export const useGetAllTasksByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllTasksByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    refetchOnWindowFocus: false,
    queryKey: ["alltasks-by-tasklane", params] as QueryKey,
    queryFn: () => getAllTasksByTaskLane(params)
  });

export const useGetAllTasksByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllTasksByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["alltasks-by-status", params] as QueryKey,
    queryFn: () => getAllTasksByStatus(params)
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetAllTaskProcessList = (
  params: AllTaskProcessListParams,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["all-task-process-list", params],
    ({ pageParam = 0 }) => getAllTaskProcessList(params, pageParam),
    {
      getNextPageParam: ({ next }: { next: string | null }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const offset = urlParams.get("offset");
          return offset;
        }
        return false;
      },
      ...config
    }
  );

// All Tasks header(status + taskLane) and list hooks
// TODO: Remove this in future
export const useGetMyTaskByTaskLane = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskByTaskLane>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["myTasks-by-taskLane", params] as QueryKey,
    queryFn: () => getMyTaskByTaskLane(params)
  });

// TODO: Remove this in future
export const useGetMyTaskByStatus = (
  params: TaskHeaderListParams,
  config?: UseQueryOptions<UseQueryReturnType<typeof getMyTaskByStatus>>
) =>
  useQuery({
    enabled: !!params.kind_id,
    ...config,
    queryKey: ["myTasks-by-status", params] as QueryKey,
    queryFn: () => getMyTaskByStatus(params)
  });

// TODO: Move this to `packages/queries/queries` folder when refactoring to new queries package structure
export const getMyTasksProcessesQuery = (
  offset: number,
  params?: ProcessFilterSet,
  orderings?: string
) => ({
  queryKey: ["myTasks-process-section", params, offset, orderings],
  queryFn: () => {
    fetchStatusesListConditionally();
    return getMyTasksProcesses(params || {}, offset, orderings);
  },
  refetchOnWindowFocus: false
});

// This is the latest my task hook to get my task processes across all and specific kind
export const useGetMyTasksProcesses = (
  offset: number,
  params?: ProcessFilterSet,
  orderings?: string,
  config?: UseQueryExtraConfigType
) =>
  useQuery({
    ...getMyTasksProcessesQuery(offset, params, orderings),
    ...config
  });

//
export const useGetDistinctAttributes = (
  offset: number,
  params: ProcessFilterSet,
  attribute: "kind_id" | "status_id",
  config?: UseQueryOptions<UseQueryReturnType<typeof getDistinctAttributes>>
) =>
  useQuery({
    refetchOnWindowFocus: false,
    ...config,
    queryKey: ["distinct-attributes", params] as QueryKey,
    queryFn: () => getDistinctAttributes(params, offset, attribute)
  });

// TODO: Remove this in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetMyTaskProcessList = (
  params: MyTaskProcessListParams,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["myTask-process-list-column", params],
    async ({ pageParam = 0 }) => {
      await fetchStatusesListConditionally();
      return getMyTaskProcessList(params, pageParam);
    },
    {
      getNextPageParam: ({ next }: { next: string | null }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const offset = urlParams.get("offset");
          return offset;
        }
        return false;
      },
      ...config
    }
  );

export const inValidateStepFieldsData = (taskId: number) =>
  queryClient.invalidateQueries(["currentStepFields", taskId]);

export const useGetTaskCount = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getTaskCount>>
) =>
  useQuery({
    queryKey: "taskCount" as QueryKey,
    queryFn: getTaskCount,
    refetchOnWindowFocus: false,
    ...config
  });

export const useGetTaskDependencies = (
  processId: number,
  config?: UseQueryOptions<UseQueryReturnType<typeof getTaskDependencyData>>
) =>
  useQuery({
    ...config,
    queryKey: [processId, "taskDependencies"] as QueryKey,
    queryFn: () => getTaskDependencyData(processId)
  });
