import { APIFetch, RequestHeaders } from "@certa/network";

import {
  createDataObjectDetailsModel,
  createDataObjectModel,
  createDataObjectTypesModel,
  createDataObjectWorkflowRelationActionsModel,
  createDataObjectActivityLogsModel,
  createDataObjectRelatedObjectsModel,
  createDataObjectStatusesModel
} from "../models/dataObject.model";

import type {
  CreateDataObjectModelResponse,
  CreateDataObjectDetailsModelResponse,
  GetDataObjectDetailsRequestParams,
  CreateDataObjectTypesModelResponse,
  CreateDataObjectWorkflowRelationActionsModelResponse,
  DataObjectWorkflowRelation,
  CreateDataObjectActivityLogsModelResponse,
  CreateDataObjectRelatedObjectsModelResponse,
  CreateDataObjectStatusesModelResponse
} from "../types/dataObjects.types";

export const getDataObjectTypes = () => {
  const url = `objects/object-types/`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<CreateDataObjectTypesModelResponse>(url, requestOptions).then(
    response => createDataObjectTypesModel(response)
  );
};

/**
 * Fetches the standard data objects for a workflow.
 * @param workflowId
 * @returns
 */
export const getDataObjectsForWorkflow = (workflowId: number) => {
  const url = `objects/${workflowId}/`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<CreateDataObjectModelResponse>(url, requestOptions).then(
    response => createDataObjectModel(response)
  );
};

/**
 * Fetches the data objects dynamically based on filters provided.
 * @param params
 * @returns
 */
export const getDataObjectDetails = (
  params: GetDataObjectDetailsRequestParams,
  offset: number = 0
) => {
  const url = `objects/object-instances-list-drest/?limit=20&offset=${offset}`;

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(params)
  };

  return APIFetch<CreateDataObjectDetailsModelResponse>(
    url,
    requestOptions
  ).then(createDataObjectDetailsModel);
};

type GetDataObjectWorkflowRelationActionsParams = {
  objectTypeTag?: string;
  workflowKindTag?: string;
  relation?: DataObjectWorkflowRelation;
};

export const getDataObjectWorkflowRelationActions = (
  params: GetDataObjectWorkflowRelationActionsParams
) => {
  const {
    objectTypeTag,
    workflowKindTag,
    relation = "initializer_object"
  } = params;

  const queryParams = new URLSearchParams();

  if (relation) queryParams.append("relation__in", relation);
  if (objectTypeTag) queryParams.append("object_type_tag", objectTypeTag);
  if (workflowKindTag) queryParams.append("workflow_kind_tag", workflowKindTag);

  const url = `objects/object-type-workflow-kind-relations/${queryParams.toString() ? "?" + queryParams.toString() : ""}`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  // We are not using pagination here because we are fetching all the
  // workflow kind relations for a given object type.
  // The workflow kinds will not be more than 20 in most cases and this is configurable.
  // TODO: Will have to revisit this if we ever have more than 20 workflow kinds.
  return APIFetch<CreateDataObjectWorkflowRelationActionsModelResponse>(
    url,
    requestOptions
  ).then(createDataObjectWorkflowRelationActionsModel);
};

export const getDataObjectActivityLogs = (objectId: string) => {
  const url = `objects/activity-logs/?object_uid=${objectId}`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<CreateDataObjectActivityLogsModelResponse>(
    url,
    requestOptions
  ).then(createDataObjectActivityLogsModel);
};

type GetRelatedObjectsParams = {
  objectReference: string;
  workflowId: number;
  relationPath?: string;
};

export const getRelatedObjects = (params: GetRelatedObjectsParams) => {
  const { objectReference, workflowId, relationPath } = params;

  const url = new URLSearchParams();
  url.set("object_reference", objectReference);
  url.set("workflow_id", workflowId.toString());
  if (relationPath) {
    url.set("relation_path", relationPath);
  }

  const endpoint = `objects/related-objects/?${url.toString()}`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<CreateDataObjectRelatedObjectsModelResponse>(
    endpoint,
    requestOptions
  ).then(createDataObjectRelatedObjectsModel);
};

export const getObjectsStatuses = () => {
  const url = `objects/statuses/`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<CreateDataObjectStatusesModelResponse>(
    url,
    requestOptions
  ).then(createDataObjectStatusesModel);
};
