import {
  Tooltip,
  TooltipPlacements,
  TooltipVariants
} from "@certa/catalyst/components/Tooltip";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { Star } from "@certa/icons/components/Star";

const MAX_LENGTH = 4;

export const RatingPreview = ({ value }: { value: string }) => {
  const shouldTruncate = value.length > MAX_LENGTH;
  const truncatedValue = shouldTruncate
    ? value.slice(0, MAX_LENGTH) + "..."
    : value;
  const content = (
    <Stack direction="horizontal" gap="4">
      <Typography
        variant={TypographyVariants.LABEL_SM}
        color={TypographyColors.NEUTRAL_600}
      >
        {value}
      </Typography>
      <Star color="orange" size={16} />
    </Stack>
  );

  return (
    <Tooltip
      content={content}
      variant={TooltipVariants.LIGHT}
      placement={TooltipPlacements.LEFT}
    >
      <Stack direction="horizontal" gap="4">
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_600}
        >
          {truncatedValue}
        </Typography>
        <Star color="orange" size={16} />
      </Stack>
    </Tooltip>
  );
};
