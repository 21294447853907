import { useAppSelector } from "../hooks";

type CycleTimeCalculationAlgorithm = "basic" | "excluding_weekend_and_holidays";
// This hook controls whether to show ageing column, axis, and filter in reports
// based on configuration settings.
// CYCLE_TIME_CALCULATION_ALGORITHM_NOTE: This is temporary toggle, will be removed after BE supports ageing for cycle time calculation algorithm
export const useIsExcludingWeekendAndHolidays = () => {
  const algorithm: CycleTimeCalculationAlgorithm =
    useAppSelector(
      state =>
        state?.config?.feature_toggles?.["CYCLE_TIME_CALCULATION_ALGORITHM"]
    ) ?? "basic";

  return algorithm === "excluding_weekend_and_holidays";
};
