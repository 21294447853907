import type {
  Metric,
  ShowAxisLabel,
  ValueLabelAngle,
  ShowLegend,
  ActiveColorType,
  GroupByDateOperation,
  ChartConfigOperationFunction,
  ShowValue,
  GeoMapTheme,
  ChartIdentifiers,
  ChartOrientation
} from "@certa/common/types";

export const chartConfigFormNames = {
  X_AXIS: "x-axis",
  Y_AXIS: "y-axis",
  X_AXIS_LABEL: "xAxisLabel",
  Y_AXIS_LABEL: "yAxisLabel",
  SHOW_X_AXIS_LABEL: "showXAxisLabel",
  SHOW_Y_AXIS_LABEL: "showYAxisLabel",
  X_AXIS_OPERATION: "xAxisOperation",
  X_AXIS_FIELD_NAME: "fieldName",
  Y_AXIS_FIELD_NAME: "fieldName",
  Y_AXIS_METRIC: "metric",
  SECOND_X_AXIS: "secondXAxis",
  SCALE: "scale",
  PRECISION: "precision",
  SHOW_LEGEND: "showLegend",
  CHART_ORIENTATION: "chartOrientation",
  SHOW_VALUE_LABELS: "shouldShowValueLabels",
  SHOW_VALUE_ON: "showValueOn",
  SHOW_PERCENTAGE_IN_VALUE_LABELS: "shouldShowPercentagesInValueLabels",
  GEO_MAP_THEME: "geoMapTheme",
  PERCENTAGE_THRESHOLD: "percentageThreshold",
  IS_GROUPED_BAR_CHART: "isGroupedBarChart",
  VALUE_LABEL_ANGLE: "valueLabelAngle",
  COLORS: "colors",
  ACTIVE_COLOR_TYPE: "activeColorType",
  SHOULD_DISPLAY_VALUE_AS_PERCENTAGE: "shouldDisplayValueAsPercentage",
  PERCENTAGE_CALCULATION_TYPE: "percentageCalculationType"
} as const;

export type XAxisMultiValue = {
  [chartConfigFormNames.X_AXIS_FIELD_NAME]: string[];
  [chartConfigFormNames.X_AXIS_OPERATION]: GroupByDateOperation;
};

type YAxisValue = {
  [chartConfigFormNames.Y_AXIS_FIELD_NAME]: string[];
  [chartConfigFormNames.Y_AXIS_METRIC]: Metric | "";
};

type ColorsFormValue = {
  [chartConfigFormNames.ACTIVE_COLOR_TYPE]: ActiveColorType;
};

export type ChartConfigurationsFormValues = {
  [chartConfigFormNames.X_AXIS]: XAxisMultiValue[];
  [chartConfigFormNames.Y_AXIS]: YAxisValue[];
  [chartConfigFormNames.SECOND_X_AXIS]: XAxisMultiValue[];
  [chartConfigFormNames.X_AXIS_LABEL]: string;
  [chartConfigFormNames.Y_AXIS_LABEL]: string;
  [chartConfigFormNames.SHOW_X_AXIS_LABEL]: ShowAxisLabel;
  [chartConfigFormNames.SHOW_Y_AXIS_LABEL]: ShowAxisLabel;
  [chartConfigFormNames.SHOW_LEGEND]: ShowLegend;
  [chartConfigFormNames.CHART_ORIENTATION]: ChartOrientation;
  [chartConfigFormNames.SHOW_VALUE_LABELS]: boolean;
  [chartConfigFormNames.SHOW_VALUE_ON]: ShowValue;
  [chartConfigFormNames.SHOW_PERCENTAGE_IN_VALUE_LABELS]: boolean;
  [chartConfigFormNames.PERCENTAGE_THRESHOLD]: number | undefined;
  [chartConfigFormNames.VALUE_LABEL_ANGLE]: ValueLabelAngle;
  [chartConfigFormNames.COLORS]: ColorsFormValue;
  [chartConfigFormNames.SCALE]?: number;
  [chartConfigFormNames.PRECISION]?: number;
  [chartConfigFormNames.GEO_MAP_THEME]?: GeoMapTheme;
  [chartConfigFormNames.IS_GROUPED_BAR_CHART]?: boolean;
  [chartConfigFormNames.SHOULD_DISPLAY_VALUE_AS_PERCENTAGE]?: boolean;
  [chartConfigFormNames.PERCENTAGE_CALCULATION_TYPE]?:
    | "row"
    | "column"
    | "table";
};

export const chartConfigWarnings = {
  chooseXAxis: {
    intlId: "report.chooseTheXAxisFirst",
    defaultMessage: "Choose the x-axis first"
  },
  chooseYAxis: {
    intlId: "report.chooseTheYAxisFirst",
    defaultMessage: "Choose the y-axis first"
  },
  chooseMeasure: {
    intlId: "report.chooseTheMeasureFirst",
    defaultMessage: "Choose the measure first"
  }
} as const;

export type YAxisValueMetricORM = ChartConfigOperationFunction | "" | "all";
export type YAxisValueORM = {
  [chartConfigFormNames.Y_AXIS_FIELD_NAME]: string[];
  [chartConfigFormNames.Y_AXIS_METRIC]: YAxisValueMetricORM;
};

export type ChartConfigurationsFormValuesORM = Omit<
  ChartConfigurationsFormValues,
  "y-axis" | "secondXAxis"
> & {
  [chartConfigFormNames.Y_AXIS]: YAxisValueORM[];
  [chartConfigFormNames.SECOND_X_AXIS]: XAxisMultiValue[];
};

export const showCommonChartConfig: ChartIdentifiers[] = [
  "LINE_CHART",
  "BAR_CHART",
  "PIE_CHART",
  "SCATTER_CHART",
  "SPIDER_CHART"
];
