/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import {
  type DropdownStatusDefault,
  type CommentChannel,
  type AdjudicationStatus,
  type ConversationThread,
  CommentObjectTypes
} from "@certa/types";
import { useCommentsContext } from "../../comments.context";
import { NoComments } from "./NoComments";
import { useIntl } from "react-intl";
import { SingleChannelView } from "./SingleChannelView/SingleChannelView";
import { MultiChannelViewAppRedesign } from "./MultiChannelViewAppRedesign";
import type { ConversationCommsModel } from "@certa/queries/queries/comms";
import { useCommsNewEndpoints } from "@certa/common/toggles";

const ChannelViewIdentifier: FC<{
  channels: CommentChannel[];
  threadsComms: ConversationCommsModel;
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
  multiChannelWithTabs?: boolean;
}> = ({
  channels,
  threadsComms,
  defaultStatus,
  defaultAdjudicationCode,
  adjudicationRiskCodes,
  multiChannelWithTabs
}) => {
  const intl = useIntl();

  const [threadWithLatestMessage, setLatestMessageThread] =
    useState<null | Number>(null);
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  const {
    isMultiChannel,
    defaultThreadId,
    activeChannel,
    activeConversationThreadComms,
    setActiveChannel,
    setActiveConversationThreadComms
  } = useCommentsContext();

  const totalMessagesInAllChannels = useMemo(
    () =>
      channels.reduce(
        (total: number, channelContext: CommentChannel) =>
          total +
          channelContext.allThreads.reduce(
            (total, threadMeta) => total + threadMeta.messageCount,
            0
          ),
        0
      ),
    [channels]
  );

  const defaultActiveThread = useMemo(() => {
    const channel = activeChannel || channels[0];
    if (defaultThreadId) {
      const threadId = defaultThreadId;
      if (channel?.allThreads.find(thread => thread.threadId === threadId)) {
        return threadId;
      }
    } else {
      return threadWithLatestMessage || channel?.allThreads[0]?.threadId;
    }
  }, [activeChannel, channels, defaultThreadId, threadWithLatestMessage]);

  useEffect(() => {
    if (
      !isCommsNewEndpointEnabled ||
      !activeConversationThreadComms ||
      activeConversationThreadComms?.conversationId ||
      !threadsComms ||
      !defaultActiveThread
    )
      return;

    const thread = threadsComms.results.find(
      thread => thread.kryptonId === defaultActiveThread
    );

    if (!thread) return;

    setActiveConversationThreadComms({
      ...activeConversationThreadComms,
      conversationId: thread.id
    });
  }, [
    isCommsNewEndpointEnabled,
    activeConversationThreadComms,
    threadsComms,
    defaultActiveThread,
    setActiveConversationThreadComms
  ]);

  const handleChannelSelect = (
    channel: CommentChannel,
    threadWithLatestMessage: number
  ) => {
    setActiveChannel(channel);
    setLatestMessageThread(threadWithLatestMessage);
  };

  const handleChannelSelectComms = (conversationThread: ConversationThread) => {
    setActiveConversationThreadComms(conversationThread);

    // Find the corresponding thread ID for this conversation and update threadWithLatestMessage
    if (threadsComms && conversationThread.conversationId) {
      const thread = threadsComms.results.find(
        thread => thread.id === conversationThread.conversationId
      );

      if (thread && thread.kryptonId) {
        setLatestMessageThread(thread.kryptonId);

        // Find and set the active channel based on the thread's kryptonId
        const channelWithThread = channels.find(channel =>
          channel.allThreads.some(
            threadMeta => threadMeta.threadId === thread.kryptonId
          )
        );

        if (channelWithThread) {
          setActiveChannel(channelWithThread);
        }
      }
    }
  };

  const noCommentsMessage = intl.formatMessage({
    id: "comments.noCommentsYetMessage",
    defaultMessage: "No comments yet"
  });

  const noCommentsGenericSubHeading = intl.formatMessage({
    id: "comments.noCommentsInWorkflow",
    defaultMessage: "There are no comments in this workflow"
  });

  /**
   * If there are no channels
   * - OR
   * If the view is multi-channel and total messages in all channels=>threads is 0
   * - Then
   * Render the no comments UI
   */
  if (!channels.length || (isMultiChannel && totalMessagesInAllChannels === 0))
    return (
      <NoComments
        heading={noCommentsMessage}
        subHeading={noCommentsGenericSubHeading}
        isMultiChannel
      />
    );

  if (!isCommsNewEndpointEnabled && isMultiChannel && !activeChannel) {
    return (
      <MultiChannelViewAppRedesign
        channels={channels}
        threadsComms={threadsComms}
        handleChannelSelect={handleChannelSelect}
        handleChannelSelectComms={handleChannelSelectComms}
        multiChannelWithTabs={multiChannelWithTabs}
      />
    );
  }

  if (
    isCommsNewEndpointEnabled &&
    isMultiChannel &&
    (!activeConversationThreadComms ||
      activeConversationThreadComms.objectType ===
        CommentObjectTypes.CONSOLIDATED)
  ) {
    return (
      <MultiChannelViewAppRedesign
        channels={channels}
        threadsComms={threadsComms}
        handleChannelSelect={handleChannelSelect}
        handleChannelSelectComms={handleChannelSelectComms}
        multiChannelWithTabs={multiChannelWithTabs}
      />
    );
  }

  return (
    <SingleChannelView
      channel={activeChannel || channels[0]}
      activeConversationThreadComms={activeConversationThreadComms}
      defaultActiveThread={defaultActiveThread as number}
      defaultStatus={defaultStatus}
      defaultAdjudicationCode={defaultAdjudicationCode}
      adjudicationRiskCodes={adjudicationRiskCodes}
      threadsComms={threadsComms}
    />
  );
};

export { ChannelViewIdentifier };
