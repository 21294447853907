//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ZoomToSelection = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ZoomToSelection"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_4012)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 4.5H5C4.72386 4.5 4.5 4.72386 4.5 5V11C4.5 11.2761 4.72386 11.5 5 11.5H11C11.2761 11.5 11.5 11.2761 11.5 11V5C11.5 4.72386 11.2761 4.5 11 4.5ZM5 3C3.89543 3 3 3.89543 3 5V11C3 12.1046 3.89543 13 5 13H11C12.1046 13 13 12.1046 13 11V5C13 3.89543 12.1046 3 11 3H5Z"
              fill="currentColor"
            />
            <path
              opacity={0.5}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.5 1.5H1.5L1.5 5.5H0L0 1.5L0 1L0 0L5.5 0V1.5ZM5.5 14.5H1.5L1.5 10.5H0L0 14.5L0 15L0 16H5.5V14.5ZM14.5 14.5H10.5V16H16V15V14.5V10.5H14.5V14.5ZM10.5 1.5H14.5V5.5H16V1.5V1V0L10.5 0V1.5Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_4012">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
