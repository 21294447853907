import { css } from "emotion";

export const filterTriggerStyles = {
  trigger: (maxWidth: string | undefined, hasSelectedValues: boolean) =>
    css({
      position: "relative",
      display: "flex",
      gap: "0.25rem",
      alignItems: "center",
      maxWidth,
      width: "fit-content",
      padding: "0.5rem 0.75rem",
      backgroundColor: hasSelectedValues
        ? "var(--colors-certa-brand-100)"
        : "var(--colors-neutral-300)",
      color: hasSelectedValues
        ? "var(--colors-certa-brand-500)"
        : "var(--colors-neutral-700)",
      border: "none",
      borderRadius: "0.25rem",
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "1rem",
      height: "2rem",
      letterSpacing: "0.016rem",
      cursor: "pointer",
      "&:hover:not(:disabled)": {
        borderColor: "var(--colors-certa-brand-400)"
      },
      "&:focus": {
        outline: "none"
      },
      "&:focus-visible": {
        outline: "0.125rem solid var(--colors-brand-400)"
      },
      "&:disabled": {
        backgroundColor: "var(--colors-neutral-400)",
        cursor: "not-allowed"
      }
    }),
  countText: css({
    flexShrink: 0
  }),
  caret: css({
    flexShrink: 0,
    'button[aria-expanded="true"] &': {
      transform: "rotate(180deg)"
    }
  })
};
