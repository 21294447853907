// App

import type { StudioTemplateTypes, HashMap } from "@certa/types";
import type { Operation } from "fast-json-patch";
import type { AppSettingsRaw } from "./appSettings.types";
import type { ObjectFieldType, StudioObject } from "@certa/studio-core";

export type CreateStudioAppParams = {
  name: string;
  description?: string;
  workflow_id: number;
};

export type UpdateStudioAppParams = {
  id: number;
  name?: string;
  description?: string;
};

export type DeleteStudioAppParams = {
  id: number;
};

export type StudioAppResponse = {
  id: number;
  name: string;
  description?: string;
  workflow_id: number;
};

export type StudioApp = {
  id: number;
  name: string;
  description?: string;
  workflowId: number;
};

// Process

export type CreateStudioProcessParams = {
  name: string;
  // DS-5906: `description` is not in use anymore.
  // BE APIs still it but FE doesn't use it anymore.
  description?: string;
  workflow_kind_tag: string;
  package_id: number;
};

export type UpdateStudioProcessParams = {
  processId: number;
  name?: string;
  description?: string;
};

export type UpdateStudioProcessResponse = {
  id: number;
  name: string;
  description: string;
};

export type StudioProcessResponse = {
  id: number;
  name: string;
  description?: string;
  workflow_kind_tag: string;
  created_by?: UserResponse;
};

export type StudioProcess = {
  id: number;
  name: string;
  description?: string;
  workflowKindTag: string;
  createdBy?: User;
};

// Branch/Savepoint
export type StudioBranchResponse = {
  id: number;
  name: string;
  created_at: string;
};

export type StudioBranch = {
  id: number;
  name: string;
  createdAt: string;
};

export type StudioBranchSavePointResponse = {
  id: number;
  title: string;
  description?: string;
  stage: SavepointStage | null;
  created_at: string;
  updated_at: string;
  stage_set_by: UserResponse;
  deployment_attempted_env: keyof typeof DeployENVToVerbiageMap | null;
  deployment_attempted_at: number | null;
};

export type StudioBranchSavePoint = {
  id: number;
  title: string;
  description?: string;
  stage: SavepointStage | null;
  createdAt: Date;
  updatedAt: Date;
  stageSetBy: User | null;
  deploymentAttemptedAt: number | null;
  deploymentAttemptedEnv: keyof typeof DeployENVToVerbiageMap | null;
};

export type StudioBranchSavepointPayload = {
  savepointId: number;
  title: string;
  description?: string;
};

export type UpdateStudioBranchSavepointResponse = {
  id: number;
  title: string;
  description?: string;
};

export type StudioProcessBySavePointResponse = {
  id: number; // Process Savepoint
  process: StudioProcessResponse;
};

export type StudioProcessBySavePoint = {
  id: number;
  process: StudioProcess;
};

export type AddStudioProcessToBranchParams = {
  branch_id: number;
  process_id: number;
};

export type UpdateDeployableJSONParams = {
  branch: number;
  process: number;
  data: HashMap;
};

// CRUD

export type GetStudioProcessMetaJSONBySavepointParams = {
  process_savepoint_id: number;
  path?: string; // when path is not passed, entire Meta JSON will be requested
};

export type GetStudioProcessDeployableJSONParams = {
  branch: number;
  process: number;
  path?: string; // when path is not passed, entire Meta JSON will be requested
};

export type GetStudioProcessMetaJSONResponse = {
  meta_json: HashMap;
};

export type GetStudioProcessDeployableJSONResponse = {
  deployable_json: HashMap;
};

export type UpdateStudioProcessMetaJSONParams = {
  branch: number;
  process: number;
  data: MetaJSONChanges;
};

export type MetaJSONChanges = {
  create_or_update?: MetaJSONPayload[];
  delete?: { path: string }[];
};

export type MetaJSONPayload = {
  // when path is missing, the data replaces the entire document,
  // rather than doing a selective update on the existing one, based
  // the path.
  path: string;
  data: any;
};

// Possibly generic

export type UserResponse = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};
export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

// Deployment logs

export type SavepointDeploymentLogResponse = {
  id: string;
  status: string;
  errors: string;
  updated_at: string;
  platform_settings_errors: AppSettingsDeploymentError;
  stage_timestamps: HashMap<{
    start_time: number;
    end_time: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type SavepointDeploymentLog = {
  id: string;
  status: string;
  errors: string;
  updatedAt: string;
  appSettingsErrors: AppSettingErrorSaveLog;
  timestamps: HashMap<{
    startTime: number;
    endTime: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type ProcessDeploymentLogResponse = {
  id: string;
  status: string;
  errors: string;
  config_errors_warnings: {
    errors: Record<string, string[]>;
    warnings: Record<string, string[]>;
  };
  warnings: string;
  workflow_kind_tag: string;
  branch_log: string;
  updated_at: string;
  stage_timestamps: HashMap<{
    start_time: number;
    end_time: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export type ProcessDeploymentLog = {
  id: string;
  status: string;
  errors: string;
  warnings: string;
  workflowKindTag: string;
  branchLogId: string;
  updatedAt: string;
  configErrorsWarnings: {
    errors: Record<string, string[]>;
    warnings: Record<string, string[]>;
  };
  timestamps: HashMap<{
    startTime: number;
    endTime: number;
    duration: number; // -1 = not evaluated yet
  }>;
};

export enum ErrorLocation {
  KIND = "kind",
  STEPGROUP = "stepgroup",
  STEP = "step",
  RULE = "rule"
}

// TODO: This might be divided into individual types when API is done
// KindDeploymentErrorMeta | SwimlaneDeploymentErrorMeta | ...
export type DeploymentErrorMetaRaw = {
  error_location: ErrorLocation;
  process: number;
  stepgroup?: string;
  stepgroup_name?: string;
  step?: string;
  step_name?: string;
  field?: string;
  field_name?: string;
  ruleType?: string;
  ruleIndex?: number;
  property?: string;
};

export type DeploymentErrorDataRaw = {
  error_code: string;
  error_msg: string;
  error_description: string;
  meta: DeploymentErrorMetaRaw;
};

// TODO: same here as DeploymentErrorMetaRaw
export type DeploymentErrorMeta = {
  errorLocation: ErrorLocation;
  processId: number;
  swimlaneTag?: string;
  swimlaneName?: string;
  stepTag?: string;
  stepName?: string;
  fieldTag?: string;
  fieldName?: string;
  ruleType?: string;
};

export type DeploymentErrorData = {
  errorCode: string;
  errorMsg: string;
  errorDescription: string;
  meta: DeploymentErrorMeta;
};

export type StudioPermissionsResponse = {
  can_access_studio: boolean;
  can_deploy_to_production: boolean;
  can_deploy_to_staging: boolean;
  can_deploy_to_qa: boolean;
  can_deploy_to_development: boolean;
  can_edit_branch: boolean;
  can_merge_branch: boolean;
  can_edit_package: boolean;
  can_edit_process: boolean;
  studio_superuser_perm: boolean;
  studio_objects_superuser_perm: boolean;
};

export type StudioPermissions = {
  canAccessStudio: boolean;
  canDeployToProduction: boolean;
  canDeployToStaging: boolean;
  canDeployToQA: boolean;
  canDeployToDevelopment: boolean;
  canEditBranch: boolean;
  canMergeBranch: boolean;
  canEditPackage: boolean;
  canEditProcess: boolean;
  isStudioSuperuser: boolean;
  isStudioObjectsSuperuser: boolean;
};

export type GetWFUpdateInProgressResponse = {
  enable_update_in_progress: boolean;
};

export type CreateBranchSavepointResponse = {
  id: number;
  branch_id: number;
  title: string;
  description: string;
};

export type StudioCentralTemplate = {
  id: number;
  uid: string;
  name: string;
  description: string;
  type: StudioTemplateTypes;
  integrationType?: TemplateIntegrationTypes;
  category?: string;
  source?: string;
  author?: string;
  templateStatus?: string;
  clientNames?: string[];
  code: string;
};

export enum TemplateIntegrationTypes {
  STEP = "step",
  SWIMLANE = "swimlane",
  MODULE = "module",
  PROCESSES = "processes"
}

export enum TemplateStatusTypes {
  DRAFT = "Draft",
  PUBLIC = "Public",
  PRIVATE = "Private",
  RETIRED = "Retired",
  CLIENT_SPECIFIC = "Client/Partner Specific"
}

export type TransactionalChange = {
  actor: {
    id: string;
    email: string;
  };
  timestamp: string;
  type: TransactionalChangeTypes;
  data: // JSONPatch operations
  Operation[];
  //TODO: Branch merge operations & Process Intialization, other events
};

export enum TransactionalChangeTypes {
  JSONPATCH = "jsonpatch",
  BRANCH_MERGE = "branch_merge"
}

export type AppSettingsDeploymentError = Record<
  keyof AppSettingsRaw,
  Record<string, { [key: string]: string | string[] }[]>
>;

export type AppSettingErrorSaveLog = {
  group?: AppsettingsErrorDetails;
  emailTemplate?: AppsettingsErrorDetails;
  region?: AppsettingsErrorDetails;
  extraJSON?: AppsettingsErrorDetails;
  processKind?: AppsettingsErrorDetails;
  processStatus?: AppsettingsErrorDetails;
  processPDFTemplate?: AppsettingsErrorDetails;
  alertCategory?: AppsettingsErrorDetails;
  commentFlagOption?: AppsettingsErrorDetails;
  feExtraMetaData?: AppsettingsErrorDetails;
  objectTypeWorkflowKindRelation?: AppsettingsErrorDetails;
};

export type AppsettingsErrorDetails = Record<string, string[]>;

/*
Reason for disabling the eslint rule : 
  - Backend send the information of the domain like certaeu, certain, so on. 
  - This enum has following responsibilties:
    - To act as a type to the BE response. 
    - To act as a map to the UI verbiages, based on the BE Response
*/

export enum DeployENVToVerbiageMap {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  certaeu = "Production",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  certain = "Production",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  certasite = "Staging",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  certaqa = "QA",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  slackcart = "Development"
}

export enum SavepointStage {
  "WIP" = "wip",
  "LOCKED" = "locked",
  "VALIDATING" = "validation_inprogress",
  "VALIDATION_FAILED" = "validation_finished_with_errors",
  "DEPLOYED" = "deployed"
}

// Standard Data Objects Studio Types
export type ObjectMetaDataResponse = {
  id: number;
  name: string;
  description?: string;
  stage: ObjectStageRaw;
  created_at: string;
  is_mutable: boolean;
};

export type ObjectMetaData = {
  id: string;
  name: string;
  description?: string;
  stage?: ObjectStage;
  createdAt: string;
  isMutable: boolean;
  // tag: string;
  // type?: string;
  // stage?: string;
};

export enum ObjectFieldWidth {
  ONE_THIRD = "1/3",
  HALF = "1/2",
  TWO_THIRD = "2/3",
  FULL = "1"
}

export enum RelationshipTypes {
  ONE_TO_MANY = "ForeignKey",
  MANY_TO_MANY = "ManyToMany"
}

// Highly likely to change
// For now we have same structure, in future, if there are more items, we may need to change this.
export type ObjectUISchema = {
  displayNames?: {
    singular?: string; // Used where ever single object type tag name is required
    plural?: string; // Used where ever multiple object type tag name is required
  };
  list: { fields: string[] }; // Field identifiers to display as columns
  detail: {
    // Groupings of fields in detail view
    sections?: {
      title: string;
      fields: {
        tag: string;
        width: ObjectFieldWidth;
      }[];
    }[];
    // Related Object Tag
    tabs?: {
      label: string; // Display name of the tab
      id: string; // Unique identifier for the tab
      path: string; // Relationship path to related object
      initializerWorkflowKind?: string;
    }[];
  };
  fields: {
    [fieldTag: string]: {
      title: string;
      format?: string;
      rules?: ObjectRules;
      type?: "currency" | "date" | number | string | "url" | "email" | "owner";
    };
  };
};

type ObjectUISchemaRaw = Omit<ObjectUISchema, "displayNames"> & {
  display_names?: {
    singular?: string; // Used where ever single object type tag name is required
    plural?: string; // Used where ever multiple object type tag name is required
  };
};

export type RawObjectField = {
  type: ObjectFieldType;
  format?: string;
  isRequired: boolean;
  // For array fields
  items?: {
    type: string;
  };
};

// Example:
// "entity": { // Tag / Forward Relationship Label
//   "type": "foreign_key",
//   "x-relationship": {
//     "to_object_type": "entity", // Target Object Tag
//     "relationship_type": "ForeignKey", // Relationship Type (1-M -> ForeignKey)
//     "reverse_relation_name": "engagements" // Reverse Relationship Label
//   }
// }
export type RawRelationshipField = {
  type: ObjectFieldType.FOREIGN_KEY;
  "x-relationship": {
    to_object_type: string;
    relationship_type: RelationshipTypes;
    reverse_relation_name: string;
  };
};

export type ObjectTypeRaw = {
  tag: string;
  schema: {
    properties: {
      // Key is the field tag, or relationship forward label
      [key: string]: RawObjectField | RawRelationshipField;
    };
    required?: string[];
  };
  // TODO:
  // Highly likely to change
  ui_schema?: ObjectUISchemaRaw;
};
// Note: Use this for type requirements like, ObjectRules["rag"].
export type ObjectRules = {
  rag?: ObjectRAGConfiguration; // Red/Amber/Green indicators
};

enum ObjectRuleOperator {
  EQUALS = "eq",
  NOT_EQUALS = "not_eq",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUALS = "gte",
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUALS = "lte",
  IN = "in",
  NOT_IN = "not_in",
  BETWEEN = "between"
}

// Note: Do not export Rule Related Types. Instead use ObjectRules Type

// RAG Configuration
type ObjectRAGConfiguration = {
  red?: ObjectRAGRule; // Defaults to "High" label if not provided
  amber?: ObjectRAGRule; // Defaults to "Medium" label if not provided
  green?: ObjectRAGRule; // Defaults to "Low" label if not provided
};

type ObjectRAGRule = {
  operator: ObjectRuleOperator;
  value?: string | number;
  values?: string[] | number[]; // For 'in' and 'not_in' operators
  min?: number; // For 'between' operator
  max?: number; // For 'between' operator
  label?: string; // Optional custom label (defaults: High/Medium/Low)
  description?: string; // Description for tooltip
};

export enum ObjectStage {
  DRAFT = "draft",
  RFT = "rft",
  DEPLOYED = "deployed",
  ARCHIVE = "ARCHIVE"
}

export enum ObjectStageRaw {
  DRAFT = "WIP",
  RFT = "RFT",
  DEPLOYED = "PROD",
  ARCHIVE = "ARCHIVE"
}

export type ObjectSchema = {
  id: number;
  metaJSON: StudioObject;
  name: string;
};

export type ObjectSchemaRaw = {
  id: number;
  meta_json: StudioObject;
  name: string;
};

// SDO Deployment Types

export enum SDODeploymentEnvironment {
  SLACKCART = "slackcart",
  CERTAQA = "certaqa",
  CERTASITE = "certasite",
  CERTAIN = "certain",
  CERTAEU = "certaeu"
}

export enum SDODeploymentStatus {
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  FAILED = "failed",
  PARTIAL_SUCCESS = "partial_success"
}

// SDO Deployment Logs
export type SDODeploymentLogResponse = {
  environment: SDODeploymentEnvironment;
  // This is at log level, a log is a unit, so it can never be partial success
  status: Omit<SDODeploymentStatus, "PARTIAL_SUCCESS">;
  started_at: string;
  completed_at: string;
  error_message: string;
};

export type SDOSavePointStatusResponse = {
  id: number;
  stage: ObjectStageRaw;
  created_at: string;
  last_deployed_at: string | null;
  deployment_status?: SDODeploymentStatus;
  deployment_logs?: SDODeploymentLogResponse[];
};

export type SDODeploymentLog = {
  environment: SDODeploymentEnvironment;
  // This is at log level, a log is a unit, so it can never be partial success
  status: Omit<SDODeploymentStatus, "PARTIAL_SUCCESS">;
  startedAt: Date;
  completedAt: Date;
  errorMessage: string;
};

export type SDOSavePointStatus = {
  id: number;
  stage: ObjectStage;
  createdAt: Date;
  lastDeployedAt: Date | null;
  deploymentStatus?: SDODeploymentStatus;
  deploymentLogs?: SDODeploymentLog[];
};

// SDO Deployment
export type SDODeploymentPayloadResponse = {
  id: number;
  environment: SDODeploymentEnvironment;
  deployable_json?: ObjectTypeRaw[]; // Only required for WIP stage
  status: "success" | "failed" | "in_progress";
  created_at: string;
};

export type SDODeploymentPayload = {
  id: number;
  environment: SDODeploymentEnvironment;
  deployableJSON?: ObjectTypeRaw[]; // Only required for WIP stage
  status: "success" | "failed" | "in_progress";
  createdAt: Date;
};
export type SDOObjectLockOwnerInfoResponse = {
  id: number;
  first_name: string;
  last_name: string;
};
export type SDOObjectLockOwnerInfo = {
  id: number;
  firstName: string;
  lastName: string;
};
export type SDOObjectLockResponse = {
  user: string | null; // BE sends user email here.
  user_info: SDOObjectLockOwnerInfoResponse;
};
export type SDOObjectLock = {
  email: string | null;
  userInfo: SDOObjectLockOwnerInfo | null;
};
