import React, { Fragment } from "react";

type ImageProps = {
  alt: string;
  src: string;
  height?: string;
  width?: string;
  fallbackSrc?: string | React.ReactNode;
  onError?: () => void;
} & React.HTMLAttributes<HTMLImageElement>;

const Image = React.forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const { alt, src, height, width, fallbackSrc, onError, ...rest } = props;
  const [error, setError] = React.useState(false);

  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setError(true);
    onError?.();
  };

  if (error) {
    return typeof fallbackSrc === "string" ? (
      <img
        {...rest}
        alt={alt}
        src={fallbackSrc}
        ref={ref}
        height={height || "auto"}
        width={width || "auto"}
      />
    ) : (
      <Fragment>{fallbackSrc}</Fragment>
    );
  }
  return (
    <img
      {...rest}
      alt={alt}
      src={src}
      ref={ref}
      height={height || "auto"}
      width={width || "auto"}
      onError={handleError}
    />
  );
});

export { Image };
