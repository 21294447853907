import type { AppSettingsRaw, AppSettings } from "../types/appSettings.types";

export const qkAppSettingsFromDB = "app-settings-from-db";
export const qkAppSettingsPermissionList = "app-settings-permissions-list";
export const appSettingsQueryKey = "app-settings";
export const qkappSettingsSchema = "app-settings-schema";

export const appSettingsRawToAppSettingsMap = {
  alertcategory: "alertCategory",
  businessunit: "businessUnit",
  commentflagoption: "commentFlagOption",
  emailattachment: "emailAttachment",
  emailtemplate: "emailTemplate",
  extrajson: "extraJSON",
  group: "group",
  objecttypeworkflowkindrelation: "objectTypeWorkflowKindRelation",
  region: "region",
  workflowkind: "processKind",
  workflowpdftemplate: "processPDFTemplate",
  workflowstatus: "processStatus",
  frontend_extra_metadata: "feExtraMetaData"
} satisfies Record<
  Exclude<keyof AppSettingsRaw, "file_paths">,
  keyof AppSettings
>;
