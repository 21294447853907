import { useState } from "react";
import { useNavigate } from "react-router";

import { Alert } from "@certa/catalyst/components/Alert";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { MobileUserDrawer } from "../MobileUserDrawer/MobileUserDrawer";

import { useAppSelector } from "../../hooks";

import { Bell2 } from "@certa/icons/components/Bell2";
import { Close } from "@certa/icons/components/Close";
import { useMentionsContext } from "@certa/mentions";
import { useGetUnReadMentionCount } from "@certa/queries/hooks/mentions.hooks";
import { useUnreadMentionsCountComms } from "@certa/queries/queries/comms";
import styles from "./MobileHeader.module.css";
import { useHeaderBanner } from "../../hooks/useHeaderBanner";
import { useCommsNewEndpoints } from "../../toggles";
import { UserAvatar } from "../Header/components/UserProfile/components";
import { AvatarSizes, AvatarColors } from "@certa/catalyst/components/Avatar";
type MobileHeaderProps = {};

export const MOBILE_HEADER_ID = "certa-mobile-header";

export const MobileHeader = (props: MobileHeaderProps) => {
  const config = useAppSelector(state => state.config);

  const authentication = useAppSelector(state => state?.authentication);

  const navigate = useNavigate();

  const bannerConfig = useHeaderBanner();
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const { data: userMentionsCount = 0 } = useGetUnReadMentionCount();
  const { data: userMentionsCountComms = 0 } = useUnreadMentionsCountComms();

  const userName =
    authentication?.user?.first_name && authentication?.user?.last_name
      ? `${authentication.user.first_name} ${authentication.user.last_name}`
      : "";

  const userEmail = authentication?.user?.email || "";

  const unreadMentions = isCommsNewEndpointEnabled
    ? userMentionsCountComms
    : userMentionsCount;
  const {
    toggleShow: toggleNotificationDrawer,
    show: shouldShowNotificationDrawer
  } = useMentionsContext();

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);

  const handleOnClick = () => {
    navigate("/");
  };
  const shouldShowNotificationIcon = !shouldShowNotificationDrawer;

  const NotificationDrawerTriggerIcon = shouldShowNotificationDrawer
    ? Close
    : Bell2;

  return (
    <>
      {bannerConfig && (
        <Alert
          type={bannerConfig.type}
          message={bannerConfig.message}
          hasIcon={bannerConfig.hasIcon}
          width="100%"
        />
      )}
      <Stack
        height="3.75rem"
        width="100%"
        direction="horizontal"
        justify="space-between"
        padding="0 20"
        align="center"
        dangerouslySetClassName={styles.mobileHeader}
        id={MOBILE_HEADER_ID}
      >
        <button
          className={styles.mobileHeaderLogoContainer}
          onClick={handleOnClick}
          data-testid="mobile-header-logo-button"
        >
          <img src={config.logo} alt={config.name} />
        </button>

        <Stack
          direction="horizontal"
          justify="flex-end"
          align="center"
          gap="12"
        >
          <button
            onClick={() => toggleNotificationDrawer()}
            className={styles.notificationTriggerButton}
            data-testid="notification-trigger-button"
          >
            <NotificationDrawerTriggerIcon />
            {unreadMentions > 0 && shouldShowNotificationIcon && (
              <span className={styles.notifyDot} />
            )}
          </button>
          <UserAvatar
            userName={userName || userEmail}
            size={AvatarSizes.SMALL}
            color={AvatarColors.NEUTRAL_600}
            onClick={() => {
              setIsUserDrawerOpen(prevState => !prevState);
            }}
          />
        </Stack>
      </Stack>
      <MobileUserDrawer
        show={isUserDrawerOpen}
        onClose={() => {
          setIsUserDrawerOpen(false);
          console.log("On Close Invoked");
        }}
      />
    </>
  );
};
