import { Godaam } from "@certa/common/utils/Godaam";
import { APIFetchKryptonForComms, RequestHeaders } from "@certa/network";

const TOKEN_FETCH_INTERVAL = 25 * 60 * 1000; // 25 minutes in milliseconds
const COMMS_TOKEN_FETCH_TIME = "commsTokenFetchTime";
// Set Auth token for Comms module in the cookie
const setCommsAuthToken = async (): Promise<void> => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include"
  };

  const res = await APIFetchKryptonForComms<void>(
    `comms/generate_token/`,
    requestOptions
  );
  const currentTime = Date.now();
  Godaam[COMMS_TOKEN_FETCH_TIME] = currentTime.toString();
  return res;
};

const getCommsAuthToken = async (isCommsNewEndpointEnabled: boolean) => {
  if (!isCommsNewEndpointEnabled) {
    return;
  }

  const currentTime = Date.now();

  const storedTime = Godaam[COMMS_TOKEN_FETCH_TIME];
  const commsTokenFetchTime = storedTime ? parseInt(storedTime, 10) : 0;

  if (currentTime - commsTokenFetchTime < TOKEN_FETCH_INTERVAL) {
    return;
  }

  try {
    await setCommsAuthToken();
  } catch (error) {
    console.error("Error generating new token:", error);
  }
};

export { getCommsAuthToken };
