import { Badge } from "@certa/icons/components/Badge";
import { Gauge } from "@certa/icons/components/Gauge";
import { Info } from "@certa/icons/components/Info";
import { Text } from "@certa/icons/components/Text";
import { Progress } from "@certa/icons/components/Progress";
import { Sum } from "@certa/icons/components/Sum";
import { Tag } from "@certa/icons/components/Tag";
import { User } from "@certa/icons/components/User";
import { Envelope } from "@certa/icons/components/Envelope";
import { Date } from "@certa/icons/components/Date";
import type { SmartColumnType, ChartTypeOption } from "./types";
import { ReportColumnFieldTags } from "./types";
import type { ColumnORM } from "@certa/types";
import type { ChartIdentifiers } from "@certa/common/types";
import { processAttrDataORM } from "@certa/common/constants";
import { createProcessValueTag } from "@certa/common/utils/report";

// Report
export const REPORT_KIND_TAG = "report-workflow";
export const REPORT_KIND_NAME = "report_workflow";
export const REPORT_NAME_FIELD_TAG = "report_name";
export const REPORT_FILTERS_FIELD_TAG = "report_filters";
export const REPORT_COLUMNS_FIELD_TAG = "report_columns";
export const REPORT_CHART_FIELD_TAG = "report_graphs";
export const REPORT_DESCRIPTION_FIELD_TAG = "report_description";
export const REPORT_PROCESS_TAG = "report_process";
export const REPORT_EDITABLE_FIELD_TAG = "report_is_editable";
export const REPORT_DELETABLE_FIELD_TAG = "report_is_deletable";
export const REPORT_EXPORTABLE_FIELD_TAG = "report_is_exportable";
export const REPORT_GRID = "report_grid";
export const REPORT_ORDER = "report_order";
export const REPORT_IS_RE_ORDERABLE = "report_is_reorderable";
export const REPORT_FAVORABLE_FIELD_TAG = "report_is_favorable";
export const REPORT_TYPE = "report_type";
export const REPORT_DATA_ORDER = "report_data_order";
export const REPORT_COMMON = "report_common";

// Dashboard
export const DASHBOARD_KIND_TAG = "report-dashboard";
export const DASHBOARD_KIND_NAME = "report_dashboard";
export const DASHBOARD_NAME_FIELD_TAG = "dashboard_name";
export const DASHBOARD_DESCRIPTION_FIELD_TAG = "dashboard_description";
export const DASHBOARD_ICON_FIELD_TAG = "dashboard_icon";
export const DASHBOARD_ORDER_FIELD_TAG = "dashboard_order";
export const DASHBOARD_SHARE_LIST_FIELD_TAG = "dashboard_share_list";

// Process report
export const PROCESS_REPORT_DASHBOARD_KIND_TAG = "report-process-dashboard";

// Extras
export const REPORT_FIELD_TAGS_TO_FETCH = [
  REPORT_NAME_FIELD_TAG,
  REPORT_FILTERS_FIELD_TAG,
  REPORT_COLUMNS_FIELD_TAG,
  REPORT_CHART_FIELD_TAG,
  REPORT_DESCRIPTION_FIELD_TAG,
  REPORT_PROCESS_TAG,
  REPORT_EDITABLE_FIELD_TAG,
  REPORT_DELETABLE_FIELD_TAG,
  REPORT_EXPORTABLE_FIELD_TAG,
  REPORT_GRID,
  REPORT_ORDER,
  REPORT_IS_RE_ORDERABLE,
  REPORT_FAVORABLE_FIELD_TAG,
  REPORT_TYPE,
  REPORT_DATA_ORDER,
  REPORT_COMMON
];

export const DASHBOARD_FIELD_TAGS_TO_FETCH = [
  DASHBOARD_NAME_FIELD_TAG,
  DASHBOARD_DESCRIPTION_FIELD_TAG,
  DASHBOARD_ICON_FIELD_TAG,
  DASHBOARD_ORDER_FIELD_TAG,
  DASHBOARD_SHARE_LIST_FIELD_TAG
];

export const REPORT_FULL_SCREEN_HASH_URL = "#table";

export const REPORT_ACTIVE_X_IDENTIFIER = "activeXIdentifier";

export const SMART_COLUMNS: SmartColumnType[] = [
  {
    title: {
      id: "dashboards.reportColumnProcessName",
      defaultMessage: "Name"
    },
    icon: Text,
    value: ReportColumnFieldTags.NAME
  },
  {
    title: {
      id: "reports.alertOccurrence",
      defaultMessage: "Occurrences in Alerts"
    },
    icon: Sum,
    value: ReportColumnFieldTags.ALERTS_COLUMN
  },
  {
    title: {
      id: "reports.progress",
      defaultMessage: "Progress"
    },
    icon: Progress,
    value: ReportColumnFieldTags.PROCESS_PROGRESS_COLUMN
  },
  {
    title: {
      id: "reports.status",
      defaultMessage: "Status"
    },
    icon: Tag,
    value: ReportColumnFieldTags.PROCESS_STATUS_COLUMN
  },
  {
    title: {
      id: "reports.ageing",
      defaultMessage: "Ageing"
    },
    icon: Gauge,
    value: ReportColumnFieldTags.AGEING
  },
  {
    title: {
      id: "reports.processCycleTime",
      defaultMessage: "Process cycle time"
    },
    icon: Gauge,
    value: ReportColumnFieldTags.PROCESS_CYCLE_TIME_COLUMN
  },
  {
    title: {
      id: "reports.badges",
      defaultMessage: "Badges"
    },
    icon: Badge,
    value: ReportColumnFieldTags.BADGES
  },
  {
    title: {
      id: "reports.systemAttributes",
      defaultMessage: "System attributes"
    },
    icon: Info,
    children: [
      {
        title: {
          id: "reports.createdAt",
          defaultMessage: "Created At"
        },
        icon: Date,
        value: ReportColumnFieldTags.CREATED_AT
      },
      {
        title: {
          id: "reports.updatedAt",
          defaultMessage: "Updated At"
        },
        icon: Date,
        value: ReportColumnFieldTags.UPDATED_AT
      },
      {
        title: {
          id: "reports.createdByEmail",
          defaultMessage: "Created By (Email)"
        },
        icon: Envelope,
        value: ReportColumnFieldTags.CREATED_BY_EMAIL
      },
      {
        title: {
          id: "reports.createdByName",
          defaultMessage: "Created By (Name)"
        },
        icon: User,
        value: ReportColumnFieldTags.CREATED_BY_NAME
      }
    ]
  }
];

export const predefinedColorPickerColors = [
  "#00A1D8",
  "#0061FD",
  "#5E30EB",
  "#BE38F3",
  "#E63B7A",
  "#FE6250",
  "#FE8648",
  "#FECB3E",
  "#E4EF65",
  "#96D35F"
];

export const defaultChartType: ChartIdentifiers = "LINE_CHART";

export const defaultReportGraphsAnswer = "{}";

export const chartTypeOptions: ChartTypeOption[] = [
  {
    label: "Line",
    value: "LINE_CHART",
    text: "Line"
  },
  {
    label: "Bar",
    value: "BAR_CHART",
    text: "Bar"
  },
  {
    label: "Pie",
    value: "PIE_CHART",
    text: "Pie"
  },
  {
    label: "Spider",
    value: "SPIDER_CHART",
    text: "Spider"
  },
  {
    label: "Scatter",
    value: "SCATTER_CHART",
    text: "Scatter"
  },
  {
    label: "Heat map",
    value: "HEAT_MAP_CHART",
    text: "Heat map"
  },
  {
    label: "Geo map",
    value: "GEO_MAP_CHART",
    text: "Geo map"
  }
];

export const MULTI_X_AXIS_SUPPORTED_CHARTS = ["LINE_CHART", "BAR_CHART"];

export const chartTypeOptionsByValue = chartTypeOptions.reduce(
  (byValue, option) => {
    byValue[option.value] = option;
    return byValue;
  },
  {} as Record<ChartIdentifiers, ChartTypeOption>
);

export const defaultColumnTags = [
  ReportColumnFieldTags.ID,
  ReportColumnFieldTags.NAME,
  ReportColumnFieldTags.PROCESS_STATUS_COLUMN,
  ReportColumnFieldTags.CREATED_AT
];

/**
 *  Wraning: custom tags are wrong in this as it requires kind_id, so don't use this directly
 *  use createDefaultColumnsORM instead
 */
export const defaultColumnTagsORM: ColumnORM[] = [
  {
    type: "attr",
    kindId: undefined,
    label: processAttrDataORM["name"].label,
    value: ReportColumnFieldTags.NAME,
    extraJSON: {
      fieldType: processAttrDataORM["name"].fieldType,
      isHidden: false,
      customTag: createProcessValueTag("name"),
      addedBy: "column"
    }
  },
  {
    type: "attr",
    kindId: undefined,
    label: processAttrDataORM["status_id"].label,
    value: "status_id",
    extraJSON: {
      fieldType: processAttrDataORM["status_id"].fieldType,
      isHidden: false,
      customTag: createProcessValueTag("status_id"),
      addedBy: "column"
    }
  },
  {
    type: "attr",
    kindId: undefined,
    label: processAttrDataORM["created_at"].label,
    value: ReportColumnFieldTags.CREATED_AT,
    extraJSON: {
      fieldType: processAttrDataORM["created_at"].fieldType,
      isHidden: false,
      customTag: createProcessValueTag("created_at"),
      addedBy: "column"
    }
  }
];

export const defaultExportColumnsTags = [
  ReportColumnFieldTags.ID,
  ReportColumnFieldTags.NAME
] as const;

export const hiddenColumnsTags = [ReportColumnFieldTags.ID] as const;

export const KEY_FIELDS_TAG = "key-fields";
export const CREATED_BY_TAG = "created-by";
export const CREATED_BY_EMAIL_TAG = "created-by-email";
export const CREATED_AT_TAG = "created-at";
export const UPDATED_AT_TAG = "updated-at";
export const STATUS_TAG = "status";
export const MATH_AND_FORMULAS_TAG = "math-and-formulas";
export const METADATA_TAG = "metadata";
export const VALUES_TAG = "values";
export const ALL_STEPS_LABEL = "All Steps";

export type XAxisSystemAttributes =
  | typeof CREATED_AT_TAG
  | typeof UPDATED_AT_TAG
  | typeof CREATED_BY_TAG
  | typeof CREATED_BY_EMAIL_TAG
  | typeof STATUS_TAG;

export const xAxisSystemAttributes: XAxisSystemAttributes[] = [
  STATUS_TAG,
  CREATED_BY_TAG,
  CREATED_BY_EMAIL_TAG,
  CREATED_AT_TAG,
  UPDATED_AT_TAG
];

export const systemTimeAttributes = [CREATED_AT_TAG, UPDATED_AT_TAG];
export const MAX_TITLE_LENGTH = 75;

export const floatingFilterCollapseKeys = {
  BASIC_FILTERS: "BASIC_FILTERS",
  WORKFLOW_FILTERS: "WORKFLOW_FILTERS",
  DATE_FILTERS: "DATE_FILTERS"
} as const;
