import { combineReducers } from "redux";

import loginReducer from "../../modules/LoginPage/loginSlice";
import { config } from "./config";
import { workflow, workflowChildren, expandedWorkflows } from "./workflow_list";
import { workflowCreate } from "./workflow_create";
import {
  workflowKind,
  workflowGroupCount,
  workflowAlertGroupCount,
  workflowKindValue
} from "./workflow_kind";
import { workflowDetails, hasStepinfo } from "./workflow_details";
import { workflowDetailsHeader } from "./workflow_details_header";
import { workflowFilters, workflowFilterType } from "./workflow_filters";
import { changeStatus } from "./change_status";
import { languageSelector } from "./internationalize";
import { showFilterMenu, showPreviewSidebar } from "./navbar";
import { userWorkflowModal } from "./user_workflow";
import { stepUsers } from "./stepBody";
import { workflowKeys } from "./workflowKeys";
import { workflowSearch } from "./workflowSearch";
import { permissions } from "../../modules/common/permissions/reducer";
import { workflowFamily } from "./workflow_family";
import { myTasks } from "./myTasks";
import { showLeftSidebar } from "./sidebar";

// New module pattern
import { workflowList } from "../../modules/dashboard";

//THIS IS THE STRUCTURE/STATE OF YOUR STORE//
export const rootReducer = combineReducers({
  authentication: loginReducer,
  changeStatus,
  config,
  expandedWorkflows,
  hasStepinfo,
  showFilterMenu,
  showPreviewSidebar,
  showLeftSidebar,
  workflow,
  workflowChildren,
  workflowCreate,
  workflowDetails,
  workflowDetailsHeader,
  workflowFilters,
  workflowFilterType,
  workflowGroupCount,
  workflowAlertGroupCount,
  workflowKind,
  workflowKindValue,
  languageSelector,
  userWorkflowModal,
  stepUsers,
  workflowKeys,
  workflowSearch,
  permissions,
  workflowFamily,
  myTasks,

  // New module pattern
  workflowList
});
