/**
 * This file contains the constants used throughout the app
 *
 * Guidelines
 * 1. Every variable name should be `CAPITALIZED_UNDERSCORE_SEPARETED`
 *
 * CAUTION: Please dont add any logic here
 */

export const URL_REGEX: RegExp =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.()~#?&//=]*)/gi;

export const ANCHOR_TAG_REGEX: RegExp = /<a[^>]*>([^<]+)<\/a>/;

export enum ObjectTypes {
  FIELD = "FIELD",
  STEP = "STEP",
  STEP_GROUP = "STEPGROUP",
  WORKFLOW = "WORKFLOW"
}

export const SIDEBAR_WFNAME_LIMIT = 3; // number of lines

export const COPY_TEXT = {
  OPPS: "Oops!"
};
