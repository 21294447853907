import { useQuery, useInfiniteQuery, useMutation } from "react-query";
import {
  getUnReadMentionCount,
  getUserMentions,
  markMentionAsRead
} from "../services/mentions.services";
import type {
  UseMutationExtraConfigType,
  UseInfiniteQueryExtraConfigType
} from "@certa/common/types";
import { useCommsNewEndpoints } from "@certa/common/toggles";

export const useGetMentionsList = (config?: UseInfiniteQueryExtraConfigType) =>
  useInfiniteQuery(
    "user-mention-list",
    ({ pageParam = 0 }) => getUserMentions(pageParam),
    {
      getNextPageParam: lastPage => {
        if (lastPage.next) {
          const url = new URL(lastPage.next);
          const urlParams = new URLSearchParams(url.search);
          return urlParams.get("page");
        }
        return false;
      },
      ...config
    }
  );

export const useGetUnReadMentionCount = () => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: "unRead-mention-count",
    queryFn: getUnReadMentionCount,
    enabled: !isCommsNewEndpointEnabled
  });
};
export const useMarkMentionRead = (config?: UseMutationExtraConfigType) =>
  useMutation(markMentionAsRead, config);
