import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes
} from "@certa/catalyst/components/Button";
import { useIntl } from "react-intl";
import { Plus } from "@certa/icons/components/Plus";
import { Trashcan } from "@certa/icons/components/Trashcan";

type AppendQueryProps = {
  onClick: () => void;
};

export const AppendQuery = ({ onClick }: AppendQueryProps) => {
  const intl = useIntl();
  return (
    <Button onClick={onClick} size={ButtonSizes.SMALL} leftIcon={<Plus />}>
      {intl.formatMessage({
        id: "savedSearch.appendQuery",
        defaultMessage: "Append Query"
      })}
    </Button>
  );
};

export const AppendGroupQuery = ({ onClick }: AppendQueryProps) => {
  const intl = useIntl();
  return (
    <Button onClick={onClick} size={ButtonSizes.SMALL} leftIcon={<Plus />}>
      {intl.formatMessage({
        id: "savedSearch.appendGroupQuery",
        defaultMessage: "Append Group Query"
      })}
    </Button>
  );
};

type RemoveIconButtonProps = {
  onClick: () => void;
  ariaLabel: string;
};

export const RemoveIconButton = ({
  onClick,
  ariaLabel
}: RemoveIconButtonProps) => {
  return (
    <div style={{ flexShrink: 0 }}>
      <Button
        size={ButtonSizes.SMALL}
        type={ButtonTypes.ICON}
        rightIcon={<Trashcan />}
        color={ButtonColors.RED}
        onClick={onClick}
        aria-label={ariaLabel}
      />
    </div>
  );
};
