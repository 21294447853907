import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { Stack } from "@certa/blocks/thanos";
import { gapS1, gapS1WithAutoWidth } from "../styles";
import { Download } from "@certa/icons/components/Download";
import {
  showToast,
  ToastPlacements,
  ToastTypes
} from "@certa/catalyst/components/Toast";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { useIntl } from "react-intl";
import { ResultLayout } from "../ResultLayout/ResultLayout";
import type { ResultComponentProps } from "./types";
import type { HierarchyItem } from "../Breadcrumbs/Breadcrumbs.types";
import { FileDownloadTypes, fileDownload } from "../../../utils/fileDownload";
import { SearchHighlighter } from "../SearchHighlighter";

export const FileResult = (
  props: ResultComponentProps<ModelledAPIResponse.FileSearchResult>
) => {
  const { result, family } = props;

  const intl = useIntl();

  const breadcrumbs: HierarchyItem[] = family
    .map((member, index) => ({
      id: member.id,
      name: member.name,
      logo: member.logo,
      hideLogo: false,
      // Except the root and the leaf, all other items can be hidden
      canHide: index !== 0 && index !== family.length - 1,
      // The root and the leaf are always priority 2, the rest are 3
      priority: index === 0 || index === family.length - 1 ? 2 : 3
    }))
    .concat([
      {
        id: result.id,
        name: result.fileName,
        canHide: false,
        priority: 1,
        logo: null,
        hideLogo: true
      }
    ]);

  const handleWorkflowFileDownload: React.MouseEventHandler<
    HTMLSpanElement
  > = e => {
    e.stopPropagation();
    const url = result.url;
    const fileName = result.fileName;

    // TODO: This component should not be making the fetch call. It should be moved to a utility function
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        fileDownload({
          downloadType: FileDownloadTypes.BLOB_DOWNLOAD,
          url,
          blob,
          fileName
        });
      })
      .catch(error => {
        showToast({
          type: ToastTypes.ERROR,
          title: intl.formatMessage({
            id: "errorMessageInstances.somethingWentWrong"
          }),
          description: intl.formatMessage({
            id: "errorMessageInstances.downloadFailed"
          }),
          placement: ToastPlacements.BOTTOM_LEFT
        });
        // Sentry.captureException(error);
      });
  };

  // In case of documents matches, if a part of document was matched
  // we need to show first light of the match in search results (this is)
  // not case with workflows and fields
  const didDocumentContentMatch = result.document_data_match;
  const textToHighlight = (result.highlights[0] || "").replace(/<strong>/g, "");

  return (
    <ResultLayout
      workflow={result.workflow}
      breadcrumbProps={{
        highlights: result.highlights,
        parentToChildHierarchy: breadcrumbs
      }}
      rightIcon={
        <Download
          onClick={handleWorkflowFileDownload}
          color={TypographyColors.BRAND_500}
        />
      }
      type={result.type}
      footer={
        <Stack direction="vertical">
          {didDocumentContentMatch ? (
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
            >
              <SearchHighlighter
                textToHighlight={textToHighlight}
                highlights={result.highlights}
              />
            </Typography>
          ) : null}
          <Stack direction="horizontal" className={gapS1} align="center">
            <Stack
              direction="horizontal"
              className={gapS1WithAutoWidth}
              justify="space-between"
              gutter="s0 s0 s0 s0"
            >
              <Stack direction="horizontal">
                <Stack direction="horizontal" gutter="s0 s0 s0 s0">
                  <Typography
                    variant={TypographyVariants.LABEL_SM}
                    color={TypographyColors.NEUTRAL_600}
                  >
                    {result.body}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
    />
  );
};
