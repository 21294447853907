import { useRef } from "react";
import { Stack } from "@certa/blocks/thanos";
import { css } from "emotion";
import { AdvancedFilterCondition } from "./AdvancedFilterCondition/AdvancedFilterCondition";
import type {
  ReportCustomFilter,
  ReportCustomFilterCondition
} from "@certa/types";
import { CompareType, FieldTypes } from "@certa/types";
import { ConditionType } from "./ConditionType";
import {
  AppendGroupQuery,
  AppendQuery,
  RemoveIconButton
} from "./AdvancedFilterButtons";

import lodashSet from "lodash-es/set";
import lodashCloneDeep from "lodash-es/cloneDeep";
import { FormList } from "../FormList/FormList";
import type { HandleChange, RCFConditionProps } from "./AdvancedFilters.types";
import { useIntl } from "react-intl";
import {
  buildConditionAriaLabel,
  buildGroupAriaLabel,
  buildRemoveGroupConditionAriaLabel
} from "./AdvancedFilterGroup.utils";
import type { OperatorsDetails } from "../../utils/filterUtils";

export type AdvancedFilterGroupProps = {
  customFilterValue: ReportCustomFilter;
  isGroupQuery: boolean;
  onChange: (value: ReportCustomFilter) => void;
  onRemove?: () => void;
  parentPath?: (string | number)[];
  groupPathIndexes?: number[];
  customOperators?: OperatorsDetails;
  shouldAppendButtons?: boolean;
} & Pick<
  RCFConditionProps,
  | "cascaderOptions"
  | "disabledCascader"
  | "getFieldType"
  | "kindId"
  | "optionsIsLoading"
  | "isORMReport"
>;

export const AdvancedFilterGroup = (props: AdvancedFilterGroupProps) => {
  const {
    parentPath = [],
    groupPathIndexes = [0],
    getFieldType,
    cascaderOptions,
    disabledCascader: isDisabledCascader,
    optionsIsLoading: isLoadingOptions,
    onRemove,
    isORMReport,
    kindId,
    isGroupQuery,
    customFilterValue,
    onChange,
    customOperators,
    shouldAppendButtons = true
  } = props;
  const intl = useIntl();

  const conditionsActionRef = useRef({
    addCondition: (_: ReportCustomFilterCondition) => {},
    removeCondition: (_: number) => {}
  });

  const handleChange: HandleChange = (path, value) => {
    const newValue = lodashCloneDeep(customFilterValue);
    // lodashSet function is not type safe, ideally it should give error
    // if we pass wrong value based on given path
    lodashSet(newValue, path, value);
    onChange(newValue);
  };
  const isRootGroup = parentPath.length === 0;
  return (
    <Stack
      gap="s2"
      className={reportCustomFilterGroupStyle}
      role={isRootGroup ? "treegrid" : "gridcell"}
      aria-label={buildGroupAriaLabel(groupPathIndexes, intl)}
    >
      <Stack
        direction="vertical"
        gap="s00"
        gutter="s2"
        className={isGroupQuery ? "groupFields" : "simpleFields"}
      >
        <FormList<ReportCustomFilter, ReportCustomFilterCondition>
          parentPath={parentPath}
          fieldName="conditions"
          value={customFilterValue}
          onChange={onChange}
        >
          {(fields, { add, remove }) => {
            conditionsActionRef.current = {
              addCondition: add,
              removeCondition: remove
            };
            return (
              <Stack gap="s2" direction="vertical" role="rowgroup">
                {fields.map((field, index) => {
                  const conditionAriaLabel = buildConditionAriaLabel(
                    groupPathIndexes,
                    index,
                    intl
                  );
                  return (
                    <Stack
                      gap="s2"
                      key={field.key}
                      role="row"
                      aria-label={conditionAriaLabel}
                    >
                      {/* Only show "AND/OR" options in cases of isGroupQuery:true */}
                      {isGroupQuery && (
                        <ConditionType
                          customFilterValue={customFilterValue}
                          parentPath={parentPath}
                          conditionIndex={index}
                          onChange={handleChange}
                        />
                      )}
                      <AdvancedFilterCondition
                        cascaderOptions={cascaderOptions}
                        conditionIndex={field.key}
                        disabledCascader={isDisabledCascader}
                        getFieldType={getFieldType}
                        kindId={kindId}
                        onChange={handleChange}
                        optionsIsLoading={isLoadingOptions}
                        parentPath={[...parentPath, "conditions", field.key]}
                        remove={remove}
                        isORMReport={isORMReport}
                        value={field.fieldValue}
                        conditionAriaLabel={conditionAriaLabel}
                        isGroupQuery={isGroupQuery}
                        customOperators={customOperators}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            );
          }}
        </FormList>
        <FormList<ReportCustomFilter, ReportCustomFilter>
          parentPath={parentPath}
          fieldName="groups"
          value={customFilterValue}
          onChange={onChange}
        >
          {(fields, { add, remove }) => (
            <Stack gap="s2" direction="vertical" gutter="s2 s0" role="rowgroup">
              {fields.map((field, index) => (
                <Stack gap="s2" key={field.key} role="row">
                  <ConditionType
                    customFilterValue={customFilterValue}
                    parentPath={parentPath}
                    groupIndex={index}
                    onChange={handleChange}
                  />
                  <AdvancedFilterGroup
                    cascaderOptions={cascaderOptions}
                    customFilterValue={customFilterValue}
                    disabledCascader={isDisabledCascader}
                    getFieldType={getFieldType}
                    kindId={kindId}
                    onChange={onChange}
                    onRemove={() => remove(index)}
                    optionsIsLoading={isLoadingOptions}
                    parentPath={[...parentPath, "groups", index]}
                    groupPathIndexes={[...groupPathIndexes, field.key]}
                    isORMReport={isORMReport}
                    isGroupQuery={isGroupQuery}
                    customOperators={customOperators}
                  />
                </Stack>
              ))}
              <Stack
                gap="s2"
                className={shouldAppendButtons ? "append-buttons" : ""}
                role="gridcell"
              >
                <AppendQuery
                  onClick={() => {
                    conditionsActionRef.current.addCondition(initialCondition);
                  }}
                />
                {isGroupQuery && (
                  <AppendGroupQuery
                    onClick={() => add(initialGroupCondition)}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </FormList>
      </Stack>
      {!!onRemove && (
        <div>
          <RemoveIconButton
            onClick={onRemove}
            ariaLabel={buildRemoveGroupConditionAriaLabel(
              groupPathIndexes,
              intl
            )}
          />
        </div>
      )}
    </Stack>
  );
};

const initialCondition: ReportCustomFilterCondition = {
  field: { path: [], fieldType: FieldTypes.TEXT },
  operator: "eq",
  value: ""
};

const initialGroupCondition: ReportCustomFilter = {
  conditions: [initialCondition],
  groups: [],
  compareType: CompareType.AND
};

const reportCustomFilterGroupStyle = css({
  "& .groupFields": {
    border: "1px solid var(--neutral-50)",
    borderRadius: "var(--s1)"
  },
  /* Simple Query Needs to grow to full parent width */
  "& .simpleFields": {
    flexGrow: 1
  },
  "& .conditionTypeText": {
    minWidth: "90px",
    paddingLeft: "var(--s4)",
    paddingTop: "var(--s4)"
  },
  '& [class$="container"]': {
    minWidth: "90px"
  },
  "& .append-buttons": {
    marginLeft: "var(--s2)"
  }
});
