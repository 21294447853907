import type {
  CommentsObjectType,
  ObjectDetails
} from "@certa/queries/queries/comms";
import {
  getObjectDetailsKey,
  useMultipleObjectNamesFromKrypton
} from "@certa/queries/queries/comms";
import type { CommentObjectTypes } from "@certa/types";
import { useMemo } from "react";

// Define a union type that can handle both mentions and subscriptions
type CommsItem = {
  conversation: {
    objectType: CommentObjectTypes;
    objectId: number;
  };
};

/**
 * Custom hook to fetch and manage object details for a list of mentions or subscriptions
 * using a simple approach where we track loading states for individual items
 *
 * @param items - Array of mentions or subscriptions with object_type and object_id
 * @returns An object containing the object details map and loading/error states
 */
export const useObjectDetailsMap = (items: CommsItem[] | undefined) => {
  // Extract object types and IDs from the items
  const objectsToFetch = useMemo(() => {
    if (!items || items.length === 0) return undefined;

    return items.map(item => {
      // Get object type and ID from the conversation property
      const objectType = item.conversation.objectType;
      const objectId = String(item.conversation.objectId);

      return {
        objectType: objectType.toLowerCase() as CommentsObjectType,
        objectId
      };
    });
  }, [items]);

  // Fetch object details
  const {
    data: objectDetailsMap = {}, // Default to empty object
    isLoading,
    isError,
    error
  } = useMultipleObjectNamesFromKrypton(objectsToFetch);

  // Create a map of which items are currently loading
  const loadingItemsMap = useMemo(() => {
    if (!items || !objectsToFetch) return {};

    const loadingMap: Record<string, boolean> = {};

    // Initialize all items as loading
    objectsToFetch.forEach(({ objectType, objectId }) => {
      const key = getObjectDetailsKey(objectType, objectId);
      // An item is loading if it's not in the result map yet
      loadingMap[key] = !(key in objectDetailsMap);
    });

    return loadingMap;
  }, [items, objectsToFetch, objectDetailsMap]);

  // Helper function to get object details for a specific item
  const getObjectDetailsForItem = (item: CommsItem): ObjectDetails | null => {
    if (!item?.conversation) return null;

    const objectType = item.conversation.objectType;
    const objectId = String(item.conversation.objectId);

    const key = getObjectDetailsKey(
      objectType.toLowerCase() as CommentsObjectType,
      objectId
    );

    return objectDetailsMap[key] || null;
  };

  // Helper function to check if a specific item is loading
  const isItemLoading = (item: CommsItem): boolean => {
    if (!item?.conversation) return false;

    const objectType = item.conversation.objectType;
    const objectId = String(item.conversation.objectId);

    const key = getObjectDetailsKey(
      objectType.toLowerCase() as CommentsObjectType,
      objectId
    );

    return loadingItemsMap[key] || false;
  };

  return {
    objectDetailsMap,
    getObjectDetailsForItem,
    isItemLoading,
    isLoading,
    isError,
    error
  };
};
