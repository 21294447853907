//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Plus = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Plus"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 2C7.23558 2 6.61538 2.62019 6.61538 3.38462V6.61538H3.38462C2.62019 6.61538 2 7.23558 2 8C2 8.76442 2.62019 9.38462 3.38462 9.38462H6.61538V12.6154C6.61538 13.3798 7.23558 14 8 14C8.76442 14 9.38462 13.3798 9.38462 12.6154V9.38462H12.6154C13.3798 9.38462 14 8.76442 14 8C14 7.23558 13.3798 6.61538 12.6154 6.61538H9.38462V3.38462C9.38462 2.62019 8.76442 2 8 2Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
