import {
  useQuery,
  useInfiniteQuery,
  type QueryKey,
  type UseQueryOptions,
  type UseInfiniteQueryOptions
} from "react-query";
import {
  getGlobalSearchResults,
  getGlobalSearchResultsPaginated
} from "../services/globalSearch.services";
import type { UseQueryReturnType } from "@certa/common/types";
import type {
  ModelledAPIResponse,
  SearchFilters
} from "../types/globalSearch.types";
import { paginatedParams, queryClient } from "../utils/utils";
import type { ReportCustomFilterCondition } from "@certa/types";

type GlobalSearchResponse = {
  count: number;
  results: ModelledAPIResponse.SearchResult[];
  next: string | null;
  previous: string | null;
};

export const QUERY_KEY_PREFIX = "globalSearch";

export const useGlobalSearch = (
  query: string,
  selectedCategories: string[],
  config?: UseQueryOptions<UseQueryReturnType<typeof getGlobalSearchResults>>
) => {
  // We sort categories to ensure that the query key is consistent for
  // the same categories in different order
  const sortedCategories = selectedCategories.sort();
  return useQuery({
    ...config,
    queryKey: [QUERY_KEY_PREFIX, ...sortedCategories, query] as QueryKey,
    queryFn: () => getGlobalSearchResults(query, selectedCategories)
  });
};

type UseGlobalSearchInfiniteOptions = {
  query: string;
  selectedCategories: string[];
  filters?: SearchFilters;
  config?: UseInfiniteQueryOptions<GlobalSearchResponse>;
  advancedFilterConditions?: ReportCustomFilterCondition[];
};

export const useGlobalSearchInfinite = ({
  query,
  selectedCategories,
  filters,
  config,
  advancedFilterConditions
}: UseGlobalSearchInfiniteOptions) => {
  const sortedCategories = selectedCategories.sort();

  return useInfiniteQuery<GlobalSearchResponse>({
    ...paginatedParams,
    ...config,
    queryKey: [
      QUERY_KEY_PREFIX,
      ...sortedCategories,
      query,
      filters,
      advancedFilterConditions
    ],
    queryFn: ({ pageParam = 0 }) => {
      return getGlobalSearchResultsPaginated({
        query,
        selectedCategories,
        offset: pageParam,
        filters,
        advancedFilterConditions
      });
    }
  });
};

/**
 * Utility function to invalidate all global search queries
 * Call this when you want to force a refetch of search results
 */
export const invalidateGlobalSearch = () => {
  queryClient.invalidateQueries([QUERY_KEY_PREFIX]);
};
