//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Boolean = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Boolean"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3442)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.75203 0L3.24615 4.60077L1.08205 2.8911L0 4.32258L2.78016 6.51613L3.39621 7L9 1.27819L7.75203 0ZM11.1598 12.4957L9 10.3272L10.3272 9L12.4957 11.1598L14.6641 9L16 10.3272L13.8313 12.4957L16 14.6641L14.6641 16L12.4957 13.8313L10.3272 16L9 14.6641L11.1598 12.4957ZM1.9375 12.7518C1.6446 13.0447 1.6446 13.5196 1.9375 13.8125C2.23039 14.1054 2.70526 14.1054 2.99815 13.8125L13.875 2.93566C14.1679 2.64276 14.1679 2.16789 13.875 1.875C13.5821 1.5821 13.1072 1.5821 12.8143 1.87499L1.9375 12.7518Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3442">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
