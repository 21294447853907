import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";
import { ButtonVariants, Button } from "@certa/catalyst/components/Button";
import { useIntl } from "react-intl";

export const GetStoragePermission = ({
  askForPermission
}: {
  askForPermission: () => void;
}) => {
  const intl = useIntl();

  const redirectionInstructions = `${intl.formatMessage({
    id: "autoLogin.permissionsRequired.redirectionInstructions",
    defaultMessage: "Please follow below mentioned instructions"
  })}: `;

  const authenticationText = intl.formatMessage({
    id: "autoLogin.permissionsRequired.authenticationText",
    defaultMessage:
      "Click on authenticate. This will authenticate you and redirect automatically."
  });
  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      align="center"
      justify="center"
    >
      <Stack>
        <Text variant="h2-regular">{redirectionInstructions}</Text>
        <Text variant="h3-regular">{authenticationText}</Text>
      </Stack>
      <Button onClick={askForPermission} variant={ButtonVariants.FILLED}>
        {authenticationText}
      </Button>
    </Stack>
  );
};
