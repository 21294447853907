import { NumberInput } from "@certa/catalyst/components/Inputs";
import type { InputFieldProps } from "./ValueFieldInput.types";
import { useIntl } from "react-intl";

export const NumberInputField = ({
  value,
  disabled,
  onChange
}: InputFieldProps) => {
  const intl = useIntl();

  const placeholderText = intl.formatMessage({
    id: "common.typeAValue",
    defaultMessage: "Type a value..."
  });

  return (
    <NumberInput
      label=""
      disabled={disabled}
      placeholder={placeholderText}
      value={value === "" || Number.isNaN(value) ? undefined : Number(value)}
      onChange={value => onChange(value?.toString())}
      width="100%"
      allowClear={true}
    />
  );
};
