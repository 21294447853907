import { persistNextUrlOnSSO } from "@certa/common/utils/routes";
import { certaGoogleLoginURL } from "main/src/config";

/**
 * Used for re-direction to google login
 */
export const RedirectToCertaLogin = () => {
  persistNextUrlOnSSO();
  window.location.href = certaGoogleLoginURL;
  return null;
};
