import { css } from "emotion";
import { getAvatarLetters } from "@certa/blocks/thanos";
import moment from "moment";

import {
  AvatarColors,
  AvatarSizes,
  Avatar
} from "@certa/catalyst/components/Avatar";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { Tooltip } from "@certa/catalyst/components/Tooltip";

import { Stack } from "@certa/catalyst/layouts/Stack";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { notificationsListItemCustomStyles } from "./notificationsItem.styles";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { Loader } from "@certa/blocks";
import { MentionsPath } from "./MentionsPath";
import { useIntl } from "react-intl";
import { getUserMentionTag } from "@certa/common/utils/mentionTag";
import { userMentionRegEx } from "@certa/common/constants";
import { ABSOLUTE_DATE_TIME_FORMAT } from "@certa/common/utils/time";
import { UserDetailsTooltip } from "@certa/common/components/UserDetailsTooltip";
import { sanitizeHTML } from "@certa/common/utils/sanitizeHTML";
import { forwardRef, useImperativeHandle } from "react";
import type { TaskDetails } from "@certa/queries/types/workflow.types";
import type { QueryStatus } from "@certa/queries/types/common.types";
export type MentionItemHandle = {
  getDetails: () => {
    processDetails?: TaskDetails;
    processDetailsStatus: QueryStatus;
  };
};

type MentionItemProps = {
  mention: any; // TODO: assign proper type here
};

// This file is identical to MentionsItem.tsx.
// It uses comms endpoints tho instead of krypton.
// Once we are live with comms, we can remove the krypton version.
const MentionItemComms = forwardRef<MentionItemHandle, MentionItemProps>(
  ({ mention }, ref) => {
    const { id, conversation, task, field, isRead, postedBy, postedAt, body } =
      mention;

    const userDisplay = postedBy.fullName || postedBy.email;
    const intl = useIntl();

    const { data: processDetails, status: processDetailsStatus } =
      useProcessDetails(conversation?.kryptonWorkflowId);

    // Expose process details to parent component via imperative handle
    useImperativeHandle(
      ref,
      () => ({
        getDetails: () => {
          return {
            processDetails,
            processDetailsStatus
          };
        }
      }),
      [processDetails, processDetailsStatus]
    );

    /**
     * TODO: Add support to get the list of all mentions here and then
     * use useCommentMessageFormat hook to highlight the mentions
     *
     * NOTE: For now the mention of the logged in user will not be rendered
     * with different CSS. We need to get the data of all the mentions here first.
     */
    const transformedMessage = body
      .replace(userMentionRegEx, getUserMentionTag(false, "$1"))
      .replace(/\n/g, "<br />");

    const mentionedYouText = intl.formatMessage({
      id: "notifications.mentionedYou",
      defaultMessage: "mentioned you"
    });

    const avatarLetters = getAvatarLetters(postedBy.fullName || postedBy.email);
    const workflowType = processDetails?.definition.name;

    // TODO: fix this to use constants from dashboard package.
    // Currently using constants from dashboard package is giving error.
    // 'Can't use before initialisation'
    const isDashboardMention = workflowType === "report_dashboard";
    const isReportMention = workflowType === "report_workflow";

    return (
      <Stack
        key={`${id.$oid}`}
        role="list"
        direction="vertical"
        justify="center"
        align="start"
        gap="16"
        dangerouslySetClassName={notificationsListItemCustomStyles}
      >
        <Stack
          direction="horizontal"
          justify="space-between"
          align="center"
          width="100%"
        >
          {processDetailsStatus === "loading" ? (
            <Loader size="extra-small" />
          ) : (
            <MentionsPath
              workflowFamily={
                isDashboardMention || isReportMention
                  ? processDetails?.workflowFamily || []
                  : []
              }
              isDashboardMention={isDashboardMention}
              isReportMention={isReportMention}
              processName={processDetails?.name || ""}
              workflowType={workflowType}
              taskName={task?.taskName || null}
              fieldName={field?.name || null}
            />
          )}
          <Tooltip content={moment(postedAt).format(ABSOLUTE_DATE_TIME_FORMAT)}>
            <Typography
              color={TypographyColors.NEUTRAL_600}
              variant={TypographyVariants.LABEL_SM}
              className={css({
                width: "6rem",
                textAlign: "right"
              })}
            >
              {formatDistanceToNowStrict(parseISO(postedAt), {
                addSuffix: true
              })}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack direction="horizontal" align="center" gap="12" width="100%">
          <div>
            <UserDetailsTooltip placement="top" user={postedBy}>
              <div
                className={avatarWrapperClassName}
                aria-label={avatarLetters}
              >
                <Avatar
                  aria-label={userDisplay}
                  color={AvatarColors.DARK}
                  size={AvatarSizes.EXTRA_LARGE}
                >
                  {avatarLetters}
                </Avatar>
              </div>
            </UserDetailsTooltip>
          </div>
          <Stack width="100%">
            <Stack
              direction="horizontal"
              justify="space-between"
              align="center"
            >
              <Stack direction="horizontal" align="center" gap="4">
                {!isRead && (
                  <div
                    role="status"
                    aria-label="Unread mention"
                    className={css({
                      borderRadius: "50%",
                      width: "0.375rem",
                      height: "0.375rem",
                      backgroundColor: "var(--colors-neutral-800)"
                    })}
                  />
                )}
                <Typography
                  variant={TypographyVariants.LABEL_SM_BOLD}
                  color={TypographyColors.NEUTRAL_800}
                >
                  {userDisplay?.length > 20 ? (
                    <Tooltip content={userDisplay}>
                      <span>{userDisplay.substring(0, 20) + "..."}</span>
                    </Tooltip>
                  ) : (
                    userDisplay
                  )}
                  {"  "}
                  {mentionedYouText}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              color={TypographyColors.NEUTRAL_700}
              variant={TypographyVariants.LABEL_SM}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(
                    transformedMessage.length > 120
                      ? transformedMessage.slice(0, 120) + "..."
                      : transformedMessage
                  )
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

export { MentionItemComms };

const avatarWrapperClassName = css({
  height: "min-content",
  marginTop: "0.125rem",
  "&:focus": {
    outline: "2px solid var(--colors-brand-400)",
    outlineOffset: "2px",
    borderRadius: "50%"
  }
});
