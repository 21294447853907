import { APIFetchComms, tenant } from "@certa/network";
import { useInfiniteQuery } from "react-query";
import type { QueryFunctionContext } from "react-query";
import { getCommsAuthToken } from "./getCommsAuthToken.query";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import type { CommentObjectTypes } from "@certa/types";

export type SubscriptionComms = {
  id: string;
  kryptonId: number;
  conversationId: string;
  conversation: {
    kryptonWorkflowId: number;
    name: string;
    objectId: number;
    objectType: CommentObjectTypes;
    kryptonId: number;
  };
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
    groups: {
      id: number;
      name: string;
    }[];
  };
  subscribedAt?: string;
  unsubscribedAt?: string;
  subscriptionStatus: number;
};

export type UserSubscriptionCommsResponse = {
  _id: {
    $oid: string;
  };
  krypton_id: number;
  conversation_id: {
    $oid: string;
  };
  conversation: {
    krypton_workflow_id: number;
    name: string;
    object_id: number;
    object_type: CommentObjectTypes;
    krypton_id: number;
  };
  user_id: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
    groups: {
      id: number;
      name: string;
    }[];
  };
  subscribed_at: {
    $date: string;
  };
  unsubscribed_at: {
    $date: string;
  };
  subscription_status: number;
};

export type SubscriptionListComms = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SubscriptionComms[];
};

export type SubscriptionListCommsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserSubscriptionCommsResponse[];
};

const getSubscriptionsComms = async ({
  pageParam = 1
}: QueryFunctionContext): Promise<SubscriptionListComms> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "x-tenant": tenant,
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  return APIFetchComms<SubscriptionListCommsResponse>(
    `subscription?page=${pageParam}`,
    requestOptions
  ).then(subscriptionsListModelCreator);
};

export const SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY = "subscriptions-list-comms";

const useGetSubscriptionsCommsInfiniteQuery = () => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const commsToken = getCommsAuthToken(!!isCommsNewEndpointEnabled);
  return useInfiniteQuery(
    SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY,
    getSubscriptionsComms,
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.next) {
          return allPages.length + 1;
        }
        return undefined;
      },
      enabled: isCommsNewEndpointEnabled && !!commsToken
    }
  );
};

const convertSubscriptionCommsResponseToCamelCase = (
  response: UserSubscriptionCommsResponse
): SubscriptionComms => {
  return {
    id: response._id.$oid,
    kryptonId: response.krypton_id,
    conversationId: response.conversation_id.$oid,
    conversation: {
      kryptonWorkflowId: response.conversation.krypton_workflow_id,
      name: response.conversation.name,
      objectId: response.conversation.object_id,
      objectType: response.conversation.object_type,
      kryptonId: response.conversation.krypton_id
    },
    user: {
      id: response.user_id.id,
      firstName: response.user_id.first_name,
      lastName: response.user_id.last_name,
      email: response.user_id.email,
      fullName: response.user_id.full_name,
      groups: response.user_id.groups
    },
    subscriptionStatus: response.subscription_status
  };
};

const convertSubscriptionListCommsResponseToCamelCase = (
  response: SubscriptionListCommsResponse
): SubscriptionListComms => {
  return {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results: response.results.map(item =>
      convertSubscriptionCommsResponseToCamelCase(item)
    )
  };
};

const subscriptionsListModelCreator = (
  response: SubscriptionListCommsResponse
): SubscriptionListComms => {
  return convertSubscriptionListCommsResponseToCamelCase(response);
};

export { useGetSubscriptionsCommsInfiniteQuery };
