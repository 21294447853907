import { get as lodashGet } from "lodash-es";
import { useAppSelector } from "../hooks";

// This toggle is only for development purposes, will be removed when we release the new global search
export const useEnableGlobalSearchNext = () => {
  return useAppSelector(
    state =>
      !lodashGet(state, "config.custom_ui_labels.specialFeatures", []).includes(
        "DISABLE_GLOBAL_SEARCH_NEXT"
      )
  );
};
