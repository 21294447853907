import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";
import { RequestHeaders } from "@certa/network/src/headers";
import { APIFetchV2 } from "@certa/network";
import { getWorkflowUniqKey } from "../../utils/workflow.utils";
import type { UseQueryReturnType } from "@certa/common/types/reactQueryGeneric.types";
import type { WorkflowItemDetailsResponse } from "../../types/workflow.types";
import { taskDetailsModelCreator } from "../../models/workflow.model";

export const getWorkflowDetails = async (
  workflowId: number | null | undefined
) => {
  if (!workflowId) throw new Error("Workflow ID is required");

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const detailsURL = `workflows/?id=${workflowId}`;
  const response = await APIFetchV2<WorkflowItemDetailsResponse>(
    detailsURL,
    requestOptions
  );
  return taskDetailsModelCreator(response);
};

export const getWorkflowDetailsQuery = (
  workflowId: number | null | undefined
): UseQueryOptions<UseQueryReturnType<typeof getWorkflowDetails>> => {
  return {
    queryKey: getWorkflowUniqKey(workflowId),
    queryFn: () => getWorkflowDetails(workflowId),
    enabled: workflowId !== null && workflowId !== undefined,
    retry: 0
  };
};

export const useGetWorkflowDetails = (
  workflowId: number | null | undefined,
  config?: UseQueryOptions<UseQueryReturnType<typeof getWorkflowDetails>>
) =>
  useQuery<UseQueryReturnType<typeof getWorkflowDetails>>({
    ...getWorkflowDetailsQuery(workflowId),
    ...config
  });
