//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Reduce = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Reduce"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3463)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.0135 1.06684V14.9332C16.0135 15.5225 15.5333 16 14.9407 16C14.4605 16 13.8679 15.5225 13.8679 14.9332V1.06684C13.8679 0.477485 14.3481 0 14.9407 0C15.4208 0 16.0135 0.477485 16.0135 1.06684ZM5.72005 12.5565C6.165 12.1127 6.16562 11.394 5.72005 10.9496L3.91137 9.14655H11.2291C11.8582 9.14655 12.3679 8.63844 12.3679 8.01096C12.3679 7.38348 11.8576 6.87475 11.2291 6.87475H3.89061L5.72005 5.05114C6.165 4.6074 6.16562 3.88805 5.72005 3.44433C5.27568 3.00122 4.5542 3.0006 4.10926 3.44433L0.0771236 7.46416C-0.219519 7.76039 -0.220146 8.24037 0.0771236 8.53662L4.10926 12.5565C4.55425 13.0002 5.2751 13.0002 5.72005 12.5565Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3463">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
