//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Cascader = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Cascader"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4.5H14C14.2761 4.5 14.5 4.72386 14.5 5V11C14.5 11.2761 14.2761 11.5 14 11.5H2C1.72386 11.5 1.5 11.2761 1.5 11V5C1.5 4.72386 1.72386 4.5 2 4.5ZM0 5C0 3.89543 0.895431 3 2 3H14C15.1046 3 16 3.89543 16 5V11C16 12.1046 15.1046 13 14 13H2C0.895431 13 0 12.1046 0 11V5ZM9.96905 10.3388L11.7409 8.61059V8.61064C11.9067 8.44828 12 8.2283 12 7.99914C12 7.76977 11.9067 7.54999 11.7409 7.38764L9.98483 5.67277C9.78543 5.47214 9.46625 5.44264 9.23178 5.60322C9.09842 5.69915 9.01432 5.84703 9.00171 6.00821C8.98889 6.16938 9.04876 6.32801 9.1657 6.44269L10.761 7.99912L9.18113 9.53995C8.97549 9.7345 8.94525 10.0461 9.10984 10.2749C9.20815 10.4046 9.35953 10.4861 9.52453 10.4984C9.68932 10.5107 9.85151 10.4525 9.96905 10.3388Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
