import { css } from "emotion";
import { Stack } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import type { SidebarAlert } from "@certa/types";
import type { ReactElement } from "react";
import { Popover } from "@certa/catalyst/components/Popover";
import { TagVariants, Tag } from "@certa/catalyst/components/Tag";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";

type AlertTooltipProps = {
  children: ReactElement;
  alerts: SidebarAlert[];
};

export const AlertTooltip = ({ children, alerts }: AlertTooltipProps) => {
  const intl = useIntl();
  const alertLabel = intl.formatMessage({
    id: "processDetail.fieldAlert"
  });

  const alertTags = alerts.map(alert => alert.tag).join(", ");
  const ariaDescription = `${alertLabel}: ${alertTags}`;

  return (
    <Popover
      ariaDescription={ariaDescription}
      content={
        <Stack
          direction="vertical"
          gap="s2"
          align="start"
          className={css`
            max-width: 18.75rem;
            min-width: 15.625rem;
            padding: var(--s4);
          `}
        >
          <Typography
            as="span"
            color={TypographyColors.NEUTRAL_600}
            variant={TypographyVariants.LABEL_SM_BOLD}
          >
            {alertLabel}
          </Typography>
          <Stack
            className={css`
              flex-wrap: wrap;
              gap: 0.5rem;
              max-height: 18.75rem;
              overflow-y: auto;
            `}
          >
            {alerts.map(alert => (
              <Tag
                key={alert.id}
                label={alert.tag}
                multiline
                variant={TagVariants.FILLED}
                backgroundColor={alert.label}
              >
                {alert.tag}
              </Tag>
            ))}
          </Stack>
        </Stack>
      }
      trigger="hover"
      showArrow
    >
      {children}
    </Popover>
  );
};
