import { APIFetchComms, tenant } from "@certa/network";
import { useQuery } from "react-query";
import { useCommsNewEndpoints } from "@certa/common/toggles";

type UnreadMentionCountResponse = {
  total: number;
};

const getUnreadMentionCountComms = async () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "x-tenant": tenant,
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  return APIFetchComms<UnreadMentionCountResponse>(
    `message/unread_mentions_count`,
    requestOptions
  ).then(response => response?.total || 0);
};

const useUnreadMentionsCountComms = () => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  return useQuery<number>(
    "getUnreadMentionsCountComms",
    getUnreadMentionCountComms,
    {
      refetchOnWindowFocus: false,
      enabled: isCommsNewEndpointEnabled
    }
  );
};

export { useUnreadMentionsCountComms };
