//TODO: Change the file name and const names when all the depdencies of previous Theme.tsx are replaced
import type { FC, HTMLProps, ReactNode } from "react";
import React, { useLayoutEffect, useContext } from "react";
import { css, cx } from "emotion";
import {
  BRAND_COLORS,
  APP_COLORS,
  TYPOGRAPHY_VARIANTS,
  Z_INDEX
} from "../constants";
import color from "color";
import { useConfigBrandColor } from "main/src/modules/common/hooks/useConfigBrandColor";

export const CSSVariablesClassName = css`
  /************************** Thanos Design System *************************/

  /* Spaces */
  --ratio: 1.5;
  --multiple: 4px;
  --s00: 0px;
  --s0: 1px;
  --s1: 4px;
  --s2: 8px;
  --s3: 12px;
  --s4: 16px;
  --s5: 20px;
  --s6: 24px;
  --s7: 28px;
  --s8: 32px;
  --s9: 36px;
  --s10: 40px;
  --s11: 44px;
  --s12: 48px;
  --s13: 52px;
  --s14: 56px;
  --s15: 60px;
  --s16: 64px;
  --s17: 68px;
  --s18: 72px;
  --s19: 76px;
  --s20: 80px;

  --n-s1: -4px;
  --n-s2: -8px;
  --n-s4: -16px;
  --n-s6: -24px;

  /* Breakpoints */
  --min-width: 900px;

  /* Border radius */
  --small-border-radius: 4px;
  --normal-border-radius: 6px;
  --big-border-radius: 8px;

  /* Elevations */
  --popover-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);

  /* Font Family */
  --font-family: "Inter", sans-serif, "system-ui";

  /* Colors */
  ${Object.entries(APP_COLORS)
    .map(([colorName, hexcode]) => `--${colorName}: ${hexcode};`)
    .join("")}

  /* Typography Variants */
  ${Object.entries(TYPOGRAPHY_VARIANTS)
    .map(
      ([fontVariant, value]) => `
        --${fontVariant}-font-size: ${value.fontSize};
        --${fontVariant}-line-height: ${value.lineHeight};
        --${fontVariant}-font-weight: ${value.fontWeight};
        ${
          value.fontStyle
            ? `--${fontVariant}-font-style: ${value.fontStyle};`
            : ""
        }
        ${
          value.textTransform
            ? `--${fontVariant}-text-transform: ${value.textTransform};`
            : ""
        }
      `
    )
    .join("")}

  /* z-index */
  ${Object.entries(Z_INDEX)
    .map(([name, value]) => `--${name}: ${value};`)
    .join("")}

  /**************** Claudiu Design System ***************/
  /*************** DEPRECATED - DO NOT USE **************/

  /** Font Sizes and line height */
  --h1: 48px;
  --h1-line-height: 56px;

  --h2: 32px;
  --h2-line-height: 40px;

  --h3: 24px;
  --h3-line-height: 32px;

  --h4: 20px;
  --h4-line-height: 28px;

  --h5: 18px;
  --h5-line-height: 22px;

  --p1: 16px;
  --p1-line-height: 24px;

  --p2: 14px;
  --p2-line-height: 20px;

  --p3: 12px;
  --p3-line-height: 16px;

  --p4: 10px;
  --p4-line-height: 12px;

  /** Font Weights */
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  --bolder: 800;

  --label: CSSVariables;
`;

export const normalizeCSSClass = css`
  /* TODO: This is more specific than blocks CSS */
  & blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  p,
  pre,
  ul,
  ol {
    margin: 0;
  }

  a {
    color: var(--brand-15);
    font-size: var(--p1-medium-font-size);
    line-height: var(--p1-medium-line-height);
    font-weight: var(--p1-medium-font-weight);
    &:hover {
      color: var(--brand);
      text-decoration: none;
    }
  }

  * {
    font-family: var(--font-family);
  }

  /****** Scrollbar Styling Start ******/

  * ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  * ::-webkit-scrollbar-track {
    border-radius: 42px;
    box-shadow: inset 0 0 8px 8px #f4f5f5;
    border: solid 2px transparent;
  }

  * ::-webkit-scrollbar-thumb {
    border-radius: 42px;
    box-shadow: inset 0 0 8px 8px var(--neutral-500);
    border: solid 2px transparent;
  }
  * ::-webkit-scrollbar-thumb:hover {
    border-radius: 42px;
    box-shadow: inset 0 0 8px 8px var(--neutral-600);
    border: solid 2px transparent;
  }

  /****** Scrollbar Styling End ******/

  /* Responsive Font Size */
  font-size: var(--p1-regular-font-size);
  line-height: var(--p1-regular-line-height);
  color: var(--neutral-100);

  @media print {
    .hide-print {
      display: none;
    }
  }

  --label: Normalizer;
`;

/**
 * Reason of adding this is to add styles to overlays mounted on LAYOUT_ID
 */
export const globalCSSClass = css`
  // The table component mounts the filter dropdown to LAYOUT_ID to which we need to add CSS
  .ant-table-filter-dropdown {
    box-shadow: var(--popover-shadow);
    border-radius: var(--big-border-radius);
  }
`;

type DesignThemeContextType = {
  disableConfigBrandColors: boolean;
};
export const DesignThemeContext = React.createContext<DesignThemeContextType>({
  disableConfigBrandColors: false
});
export const DesignThemeContextProvider: FC<{
  value: DesignThemeContextType;
  children: ReactNode;
}> = ({ value, ...props }) => {
  return (
    <DesignThemeContext.Provider value={value}>
      {props.children}
    </DesignThemeContext.Provider>
  );
};

/**
 * Container which provides children elements access to the CSS variables of the design system
 */
export const DesignTheme: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...restProps
}) => {
  const configBrandColor = useConfigBrandColor();
  const { disableConfigBrandColors } = useContext(DesignThemeContext);

  // There are few components from blocks (DS 2.0) package used inside the catalyst drawer, Dialog.
  // Since catalyst drawer, Dialog are rendered outside of this DesignTheme component,
  // we had to set the DS 2.0 CSS color variables on the root element.
  useLayoutEffect(() => {
    const brandColors = disableConfigBrandColors
      ? BRAND_COLORS
      : getBrandColors(configBrandColor);

    const rootElement = document.querySelector(":root") as HTMLElement;
    Object.entries(brandColors).forEach(([colorName, hexcode]) => {
      rootElement?.style.setProperty(`--${colorName}`, hexcode);
    });
  }, [configBrandColor, disableConfigBrandColors]);

  return (
    <div className={cx(CSSVariablesClassName, className)} {...restProps}>
      {children}
    </div>
  );
};

export function getBrandColors(configBrandColor?: string) {
  if (configBrandColor) {
    const colorObj = color(configBrandColor);
    const hslColorObj: any = colorObj.hsl(); // type issue
    const colorLightness = hslColorObj.color[2] / 100;
    return {
      "brand-d10": colorObj
        .lightness((colorLightness - colorLightness * 0.1) * 100) // decrease lightness by 10%
        .hex(),
      "brand-d15": colorObj
        .lightness((colorLightness - colorLightness * 0.15) * 100) // decrease lightness by 15%
        .hex(),
      brand: configBrandColor,
      "brand-15": colorObj
        .lightness((colorLightness + (1 - colorLightness) * 0.15) * 100) // increase lightness by 15% on lighter side
        .hex(),
      "brand-35": colorObj
        .lightness((colorLightness + (1 - colorLightness) * 0.8) * 100)
        .hex()
      // increase lightness by 80% on lighter side works better than 35% for proper contrast. Will need to check contrast for better contrast
    };
  }
  return BRAND_COLORS;
}
