import React, { useLayoutEffect } from "react";

type CatalystTheme = {
  colors?: {
    "brand-50": string;
    "brand-100": string;
    "brand-200": string;
    "brand-300": string;
    "brand-400": string;
    "brand-500": string;
    "brand-600": string;
  } | null;
};

type CatalystProviderProps = {
  theme: CatalystTheme;
  children: React.ReactNode;
};

const CatalystBrandNeutrals = {
  "brand-neutral-50": "#f8f9f9",
  "brand-neutral-100": "#f4f5f5",
  "brand-neutral-200": "#d0d4df",
  "brand-neutral-300": "#8892ac",
  "brand-neutral-400": "#00164e",
  "brand-neutral-500": "#00164e",
  "brand-neutral-600": "#00164e"
};

export const CatalystProvider = ({
  theme,
  children
}: CatalystProviderProps) => {
  useLayoutEffect(() => {
    if (theme.colors) {
      const rootElement = document.querySelector(":root") as HTMLElement;
      Object.entries(theme.colors).forEach(([key, value]) => {
        rootElement?.style.setProperty(`--colors-${key}`, value);
      });

      Object.entries(CatalystBrandNeutrals).forEach(([key, value]) => {
        rootElement?.style.setProperty(`--colors-${key}`, value);
      });
    }
  }, [theme.colors]);

  return <>{children}</>;
};
