//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const AddUser = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="AddUser"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.19508 16H6.02833H6.86159C8.80025 16 10.4516 15.938 11.3277 14.9894C11.2194 14.9964 11.1101 15 11 15C8.23858 15 6 12.7614 6 10C6 7.73749 7.50275 5.82597 9.56457 5.2091C9.73836 4.77008 9.83326 4.29313 9.83326 3.80049C9.83326 1.70718 8.13852 0 6 0C3.88864 0 2.16674 1.68024 2.16674 3.80049C2.16674 5.28709 3.02822 6.58236 4.24988 7.18791C1.80544 7.93121 0 10.189 0 12.8612C0.056417 15.8623 2.30665 16 5.1948 16H5.19508ZM10.3077 7.69231C10.3077 7.3101 10.6178 7 11 7C11.3822 7 11.6923 7.3101 11.6923 7.69231V9.30769H13.3077C13.6899 9.30769 14 9.61779 14 10C14 10.3822 13.6899 10.6923 13.3077 10.6923H11.6923V12.3077C11.6923 12.6899 11.3822 13 11 13C10.6178 13 10.3077 12.6899 10.3077 12.3077V10.6923H8.69231C8.3101 10.6923 8 10.3822 8 10C8 9.61779 8.3101 9.30769 8.69231 9.30769H10.3077V7.69231Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
