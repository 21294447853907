//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ArrowLeftLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ArrowLeftLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1.15778 7.52321L4.53726 3.27125C4.81732 2.94688 5.2652 2.91561 5.55941 3.16573C5.85362 3.41585 5.90264 3.91998 5.66125 4.22482L3.25487 7.24971H14.2758C14.6756 7.24971 15 7.58582 15 8.00006C15 8.4143 14.6756 8.7504 14.2758 8.7504H3.25487L5.66125 11.7753C5.90264 12.0801 5.84795 12.5774 5.55941 12.8344C5.26144 13.0992 4.77771 13.0337 4.53726 12.7289L1.15778 8.47691C0.93808 8.15646 0.95694 7.83306 1.15778 7.52334V7.52321Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
