import { RequestHeaders, APIFetchV2, APIFetchV3 } from "@certa/network";
import type {
  ChildWorkflow,
  ConsolidatedTablularWorkflows
} from "@certa/types";
import {
  createChildWorkflowModel,
  createPackagedWorkflowModel,
  createTestCaseWorkflowModel,
  createChildWorkflowPaginatedModel,
  createWorkflowFieldModel
} from "../models/childWorkflow.model";
import { createIntegrationRowsModel } from "../models/integration.model";
import type {
  ChildWorkflowServicePaginatedModel,
  ChildWorkflowServicePaginatedResponse,
  GetChildWorkflowServiceParams,
  GetIntegrationRowsParams,
  GetIntegrationRowsResponse,
  GetPackagedWorkflowServiceParams,
  GetTestCaseWorkflowServiceParams
} from "../types/readonly.types";
import queryString from "query-string";
import type {
  UseGetWorkflowFields,
  WorkflowFieldDynamicAPIDataPayload,
  WorkflowFieldDynamicAPIParams,
  WorkflowFieldDynamicAPIResults
} from "../types/childWorkflow.types";

// TODO: To be eventually moved out this module and place somewhere common
// as it contains all the services that we need to work with workflows on
// WorkflowDetails
export const readonlyTableServices = {
  getChildWorkflows,
  getConsolidatedWorkflows,
  getIntegrationRows,
  getPackagedWorkflows,
  getTestCaseWorkflows,
  getChildWorkflowsPaginated
};

/**
 * Calls the API to retrive the child workflow list based on a specific parent
 * or root and a particular workflow kind ID.
 * @param {GetChildWorkflowServiceParams} params
 * @returns {Promise<ChildWorkflow[]>} array of model is returned if the API is successful.
 */
function getChildWorkflows(
  params: GetChildWorkflowServiceParams
): Promise<ChildWorkflow[]> {
  const { kind, ...otherParams } = params;
  const urlParam = queryString.stringify(otherParams);

  // if (!otherParams.parents && !otherParams.root) {
  //   const e = new Error(`Missing root or parents: kind: ${kind}`);
  //   Sentry.captureException(e);
  // }

  const url = `workflows/tabular/${kind}/?${urlParam}`;

  // Show loader only if the silent has falsy value
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(url, requestOptions).then(response =>
    createChildWorkflowModel(response as any[])
  );
}

export const getWorkflowsFields = (
  params: WorkflowFieldDynamicAPIParams
): Promise<UseGetWorkflowFields[]> => {
  const { kind, parents, fieldTags, idIn } = params;

  const url = `workflows-list-drest/?limit=none`;
  const payload: WorkflowFieldDynamicAPIDataPayload = {
    data: {
      id: null,
      fields: {
        _filters: {
          field_tags: fieldTags ?? []
        },
        id: null,
        definition: {
          id: null,
          tag: null,
          field_type: null
        },
        answers: {
          answer: null
        },
        extra: null,
        integration_json: null
      },
      parents: {
        id: null
      }
    },
    filters: {
      kind_id__in: kind
    }
  };
  if (parents) {
    payload.filters["parent_id"] = parents;
  }
  if (idIn?.length) {
    payload.filters["id__in"] = idIn.join(",");
  }

  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV3<WorkflowFieldDynamicAPIResults>(url, requestOptions).then(
    response => createWorkflowFieldModel(response)
  );
};

/**
 * Calls the API to retrieve the child workflow list based on a specific parent
 * or root and a particular workflow kind ID in a paginated manner.
 * @param {GetChildWorkflowServicePaginatedParams} params
 * @returns {Promise<ChildWorkflowServicePaginatedModel>} object model containing array of child workflows, total count, previous URL, and next UL is returned if the API is successful.
 */
function getChildWorkflowsPaginated(
  params: GetChildWorkflowServiceParams,
  offset: number
): Promise<ChildWorkflowServicePaginatedModel> {
  const { kind, ...otherParams } = params;
  const urlParams = queryString.stringify(otherParams);

  const url = `workflows/tabular-paginated/${kind}/?${urlParams}&limit=100&offset=${
    offset || 0
  }`;

  // Show loader only if the silent has falsy value
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(url, requestOptions).then(response =>
    createChildWorkflowPaginatedModel(
      response as ChildWorkflowServicePaginatedResponse
    )
  );
}

function getConsolidatedWorkflows(
  tableFieldId: number
): Promise<ConsolidatedTablularWorkflows> {
  const url = `workflows/tabular-consolidated/${tableFieldId}`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(url, requestOptions).then(
    (response: any) => response?.data as ConsolidatedTablularWorkflows
  );
}

/**
 * Calls the API to retrieve the child workflow list based on a specific parent
 * or root and a particular workflow kind ID.
 * @param {GetPackagedWorkflowServiceParams} params
 * @returns {Promise<ChildWorkflow[]>} array of model is returned if the API is successful.
 */
function getPackagedWorkflows(
  payload: GetPackagedWorkflowServiceParams
): Promise<ChildWorkflow[]> {
  const url = `related_app_builders/?package_id=${payload.packageId}`;

  // Show loader only if the silent has falsy value
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(url, requestOptions).then(response =>
    createPackagedWorkflowModel(response as any[])
  );
}

/**
 * Calls the API to retrieve the test case workflow list based on a specific parent
 * or root and a particular workflow kind ID.
 * @param {GetTestCaseWorkflowServiceParams} params
 * @returns {Promise<ChildWorkflow[]>} array of model is returned if the API is successful.
 */
async function getTestCaseWorkflows(
  payload: GetTestCaseWorkflowServiceParams
): Promise<ChildWorkflow[]> {
  const url = `related_test_cases/?package_version_id=${payload.packageVersionId}`;

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const response = await APIFetchV2(url, requestOptions);
  return createTestCaseWorkflowModel(response as any[]);
}

function getIntegrationRows(
  params: GetIntegrationRowsParams
): Promise<GetIntegrationRowsResponse> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const url = `workflows/${params.workflowId}/fields/${params.fieldTag}/integrationresults/tabular/`;
  // @ts-expect-error TS5 upgrade
  return APIFetchV2(url, requestOptions).then((response: any) => {
    const data = createIntegrationRowsModel(response?.results || []);
    return data;
  });
}
