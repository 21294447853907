import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";
import { APIFetchV2 } from "@certa/network/src/fetch";
import { stepGroupModelCreator } from "../../models/workflow.model";
import { RequestHeaders } from "@certa/network/src/headers";

import type { UseQueryReturnType } from "@certa/common/types/reactQueryGeneric.types";

function getSwimlaneList(workflowId: number | undefined | null) {
  if (!workflowId) {
    throw new Error("Workflow ID is required");
  }

  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(`workflows/${workflowId}/stepgroups/`, requestOptions).then(
    stepGroupModelCreator
  );
}

export const getSwimlaneListQuery = (
  workflowId: number | null | undefined
): UseQueryOptions<UseQueryReturnType<typeof getSwimlaneList>> => {
  return {
    queryKey: ["stepGroups", workflowId],
    queryFn: () => getSwimlaneList(workflowId),
    enabled: workflowId !== null && workflowId !== undefined
  };
};

export const useGetSwimlaneList = (
  workflowId: number | null | undefined,
  config?: UseQueryOptions<UseQueryReturnType<typeof getSwimlaneList>>
) =>
  useQuery<UseQueryReturnType<typeof getSwimlaneList>>({
    ...getSwimlaneListQuery(workflowId),
    ...config
  });
