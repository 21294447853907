import type { Table, HeaderGroup } from "@tanstack/react-table";
import type {
  DataCellFormatter,
  DataPlusUndefined,
  ColumnSubTotalAndAvg,
  ColumnDefinition
} from "../../types";
import {
  SortableContext,
  horizontalListSortingStrategy
} from "@dnd-kit/sortable";
import { flexRender } from "@tanstack/react-table";
import { createTableStyles } from "./HeatMapTable.styles";

// Components
import { Summary } from "./Summary";
import { DraggableHeader } from "./DraggableHeader";
import { shouldShowHeader } from "../../utils/HeatMapUtils";

type HeatMapTableProps = {
  table: Table<DataPlusUndefined>;
  yAxisKeys: string[];
  xAxisKeys: string[];
  cellBaseColors?: string[];
  minValue: number;
  minMaxDiff: number;
  dataCellFormatter: DataCellFormatter;
  columnSubTotalAndAvg: ColumnSubTotalAndAvg | {}[];
  dataColumns: ColumnDefinition[];
  columnOrder: string[];
  onColumnOrderChange?: (columnOrder: string[]) => void;
};

export const HeatMapTable = ({
  table,
  yAxisKeys,
  xAxisKeys,
  cellBaseColors,
  minValue,
  minMaxDiff,
  dataCellFormatter,
  columnSubTotalAndAvg,
  dataColumns,
  columnOrder,
  onColumnOrderChange
}: HeatMapTableProps) => {
  // Filter out header groups which span multiple rows to avoid react-table for rendering them multiple times for the same row
  const filterHeaderGroups = (
    headerGroups: HeaderGroup<DataPlusUndefined>[],
    yAxisKeys: string[],
    xAxisKeys: string[]
  ) => {
    return headerGroups.map(headerGroup => ({
      ...headerGroup,
      headers: headerGroup.headers.filter(header =>
        shouldShowHeader(
          header.column.id,
          headerGroup.depth,
          yAxisKeys,
          xAxisKeys
        )
      )
    }));
  };

  return (
    <table className={createTableStyles(yAxisKeys.length)}>
      <thead>
        {filterHeaderGroups(table.getHeaderGroups(), yAxisKeys, xAxisKeys).map(
          headerGroup => (
            <tr key={headerGroup.id}>
              <SortableContext
                items={columnOrder ?? []}
                strategy={horizontalListSortingStrategy}
              >
                {headerGroup.headers.map(header => (
                  <DraggableHeader
                    key={header.id}
                    header={header}
                    allowDragging={!!onColumnOrderChange}
                  />
                ))}
              </SortableContext>
            </tr>
          )
        )}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} style={{ width: cell.column.getSize() }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
        <Summary
          cellBaseColors={cellBaseColors}
          lastRowIndex={table.getRowModel().rows.length - 1}
          minValue={minValue}
          minMaxDiff={minMaxDiff}
          yAxisKeys={yAxisKeys}
          dataCellFormatter={dataCellFormatter}
          columnSubTotalAndAvg={columnSubTotalAndAvg}
          dataColumns={dataColumns}
          columnOrder={columnOrder}
        />
      </tbody>
    </table>
  );
};
