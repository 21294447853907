import { useState, useEffect, useCallback, forwardRef, useRef } from "react";
import { ChevronDown } from "@certa/icons/components/ChevronDown";
import { ChevronUp } from "@certa/icons/components/ChevronUp";
import { Clear } from "@certa/icons/components/Clear";

import { classNames, filterProperties, mergeRefs } from "../../../utils/common";
import type { NumberInputContainerStyle } from "../../types";
import { FieldStatus } from "../../types";

import styles from "./Number.module.css";
import { NUMBER_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES } from "../../../constants/styles";

export enum NumberInputSizes {
  DEFAULT = "Default",
  SMALL = "Small"
}

export type NumberInputStyleConfig = {
  container?: NumberInputContainerStyle;
};

type NumberStyleAllowedPropertiesType = {
  container: (keyof NumberInputContainerStyle)[];
};

const NumberStyleAllowedProperties: NumberStyleAllowedPropertiesType = {
  container: NUMBER_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES
};

export type NumberInputProps = {
  id?: string;
  label: string;
  "aria-describedby"?: string;
  disabled?: boolean;
  required?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
  /**
   * If true, the input will only allow integer values.
   * @default false
   */
  isInteger?: boolean;
  autoComplete?: "on" | "off";
  value?: number | null;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  size?: NumberInputSizes;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  width?: string;
  error?: string;
  status?: FieldStatus;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onChange?: (
    value?: number,
    evt?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (value?: number, evt?: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: (evt: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  styleConfig?: NumberInputStyleConfig;
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const {
      id,
      label,
      "aria-describedby": ariaDescribedBy,
      value = "",
      onClick,
      onChange,
      onFocus,
      onBlur,
      onPaste,
      onKeyDown,
      onKeyUp,
      onKeyPress,
      disabled: isDisabled = false,
      required: isRequired = false,
      allowClear: shouldAllowClear = false,
      isInteger = false,
      autoComplete,
      autoFocus: shouldAutoFocus,
      placeholder,
      size = NumberInputSizes.DEFAULT,
      leftIcon,
      width,
      status,
      error,
      min,
      max,
      step = 1,
      styleConfig = {}
    } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const numberInputRef = useRef<HTMLInputElement>();

    const [hasValue, setHasValue] = useState(!!value);

    const { focusedBorder: containerFocusedBorder = "", ...containerStyles } =
      styleConfig?.container
        ? filterProperties(
            styleConfig.container,
            NumberStyleAllowedProperties.container
          )
        : {};
    const { border: containerBorder = "" } = containerStyles;

    useEffect(() => {
      if (numberInputRef.current) {
        // Converting the number to string converts large number into exponential notation.
        // So just setting the value as number and typecasting it to string to avoid TS error.
        // Number input does takes in number as value but it is displayed as string.
        const valueAsString = value === null ? "" : (value as string);
        let currentValue = valueAsString;
        if (isInteger) {
          const valueAsNumber = Number(valueAsString);
          // If `isInteger` is true and the value is not an integer
          // then it will be converted to an integer
          if (!Number.isInteger(valueAsNumber)) {
            const parsedValue = parseInt(valueAsString);
            currentValue = parsedValue ? parsedValue.toString() : "";
          }
        }
        numberInputRef.current.value = currentValue;
        setHasValue(!!currentValue);
      }
    }, [isInteger, value]);

    const hasError = status === FieldStatus.ERROR;

    const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = isInteger
        ? parseInt(evt.target.value)
        : Number(evt.target.value);
      const value = evt.target.value === "" ? undefined : convertedValue;
      setHasValue(!!value);
      onChange && onChange(value, evt);
    };

    const handleFocus = useCallback(
      (evt: React.FocusEvent<HTMLInputElement>) => {
        onFocus && onFocus(evt);
        if (containerFocusedBorder && containerRef?.current) {
          containerRef.current.style.border = containerFocusedBorder;
        }
      },
      [onFocus, containerFocusedBorder]
    );

    const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (value === "") {
        onBlur?.(undefined, evt);
        return;
      }
      if (min !== undefined && Number(value) < min) {
        onBlur?.(min, evt);
        return;
      }
      if (max && Number(value) > max) {
        onBlur?.(max, evt);
        return;
      }
      // TODO: find a better way to change boader on focus (try to extract emotions functionality)
      if (containerBorder && containerRef?.current) {
        containerRef.current.style.border = containerBorder;
      }
      onBlur && onBlur(isInteger ? parseInt(value) : Number(value), evt);
    };

    const handleOnClear = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      if (numberInputRef.current) {
        numberInputRef.current.value = "";
        numberInputRef.current.focus();
      }
      onChange?.(undefined);
    };

    const onSpinnerIncrement = () => {
      if (isDisabled) return;

      if (numberInputRef.current?.value !== undefined) {
        let newValue = Number(numberInputRef.current.value) + step;
        if (max !== undefined && newValue > max) {
          newValue = max;
        }
        numberInputRef.current.value = newValue.toString();
        onChange?.(Number(newValue));
      } else if (numberInputRef.current) {
        numberInputRef.current.value = step.toString();
      }
    };

    const onSpinnerDecrement = () => {
      if (isDisabled) return;

      if (numberInputRef.current?.value !== undefined) {
        let newValue: string | number =
          Number(numberInputRef.current.value) - step;
        if (min !== undefined && newValue < min) {
          newValue = min;
        }
        numberInputRef.current.value = newValue.toString();
        onChange?.(Number(newValue));
      } else if (numberInputRef.current) {
        numberInputRef.current.value = (-step).toString();
      }
    };

    const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (isInteger && evt.key === ".") {
        evt.preventDefault();
        evt.stopPropagation();
      }
      onKeyPress?.(evt);
    };

    const ariaDescribedById = ariaDescribedBy
      ? ariaDescribedBy
      : !!error
        ? id + "-error"
        : undefined;

    return (
      <div
        className={classNames({
          [`${styles["catalystNumberInputContainer"]}`]: true,
          [`${styles["catalystNumberInputContainer" + size]}`]: true,
          [`${styles["catalystNumberInputContainer" + status]}`]:
            !!status && !isDisabled,
          [`${styles["catalystNumberInputContainerDisabled"]}`]: isDisabled,
          [`${styles["catalystNumberInputHasValue"]}`]: hasValue
        })}
        style={{
          width: width,
          ...containerStyles
        }}
        onClick={onClick}
        ref={containerRef}
      >
        {leftIcon && (
          <div className={styles.catalystNumberInputLeftIcon}>{leftIcon}</div>
        )}
        <input
          id={id}
          type="number"
          className={classNames({
            [`${styles["catalystNumberInput"]}`]: true
          })}
          disabled={isDisabled}
          placeholder={placeholder}
          required={isRequired}
          aria-invalid={hasError}
          aria-required={isRequired}
          aria-describedby={ariaDescribedById}
          aria-label={label}
          ref={mergeRefs([numberInputRef, ref])}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          max={max}
          min={min}
          step={step}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onKeyPress={handleKeyPress}
          autoComplete={autoComplete}
          autoFocus={shouldAutoFocus}
        />
        <div className={styles.catalystNumberInputSpinners}>
          <ChevronUp size={12} onClick={onSpinnerIncrement} />
          <ChevronDown size={12} onClick={onSpinnerDecrement} />
        </div>
        {shouldAllowClear && hasValue && (
          <button
            className={styles.catalystNumberInputClearIcon}
            onClick={handleOnClear}
          >
            <Clear size={12} />
          </button>
        )}
      </div>
    );
  }
);
