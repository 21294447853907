import queryString from "query-string";
import type { CaptchaService } from "../types";
import { tenant } from "@certa/network";
import type { AuthUserDetail } from "@certa/types";
import { Godaam } from "@certa/common/utils/Godaam";
import {
  getDashboardsGenericRoute,
  getHomeRoute,
  getTasksRoute
} from "@certa/common/utils/routes";

type SearchParams = {
  next?: string;
};

export const getNextURL = (
  searchParams: string,
  /** @deprecated This param is no longer in use. */
  isCERTA2Enabled: boolean = false,
  isHomePageEnabled: boolean = false,
  canViewDashboard: boolean = false
): string => {
  const parsed: SearchParams = queryString.parse(searchParams);
  const nextURL = parsed.next;
  const landingURL = isCERTA2Enabled
    ? isHomePageEnabled
      ? getHomeRoute()
      : canViewDashboard
        ? getDashboardsGenericRoute()
        : getTasksRoute()
    : "/workflows/instances/";
  return nextURL && nextURL !== "/" ? nextURL : landingURL;
};

export const getCaptchaServiceNameforBE = (captchaService: CaptchaService) =>
  // Done to suffice BE API requirement. Should ideally be turnstile || recaptcha
  captchaService === "turnstile" ? captchaService : undefined;

export const saveUserDataToGodaam = (user: AuthUserDetail) => {
  const userData: AuthUserDetail & { tenant?: string } = user;
  userData.tenant = tenant;
  userData.csrf = document.cookie;
  return (Godaam.user = JSON.stringify(userData));
};
