//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const App = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="App"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.8699 11.2007V4.79927C14.8699 4.23767 14.575 3.71777 14.0936 3.42813L8.75908 0.227392C8.25273 -0.0757974 7.62029 -0.0757974 7.11391 0.227392L1.77935 3.42813C1.29695 3.71673 1.00104 4.2377 1 4.79927V11.2007C1 11.7623 1.29487 12.2822 1.77623 12.5719L7.11079 15.7726C7.61714 16.0758 8.24958 16.0758 8.75596 15.7726L14.0905 12.5719C14.5729 12.2833 14.8688 11.7623 14.8699 11.2007ZM7.93495 1.59853L12.4901 4.33249L7.93495 7.06645L3.37977 4.33249L7.93495 1.59853ZM2.60039 11.2007V5.73281L7.13477 8.45317V13.9211L2.60039 11.2007ZM8.73513 13.9211V8.45317L13.2695 5.73281V11.2007L8.73513 13.9211Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
