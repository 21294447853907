import { showToast, ToastTypes } from "@certa/catalyst/components/Toast";
import { COPY_TEXT } from "@certa/comments/src/util/copyText";
import { useAppSelector } from "@certa/common/hooks";
import { useRemoveSubscription } from "@certa/queries/hooks/threadSubscription.hooks";
import {
  SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY,
  type SubscriptionComms
} from "@certa/queries/queries/comms";
import { queryClient } from "@certa/queries/utils/utils";
import type { CommentObjectTypes } from "@certa/types";
import { useIntl } from "react-intl";

/*
  - Why this hook?
  - This hook is used to perform optimistic update on removing subscription.
*/

type RemoveSubscriptionOptUpdateCommsProps = {
  objectId: number;
  objectType: CommentObjectTypes;
};

export const useRemoveSubscriptionOptUpdateComms = (
  props: RemoveSubscriptionOptUpdateCommsProps
) => {
  const userId = useAppSelector(state => state.authentication.user.id!);
  const { objectId, objectType } = props;
  const intl = useIntl();

  const { mutate: removeSubscription } = useRemoveSubscription(
    objectId,
    objectType,
    userId,
    {
      onMutate: async ({
        threadId,
        enable
      }: {
        threadId: number;
        enable: boolean;
      }) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY);
        const previousSubscriptions = queryClient.getQueryData(
          SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY
        );
        // Optimistically update to the new value
        queryClient.setQueryData(
          SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY,
          (oldData: any) => {
            return {
              pages: oldData.pages.map((page: any) => ({
                ...page,
                results: page.results.filter(
                  (subscription: SubscriptionComms) =>
                    subscription.conversation.kryptonId !== threadId
                )
              }))
            };
          }
        );

        // This is to handle error case. When we return this, we get previous data in context in onError
        return { previousSubscriptions };
      },
      onError: (error, variables, context) => {
        let errorMessage = intl.formatMessage({
          id: "comments.errors.changeSubscriptionFailed",
          defaultMessage: COPY_TEXT.DEFAULT_MESSAGES.UNABLE_TO_UNSUBSCRIBE
        });
        if (error && error.text) {
          error.text().then((text: string) => {
            try {
              const parsedText = JSON.parse(text);
              errorMessage = parsedText.detail;
            } catch (e) {
              // Do nothing
            }
            showToast({
              title: "Error",
              description: errorMessage,
              type: ToastTypes.ERROR
            });
          });
        } else {
          showToast({
            title: "Error",
            description: errorMessage,
            type: ToastTypes.ERROR
          });
        }
        // Reverting optimistic update
        queryClient.setQueryData(
          [SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY],
          context.previousSubscriptions
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY);
      }
    }
  );
  return removeSubscription;
};
