import { useCallback, useMemo } from "react";
import { FieldTreeWrapper } from "main/src/modules/dashboard/workflowToolbar/components/AdvancedFilter";

import {
  useGetFieldsHierarchy,
  useGetFieldsHierarchyORM
} from "@certa/queries/hooks/workflow.hooks";
import { useKindId, useKinds } from "@certa/queries/hooks/kinds.hooks";

import type { ReportCustomFilter } from "@certa/types";
import { CompareType, FieldTypes, FilterParamName } from "@certa/types";
import { getORMAdvancedFilterOptions } from "@certa/dashboards/src/components/utils/ormReportAdvancedFilterOptionsUtils";
import type {
  ORMReportProcessAttr,
  ORMReportStepAttr,
  ORMReportStepGroupAttr
} from "../../constants/ormConstants";
import { getDetailsFromCustomAttributeTag } from "@certa/dashboards/src/components/utils/ormReportAdvancedFilterUtils";
import { getKindIdFromTag } from "../../utils/getKindIdFromTag";
import { AdvancedFilterGroup } from "./AdvancedFilterGroup";
import type { AdvancedFilterGroupProps } from "./AdvancedFilterGroup";
import { FormItem } from "@certa/blocks/thanos";
import { isProcessAttrORM } from "@certa/common/utils/report";
import { getLastObject } from "@certa/common/utils/helper";
import {
  ORM_REPORT_FORM_NAME,
  ormStepGroupLevelAttributeDetails,
  ormStepLevelAttributeDetails,
  processAttrDataORM
} from "@certa/common/constants";
import { useIsExcludingWeekendAndHolidays } from "../../toggles";
import type { OperatorsDetails } from "../../utils/filterUtils";

export type AdvancedFiltersKindTag = { kindTag: string; kindId?: never };
export type AdvancedFiltersKindId = { kindId: number; kindTag?: never };

type CommonAdvancedFilteringProps = {
  shouldEnableGroupQuery?: boolean;
  customOperators?: OperatorsDetails;
};
type AdvancedFiltersProps = (AdvancedFiltersKindTag | AdvancedFiltersKindId) &
  CommonAdvancedFilteringProps;

const useAdvancedFilters = (props: AdvancedFiltersProps) => {
  const { kindId, kindTag } = props;

  const { data, status } = useGetFieldsHierarchy({
    kindTag,
    processTypeId: kindId
  });

  // In case we already have kindId we pass it directly in props below.
  const { data: kindData = [] } = useKinds(
    { hideInternal: true },
    { enabled: !kindId }
  );
  const kindIdFromTag = getKindIdFromTag({ kinds: kindData, kindTag });

  const fieldsTree = data?.hierarchy || [];
  const mapping = useMemo(() => data?.mapping || {}, [data]);

  const getFieldType = useCallback(
    (field: string[]) => {
      const fieldTag = field?.[field?.length - 1];
      return (mapping[fieldTag]?.fieldType as FieldTypes) ?? FieldTypes.TEXT;
    },
    [mapping]
  );

  const cascaderOptions = FieldTreeWrapper(fieldsTree);
  const isDisabledCascader = status !== "success" || !(kindTag || kindId);
  const isOptionsIsLoading = status === "loading";

  return {
    getFieldType,
    cascaderOptions,
    isDisabledCascader,
    isOptionsIsLoading,
    kindId: kindIdFromTag || kindId?.toString(),
    parentPath: undefined
  };
};

export const AdvancedFilters = (props: AdvancedFiltersProps) => {
  const { shouldEnableGroupQuery, customOperators } = props;
  const {
    getFieldType,
    cascaderOptions,
    isDisabledCascader,
    isOptionsIsLoading,
    kindId
  } = useAdvancedFilters(props);

  return (
    <FormItem noStyle shouldUpdate name={FilterParamName.ANSWER}>
      <AdvancedFilterGroupWrapper
        getFieldType={getFieldType}
        cascaderOptions={cascaderOptions}
        disabledCascader={isDisabledCascader}
        isGroupQuery={!!shouldEnableGroupQuery}
        optionsIsLoading={isOptionsIsLoading}
        isORMReport={false}
        kindId={kindId}
        parentPath={undefined}
        customOperators={customOperators}
      />
    </FormItem>
  );
};

type ControlledAdvancedFiltersProps = AdvancedFiltersProps & {
  value: ReportCustomFilter;
  onChange: (value: ReportCustomFilter) => void;
  shouldAppendButtons?: boolean;
};
export const ControlledAdvancedFilters = (
  props: ControlledAdvancedFiltersProps
) => {
  const {
    shouldEnableGroupQuery,
    customOperators,
    value,
    onChange,
    shouldAppendButtons = true
  } = props;
  const {
    getFieldType,
    cascaderOptions,
    isDisabledCascader,
    isOptionsIsLoading,
    kindId
  } = useAdvancedFilters(props);

  return (
    <AdvancedFilterGroup
      getFieldType={getFieldType}
      cascaderOptions={cascaderOptions}
      disabledCascader={isDisabledCascader}
      isGroupQuery={!!shouldEnableGroupQuery}
      optionsIsLoading={isOptionsIsLoading}
      isORMReport={false}
      kindId={kindId}
      parentPath={undefined}
      customOperators={customOperators}
      customFilterValue={value}
      onChange={onChange}
      shouldAppendButtons={shouldAppendButtons}
    />
  );
};

export const ORMAdvancedFilters = (props: {
  kindTag: string | undefined;
  childrenKindIds: string[] | undefined;
  grandChildrenKindIds: string[] | undefined;
}) => {
  const { kindTag = "", childrenKindIds, grandChildrenKindIds } = props;

  const { data: kinds } = useKinds();
  const kindId = useKindId(kindTag);

  const { data, isLoading, status } = useGetFieldsHierarchyORM({
    processTypeId: kindId,
    childrenKindIds,
    grandChildrenKindIds,
    isORMReport: true
  });

  const mapping = useMemo(() => data?.fieldTagMapping || {}, [data]);

  const getFieldType = useCallback(
    (field: string[]) => {
      const fieldTag = getLastObject(field);
      let fieldType = FieldTypes.TEXT;
      if (!fieldTag) {
        return fieldType;
      }

      const { attributeType, attribute } =
        getDetailsFromCustomAttributeTag(fieldTag);

      if (attributeType === "field") {
        fieldType = mapping[fieldTag]?.fieldType;
      } else if (attributeType === "attr") {
        if (isProcessAttrORM(attribute)) {
          fieldType =
            processAttrDataORM[attribute as ORMReportProcessAttr]?.fieldType;
        }
      } else if (attributeType === "step_group") {
        fieldType =
          ormStepGroupLevelAttributeDetails[attribute as ORMReportStepGroupAttr]
            ?.systemFieldType;
      } else {
        fieldType =
          ormStepLevelAttributeDetails[attribute as ORMReportStepAttr]
            ?.systemFieldType;
      }
      return fieldType ?? FieldTypes.TEXT;
    },
    [mapping]
  );
  const isExcludingWeekendAndHolidays = useIsExcludingWeekendAndHolidays();
  const { options: cascaderOptions } = getORMAdvancedFilterOptions({
    data,
    kinds,
    kindId,
    isExcludingWeekendAndHolidays
  });

  const isDisabledCascader = status !== "success" || !kindId;
  return (
    <FormItem noStyle shouldUpdate name={ORM_REPORT_FORM_NAME.ADVANCED_FILTERS}>
      <AdvancedFilterGroupWrapper
        getFieldType={getFieldType}
        cascaderOptions={cascaderOptions}
        disabledCascader={isDisabledCascader}
        isGroupQuery={true}
        optionsIsLoading={isLoading}
        isORMReport={true}
        kindId={kindId?.toString()}
        parentPath={undefined}
      />
    </FormItem>
  );
};

// This wrapper need to be created because AdvancedFilterGroup component
// requires onChange and value props, that passed FormItem but typescript is not able to infer it.
const AdvancedFilterGroupWrapper = (
  props: {
    value?: ReportCustomFilter;
    onChange?: (value: ReportCustomFilter) => void;
  } & Omit<AdvancedFilterGroupProps, "customFilterValue" | "onChange">
) => {
  const {
    onChange = () => {},

    value = {
      groups: [] as ReportCustomFilter["groups"],
      conditions: [] as ReportCustomFilter["conditions"],
      compareType: CompareType.AND
    },
    ...rest
  } = props;

  return (
    <AdvancedFilterGroup
      customFilterValue={value}
      onChange={onChange}
      {...rest}
    />
  );
};
