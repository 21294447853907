import { createColumnHelper, type ColumnMeta } from "@tanstack/react-table";
import type {
  CellProps,
  ActiveColumnIdentifier,
  DataPlusUndefined,
  DataCellFormatter,
  ColumnTitleProps,
  ColumnDefinition,
  DataType
} from "../types";
import { MAX_COLUMN_WIDTH } from "../constants";
import { calculateRatio } from "./cellUtils";

export const columnHelper = createColumnHelper<DataPlusUndefined>();

const FIRST_X_AXIS_LABELS = 0;
const NO_OF_ADDITIONAL_COLUMNS = 3;

type CreateDataColumnsProps = {
  data: DataType[];
  xAxisKeys: string[];
  yAxisKeys: string[];
  multipleXAxisLabels: string[][];
  dataCellFormatter: DataCellFormatter;
  minValue: number;
  minMaxDiff: number;
  renderColumnTitle: (props: ColumnTitleProps) => React.ReactNode;
  renderDataCell: (props: CellProps) => React.ReactNode;
  cellBaseColors?: string[];
  onClick?: CellProps["onClick"];
  activeColumnIdentifier?: ActiveColumnIdentifier;
};

export const createDataColumns = ({
  data,
  xAxisKeys,
  yAxisKeys,
  multipleXAxisLabels,
  dataCellFormatter,
  minValue,
  minMaxDiff,
  renderColumnTitle,
  renderDataCell,
  cellBaseColors,
  onClick,
  activeColumnIdentifier
}: CreateDataColumnsProps): ColumnDefinition[] => {
  const groupDataInReqFormat = (
    data: DataType[],
    xAxisKeys: string[],
    yAxisKeys: string[],
    index = 0,
    parentHierarchy: string[] = [],
    parentDataIndexSet = new Set<string>()
  ): ColumnDefinition[] => {
    const result: ColumnDefinition[] = [];
    let newParentDataIndexSet = new Set(parentDataIndexSet);

    if (index === xAxisKeys.length - 1) {
      // Base case - leaf columns
      data.forEach((item: DataType, colIndex) => {
        const header = item[xAxisKeys[index]];
        const safeHeader = header === null ? "null" : header;
        const columnKey = generateColumnKey(`${safeHeader}`, parentHierarchy);

        // Ensure uniqueness using the Set
        if (!newParentDataIndexSet.has(columnKey)) {
          result.push(
            columnHelper.accessor(row => row[columnKey], {
              id: columnKey,
              header: () => renderColumnTitle({ title: `${safeHeader}` }),
              meta: { rowSpan: xAxisKeys.length === 1 ? 2 : 1 } as ColumnMeta<
                DataPlusUndefined,
                any
              >,
              size:
                MAX_COLUMN_WIDTH /
                (multipleXAxisLabels[FIRST_X_AXIS_LABELS]?.length +
                  NO_OF_ADDITIONAL_COLUMNS),
              cell: info => {
                const currentItem = findMatchingItem(
                  data,
                  info,
                  yAxisKeys,
                  xAxisKeys,
                  header
                );
                const value = info.getValue();
                const ratio = calculateRatio(value, minValue, minMaxDiff);

                return renderDataCell({
                  cellBaseColors,
                  dataCellFormatter,
                  cellValue: info.getValue(),
                  columnHeaderKey: columnKey,
                  cellFiltersData: currentItem,
                  rowRecord: info.row.original,
                  rowIndex: info.row.index,
                  columnIndex: colIndex,
                  ratio,
                  onClick,
                  activeColumnIdentifier
                });
              }
            })
          );
          newParentDataIndexSet.add(columnKey);
        }
      });
    } else {
      // Recursive case - group columns
      const groupedData = groupBy(data, xAxisKeys[index]);

      for (const [key, children] of Object.entries(groupedData)) {
        const safeKey = key === null ? "null" : key; // Handle null/undefined keys
        const columnKey = generateColumnKey(safeKey, parentHierarchy);
        newParentDataIndexSet = new Set();

        if (!newParentDataIndexSet.has(columnKey)) {
          const childHierarchy = [...parentHierarchy, safeKey];

          const childColumns = groupDataInReqFormat(
            children,
            xAxisKeys,
            yAxisKeys,
            index + 1,
            childHierarchy,
            newParentDataIndexSet
          );

          result.push(
            columnHelper.group({
              id: columnKey,
              header: () => renderColumnTitle({ title: key }),
              columns: childColumns
            })
          );

          newParentDataIndexSet.add(columnKey);
        }
      }
    }

    return result;
  };

  const reversedXAxisKeys = xAxisKeys.slice().reverse();
  return groupDataInReqFormat(data, reversedXAxisKeys, yAxisKeys);
};

// Helper functions
const findMatchingItem = (
  data: DataType[],
  info: any,
  yAxisKeys: string[],
  xAxisKeys: string[],
  header: any
) => {
  return data.find(item => {
    const isYAxisKeyValueMatch = yAxisKeys.every(
      key => item[key] === info.row.original[key]
    );
    const isXAxisKeyValueMatch =
      item[xAxisKeys[xAxisKeys.length - 1]] === header;
    return isYAxisKeyValueMatch && isXAxisKeyValueMatch;
  });
};

const groupBy = (data: DataType[], key: string): Record<string, DataType[]> => {
  return data.reduce<Record<string, DataType[]>>((result, item) => {
    const groupKey = `${item[key]}`;
    result[groupKey] = result[groupKey] || [];
    result[groupKey].push(item);
    return result;
  }, {});
};

export const generateColumnKey = (
  label: string,
  hierarchy: string[]
): string => {
  const updatedHierarchy = [...hierarchy, label].reverse();
  return `$$$${updatedHierarchy.join("___")}$$$`;
};
