import React, { useState, useEffect, useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { get as lodashGet, debounce } from "lodash-es";
import { COPY_TEXT } from "../../util/copyText";
import { ChannelContext, ThreadContext } from "../../comments.context";
import {
  useRemoveSubscription,
  useAddSubscription
} from "@certa/queries/hooks/threadSubscription.hooks";
import { Switch } from "@certa/catalyst/components/Switch";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { showMessage } from "@certa/common/components/showMessage";

const DEBOUNCE_TIMER_IN_MS = 500;

const initiateUserSubscriptionFlow = (
  cb: Function,
  toggleValue: boolean,
  threadId: number,
  onSuccess: Function,
  onError: Function
) => {
  return cb(
    {
      enable: toggleValue,
      threadId
    },
    {
      onSuccess: () => onSuccess(toggleValue),
      onError
    }
  );
};

const debounceRequest = debounce(
  initiateUserSubscriptionFlow,
  DEBOUNCE_TIMER_IN_MS
);

const SubscribeSwitch = () => {
  const intl = useIntl();
  const { threadId, subscribedBy } = useContext(ThreadContext);
  const { objectId, objectType } = useContext(ChannelContext);
  const userId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );
  const [isSubscribed, setIsSubscribed] = useState(() =>
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    subscribedBy.includes(userId)
  );
  const { mutate: removeSubscription } = useRemoveSubscription(
    objectId,
    objectType,
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    userId
  );
  const { mutate: addSubscription } = useAddSubscription(
    objectId,
    objectType,
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    userId
  );

  useEffect(() => {
    // @ts-expect-error - use useAppSelector from common package instead of useSelector
    setIsSubscribed(subscribedBy.includes(userId));
  }, [threadId, subscribedBy, userId]);

  const handleOnSuccess = useCallback((toggleValue: boolean) => {
    setIsSubscribed(toggleValue);
  }, []);

  const handleOnError = useCallback(() => {
    showMessage(
      "error",
      intl.formatMessage({
        id: "comments.errors.changeSubscriptionFailed",
        defaultMessage: COPY_TEXT.DEFAULT_MESSAGES.UNABLE_TO_SUBSCRIBE
      })
    );
  }, [intl]);

  const debouncedCallback = useCallback(
    (cb: Function, toggleValue: boolean) => {
      return debounceRequest(
        cb,
        toggleValue,
        threadId,
        handleOnSuccess,
        handleOnError
      );
    },
    [handleOnError, handleOnSuccess, threadId]
  );

  const onToggle = (toggleValue: boolean) => {
    MixPanelActions.track(
      toggleValue
        ? MixPanelEvents.workflowDetailEvents
            .COMMENT_DETAIL_PANE_CLICK_SUBSCRIBE_TO_THREAD
        : MixPanelEvents.workflowDetailEvents
            .COMMENT_DETAIL_PANE_CLICK_UNSUBSCRIBE_FROM_THREAD
    );
    const mutatorFn = toggleValue ? addSubscription : removeSubscription;
    debouncedCallback(mutatorFn, toggleValue);
  };

  const subscribedMessage = intl.formatMessage({
    id: "comments.subscribedMessage",
    defaultMessage: "SUBSCRIBED"
  });

  return (
    <Stack direction="horizontal" align="center" justify="flex-start" gap="8">
      <Switch
        label={subscribedMessage}
        checked={isSubscribed}
        onChange={onToggle}
      />
      <Typography
        variant={TypographyVariants.OVERLINE_SMALL}
        color={TypographyColors.NEUTRAL_700}
      >
        {subscribedMessage}
      </Typography>
    </Stack>
  );
};

export { SubscribeSwitch };
