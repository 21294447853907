import type { SelectProps } from "@certa/catalyst/components/Select";
import { FormattedMessage } from "react-intl";
import { MAX_RECENT_SEARCHES_TO_SHOW, SearchResultType } from "./constants";
import format from "date-fns/format";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { UnreachableError } from "@certa/types";
import { RecentSearchResult } from "./resultComponents/RecentSearchResult";

export function createRecentSearchGroup(
  recentSearches: string[],
  filterQuery?: string
): SelectProps["options"][0] {
  const sanitizedFilterQuery = filterQuery?.trim().toLowerCase();
  const filterUsingQuery = (recentSearch: string) => {
    if (!sanitizedFilterQuery) {
      return true;
    }
    return recentSearch.includes(sanitizedFilterQuery);
  };
  return {
    // @ts-expect-error catalyst need to fix the type of the label to include React.NamedExoticComponent too @yadhus
    label: (
      <FormattedMessage
        id="globalSearch.recentSearches"
        defaultMessage="Recent Searches"
      />
    ) as React.ReactNode,
    options: recentSearches
      .filter(filterUsingQuery)
      .slice(0, MAX_RECENT_SEARCHES_TO_SHOW)
      .map(recentSearch => ({
        label: <RecentSearchResult value={recentSearch} />,
        value: recentSearch,
        type: SearchResultType.RECENT_SEARCH
      }))
  };
}

export function formatDate(dateString: string) {
  // TODO: format relative
  return format(new Date(dateString), "dd/MM/yyyy");
}

export function getWorkflowIdsToFetchFamily(
  // TODO: Should not export ModelledAPIResponse from the queries package
  // since it might collide with other namespaced ModelledAPIResponse
  data: ModelledAPIResponse.SearchResult[]
): number[] {
  return data.map(searchResult => {
    switch (searchResult.type) {
      case "workflow":
        return searchResult.id;
      case "field":
        return searchResult.workflow.id;
      case "lc":
      case "file":
        return searchResult.workflow.id;
      default:
        throw new UnreachableError(
          searchResult,
          "Trying to extract WF ID from global search result of type never"
        );
    }
  });
}
