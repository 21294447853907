import { FieldTypes } from "@certa/types";
import type { ORMReportStepAttr, ORMReportStepGroupAttr } from "./ormConstants";

export const ormStepAttributes: ORMReportStepAttr[] = [
  "completed_by_name",
  "completed_by_email",
  "is_locked",
  "is_enabled",
  "initiated_at",
  "completed_at",
  "created_at",
  "updated_at",
  "cycle_start",
  "cycle_end",
  "cycle_time",
  "ageing",
  "assignee_names",
  "assignee_emails",
  "is_cycle_running",
  "submit_count",
  "sla_start_time",
  "sla_breach_time",
  "sla_elapsed_time",
  "is_sla_breached",
  "resolution_time"
];

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
export const ormStepAttributesWithoutAgeing = ormStepAttributes.filter(
  attr => attr !== "ageing"
);

export const ormStepGroupAttributes: ORMReportStepGroupAttr[] = [
  "ageing",
  "cycle_time",
  "cycle_start",
  "cycle_end",
  "is_cycle_running"
];

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
export const ormStepGroupAttributesWithoutAgeing =
  ormStepGroupAttributes.filter(attr => attr !== "ageing");

type OrmSLADetailsValue = {
  labelText: string;
  iconText: string;
  value: ORMReportStepAttr;
  hasSorting: boolean;
  systemFieldType: FieldTypes;
  hasFilter: boolean;
  hasColumnFilter: boolean;
  isHiddenInFYIorReadOnly: boolean;
  canAddToXAxis: boolean;
  canAddToYAxis: boolean;
};

export const ormStepLevelAttributeDetails: Readonly<
  Record<ORMReportStepAttr, OrmSLADetailsValue>
> = {
  id: {
    labelText: "ID",
    iconText: "integer",
    value: "id",
    hasSorting: true,
    systemFieldType: FieldTypes.INTEGER,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  tag: {
    labelText: "Tag",
    iconText: "char_field",
    value: "tag",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  name: {
    labelText: "Name",
    iconText: "char_field",
    value: "name",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  completed_by_name: {
    labelText: "Completed By (Name)",
    value: "completed_by_name",
    iconText: "user",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  completed_by_email: {
    labelText: "Completed By (Email)",
    iconText: "email",
    value: "completed_by_email",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  assignee_names: {
    labelText: "Assignee (Name)",
    value: "assignee_names",
    iconText: "user",
    hasSorting: false,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: false,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  assignee_emails: {
    labelText: "Assignee (Email)",
    value: "assignee_emails",
    iconText: "email",
    hasSorting: false,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: false,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  is_locked: {
    labelText: "Is locked",
    iconText: "lock",
    value: "is_locked",
    hasSorting: true,
    systemFieldType: FieldTypes.BOOL,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  is_enabled: {
    labelText: "Is enabled",
    iconText: "bool",
    value: "is_enabled",
    hasSorting: true,
    systemFieldType: FieldTypes.BOOL,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  initiated_at: {
    labelText: "Initiated At",
    iconText: "date",
    value: "initiated_at",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  completed_at: {
    labelText: "Completed At",
    iconText: "date",
    value: "completed_at",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  created_at: {
    labelText: "Created At",
    iconText: "date",
    value: "created_at",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  updated_at: {
    labelText: "Updated At",
    iconText: "date",
    value: "updated_at",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  cycle_start: {
    labelText: "Cycle start",
    iconText: "clock",
    value: "cycle_start",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  cycle_end: {
    labelText: "Cycle end",
    iconText: "clock",
    value: "cycle_end",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  cycle_time: {
    labelText: "Cycle time",
    iconText: "clock",
    value: "cycle_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  ageing: {
    labelText: "Ageing",
    iconText: "clock",
    value: "ageing",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  is_cycle_running: {
    labelText: "Is cycle running",
    iconText: "bool",
    value: "is_cycle_running",
    hasSorting: true,
    systemFieldType: FieldTypes.BOOL,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  submit_count: {
    labelText: "Submit count",
    iconText: "integer",
    value: "submit_count",
    hasSorting: true,
    systemFieldType: FieldTypes.INTEGER,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  sla_start_time: {
    labelText: "SLA start time",
    iconText: "date",
    value: "sla_start_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  sla_breach_time: {
    labelText: "SLA breach time",
    iconText: "date",
    value: "sla_breach_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  sla_elapsed_time: {
    labelText: "SLA elapsed time",
    iconText: "integer",
    value: "sla_elapsed_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  is_sla_breached: {
    labelText: "Is SLA breached",
    iconText: "bool",
    value: "is_sla_breached",
    hasSorting: true,
    systemFieldType: FieldTypes.BOOL,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: true,
    canAddToYAxis: false
  },
  resolution_time: {
    labelText: "Resolution time",
    iconText: "integer",
    value: "resolution_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: true,
    canAddToXAxis: false,
    canAddToYAxis: true
  }
};

type OrmSGLADetailsValue = Omit<OrmSLADetailsValue, "value"> & {
  value: ORMReportStepGroupAttr;
};

export const ormStepGroupLevelAttributeDetails: Readonly<
  Record<ORMReportStepGroupAttr, OrmSGLADetailsValue>
> = {
  id: {
    labelText: "ID",
    iconText: "integer",
    value: "id",
    hasSorting: true,
    systemFieldType: FieldTypes.INTEGER,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  tag: {
    labelText: "Tag",
    iconText: "char_field",
    value: "tag",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  name: {
    labelText: "Name",
    iconText: "char_field",
    value: "name",
    hasSorting: true,
    systemFieldType: FieldTypes.CHAR,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: false,
    canAddToYAxis: false
  },
  ageing: {
    labelText: "Ageing",
    iconText: "clock",
    value: "ageing",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  cycle_time: {
    labelText: "Cycle time",
    iconText: "clock",
    value: "cycle_time",
    hasSorting: true,
    systemFieldType: FieldTypes.DECIMAL,
    hasFilter: true,
    hasColumnFilter: false,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: false,
    canAddToYAxis: true
  },
  cycle_start: {
    labelText: "Cycle start",
    iconText: "clock",
    value: "cycle_start",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  cycle_end: {
    labelText: "Cycle end",
    iconText: "clock",
    value: "cycle_end",
    hasSorting: true,
    systemFieldType: FieldTypes.NAIVE_DATE,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: true
  },
  is_cycle_running: {
    labelText: "Is cycle running",
    iconText: "bool",
    value: "is_cycle_running",
    hasSorting: true,
    systemFieldType: FieldTypes.BOOL,
    hasFilter: true,
    hasColumnFilter: true,
    isHiddenInFYIorReadOnly: false,
    canAddToXAxis: true,
    canAddToYAxis: false
  }
};
