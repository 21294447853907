import type { ObjectStage } from "@certa/queries/types/studio.types";

export const getDashboardsGenericRoute = () => `/dashboards`;
export const getStudioGenericRoute = () => `/studio/apps`;

export const getStudioObjectsRoute = (params?: { stage: ObjectStage }) =>
  params ? `/studio/records/${params.stage}` : `/studio/records/:stage`;

export const getStudioObjectsRouteWithoutStage = () => `/studio/records/`;

export const getStudioAppDetailsRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}`
    : "/studio/apps/:id/:branch/:savepoint";

export const getStudioAppDetailsRouteWithoutSavepoint = (params?: {
  id: number;
  branch: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}`
    : "/studio/apps/:id/:branch";

export const getStudioAppDetailsRouteWithoutBranchAndSavepoint = (params?: {
  id: number;
}) => (params ? `/studio/apps/${params.id}` : "/studio/apps/:id");

export const getStudioObjectsDesignerRoute = (params?: {
  stage: ObjectStage;
  id: string;
}) =>
  params
    ? `/studio/records/${params.stage}/${params.id}/`
    : "/studio/records/:stage/:id/";

export const getStudioAppSettingsRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/settings/`
    : "/studio/apps/:id/:branch/:savepoint/settings/*";

export const getStudioProcessEditorRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
  processSavepointId: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/process/${params.process}/${params.processSavepointId}`
    : "/studio/apps/:id/:branch/:savepoint/process/:process/:processSavepointId";

export const getStudioProcessEditorRouteWithoutProcessSavepointId = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/process/${params.process}`
    : "/studio/apps/:id/:branch/:savepoint/process/:process";

export const PROCESS_DESIGNER_SUFFIX = "designer";
export const getStudioProcessDesignerRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
  processSavepointId: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/designer/${params.process}/${params.processSavepointId}`
    : `/studio/apps/:id/:branch/:savepoint/${PROCESS_DESIGNER_SUFFIX}/:process/:processSavepointId`;

export const getStudioProcessDesignerRouteWithoutProcessSavepointId =
  (params?: {
    id: number;
    branch: number;
    savepoint: number;
    process: number;
  }) =>
    params
      ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/designer/${params.process}`
      : `/studio/apps/:id/:branch/:savepoint/${PROCESS_DESIGNER_SUFFIX}/:process`;

export const getStudioAuditLogsRoute = () => "/studio/activity-logs";
export const getStudioPreviewRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/preview/${params.process}/`
    : `/studio/apps/:id/:branch/:savepoint/preview/:process/`;

export const getDashboardsRoute = (
  dashboardId: number | string = ":dashboardId"
) => `/dashboard/${dashboardId}`;

export const getCreateNewReportRoute = (
  dashboardId: number | ":dashboardId" = ":dashboardId"
) => `/dashboards/${dashboardId}/report/new`;

export const getCreateNewReportRouteWithQueryParams = (
  dashboardId: number | ":dashboardId" = ":dashboardId",
  query: string
) => `/dashboards/${dashboardId}/report/new${query}`;

export const getOldEditReportRoute = (
  dashboardId: number | ":dashboardId" = ":dashboardId",
  reportId: number | ":reportId" = ":reportId"
) => `/dashboards/${dashboardId}/report/${reportId}/edit`;

export const getEditReportRoute = (
  reportId: number | ":reportId" = ":reportId"
) => `/report/${reportId}/edit`;

export const getOldFullScreenReportRoute = () =>
  `/dashboards/:dashboardId/report/:reportId`;

export const getFullScreenReportRoute = (
  reportId: number | ":reportId" = ":reportId"
) => `/report/${reportId}`;

export const getTasksRoute = () => `/tasks`;

export const getProcessDetailRoute = (id: number | string = ":id") =>
  `/process/${id}`;

export const getFilePreviewRoute = () => `/file-preview/`;

export const WIZARD_ROUTE_SLUG = "wizard";
export const getProcessWizardRoute = (id: number | string = ":id") =>
  `/process/${id}/${WIZARD_ROUTE_SLUG}/`;

export const getHomeRoute = () => `/home`;

export const getTaskRoute = ({
  processId,
  taskLaneId,
  taskId
}: {
  processId: number;
  taskLaneId?: number;
  taskId: number;
}) =>
  `${getProcessDetailRoute(processId)}?${
    taskLaneId ? `group=${taskLaneId}` : ""
  }&step=${taskId}`;

export const getSearchRoute = () => `/search`;

export const getDataObjectTypeRoute = (objectType?: string) =>
  objectType ? `/records/${objectType}` : "/records/:objectType";

export const getDataObjectRoute = (params?: {
  objectType: string;
  objectId: string;
  objectTab: string;
}) =>
  params
    ? `/records/${params.objectType}/${params.objectId}/${params.objectTab ?? ""}`
    : "/records/:objectType/:objectId/:objectTab";

export const getDataObjectRouteWithoutTab = (params?: {
  objectType: string;
  objectId: string;
}) =>
  params
    ? `/records/${params.objectType}/${params.objectId}`
    : "/records/:objectType/:objectId";

export const getRouteForProcessDetail = (processId: number) =>
  window.location.pathname.includes(`/workflows/instances/`)
    ? `/workflows/instances/${processId}`
    : getProcessDetailRoute(processId);

/**
 * @deprecated This feature is no longer in use
 */
export const canSeeCERTA2 = () => {
  const certa2routes = [
    "/process",
    "/tasks",
    "/dashboard",
    "/dashboards",
    "/report",
    "/search",
    "/home",
    "/studio",
    "/apps"
  ];

  return certa2routes.some(route => window.location.pathname.includes(route));
};

/**
 *  Persists the `nextUrl` as `ssoRedirectUrl`
 *  in sessionStorage before we enter sso flow
 *  TODO: Revert this change once BE supports redirect_uri, Ref: VET-46317
 */
export const persistNextUrlOnSSO = () => {
  const params = new URLSearchParams(window.location.search);
  const ssoRedirectUrl = params.get("next");
  if (ssoRedirectUrl) {
    sessionStorage.setItem("ssoRedirectUrl", `${ssoRedirectUrl}`);
  }
};

export const getDashboardIdFromPathname = () => {
  // if url is in pattern "/dashboard/:dashboardId",
  // match returns an array of ["/dashboard/:dashboardId", dashboardId]
  // The func returns 1st index of the array as dashboardId
  return window.location.pathname.match(/^\/dashboard\/(\d+)/)?.[1];
};

export const TABLEAU_PATH = "/reports/tableau";
export const SERVICE_UNAVAILABLE_PATH = "/service-unavailable/";
export const USER_INTERACTION_REQUIRED_PATH = "/storage-access-flow";

export const WORKFLOW_DEF_JSON_EDITOR_PATH = "/json-editor";
