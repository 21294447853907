import type { WorkflowFamilyResponse } from "@certa/queries/workflows/workflowFamily";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { SearchResultType } from "../constants";
import type { ResultComponentProps } from "./types";
import { WorkflowResult } from "./WorkflowResult";
import { FileResult } from "./FileResult";
import { FieldResponseResult } from "./FieldResponseResult";
import { UnreachableError } from "@certa/types";

type WrapperComponentProps = Pick<ResultComponentProps<never>, "searchQuery">;

export function getSearchResult(
  searchResult: ModelledAPIResponse.SearchResult,
  workflowFamily: WorkflowFamilyResponse.FamilyMap
) {
  switch (searchResult.type) {
    case "workflow": {
      const family = workflowFamily.get(searchResult.id) || [];
      return {
        component: (props: WrapperComponentProps) => (
          <WorkflowResult {...props} result={searchResult} family={family} />
        ),
        value: searchResult.id.toString(),
        type: SearchResultType.WORKFLOW_RESULT
      };
    }
    case "file": {
      const family = workflowFamily.get(searchResult.workflow.id) || [];
      return {
        component: (props: WrapperComponentProps) => (
          <FileResult {...props} result={searchResult} family={family} />
        ),
        value: searchResult.id.toString(),
        type: SearchResultType.FILE_RESULT
      };
    }
    case "field": {
      const family = workflowFamily.get(searchResult.workflow.id) || [];
      return {
        component: (props: WrapperComponentProps) => (
          <FieldResponseResult
            {...props}
            result={searchResult}
            family={family}
          />
        ),
        value: searchResult.id.toString(),
        type: SearchResultType.FIELD_RESPONSE_RESULT
      };
    }
    case "lc": {
      const family = workflowFamily.get(searchResult.workflow.id) || [];
      return {
        component: (props: WrapperComponentProps) => (
          <FieldResponseResult
            {...props}
            result={searchResult}
            family={family}
          />
        ),
        value: searchResult.id.toString(),
        type: SearchResultType.LC
      };
    }
    default:
      throw new UnreachableError(searchResult, "");
  }
}
