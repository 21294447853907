import {
  DEFAULT_PRECISION,
  DEFAULT_SCALE,
  mapBoxThemeOptions
} from "@certa/chartreport/src/constants";
import type {
  OtherChartConfig,
  MetricAttributes,
  ChartIdentifiers,
  YAxisOtherChartConfig,
  MetricDataTypes,
  Metric,
  ChartConfig,
  YAxisChart
} from "../types";
import {
  ALL_STEPS_TAG,
  INDEX_ONE,
  INDEX_ZERO,
  PROCESS_SWIMLANES_TAG
} from "./common";
import type { ChartConfigORM } from "@certa/types";

export const commonXAxisLabel = {
  PROCESS_SWIMLANES: "Process Swimlanes",
  ALL_STEPS: "All Steps"
};
export const ALL_FIELDS_TAG = "all-fields";

export const CYCLE_TIME_OPTION_LABEL = "Cycle Time";

export const VALUE_LABEL_ANGLE_OPTIONS = {
  "-90": -90,
  "-45": -45,
  "-60": -60,
  "0": 0,
  "90": 90
} as const;

export const otherChartConfigDefaultValue: OtherChartConfig = {
  showXAxisLabel: "auto",
  showYAxisLabel: "auto",
  showLegend: "bottom",
  chartOrientation: "vertical",
  shouldShowValueLabels: true,
  showValueOn: "always",
  valueLabelAngle: 0,
  geoMapTheme: mapBoxThemeOptions?.dark?.value,
  scale: DEFAULT_SCALE,
  precision: DEFAULT_PRECISION,
  dataOrder: []
};

export const getDataTypesFromMetrics = (
  metrics: Omit<MetricAttributes, "path">[]
) => metrics.map(metric => metric.data_type).filter(Boolean);

const STACKED_BAR_CHART_X_AXIS_LENGTH = 2;
export const getIsStackedBarChart = (
  chartConfig: Partial<ChartConfig> | undefined,
  chartType: ChartIdentifiers
) => {
  const xAxis = chartConfig?.["x-axis"];
  const otherConfig = chartConfig?.otherConfig;
  if (chartType === "BAR_CHART" && xAxis?.length) {
    // After Adding support for Multiple X-Axis,
    // this key will be defiend is the chart is stacked or not
    if (otherConfig?.isStackedBarChart !== undefined) {
      return otherConfig.isStackedBarChart;
      // If isStackedBarChart is not defined, we will check if the xAxis is of length 2
      // because second xAxis is only added for stacked bar chart
    } else return xAxis.length === STACKED_BAR_CHART_X_AXIS_LENGTH;
  }
  return false;
};

export const getYAxisProbableDataType = (
  yAxisOtherConfig: YAxisOtherChartConfig,
  dataType: MetricDataTypes[]
) => {
  if (yAxisOtherConfig.outputDataType?.length) {
    return yAxisOtherConfig.outputDataType.every(
      item => item === "date" || item === "datetime"
    )
      ? "date"
      : yAxisOtherConfig.outputDataType.every(item => item === "cycle-time")
        ? "cycle-time"
        : "float";
  }

  // for old reports, where yAxisOtherConfig.outputDataType is not defined
  const probableDataType = dataType.length
    ? dataType.includes("float")
      ? "float"
      : "date"
    : "float";
  return probableDataType;
};

export const CYCLE_TIME_METRICS = ["min", "max", "avg"];

export const getAggregateMetricType = (metrics: YAxisChart | undefined) => {
  if (!metrics) return "";
  // for old reports, where metrics is an array of string, like metrics[INDEX_ZERO] = "min", "max", "avg", "all"
  if (typeof metrics[INDEX_ZERO] === "string") {
    return metrics[INDEX_ZERO] as Metric;
    // for cycle time report
  } else if (metrics[INDEX_ZERO]?.mtr_type) {
    if (metrics.length === 1) {
      return metrics[INDEX_ZERO].mtr_type;
      // if there is more then one yAxis,
      // either all yAxis should be of same type or all yAxis should be of different type
      // like when aggregate (mtr_type) is "min" then all yAxis will be of type "min"
    } else if (
      typeof metrics[INDEX_ONE] !== "string" &&
      metrics[INDEX_ZERO].mtr_type === metrics[INDEX_ONE].mtr_type
    ) {
      return metrics[INDEX_ZERO].mtr_type;
    }
    // but if aggregate (mtr_type) is "all" then yaxis mtr_type will be sequence of "min", "max", "avg"
    return "all" as Metric;
  }
  return "";
};

// Sadly, what we are saving in cycleTimePath is tag information, not path
export const getPathFromCycleTimePath = (cycleTimePath: string | undefined) => {
  if (!cycleTimePath) return [];
  else if (cycleTimePath === PROCESS_SWIMLANES_TAG) {
    return [PROCESS_SWIMLANES_TAG];
  } else if (cycleTimePath.startsWith(`${ALL_STEPS_TAG}-of-`)) {
    return [
      ALL_FIELDS_TAG,
      cycleTimePath.split("-of-")[INDEX_ONE],
      cycleTimePath
    ];
  } else return [];
};

export const defaultOtherConfigurationsORM: ChartConfigORM["otherConfigurations"] =
  {
    colors: {
      activeColorType: "singleTone"
    },
    showXAxisLabel: "auto",
    showYAxisLabel: "auto",
    showLegend: "bottom",
    chartOrientation: "vertical",
    shouldShowValueLabels: true,
    showValueOn: "always",
    valueLabelAngle: 0,
    yAxisLabel: "",
    xAxisLabel: "",
    isCycleTimeReport: false,
    isStackedBarChart: false,
    isGroupedBarChart: false,
    cycleTimeTag: undefined,
    percentageThreshold: undefined,
    shouldShowPercentagesInValueLabels: false,
    geoMapTheme: mapBoxThemeOptions?.dark?.value,
    scale: DEFAULT_SCALE,
    precision: DEFAULT_PRECISION,
    dataOrder: [],
    shouldDisplayValueAsPercentage: false,
    percentageCalculationType: "row"
  };

export const defaultChartConfigORM: ChartConfigORM = {
  groupBy: [],
  attributes: [],
  operations: [],
  orderBy: [],
  otherConfigurations: defaultOtherConfigurationsORM
};
