import type { IconProps } from "@certa/icons";
import { processAttrDataORM } from "./processAttrDataORM";
import { createProcessValueTag } from "../utils/report";
import { ormReportDynamicAPIProcessAttr } from "./ormConstants";

export type ORMSmartColumnType = {
  title: {
    id: string;
    defaultMessage: string;
  };
  icon: React.ComponentType<IconProps>;
  value: string | "parent" | "child" | "grandchild";
  children?: ORMSmartColumnType[];
  kindId?: string;
};

export const ORM_SMART_COLUMNS_PARENT: ORMSmartColumnType[] = Object.values(
  processAttrDataORM
)
  .filter(data => !data.isHidden && !data.isDeprecated)
  .map(data => ({
    title: {
      id: data.intlId,
      defaultMessage: data.label
    },
    icon: data.icon,
    value: createProcessValueTag(data.value)
  }));

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
export const ORM_SMART_COLUMNS_PARENT_WITHOUT_AGEING: ORMSmartColumnType[] =
  ORM_SMART_COLUMNS_PARENT.filter(
    ({ value }) => value !== createProcessValueTag("ageing")
  );

const ormReportDynamicAPIProcessAttrValueTag =
  ormReportDynamicAPIProcessAttr.map(value => createProcessValueTag(value));

export const ORM_SMART_COLUMNS: ORMSmartColumnType[] =
  ORM_SMART_COLUMNS_PARENT.filter(
    ({ value }) => !ormReportDynamicAPIProcessAttrValueTag.includes(value)
  );

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
export const ORM_SMART_COLUMNS_WITHOUT_AGEING: ORMSmartColumnType[] =
  ORM_SMART_COLUMNS.filter(
    ({ value }) => value !== createProcessValueTag("ageing")
  );
