import { makeActionCreator } from "../common/utils";
import {
  GET_ALL_KINDS,
  GET_ALL_KINDS_SUCCESS,
  GET_ALL_KINDS_FAILURE,
  GET_ALL_ALERTS,
  GET_ALL_ALERTS_SUCCESS,
  GET_ALL_ALERTS_FAILURE,
  GET_ALL_TASK_QUEUES,
  GET_ALL_TASK_QUEUES_SUCCESS,
  GET_ALL_TASK_QUEUES_FAILURE,
  SET_WORKFLOW_FILTER,
  SET_MULTIPLE_WORKFLOW_FILTERS,
  REMOVE_WORKFLOW_FILTERS,
  GET_WORKFLOW_LIST,
  GET_WORKFLOW_LIST_SUCCESS,
  GET_WORKFLOW_LIST_FAILURE,
  GET_ADVANCED_FILTER_DATA,
  GET_ADVANCED_FILTER_DATA_SUCCESS,
  GET_ADVANCED_FILTER_DATA_FAILURE,
  RESET_WORKFLOW_FILTERS,
  SET_CURRENT_DASHBOARD_VIEW
} from "./actions";

export const getAllKinds = makeActionCreator(GET_ALL_KINDS);
export const getAllKindsSuccess = makeActionCreator(GET_ALL_KINDS_SUCCESS);
export const getAllKindsFailure = makeActionCreator(GET_ALL_KINDS_FAILURE);

export const getAllAlerts = makeActionCreator(GET_ALL_ALERTS);
export const getAllAlertsSuccess = makeActionCreator(GET_ALL_ALERTS_SUCCESS);
export const getAllAlertsFailure = makeActionCreator(GET_ALL_ALERTS_FAILURE);

export const getAllTaskQueues = makeActionCreator(GET_ALL_TASK_QUEUES);
export const getAllTaskQueuesSuccess = makeActionCreator(
  GET_ALL_TASK_QUEUES_SUCCESS
);
export const getAllTaskQueuesFailure = makeActionCreator(
  GET_ALL_TASK_QUEUES_FAILURE
);

export const setWorkflowFilter = makeActionCreator(SET_WORKFLOW_FILTER);
export const setMultipleWorkflowFilters = makeActionCreator(
  SET_MULTIPLE_WORKFLOW_FILTERS
);
export const removeWorkflowFilter = makeActionCreator(REMOVE_WORKFLOW_FILTERS);
export const resetWorkflowFilters = makeActionCreator(RESET_WORKFLOW_FILTERS);

export const getWorkflowList = makeActionCreator(GET_WORKFLOW_LIST);
export const getWorkflowListSuccess = makeActionCreator(
  GET_WORKFLOW_LIST_SUCCESS
);
export const getWorkflowListFailure = makeActionCreator(
  GET_WORKFLOW_LIST_FAILURE
);

export const getAdvancedFilterData = makeActionCreator(
  GET_ADVANCED_FILTER_DATA
);
export const getAdvancedFilterDataSuccess = makeActionCreator(
  GET_ADVANCED_FILTER_DATA_SUCCESS
);
export const getAdvancedFilterDataFailure = makeActionCreator(
  GET_ADVANCED_FILTER_DATA_FAILURE
);

export const setCurrentDashboardView = makeActionCreator(
  SET_CURRENT_DASHBOARD_VIEW
);
