import { useState, useImperativeHandle } from "react";
import { useIntl } from "react-intl";
import type {
  WithinTheLastTimePeriod as TIME_PERIOD,
  WithinTheLastErrorCode as ErrorCode
} from "@certa/types";
import {
  useTimePeriodOptions,
  useErrorMessageMapping
} from "./useWithinTheLastFieldText";
import type { WithinTheLastValueFieldProps } from "../WithinTheLastValueField.types";
import { CREATED_RANGE_AFTER_INDEX } from "../../../../../../utils/filterUtils";
import {
  destructureRelativeTime,
  getRelativeTimeString,
  withinTheLastValidation as fieldValidation,
  DEFAULT_TIME_PERIOD_VALUE
} from "../../../../../../utils/withinTheLastUtils";

export const useWithinTheLastValueField = (
  props: WithinTheLastValueFieldProps
) => {
  const { value, onChange, queryConfigErrorsRef } = props;
  const intl = useIntl();
  const timePeriodOptions = useTimePeriodOptions();
  const { timePeriod, withinTheLast } = extractValues(value);

  const [errorCode, setErrorCode] = useState<ErrorCode | undefined>(undefined);
  const errorMessage = useErrorMessageMapping(errorCode);

  const handleChange = (valueType: string, value: string | undefined) => {
    let newTimePeriod = timePeriod;
    let newWithinTheLast = withinTheLast;
    let newErrorCode = errorCode;
    let relativeDateRange = undefined;

    if (valueType === "timePeriod") {
      newTimePeriod = value as TIME_PERIOD;
      const { errorCode } = fieldValidation(newWithinTheLast, newTimePeriod);
      newErrorCode = errorCode;
    } else if (valueType === "withinTheLast") {
      newWithinTheLast =
        value !== "" && value !== undefined ? Number(value) : undefined;
      const { errorCode } = fieldValidation(newWithinTheLast, newTimePeriod);
      newErrorCode = errorCode;
    }

    setErrorCode(newErrorCode);
    if (newWithinTheLast !== undefined) {
      relativeDateRange = [
        getRelativeTimeString(newWithinTheLast, newTimePeriod, true),
        getRelativeTimeString(0, newTimePeriod)
      ];
    }
    onChange?.(relativeDateRange);
  };

  useImperativeHandle(
    queryConfigErrorsRef,
    () => {
      const prevErrors = queryConfigErrorsRef?.current ?? {};
      return errorCode
        ? {
            ...prevErrors,
            withinTheLast: errorMessage
          }
        : prevErrors;
    },
    [errorCode, errorMessage, queryConfigErrorsRef]
  );

  const numberInputLabel = intl.formatMessage({
    id: "savedSearch.withinTheLast",
    defaultMessage: "Within the last"
  });

  return {
    numberInputLabel,
    withinTheLast,
    handleChange,
    timePeriod,
    timePeriodOptions,
    errorMessage
  };
};

const extractValues = (value: string[] | string | undefined) => {
  const relativeTime = destructureRelativeTime(
    value?.[CREATED_RANGE_AFTER_INDEX]
  );
  const timePeriod = relativeTime.timePeriod ?? DEFAULT_TIME_PERIOD_VALUE;
  const withinTheLast = relativeTime.value;

  return { timePeriod, withinTheLast };
};
