import { useEffect } from "react";
import { queryClient } from "@certa/queries/utils/utils";
import {
  useUpdateUserRecentActivity,
  useUserRecentActivity
} from "@certa/queries/hooks/user.hooks";

import { useSyncRecentlyViewedGodaam } from "../hooks/useSyncRecentlyViewedGodaam";
import { useLocation } from "react-router";
import { isEqual } from "lodash-es";
import { useAppSelector } from "../hooks";

const getWorkflowIdFromPathname = (pathname: string) => {
  return pathname.match(/\/process\/\d+/)?.[0]?.match(/\d+(?!process)/)?.[0];
};

const SYNC_RECENT_ACTIVITY_INTERVAL = 60000;

type RecentlyVisitedRoutesProviderProps = {
  disabled: boolean;
};

export const RecentlyVisitedWorkflows = (
  props: RecentlyVisitedRoutesProviderProps
) => {
  const { disabled: isDisabled } = props;

  const authentication = useAppSelector(state => state.authentication);

  const { data: recentActivity } = useUserRecentActivity({
    // Since this API should not be triggered at login or in studio
    enabled: !!authentication.user.id && !isDisabled
  });

  const location = useLocation();

  const workflowId = Number(getWorkflowIdFromPathname(location.pathname));

  const { recentlyViewedWorkflows } = useSyncRecentlyViewedGodaam<number>(
    workflowId,
    recentActivity?.recentlyViewedWorkflows
  );

  const { mutateAsync: updateUserRecentActivity } =
    useUpdateUserRecentActivity();

  const shouldUpdateRecentlyViewedWorkflows =
    recentlyViewedWorkflows.length > 0 &&
    !isDisabled &&
    !isEqual(recentlyViewedWorkflows, recentActivity?.recentlyViewedWorkflows);

  useEffect(() => {
    if (shouldUpdateRecentlyViewedWorkflows) {
      const recentActivityInterval = setInterval(() => {
        updateUserRecentActivity({
          recently_viewed_workflows: recentlyViewedWorkflows
        }).then(() => {
          // Makes sure we fetch new list after every update
          queryClient.invalidateQueries({ queryKey: ["userRecentActivity"] });
        });
      }, SYNC_RECENT_ACTIVITY_INTERVAL);

      return () => {
        clearInterval(recentActivityInterval);
      };
    }
  }, [
    recentlyViewedWorkflows,
    shouldUpdateRecentlyViewedWorkflows,
    updateUserRecentActivity
  ]);

  return null;
};

export const useRecentlyViewed = () => {
  const {
    recentlyViewedWorkflows: recentlyViewedWorkflowIds,
    onUpdateRecentlyViewed
  } = useSyncRecentlyViewedGodaam<number>();

  return { recentlyViewedWorkflowIds, onUpdateRecentlyViewed };
};
