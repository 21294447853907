//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Eye = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Eye"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8C0 8 3.2946 3 8 3C12.7531 3 16 8 16 8C16 8 13.6468 13 8 13C2.39991 13 0 8 0 8ZM4.70631 8C4.70631 9.77262 6.16477 11.1813 8 11.1813C9.83523 11.1813 11.2937 9.77262 11.2937 8C11.2937 6.22738 9.83523 4.81866 8 4.81866C6.16477 4.81866 4.70631 6.22738 4.70631 8ZM8.00003 9.36355C8.77999 9.36355 9.41174 8.75336 9.41174 8C9.41174 7.24664 8.77999 6.63644 8.00003 6.63644C7.22006 6.63644 6.58832 7.24664 6.58832 8C6.58832 8.75336 7.22006 9.36355 8.00003 9.36355Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
