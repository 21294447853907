import type { FieldTypes } from "./fieldTypes";
import type { HashMap } from "./common";
export enum CommentObjectTypes {
  WORKFLOW = "workflow",
  STEP = "step",
  FIELD = "field",
  INTEGRATION_RESULT = "integrationresult",
  CONSOLIDATED = "all_data"
}

export enum CommentMode {
  CONSOLIDATED = "consolidated",
  SINGLE_CHANNEL = "single_channel"
}

export type ChannelUserGroup = {
  id: number;
  name: string;
};

export type ChannelUser = {
  id: number;
  email: string;
  full_name: string;
  username: string;
};

export type CommentThreadMeta = {
  threadId: number;
  threadName: string;
  messageCount: number;
  isPublic: boolean;
  threadUserGroups: ChannelUserGroup[];
  threadUsers: ChannelUser[];
  subscribedBy: number[];
};

export type CommentMention = {
  display: string;
  id: string; // like u123, or g1234
  isBot: boolean;
};

export type CommentMessage = {
  message: string;
  attachment: string;
  // mentions:
  createdAt: string;
  postedBy: string;
  postedById: number;
  messageId: number | null;
};

export type ConversationThread = {
  conversationId?: string;
  objectId: string;
  objectType: string;
  path?: {
    groupId: string;
    groupName: string;
    stepId: string;
    stepName: string;
    fieldId: string;
    fieldName: string;
  };
};

export type CommentThread = Omit<
  CommentThreadMeta,
  "isPublic" | "threadUsers"
> & {
  messages: CommentMessage[];
  groupedMessages: {
    [date: string]: CommentMessage[];
  };
};

export type WorkflowFamily = {
  name: string;
}[];

export type CommentThreads = {
  [key: number]: CommentThread;
};

export type CommentChannel = {
  objectId: number;
  objectType: CommentObjectTypes;
  target: HashMap;
  isIntegrationType?: boolean;
  integrationUID?: string;
  fieldId?: number;
  fieldName?: string;
  fieldType?: FieldTypes;
  parentFieldId?: number;
  parentRowUID?: string;
  stepGroupId?: number;
  stepGroupName?: string;
  stepId?: number;
  stepName?: string;
  workflowId: number;
  childWorkflowId?: number;
  childWorkflowName?: string;
  childWorkflowKind?: number;
  // flagOptions
  channelUserGroups: ChannelUserGroup[];
  channelUsers: ChannelUser[];
  allThreads: CommentThreadMeta[];
  threads: CommentThreads;
};

export type ThreadRaw = {
  id: string | number;
  messages: {
    message: string;
    attachment: string;
    created_at: string;
    posted_by: {
      id: number;
    };
    id: number;
    thread: number;
  }[];
};

export type Subscription = {
  id: number;
  name: string;
  type: CommentObjectTypes;
  channel: number;
  workflow: {
    id: number;
    name: string;
  };
  step: {
    id: number;
    name: string;
  };
  stepGroup: {
    id: number;
    name: string;
  };
  field: {
    id: number;
    name: string;
  };
  integrationResult: null | { uid: number };
};

export type SubscriptionList = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Subscription[];
};
