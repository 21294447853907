import {
  useQuery,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult
} from "react-query";
import {
  type GetAllKindsArgs,
  getAllKinds,
  getWorkflowKindsDynamicAPI
} from "../services/kinds.services";
import { type Kind, PermissionTypes } from "@certa/types";

import { useCheckPermission } from "@certa/common/utils/permissions/Chowkidar";
import type { UseQueryReturnType } from "@certa/common/types";

export const getKindsQuery = (params?: GetAllKindsArgs) => {
  return {
    queryKey: ["kinds", params] as QueryKey,
    queryFn: () => getAllKinds(params),
    // 5 hrs
    staleTime: 1000 * 60 * 60 * 5
  };
};

export const useKinds = (
  params?: GetAllKindsArgs,
  config?: UseQueryOptions<UseQueryReturnType<typeof getAllKinds>>
): UseQueryResult<Kind[], unknown> => {
  return useQuery({
    ...getKindsQuery(params),
    ...config
  });
};

export const useKindId = (kindTag: string) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.tag === kindTag)?.id;
};

export const useKind = (kindTag: string) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.tag === kindTag);
};

export const useKindFromId = (kindId: number) => {
  const { data: kinds } = useKinds();
  return kinds?.find(kind => kind.id === kindId);
};

export const useCreatableWorkflowKind = (
  kindTag: string,
  disableIsRelatedKindCheck?: boolean
) => {
  const { data: kinds } = useKinds();
  const canCreateWorkflows = useCheckPermission(
    PermissionTypes.CAN_ADD_WORKFLOW
  );
  return canCreateWorkflows
    ? kinds?.find(
        kind =>
          kindTag === kind.tag &&
          (disableIsRelatedKindCheck || kind.isRelatedKind) &&
          kind.features.includes("add_workflow")
      )
    : undefined;
};

type UseGetWorkflowKindsDynamicAPIParams = {
  filters: {
    id__in?: number[];
    tag__in?: string[];
  };
};

export const useGetWorkflowKindsDynamicAPI = (
  params: UseGetWorkflowKindsDynamicAPIParams,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof getWorkflowKindsDynamicAPI>
  >
) => {
  const { filters } = params;

  const transformedParams = {
    data: {
      id: null,
      name: null,
      tag: null,
      related_kind_tags: null,
      dynamic_group_names: null
    },
    filters: {
      id__in: filters?.id__in?.join(","),
      tag__in: filters?.tag__in?.join(",")
    }
  };
  return useQuery({
    queryKey: ["workflow-kinds-dynamic-api"],
    queryFn: () => getWorkflowKindsDynamicAPI(transformedParams)
  });
};
