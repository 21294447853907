//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronUp = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronUp"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.53346 5.60657L2.38686 9.75449C2.11405 10.0297 1.97436 10.4093 2.00389 10.7951C2.03342 11.1814 2.22889 11.5357 2.54015 11.7659C3.08905 12.1512 3.83675 12.0804 4.30355 11.599L8.00063 7.90062L11.7351 11.6351C12.0103 11.9089 12.3909 12.049 12.7776 12.019C13.1644 11.9895 13.5192 11.7926 13.7494 11.4805C14.1347 10.9315 14.0639 10.1843 13.5825 9.71753L9.46782 5.60657C9.07829 5.21844 8.55095 5 8.00058 5C7.45074 5 6.92292 5.21844 6.53334 5.60657H6.53346Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
