//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const OpenInWindow = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="OpenInWindow"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3449)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.35236 1.06337C9.35236 0.476085 9.82845 0 10.4157 0H14.9366C15.5239 0 16 0.476085 16 1.06337V5.5533C16 6.14058 15.5239 6.61667 14.9366 6.61667C14.3493 6.61667 13.8732 6.14058 13.8732 5.5533V3.76691L8.0636 9.64627C7.65081 10.064 6.97753 10.068 6.55979 9.65524C6.14205 9.24245 6.13804 8.56918 6.55082 8.15144L12.5041 2.12674H10.4157C9.82845 2.12674 9.35236 1.65065 9.35236 1.06337ZM1.18666 2.00358C0.599376 2.00358 0.123291 2.47966 0.123291 3.06695V15.0239C0.123291 15.6111 0.599376 16.0872 1.18666 16.0872H13.1428C13.7301 16.0872 14.2062 15.6111 14.2062 15.0239V8.81204C14.2062 8.22476 13.7301 7.74867 13.1428 7.74867C12.5555 7.74867 12.0794 8.22476 12.0794 8.81204V13.9605H2.25003V4.13032H7.3977C7.98498 4.13032 8.46107 3.65423 8.46107 3.06695C8.46107 2.47966 7.98498 2.00358 7.3977 2.00358H1.18666Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3449">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
