import { useState, useEffect, useCallback, forwardRef, useRef } from "react";
import { Clear } from "@certa/icons/components/Clear";

import { classNames, filterProperties, mergeRefs } from "../../../utils/common";
import type { FieldStatus, TextInputContainerStyle } from "../../types";

import styles from "./Text.module.css";
import { TEXT_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES } from "../../../constants/styles";

export enum TextInputSizes {
  DEFAULT = "Default",
  SMALL = "Small"
}

export type TextInputStyleConfig = {
  container?: TextInputContainerStyle;
};

type InputStyleAllowedPropertiesType = {
  container: (keyof TextInputContainerStyle)[];
};

const InputStyleAllowedProperties: InputStyleAllowedPropertiesType = {
  container: TEXT_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES
};

export type TextInputProps = {
  name?: string;
  id?: string;
  label: string;
  "aria-describedby"?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  allowClear?: boolean;
  autoComplete?: "on" | "off";
  value?: string;
  placeholder?: string;
  size?: TextInputSizes;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  width?: string;
  error?: string;
  status?: FieldStatus;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onChange?: (value: string, evt: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (value: string, evt: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  htmlType?: "text" | "password" | "email" | "search" | "url";
  onPointerDown?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  styleConfig?: TextInputStyleConfig;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, forwardedRef) => {
    const {
      name,
      id,
      label,
      htmlType = "text",
      "aria-describedby": ariaDescribedBy,
      value,
      onClick,
      onChange,
      onClear,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      onKeyPress,
      onPointerDown,
      onMouseEnter,
      onMouseLeave,
      disabled: isDisabled = false,
      readOnly: isReadOnly = false,
      required: isRequired = false,
      allowClear: shouldAllowClear = true,
      autoComplete,
      autoFocus: shouldAutoFocus,
      placeholder,
      size = TextInputSizes.DEFAULT,
      leftIcon,
      rightIcon,
      width,
      status,
      error,
      styleConfig = {}
    } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const textInputRef = useRef<HTMLInputElement>();

    const [hasValue, setHasValue] = useState(!!value);
    const { focusedBorder: containerFocusedBorder = "", ...containerStyles } =
      styleConfig?.container
        ? filterProperties(
            styleConfig.container,
            InputStyleAllowedProperties.container
          )
        : {};
    const { border: containerBorder = "" } = containerStyles;

    useEffect(() => {
      setHasValue(!!value);
    }, [value]);

    const handleOnChange = useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        setHasValue(!!value);
        onChange && onChange(value, evt);
      },
      [onChange]
    );

    const handleFocus = useCallback(
      (evt: React.FocusEvent<HTMLInputElement>) => {
        onFocus && onFocus(evt);
        // TODO: find a better way to change boader on focus (try to extract emotions functionality)
        if (containerFocusedBorder && containerRef?.current) {
          containerRef.current.style.border = containerFocusedBorder;
        }
      },
      [onFocus, containerFocusedBorder]
    );

    const handleOnBlur = useCallback(
      (evt: React.FocusEvent<HTMLInputElement>) => {
        onBlur && onBlur(evt.target.value, evt);
        if (containerBorder && containerRef?.current) {
          containerRef.current.style.border = containerBorder;
        }
      },
      [onBlur, containerBorder]
    );

    const handleOnClear = (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation();
      setHasValue(false);
      if (textInputRef.current) {
        textInputRef.current.value = "";
        textInputRef.current.focus();
        onChange?.("", {
          ...evt,
          target: textInputRef.current as EventTarget & HTMLInputElement,
          currentTarget: textInputRef.current as EventTarget & HTMLInputElement
        });
      } else {
        // This is just a fallback in case the ref is not available
        onChange?.("", { ...evt, target: { value: "" } } as any);
      }
      onClear?.(evt);
    };

    const ariaDescribedById = ariaDescribedBy
      ? ariaDescribedBy
      : !!error
        ? id + "-error"
        : undefined;

    return (
      <div
        className={classNames({
          [`${styles["catalystTextInputContainer"]}`]: true,
          [`${styles["catalystTextInputContainer" + size]}`]: true,
          [`${styles["catalystTextInputContainer" + status]}`]:
            !!status && !isDisabled,
          [`${styles["catalystTextInputContainerDisabled"]}`]: isDisabled,
          [`${styles["catalystTextInputHasValue"]}`]: hasValue
        })}
        style={{
          width: width,
          ...containerStyles
        }}
        ref={containerRef}
        onClick={onClick}
        onPointerDown={onPointerDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {leftIcon && (
          <div className={styles.catalystTextInputLeftIcon}>{leftIcon}</div>
        )}
        <input
          name={name}
          id={id}
          aria-label={label}
          type={htmlType}
          className={classNames({
            [`${styles["catalystTextInput"]}`]: true
          })}
          disabled={isDisabled}
          placeholder={placeholder}
          required={isRequired}
          aria-invalid={!!error}
          aria-required={isRequired}
          aria-describedby={ariaDescribedById}
          ref={mergeRefs([textInputRef, forwardedRef])}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          autoFocus={shouldAutoFocus}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          value={value}
          readOnly={isReadOnly}
        />
        {rightIcon && (
          <div className={styles.catalystTextInputRightIcon}>{rightIcon}</div>
        )}
        {shouldAllowClear && hasValue && (
          <div
            role="button"
            className={styles.catalystTextInputClearIcon}
            onClick={handleOnClear}
            aria-label="clear input"
          >
            <Clear size={12} aria-hidden={true} />
          </div>
        )}
      </div>
    );
  }
);
