import type { CascaderOptionType } from "@certa/catalyst/components/Cascader";
import type { StepGroupsData } from "@certa/queries/types/workflow.types";
import type { CascaderOption } from "@certa/types";
import { FieldTypes } from "@certa/types";
import { getLastObject } from "./helper";
import { LENGTH_ZERO } from "../constants/common";
import { systemFieldsDetailsMapping } from "../constants/systemFields";
import type { SourceMapping } from "../constants/systemFields";

export type FieldMap = Record<
  string,
  {
    path: string[];
    pathLabels: string[];
    fieldType: FieldTypes;
    fieldLabel: string;
    source_mapping?: SourceMapping;
  }
>;

const pathArray: string[] = [];
const pathLabels: string[] = [];

export type FieldHierarchyTree = {
  label: string;
  value: string;
  tag: string;
  field_type: string;
  source_mapping?: SourceMapping;
  children?: FieldHierarchyTree[];
};

export type FieldLabelMapper = {
  [x: string]: Omit<FieldMap["x"], "fieldType" | "pathLabels" | "path">;
};

export type FieldMetricMapper = {
  [x: string]: Omit<FieldMap["x"], "fieldType" | "pathLabels" | "path"> & {
    dataType: string;
    fieldType?: FieldTypes;
    colType: "field" | "attr";
    path?: string[];
  };
};

/**
 * Pre-order tree traversal
 * This collects the path of the field-tag from the hierarchy API response
 */
const traversal = (root: FieldHierarchyTree, map: FieldMap) => {
  if (Array.isArray(root.children) && root.children.length) {
    pathArray.push(root.value);
    pathLabels.push(root.label);
    root.children.forEach(child => {
      traversal(child, map);
    });
    pathArray.pop();
    pathLabels.pop();
  } else {
    map[root.value] = {
      path: pathArray.slice(),
      fieldLabel: root.label,
      fieldType: getFieldTypeFromSourceMapping(
        root.field_type as FieldTypes,
        root?.source_mapping
      ),
      source_mapping: root?.source_mapping,
      pathLabels: pathLabels.slice()
    };
  }
};

export const getFieldTypeFromSourceMapping = (
  fieldType: FieldTypes,
  sourceMapping: SourceMapping | undefined
) => {
  if (fieldType === FieldTypes.SYSTEM && sourceMapping) {
    return (
      systemFieldsDetailsMapping[sourceMapping]?.fieldType ?? FieldTypes.TEXT
    );
  }
  return fieldType;
};

export const createMapping = (root: FieldHierarchyTree) => {
  const map: FieldMap = {};
  traversal(root, map);
  return map;
};

type PathMapping = Record<string, string[]>;
export const createPathFromCascader = (
  node: CascaderOptionType,
  path: string[] = []
): PathMapping => {
  const mapping: PathMapping = {};
  if (node) {
    if (node.value !== undefined && node.value !== null) {
      path.push(String(node.value));
    }
    if (node.children?.length) {
      for (const child of node.children) {
        const childMapping = createPathFromCascader(child, [...path]);
        for (const key in childMapping) {
          if (mapping[key]) {
            mapping[key] = mapping[key].concat(childMapping[key]);
          } else {
            mapping[key] = childMapping[key];
          }
        }
      }
    } else {
      if (path.length > LENGTH_ZERO) {
        mapping[getLastObject(path) as string] = path;
      }
    }
  }
  return mapping;
};
export type CascaderOptionMapping = Record<
  string,
  { path: string[]; option?: CascaderOptionType }
>;
export const createMappingFromCascader = (
  node: CascaderOptionType,
  path: string[] = []
): CascaderOptionMapping => {
  const mapping: CascaderOptionMapping = {};
  if (node) {
    if (node.value !== undefined && node.value !== null) {
      path.push(String(node.value));
    }
    if (node.children?.length) {
      for (const child of node.children) {
        const childMapping = createMappingFromCascader(child, [...path]);
        for (const key in childMapping) {
          if (mapping[key]) {
            mapping[key] = {
              option: mapping[key].option,
              path: mapping[key].path.concat(childMapping[key].path)
            };
          } else {
            mapping[key] = {
              option: childMapping[key].option,
              path: childMapping[key].path
            };
          }
        }
      }
    } else {
      if (path.length > LENGTH_ZERO) {
        mapping[getLastObject(path) as string] = { path, option: node };
      }
    }
  }
  return mapping;
};

export const getProfileAccountsStepId = (stepGroupData: StepGroupsData) => {
  const accountsStepGroup = stepGroupData.results.find(
    stepGroup => stepGroup.definitionTag === "up_account_settings"
  );
  const accountDetailsStep = accountsStepGroup?.steps.find(
    step => step.definitionTag === "up_details"
  );
  return accountDetailsStep?.id;
};

export const createMappingForSLAAndSGLA = (
  root: FieldHierarchyTree[] | undefined
) => {
  const mapping: SLAAndSGLAMapping = {};
  if (root) {
    root.forEach((stepGroup: FieldHierarchyTree) => {
      if (stepGroup.children?.length) {
        stepGroup.children.forEach((step: FieldHierarchyTree) => {
          const stepTag = step.value;
          mapping[stepTag] = {
            stepGroupTag: stepGroup.value,
            stepGroupLabel: stepGroup.label,
            label: step.label,
            value: stepTag,
            type: "step"
          };
        });
      }
      const stepGroupTag = stepGroup.value;
      mapping[stepGroupTag] = {
        label: stepGroup.label,
        value: stepGroupTag,
        type: "stepGroup"
      };
    });
  }
  return mapping;
};

export type SLAAndSGLAMappingValue = {
  stepGroupTag?: string;
  stepGroupLabel?: string;
  label: string;
  value: string;
  type: "stepGroup" | "step";
};

export type SLAAndSGLAMapping = Record<string, SLAAndSGLAMappingValue>;

export type StepGroup = {
  label: string;
  value: string;
  children: Step[];
};

export type Step = {
  label: string;
  value: string;
  children: Field[];
};

export type Field = {
  label: string;
  value: string;
  field_type: FieldTypes;
};

export const getExtraJSONForCascader = (
  answer: string,
  cascaderOptions: CascaderOption[] | undefined
) => {
  const path = answer.split("~");
  const selectedChildList: CascaderOption[] = [];
  let nestedOptions = cascaderOptions;
  path.forEach(value => {
    if (nestedOptions) {
      const option = nestedOptions.find(option => option.value === value);
      if (option) {
        selectedChildList.push(option);
        nestedOptions = option.children ? [...option.children] : undefined;
      }
    }
  });
  let extraJson = {} as CascaderOption;
  const FIRST_CHILD = 0;
  selectedChildList.reverse().forEach((child, i) => {
    if (i === FIRST_CHILD) {
      extraJson = { ...child };
    } else {
      extraJson = {
        ...child,
        children: [{ ...extraJson }]
      };
    }
  });
  return extraJson;
};
