import { RequestHeaders, APIFetchV2, APIFetchV3 } from "@certa/network";
import {
  kindsModelCreator,
  kindsHeirarchyModelCreator,
  type KindsHeirarchyResponse,
  workflowKindsDynamicAPIModelCreator,
  type WorkflowKindsDynamicAPIResponse
} from "../models/kinds.models";

export type GetAllKindsArgs = { hideInternal?: boolean };

export function getAllKinds(params: GetAllKindsArgs = {}) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const searchParams = new URLSearchParams({
    limit: "200",
    ...(params.hideInternal ? { hide_internal: "True" } : undefined)
  });

  // Essentially this API shouldn't have limit param because we always need
  // all the workflow kinds that are available within the system.
  return APIFetchV2<any[]>(
    `workflow-kinds/?${searchParams}`,
    requestOptions
  ).then(kindsModelCreator);
}

export const workflowKindsHeirarchy = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  return APIFetchV3<KindsHeirarchyResponse>(
    "workflow-kind-hierarchy/",
    requestOptions
  ).then(kindsHeirarchyModelCreator);
};

export const getWorkflowKindsDynamicAPI = (payload: any) => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(payload)
  };

  return APIFetchV3<WorkflowKindsDynamicAPIResponse>(
    `workflow-kind-list-drest/`,
    requestOptions
  ).then(workflowKindsDynamicAPIModelCreator);
};
