//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Language = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Language"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3596)">
            <path
              d="M0 0V9.14291H9.14291V0H0ZM4.00002 1.7143H5.14289L7.42861 7.42861H6.28575L5.82789 6.28575H3.31505L2.8572 7.42861H1.71433L4.00002 1.7143ZM4.57145 3.14288L3.77145 5.14298H5.37217L4.57145 3.14288ZM10.2858 6.85709V7.99995H14.8572V14.8571H8.00005V10.2857H6.85718V16H16.0001V6.85709H10.2858ZM10.8572 8.57139V9.71425H8.57148V10.8571H9.74577C9.83578 11.6493 10.1172 12.2921 10.5094 12.7936C9.9215 13.045 9.21293 13.1429 8.57215 13.1429V14.2857C9.46287 14.2857 10.52 14.15 11.4293 13.6057C12.3386 14.15 13.3958 14.2857 14.2865 14.2857V13.1429C13.645 13.1429 12.9372 13.045 12.3493 12.7936C12.7407 12.2921 13.0221 11.6493 13.1129 10.8571H14.2871V9.71425H12V8.57139H10.8572ZM10.8965 10.8571H11.9615C11.8865 11.4285 11.6965 11.8364 11.4293 12.1443C11.1615 11.8371 10.9722 11.4285 10.8972 10.8571H10.8965Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3596">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
