//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const AccordionCollapse = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="AccordionCollapse"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3461)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 10.4874L0 5.51983C0 5.06606 0.484076 4.82613 0.760295 5.14743L2.87921 7.60411C3.04026 7.79083 3.04026 8.11109 2.87921 8.32494L0.760295 10.8348C0.484076 11.1822 0 10.9412 0 10.4874ZM16 10.4874V5.51983C16 5.06606 15.5159 4.82613 15.2397 5.14743L13.1208 7.60411C12.9597 7.79083 12.9597 8.11109 13.1208 8.32494L15.2397 10.8348C15.5159 11.1822 16 10.9412 16 10.4874Z"
              fill="currentColor"
            />
            <rect
              opacity={0.5}
              x={5}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 5 16)"
              fill="currentColor"
            />
            <rect
              opacity={0.5}
              x={9}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 9 16)"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3461">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
