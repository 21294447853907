//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const FilterLines = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="FilterLines"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.1429 8.57143C13.1429 8.72298 13.0827 8.86832 12.9755 8.97549C12.8683 9.08265 12.723 9.14286 12.5714 9.14286H3.42857C3.27702 9.14286 3.13167 9.08265 3.02451 8.97549C2.91735 8.86832 2.85714 8.72298 2.85714 8.57143C2.85714 8.41987 2.91735 8.27453 3.02451 8.16737C3.13167 8.0602 3.27702 8 3.42857 8H12.5714C12.723 8 12.8683 8.0602 12.9755 8.16737C13.0827 8.27453 13.1429 8.41987 13.1429 8.57143ZM15.4286 4.57143H0.571429C0.419876 4.57143 0.274531 4.63163 0.167368 4.73879C0.0602039 4.84596 0 4.9913 0 5.14285C0 5.29441 0.0602039 5.43975 0.167368 5.54692C0.274531 5.65408 0.419876 5.71428 0.571429 5.71428H15.4286C15.5801 5.71428 15.7255 5.65408 15.8326 5.54692C15.9398 5.43975 16 5.29441 16 5.14285C16 4.9913 15.9398 4.84596 15.8326 4.73879C15.7255 4.63163 15.5801 4.57143 15.4286 4.57143ZM9.71429 11.4286H6.28571C6.13416 11.4286 5.98882 11.4888 5.88165 11.5959C5.77449 11.7031 5.71429 11.8484 5.71429 12C5.71429 12.1516 5.77449 12.2969 5.88165 12.4041C5.98882 12.5112 6.13416 12.5714 6.28571 12.5714H9.71429C9.86584 12.5714 10.0112 12.5112 10.1183 12.4041C10.2255 12.2969 10.2857 12.1516 10.2857 12C10.2857 11.8484 10.2255 11.7031 10.1183 11.5959C10.0112 11.4888 9.86584 11.4286 9.71429 11.4286Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
