import { getLayoutContainer } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import {
  // TODO: Create a new config for reports to improve the UX
  cascaderSearchConfig
} from "main/src/modules/dashboard/workflowToolbar/components/AdvancedFilter";
import type { CascaderOptionType } from "@certa/catalyst/components/Cascader";
import { Cascader } from "@certa/catalyst/components/Cascader";
import { getLastArrayElement } from "../../../utils/helper";
import type { RCFConditionProps } from "../AdvancedFilters.types";
import type { ReportCustomFilterCondition } from "@certa/types";

const SCREEN_HEIGHT_THRESHOLD = 900;

type Props = Pick<
  RCFConditionProps,
  "cascaderOptions" | "disabledCascader" | "optionsIsLoading" | "getFieldType"
> & {
  value: ReportCustomFilterCondition["field"];
  onChange: (value: ReportCustomFilterCondition["field"]) => void;
};

export const CascaderField = ({
  value,
  getFieldType,
  cascaderOptions,
  disabledCascader,
  optionsIsLoading,
  onChange
}: Props) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: "common.selectField",
    defaultMessage: "Select a field"
  });
  const cascaderPlacement =
    window.innerHeight > SCREEN_HEIGHT_THRESHOLD ? "bottomLeft" : "topLeft";

  return (
    <Cascader
      minWidth="110px"
      placement={cascaderPlacement}
      options={cascaderOptions}
      showSearch={{
        ...cascaderSearchConfig,
        matchInputWidth: false
      }}
      onChange={(value: string[] | undefined = []) =>
        onChange({
          path: value,
          fieldType: getFieldType(value)
        })
      }
      value={value?.path}
      disabled={disabledCascader}
      label={label}
      placeholder={label}
      loading={optionsIsLoading}
      displayRender={(_: string[], selectedOptions?: CascaderOptionType[]) => {
        const title = getLastArrayElement(selectedOptions)?.labelText;
        return <div title={title}>{title}</div>;
      }}
      getPopupContainer={() => getLayoutContainer()}
    />
  );
};
