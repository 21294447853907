import type { ComponentType } from "react";
import * as Sentry from "@sentry/react";

export const withSentryProfiler = (
  Component: ComponentType,
  componentName: string
) => {
  return Sentry.withProfiler(Component, {
    name: componentName
  });
};
