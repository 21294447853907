//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Tasks = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Tasks"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3237)">
            <path
              d="M13.6 0C14.9256 0 16 1.07437 16 2.4V13.6C16 14.9256 14.9256 16 13.6 16H2.4C1.07437 16 0 14.9256 0 13.6V2.4C0 1.07437 1.07437 0 2.4 0H13.6ZM13.6 1.6H2.4C1.95813 1.6 1.6 1.95813 1.6 2.4V13.6C1.6 14.0419 1.95813 14.4 2.4 14.4H13.6C14.0419 14.4 14.4 14.0419 14.4 13.6V2.4C14.4 1.95813 14.0419 1.6 13.6 1.6ZM12.5469 8.26944L12.6169 8.33132C12.9069 8.62132 12.9275 9.07882 12.6788 9.39319L12.6169 9.46256L9.22248 12.857C8.93248 13.147 8.47498 13.1676 8.16123 12.9188L8.09123 12.857L6.39443 11.1602C6.08194 10.8477 6.08194 10.3408 6.39443 10.0283C6.68443 9.73829 7.14194 9.71766 7.45568 9.96641L7.52568 10.0283L8.65693 11.1589L11.4857 8.33139C11.7757 8.04139 12.2332 8.02077 12.547 8.26952L12.5469 8.26944ZM8 6.4C8.44187 6.4 8.8 6.75813 8.8 7.2C8.8 7.61 8.49125 7.94813 8.09312 7.99437L7.99999 7.99999H3.99999C3.55812 7.99999 3.2 7.64186 3.2 7.19999C3.2 6.78999 3.50875 6.45186 3.90688 6.40562L4 6.4H8ZM12 3.2C12.4419 3.2 12.8 3.55813 12.8 4C12.8 4.41 12.4912 4.74813 12.0931 4.79437L12 4.79999H3.99999C3.55812 4.79999 3.2 4.44186 3.2 3.99999C3.2 3.58999 3.50875 3.25186 3.90688 3.20562L4 3.2H12Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3237">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
