//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Location = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Location"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.3392 4.51589C11.9828 1.81755 8.69475 0.726771 5.99641 2.08309C3.29807 3.43944 2.25167 6.84562 3.56361 9.42586C4.18275 10.6497 6.76296 13.8341 7.89812 15.2348C8.17802 15.5884 8.70905 15.5884 9.00393 15.2348C10.1391 13.8341 12.69 10.649 13.3535 9.42586C14.1794 7.92209 14.15 6.12345 13.3391 4.51589H13.3392ZM8.45854 9.83884C6.88106 9.83884 5.61276 8.55621 5.61276 6.99306C5.61276 5.41558 6.881 4.14728 8.45854 4.14728C10.036 4.14728 11.3043 5.42991 11.3043 6.99306C11.3193 8.55624 10.0367 9.83884 8.45854 9.83884Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
