import { get as lodashGet } from "lodash-es";
import type {
  IKind,
  IFieldAnswer,
  IKindStatus,
  ILCData,
  IWorkflowFamily,
  IAlerts,
  IWorkflowItem,
  IFilteredWorkflowsAPIResponse
} from "./interfaces";
import { queryClient } from "@certa/queries/utils/utils";

/**
 *
 * @param {any} jsonFromAPI
 * @returns Array<Kind>
 */

export const kindModelCreator = (jsonFromAPI: any): IKind[] => {
  return jsonFromAPI.map((kind: IKind) => ({
    id: lodashGet(kind, "id", null),
    features: lodashGet(kind, "features", []),
    isSortingFieldEnabled: lodashGet(kind, "is_sorting_field_enabled", false),
    isRelatedKind: lodashGet(kind, "is_related_kind", false),
    name: lodashGet(kind, "name", null),
    tag: lodashGet(kind, "tag", null),
    icon: lodashGet(kind, "icon", null),
    availableStatuses: lodashGet(kind, "available_statuses", null),
    fieldTagsForFilter: lodashGet(
      kind,
      "field_tags_for_filter.0.options",
      []
    ).map((filterTag: IFieldAnswer) => ({
      label: lodashGet(filterTag, "label", null),
      value: lodashGet(filterTag, "value", null)
    })),
    fieldFilterTag: lodashGet(kind, "field_tags_for_filter.0.tag", null)
  }));
};

/**
 * @deprecated
 */
export const statusModelCreator = (jsonFromAPI: any): IKindStatus[] => {
  return (
    jsonFromAPI
      ?.map((status: any) => ({
        id: lodashGet(status, "id", null),
        displayName: lodashGet(status, "display_name", null)
      }))
      .sort((a: any, b: any) => a.displayName.localeCompare(b.displayName)) ||
    []
  );
};

/**
 * TODO: Add description
 * Workflow Item model creator
 * @param workflow => Workflow item json from the API
 * @returns workflowItem
 * @deprecated
 */
const workflow$MC = (workflow: any): IWorkflowItem => {
  const availableStatusesKeyedById: any =
    queryClient.getQueryData("availableStatusesKeyedById") || {};
  return {
    id: workflow.id,
    name: workflow.name,
    createdAt: workflow.created_at,
    sortingPrimaryField: workflow.sorting_primary_field,
    lcData: workflow.lc_data.map(
      (data: any): ILCData => ({
        value: data.value,
        label: data.translated_label || data.label,
        displayType: data.display_type,
        format: data.format
      })
    ),
    workflowFamily: [
      ...workflow.workflow_family.map(
        (family: any): IWorkflowFamily => ({
          name: family.name,
          id: family.id
        })
      ),
      {
        id: workflow.id,
        name: workflow.name
      }
    ],
    alerts: workflow.alerts.map(
      (alert: any): IAlerts => ({
        id: alert.id,
        fieldId: alert.field_id,
        stepId: alert.step_id,
        stepGroupId: alert.step_group_id,
        workflowId: alert.workflow_id,
        catColorLabel: alert.cat_color_label,
        alertTag: alert.alert_tag
      })
    ),
    status: {
      id: workflow.status.id,
      displayName: workflow.status.display_name,
      colorCode: availableStatusesKeyedById[workflow.status.id]?.color_code
    },
    progress: workflow.progress,
    rank: workflow.rank
  };
};
/**
 * @deprecated
 */
export const myStuffFilteredWorkflows$MC = (
  jsonFromAPI: IFilteredWorkflowsAPIResponse
): any => {
  return {
    count: jsonFromAPI.count,
    results: jsonFromAPI.results.map((workflow: any) => workflow$MC(workflow)),
    next: jsonFromAPI.next,
    previous: jsonFromAPI.previous
  };
};
