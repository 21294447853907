import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";
import { ButtonVariants, Button } from "@certa/catalyst/components/Button";
import { useLocation } from "react-router";
import { USER_INTERACTION_REQUIRED_PATH } from "@certa/common/utils/routes";
import { EPHEMERAL_TOKEN_QUERY_PARAM } from "@certa/common/constants";
import { useIntl } from "react-intl";

const StoragePermissionRequiredInstructions = ({
  askForPermission
}: {
  askForPermission: () => void;
}) => {
  const location = useLocation();
  const intl = useIntl();
  const urlParams = new URLSearchParams(location.search);

  const ephemeralToken = urlParams.get(EPHEMERAL_TOKEN_QUERY_PARAM);
  const redirectionInstructions = `${intl.formatMessage({
    id: "autoLogin.permissionsRequired.redirectionInstructions",
    defaultMessage: "Please follow below mentioned instructions"
  })}: `;

  const clickHereText = `${intl.formatMessage({
    id: "commonTextInstances.clickHere",
    defaultMessage: "Click Here"
  })} `;

  const clickInstructions = intl.formatMessage({
    id: "autoLogin.permissionsRequired.clickInstructions",
    defaultMessage: "which will redirect you to new page"
  });

  const closeInstructions = intl.formatMessage({
    id: "autoLogin.permissionsRequired.closeInstructions",
    defaultMessage: "Close that page by clicking on 'Close this page'"
  });

  const afterCloseInstructions = intl.formatMessage({
    id: "autoLogin.permissionsRequired.afterCloseInstructions",
    defaultMessage:
      "After you've closed that page, please follow the instructions on the button below"
  });

  const buttonLineOne = intl.formatMessage({
    id: "autoLogin.permissionsRequired.buttonLineOne",
    defaultMessage: "Please CLICK HERE and then"
  });

  const buttonLineTwo = intl.formatMessage({
    id: "autoLogin.permissionsRequired.buttonLineTwo",
    defaultMessage:
      "Click 'ALLOW' in your browser if a pop-up appears to load the screen"
  });

  return (
    <Stack
      style={{ width: "100%", height: "100vh" }}
      align="center"
      justify="center"
    >
      <Stack>
        <Text variant="h2-regular">{redirectionInstructions}</Text>
        <li>
          <a
            href={`${window.origin}${USER_INTERACTION_REQUIRED_PATH}?${EPHEMERAL_TOKEN_QUERY_PARAM}=${ephemeralToken}`}
            target="_blank"
            rel="noreferrer"
          >
            {clickHereText}
          </a>
          {clickInstructions}
        </li>
        <li>{closeInstructions}</li>
        <li>{afterCloseInstructions}</li>
      </Stack>
      <Button onClick={askForPermission} variant={ButtonVariants.FILLED}>
        <Stack dir="column">
          <span>{buttonLineOne}</span>
          <span>{buttonLineTwo}</span>
        </Stack>
      </Button>
    </Stack>
  );
};

export { StoragePermissionRequiredInstructions };
