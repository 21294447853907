import { useVirtualizer, type VirtualItem } from "@tanstack/react-virtual";
import { useEffect, useRef } from "react";
import {
  useListInteraction,
  type ListInteractionMode
} from "../useListInteraction";
import styles from "./Style.module.css";
import { useCallbackRef } from "../useCallbackRef";

export type VirtualListItem = {
  index: number;
  key: string | number;
  size: number;
  start: number;
  end: number;
};

export type UseVirtualListProps = {
  totalItemsCount: number;
  hasMore?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;

  isCombobox?: boolean;
  onSelect?: (index: number, interactionMode: ListInteractionMode) => void;
  activeIndex: number;
  selectedIndex: number;
  setActiveIndex: (index: number) => void;
  interactionMode?: ListInteractionMode;
  setInteractionMode: (mode: ListInteractionMode) => void;
  rowHeight?: number;
  overscan?: number;
  getOptionId: (index: number) => string;
};

type VirtualStyles = {
  listbox: {
    width: "100%";
    overflow: "auto";
  };
  viewport: {
    height: number;
    width: "100%";
    position: "relative";
  };
  offsetter: {
    position: "absolute";
    top: 0;
    left: 0;
    width: "100%";
    transform: string;
  };
};

export function useVirtualList({
  totalItemsCount,
  hasMore = false,
  isLoading = false,
  onLoadMore,

  isCombobox = false,
  onSelect,
  activeIndex,
  setActiveIndex,
  selectedIndex,
  interactionMode = "mouse",
  setInteractionMode,
  rowHeight = 56,
  overscan = 5,
  getOptionId
}: UseVirtualListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemCount = hasMore ? totalItemsCount + 1 : totalItemsCount;
  const getOptionIdCallbackRef = useCallbackRef(getOptionId);

  const virtualizer = useVirtualizer({
    count: itemCount,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => rowHeight,
    overscan
  });
  const virtualItems = virtualizer.getVirtualItems();

  // Handle infinite scroll
  useEffect(() => {
    const lastItem = virtualItems[virtualItems.length - 1];

    if (
      lastItem &&
      lastItem.index >= totalItemsCount - 1 &&
      hasMore &&
      !isLoading &&
      onLoadMore
    ) {
      onLoadMore();
    }
  }, [virtualItems, totalItemsCount, hasMore, isLoading, onLoadMore]);

  // Scroll selected item into view only when using keyboard nav
  useEffect(() => {
    if (activeIndex === 0) {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
      virtualizer.scrollToOffset(0);
      return;
    }

    if (activeIndex > 0 && scrollRef.current) {
      const element = document.getElementById(
        getOptionIdCallbackRef(activeIndex)
      );
      if (!element) return;
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }, [activeIndex, getOptionIdCallbackRef, virtualizer]);

  const { handleKeyDown, handleMouseMove, handleItemMouseEnter, handleSelect } =
    useListInteraction({
      totalItemsCount,
      activeIndex,
      setActiveIndex,
      interactionMode,
      setInteractionMode,
      getOptionId,
      onSelect
    });

  // Generate styles for virtualization
  const virtualStyles: VirtualStyles = {
    listbox: {
      width: "100%",
      overflow: "auto"
    },
    viewport: {
      height: virtualizer.getTotalSize(),
      width: "100%",
      position: "relative"
    },
    offsetter: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      transform: `translateY(${virtualItems[0]?.start ?? 0}px)`
    }
  };

  // Get the ID of the currently focused option
  const getActiveDescendantId = () =>
    activeIndex >= 0 ? getOptionId(activeIndex) : undefined;

  const getRowProps = (row: VirtualItem) => {
    const isLoaderRow = row.index > totalItemsCount - 1;
    const rowIndex = row.index;
    const isSelected = rowIndex === selectedIndex;
    const isActive = rowIndex === activeIndex;

    return {
      rowKey: row.key,
      isLoaderRow,
      rowProps: {
        ref: virtualizer.measureElement,
        "data-index": row.index
      },
      optionProps: {
        role: "option",
        id: getOptionId(rowIndex),
        "aria-selected": isSelected,
        onMouseEnter: () => handleItemMouseEnter(rowIndex),
        onClick: () => handleSelect(rowIndex, "mouse"),
        tabIndex: isActive ? 0 : -1
      }
    };
  };

  const getListboxProps = ({ label }: { label: string }) => ({
    ref: scrollRef,
    role: "listbox",
    "aria-label": label,
    // Only include aria-activedescendant if not in combobox mode
    ...(isCombobox
      ? {}
      : {
          "aria-activedescendant": getActiveDescendantId()
        }),
    onKeyDown: handleKeyDown,
    onMouseMove: handleMouseMove,
    tabIndex: -1,
    style: virtualStyles.listbox,
    "aria-busy": isLoading,
    className: interactionMode === "keyboard" ? styles.listboxCursorNone : ""
  });

  const getViewportProps = () => ({
    style: virtualStyles.viewport
  });

  const getOffsetterProps = () => ({
    style: virtualStyles.offsetter
  });

  return {
    getListboxProps,
    getViewportProps,
    getOffsetterProps,
    getRowProps,
    state: {
      activeDescendantId: getActiveDescendantId(),
      list: virtualItems
    },
    virtualizer
  };
}
