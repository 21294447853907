import { getFieldResponse } from "../services/appBuilder.services";
import type { FieldResponse } from "../types/generic.types";
import { FieldTypes, type FieldTypeFromBE } from "@certa/types";
import { FETCH_STEPGROUP_ATTR, FETCH_STEP_ATTR } from "@certa/common/constants";

/**
 * Calls the getFieldResponse for each individual provided
 * fieldTag and returns a promise with an array of answers
 * from all fieldTags. Missing/inaccessible field tags return
 * null as their answer.
 * @param processId
 * @param fieldTags
 * @returns
 */
export const batchFieldsResponses = (
  processId: number,
  fieldTags: string[]
) => {
  const promises = fieldTags.map(fieldTag => {
    // We are wrapping it with a custom promise because the API returns a 404 even when there
    // is a field but it may not have a value, which could be an actual use-case.
    return new Promise<string | null>((resolve, reject) => {
      getFieldResponse<FieldResponse>(processId, fieldTag)
        .then(response => resolve(response.answer))
        .catch(err => {
          // probably answer was not present, or the field itself was not present, or uses did't have access to it.
          resolve(null);
        });
    });
  });

  return Promise.all(promises);
};

export const refactorFieldTagsForBE = (fieldTags: string[]) => {
  const fetchFieldTags: string[] = [];
  const fetchStepAttrSet: Record<string, Set<string>> = {};
  const fetchStepGroupAttr: Record<string, string[]> = {};

  fieldTags.forEach(tag => {
    if (tag.includes(FETCH_STEP_ATTR)) {
      const splitTag = tag.split("__");
      let systemAttributes = splitTag.slice(-1)[0];
      const stepTag = splitTag.slice(1, -1).join("__");

      if (
        ["completed_by_name", "completed_by_email"].includes(systemAttributes)
      ) {
        systemAttributes = "completed_by";
      }

      if (fetchStepAttrSet[stepTag])
        fetchStepAttrSet[stepTag].add(systemAttributes);
      else fetchStepAttrSet[stepTag] = new Set([systemAttributes]);
    } else if (tag.includes(FETCH_STEPGROUP_ATTR)) {
      const splitTag = tag.split("__");
      const systemAttributes = splitTag.slice(-1)[0];
      const stepTag = splitTag.slice(1, -1).join("__");

      if (fetchStepGroupAttr[stepTag])
        fetchStepGroupAttr[stepTag].push(systemAttributes);
      else fetchStepGroupAttr[stepTag] = [systemAttributes];
    } else fetchFieldTags.push(tag);
  });

  const fetchStepAttr = Object.keys(fetchStepAttrSet).reduce(
    (fetchStepAttr, stepTag) => {
      fetchStepAttr[stepTag] = Array.from(fetchStepAttrSet[stepTag]);
      return fetchStepAttr;
    },
    {} as Record<string, string[]>
  );

  return {
    fetch_field_tags: fetchFieldTags,
    fetch_step_attr: fetchStepAttr,
    fetch_stepgroup_attr: fetchStepGroupAttr
  };
};

export const isIntegrationField = (field: FieldTypeFromBE): boolean => {
  return field.definition.field_type === FieldTypes.INTEGRATION;
};

export const getFieldTypeFromField = (field: FieldTypeFromBE): FieldTypes => {
  if (isIntegrationField(field)) {
    return field.definition.extra?.api_type || FieldTypes.INTEGRATION;
  }
  return field.definition.field_type;
};
