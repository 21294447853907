//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Kanban = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Kanban"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4ZM4.5 4C4.22386 4 4 4.22386 4 4.5V11.5C4 11.7761 4.22386 12 4.5 12H5.5C5.77614 12 6 11.7761 6 11.5V4.5C6 4.22386 5.77614 4 5.5 4H4.5ZM7.5 4C7.22386 4 7 4.22386 7 4.5V7.5C7 7.77614 7.22386 8 7.5 8H8.5C8.77614 8 9 7.77614 9 7.5V4.5C9 4.22386 8.77614 4 8.5 4H7.5ZM10 4.5C10 4.22386 10.2239 4 10.5 4H11.5C11.7761 4 12 4.22386 12 4.5V9.5C12 9.77614 11.7761 10 11.5 10H10.5C10.2239 10 10 9.77614 10 9.5V4.5Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
