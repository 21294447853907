import { cx, css } from "emotion";
import type { FC } from "react";
import { useCallback, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Stack, Text } from "@certa/blocks/thanos";
import type {
  AdjudicationStatus,
  CommentChannel,
  ConversationThread,
  DropdownStatusDefault,
  WorkflowFamily
} from "@certa/types";

import { CommentsContext } from "../../comments.context";
import { ChannelViewIdentifier } from "./ChannelViewIdentifier";
import { TIMEOUTS } from "@certa/common/constants";
import { FullHeightSpinner } from "@certa/common/components/FullHeightSpinner";
import type { ConversationCommsModel } from "@certa/queries/queries/comms";
import { useCommsNewEndpoints } from "@certa/common/toggles";

type CommentsProps = {
  onStepClick?: (groupId: number, stepId: number, workflowId: number) => void;
  onClose: () => void;
  multiChannel?: boolean;
  readOnly?: boolean;
  visible?: boolean;
  loading?: boolean;
  channels: CommentChannel[];
  defaultThreadId?: number;
  extra?: any;
  header: React.ReactChild;
  status?: string;
  messageId?: string;
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
  activeConversationThreadComms: ConversationThread | null;
  setActiveConversationThreadComms: (
    conversationThread: ConversationThread
  ) => void;
};

const renderSomethingWentWrong = () => (
  <Stack direction="vertical" align="center" justify="center">
    <Text variant="h2-regular" color="red">
      <FormattedMessage id="notificationInstances.somethingWentWrong" />
    </Text>
  </Stack>
);

/**
 * Comments hierarchy:
 * - No Comments
 * - Multi-channel => list of channels => go to a SingleChannel
 * - Single Channel => Threads => Separate message for ever thread
 */

export const CommentsNew: FC<
  CommentsProps & {
    activeChannel: null | CommentChannel;
    setActiveChannel: (channel: null | CommentChannel) => void;
    threadsComms?: ConversationCommsModel;
    threadsCommsStatus?: "loading" | "error" | "idle" | "success";
    workflowFamily?: WorkflowFamily;
    multiChannelWithTabs?: boolean;
  }
> = props => {
  const {
    onStepClick,
    onClose,
    multiChannel: isMultiChannel,
    readOnly: isReadOnly,
    channels,
    defaultThreadId,
    header,
    status,
    activeChannel,
    setActiveChannel,
    threadsComms,
    threadsCommsStatus,
    messageId,
    defaultStatus,
    defaultAdjudicationCode,
    adjudicationRiskCodes,
    workflowFamily,
    multiChannelWithTabs: isMultiChannelWithTabs,
    activeConversationThreadComms,
    setActiveConversationThreadComms
  } = props;

  /**
   * To handle step click event, that gets triggered from the CommentSubHeader
   */
  const handleStepClick = useCallback(
    (
      workflowId: number,
      { groupId, stepId }: { groupId?: number; stepId?: number }
    ) => {
      if (stepId && groupId && onStepClick) {
        onClose?.();
        onStepClick?.(groupId, stepId, workflowId);
      }
    },
    [onClose, onStepClick]
  );

  useEffect(() => {
    if (messageId && status === "success") {
      const messageDOMElement = document.getElementById(messageId);
      messageDOMElement?.scrollIntoView({
        behavior: "auto",
        block: "center"
      });
      messageDOMElement?.classList.add("hightlight-message");
      setTimeout(() => {
        messageDOMElement?.classList.remove("hightlight-message");
      }, TIMEOUTS.MESSAGE_HIGHLIGHT);
    }
  }, [messageId, status]);

  const contextValue = useMemo(
    () => ({
      onStepClick: handleStepClick,
      isReadOnly: !!isReadOnly,
      isMultiChannel: !!isMultiChannel,
      defaultThreadId: defaultThreadId,
      extra: props.extra,
      header,
      activeChannel,
      setActiveChannel,
      activeConversationThreadComms,
      setActiveConversationThreadComms,
      threadsComms,
      threadsCommsStatus,
      workflowFamily
    }),
    [
      handleStepClick,
      isReadOnly,
      isMultiChannel,
      defaultThreadId,
      props.extra,
      header,
      activeChannel,
      setActiveChannel,
      activeConversationThreadComms,
      setActiveConversationThreadComms,
      workflowFamily,
      threadsComms,
      threadsCommsStatus
    ]
  );

  const isCommsNewEndpointsEnabled = useCommsNewEndpoints();

  if (
    (!isCommsNewEndpointsEnabled &&
      (status === "idle" || status === "loading")) ||
    (isCommsNewEndpointsEnabled &&
      (status === "idle" ||
        status === "loading" ||
        threadsCommsStatus === "loading"))
  ) {
    return <FullHeightSpinner />;
  }

  if (
    (!isCommsNewEndpointsEnabled && status === "error") ||
    (isCommsNewEndpointsEnabled && threadsCommsStatus === "error")
  ) {
    return renderSomethingWentWrong();
  }

  return (
    <CommentsContext.Provider value={contextValue}>
      <Stack
        className={cx(
          "comments-new",
          css`
            height: 100%;
          `
        )}
        direction="vertical"
      >
        <ChannelViewIdentifier
          channels={channels}
          threadsComms={threadsComms!}
          defaultStatus={defaultStatus}
          defaultAdjudicationCode={defaultAdjudicationCode}
          adjudicationRiskCodes={adjudicationRiskCodes}
          multiChannelWithTabs={isMultiChannelWithTabs}
        />
      </Stack>
    </CommentsContext.Provider>
  );
};
