import {
  useObjectNameFromKrypton,
  hasFieldDetails
} from "@certa/queries/queries/comms";
import type {
  CommentsObjectType,
  ConversationCommsModel,
  ConversationMessageCommsModel,
  LatestMessage
} from "@certa/queries/queries/comms";
import { groupBy } from "lodash-es";
import { ChevronDown } from "@certa/icons/components/ChevronDown";
import { Messages } from "@certa/icons/components/Messages";
import { Paper, Text, Divider, getAvatarLetters } from "@certa/blocks/thanos";

import {
  AvatarColors,
  AvatarSizes,
  Avatar
} from "@certa/catalyst/components/Avatar";
import {
  ButtonSizes,
  ButtonTypes,
  Button
} from "@certa/catalyst/components/Button";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { Tooltip } from "@certa/catalyst/components/Tooltip";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst/components/Typography";
import { CatalystColors } from "@certa/catalyst/constants/styles";
import { useCommentMessageFormatComms } from "../../hooks/useCommentMessageFormat";
import { css } from "emotion";
import { messageStyles } from "./CommentsRedesigned.styles";
import { useMemo } from "react";
import { HierarchyBreadcrumbsComms } from "./HierarchyBreadcrumbs";
import { format, formatDistanceToNow } from "date-fns";
import { Loader } from "@certa/blocks";
import type { ConversationThread } from "@certa/types";

const ConversationListItemAppRedesign = ({
  threadsComms,
  handleChannelSelectComms
}: {
  threadsComms: ConversationCommsModel;
  handleChannelSelectComms: (conversationThread: ConversationThread) => void;
}) => {
  const conversations = useMemo(() => {
    if (!threadsComms?.results) {
      return [];
    }
    const groupedThreads = groupBy(
      threadsComms.results,
      thread => `${thread.objectType}-${thread.objectId}`
    );

    return Object.entries(groupedThreads).map(([key, threads]) => {
      const messageCount = threads.reduce(
        (sum, thread) => sum + thread.messageCount,
        0
      );
      const latestMessage = threads.reduce((latest, thread) =>
        latest.latestMessage &&
        thread.latestMessage &&
        new Date(thread.latestMessage.postedAt) >
          new Date(latest.latestMessage.postedAt)
          ? thread
          : latest
      );

      return {
        key,
        threads,
        objectType: key.split("-")[0],
        objectId: key.split("-")[1],
        messageCount,
        latestMessage: latestMessage.latestMessage
      };
    });
  }, [threadsComms]);

  return conversations
    .filter(conversation => conversation?.latestMessage?.body)
    .map(conversation => (
      <ConversationItemComms
        key={conversation.key}
        latestConversation={conversation}
        handleChannelSelectComms={handleChannelSelectComms}
      />
    ));
};

type LatestConversationComms = {
  key: string;
  threads: ConversationMessageCommsModel[];
  objectType: string;
  objectId: string;
  messageCount: number;
  latestMessage: LatestMessage;
};

const ConversationItemComms = ({
  latestConversation,
  handleChannelSelectComms
}: {
  latestConversation: LatestConversationComms;
  handleChannelSelectComms: (conversationThread: ConversationThread) => void;
}) => {
  const { latestMessage, objectType, objectId, messageCount } =
    latestConversation;

  const {
    conversationId,
    body: messageBody,
    mentionedUsers,
    mentionedGroups,
    postedBy,
    postedAt
  } = latestMessage;
  const formattedMessage = useCommentMessageFormatComms(
    messageBody,
    mentionedUsers,
    mentionedGroups
  );
  const { data: pathDetails, isLoading: isLoadingPathData } =
    useObjectNameFromKrypton({
      objectType: objectType as CommentsObjectType,
      objectId
    });
  const conversationThread: ConversationThread = {
    conversationId,
    objectId,
    objectType,
    path: {
      groupId: pathDetails?.stepGroupId ?? "",
      groupName: pathDetails?.stepGroupName ?? "",
      stepId: pathDetails?.stepId ?? "",
      stepName: pathDetails?.stepName ?? "",
      fieldId:
        pathDetails && hasFieldDetails(pathDetails) ? pathDetails.fieldId : "",
      fieldName:
        pathDetails && hasFieldDetails(pathDetails) ? pathDetails.fieldName : ""
    }
  };

  return (
    <Paper
      direction="vertical"
      gutter="s2 s4"
      className={css({
        cursor: "pointer",
        padding: "16px",
        borderRadius: "var(--small-border-radius)",
        boxShadow: CatalystColors.NEUTRAL_100,
        "&:hover": {
          backgroundColor: CatalystColors.TEAL_100,
          ".message-count": {
            backgroundColor: CatalystColors.NEUTRAL_200,
            span: {
              color: CatalystColors.NEUTRAL_700
            }
          }
        }
      })}
      onClick={() => handleChannelSelectComms(conversationThread)}
    >
      <Stack direction="horizontal" align="center" justify="space-between">
        {isLoadingPathData ? (
          <Loader size="extra-small" />
        ) : (
          <HierarchyBreadcrumbsComms
            taskLaneName={pathDetails?.stepGroupName ?? ""}
            taskName={pathDetails?.stepName ?? ""}
            fieldName={
              pathDetails && hasFieldDetails(pathDetails)
                ? pathDetails.fieldName
                : ""
            }
          />
        )}
        <Stack direction="horizontal" align="center" gap="16" padding="8 4">
          <Stack
            direction="horizontal"
            align="center"
            gap="4"
            dangerouslySetClassName={
              css({
                backgroundColor: CatalystColors.NEUTRAL_100,
                borderRadius: "var(--small-border-radius)"
              }) + " message-count"
            }
          >
            <Messages size={12} color={CatalystColors.NEUTRAL_700} />
            <Typography
              variant={TypographyVariants.BODY_BOLD}
              color={TypographyColors.NEUTRAL_700}
            >
              {messageCount}
            </Typography>
          </Stack>
          <Button
            rightIcon={<ChevronDown style={{ transform: `rotate(-90deg)` }} />}
            type={ButtonTypes.ICON}
            size={ButtonSizes.SMALL}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="vertical" padding="8 0" gap="16" align="start">
        <Stack direction="horizontal" justify="space-between" width="100%">
          <Stack direction="horizontal" align="center" justify="start" gap="4">
            <Avatar
              size={AvatarSizes.SMALL}
              color={AvatarColors.DARK}
              aria-label={postedBy.fullName}
            >
              {getAvatarLetters(postedBy.fullName)}
            </Avatar>
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_700}
            >
              {postedBy.fullName}
            </Typography>
          </Stack>
          <Stack direction="vertical" align="end" gap="8" width="8rem">
            <Tooltip content={format(new Date(postedAt), "PPpp")}>
              <Typography
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_700}
              >
                {formatDistanceToNow(new Date(postedAt), {
                  addSuffix: true
                })}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
        <Text
          variant="p1-regular"
          color={CatalystColors.NEUTRAL_700}
          lineClamp={3}
          className={messageStyles}
          dangerouslySetInnerHTML={{
            __html: formattedMessage
          }}
        />
      </Stack>
    </Paper>
  );
};

export { ConversationListItemAppRedesign };
