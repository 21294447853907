import type { FC } from "react";
import React from "react";
import { Menu as AntMenu } from "antd";
import type { MenuProps as AntMenuProps } from "antd/lib/menu";
import { getVariantStyles } from "../Typography/utils";
import styled from "@emotion/styled";
import { getLayoutContainer } from "../../utils";

export type MenuProps = Omit<AntMenuProps, "getPopupContainer"> & {
  children: React.ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement | null;
};

const CustomMenu: FC<MenuProps> = props => {
  const { getPopupContainer, ...menuProps } = props;
  return (
    <AntMenu
      getPopupContainer={triggerNode =>
        getPopupContainer?.(triggerNode) ||
        (triggerNode.closest(".ant-dropdown-trigger") as HTMLElement) ||
        getLayoutContainer()
      }
      {...menuProps}
    />
  );
};

export const Menu = styled(CustomMenu)`
  background: var(--neutral-0);
  box-shadow: var(--popover-shadow) !important;
  border-radius: var(--s2);
  padding: var(--s2);
  min-width: 200px;
  max-height: ${window.innerHeight / 3}px;
  overflow-y: auto;

  .ant-menu-submenu,
  .ant-dropdown-menu-submenu {
    color: var(--neutral-100) !important;
    margin: 0 !important;
    border-radius: var(--s1);
    ${getVariantStyles("p1-regular")};
    &.ant-menu-submenu-disabled .ant-menu-submenu-title,
    &.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
      color: var(--neutral-70) !important;
    }
    .ant-menu-submenu-title,
    .ant-dropdown-menu-submenu-title {
      color: inherit;
      padding: var(--s3) var(--s2) var(--s3) var(--s2) !important;
      margin: 0;
      height: auto !important;
      line-height: normal !important;
      border-radius: var(--s1);
      .ant-menu-submenu-arrow,
      .ant-dropdown-menu-submenu-arrow {
        display: none;
      }
    }
    &:not(.ant-menu-submenu-disabled) .ant-menu-submenu-title:hover,
    &:not(.ant-dropdown-menu-submenu-disabled)
      .ant-dropdown-menu-submenu-title:hover {
      background-color: var(--neutral-10);
      color: var(--brand) !important;
      border-radius: var(--s1);
    }
  }
  .ant-menu-submenu-popup,
  .ant-dropdown-menu-submenu-popup {
    border-radius: 0;
    box-shadow: none;
  }

  --label: CustomMenu;
`;
