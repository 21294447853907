const getPreviewBasename = () => {
  const pathname = window.location.pathname;
  return pathname && pathname.split("/")[1];
};

const getPreviewTenant = () => {
  const hostName = window.location.hostname;
  const previewTenant = hostName && hostName.split(".")[0];
  return previewTenant || "walmart";
};

const isPreviewEnabled = import.meta.env.REACT_APP_PREVIEW_ENABLED;

export function getTenant() {
  const url = new URL(window.location.href);
  if (isPreviewEnabled) {
    return getPreviewTenant();
  }
  return url.hostname.split(".")[0];
}

const trustedDomains = new Set(
  import.meta.env.REACT_APP_TRUSTED_DOMAINS?.split(",")
);

// This is duplicated from certa/network/config, but we can't import it here
// since it would create a circular dependency. We should consider moving
// this to a shared location in future
function getHostName() {
  const url = new URL(window.location.href);
  const splitURL = url.hostname.split(".");
  return splitURL.slice(1).join(".");
}

// use getAPIOrigin from network/src/config
function getAPIOrigin() {
  //  preview is only avilable on slackcart
  if (isPreviewEnabled) {
    return "https://previewapi.slackcart.com";
  }
  const hostname = getHostName();
  if (trustedDomains.has(hostname)) return `https://api.${hostname}`;
  else return "https://"; // any API calls will simply fail and show the service-unavailable page
}

function getAcitivityLogsOrigin() {
  const hostname = getHostName();
  return `https://activitylogs.${hostname}/v1/`;
}

const DEFAULT_API_ORIGIN = import.meta.env.REACT_APP_API_ORIGIN;

// Building via scripts keeps the environment as production which can be confusing
// hence the choice of this variable name to be more verbose
const isProdEnv = import.meta.env.PROD;

export const tenant = getTenant();
export const siteOrigin = `${document.location.origin}`;
export const apiBaseURL = isProdEnv
  ? `${getAPIOrigin()}/api/v1/`
  : `${DEFAULT_API_ORIGIN}/api/v1/`;
export const apiBaseURLV2 = isProdEnv
  ? `${getAPIOrigin()}/api/v2/`
  : `${DEFAULT_API_ORIGIN}/api/v2/`;

export const certaGoogleLoginURL =
  siteOrigin + "/api/v1/google_authenticate/form";

export const auditLogBaseURL = getAcitivityLogsOrigin();

/**
 * @deprecated
 */
export const supportedFieldFormats = {
  duns: "##-###-####"
};

export const envTag = import.meta.env.REACT_APP_SENTRY_TAG;
// eslint-disable-next-line no-unused-vars
const prasaranChannelPrefix = `${
  import.meta.env.REACT_APP_PRASARAN_PREFIX
}_${tenant}`;

export const basename = isPreviewEnabled ? `/${getPreviewBasename()}` : "";
