//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Visualisation = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Visualisation"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3847)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0.75C0 0.335786 0.335786 0 0.75 0C1.16421 0 1.5 0.335786 1.5 0.75V14.5H15.25C15.6642 14.5 16 14.8358 16 15.25C16 15.6642 15.6642 16 15.25 16H0.75C0.335786 16 0 15.6642 0 15.25V0.75ZM11.2527 9.76446L15.6007 3.9491L14.3993 3.0509L10.0514 8.86625C9.85696 9.12632 9.51725 9.23172 9.20975 9.12739L7.70162 8.6157C6.83116 8.32036 5.86858 8.58421 5.27037 9.28212L2.93056 12.0119L4.06944 12.9881L6.40926 10.2583C6.60866 10.0257 6.92952 9.93772 7.21967 10.0362L8.72781 10.5479C9.6503 10.8608 10.6694 10.5446 11.2527 9.76446Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3847">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
