import {
  ToastPlacements,
  ToastTypes,
  showToast
} from "@certa/catalyst/components/Toast";
import { gmtDate } from "./time";
import { COPY_TEXT } from "@certa/comments/src/util/copyText";
import { Zip } from "./zip";
import { toPng } from "html-to-image";

export enum FileDownloadTypes {
  BLOB_DOWNLOAD = "BLOB_DOWNLOAD",
  NEW_TAB = "NEW_TAB",
  FILE_DOWNLOAD = "FILE_DOWNLOAD"
}

type ZipFile = {
  name: string;
  content: string;
  option: { base64: boolean; binary: boolean };
};

/**
 * @downloadType String of enum FileDownloadTypes. It will select to either download via blob or open a new tab
 * @url String. This will be the url of where the file is stored at
 * @blob Blob type data of file object. Required only for single file download atm.
 * @fileName Filename string that will be the name of the file once downloaded. Required only for single file download atm.
 */
export function fileDownload({
  downloadType,
  url,
  blob,
  fileName
}: {
  downloadType: FileDownloadTypes;
  url: string;
  blob?: Blob;
  fileName?: string;
}): void {
  try {
    if (downloadType === FileDownloadTypes.FILE_DOWNLOAD && url) {
      // Download file when url is given
      const a = document.createElement("a");
      a.style.display = "none"; //optional
      a.href = url;
      a.download = fileName ? fileName : "";
      document.body.appendChild(a);
      a.click();
    } else if (
      downloadType === FileDownloadTypes.BLOB_DOWNLOAD &&
      blob &&
      url
    ) {
      const fileURL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none"; //optional
      a.href = fileURL;
      a.download = fileName ? fileName : "";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (downloadType === FileDownloadTypes.NEW_TAB && url) {
      window.open(url, "_blank");
    }
  } catch (error) {
    showToast({
      type: ToastTypes.ERROR,
      placement: ToastPlacements.BOTTOM_RIGHT,
      title: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.TITLE,
      description: COPY_TEXT.DOWNLOAD_FILE.ERROR.DEFAULT.DESCRIPTION
    });
  }
}

/**
 * @param files Array of files to be zipped
 * @param zipFileName Name of the zip file to be downloaded
 * Download a zip file
 */
export const zipDownload = async (files: ZipFile[], zipFileName: string) => {
  const zip = new Zip();
  await zip.addFiles(files);
  await zip.downloadAsync(zipFileName);
};

export const fileNameWithTimeStamp = (fileName: string): string => {
  const date = new Date();
  const timeStamp = gmtDate(date);
  return fileName + " " + timeStamp;
};

const HORIZONTAL_PADDING = 24;
const VERTICAL_PADDING = 48;

/**
 * @param ref ref of the container
 * @returns Data URL of the container
 */
export const getDataURL = (ref: React.RefObject<HTMLDivElement>) => {
  if (!ref?.current) {
    return undefined;
  }
  return toPng(ref?.current, {
    pixelRatio: 1,
    width: ref.current.offsetWidth + HORIZONTAL_PADDING,
    height: ref.current.offsetHeight + VERTICAL_PADDING,
    backgroundColor: "#FFF"
  });
};
