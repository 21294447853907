import type { StudioTemplateTypes } from "@certa/types";
import type { PaginatedResultsResponse } from "../types/generic.types";
import type {
  StudioAppResponse,
  StudioApp,
  StudioProcessResponse,
  StudioProcess,
  UserResponse,
  User,
  StudioBranchSavePointResponse,
  StudioBranchSavePoint,
  StudioProcessBySavePointResponse,
  StudioProcessBySavePoint,
  SavepointDeploymentLogResponse,
  SavepointDeploymentLog,
  ProcessDeploymentLogResponse,
  ProcessDeploymentLog,
  StudioPermissions,
  StudioPermissionsResponse,
  StudioCentralTemplate,
  TemplateIntegrationTypes,
  TemplateStatusTypes,
  AppSettingsDeploymentError,
  AppSettingErrorSaveLog,
  AppsettingsErrorDetails,
  StudioBranch,
  StudioBranchResponse,
  ObjectMetaDataResponse,
  ObjectMetaData,
  ObjectSchemaRaw,
  ObjectSchema,
  SDODeploymentLogResponse,
  SDODeploymentLog,
  SDOSavePointStatusResponse,
  SDOSavePointStatus,
  SDODeploymentPayloadResponse,
  SDODeploymentPayload,
  SDOObjectLockResponse,
  SDOObjectLock,
  SDOObjectLockOwnerInfoResponse,
  SDOObjectLockOwnerInfo
} from "../types/studio.types";
import { ObjectStageRaw, ObjectStage } from "../types/studio.types";
import type { ExternalProcessResponse } from "../types/workflow.types";
import { isEmpty, flatMap, camelCase } from "lodash-es";
// App

export const studioAppModelCreator = (
  response: StudioAppResponse
): StudioApp => {
  return {
    id: response.id,
    name: response.name,
    description: response.description,
    workflowId: response.workflow_id
  };
};

export const studioAppsModelCreator = (
  response: PaginatedResultsResponse<StudioAppResponse>
) => ({
  count: response.count,
  results: response.results.map(studioAppModelCreator),
  next: response.next,
  previous: response.previous
});

// Process

export const studioProcessModelCreator = (
  response: StudioProcessResponse
): StudioProcess => ({
  id: response.id,
  name: response.name,
  description: response.description,
  workflowKindTag: response.workflow_kind_tag,
  createdBy: response.created_by && userModelCreator(response.created_by)
});

export const studioProcessesModelCreator = (
  response: PaginatedResultsResponse<StudioProcessResponse>
) => response.results.map(studioProcessModelCreator);

// Branch

export const studioBranchSavePointModelCreator = (
  response: StudioBranchSavePointResponse
): StudioBranchSavePoint => ({
  id: response.id,
  title: response.title,
  description: response.description,
  stage: response.stage,
  createdAt: new Date(response.created_at),
  updatedAt: new Date(response.updated_at),
  stageSetBy: response.stage_set_by && userModelCreator(response.stage_set_by),
  deploymentAttemptedAt: response.deployment_attempted_at,
  deploymentAttemptedEnv: response.deployment_attempted_env
});

export const studioBranchSavePointsModelCreator = (
  response: PaginatedResultsResponse<StudioBranchSavePointResponse>
) => ({
  ...response,
  results: response.results.map(studioBranchSavePointModelCreator)
});

// Savepoint

const studioProcessBySavePointModelCreator = (
  response: StudioProcessBySavePointResponse
): StudioProcessBySavePoint => ({
  id: response.id,
  process: studioProcessModelCreator(response.process)
});

export const studioProcessesBySavePointsModelCreator = (
  response: PaginatedResultsResponse<StudioProcessBySavePointResponse>
) => response.results.map(studioProcessBySavePointModelCreator);

// TODO: Possibly generic

const userModelCreator = (response: UserResponse): User => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name,
  email: response.email
});

const savepointDeploymentLogModelCreator = (
  response: SavepointDeploymentLogResponse
): SavepointDeploymentLog => ({
  id: response.id,
  errors: response.errors,
  status: response.status,
  appSettingsErrors: appSettingsErrorModelCreator(
    response.platform_settings_errors
  ),
  updatedAt: response.updated_at,
  timestamps: (
    Object.keys(
      response.stage_timestamps
    ) as (keyof SavepointDeploymentLogResponse["stage_timestamps"])[]
  ).reduce(
    (a, c) => ({
      ...a,
      [c]: (
        Object.keys(
          response.stage_timestamps[c]
        ) as (keyof SavepointDeploymentLogResponse["stage_timestamps"])[]
      ).reduce(
        (d, e) => ({
          ...d,
          // to convert start_time to startTime with types
          [camelCase(e as string)]:
            response.stage_timestamps[c][
              e as string as keyof (keyof SavepointDeploymentLogResponse["stage_timestamps"])
            ]
        }),
        {} as { startTime: number; endTime: number; duration: number }
      )
    }),
    {} as SavepointDeploymentLog["timestamps"]
  )
});

export const savepointDeploymentLogsModelCreator = (
  response: PaginatedResultsResponse<SavepointDeploymentLogResponse>
) => response.results.map(savepointDeploymentLogModelCreator);

const processDeploymentLogModelCreator = (
  response: ProcessDeploymentLogResponse
): ProcessDeploymentLog => ({
  id: response.id,
  errors: response.errors,
  status: response.status,
  warnings: response.warnings,
  workflowKindTag: response.workflow_kind_tag,
  branchLogId: response.branch_log,
  updatedAt: response.updated_at,
  configErrorsWarnings: response.config_errors_warnings,
  timestamps: (
    Object.keys(
      response.stage_timestamps
    ) as (keyof ProcessDeploymentLogResponse["stage_timestamps"])[]
  ).reduce(
    (a, c) => ({
      ...a,
      [c]: (
        Object.keys(
          response.stage_timestamps[c]
        ) as (keyof ProcessDeploymentLogResponse["stage_timestamps"])[]
      ).reduce(
        (d, e) => ({
          ...d,
          // to convert start_time to startTime with types
          [camelCase(e as string)]:
            response.stage_timestamps[c][
              e as string as keyof (keyof ProcessDeploymentLogResponse["stage_timestamps"])
            ]
        }),
        {} as { startTime: number; endTime: number; duration: number }
      )
    }),
    {} as ProcessDeploymentLog["timestamps"]
  )
});

export const processDeploymentLogsModelCreator = (
  response: PaginatedResultsResponse<ProcessDeploymentLogResponse>
) => response.results.map(processDeploymentLogModelCreator);

export const studioPermissionsModelCreator = (
  response: StudioPermissionsResponse
): StudioPermissions => ({
  canAccessStudio: response.can_access_studio,
  canDeployToDevelopment: response.can_deploy_to_development,
  canDeployToProduction: response.can_deploy_to_production,
  canDeployToQA: response.can_deploy_to_qa,
  canDeployToStaging: response.can_deploy_to_staging,
  canEditBranch: response.can_edit_branch,
  canMergeBranch: response.can_merge_branch,
  canEditPackage: response.can_edit_package,
  canEditProcess: response.can_edit_process,
  isStudioSuperuser: response.studio_superuser_perm,
  isStudioObjectsSuperuser: response.studio_objects_superuser_perm
});

const studioCentralTemplateModelCreator = (
  response: ExternalProcessResponse
): StudioCentralTemplate => ({
  id: response.id,
  uid: response.uid,
  name: response.name,
  description: response.lc_data.Description,
  type: response.lc_data.Type as StudioTemplateTypes,
  category: response.lc_data.Category,
  source: response.lc_data.Source,
  author: response.lc_data.Author,
  integrationType: response.lc_data?.[
    "Integration Type"
  ] as TemplateIntegrationTypes,
  templateStatus: response.lc_data["Template Status"] as TemplateStatusTypes,
  clientNames: response.lc_data["Client/Partner Names"]
    ? response.lc_data["Client/Partner Names"].split("~")
    : [],
  code: response.lc_data.Code
});

export const studioCentralTemplatesModelCreator = (
  response: PaginatedResultsResponse<ExternalProcessResponse>
): PaginatedResultsResponse<StudioCentralTemplate> => ({
  ...response,
  results: response.results.map(studioCentralTemplateModelCreator)
});

const appSettingsErrorModelCreator = (
  appSettingErrors: AppSettingsDeploymentError
): AppSettingErrorSaveLog => {
  const data: AppSettingErrorSaveLog = {
    group: appSettingsErrorMsgModelCreator(appSettingErrors?.group),
    emailTemplate: appSettingsErrorMsgModelCreator({
      // We show both emailTemplate and emailAttachment in the app settings UI.
      ...appSettingErrors?.emailtemplate,
      ...appSettingErrors?.emailattachment
    }),
    region: appSettingsErrorMsgModelCreator({
      // We show both region and businessUnit in the app settings UI.
      ...appSettingErrors?.region,
      ...appSettingErrors?.businessunit
    }),
    extraJSON: appSettingsErrorMsgModelCreator({
      ...appSettingErrors?.extrajson
    }),
    processKind: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowkind
    ),
    processStatus: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowstatus
    ),
    processPDFTemplate: appSettingsErrorMsgModelCreator(
      appSettingErrors?.workflowpdftemplate
    ),
    alertCategory: appSettingsErrorMsgModelCreator(
      appSettingErrors?.alertcategory
    ),
    commentFlagOption: appSettingsErrorMsgModelCreator(
      appSettingErrors?.commentflagoption
    ),
    feExtraMetaData: appSettingsErrorMsgModelCreator(
      appSettingErrors?.frontend_extra_metadata
    ),
    objectTypeWorkflowKindRelation: appSettingsErrorMsgModelCreator(
      appSettingErrors?.objecttypeworkflowkindrelation
    )
  };

  for (const key in data) {
    if (isEmpty(data[key as keyof AppSettingErrorSaveLog])) {
      delete data[key as keyof AppSettingErrorSaveLog];
    }
  }
  return data;
};

const appSettingsErrorMsgModelCreator = (
  data: Record<string, any[]>
): AppsettingsErrorDetails => {
  if (!isEmpty(data)) {
    for (const key in data) {
      const flatennedErrorMsg = flatMap(data[key], error =>
        Object.values(error)
      );
      // If error message is a list of error messages
      if (Array.isArray(flatennedErrorMsg[0])) {
        data[key] = flatennedErrorMsg[0];
      } else {
        data[key] = flatennedErrorMsg;
      }
    }
  }
  return data;
};

export const studioBranchesModelCreator = (
  response: PaginatedResultsResponse<StudioBranchResponse>
): PaginatedResultsResponse<StudioBranch> => {
  return {
    ...response,
    results: response.results.map(studioBranchModelCreator)
  };
};

const studioBranchModelCreator = (
  response: StudioBranchResponse
): StudioBranch => {
  return {
    id: response.id,
    name: response.name,
    createdAt: response.created_at
  };
};

// Data Objects

export const studioDataObjectsModelCreator = (
  response: PaginatedResultsResponse<ObjectMetaDataResponse>
): PaginatedResultsResponse<ObjectMetaData> => {
  return {
    ...response,
    results: response.results.map(studioDataObjectModelCreator)
  };
};

export const studioDataObjectModelCreator = (
  response: ObjectMetaDataResponse
): ObjectMetaData => {
  return {
    id: `${response.id}`,
    name: response.name,
    description: response.description,
    stage: stageModelCreator(response.stage),
    createdAt: response.created_at,
    isMutable: response.is_mutable
  };
};

const stageModelCreator = (stage: ObjectStageRaw) => {
  switch (stage) {
    case ObjectStageRaw.DRAFT:
      return ObjectStage.DRAFT;
    case ObjectStageRaw.RFT:
      return ObjectStage.RFT;
    case ObjectStageRaw.DEPLOYED:
      return ObjectStage.DEPLOYED;
    case ObjectStageRaw.ARCHIVE:
      return ObjectStage.ARCHIVE;
  }
};

export const objectSchemasModelCreator = (
  response: ObjectSchemaRaw[]
): ObjectSchema[] => {
  return response.map(schema => ({
    id: schema.id,
    name: schema.name,
    metaJSON: schema.meta_json
  }));
};

// SDO Deployment Logs
const deploymentLogModelCreator = (
  response: SDODeploymentLogResponse
): SDODeploymentLog => ({
  environment: response.environment,
  status: response.status,
  startedAt: new Date(response.started_at),
  completedAt: new Date(response.completed_at),
  errorMessage: response.error_message
});

const sdoSavePointStatusModelCreator = (
  response: SDOSavePointStatusResponse
): SDOSavePointStatus => ({
  id: response.id,
  stage: stageModelCreator(response.stage),
  createdAt: new Date(response.created_at),
  lastDeployedAt: response.last_deployed_at
    ? new Date(response.last_deployed_at)
    : null,
  deploymentStatus: response.deployment_status,
  deploymentLogs: response.deployment_logs?.map(deploymentLogModelCreator)
});

export const sdoSavePointStatusesModelCreator = (
  response: SDOSavePointStatusResponse[]
): SDOSavePointStatus[] => response.map(sdoSavePointStatusModelCreator);

// SDO Deployment

export const sdoDeploymentModelCreator = (
  response: SDODeploymentPayloadResponse
): SDODeploymentPayload => ({
  id: response.id,
  environment: response.environment,
  deployableJSON: response.deployable_json,
  status: response.status,
  createdAt: new Date(response.created_at)
});

// SDO Object Lock

export const sdoObjectLockModelCreator = (
  response: SDOObjectLockResponse
): SDOObjectLock => ({
  email: response.user,
  userInfo: response.user_info
    ? sdoObjectLockOwnerInfoModelCreator(response.user_info)
    : null
});

const sdoObjectLockOwnerInfoModelCreator = (
  response: SDOObjectLockOwnerInfoResponse
): SDOObjectLockOwnerInfo => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name
});
