import { createCustomTag } from "@certa/common/modelCreators";
import { APIFetchV3, RequestHeaders } from "@certa/network";
import { useQuery } from "react-query";
import type { UseQueryOptions } from "react-query";
import { queryClient } from "../../utils/utils";

type GetFieldOptionResponse = {
  options: { [fieldTag: string]: { label: string | null; value: string }[] };
};

export type SelectableFieldData = {
  options: { label: string; value: string }[];
  key: string;
  kindId: number | undefined;
  fieldTag: string;
};

const modelCreators = (
  response: GetFieldOptionResponse,
  kindId: number | undefined,
  fieldTag: string
): SelectableFieldData => {
  return {
    options: (response?.options?.[fieldTag] || []).map(option => ({
      label: option.label ?? option.value,
      value: option.value
    })),
    key: createCustomTag({
      kindId,
      type: "field",
      tag: fieldTag,
      value: "answer"
    }),
    kindId,
    fieldTag
  };
};

const getFieldOptions = async (
  kindId: number | undefined,
  fieldTag: string
): Promise<SelectableFieldData> => {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      kind: kindId,
      field_tags: [fieldTag]
    })
  };

  const url = `field-label-value-options/`;
  return APIFetchV3<GetFieldOptionResponse>(url, requestOptions).then(
    response => modelCreators(response, kindId, fieldTag)
  );
};

type UseGetFieldLabelValueOptionsParams = {
  kindId: string | undefined;
  fieldTag: string;
  config?: UseQueryOptions<SelectableFieldData>;
};

export const useGetFieldLabelValueOptions = ({
  kindId,
  fieldTag,
  config
}: UseGetFieldLabelValueOptionsParams) =>
  useQuery<SelectableFieldData>({
    enabled: !!Number(kindId) && !!fieldTag,
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...config,
    queryKey: ["report-field-options", Number(kindId), fieldTag],
    queryFn: () => getFieldOptions(Number(kindId), fieldTag)
  });

const setFieldOptionsToCache = (data: SelectableFieldData) => {
  queryClient.setQueryData(
    ["report-field-options", data.kindId, data.fieldTag],
    data
  );
};

type MultipleFieldOptionsPayload = {
  kindId: number;
  fieldTag: string;
};

const getMultipleFieldOptions = async (
  payloads: MultipleFieldOptionsPayload[]
): Promise<Record<string, SelectableFieldData>> => {
  const results = await Promise.all(
    payloads.map(({ kindId, fieldTag }) => getFieldOptions(kindId, fieldTag))
  );

  return results.reduce<Record<string, SelectableFieldData>>(
    (acc, selectableFieldData) => {
      acc[selectableFieldData.key] = selectableFieldData;
      setFieldOptionsToCache(selectableFieldData);
      return acc;
    },
    {}
  );
};

export const useGetMultipleFieldLabelValueOptions = (
  payloads: MultipleFieldOptionsPayload[]
) => {
  // Have tried to use useQueries but problem is that it will return new reference for data every time component renders
  // due to which component or hook using this will render every time even though the data is same
  // useMemo is not helping as well.
  // So, we have created this getMultipleFieldOptions function which take care of fetching data for multiple fields and caching it
  return useQuery({
    enabled: !!payloads.length,
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryKey: ["report-multiple-fields-options", payloads],
    queryFn: () => getMultipleFieldOptions(payloads)
  });
};
