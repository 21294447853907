import styles from "./Badge.module.css";
import { forwardRef } from "react";
import { classNames } from "../../utils/common";
import { isBackgroundColorDark } from "../../utils/styles";

export enum BadgeColors {
  NEUTRAL = "Neutral500",
  LIGHT_NEUTRAL = "Neutral300",
  NEUTRAL_100 = "Neutral100",
  NEUTRAL_700 = "Neutral700",
  RED = "Red500",
  LIGHT_RED = "Red300",
  BRAND = "Brand400",
  LIGHT_BRAND = "Brand100",
  GREEN = "Green500",
  LIGHT_GREEN = "Green300",
  ORANGE = "Orange400",
  LIGHT_ORANGE = "Orange300",
  TEAL = "Teal500",
  LIGHT_TEAL = "Teal300",
  PURPLE = "Purple400",
  LIGHT_PURPLE = "Purple300",
  BLUEBERRY = "Blueberry400",
  LIGHT_BLUEBERRY = "Blueberry300"
}

export enum BadgeSizes {
  SMALL = "Small",
  DEFAULT = "Default"
}

export enum BadgeTypes {
  RECTANGLE = "Rectangle",
  PILL = "Pill"
}

type BadgeRoleProps = {
  role?: string;
  "aria-label"?: string;
};

export type BadgeProps = {
  /**
   * The pre-defined badge text and background color
   */
  color?: BadgeColors;
  /**
   * For custom badge background color.
   * The text color will be automatically determined based on the background color.
   */
  backgroundColor?: string;
  type?: BadgeTypes;
  size?: BadgeSizes;
  label: string | number;
  children?: React.ReactElement;
  showZero?: boolean;
  overflowCount?: number;
  /**
   * [top, right] offset for the badge
   * @default [-6, -6]
   */
  offset?: [number, number];
  onMouseEnter?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (evt: React.MouseEvent<HTMLDivElement>) => void;
} & BadgeRoleProps;

export const Badge = forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  const {
    role,
    "aria-label": ariaLabel,
    label,
    color = BadgeColors.NEUTRAL,
    type = BadgeTypes.RECTANGLE,
    size = BadgeSizes.DEFAULT,
    showZero: shouldShowZero = true,
    overflowCount,
    children,
    offset = [-6, -6],
    onMouseEnter,
    onMouseLeave,
    backgroundColor
  } = props;

  const isLabelTypeNumber = typeof label === "number";

  let displayCount: string | number = isLabelTypeNumber
    ? label.toLocaleString()
    : label;

  if (
    overflowCount !== undefined &&
    isLabelTypeNumber &&
    Number(label) > overflowCount
  ) {
    displayCount = overflowCount + "+";
  }

  if (isLabelTypeNumber && Number(label) === 0 && !shouldShowZero) {
    displayCount = "";
  }

  const offsetStyles = !!children
    ? {
        top: offset[0],
        right: offset[1]
      }
    : {};

  return (
    <div
      className={classNames({
        [styles.catalystBadgeWrapper]: true,
        [styles.catalystBadgeWithTarget]: !!children
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      {displayCount && (
        <>
          <span
            role={role}
            aria-label={role ? ariaLabel : undefined}
            className={classNames({
              [styles.catalystBadge]: true,
              [styles["catalystBadge" + type]]: !!type,
              [styles["catalystBadge" + color]]: !!color,
              [styles["catalystBadge" + size]]: true
            })}
            aria-hidden={!!ariaLabel}
            style={{
              ...offsetStyles,
              backgroundColor,
              color:
                backgroundColor &&
                // TODO: To use WCAG based contrast colors
                (isBackgroundColorDark(backgroundColor)
                  ? "var(--colors-neutral-100)"
                  : "var(--colors-neutral-800)")
            }}
          >
            {displayCount}
          </span>
          {ariaLabel && (
            <div className="catalyst-screen-reader-only">{ariaLabel}</div>
          )}
        </>
      )}
      {children ? children : null}
    </div>
  );
});
