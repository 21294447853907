import { useEffect } from "react";
import { getLoader, hideElement } from "@certa/common/utils/helper";

export const AppLoader = () => {
  // this component triggers global loader only, to maintain loading animation
  useEffect(() => {
    const appLoader = getLoader();
    if (appLoader) {
      const isVisible = appLoader.style.display;
      if (isVisible === "none") {
        appLoader.style.display = "block";
      }
      return () => {
        hideElement(appLoader);
      };
    }
  }, []);
  return null;
};
