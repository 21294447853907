import React from "react";

import { classNames } from "../../utils/common";

import { CatalystColors } from "../../constants/styles";

import styles from "./Typography.module.css";

export enum TypographyVariants {
  TITLE_MD = "TitleMd",
  TITLE_SM = "TitleSm",
  BODY = "Body",
  BODY_BOLD = "BodyBold",
  LABEL_SM = "LabelSm",
  LABEL_SM_BOLD = "LabelSmBold",
  OVERLINE_SMALL = "OverlineSm"
}

export { CatalystColors as TypographyColors };

const getTypographyVariantElement = (variant: TypographyVariants) => {
  switch (variant) {
    case TypographyVariants.TITLE_MD:
      return "h3";
    case TypographyVariants.TITLE_SM:
      return "h4";
    case TypographyVariants.BODY_BOLD:
      return "p";
    case TypographyVariants.LABEL_SM:
      return "h5";
    case TypographyVariants.LABEL_SM_BOLD:
      return "h5";
    case TypographyVariants.OVERLINE_SMALL:
      return "h6";
    default:
      // Body is the default typography
      return "p";
  }
};
type TypographyAsProp =
  | {
      as?: undefined;
      htmlFor?: undefined;
    }
  | {
      as: "label";
      htmlFor?: string;
    }
  | {
      as: Exclude<React.ElementType, "label">;
      htmlFor?: undefined;
    };

export type TypographyProps = React.HTMLAttributes<
  HTMLParagraphElement | HTMLHeadingElement
> & {
  children: React.ReactNode;
  variant?: TypographyVariants;
  color?: CatalystColors;
  ellipsis?: boolean;
  width?: string;
} & TypographyAsProp;

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (props, ref) => {
    const {
      as,
      variant = TypographyVariants.BODY,
      color,
      width,
      children,
      className,
      style,
      ...restProps
    } = props;

    return (
      <>
        {React.createElement(as || getTypographyVariantElement(variant), {
          children,
          style: { color, width, ...style },
          className: classNames(
            className,
            styles.catalystTypography,
            styles["catalystTypography" + variant]
          ),
          ref,
          ...restProps
        })}
      </>
    );
  }
);
