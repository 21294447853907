import React from "react";
import { Menu } from "../Menu/Menu";
import { MenuItem } from "../Menu/MenuItem";
import type { BreadcrumbOptions } from "./types";
import { Link } from "react-router";

type Props = {
  options: BreadcrumbOptions[];
};

export const BreadcrumbMenu = ({ options }: Props) => {
  const renderTitle = (
    label: BreadcrumbOptions["label"],
    href: BreadcrumbOptions["href"],
    isExternal: BreadcrumbOptions["isExternalLink"]
  ) => {
    return href ? (
      isExternal ? (
        <a href={href}>{label}</a>
      ) : (
        <Link to={href} style={{ color: "inherit" }}>
          {label}
        </Link>
      )
    ) : (
      label
    );
  };
  return (
    <Menu>
      {options.map(option => {
        return (
          <MenuItem
            key={option.href}
            icon={option.icon}
            title={renderTitle(
              option.label,
              option.href,
              option.isExternalLink
            )}
          ></MenuItem>
        );
      })}
    </Menu>
  );
};
