import { useHandleAdvancedFilterSelect } from "./SelectValueInputField.hooks";
import { filterFieldOption } from "./SelectValueInputField.utils";
import { Select } from "@certa/catalyst/components/Select";
import type { FieldTypes } from "@certa/types";

export type SelectValueInputFieldProps = {
  disabled: boolean;
  fieldTag: string;
  fieldType: FieldTypes;
  shouldUseNewLabelValueOptions: boolean;
  kindId: string | undefined;
  onChange: (value: string | string[] | undefined) => void;
  value: string | undefined;
};

export const SelectValueInputField = ({
  disabled,
  fieldTag,
  fieldType,
  shouldUseNewLabelValueOptions,
  kindId,
  onChange,
  value
}: SelectValueInputFieldProps) => {
  const {
    selectOptions,
    isLoading: isLoadingSelectData,
    handleCreateOptions
  } = useHandleAdvancedFilterSelect({
    shouldUseNewLabelValueOptions,
    kindId,
    fieldTag,
    fieldType,
    onChange
  });

  return (
    <Select
      label=""
      options={selectOptions}
      menuPortalTarget={document.body}
      onCreateOption={handleCreateOptions}
      filterOption={filterFieldOption}
      isLoading={isLoadingSelectData}
      value={value}
      onChange={value => onChange(value)}
      disabled={disabled}
    />
  );
};
