//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Lock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Lock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.80025 8.00033C3.35839 8.00033 3 8.33685 3 8.75051V13.2498C3 13.664 3.35899 14 3.80025 14H11.7997C12.2416 14 12.6 13.6635 12.6 13.2498V8.75051C12.6 8.33629 12.241 8.00033 11.7997 8.00033H11.5332V5.50045C11.5332 3.57185 9.8581 2 7.79907 2C5.74172 2 4.06678 3.57185 4.06678 5.50045C4.06678 5.77437 4.06678 8.00033 4.06678 8.00033H5.6661C5.6661 8.00033 5.6661 5.77437 5.6661 5.50045C5.6661 4.39867 6.62557 3.50087 7.79916 3.50087C8.97449 3.50087 9.93391 4.4003 9.93391 5.50045V8.00033H3.80025Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
