//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronDown = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronDown"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.53346 11.4165L2.38686 7.26855C2.11405 6.99339 1.97436 6.61371 2.00389 6.22792C2.03342 5.84166 2.22889 5.48729 2.54015 5.25714C3.08905 4.87184 3.83675 4.94261 4.30355 5.42402L8.00063 9.12242L11.7351 5.3879C12.0103 5.11415 12.3909 4.97399 12.7776 5.00399C13.1644 5.03352 13.5192 5.2304 13.7494 5.54259C14.1347 6.09149 14.0639 6.83871 13.5825 7.30551L9.46782 11.4165C9.07829 11.8046 8.55095 12.023 8.00058 12.023C7.45074 12.023 6.92292 11.8046 6.53334 11.4165H6.53346Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
