import { useState, forwardRef } from "react";
import { Caret } from "@certa/icons/components/Caret";

import { classNames } from "../../utils/common";

import styles from "./Avatar.module.css";

export enum AvatarSizes {
  BIG = "Big",
  SMALL = "Small",
  EXTRA_LARGE = "ExtraLarge"
}

export enum AvatarColors {
  BRAND = "Brand",
  NEUTRAL = "Neutral",
  NEUTRAL_600 = "Neutral600",
  DARK = "Dark"
}

type AvatarDefaultProps = {
  selectable?: boolean;
  size?: AvatarSizes;
  color?: AvatarColors;
  "aria-label": string;
  children?: string;
};

type AvatarImageProps =
  | {
      src: string;
      alt: string;
    }
  | {
      src?: undefined;
      alt?: undefined;
    };

type AvatarProps = AvatarDefaultProps & AvatarImageProps;

export const Avatar = forwardRef<
  HTMLImageElement | HTMLDivElement,
  AvatarProps
>((props, ref) => {
  const {
    "aria-label": ariaLabel,
    src,
    alt,
    size = AvatarSizes.BIG,
    color = AvatarColors.BRAND,
    children,
    selectable: isSelectable = false
  } = props;

  const [hasError, setHasError] = useState(false);

  const hasImage = !!src && !hasError;

  const handleError = () => {
    setHasError(true);
  };

  const avatarText =
    children?.slice(0, 2).toUpperCase() || alt?.slice(0, 2).toUpperCase() || "";

  return (
    <div
      className={classNames({
        [styles.catalystAvatarWrapper]: true,
        [styles["catalystAvatar" + size]]: true,
        [styles["catalystAvatar" + color]]: true,
        [styles.catalystAvatarSelectable]: isSelectable,
        [styles.catalystAvatarSrc]: hasImage
      })}
      ref={ref}
    >
      <div
        className={classNames({
          [styles.catalystAvatar]: true
        })}
        role="img"
        aria-label={ariaLabel}
      >
        {hasImage ? (
          <img src={src} alt={alt} onError={handleError} />
        ) : (
          <div>{avatarText}</div>
        )}
      </div>
      {/* 
        TODO: Dropdown menu or hover card will be added 
        once the dropdown component is built
      */}
      {isSelectable && (
        <div
          className={styles.catalystAvatarCaret}
          data-testid="catalystAvatarCaret"
        >
          <Caret />
        </div>
      )}
    </div>
  );
});
