import type { FC } from "react";
import { Typography } from "antd";
import { Tooltip } from "@certa/common/components/Tooltip";
import type { ColumnTitleProps } from "../types";

export const ColumnTitle: FC<ColumnTitleProps> = ({
  title,
  width = "100%"
}) => {
  return (
    <Tooltip content={title}>
      <Typography.Text style={{ width }} ellipsis={true}>
        {title}
      </Typography.Text>
    </Tooltip>
  );
};
