import { css } from "emotion";

export const breadcrumbsContainer = css({
  display: "flex",
  flexGrow: 1,
  overflowX: "hidden",
  whiteSpace: "nowrap",
  gap: "var(--s1)",
  maxWidth: "430px",
  wordWrap: "break-word",
  overflow: "hidden"
});

export const breadcrumbItem = css({
  flexBasis: "0%",
  maxWidth: "max-content",
  textOverflow: "ellipsis",
  overflowX: "hidden"
});
