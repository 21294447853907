import {
  ButtonTypes,
  ButtonVariants,
  Button,
  ButtonSizes
} from "@certa/catalyst/components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@certa/catalyst/components/Dropdown";
import {
  TypographyColors,
  TypographyVariants,
  Typography,
  Ellipsis
} from "@certa/catalyst/components/Typography";

import { Stack } from "@certa/catalyst/layouts/Stack";
import { ChevronLeft } from "@certa/icons/components/ChevronLeft";
import { Close } from "@certa/icons/components/Close";
import { Dots } from "@certa/icons/components/Dots";
import {
  CommentObjectTypes,
  type CommentChannel,
  type ConversationThread,
  type WorkflowFamily
} from "@certa/types";
import { css } from "emotion";
import { useIntl } from "react-intl";
import { handleOnPrintComment } from "../commentsNew/SingleChannelView/SingleChannelView";

import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { useCommsNewEndpoints } from "@certa/common/toggles";

export const DrawerTitle = ({
  isMultiChannel,
  channels,
  workflowFamily,
  fieldName,
  activeChannel,
  activeConversationThreadComms,
  setActiveChannel,
  setActiveConversationThreadComms,
  showBackButton = false,
  onClose
}: {
  isMultiChannel: boolean;
  channels: CommentChannel[] | undefined;
  workflowFamily: WorkflowFamily | undefined;
  fieldName: string | undefined;
  activeChannel: CommentChannel | null;
  activeConversationThreadComms: ConversationThread | null;
  setActiveChannel: (activeChannel: CommentChannel | null) => void;
  setActiveConversationThreadComms: (
    conversationThread: ConversationThread | null
  ) => void;
  showBackButton?: boolean;
  onClose: () => void;
}) => {
  const intl = useIntl();

  const commentsText = intl.formatMessage({
    id: "workflowsInstances.commentsText",
    defaultMessage: "Comments"
  });

  const fieldText = intl.formatMessage({
    id: "commentsDrawer.fieldText",
    defaultMessage: "Field"
  });

  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  const isOnMultiChannelView =
    isMultiChannel &&
    (isCommsNewEndpointEnabled
      ? !activeConversationThreadComms ||
        activeConversationThreadComms.objectType ===
          CommentObjectTypes.CONSOLIDATED
      : !activeChannel);

  const stepGroupName =
    activeChannel?.stepGroupName || channels?.[0]?.stepGroupName;
  const stepName = activeChannel?.stepName || channels?.[0]?.stepName;

  const titleForDashboardAndReports = workflowFamily
    ?.map(workflow => workflow.name)
    .join(": ");

  const commentsDrawerTitle = titleForDashboardAndReports
    ? titleForDashboardAndReports
    : `${stepGroupName}: ${stepName}`;

  const commentsDrawerSubHeading = fieldName
    ? `${fieldText}: ${fieldName}`
    : "";

  const { isMobileResolution } = useScreenResolution();

  const mobileResolutionPadding = isOnMultiChannelView ? "8 4 8 16" : "12 8";

  const desktopResolutionPadding = isOnMultiChannelView
    ? "0 20 0 24"
    : "0 20 0 24";

  return (
    <Stack
      direction="horizontal"
      align="center"
      justify="space-between"
      padding={
        isMobileResolution ? mobileResolutionPadding : desktopResolutionPadding
      }
      gap="8"
      dangerouslySetClassName={drawerTitleWrapperStyles(
        isOnMultiChannelView,
        isMobileResolution
      )}
    >
      <Stack
        direction="horizontal"
        gap="4"
        dangerouslySetClassName={drawerTitleStyles}
      >
        {showBackButton && !isOnMultiChannelView && (
          <Button
            aria-label="Back button"
            leftIcon={<ChevronLeft />}
            variant={ButtonVariants.TEXT}
            size={isMobileResolution ? ButtonSizes.SMALL : ButtonSizes.DEFAULT}
            onClick={() => {
              if (isMultiChannel) {
                if (activeChannel) {
                  setActiveChannel(null);
                }
                if (activeConversationThreadComms) {
                  setActiveConversationThreadComms(null);
                }
              } else {
                onClose();
              }
            }}
          />
        )}
        <Stack
          direction="vertical"
          align="start"
          justify={fieldName ? "flex-start" : "center"}
          width={
            showBackButton && !isOnMultiChannelView
              ? isMobileResolution
                ? "85%"
                : "90%"
              : "100%"
          }
        >
          <Typography
            className={css({
              maxWidth: "100%",
              textWrap: "nowrap"
            })}
            variant={
              isOnMultiChannelView
                ? TypographyVariants.TITLE_SM
                : TypographyVariants.BODY_BOLD
            }
            color={TypographyColors.NEUTRAL_800}
          >
            {!isOnMultiChannelView && stepGroupName && stepName ? (
              <Ellipsis>{commentsDrawerTitle}</Ellipsis>
            ) : (
              commentsText
            )}
          </Typography>
          {commentsDrawerSubHeading && (
            <Typography
              variant={TypographyVariants.BODY}
              color={TypographyColors.NEUTRAL_700}
              className={css({
                maxWidth: "100%",
                textWrap: "nowrap"
              })}
            >
              <Ellipsis>{commentsDrawerSubHeading}</Ellipsis>
            </Typography>
          )}
        </Stack>
      </Stack>
      <div className={drawerActionButtonsStyles}>
        {(!isMultiChannel || !!activeChannel) && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div>
                <Button
                  aria-label="Meta actions"
                  leftIcon={<Dots />}
                  type={ButtonTypes.ICON}
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" width="10.5rem">
              <DropdownMenuItem
                value="print"
                onSelect={() => {
                  handleOnPrintComment();
                }}
              >
                {intl.formatMessage({
                  id: "stepBodyFormInstances.printText",
                  defaultMessage: "Print"
                })}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <Button
          aria-label="Close comments drawer"
          leftIcon={<Close size={18} />}
          variant={ButtonVariants.TEXT}
          onClick={onClose}
        />
      </div>
    </Stack>
  );
};

const drawerTitleWrapperStyles = (
  isOnMultiChannelView: boolean,
  isMobileResolution: boolean
) => {
  return css({
    borderBottom: isOnMultiChannelView
      ? "1px solid var(--colors-neutral-400)"
      : "none",
    height: isMobileResolution ? "" : "5rem",
    flexShrink: 0
  });
};

const drawerActionButtonsStyles = css({
  display: "flex",
  alignItems: "center",
  color: TypographyColors.BRAND_400
});

const drawerTitleStyles = css({
  flex: "1",
  minWidth: "0"
});
