import type { GetAdvancedFilterDataORMReturnType } from "@certa/queries/types/workflow.types";
import type { FilterConditionORM, Kind } from "@certa/types";

import { FieldTypes } from "@certa/types";

import { labelWrapper } from "main/src/modules/dashboard/workflowToolbar/components/AdvancedFilter";
import { isStepFyiOrReadonly, isSwimlaneFyiOrReadonly } from "./reportUtils";

import type {
  FieldHierarchyType,
  StepHierarchyType,
  SwimlaneHierarchyType
} from "./reportUtils";
import {
  PROCESS_ATTRIBUTE_LABEL,
  STEP_ATTRIBUTE_LABEL,
  STEP_GROUP_ATTRIBUTE_LABEL
} from "../createReport/chartConfigurationORM/constants";
import { createPathFromCascader } from "@certa/common/utils/fieldHierarchy";
import { getDataType } from "@certa/common/utils/filterUtils";
import {
  createStepGroupValueTag,
  createStepValueTag,
  getCustomAttributeTag,
  createProcessValueTag
} from "@certa/common/utils/report";
import type {
  ORMReportProcessAttr,
  ORMReportStepAttr,
  ORMReportStepGroupAttr
} from "@certa/common/constants";
import {
  FETCH_STEPGROUP_ATTR,
  ORM_CASCADER_COMMON_LABELS,
  ORM_CASCADER_COMMON_VALUES,
  ormStepGroupLevelAttributeDetails,
  processAttrDataORM,
  ormStepGroupAttributes,
  ormStepAttributes,
  ormStepLevelAttributeDetails,
  ormReportProcessAttr
} from "@certa/common/constants";

type GetORMAdvancedFilterOptions = {
  data: GetAdvancedFilterDataORMReturnType | undefined;
  kinds?: Kind[];
  kindId?: number;
  isExcludingWeekendAndHolidays: boolean;
};

type ORMAdvancedFilterCascaderOption = {
  label: React.ReactNode;
  lhs_type?: FilterConditionORM["lhsType"];
  lhs_source?: FilterConditionORM["lhsSource"];
  data_type?: FilterConditionORM["dataType"];
  field_type?: FieldTypes;
  labelText: string;
  value: string;
  tag?: string;
  kindId?: number;
  children?: ORMAdvancedFilterCascaderOption[];
};

export const ORM_ADVANCED_FILTER_JOIN_RELATION_INDEX = 0;

export const getORMAdvancedFilterOptions = (
  params: GetORMAdvancedFilterOptions
) => {
  const { data, kinds, kindId, isExcludingWeekendAndHolidays } = params;
  const findKind = (id: number | undefined) =>
    kinds?.find(kind => kind.id === id);
  const cascaderOptions: ORMAdvancedFilterCascaderOption[] = [];
  const pathMapping: Record<string, string[]> = {};

  if (!data || !data.parentOption.hierarchy?.length) {
    return {
      options: cascaderOptions,
      pathMapping
    };
  }

  const getChildren = (
    childrenData:
      | GetAdvancedFilterDataORMReturnType["children"]
      | undefined = [],
    forChildren = true
  ) => ({
    label: labelWrapper(
      forChildren
        ? ORM_CASCADER_COMMON_LABELS.CHILDREN
        : ORM_CASCADER_COMMON_LABELS.GRANDCHILDREN
    ),
    labelText: forChildren
      ? ORM_CASCADER_COMMON_LABELS.CHILDREN
      : ORM_CASCADER_COMMON_LABELS.GRANDCHILDREN,

    // #ORM_ADVANCED_FILTER_JOIN_RELATION_INDEX = 0: value should be equal to the ORM_REPORT_FILTER_CONDITION_JOIN_RELATION
    value: forChildren
      ? ORM_CASCADER_COMMON_VALUES.CHILDREN
      : ORM_CASCADER_COMMON_VALUES.GRANDCHILDREN,

    children: childrenData?.reduce<ORMAdvancedFilterCascaderOption[]>(
      (children, child) => {
        const { kindId, advancedFilterData } = child;
        const childKind = findKind(Number(kindId));
        if (!childKind) {
          return children;
        }
        const childOption = getChildrenOptions(
          advancedFilterData?.hierarchy ?? [],
          childKind.id,
          childKind.tag,
          isExcludingWeekendAndHolidays
        );
        // #ORM_COLUMN_PATH_KIND_ID_INDEX: as value is added to the children ORM_COLUMN_PATH_KIND_ID_INDEX = 1
        return children.concat({
          label: labelWrapper(childKind?.name ?? "NA"),
          labelText: childKind?.name ?? "NA",
          value: childKind.id.toString(),
          children: childOption.length ? childOption : []
        });
      },
      []
    )
  });
  const children = getChildren(data?.children);
  const grandChildren = getChildren(data?.grandChildren, false);

  const parentKind = findKind(kindId);

  if (parentKind) {
    cascaderOptions.push({
      label: labelWrapper(ORM_CASCADER_COMMON_LABELS.PARENTS),
      labelText: ORM_CASCADER_COMMON_LABELS.PARENTS,
      value: ORM_CASCADER_COMMON_VALUES.PARENTS,
      children: [
        {
          label: labelWrapper(parentKind.name),
          labelText: parentKind.name,
          value: parentKind.id.toString(),
          children: getChildrenOptions(
            data?.parentOption?.hierarchy ?? [],
            parentKind.id,
            parentKind.tag,
            isExcludingWeekendAndHolidays
          )
        }
      ]
    });
  }
  if (children.children?.length) {
    cascaderOptions.push(children);
  }
  if (grandChildren.children?.length) {
    cascaderOptions.push(grandChildren);
  }
  cascaderOptions.forEach(option => {
    Object.assign(pathMapping, { ...createPathFromCascader(option) });
  });
  return { options: cascaderOptions, pathMapping };
};

const getChildrenOptions = (
  hierarchy: any[],
  kindId: number,
  kindTag: string,
  isExcludingWeekendAndHolidays: boolean
) => {
  const options: ORMAdvancedFilterCascaderOption[] = [
    {
      label: labelWrapper(PROCESS_ATTRIBUTE_LABEL, "system"),
      labelText: PROCESS_ATTRIBUTE_LABEL,
      value: kindId?.toString(),
      children: getProcessAttrOptions(
        kindId,
        kindTag,
        isExcludingWeekendAndHolidays
      )
    },
    ...hierarchy.map((swimlane: SwimlaneHierarchyType) => {
      const stepGroupChildren: ORMAdvancedFilterCascaderOption[] =
        isSwimlaneFyiOrReadonly(swimlane)
          ? []
          : getORMStepGroupsChildren(
              swimlane.value,
              kindId,
              isExcludingWeekendAndHolidays
            );

      stepGroupChildren.push(
        ...(swimlane.children?.map((step: StepHierarchyType) => ({
          ...step,
          label: labelWrapper(step.label, "step"),
          labelText: step.label,
          children: [
            getORMStepChildren(
              step.value,
              isStepFyiOrReadonly(step),
              kindId,
              isExcludingWeekendAndHolidays
            )
          ].concat(
            ...(step.children?.reduce<ORMAdvancedFilterCascaderOption[]>(
              (options, field: FieldHierarchyType) => {
                const { label, field_type: fieldType, value } = field;
                if (fieldType !== FieldTypes.FILE) {
                  options.push({
                    value: getCustomAttributeTag(kindId, value),
                    label: labelWrapper(label, fieldType),
                    labelText: label,
                    lhs_type: "field",
                    lhs_source: "answer",
                    data_type: getDataType(fieldType),
                    children: []
                  });
                }
                return options;
              },
              []
            ) || [])
          )
        })) || [])
      );
      return {
        ...swimlane,
        label: labelWrapper(swimlane.label, "stepGroup"),
        labelText: swimlane.label,
        children: stepGroupChildren
      };
    })
  ];
  return options;
};

const filterableProcessAttributes: ORMReportProcessAttr[] =
  ormReportProcessAttr.filter(attr => {
    const { hasFilter, isHidden, isDeprecated } = processAttrDataORM[attr];
    return hasFilter && !isHidden && !isDeprecated;
  });

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
const filterableProcessAttributesWithoutAgeing =
  filterableProcessAttributes.filter(attr => attr !== "ageing");

const getProcessAttrOptions = (
  kindId: number,
  kindTag: string,
  isExcludingWeekendAndHolidays: boolean
): ORMAdvancedFilterCascaderOption[] => {
  const baseAttributes = isExcludingWeekendAndHolidays
    ? filterableProcessAttributesWithoutAgeing
    : filterableProcessAttributes;
  return baseAttributes.map(item => {
    const { label, fieldType, value } = processAttrDataORM[item];
    return {
      label: labelWrapper(label, fieldType),
      labelText: label,
      lhs_type: "attr",
      value: getCustomAttributeTag(kindId, createProcessValueTag(value)),
      tag: kindTag,
      kindId: kindId,
      lhs_source: value,
      data_type: getDataType(fieldType),
      field_type: fieldType,
      children: []
    };
  });
};

const filterableStepGroupAttributes: ORMReportStepGroupAttr[] =
  ormStepGroupAttributes.filter(
    attr => ormStepGroupLevelAttributeDetails[attr].hasFilter
  );

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
const filterableStepGroupAttributesWithoutAgeing =
  filterableStepGroupAttributes.filter(attr => attr !== "ageing");

const getORMStepGroupsChildren = (
  stepGroupTeg: string,
  kindId: string | number | undefined,
  isExcludingWeekendAndHolidays: boolean
): ORMAdvancedFilterCascaderOption[] => {
  const baseAttributes = isExcludingWeekendAndHolidays
    ? filterableStepGroupAttributesWithoutAgeing
    : filterableStepGroupAttributes;
  const stepGroupChildren: ORMAdvancedFilterCascaderOption[] =
    baseAttributes.map(key => {
      const { labelText, iconText, value, systemFieldType } =
        ormStepGroupLevelAttributeDetails[key];
      return {
        label: labelWrapper(labelText, iconText),
        labelText: labelText,
        value: getCustomAttributeTag(
          kindId,
          createStepGroupValueTag(stepGroupTeg, value)
        ),
        field_type: systemFieldType,
        lhs_type: "step_group",
        lhs_source: value,
        data_type: getDataType(systemFieldType),
        children: []
      };
    });

  return [
    {
      label: labelWrapper(STEP_GROUP_ATTRIBUTE_LABEL, "alert"),
      labelText: STEP_GROUP_ATTRIBUTE_LABEL,
      value: `${FETCH_STEPGROUP_ATTR}__${stepGroupTeg}`,
      children: stepGroupChildren
    }
  ];
};

const filtersSLAORM: ORMReportStepAttr[] = ormStepAttributes.filter(attr => {
  const { hasFilter } = ormStepLevelAttributeDetails[attr];
  return hasFilter;
});

//ref: CYCLE_TIME_CALCULATION_ALGORITHM_NOTE
const filtersSLAORMWithoutAgeing = filtersSLAORM.filter(
  attr => attr !== "ageing"
);

const filtersFYIAndReadOnlySLAORM: ORMReportStepAttr[] =
  ormStepAttributes.filter(attr => {
    const { hasFilter, isHiddenInFYIorReadOnly } =
      ormStepLevelAttributeDetails[attr];
    return hasFilter && !isHiddenInFYIorReadOnly;
  });

const getORMStepChildren = (
  stepTeg: string,
  isStepFyiOrReadonlyFlag: boolean,
  kindId: string | number | undefined,
  isExcludingWeekendAndHolidays: boolean
): ORMAdvancedFilterCascaderOption => {
  const baseAttributes = isExcludingWeekendAndHolidays
    ? filtersSLAORMWithoutAgeing
    : filtersSLAORM;
  const filterableStepAttributes: ORMReportStepAttr[] = isStepFyiOrReadonlyFlag
    ? filtersFYIAndReadOnlySLAORM
    : baseAttributes;

  const stepChildren: ORMAdvancedFilterCascaderOption[] =
    filterableStepAttributes.map(key => {
      const { labelText, iconText, value, systemFieldType } =
        ormStepLevelAttributeDetails[key];
      return {
        label: labelWrapper(labelText, iconText),
        labelText: labelText,
        value: getCustomAttributeTag(
          kindId,
          createStepValueTag(stepTeg, value)
        ),
        field_type: systemFieldType,
        lhs_type: "step",
        lhs_source: value,
        data_type: getDataType(systemFieldType),
        children: []
      };
    });

  return {
    label: labelWrapper(STEP_ATTRIBUTE_LABEL, "alert"),
    labelText: STEP_ATTRIBUTE_LABEL,
    value: `${FETCH_STEPGROUP_ATTR}__${stepTeg}`,
    children: stepChildren
  };
};
