import { css } from "emotion";
import type { CSSProperties } from "react";
import { CatalystColors } from "@certa/catalyst/constants/styles";

const getBoxShadowStyle = ({
  isOver,
  isActive,
  isInvalid
}: {
  isOver: boolean;
  isActive: boolean;
  isInvalid: boolean;
}) => {
  if (isActive) {
    return `inset 0 0 0 2px ${CatalystColors.BRAND_600}`;
  }
  if (isOver) {
    return `inset 0 0 0 2px ${
      isInvalid ? CatalystColors.RED_600 : CatalystColors.GREEN_600
    }`;
  }
  return "none";
};

const getBackgroundColor = ({
  isOver,
  isActive,
  isInvalid
}: {
  isOver: boolean;
  isActive: boolean;
  isInvalid: boolean;
}) => {
  if (isActive) {
    return CatalystColors.BRAND_600;
  }
  if (isOver) {
    return `${isInvalid ? CatalystColors.RED_200 : CatalystColors.GREEN_200} !important`;
  }

  return "inherit";
};

const getCursor = ({
  isDraggable,
  isDragging,
  isInvalid
}: {
  isDraggable: boolean;
  isDragging: boolean;
  isInvalid: boolean;
}) => {
  if (isInvalid) {
    return "not-allowed";
  }
  if (!isDraggable) {
    return "default";
  }
  return isDragging ? "grabbing" : "grab";
};

export const getHeaderStyle = ({
  transform,
  isDragging,
  isDraggable,
  isOver,
  isActive,
  isInvalid
}: {
  transform: CSSProperties["transform"];
  isDragging: boolean;
  isDraggable: boolean;
  isOver: boolean;
  isActive: boolean;
  isInvalid: boolean;
}) =>
  css({
    transform,
    opacity: isDragging ? 0.6 : 1,
    zIndex: isDragging ? 1 : 0,
    cursor: getCursor({ isDraggable, isDragging, isInvalid }),
    position: "relative",
    userSelect: isDragging ? "none" : "auto",
    boxShadow: getBoxShadowStyle({ isOver, isActive, isInvalid }),
    borderRadius: 4,
    backgroundColor: getBackgroundColor({ isOver, isActive, isInvalid })
  });
