import { useMemo } from "react";
import type {
  StepGroupsData,
  TaskDetails
} from "@certa/queries/types/workflow.types";
import { useSelector } from "react-redux";
import type { IStepUser } from "main/src/js/components/WorkflowDetails/utils/interfaces";
import type { Step, StepGroup } from "@certa/types";
import { useCurrentUser } from "@certa/common/hooks/useCurrentUser";

type StepUserType = {
  [stepId: number]: IStepUser & {
    user: number;
    users: IStepUser[];
  };
};

const getProfileData = (state: Record<string, any>) => ({
  profileRegions:
    state?.authentication?.user?.profile?.region_read_access || [],
  profileBUs:
    state?.authentication?.user?.profile?.business_unit_read_access || []
});

export const useIsReadOnlyTask = (taskDetails: TaskDetails | undefined) => {
  const { profileBUs, profileRegions } = useSelector(getProfileData);

  if (taskDetails) {
    const { businessUnit, region } = taskDetails;
    //look for current task region in profile readonly list
    const isRegionReadOnly = region && profileRegions.includes(region);

    //look for current task BU in profile readonly list
    const isBUReadOnly = businessUnit && profileBUs.includes(businessUnit);

    return isRegionReadOnly || isBUReadOnly;
  } else {
    return false;
  }
};

/**
 * Map steps inside a step group to the user data.
 * Optionally filters out completed tasks.
 */
function mapStepGroupsWithUserData(
  stepGroups: StepGroup[],
  userData: { currentUser: { id: number }; stepUsers?: StepUserType },
  options?: { filterOutCompletedTasks: boolean }
) {
  const { currentUser, stepUsers } = userData;
  const mappedStepGroups = stepGroups.map(stepGroup => ({
    ...stepGroup,
    steps: stepGroup.steps.map(step =>
      taskUserAssigmentMap(step, currentUser, stepUsers)
    )
  }));

  if (options?.filterOutCompletedTasks) {
    return mappedStepGroups.filter(stepGroup =>
      stepGroup.steps.some(step => !step.isCompleted)
    );
  } else {
    return mappedStepGroups;
  }
}

/**
 * Map the User info to Assigned Steps.
 * Returns the processed StepGroupsData with user-specific and group-specific swimlanes.
 * @param filteredStepGroupsData
 * @returns
 */
export const useTaskLaneUserFormatData = (
  stepGroupsData?: StepGroupsData,
  stepUsers?: StepUserType,
  options?: { filterOutCompletedTasks: boolean }
): StepGroupsData | undefined => {
  const currentUser = useCurrentUser();

  const stepGroupWithUserData = useMemo(() => {
    if (!stepGroupsData) {
      return stepGroupsData;
    }

    const results = stepGroupsData.results.map(stepGroup => ({
      ...stepGroup,
      steps: stepGroup?.steps?.map(step =>
        taskUserAssigmentMap(step, currentUser, stepUsers)
      )
    }));

    // Swimlanes/Step Groups which have certain/all steps assigned to user
    const userAssignedStepsSwimlanes = mapStepGroupsWithUserData(
      stepGroupsData?.myTaggedSteps || [],
      {
        currentUser,
        stepUsers
      },
      options
    );

    const assignedToGroupSwimlanes = mapStepGroupsWithUserData(
      stepGroupsData?.userStepGroups || [],
      {
        currentUser,
        stepUsers
      },
      options
    );

    return {
      ...stepGroupsData,
      results,
      myStepGroups: userAssignedStepsSwimlanes,
      userStepGroups: assignedToGroupSwimlanes
    };
  }, [currentUser, options, stepGroupsData, stepUsers]);

  return stepGroupWithUserData;
};

const taskUserAssigmentMap = (
  step: Step,
  currentUser: { id: number },
  stepUsers?: StepUserType
) => {
  const stepUser = stepUsers?.[step.id];
  if (stepUser?.id) {
    return {
      ...step,
      isCurrentUser: stepUser?.user === currentUser?.id,
      assignedUserId: stepUser?.user,
      assignedUserName: stepUser?.user_full_name?.trim(),
      assignedUserEmail: stepUser?.user_email,
      assignedUserGroups: stepUser?.groups,
      allUsers: stepUser?.users
    };
  }

  return step;
};
