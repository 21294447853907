import { Stack } from "@certa/blocks/thanos";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { FormattedMessage } from "react-intl";
import type { HierarchyItem } from "../Breadcrumbs/Breadcrumbs.types";
import { ResultLayout } from "../ResultLayout/ResultLayout";
import {
  gapS1,
  gapS1WithAutoWidth,
  createdOnStyles,
  workflowKindNameStyles
} from "../styles";
import { formatDate } from "../utils";
import type { ResultComponentProps } from "./types";

export const WorkflowResult = (
  props: ResultComponentProps<ModelledAPIResponse.WorkflowSearchResult>
) => {
  const { result, family } = props;
  const breadcrumbs: HierarchyItem[] = family.map((member, index) => ({
    id: member.id,
    name: member.name,
    logo: member.logo,
    hideLogo: false,
    // Except the root and the leaf, all other items can be hidden
    canHide: index !== 0 && index !== family.length - 1,
    // The root and the leaf are always priority 1, the rest are 2
    priority: index === 0 || index === family.length - 1 ? 1 : 2
  }));

  return (
    <ResultLayout
      workflow={result}
      breadcrumbProps={{
        highlights: result.highlights,
        parentToChildHierarchy: breadcrumbs
      }}
      type={result.type}
      footer={
        <Stack direction="horizontal" className={gapS1} align="center">
          <Stack
            direction="horizontal"
            className={gapS1WithAutoWidth}
            justify="space-between"
            gutter="s0 s0 s0 s0"
          >
            {result.kind?.name && (
              <Typography
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_600}
                className={workflowKindNameStyles}
              >
                {result.kind?.name}
              </Typography>
            )}
            <Typography
              variant={TypographyVariants.LABEL_SM}
              color={TypographyColors.NEUTRAL_600}
              className={createdOnStyles}
            >
              <FormattedMessage
                id="globalSearch.createdOn"
                defaultMessage="Created on"
              />{" "}
              {formatDate(result.createdAt)}
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
};
