import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";

export const useShouldCheckWFStatusPermission = () => {
  return useSelector((state: ReduxState) =>
    lodashGet(
      state,
      "config.custom_ui_labels.check_workflow_status_access",
      false
    )
  );
};
