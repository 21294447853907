import { APIFetchV3, RequestHeaders } from "@certa/network";
import {
  globalSearchModelCreator,
  globalSearchModelCreatorNext
} from "../models/globalSearch.model";
import type {
  RawAPIResponse,
  SearchFilters,
  SearchRequestFilters
} from "../types/globalSearch.types";
import type { ReportCustomFilterCondition } from "@certa/types";
import { transformAnswerFilterToGlobalSearchBEFormat } from "@certa/common/utils/filterUtils";

export const SEARCH_FILTER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]";

const prepareFiltersForQuery = ({
  filters,
  advancedFilterConditions
}: {
  filters?: SearchFilters;
  advancedFilterConditions?: ReportCustomFilterCondition[];
}): SearchRequestFilters | undefined => {
  if (!filters) return undefined;

  const preparedFilters: SearchRequestFilters = {};

  if (filters.regionIds.length > 0) {
    preparedFilters.region_id__in = filters.regionIds.join(",");
  }
  if (filters.businessUnitIds.length > 0) {
    preparedFilters.business_unit_id__in = filters.businessUnitIds.join(",");
  }
  if (filters.statusIds.length > 0) {
    preparedFilters.status_id__in = filters.statusIds.join(",");
  }

  if (filters.kindIds.length > 0) {
    preparedFilters.kind_id__in = filters.kindIds.join(",");
  }

  if (filters.startDate) {
    preparedFilters.created_range_after = filters.startDate;
  }

  if (filters.endDate) {
    preparedFilters.created_range_before = filters.endDate;
  }

  if (advancedFilterConditions && advancedFilterConditions.length > 0) {
    preparedFilters.json_answer = {
      type: "AND",
      operands: transformAnswerFilterToGlobalSearchBEFormat(
        advancedFilterConditions
      )
    };
  }

  return Object.keys(preparedFilters).length > 0 ? preparedFilters : undefined;
};

/**
 * The main idea behind the following structure of the request body is to
 * allow FE to dictate the attributes that it needs in the response. In the
 * favour of avoiding over complications for now hard-coding the attributes
 * inside the service function since this API is being used only at one place.
 * Later - This needs to come as a argument to the service function still
 * avoiding this huge structure to be defined at the call site.
 */
const DEFAULT_REQUEST_BODY = {
  data: {
    id: null,
    name: null,
    logo: null,
    created_at: null,
    formatted_lc_data: null,
    status: {
      id: null,
      tag: null,
      label: null,
      kind: null,
      display_name: null,
      color_code: null
    },
    parents: {
      id: null,
      name: null,
      logo: null,
      created_at: null
    },
    definition: {
      id: null,
      name: null,
      kind: {
        id: null,
        tag: null,
        name: null,
        icon: null,
        menu_index: null,
        related_kind_tags: null
      }
    },
    answers: {
      id: null,
      answer: null,
      attachment: null,
      submitted_by: {
        id: null,
        first_name: null,
        last_name: null,
        email: null
      },
      submitted_at: null,
      files: null,
      files_details: {
        name: null,
        url: null,
        uid: null
      },
      field: {
        id: null,
        definition: {
          id: null,
          tag: null,
          body: null,
          workflow_mapping: null
        },
        step: {
          id: null,
          definition: {
            id: null,
            tag: null,
            name: null
          }
        }
      }
    }
  }
};

async function makeSearchRequest(
  query: string,
  selectedCategories: string[],
  url: string,
  filters?: SearchFilters,
  advancedFilterConditions?: ReportCustomFilterCondition[]
) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      ...DEFAULT_REQUEST_BODY,
      search: query,
      search_category: selectedCategories,
      filters: prepareFiltersForQuery({
        filters,
        advancedFilterConditions
      })
    })
  };

  const response = await APIFetchV3<RawAPIResponse.GlobalSearchResponse>(
    url,
    requestOptions
  );

  return {
    modeledResults: globalSearchModelCreator(response, query),
    rawResponse: response
  };
}

async function makeSearchRequestNext(
  query: string,
  selectedCategories: string[],
  url: string,
  filters?: SearchFilters,
  advancedFilterConditions?: ReportCustomFilterCondition[]
) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify({
      ...DEFAULT_REQUEST_BODY,
      search: query,
      search_category: selectedCategories,
      filters: prepareFiltersForQuery({
        filters,
        advancedFilterConditions
      })
    })
  };

  const response = await APIFetchV3<RawAPIResponse.GlobalSearchResponse>(
    url,
    requestOptions
  );

  return {
    modeledResults: globalSearchModelCreatorNext(
      response,
      query,
      selectedCategories
    ),
    rawResponse: response
  };
}

export async function getGlobalSearchResults(
  query: string,
  selectedCategories: string[]
) {
  const { modeledResults } = await makeSearchRequest(
    query,
    selectedCategories,
    "search/"
  );
  return modeledResults;
}

type GetGlobalSearchResultsPaginatedOptions = {
  query: string;
  selectedCategories: string[];
  offset: number;
  filters?: SearchFilters;
  advancedFilterConditions?: ReportCustomFilterCondition[];
};

export async function getGlobalSearchResultsPaginated({
  query,
  selectedCategories,
  offset,
  filters,
  advancedFilterConditions
}: GetGlobalSearchResultsPaginatedOptions) {
  const { modeledResults, rawResponse } = await makeSearchRequestNext(
    query,
    selectedCategories,
    `search/?limit=50&offset=${offset}`,
    filters,
    advancedFilterConditions
  );

  return {
    results: modeledResults,
    count: rawResponse.count,
    next: rawResponse.next,
    previous: rawResponse.previous
  };
}
