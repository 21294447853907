import { APIFetchComms, tenant } from "@certa/network";
import type { QueryFunctionContext } from "react-query";
import { useInfiniteQuery } from "react-query";
import { getCommsAuthToken } from "./getCommsAuthToken.query";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import type { CommentObjectTypes, UserGroup } from "@certa/types";
import { Godaam } from "@certa/common/utils/Godaam";

type UserMentionListComms = {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserMentionComms[];
};

export type UserMentionComms = {
  conversation: {
    kryptonWorkflowId: number;
    objectId: number;
    objectType: CommentObjectTypes;
  };
  conversationId: {
    $oid: string;
  };
  id: string;
  isRead: boolean;
  postedAt: string;
  postedBy: {
    id: number;
    firstName: string;
    fullName: string;
    email: string;
    groups: UserGroup[];
  };
  kryptonId: number;
  body: string;
};

type UserMentionListCommsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserMentionCommsReponse[];
};

type UserMentionCommsReponse = {
  conversation: {
    krypton_workflow_id: number;
    object_id: number;
    object_type: CommentObjectTypes;
  };
  conversation_id: {
    $oid: string;
  };
  _id: {
    $oid: string;
  };
  read_by?: number[];
  posted_at: {
    $date: string;
  };
  posted_by: {
    id: number;
    first_name: string;
    full_name: string;
    email: string;
    groups: UserGroup[];
  };
  krypton_id: number;
  body: string;
};

const getMentionsListComms = async ({
  pageParam = 1
}: QueryFunctionContext): Promise<UserMentionListComms> => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "x-tenant": tenant,
      "Content-Type": "application/json"
    },
    credentials: "include"
  };
  return APIFetchComms<UserMentionListCommsResponse>(
    `message/mentions_list?page=${pageParam}`,
    requestOptions
  ).then(mentionsListModelCreator);
};

const useGetMentionsListCommsInfiniteQuery = () => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const commsToken = getCommsAuthToken(!!isCommsNewEndpointEnabled);
  return useInfiniteQuery(
    ["user-mention-list-comms", commsToken],
    getMentionsListComms,
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.next) return undefined;
        return allPages.length + 1;
      },
      enabled: isCommsNewEndpointEnabled && !!commsToken
    }
  );
};

const convertUserMentionCommsResponseToCamelCase = (
  response: UserMentionCommsReponse,
  userId: number
): UserMentionComms => {
  return {
    conversation: {
      kryptonWorkflowId: response.conversation.krypton_workflow_id,
      objectId: response.conversation.object_id,
      objectType: response.conversation.object_type
    },
    conversationId: {
      $oid: response.conversation_id.$oid
    },
    id: response._id.$oid,
    isRead: response.read_by?.includes(userId) ?? false,
    postedBy: {
      id: response.posted_by.id,
      firstName: response.posted_by.first_name.trim(),
      fullName: response.posted_by.full_name.trim(),
      email: response.posted_by.email,
      groups: response.posted_by.groups
    },
    postedAt: response.posted_at.$date,
    kryptonId: response.krypton_id,
    body: response.body
  };
};

const convertUserMentionListCommsResponseToCamelCase = (
  response: UserMentionListCommsResponse
): UserMentionListComms => {
  const userId = JSON.parse(Godaam.user).id;
  return {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results: response.results.map(item =>
      convertUserMentionCommsResponseToCamelCase(item, userId)
    )
  };
};

const mentionsListModelCreator = (
  response: UserMentionListCommsResponse
): UserMentionListComms => {
  return convertUserMentionListCommsResponseToCamelCase(response);
};

export { useGetMentionsListCommsInfiniteQuery };
