import {
  getAllAlerts$$,
  getAllTaskQueues$$,
  getAdvancedFilterData$$,
  searchWorkflow$$,
  getAllKinds$$
} from "./services";
import { to } from "await-to-js";
import {
  getAllKinds,
  getAllKindsSuccess,
  getAllKindsFailure,
  getAllAlerts,
  getAllAlertsSuccess,
  getAllAlertsFailure,
  getAllTaskQueues,
  getAllTaskQueuesSuccess,
  getAllTaskQueuesFailure,
  getWorkflowList,
  getWorkflowListFailure,
  getWorkflowListSuccess,
  getAdvancedFilterData,
  getAdvancedFilterDataFailure,
  getAdvancedFilterDataSuccess
} from "./actionCreators";
import {
  appendRankInWorkflowItem,
  createFilterParams,
  createSearchParams
} from "./utils";
import { showNotification } from "../common/notification";

export const getAllKindsThunk = () => {
  return async (dispatch, getState) => {
    if (!getState().workflowList.kinds.data) {
      dispatch(getAllKinds());
    }

    const [error, kinds] = await to(getAllKinds$$());

    if (error) {
      dispatch(getAllKindsFailure(error.message));
      throw error;
    }

    dispatch(getAllKindsSuccess(kinds));
    return kinds;
  };
};

export const getAllAlertsThunk = (kindTag, hideLoader) => {
  return async dispatch => {
    if (!hideLoader) {
      dispatch(getAllAlerts());
    }

    const [error, alerts] = await to(getAllAlerts$$(kindTag));

    if (error) {
      dispatch(getAllAlertsFailure(error.message));
      throw error;
    }

    dispatch(getAllAlertsSuccess(alerts.alert_details));
    return alerts;
  };
};

export const getAllTaskQueuesThunk = (kindTag, hideLoader) => {
  return async dispatch => {
    if (!hideLoader) {
      dispatch(getAllTaskQueues());
    }

    const [error, taskQueues] = await to(getAllTaskQueues$$(kindTag));

    if (error) {
      dispatch(getAllTaskQueuesFailure(error.message));
      throw error;
    }

    dispatch(getAllTaskQueuesSuccess(taskQueues.stepgroupdef_counts));
    return taskQueues;
  };
};

let controller;
export function getWorkflowListThunk(hideLoader, { navigate }) {
  return async (dispatch, getState) => {
    if (!hideLoader) {
      dispatch(getWorkflowList());
    }
    const { selectedWorkflowFilters } = getState().workflowList;

    // Update Search Params
    const searchParams = createSearchParams(selectedWorkflowFilters);

    navigate({
      pathname: window.location.pathname,
      search: searchParams
    });

    controller &&
      controller.abort &&
      controller.abort("getWorkflowListThunk aborted API call");
    controller = new AbortController();

    const filterParams = createFilterParams(selectedWorkflowFilters);

    const [error, workflows] = await to(
      searchWorkflow$$(filterParams, controller)
    );

    if (error) {
      // Removed because there's no reason to show the user when their request is cancelled
      // because another "newer" request is getting data for them.
      // Chromes gives "The user aborted a request."
      // Firefox gives "The operation was aborted. "
      if (typeof error === "string" && /abort/.test(error)) return;

      showNotification({
        type: "error",
        message: error.message
      });

      dispatch(getWorkflowListFailure(error.message));
      // throw error;
      return;
    }
    dispatch(
      getWorkflowListSuccess(appendRankInWorkflowItem(workflows, filterParams))
    );
    return workflows;
  };
}

export function getAdvancedFilterDataThunk(kindTag) {
  return async (dispatch, getState) => {
    if (!getState().workflowList.staticData.advancedFilterData.data) {
      dispatch(getAdvancedFilterData());
    }

    const [error, filterData] = await to(getAdvancedFilterData$$(kindTag));

    if (error) {
      dispatch(getAdvancedFilterDataFailure(error.message));
      throw error;
    }

    dispatch(getAdvancedFilterDataSuccess(filterData.results));
    return filterData;
  };
}
