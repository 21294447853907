//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const RelatedProcesses = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="RelatedProcesses"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 1.00004C6.90125 1.00004 6 1.90128 6 3.00004C6 3.92567 6.64187 4.70324 7.5 4.92756V6.99188H2.5V10.07C1.64187 10.2949 1 11.0749 1 11.9999C1 13.0986 1.90125 13.9999 3 13.9999C4.09875 13.9999 5 13.0986 5 11.9999C5 11.0749 4.35813 10.2949 3.5 10.07V7.99187H8V8H12.5V10.0701C11.6419 10.2951 11 11.0751 11 12C11 13.0988 11.9012 14 13 14C14.0988 14 15 13.0988 15 12C15 11.075 14.3581 10.295 13.5 10.0701V7H8.5V4.92752C9.35813 4.70315 10 3.92565 10 3C10 1.90125 9.09875 1 8 1V1.00004Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
