import React, { forwardRef } from "react";

import { classNames } from "../../utils/common";
import {
  getTransformedPaddingWithCSSVariables,
  getTransformedMarginWithCSSVariables
} from "../utils";

import type { BoxProps } from "./types";

export const Box = forwardRef<HTMLElement, BoxProps>((props, ref) => {
  const {
    as = "div",
    children,
    dangerouslySetClassName = "",
    padding,
    height,
    minHeight,
    maxHeight,
    width,
    minWidth,
    maxWidth,
    backgroundColor,
    overflow,
    overflowX,
    overflowY,
    borderRadius,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    boxShadow,
    margin,
    color
  } = props;

  return (
    <>
      {React.createElement(as, {
        ref,
        children,
        style: {
          padding: getTransformedPaddingWithCSSVariables(padding),
          minHeight,
          maxHeight,
          height,
          minWidth,
          maxWidth,
          width,
          backgroundColor,
          // Do not change the order of the overflow properties
          overflowX,
          overflowY,
          overflow,
          borderRadius: borderRadius + "px",
          // Do not change the order of the border properties
          border,
          borderTop,
          borderRight,
          borderBottom,
          borderLeft,
          boxShadow,
          margin: getTransformedMarginWithCSSVariables(margin),
          color
        },
        className: classNames({
          [dangerouslySetClassName]: !!dangerouslySetClassName
        })
      })}
    </>
  );
});
