import { useGlobalSearchInfinite } from "@certa/queries/hooks/globalSearch.hook";
import { useDebouncedValue } from "../../../hooks/useDebounce";
import {
  CATEGORIES_WITH_ADVANCED_FILTERS,
  MIN_CHAR_LENGTH_TO_SEARCH,
  SEARCH_CATEGORIES,
  SEARCH_DEBOUNCE_TIME,
  SEARCH_QUERY_STALE_TIME
} from "../GlobalSearchNext.constants";
import { useSearchStore } from "../store/useSearchStore";
import { useEffect, useMemo } from "react";
import { getWorkflowIdsToFetchFamily } from "../../GlobalSearch/utils";
import { useGetWorkflowFamily } from "@certa/queries/workflows/workflowFamily";
import { useKinds } from "@certa/queries/hooks/kinds.hooks";

export const useGlobalSearch = () => {
  const {
    query,
    selectedCategories,
    filters,
    setAllSearchResults,
    setIsLoading,
    setWorkflowFamilyData,
    setIsLoadingWorkflowFamily,
    setKinds,
    setIsLoadingKinds,
    advancedFilterConditions,
    selectedKindIdForAdvancedFilters,
    areAdvancedFiltersApplied
  } = useSearchStore();
  const debouncedQuery = useDebouncedValue(query, SEARCH_DEBOUNCE_TIME);

  const { data: kindsData, isLoading: isLoadingKindsData } = useKinds();

  // Update kinds in store
  useEffect(() => {
    setIsLoadingKinds(isLoadingKindsData);
    if (kindsData) {
      setKinds(kindsData);
    }
  }, [kindsData, isLoadingKindsData, setKinds, setIsLoadingKinds]);

  const appliedCategories = areAdvancedFiltersApplied
    ? CATEGORIES_WITH_ADVANCED_FILTERS
    : selectedCategories;
  const hasWorkflowsInCategories = appliedCategories.includes(
    SEARCH_CATEGORIES.WORKFLOWS
  );
  const kindIdsToFilterFrom = areAdvancedFiltersApplied
    ? [selectedKindIdForAdvancedFilters]
    : filters.kindIds;
  const kindIds = hasWorkflowsInCategories ? kindIdsToFilterFrom : [];

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    status
  } = useGlobalSearchInfinite({
    query: debouncedQuery,
    selectedCategories: appliedCategories,
    advancedFilterConditions,
    filters: { ...filters, kindIds },
    config: {
      enabled:
        debouncedQuery.length > MIN_CHAR_LENGTH_TO_SEARCH &&
        selectedCategories.length > 0 &&
        !isLoadingKindsData, // Only enable search after kinds are loaded
      staleTime: SEARCH_QUERY_STALE_TIME
    }
  });

  const flatData = useMemo(() => {
    return data?.pages?.flatMap(page => page.results) ?? [];
  }, [data]);

  // Extract workflow IDs using utility function Current - Send all IDs):
  // 1. Prevents race conditions during rapid scrolling/pagination
  // 2. Optimizes network usage by consolidating multiple requests into one
  // 3. Simplifies state management by handling all data in a single update
  const workflowIds = useMemo(
    () => getWorkflowIdsToFetchFamily(flatData),
    [flatData]
  );

  // Fetch workflow families using the hook
  const {
    data: workflowFamilyData,
    isLoading: isLoadingWorkflowFamily,
    isSuccess: isSuccessWorkflowFamily
  } = useGetWorkflowFamily(workflowIds, {
    enabled: workflowIds.length > 0
  });

  useEffect(() => {
    setAllSearchResults(flatData);
    setIsLoading(isLoading);
  }, [flatData, isLoading, setAllSearchResults, setIsLoading]);

  useEffect(() => {
    setIsLoadingWorkflowFamily(isLoadingWorkflowFamily);
    if (isSuccessWorkflowFamily) {
      setWorkflowFamilyData(workflowFamilyData);
    }
  }, [
    workflowFamilyData,
    isLoadingWorkflowFamily,
    isSuccessWorkflowFamily,
    setWorkflowFamilyData,
    setIsLoadingWorkflowFamily
  ]);

  return {
    data,
    isLoading: isLoading || isLoadingKindsData, // Include kinds loading state
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    status,
    flatData
  };
};
