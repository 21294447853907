import React, { forwardRef } from "react";
import { Caret } from "@certa/icons/components/Caret";
import { Spinner } from "@certa/icons/components/Spinner";

import styles from "./Button.module.css";

import { classNames } from "../../utils/common";

export enum ButtonVariants {
  OUTLINE = "Outline",
  FILLED = "Filled",
  TEXT = "Text",
  LINK = "Link",
  ROUNDED = "Rounded"
}

export enum ButtonTypes {
  ICON = "Icon"
}

export enum ButtonColors {
  BRAND = "Brand",
  RED = "Red",
  ORANGE = "Orange",
  // TODO: Add this color to the button component after design is done
  PURPLE = "Purple"
}

export enum ButtonSizes {
  DEFAULT = "Default",
  SMALL = "Small"
}

export type ButtonProps = {
  onClick?: (
    evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onPointerDown?: (
    evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onKeyDown?: (
    evt: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onMouseEnter?: (
    evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onMouseLeave?: (
    evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  /** The variant of the button */
  variant?: `${ButtonVariants}`;
  type?: ButtonTypes;
  size?: `${ButtonSizes}`;
  color?: ButtonColors;
  disabled?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  fullWidth?: boolean;
  minWidth?: string;
  maxWidth?: string;
  target?: string;
  htmlType?: "button" | "submit" | "reset";
  showDropdownArrow?: boolean;
  tabIndex?: number;
} & ButtonAnchorProps &
  React.AriaAttributes;

type ButtonAnchorProps = {
  href?: string;
  rel?: string;
};

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const {
    onClick,
    onPointerDown,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    children,
    variant = ButtonVariants.TEXT,
    type,
    size = ButtonSizes.DEFAULT,
    color = ButtonColors.BRAND,
    loading: isLoading = false,
    disabled: isDisabled = false,
    // Icons should have the fill property with 'currentColor'
    leftIcon,
    rightIcon,
    minWidth,
    maxWidth,
    fullWidth: isFullWidth = false,
    href,
    target,
    rel,
    htmlType = "button",
    showDropdownArrow: shouldShowDropdownArrow = false,
    tabIndex,
    ...restProps
  } = props;

  const handleOnClick = isLoading ? undefined : onClick;

  const ariaProps: React.AriaAttributes = Object.entries(restProps)
    .filter(([restPropKey]) => restPropKey.includes("aria-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  const dataProps = Object.entries(restProps)
    .filter(([restPropKey]) => restPropKey.includes("data-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  const defaultProps: React.HTMLAttributes<
    HTMLButtonElement | HTMLAnchorElement
  > = {
    className: classNames({
      [styles.catalystButton]: true,
      [`${styles["catalystButton" + variant]}`]: !!variant,
      [`${styles["catalystButton" + type]}`]: !!type,
      [`${styles["catalystButton" + size]}`]:
        !!size && variant !== ButtonVariants.ROUNDED,
      [`${styles["catalystButton" + color]}`]: !!color,
      [styles.catalystButtonFullWidth]: isFullWidth,
      [styles.catalystButtonLoading]: isLoading,
      [styles.catalystButtonHasChildren]: !!children
    }),
    style: {
      minWidth,
      maxWidth
    },
    onClick: handleOnClick,
    tabIndex,
    ...ariaProps,
    "aria-label": ariaProps?.["aria-label"] || children?.toString(),
    "aria-describedby": ariaProps?.["aria-describedby"] || "",
    "aria-busy": isLoading,
    ...dataProps,
    onKeyDown: onKeyDown,
    onPointerDown: onPointerDown,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    onFocus,
    onBlur,
    children: (
      <>
        {isLoading && (
          <div className={styles.catalystButtonLoader}>
            <Spinner size={size === ButtonSizes.DEFAULT ? 16 : 12} />
          </div>
        )}
        {leftIcon && (
          <div className={styles.catalystButtonLeftIcon}>{leftIcon}</div>
        )}
        <span>{children}</span>
        {(rightIcon || shouldShowDropdownArrow) && (
          <div className={styles.catalystButtonRightIcon}>
            {rightIcon ? (
              rightIcon
            ) : (
              <Caret className={styles.catalystButtonDropdownIcon} />
            )}
          </div>
        )}
      </>
    )
  };

  const defaultAnchorProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > = {
    ...defaultProps,
    ref: ref as React.Ref<HTMLAnchorElement>,
    href,
    target,
    rel
  };

  if (href) {
    return <a {...defaultAnchorProps}>{defaultAnchorProps.children}</a>;
  }

  const defaultButtonProps: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > = {
    ...defaultProps,
    disabled: isDisabled,
    ref: ref as React.Ref<HTMLButtonElement>,
    type: htmlType
  };

  return <button {...defaultButtonProps} />;
});
