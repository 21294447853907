import {
  Button,
  ButtonSizes,
  ButtonVariants
} from "@certa/catalyst/components/Button";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { FilterLines } from "@certa/icons/components/FilterLines";
import { Search } from "@certa/icons/components/Search";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import {
  DEFAULT_SEARCH_CATEGORY,
  RECENT_SEARCHES_LISTBOX_ID,
  SEARCH_FILTERS_ID,
  SEARCH_LISTBOX_ID
} from "../GlobalSearchNext.constants";
import { useSearchStore } from "../store/useSearchStore";
import { checkIfAnyFilterIsApplied } from "./filters/filters.utils";
import { getSearchResultId } from "./MainContent";
import { getRecentSearchId } from "./RecentSearchList";
import { searchContainerStyles as styles } from "./SearchContainer.styles";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
const getItemId = (hasSearchQuery: boolean, selectedItem: number) => {
  const getter = hasSearchQuery ? getSearchResultId : getRecentSearchId;
  if (selectedItem >= 0) {
    return getter(selectedItem);
  }
  return undefined;
};
type SearchContainerProps = {
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const SearchContainer = ({ onKeyDown }: SearchContainerProps) => {
  const {
    query,
    selectedItem,
    setQuery,
    setSelectedItem,
    toggleFilterBar,
    filters,
    isFilterBarVisible,
    hasSearchQuery,
    areAdvancedFiltersApplied,
    selectedCategories,
    preSelectedKindIds,
    resetSearch
  } = useSearchStore();
  const inputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setSelectedItem(0);
  };

  const hasActiveFilters =
    checkIfAnyFilterIsApplied(filters) || areAdvancedFiltersApplied;
  const hasDirtyCategories =
    selectedCategories.length !== DEFAULT_SEARCH_CATEGORY.length;
  const hasPrefilledKinds =
    preSelectedKindIds?.length === filters.kindIds.length &&
    filters.kindIds.every(kindId => preSelectedKindIds.includes(kindId));
  const hasDirtyKindFilters = !hasPrefilledKinds;

  const shouldShowResetFilterButton =
    hasActiveFilters || hasDirtyCategories || hasDirtyKindFilters;

  const searchPlaceholder = intl.formatMessage({
    id: "homepage.searchbar.typeToSearch",
    defaultMessage: "Search..."
  });

  const resetFilterLabel = intl.formatMessage({
    id: "homepage.searchbar.resetFilters",
    defaultMessage: "Reset filters"
  });

  const handleResetFilters = () => {
    resetSearch();
    MixPanelActions.track(
      MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_RESET_FILTER_CLICK
    );
  };

  const handleToggleFilterBar = () => {
    toggleFilterBar();
    MixPanelActions.track(
      MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_FILTER_BAR_TOGGLE_CLICK
    );
  };

  return (
    <Stack
      direction="horizontal"
      dangerouslySetClassName={styles.searchContainer}
      height="4.688rem"
      padding="16"
      gap="8"
      align="center"
    >
      <Search size={14} />
      <input
        className={styles.input}
        ref={inputRef}
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={searchPlaceholder}
        role="combobox"
        aria-expanded="true"
        aria-controls={
          hasSearchQuery ? SEARCH_LISTBOX_ID : RECENT_SEARCHES_LISTBOX_ID
        }
        aria-activedescendant={getItemId(hasSearchQuery, selectedItem)}
        aria-autocomplete="list"
        aria-label="Search input"
      />
      {/* Todo: Use catalyst button https://thevetted.atlassian.net/browse/PLAT-28502 */}
      {hasSearchQuery ? (
        <Stack
          direction="horizontal"
          gap="8"
          align="center"
          justify="center"
          dangerouslySetClassName={styles.rightContainer}
        >
          {shouldShowResetFilterButton ? (
            <Button
              onClick={handleResetFilters}
              size={ButtonSizes.SMALL}
              variant={ButtonVariants.TEXT}
            >
              {resetFilterLabel}
            </Button>
          ) : null}

          <button
            onClick={handleToggleFilterBar}
            className={styles.filterButton({
              isActive: hasActiveFilters
            })}
            aria-expanded={isFilterBarVisible}
            aria-controls={SEARCH_FILTERS_ID}
            aria-label={`${isFilterBarVisible ? "Hide" : "Show"} filters`}
            data-testid="filter-button"
          >
            <FilterLines />
          </button>
        </Stack>
      ) : null}
    </Stack>
  );
};
