//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Attachment = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Attachment"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.1002 2.89984C12.5161 2.31576 11.7581 1.99997 10.9211 1.99997C10.0841 1.99997 9.32601 2.31576 8.74196 2.89984L2.89987 8.75803C2.31579 9.34211 2 10.1001 2 10.921C2 11.7419 2.31579 12.5161 2.89987 13.1001C3.48395 13.6842 4.25801 14 5.07897 14C5.88448 14 6.65791 13.6842 7.25807 13.1001L11.2375 9.12072C11.9165 8.3627 11.9005 7.19456 11.1746 6.45262C10.4326 5.6946 9.20089 5.6946 8.45894 6.45262L5.17395 9.73698C5.03209 9.87883 4.95315 10.0842 4.95315 10.2896C4.95315 10.495 5.0321 10.7004 5.17395 10.8422C5.4737 11.142 5.97947 11.142 6.26317 10.8422L9.51611 7.5893C9.65796 7.44744 9.91084 7.44744 10.0527 7.5893C10.1946 7.73116 10.1946 7.98404 10.0527 8.12589L6.15286 12.0104C5.56878 12.5945 4.58996 12.5945 3.98987 12.0104C3.40579 11.4263 3.40579 10.4475 3.98987 9.84741L9.84806 3.98922C10.4321 3.40513 11.411 3.40513 12.0111 3.98922C12.2954 4.27355 12.4533 4.65225 12.4533 5.06302C12.4533 5.4738 12.2954 5.85249 12.0111 6.15224C11.8532 6.2941 11.7742 6.49948 11.7742 6.70487C11.7742 6.91026 11.8692 7.11565 12.0111 7.2575C12.3108 7.54184 12.8005 7.54184 13.1003 7.2575C14.2999 6.04246 14.2999 4.10025 13.1003 2.89994L13.1002 2.89984Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
