import React from "react";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { RegionFilter } from "./RegionFilter";
import { BusinessUnitFilter } from "./BusinessUnitFilter";
import { StatusFilter } from "./StatusFilter";
import { DateFilter } from "./DateFilter";
import { useSearchStore } from "../../store/useSearchStore";
import { css } from "emotion";
import { SEARCH_FILTERS_ID } from "../../GlobalSearchNext.constants";
import { FilterTrigger } from "../FilterTrigger";
import { useIntl } from "react-intl";
import { AdvancedFilterWrapper } from "./AdvancedFilterWrapper";

const styles = {
  wrapper: css({
    flexWrap: "wrap",
    borderBottom: "1px solid var(--colors-neutral-300)"
  })
};

export const FilterBar: React.FC = () => {
  const {
    isFilterBarVisible,
    areAdvancedFiltersVisible,
    areAdvancedFiltersApplied,
    toggleAdvancedFiltersVisibility,
    advancedFilterConditions
  } = useSearchStore();
  const intl = useIntl();
  const advancedFiltersLabel = intl.formatMessage({
    id: "advancedFilters.advanced",
    defaultMessage: "Advanced"
  });
  const numberOfAppliedFilters = advancedFilterConditions?.length || 0;
  const label =
    numberOfAppliedFilters > 0
      ? `${advancedFiltersLabel} (${numberOfAppliedFilters})`
      : advancedFiltersLabel;
  if (!isFilterBarVisible) return null;

  return (
    <>
      <Stack
        direction="horizontal"
        gap="8"
        padding="8 16"
        dangerouslySetClassName={styles.wrapper}
        id={SEARCH_FILTERS_ID}
      >
        <RegionFilter />
        <BusinessUnitFilter />
        <StatusFilter />
        <DateFilter />
        <FilterTrigger
          label={label}
          onClick={toggleAdvancedFiltersVisibility}
          isActive={areAdvancedFiltersApplied}
          aria-expanded={areAdvancedFiltersVisible}
        />
      </Stack>
      <AdvancedFilterWrapper />
    </>
  );
};
