import React, { useState, forwardRef } from "react";

import { ChevronDownLight } from "@certa/icons/components/ChevronDownLight";
import { ChevronUpLight } from "@certa/icons/components/ChevronUpLight";

import { NavbarItem, type NavbarItemProps } from ".";

import styles from "./NavbarItem.module.css";

type NavbarItemCollapseProps = {
  label: string;
  id: string;
  icon: React.ReactElement;
  collapsableNavItems: NavbarItemProps[];
};

type NavbarItemCollapseInternalProps = {
  expanded: boolean;
  isActive: boolean;
  // These two events are majorly used for dropdown menu trigger
  onPointerDown?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLButtonElement>) => void;
};

export const NavbarItemCollapse = forwardRef<
  HTMLButtonElement,
  NavbarItemCollapseProps
>((props, ref) => {
  const { label, id, icon, collapsableNavItems, ...internalProps } = props;

  const [isCollapseExpanded, setIsCollapseExpanded] = useState(false);

  const handleOnClick = () => {
    setIsCollapseExpanded(prevState => !prevState);
  };

  // Extracting aria props majorly used by dropdown menu trigger
  const ariaProps: React.AriaAttributes = Object.entries(internalProps)
    .filter(([restPropKey]) => restPropKey.includes("aria-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  // Extracting data props majorly used by dropdown menu trigger
  const dataProps = Object.entries(internalProps)
    .filter(([restPropKey]) => restPropKey.includes("data-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  const rightIcon = (internalProps as NavbarItemCollapseInternalProps)
    .expanded ? (
    isCollapseExpanded ? (
      <ChevronUpLight />
    ) : (
      <ChevronDownLight />
    )
  ) : undefined;

  return (
    <>
      <NavbarItem
        id={id}
        label={label}
        onClick={handleOnClick}
        icon={icon}
        rightIcon={rightIcon}
        {...ariaProps}
        {...dataProps}
        {...internalProps}
      />
      {isCollapseExpanded && (
        <ul className={styles.catalystCollapsableNavItemsContainer}>
          {collapsableNavItems.map(collapsedItem => {
            return (
              <NavbarItem
                icon={
                  <div
                    className={styles.catalystCollapseItemIconPlaceholder}
                  ></div>
                }
                key={collapsedItem.id}
                path={collapsedItem.path}
                {...collapsedItem}
                {...internalProps}
              />
            );
          })}
        </ul>
      )}
    </>
  );
});
