import { useEffect } from "react";
import type { AggregateModelledResponse } from "@certa/common/types";

const DEFAULT_COUNT = 0;

type Params = {
  chartData: AggregateModelledResponse;
  isLoading: boolean;
  setTotalCount?: (count: number | undefined) => void;
};

export const useSetTotalCount = (params: Params) => {
  const { chartData, isLoading, setTotalCount } = params;

  useEffect(() => {
    const count = chartData.data.length;
    if (isLoading) {
      setTotalCount?.(undefined);
    } else {
      setTotalCount?.(count || DEFAULT_COUNT);
    }
  }, [chartData, isLoading, setTotalCount]);
};
