import React, { Component } from "react";
import * as Sentry from "@sentry/react";
import styled from "@emotion/styled";
import { MehOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { Button, ButtonVariants } from "@certa/catalyst/components/Button";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { stringConstants } from "../../../constants/stringConstants";
import { router } from "@certa/routing/routes";
import { extractErrorDetails } from "@certa/common/components/ErrorBoundary";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });

    this.eventId = Sentry.captureException(err);

    extractErrorDetails(err).then(processedError => {
      Sentry.captureEvent({
        message: processedError.message,
        label: "error",
        extra: {
          componentStack: info?.componentStack,
          originalError: err.message
        },
        stacktrace: [],
        beforeBreadcrumb(breadcrumb, hint) {
          return breadcrumb.category === "ui.click" ? null : breadcrumb;
        }
      });
    });
  }

  showFeedbackModal = () => {
    Sentry.showReportDialog({
      eventId: this.eventId || "unknown",
      title: "Feedback",
      subtitle: "Please share the details about actions that led you here.",
      subtitle2:
        "Your feedback is extremely important to us, as it will help us improve the product and serve you better."
    });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorPage onSubmitFeedback={this.showFeedbackModal} />;
    }
    return this.props.children;
  }
}

const ErrorPage = ({ onSubmitFeedback }) => {
  const intl = useIntl();
  const oopsMsg = intl.formatMessage({
    id: "commonTextInstances.oops",
    defaultMessage: "Oops!"
  });
  const errorBoundaryMsg = intl.formatMessage({
    id: "commonTextInstances.errorBoundaryMsg",
    defaultMessage:
      "Sorry! It looks like something isn’t working. Our engineering team has been notified. Thanks for your patience as they work through to get this running back up again. In the meantime please consider submitting feedback on the the actions that led you here."
  });

  const handleGoHome = () => {
    router.navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <StyledContainer>
      <StyledPadding />
      <StyledBody>
        <StyledCard>
          <StyledHeader>
            <MehOutlined style={{ color: "#ffffff", fontSize: 64 }} />
            <StyledHeading>
              {oopsMsg}
              {stringConstants.exclamation}
            </StyledHeading>
          </StyledHeader>
          <StyledDescription>{errorBoundaryMsg}</StyledDescription>
          <Stack
            direction="horizontal"
            align="center"
            justify="center"
            gap="24"
            padding="48 12 0 12"
          >
            <Button onClick={handleGoHome} fullWidth>
              {intl.formatMessage({
                id: "commonTextInstances.goHome",
                defaultMessage: "GO HOME"
              })}
            </Button>
            <Button
              onClick={onSubmitFeedback}
              fullWidth
              variant={ButtonVariants.FILLED}
            >
              {intl.formatMessage({
                id: "commonTextInstances.submitFeedback",
                defaultMessage: "SUBMIT FEEDBACK"
              })}
            </Button>
          </Stack>
        </StyledCard>
      </StyledBody>
      <StyledPadding />
    </StyledContainer>
  );
};

export const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  background-color: var(--theme-blue);
  margin: -40px -24px 24px -24px;
  padding-top: 32px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPadding = styled.div`
  flex: 1;
`;

export const StyledBody = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCard = styled.div`
  width: 40%;
  min-width: 400px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 40px 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const StyledHeading = styled.h1`
  text-align: center;
  color: #ffffff;
  margin-top: 12px;
`;

export const StyledDescription = styled.p`
  text-align: center;
`;
