import { APIFetchV3, RequestHeaders } from "@certa/network";
import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";
import { convertArrayToMap } from "@certa/common/utils/array";
import type { UseQueryReturnType } from "@certa/common/types";

export function useGetWorkflowFamily(
  leafNodes: GetWorkflowFamilyParams["leafNodes"],
  config?: UseQueryOptions<UseQueryReturnType<typeof getWorkflowFamily>>
) {
  return useQuery({
    ...config,
    queryKey: ["workflowFamily", leafNodes] as QueryKey,
    queryFn: () => getWorkflowFamily(leafNodes)
  });
}

async function getWorkflowFamily(
  leafNodeIds: GetWorkflowFamilyParams["leafNodes"]
) {
  /**
   * The primary purpose of the request body structure outlined below is to
   * enable the frontend to specify the attributes it requires in the response.
   * To prevent unnecessary complexity at this stage, we are hard-coding the
   * attributes within the service function, as this API is currently utilized
   * in only one location. In the future, these attributes should be passed as
   * an argument to the service function, thereby avoiding the need for defining
   * this extensive structure at the call site.
   */
  const requestBody = {
    data: {
      id: null,
      name: null,
      parents: null,
      logo: null
    },
    filters: {
      id__in: leafNodeIds.join(",")
    }
  };
  const requestOptions: RequestInit = {
    method: "POST",
    headers: RequestHeaders.POST,
    credentials: "include",
    body: JSON.stringify(requestBody)
  };
  const url = "workflows-family-tree/";

  const response = await APIFetchV3<RawAPIResponse.Response>(
    url,
    requestOptions
  );

  return modelCreator(response, leafNodeIds);
}

function modelCreator(
  response: RawAPIResponse.Response,
  leafNodes: GetWorkflowFamilyParams["leafNodes"]
): ModelledAPIResponse.FamilyMap {
  const responseMap = response.workflows
    ? convertArrayToMap(response.workflows, "id")
    : new Map<number, RawAPIResponse.Response["workflows"][number]>();

  const familyMap: ModelledAPIResponse.FamilyMap = new Map();

  for (const leafNode of leafNodes) {
    const family = getFamilyRecursively(leafNode, responseMap);
    familyMap.set(leafNode, family.reverse());
  }

  return familyMap;
}

function getFamilyRecursively<T extends { parents: number[] }>(
  leafNode: number,
  map: Map<number, T>
): Omit<T, "parents">[] {
  const leafNodeWorkflow = map.get(leafNode);

  if (!leafNodeWorkflow) {
    return [];
  }

  // Initialization
  const family: Omit<T, "parents">[] = [leafNodeWorkflow];
  const parentWorkflowId = leafNodeWorkflow.parents[0];

  // Termination
  if (!parentWorkflowId) {
    return family;
  }

  // Recursion
  family.push(...getFamilyRecursively(parentWorkflowId, map));
  return family;
}

type GetWorkflowFamilyParams = {
  leafNodes: number[];
};

namespace RawAPIResponse {
  export type Response = {
    workflows: {
      id: number;
      name: string;
      parents: number[];
      logo: string | null;
    }[];
  };
}

namespace ModelledAPIResponse {
  type LeafWorkflowId = number;

  export type FamilyMap = Map<LeafWorkflowId, Workflow[]>;

  export type Workflow = {
    id: number;
    name: string;
    logo: string | null;
  };
}

export type { ModelledAPIResponse as WorkflowFamilyResponse };
