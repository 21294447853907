/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { WorkflowLevelPermissionType } from "@certa/queries/types/permissions.types";
import { workflowServices } from "../services/workflow.services";

import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  type UseQueryOptions,
  type QueryKey
} from "react-query";

import { useNavigate } from "react-router";
import type {
  ReportOrdering,
  WorkflowFiltersQuery,
  RelatedKindType,
  WorkflowAccessQuery,
  GetAdvancedFilterDataORMType,
  StepGroupsData,
  StepUserTags
} from "../types/workflow.types";
import type {
  ColumnED,
  ColumnIC,
  FilterED,
  FilterIC,
  WorkflowDynamicAPIResultType
} from "@certa/types";
import { upsertWorkflow } from "../services/external.services";
import { payloadCreatorED } from "../models/emailDeliveryReportPayload.model";
import { columnsPayloadCreatorIC } from "../models/integrationCallReportPayload.model";
import { queryClient } from "../utils/utils";
import type { FieldHierarchyReturnType } from "../models/workflow.model";
import type { ReportWorkflowsCustomSort } from "@certa/dashboards/src/components/types";
import { useCheckLevelPermission } from "@certa/common/Chokidar";
import {
  EMAIL_DELIVERY_REPORT,
  INTEGRATION_CALL_REPORT,
  WORKFLOW_REPORT
} from "@certa/common/constants";
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_SIZE
} from "@certa/common/hooks/usePaginationOffset";
import type {
  UseInfiniteQueryExtraConfigType,
  UseMutationExtraConfigType,
  UseQueryExtraConfigType,
  UseQueryReturnType
} from "@certa/common/types";
import {
  ToastPlacements,
  ToastTypes,
  showToast
} from "../../catalyst/components/Toast";
import { useGetSwimlaneList } from "../queries/workflowDetails/getSwimlaneList.query";

export const useCreateWorkflowMutation = () =>
  useMutation(workflowServices.createWorkflow);

export const useCreateReportWorkflowWithLabelMappingsMutation = () =>
  useMutation(workflowServices.createReportWorkFlowWithLabelMappings);

export const useGetUserWorkflowByEmail = () =>
  useMutation(workflowServices.getUserWorkflowByEmail);

export const useUpdateChildWorkflow = () =>
  useMutation(workflowServices.updateChildWorkflow);

export const useArchiveWorkflowMutation = () =>
  useMutation(workflowServices.archiveWorkflow);

export const useExportReportMutation = () =>
  useMutation(workflowServices.exportReport);

export const useDiscardWorkflow = () =>
  useMutation(workflowServices.discardWorkflow);

export const useGetTaskLaneList = (
  taskId: number | undefined,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getStepGroupList>
  >,
  redirectToHomeOnError?: boolean // On Process detail page, when process id is invalid, redirection to home is required
) => {
  const navigate = useNavigate();
  return useGetSwimlaneList(taskId, {
    ...config,
    onError: error => {
      if (!redirectToHomeOnError) return;
      // @ts-expect-error error.status not available Check this
      if (error && error.status === 404) {
        // In process details page, when process not found adding redirction to home
        showToast({
          type: ToastTypes.ERROR,
          title: "Process not found!",
          placement: ToastPlacements.BOTTOM_LEFT
        });
        // Adding set timeout so like logout for user friendly notification, first error message is shown, then user is redircted to home page
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1000);
      }
    }
  });
};

export const getStepGroupDataFromCache = (taskId: number) =>
  queryClient.getQueryData<StepGroupsData>(["stepGroups", taskId]);

/**
 * TODO: Make proper types according to all filters supported
 * CAUTION:
 * TODO: This needs testing, since this touches the production code!
 */
export const useGetWorkflowList = (
  params: Record<string, string | number>,
  config?: UseInfiniteQueryExtraConfigType
) =>
  useInfiniteQuery(
    ["workflowList", params],
    () => workflowServices.getWorkflowList(params),
    {
      refetchInterval: 60 * 1000,
      retry: 0,
      getNextPageParam: ({ next }) => {
        if (next) {
          const urlParams = new URLSearchParams(next);
          const nextPage = urlParams.get("page");
          return nextPage;
        }
        return false;
      },
      ...config
    }
  );

export const useGetProcessMigrationStats = (
  processId: number | null,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getTaskMigrationStats>
  >
) =>
  useQuery({
    ...config,
    queryKey: [processId, "procesMigrationStats"] as QueryKey,
    queryFn: () =>
      workflowServices.getTaskMigrationStats({
        taskId: processId,
        includeTotalEstimatedCompletionTime: true
      }),
    enabled: processId !== null
  });

export const useGetProgressMap = (
  taskId: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getStepGroupList>
  >
) => {
  const { status: canViewProgressMapStatus } = useCheckLevelPermission({
    processId: taskId,
    checkFor: WorkflowLevelPermissionType.CAN_VIEW_PROGRESS_MAP
  });
  return useQuery({
    enabled:
      canViewProgressMapStatus === "granted" &&
      (taskId !== undefined || taskId !== null),
    retry: 0,
    ...config,
    queryKey: ["progressMap", taskId, { "show-all": true }] as QueryKey,
    queryFn: () =>
      workflowServices.getStepGroupList(taskId, { "show-all": "true" })
  });
};

export const useGetStepUserTags = (taskId: number) => {
  return useQuery({
    enabled: taskId !== null,
    retry: 0,
    refetchOnWindowFocus: false,
    queryKey: ["stepUserTags", taskId],
    queryFn: () => workflowServices.getStepUserTags(taskId)
  });
};

export const getStepUserTagsFromCache = (taskId: number) => {
  return queryClient.getQueryData<StepUserTags>(["stepUserTags", taskId]);
};

// TODO: Remove kind tag from this hook
export const useGetFieldsHierarchy = (
  {
    kindTag,
    processTypeId
  }: Parameters<typeof workflowServices.getAdvancedFilterData>[0],

  config?: UseQueryOptions<FieldHierarchyReturnType>
) => {
  return useQuery({
    ...config,
    enabled: !!kindTag || !!processTypeId,
    cacheTime: Infinity,
    staleTime: Infinity,

    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    queryKey: ["fields", kindTag || processTypeId] as QueryKey,
    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getAdvancedFilterData(
        {
          kindTag: kindTag,
          processTypeId: processTypeId
        },
        {
          signal
        }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("Aborting Advanced filters API call");
        controller.abort("useGetFieldsHierarchy aborted API call");
      };
      return promise;
    }
  });
};

export const useGetFieldsHierarchyORM = (
  {
    processTypeId,
    childrenKindIds = [],
    grandChildrenKindIds = [],
    isORMReport
  }: GetAdvancedFilterDataORMType,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getAdvancedFilterDataORM>
  >
) => {
  return useQuery({
    ...config,
    enabled: !!processTypeId,
    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    cacheTime: Infinity,
    // Cache it forever, it will anyways be invalidated once the page is reloaded,
    staleTime: Infinity,
    queryKey: [
      "fields",
      processTypeId,
      childrenKindIds?.join(","),
      grandChildrenKindIds?.join(","),
      isORMReport
    ] as QueryKey,
    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getAdvancedFilterDataORM(
        {
          processTypeId,
          childrenKindIds,
          grandChildrenKindIds,
          isORMReport
        },
        {
          signal
        }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("Aborting Advanced filters API call");
        controller.abort("useGetFieldsHierarchyORM aborted API call");
      };
      return promise;
    }
  });
};

type UseGetReportWorkflowsTypes = {
  queryKey?: string;
  query: WorkflowFiltersQuery | null;
  offset: number;
  fieldTags: string[];
  ordering: ReportOrdering[] | null;
  config?: UseQueryExtraConfigType;
  reportType?: string;
  customSort?: ReportWorkflowsCustomSort;
  customPageSize?: number;
};
export const useReportWorkflows = ({
  queryKey,
  query,
  offset,
  fieldTags,
  ordering,
  config,
  reportType,
  customSort,
  customPageSize
}: UseGetReportWorkflowsTypes) => {
  const [count, setCount] = useState();
  const workflows = useGetReportWorkflows({
    query,
    ordering,
    offset,
    fieldTags,
    config,
    reportType,
    customPageSize
  });
  // Mutates the original array
  if (customSort) workflows?.data?.results?.sort(customSort);
  const { refetch } = useGetReportWorkflowsCount({
    queryKey,
    query,
    config: {
      ...config,
      staleTime: 60 * 60 * 1000,
      enabled: false
    }
  });

  useEffect(() => {
    const _count = workflows.data?.results?.length;

    if (!workflows.isFetching) {
      if (_count < DEFAULT_PAGINATION_SIZE && offset === DEFAULT_OFFSET) {
        setCount(_count);
      } else if (_count !== undefined && offset === DEFAULT_OFFSET) {
        refetch().then(res => {
          setCount(res?.data?.count);
        });
      }
    }
  }, [workflows.isFetching, workflows.data?.results?.length, offset, refetch]);

  return {
    ...workflows,
    data: {
      count,
      results: workflows?.data?.results
    }
  };
};

export const useGetReportWorkflowsCount = ({
  queryKey,
  query,
  config
}: {
  queryKey?: string;
  query: WorkflowFiltersQuery | null;
  config?: UseQueryOptions<// Replace Any Type after getReportWorkflowsCount returns a type
  any>;
  // UseQueryReturnType<typeof workflowServices.getReportWorkflowsCount>
}) => {
  const reportWorkflowsCount = useQuery({
    refetchOnWindowFocus: false,
    retry: 0,
    ...config,
    queryKey: [queryKey ?? "report-data-count", query] as QueryKey,

    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getReportWorkflowsCount(
        {
          ...query! // Typecasting since this query will be disabled when there is no kind_id
        },
        { signal }
      );

      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("useGetReportWorkflowsCount: Aborting API call");
        controller.abort("useGetReportWorkflowsCount aborted API call");
      };
      return promise;
    }
  });
  return reportWorkflowsCount;
};

const useGetReportWorkflows = ({
  queryKey,
  query,
  offset,
  fieldTags,
  ordering,
  config,
  reportType,
  customPageSize
}: UseGetReportWorkflowsTypes) => {
  const reportWorkflows = useQuery({
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: Boolean(
      (query as WorkflowFiltersQuery)?.kind_id ||
        query?.self_created ||
        query?.incomplete_step_tags ||
        !!query?.id__in ||
        reportType === WORKFLOW_REPORT
    ),
    ...config,
    queryKey: [
      queryKey ?? "report-data",
      query,
      // Sorting this will make sure that the API
      // is not called once the fields ordering is changed
      [...fieldTags].sort(),
      offset,
      ordering
    ],

    queryFn: () => {
      // Create a new AbortController instance for this request
      const controller = new AbortController();

      // Get the abortController's signal
      const signal = controller.signal;
      const promise = workflowServices.getReportWorkflows(
        {
          ...query!, // Typecasting since this query will be disabled when there is no kind_id
          offset
        },
        fieldTags,
        ordering || undefined,
        { signal },
        customPageSize
      );
      // @ts-expect-error: Cancel the request if React Query calls the `promise.cancel` method
      // React query expects a .cancel method on the returned promise
      // and it will automatically cancel the old promise when a new call is fired
      // but naturally the promise object does not have a cancel property.
      promise.cancel = () => {
        console.log("useGetReportWorkflows: Aborting API call");
        controller.abort("useGetReportWorkflows aborted API call");
      };
      return promise;
    }
  });
  return {
    ...reportWorkflows,
    data: {
      count: reportWorkflows?.data?.count,
      results: reportWorkflows?.data?.results
    }
  };
};

export const useGetWorkflowDetails = (
  id: number | undefined,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getWorkflowDetailsById>
  >
) =>
  useQuery({
    enabled: id !== null,
    retry: 0,
    ...config!,
    queryKey: ["workflowDetails", id] as QueryKey,
    queryFn: () => workflowServices.getWorkflowDetailsById(id)
  });

export const useExportEmailDeliveryReport = () =>
  useMutation(
    ({
      attributes,
      filters,
      reportId
    }: {
      attributes: ColumnED[];
      filters?: FilterED;
      reportId: number;
    }) => {
      const { attributes: attributesPayload, filters: filtersPayload } =
        payloadCreatorED({
          attributes,
          filters
        });
      return workflowServices.exportEmailDeliveryReport({
        attributes: attributesPayload,
        filters: filtersPayload,
        reportId
      });
    }
  );

export const useExportIntegrationReport = () =>
  useMutation(
    ({
      attributes,
      filters,
      reportId
    }: {
      attributes: ColumnIC[];
      filters?: FilterIC;
      reportId: number;
    }) => {
      const { attributes: attributesPayload, filters: filtersPayload } =
        columnsPayloadCreatorIC({
          attributes,
          filters
        });
      return workflowServices.exportIntegrationReport({
        attributes: attributesPayload,
        filters: filtersPayload,
        reportId
      });
    }
  );

export const useGetWorkflowPermissionsType = (
  id: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.geWorkflowPermissionsTypeById>
  >
) =>
  useQuery({
    enabled: config?.enabled !== undefined ? config.enabled : !!id,
    retry: 0,
    staleTime: Infinity,
    ...config!,
    queryKey: ["workflowPermissions", id] as QueryKey,
    queryFn: () => workflowServices.geWorkflowPermissionsTypeById(id)
  });

export function useGetDynamicWorkflow<T, ResponseDataT = any>(
  payload: {
    data: { workflow: T };
    filters: WorkflowFiltersQuery;
    extra?: WorkflowAccessQuery;
  },
  config?: UseQueryOptions<
    unknown,
    unknown,
    WorkflowDynamicAPIResultType<ResponseDataT>
  >
) {
  return useQuery({
    enabled: !!payload?.data?.workflow,
    retry: 0,
    staleTime: Infinity,
    ...config!,
    queryKey: ["workflowDynamicAPI", payload] as QueryKey,
    queryFn: () => workflowServices.dynamicWorkflowAPI(payload)
  });
}

export const useGetRelatedWorkflows = (kindId: number) =>
  useQuery<RelatedKindType[]>({
    retry: 0,
    cacheTime: 0,
    queryKey: ["related-kinds"],
    queryFn: () => workflowServices.getRelatedWorkflows(kindId)
  });

export const useUpsertWorkflow = (mutateConfig?: UseMutationExtraConfigType) =>
  useMutation(upsertWorkflow, mutateConfig);

export const useGetWorkflowsList = (
  params: {
    filters: {
      workflowIds: number[];
    };
  },
  offset?: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getWorkflowsList>
  >
) => {
  const { workflowIds } = params.filters;
  const workflowIdsString = workflowIds.join(",");

  const payload = {
    data: {
      id: null,
      name: null,
      applied_badges: null,
      formatted_lc_data: null,
      status: {
        id: null,
        tag: null,
        kind: null,
        display_name: null,
        color_code: null
      },
      definition: {
        id: null,
        name: null,
        kind: {
          id: null,
          tag: null
        }
      },
      progress: null,
      my_tasks_count: null,
      created_at: null
    },
    filters: {
      id__in: workflowIdsString
    }
  };

  return useQuery({
    ...config,
    queryKey: ["workflows-list", workflowIds, offset] as QueryKey,
    queryFn: () => workflowServices.getWorkflowsList(payload, offset)
  });
};

export const useGetThirdPartyUsers = (
  workflowId: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof workflowServices.getThirdPartyUsers>
  >
) => {
  return useQuery({
    ...config,
    queryKey: ["thirdPartyUsers", workflowId] as QueryKey,
    queryFn: () => workflowServices.getThirdPartyUsers(workflowId)
  });
};

export const useInviteThirdPartyUser = (
  mutateConfig?: UseMutationExtraConfigType
) => useMutation(workflowServices.inviteThirdPartyUser, mutateConfig);

export const useDeleteThirdPartyUser = (
  mutateConfig?: UseMutationExtraConfigType
) => useMutation(workflowServices.deleteThirdPartyUser, mutateConfig);
