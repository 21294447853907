import React from "react";
import { MagicLinkRedirect } from "./MagicLinkRedirect";

export const GenericNotFound = () => {
  const param = document.location.search.split("=")[1];
  const errorText = "ERROR 404!";
  const pageNotFoundText = "Page Not Found";
  if (param === "magic-link") {
    return <MagicLinkRedirect />;
  } else {
    return (
      <div className="text-center">
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>
          {errorText}
          <br />
        </h1>
        <h3>{pageNotFoundText}</h3>
      </div>
    );
  }
};
