import type { FC, ReactElement, PropsWithChildren } from "react";
import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { autoLoginUser as _autoLoginUser } from "../LoginPage/loginSlice";
import { AppLoader } from "./AppLoader";
import to from "await-to-js";
import { languageActions } from "../header/actions";
import { logout$$ } from "../LoginPage/service";
import { StoragePermissionRequiredInstructions } from "./StoragePermissionRequiredInstructions";
import { GetStoragePermission } from "./GetStoragePermission";
import { supportStorageAccessApi } from "@certa/common/utils/storageHelpers";
import { Godaam } from "@certa/common/utils/Godaam";
import { useStoragePermissions } from "@certa/common/hooks/useStoragePermissions";
import { deleteCookie, isSafari } from "@certa/common/utils/helper";
import { USER_INTERACTION_REQUIRED_PATH } from "@certa/common/utils/routes";
import { EPHEMERAL_TOKEN_QUERY_PARAM } from "@certa/common/constants";
import { useAppDispatch } from "@certa/common/hooks";

type AutoLoginProps = PropsWithChildren<{}>;

// Constants
const PREFERRED_LANGUAGE_QUERY_PARAM = "preferred_language";

export const AutoLogin: FC<AutoLoginProps> = props => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { askForPermission, isPermissionNeeded, hasCheckedPermission } =
    useStoragePermissions();
  const urlParams = new URLSearchParams(location.search);
  const ephemeralToken = urlParams.get(EPHEMERAL_TOKEN_QUERY_PARAM);
  const preferredLanguage = urlParams.get(PREFERRED_LANGUAGE_QUERY_PARAM);
  const isAuthenticated = !!Godaam.user;

  const dispatch = useAppDispatch();

  const autoLoginUser = useCallback(
    async (ephemeralToken: string) => {
      if (isSafari() && document.cookie.includes(ephemeralToken)) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(EPHEMERAL_TOKEN_QUERY_PARAM);
        deleteCookie(EPHEMERAL_TOKEN_QUERY_PARAM);
        navigate(`${location.pathname}?${searchParams}`, { replace: true });
        return;
      }
      const loginPromise = dispatch(
        // @todo by useMutation
        _autoLoginUser(ephemeralToken)
      ) as any as Promise<any>;
      const [error] = await to(loginPromise);
      if (!error) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(EPHEMERAL_TOKEN_QUERY_PARAM);
        navigate(`${location.pathname}?${searchParams}`, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    },
    [dispatch, location.pathname, location.search, navigate]
  );

  const handleSubmit = useCallback(
    async (ephemeralToken: string) => {
      if (isAuthenticated) {
        await logout$$();
      }
      if (!isPermissionNeeded) {
        autoLoginUser(ephemeralToken);
      }
    },
    [autoLoginUser, isAuthenticated, isPermissionNeeded]
  );

  const handleEphemeralAuth = useCallback(
    async (ephemeralToken: string) => {
      const hasStoragePermission = await document.hasStorageAccess();
      if (
        hasStoragePermission &&
        !location.pathname.includes(USER_INTERACTION_REQUIRED_PATH)
      ) {
        handleSubmit(ephemeralToken);
      }
    },
    [handleSubmit, location.pathname]
  );

  useEffect(() => {
    if (preferredLanguage) {
      dispatch(languageActions.setLanguage(preferredLanguage));
    }
    if (ephemeralToken) {
      handleEphemeralAuth(ephemeralToken);
    }
  }, [
    handleSubmit,
    ephemeralToken,
    preferredLanguage,
    dispatch,
    isPermissionNeeded,
    location.pathname,
    location.search,
    handleEphemeralAuth
  ]);

  if (
    !ephemeralToken ||
    (ephemeralToken &&
      location.pathname.includes(USER_INTERACTION_REQUIRED_PATH))
  ) {
    return (children || null) as ReactElement;
  }

  if (supportStorageAccessApi() && hasCheckedPermission && isPermissionNeeded) {
    // Since safari has a different flow given below
    return isSafari() ? (
      <StoragePermissionRequiredInstructions
        askForPermission={askForPermission}
      />
    ) : (
      <GetStoragePermission askForPermission={askForPermission} />
    );
  }

  return <AppLoader />;
};
