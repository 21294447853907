import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";
import { checkAuth$$ } from "../services/auth.services";
import type { UseQueryReturnType } from "@certa/common/types";

/**
 * Checks whether the user is authenticated or not.
 */
export const useCheckAuth = (
  isLoginPage: boolean,
  config: UseQueryOptions<UseQueryReturnType<typeof checkAuth$$>, any>
) => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: !isLoginPage,
    ...config,
    queryKey: "checkAuth" as QueryKey,
    queryFn: checkAuth$$
  });
};
