import { css } from "emotion";
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Paper, Stack, Text } from "@certa/blocks/thanos";
import { ButtonVariants, Button } from "@certa/catalyst/components/Button";
import { Select } from "@certa/catalyst/components/Select";
import { Cascader } from "@certa/catalyst/components/Cascader";
import {
  useAdjudicationFlags,
  useIntegrationStatuses
} from "@certa/queries/hooks/comments.hooks";
import { useWorkflowStatuses } from "@certa/queries/hooks/filters.hooks";
import { useChannelContext, useCommentsContext } from "../../comments.context";
import type {
  DropdownStatusDefault,
  WorkflowStatus as ProcessStatus,
  AdjudicationStatus,
  IntegrationStatus
} from "@certa/types";
import { CommentObjectTypes } from "@certa/types";
import { Send } from "@certa/icons/components/Send";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { useGetApplicableRiskCodes } from "@certa/common/hooks/useGetApplicableRiskCodes";
import type { SubmitType } from "@certa/common/hooks/useAdjudicationApis";
import { useAdjudicationApis } from "@certa/common/hooks/useAdjudicationApis";
import type { ChangeType } from "@certa/common/hooks/useAdjudicationRiskAndStatusOnChange";
import { useAdjudicationRiskCodeAndStatusOnChange } from "@certa/common/hooks/useAdjudicationRiskAndStatusOnChange";

/**
 * TODOs: Split all the components in this file to separate files and then delete this file
 */

const ProcessStatusSelect: FC<{
  processTypeId: number;
  onChange: (val: string) => void;
  isUpdating: boolean;
  isReadOnly?: boolean;
  defaultStatus?: DropdownStatusDefault;
  adjudicationRiskCodes?: string[];
}> = props => {
  const {
    processTypeId,
    isUpdating,
    defaultStatus,
    onChange,
    isReadOnly = false
  } = props;
  const intl = useIntl();

  /**
   * Fetches the available statues for the child workflow's kind
   */
  const { data: workflowStatuses = [], status } = useWorkflowStatuses({
    workflowKindId: processTypeId
  });

  const handleStatusChange = (option: string) => {
    onChange(option);
  };

  const isDisabled = isReadOnly || status === "loading" || isUpdating;

  return (
    <Select
      width="250px"
      label="Status"
      placeholder={intl.formatMessage({
        id: "workflowFiltersTranslated.filterPlaceholders.status",
        defaultMessage: "Status"
      })}
      options={workflowStatuses.map((status: ProcessStatus) => ({
        label: status.label,
        value: status.id.toString()
      }))}
      isSearchable={false}
      isClearable={false}
      onChange={handleStatusChange}
      disabled={isDisabled}
      isLoading={status === "loading"}
      defaultValue={defaultStatus?.id?.toString()}
    />
  );
};

const IntegrationStatusSelect: FC<{
  onChange: (val: string) => void;
  isUpdating: boolean;
  isReadOnly?: boolean;
  defaultStatus?: DropdownStatusDefault;
}> = ({ defaultStatus, onChange, isUpdating, isReadOnly = false }) => {
  const intl = useIntl();
  const {
    data: integrationStatuses = [],
    isLoading: isLoadingintegrationStatuses
  } = useIntegrationStatuses();

  const handleOnchange = (val: string) => {
    onChange(val);
  };

  const isLoading = isLoadingintegrationStatuses || isUpdating;
  const isDisabled = isReadOnly || isLoading;

  return (
    <Select
      width="250px"
      label="Status"
      placeholder={intl.formatMessage({
        id: "workflowFiltersTranslated.filterPlaceholders.status",
        defaultMessage: "Status"
      })}
      options={integrationStatuses.map((status: IntegrationStatus) => ({
        label: status.label,
        value: status.tag
      }))}
      isSearchable={false}
      isClearable={false}
      isLoading={isLoading}
      onChange={handleOnchange}
      defaultValue={defaultStatus?.displayName}
      disabled={isDisabled}
    />
  );
};

const AdjudicationCascader: FC<{
  onChange: (val: any) => void;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
  isReadOnly?: boolean;
  isUpdating: boolean;
}> = ({
  defaultAdjudicationCode,
  isUpdating,
  adjudicationRiskCodes,
  onChange,
  isReadOnly = false
}) => {
  const intl = useIntl();

  const { data, status } = useAdjudicationFlags();
  const [defaultValueMap, setDefaultValueMap] = useState<{
    isDefaultValueSet: boolean;
    defaultValue?: (string | number)[];
  }>({
    isDefaultValueSet: false
  });

  useEffect(() => {
    if (status === "success") {
      if (
        defaultAdjudicationCode?.tag &&
        defaultAdjudicationCode?.reasonCodes
      ) {
        // If we have defaultAdjudicationCode and customized adjudicationRiskCode then,
        // If selected value is there after filtering customized risk code then it is fine
        // else you don't have to show selected value at all.
        let isItemFoundInFileterdRiskCode = false;
        if (adjudicationRiskCodes) {
          const afterFiltereingAdjudicationCodes = (data || []).filter(item =>
            adjudicationRiskCodes.includes(item.tag)
          );
          const findSelectedValue = afterFiltereingAdjudicationCodes.find(
            item => item.tag === defaultAdjudicationCode?.tag
          );
          if (!findSelectedValue) {
            setDefaultValueMap({ isDefaultValueSet: true });
          } else {
            isItemFoundInFileterdRiskCode = true;
          }
        }
        if (isItemFoundInFileterdRiskCode || !adjudicationRiskCodes) {
          // Write logic to set it
          const actualValue = data.find(
            item => item.tag === defaultAdjudicationCode?.tag
          );
          if (actualValue && actualValue.value !== undefined) {
            setDefaultValueMap({
              isDefaultValueSet: true,
              defaultValue: [
                actualValue.value,
                ...(defaultAdjudicationCode?.reasonCodes || [])
              ]
            });
          } else {
            setDefaultValueMap({
              isDefaultValueSet: true
            });
          }
        }
      } else {
        setDefaultValueMap({
          isDefaultValueSet: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, status, defaultAdjudicationCode, setDefaultValueMap]);

  const handleChange = (val: any) => {
    onChange(val);
    const flag = val[0];
    const reasonCodes = val.length > 1 ? [val[1]] : [];
    const defaultValue = [flag];
    if (reasonCodes.length > 0) {
      defaultValue.push(reasonCodes[0]);
    }
    setDefaultValueMap({
      isDefaultValueSet: true,
      defaultValue
    });
  };
  const applicableRiskCodes = useGetApplicableRiskCodes(
    adjudicationRiskCodes,
    data
  );
  const riskCodePlaceholder = intl.formatMessage({
    id: "workflowsInstances.comments.riskCodes",
    defaultMessage: "Risk Codes"
  });

  const isDisabled = isReadOnly || status === "loading" || isUpdating;

  return (
    <Cascader
      allowClear={false}
      minWidth="200px"
      width="100%"
      label={riskCodePlaceholder}
      placeholder={riskCodePlaceholder}
      options={applicableRiskCodes || []}
      onChange={handleChange}
      disabled={isDisabled}
      value={defaultValueMap?.defaultValue || undefined}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      defaultValue={defaultValueMap.defaultValue}
      expandTrigger="click"
    />
  );
};

const StatusDropdownView: FC<{
  threadId: number;
  defaultStatus?: DropdownStatusDefault;
  defaultAdjudicationCode?: AdjudicationStatus;
  adjudicationRiskCodes?: string[];
}> = ({
  threadId,
  defaultStatus,
  defaultAdjudicationCode,
  adjudicationRiskCodes
}) => {
  const { data, status } = useAdjudicationFlags();

  const intl = useIntl();

  const { isMobileResolution } = useScreenResolution();

  const { isReadOnly } = useCommentsContext();

  const { isIntegrationType: shouldShowIntegrationSelect, objectType } =
    useChannelContext();

  const {
    childWorkflowId: childProcessId,
    childWorkflowKind: childProcessTypeId
  } = useChannelContext();

  const shouldShowProcessStatusSelect =
    !isReadOnly && !!childProcessId && !!childProcessTypeId;

  const shouldShowAdjudicationCascader = [
    CommentObjectTypes.INTEGRATION_RESULT,
    CommentObjectTypes.WORKFLOW
  ].includes(objectType);

  const findAdjudicationFlagId = useMemo(() => {
    if (status === "success") {
      return data.find(item => item.tag === defaultAdjudicationCode?.tag)
        ?.value;
    }
    return undefined;
  }, [status, data, defaultAdjudicationCode]);

  const { dispatcher, isChanged, changeSet, changedPayload } =
    useAdjudicationRiskCodeAndStatusOnChange(
      shouldShowProcessStatusSelect && defaultStatus?.id
        ? {
            id: defaultStatus.id
          }
        : undefined,
      shouldShowAdjudicationCascader &&
        findAdjudicationFlagId &&
        status === "success"
        ? {
            flag: findAdjudicationFlagId,
            reasonCode: defaultAdjudicationCode?.reasonCodes?.[0]
          }
        : undefined,
      shouldShowIntegrationSelect && defaultStatus?.displayName
        ? {
            updatedStatus: defaultStatus.displayName
          }
        : undefined
    );

  const {
    onSubmit,
    isUpadtingWorkflowStatus,
    isUpdatingFlags,
    isUpdatingIntegrationStatus
  } = useAdjudicationApis(
    threadId,
    (resetType: ChangeType) => {
      dispatcher({ type: `RESET_${resetType}` });
    },
    childProcessId
  );
  // This effect is because we are not getting defaultAdjudicationCode in component mount.
  // It is async. Check parent component for more info
  useEffect(() => {
    if (defaultAdjudicationCode && findAdjudicationFlagId) {
      dispatcher({
        type: "SET_INITIAL_RISK_CODE",
        payload: {
          flag: findAdjudicationFlagId,
          reasonCode: defaultAdjudicationCode?.reasonCodes?.[0]
        }
      });
    }
  }, [defaultAdjudicationCode, dispatcher, findAdjudicationFlagId]);

  const onChangeInAnyDropdown = (
    changeType: ChangeType,
    changes: string | [number, string?]
  ) => {
    switch (changeType) {
      case "INTEGRATION_STATUS": {
        dispatcher({
          type: "CHANGE_INTEGRATION_STATUS",
          payload: {
            updatedStatus: changes as string
          }
        });
        break;
      }
      case "STATUS": {
        dispatcher({
          type: "CHANGE_STATUS",
          payload: {
            id: Number(changes)
          }
        });
        break;
      }
      case "RISK_CODE": {
        dispatcher({
          type: "CHANGE_RISK_CODE",
          payload: {
            flag: changes?.[0] as number,
            reasonCode: changes?.[1]
          }
        });
        break;
      }
    }
  };

  const onSubmitAll = () => {
    const finalPayload: SubmitType = [];
    changeSet.forEach(item => {
      if (changedPayload?.[item]) {
        finalPayload.push({
          type: item,
          payload: changedPayload?.[item]
        });
      }
    });
    if (finalPayload.length > 0) {
      onSubmit(finalPayload);
    }
  };

  if (
    !shouldShowIntegrationSelect &&
    !shouldShowAdjudicationCascader &&
    !shouldShowProcessStatusSelect
  )
    return null;

  const isUpdating =
    isUpadtingWorkflowStatus || isUpdatingFlags || isUpdatingIntegrationStatus;

  return (
    <Paper
      gutter="s4"
      direction="vertical"
      className={css`
        flex-direction: column;
        align-items: flex-end;
        gap: 11px;
      `}
    >
      <Stack
        direction={isMobileResolution ? "vertical" : "horizontal"}
        className={css`
          width: 100%;
          gap: 16px;
        `}
      >
        <Stack gap="s3" direction="vertical">
          {shouldShowProcessStatusSelect && (
            <Stack align="center" gap="s4">
              <Text variant="p1-bold" color="neutral-70">
                {intl.formatMessage({
                  id: "workflowFiltersTranslated.filterPlaceholders.status",
                  defaultMessage: "Status"
                })}
              </Text>
              <ProcessStatusSelect
                isReadOnly={isReadOnly}
                processTypeId={childProcessTypeId}
                defaultStatus={defaultStatus}
                onChange={val => onChangeInAnyDropdown("STATUS", val)}
                isUpdating={isUpdating}
              />
            </Stack>
          )}
          {shouldShowIntegrationSelect && (
            <Stack align="center" gap="s4">
              <Text variant="p1-bold" color="neutral-70">
                {intl.formatMessage({
                  id: "workflowFiltersTranslated.filterPlaceholders.status",
                  defaultMessage: "Status"
                })}
              </Text>
              <IntegrationStatusSelect
                isReadOnly={isReadOnly}
                defaultStatus={defaultStatus}
                onChange={val =>
                  onChangeInAnyDropdown("INTEGRATION_STATUS", val)
                }
                isUpdating={isUpdating}
              />
            </Stack>
          )}
        </Stack>

        {shouldShowAdjudicationCascader && (
          <Stack
            align="center"
            gap="s4"
            className={css`
              width: 100%;
            `}
          >
            <Text
              variant="p1-bold"
              color="neutral-70"
              className={css`
                width: fit-content !important;
              `}
            >
              {intl.formatMessage({
                id: "workflowsInstances.comments.adjudication",
                defaultMessage: "Adjudication"
              })}
            </Text>
            <AdjudicationCascader
              isReadOnly={isReadOnly}
              defaultAdjudicationCode={defaultAdjudicationCode}
              adjudicationRiskCodes={adjudicationRiskCodes}
              onChange={val => onChangeInAnyDropdown("RISK_CODE", val)}
              isUpdating={isUpdating}
            />
          </Stack>
        )}
      </Stack>
      <Button
        variant={ButtonVariants.FILLED}
        onClick={onSubmitAll}
        loading={isUpdating}
        disabled={!isChanged || isUpdating}
        leftIcon={<Send />}
      >
        <FormattedMessage id="stepBodyFormInstances.postButtonText" />
      </Button>
    </Paper>
  );
};

export { StatusDropdownView };
