//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Clear = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Clear"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3717)">
            <path
              d="M13.726 2.27401C11.6691 0.286813 8.71616 -0.467788 5.95823 0.288368C3.19971 1.04464 1.04476 3.19967 0.288413 5.95818C-0.467861 8.71611 0.286894 11.669 2.27405 13.726C4.33103 15.7132 7.28393 16.4678 10.0419 15.7116C12.8004 14.9554 14.9553 12.8003 15.7117 10.0418C16.468 7.28389 15.7132 4.33099 13.726 2.27401ZM11.4504 10.3007V10.3C11.6038 10.4526 11.6903 10.6591 11.6903 10.8755C11.6903 11.0912 11.6038 11.2985 11.4504 11.4503C11.2978 11.6022 11.0905 11.6865 10.8756 11.685C10.6599 11.6865 10.4534 11.6022 10.3 11.4503L8.00006 9.14201L5.7001 11.4503C5.54672 11.6022 5.34018 11.6865 5.12455 11.685C4.90966 11.6865 4.70237 11.6022 4.54975 11.4503C4.39637 11.2985 4.30981 11.0912 4.30981 10.8755C4.30981 10.6591 4.39637 10.4526 4.54975 10.3L6.85807 8.00002L4.54975 5.70006C4.23236 5.38191 4.23236 4.86708 4.54975 4.54969C4.86715 4.2323 5.38195 4.2323 5.70012 4.54969L8.00008 6.85801L10.3 4.54969C10.6182 4.2323 11.133 4.2323 11.4504 4.54969C11.7678 4.86708 11.7678 5.38189 11.4504 5.70006L9.14209 8.00002L11.4504 10.3007Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3717">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
