import { Eye } from "@certa/icons/components/Eye";
import { Edit } from "@certa/icons/components/Edit";
import { Checkmark } from "@certa/icons/components/Checkmark";
import { UndoSquare } from "@certa/icons/components/UndoSquare";
import { Envelope } from "@certa/icons/components/Envelope";
import { Bot } from "@certa/icons/components/Bot";
import { Trashcan } from "@certa/icons/components/Trashcan";
import { AddUser } from "@certa/icons/components/AddUser";
import { User } from "@certa/icons/components/User";

export const TIMELINE_ACTION_MAPPER = {
  viewed: {
    color: "teal",
    bgColor: "#F1FAFF",
    icon: Eye
  },
  edited: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  updated: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  submitted: {
    color: "green",
    bgColor: "#DEFFEF",
    icon: Checkmark
  },
  undo: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: UndoSquare
  },
  queued: {
    color: "neutral-70",
    bgColor: "#F6F6F6",
    icon: Envelope
  },
  delivered: {
    color: "green",
    bgColor: "#DEFFEF",
    icon: Envelope
  },
  bounced: {
    color: "red",
    bgColor: "#feeaea",
    icon: Envelope
  },
  deferred: {
    color: "orange",
    bgColor: "##FFF1DA",
    icon: Envelope
  },
  performed: {
    color: "purple",
    bgColor: "#FBDFFF",
    icon: Bot
  },
  created: {
    color: "orange",
    bgColor: "#FFF1DA",
    icon: Edit
  },
  deleted: {
    color: "red",
    bgColor: "#feeaea",
    icon: Trashcan
  },
  added: {
    color: "green",
    bgColor: "#DEFFEF",
    icon: AddUser
  },
  removed: {
    color: "red",
    bgColor: "#feeaea",
    icon: User
  }
};
