import { flexRender } from "@tanstack/react-table";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import type { HeaderDefinition } from "../../types";
import { getHeaderStyle } from "./DraggableHeader.styles";
import { hasSameParentHierarchy } from "../../utils/columnOrderUtils";

const DraggableHeader = ({
  header,
  allowDragging
}: {
  header: HeaderDefinition;
  allowDragging: boolean;
}) => {
  const columnMeta = header.column.columnDef.meta;
  const isDraggable = allowDragging && (columnMeta?.draggable ?? true);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
    over,
    active
  } = useSortable({
    id: header.column.id,
    disabled: !isDraggable,
    strategy: ({ layoutRects, index }) => {
      if (!layoutRects[index]) {
        return null;
      }

      return {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1
      };
    },
    data: { index: header.index }
  });

  /*
    Column's immediate parent needs to be same to be a valid swap
    @over.id - Target column's id (format: `columnId___parentId___grandParentId`)
    @active.id - Dragged column's id (format: `columnId___parentId___grandParentId`)
  */
  const isInvalid =
    over?.id && active?.id
      ? !hasSameParentHierarchy(over.id, active.id)
      : false;

  const headerStyle = getHeaderStyle({
    transform: CSS.Translate.toString(transform),
    isDragging,
    isDraggable,
    isOver: over?.id === header.column.id,
    isInvalid,
    isActive: active?.id === header.column.id
  });

  return (
    <th
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      rowSpan={columnMeta?.rowSpan || 1}
      colSpan={header.colSpan}
      className={headerStyle}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};

export { DraggableHeader };
