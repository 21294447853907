import { TooltipPlacements } from "@certa/catalyst/components/Tooltip";

const TOOLTIP_OFFSET = "0.5rem";

export const isPopoverAPISupported = () => {
  return "popover" in document.documentElement;
};

export const getTooltipTopAndLeft = (
  containerPosition: DOMRect | undefined,
  placement: TooltipPlacements
) => {
  let top = "0";
  let left = "0";
  let transform = "";
  if (!containerPosition) {
    return {
      top,
      left,
      transform
    };
  }
  const {
    top: containerTop,
    left: containerLeft,
    bottom: containerBottom,
    right: containerRight,
    width: containerWidth,
    height: containerHeight
  } = containerPosition;

  switch (placement) {
    case TooltipPlacements.TOP:
      top = `${containerTop}px`;
      left = `${containerLeft + containerWidth / 2}px`;
      transform = `translate(-50%, calc(-100% - ${TOOLTIP_OFFSET}))`;
      break;
    case TooltipPlacements.TOP_START:
      top = `${containerTop}px`;
      left = `${containerLeft}px`;
      transform = `translate(0%, calc(-100% - ${TOOLTIP_OFFSET}))`;
      break;
    case TooltipPlacements.TOP_END:
      top = `${containerTop}px`;
      left = `${containerRight}px`;
      transform = `translate(-100%, calc(-100% - ${TOOLTIP_OFFSET}))`;
      break;
    case TooltipPlacements.BOTTOM:
      top = `${containerBottom}px`;
      left = `${containerLeft + containerWidth / 2}px`;
      transform = `translate(-50%, ${TOOLTIP_OFFSET})`;
      break;
    case TooltipPlacements.BOTTOM_START:
      top = `${containerBottom}px`;
      left = `${containerLeft}px`;
      transform = `translate(0%, ${TOOLTIP_OFFSET})`;
      break;
    case TooltipPlacements.BOTTOM_END:
      top = `${containerBottom}px`;
      left = `${containerRight}px`;
      transform = `translate(-100%, ${TOOLTIP_OFFSET})`;
      break;
    case TooltipPlacements.LEFT:
      top = `${containerTop + containerHeight / 2}px`;
      left = `${containerLeft}px`;
      transform = `translate(calc(-100% - ${TOOLTIP_OFFSET}), -50%)`;
      break;
    case TooltipPlacements.LEFT_START:
      top = `${containerTop}px`;
      left = `${containerLeft}px`;
      transform = `translate(calc(-100% - ${TOOLTIP_OFFSET}), 0%)`;
      break;
    case TooltipPlacements.LEFT_END:
      top = `${containerBottom}px`;
      left = `${containerLeft}px`;
      transform = `translate(calc(-100% - ${TOOLTIP_OFFSET}), -100%)`;
      break;
    case TooltipPlacements.RIGHT:
      top = `${containerTop + containerHeight / 2}px`;
      left = `${containerRight}px`;
      transform = `translate(${TOOLTIP_OFFSET}, -50%)`;
      break;
    case TooltipPlacements.RIGHT_START:
      top = `${containerTop}px`;
      left = `${containerRight}px`;
      transform = `translate(${TOOLTIP_OFFSET}, 0%)`;
      break;
    case TooltipPlacements.RIGHT_END:
      top = `${containerBottom}px`;
      left = `${containerRight}px`;
      transform = `translate(${TOOLTIP_OFFSET}, -100%)`;
      break;
  }

  return {
    top,
    left,
    transform
  };
};
