import React from "react";
import "./Popover.css";

import { CATALYST_PORTAL_CONTAINER_IDS } from "../../constants/styles";
import {
  Popover as ChakraPopover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow
} from "@chakra-ui/popover";
import { Portal } from "../Portal/Portal";
import { FocusLock } from "../FocusLock/FocusLock";
import { useId } from "../../hooks/useId";

type VariationPlacement =
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "right-start"
  | "right-end"
  | "left-start"
  | "left-end";
type AutoPlacement = "auto" | "auto-start" | "auto-end";
export declare const top: "top"; // TODO: Madhav to confirm if export is required.
export declare const bottom: "bottom";
export declare const right: "right";
export declare const left: "left";
export declare const auto: "auto";
type BasePlacement = typeof top | typeof bottom | typeof right | typeof left;
type Placement = AutoPlacement | BasePlacement | VariationPlacement;

type Logical =
  | "start-start"
  | "start-end"
  | "end-start"
  | "end-end"
  | "start"
  | "end";

export type PopoverPlacements = Placement | Logical;

export type PopoverBaseProps = {
  /**
   * The content of the popover
   */
  content: string | React.ReactNode;
  /**
   * The trigger of the popover.
   */
  children: React.ReactElement;
  /**
   * If `true`, the popover will be opened in controlled mode
   */
  visible?: boolean;
  onVisibleChange?: (value: boolean) => void;
  borderRadius?: string;
  /**
   * If `true`, the popover will show an arrow tip
   * @default false
   */
  showArrow?: boolean;
  /**
   * If `true`, the popover will be opened in `portal`
   * @default false
   */
  openInPortal?: boolean;
  /**
   * The CSS positioning strategy to use.
   * @default "absolute"
   */
  strategy?: "absolute" | "fixed";
  /**
   * The placement of the popper relative to its reference.
   * @default "bottom"
   */
  placement?: PopoverPlacements;
  /**
   * The interaction that triggers the popover.
   *
   * `hover` - means the popover will open when you hover with mouse or
   * focus with keyboard on the popover trigger
   *
   * `click` - means the popover will open on click or
   * press `Enter` to `Space` on keyboard
   *
   * @default "click"
   */
  trigger?: "hover" | "click";
  /**
   * Performance 🚀:
   * If `true`, the PopoverContent rendering will be deferred
   * until the popover is open.
   */
  isLazy?: boolean;
  /**
   * Performance 🚀:
   * The lazy behavior of popover's content when not visible.
   * Only works when `isLazy={true}`
   *
   * - "unmount": The popover's content is always unmounted when not open.
   * - "keepMounted": The popover's content initially unmounted,
   * but stays mounted when popover is open.
   *
   * @default "unmount"
   */
  lazyBehavior?: "unmount" | "keepMounted";
  /**
   * The `ref` of the element that should receive focus when the popover opens.
   */
  initialFocusRef?: React.RefObject<{ focus(): void }>;
  /**
   * If `true`, focus will be locked inside popover
   * @default false
   */
  focusLock?: boolean;
  onClose?: () => void;
};

type PopoverAriaProps =
  | {
      trigger: "hover";
      ariaDescription: string;
    }
  | {
      trigger?: "click";
      ariaDescription?: never;
    };

export type PopoverProps = PopoverBaseProps & PopoverAriaProps;

export const Popover = (props: PopoverProps) => {
  const {
    content,
    children,
    trigger = "click",
    isLazy = true,
    strategy = "fixed",
    initialFocusRef,
    placement = "bottom",
    openInPortal: shouldOpenInPortal = false,
    focusLock: isFocusLock = false,
    showArrow: shouldShowArrow = false,
    visible: isVisible,
    ariaDescription,
    onClose
  } = props;
  const ariaDescriptionId = useId();
  const addDescription = trigger === "hover" && ariaDescription;

  const popoverInnerContent = (
    <FocusLock disabled={!isFocusLock}>
      {shouldShowArrow && (
        <PopoverArrow backgroundColor="var(--colors-neutral-100)" />
      )}

      <PopoverBody>{content}</PopoverBody>
    </FocusLock>
  );

  const popoverContent = (
    // @ts-expect-error - Ts defination of return of component is not well supported!
    <PopoverContent variants={{}}>{popoverInnerContent}</PopoverContent>
  );

  return (
    <ChakraPopover
      isLazy={isLazy}
      isOpen={isVisible}
      strategy={strategy}
      placement={placement}
      trigger={trigger}
      initialFocusRef={initialFocusRef}
      arrowSize={12}
      arrowShadowColor="rgba(0, 22, 78, 0.1)"
      onClose={onClose}
    >
      <PopoverTrigger>
        {React.cloneElement(children, {
          "aria-describedby": addDescription ? ariaDescriptionId : null,
          "data-state": isVisible ? "open" : "closed"
        })}
      </PopoverTrigger>
      <Portal
        disablePortal={!shouldOpenInPortal}
        containerId={CATALYST_PORTAL_CONTAINER_IDS.POPOVER}
      >
        {popoverContent}
      </Portal>
      {addDescription && (
        <div className="catalyst-screen-reader-only" id={ariaDescriptionId}>
          {ariaDescription}
        </div>
      )}
    </ChakraPopover>
  );
};
