import React, { useEffect, useMemo, memo } from "react";
import type {
  DropdownIndicatorProps,
  OptionProps,
  ClearIndicatorProps,
  ValueContainerProps,
  GroupProps,
  MenuProps,
  MenuListProps,
  ControlProps,
  GroupBase
} from "react-select";
import { components } from "react-select";
import { Check } from "@certa/icons/components/Check";
import { ChevronDown } from "@certa/icons/components/ChevronDown";
import { ChevronUp } from "@certa/icons/components/ChevronUp";
import { Clear } from "@certa/icons/components/Clear";
import { List, CellMeasurer, CellMeasurerCache } from "react-virtualized";

import type { SelectFooterButtonProps, SelectOption } from "./";

import { classNames, mergeRefs } from "../../utils/common";

import styles from "./Select.module.css";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { Button, ButtonSizes, ButtonVariants } from "../Button";

const cache = new CellMeasurerCache({ fixedWidth: true });

const MIN_OPTIONS_COUNT_TO_VIRTUALIZE: number = 15;

export const DropdownIndicatorComponent = memo(
  <T extends SelectOption>(props: DropdownIndicatorProps<T>) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <ChevronUp size={12} />
        ) : (
          <ChevronDown size={12} />
        )}
      </components.DropdownIndicator>
    );
  }
) as typeof components.DropdownIndicator;

type OptionsComponentProps<T> = {
  isVirtualized?: boolean;
  handleScrolledToBottom?: () => void;
} & OptionProps<T>;

export const OptionComponent = memo(
  <T extends SelectOption>(props: OptionsComponentProps<T>) => {
    const {
      isVirtualized,
      innerRef,
      options,
      data,
      handleScrolledToBottom,
      ...rest
    } = props;
    const currentOptionValue = data.value;
    const currentOptionIcon = data.icon;

    const isLastOption = useMemo(() => {
      const _optionIndex = options.findIndex(
        // @ts-expect-error: suppressed type error
        opt => opt.value === currentOptionValue
      );
      return _optionIndex === options.length - 1;
    }, [options, currentOptionValue]);

    const { ref } = useIntersectionObserver({
      loadMore: handleScrolledToBottom
    });

    /*
     * Adding menuPosition = "fixed", is prohibiting from menu option to be focused
     * (which normally works by default while using react-select)
     * So for bringing focused option to view, we do two additonal actions
     * 1. For non virtualized select, we add scrollIntoView, in ref of option
     * 2. Adding scrollToIndex, for virtualized select
     *
     * You may think, wasn't action 1 enough ?
     * No, because, when we use virtualized select, the options are not rendered
     * hence we have to use scrollToIndex
     *
     * Note:
     * Please make a note here that if new options are generated
     * (i.e., if a new object reference is created on each render),
     * this will cause scrolling to the top on change.
     * Please use memoized options to avoid this.
     */
    const customInnerRef = isVirtualized
      ? innerRef
      : (ref: HTMLDivElement | null) => {
          if (ref && props.isFocused) {
            ref.scrollIntoView({ block: "nearest" });
          }
        };

    return (
      <components.Option
        {...rest}
        options={options}
        data={data}
        // @ts-expect-error: suppressed type error
        innerRef={mergeRefs([customInnerRef, isLastOption ? ref : null])}
      >
        <div className={styles.catalystSelectOption}>
          <div className={styles.catalystSelectOptionContent}>
            {currentOptionIcon && (
              <div className={styles.catalystSelectOptionIcon}>
                {currentOptionIcon}
              </div>
            )}
            {props.children}
          </div>
          {props.isSelected && <Check size={12} />}
        </div>
      </components.Option>
    );
  }
) as typeof components.Option;

export const ControlComponent = memo(
  <T extends SelectOption>(
    props: ControlProps<T> & { leftIcon?: () => React.ReactElement }
  ) => {
    const { leftIcon, innerProps, ...rest } = props;

    let title = "";
    const [labelValueIcon] = props.getValue();
    if (
      labelValueIcon &&
      labelValueIcon.label &&
      typeof labelValueIcon.label === "string"
    ) {
      title = labelValueIcon.label;
    }

    const getIcon = () => {
      if (leftIcon) {
        return leftIcon();
      }
      if (!props.isMulti && labelValueIcon?.icon) {
        return labelValueIcon.icon;
      }
      return null;
    };

    return (
      <components.Control {...rest} innerProps={{ ...innerProps, title }}>
        {getIcon() && (
          <div className={styles.catalystSelectLeftIcon}>{getIcon()}</div>
        )}
        {props.children}
      </components.Control>
    );
  }
) as typeof components.Control;

export const ClearIndicatorComponent = memo(
  <T extends SelectOption>(props: ClearIndicatorProps<T>) => {
    return (
      <components.ClearIndicator {...props}>
        <Clear size={12} />
      </components.ClearIndicator>
    );
  }
) as typeof components.ClearIndicator;

export const ValueContainerComponent = memo(
  <T extends SelectOption>(props: ValueContainerProps<T>) => {
    return (
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    );
  }
) as typeof components.ValueContainer;

export const GroupComponent = memo(
  <T extends SelectOption>(props: GroupProps<T>) => {
    return (
      <components.Group {...props} className={styles.catalystSelectGroup} />
    );
  }
) as typeof components.Group;

type MenuListComponentProps<T> = {
  isVirtualized?: boolean;
  menuHeight?: number;
  menuWidth: number;
  children: React.ReactElement | React.ReactElement[];
  filteredOptions: SelectOption[];
} & SelectFooterButtonProps &
  MenuListProps<T>;

export const MenuListComponent = memo(
  <T extends SelectOption>(props: MenuListComponentProps<T>) => {
    const {
      isVirtualized,
      menuHeight,
      menuWidth,
      children,
      footerButtonText,
      onClickFooterButton,
      shouldCloseOnFooterButtonClick,
      filteredOptions,
      ...restProps
    } = props;

    const hasGroupedOptions = useMemo(
      () => !!props.options.find(option => (option as GroupBase<T>)?.options),
      [props.options]
    );

    const currentOptionsCount = React.Children.toArray(children).length;

    const canVirtualize =
      isVirtualized &&
      !hasGroupedOptions &&
      currentOptionsCount > MIN_OPTIONS_COUNT_TO_VIRTUALIZE;

    useEffect(() => {
      // This is a workaround to clear the cache when the options changes.
      // Because the cache is not cleared, the height of the options is not recalculated.
      if (canVirtualize && currentOptionsCount > 15) {
        cache.clearAll();
      }
    }, [currentOptionsCount, canVirtualize]);

    const scrollToIndex = useMemo(() => {
      if (!props.focusedOption) {
        return undefined;
      }
      return filteredOptions.indexOf(props.focusedOption);
    }, [filteredOptions, props.focusedOption]);

    const handleFooterButtonClick = () => {
      onClickFooterButton?.();
      if (shouldCloseOnFooterButtonClick) {
        restProps.selectProps.onMenuClose();
      }
    };

    // TODO: Add support for virtualized menu with grouped options.
    // --------------------------------------------------------------
    // Issue: The grouped options with group heading has to be calculated and
    // rendered as direct childs of the react-virtualized List component. But the
    // react-select library renders the groups as separate divs which has the group
    // heading and the options as its children. This makes it difficult to calculate
    // the height of the group heading and the options together. Hence, the virtualized
    // menu is not supported for grouped options.
    return (
      <div className={styles.catalystMenuListWrapper}>
        {canVirtualize ? (
          <List
            overscanRowCount={10}
            width={menuWidth}
            height={menuHeight as number}
            rowCount={currentOptionsCount}
            rowHeight={cache.rowHeight}
            deferredMeasurementCache={cache}
            scrollToIndex={scrollToIndex}
            style={{
              padding: "0.375rem"
            }}
            rowRenderer={({ key, index, style, parent }) => {
              const isLastOption = index === currentOptionsCount - 1;

              // Add extra padding at the bottom of last element to account
              // for space used by footer button (only when required)
              const extraPaddingForFooter =
                isLastOption && footerButtonText
                  ? { paddingBottom: "3.5rem" }
                  : {};

              return (
                <CellMeasurer
                  key={key}
                  cache={cache}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ registerChild }) => {
                    return (
                      <div
                        key={key}
                        style={{
                          ...style,
                          ...extraPaddingForFooter
                        }}
                        ref={ref => {
                          if (ref) {
                            registerChild?.(ref);
                          }
                        }}
                      >
                        {(props.children as any)[index]}
                      </div>
                    );
                  }}
                </CellMeasurer>
              );
            }}
          />
        ) : (
          <components.MenuList
            {...restProps}
            className={classNames({
              [styles.catalystSelectMenuList]: !hasGroupedOptions
            })}
          >
            <div style={{ maxHeight: menuHeight, overflowY: "auto" }}>
              {/*
          https://github.com/JedWatson/react-select/issues/3128
          - The onMouseMove and onMouseOver events are removed from the
            menu options to improve the performance of the react-select menu.
          - This fixes the lagging issue when hovering over the options.
         */}
              {hasGroupedOptions
                ? React.Children.map(
                    children,
                    (groupChild: React.ReactElement) => {
                      if (Array.isArray(groupChild?.props?.children)) {
                        React.Children.toArray(
                          groupChild.props.children
                        ).forEach(child => {
                          delete (child as React.ReactElement)?.props
                            ?.innerProps?.onMouseMove;
                          delete (child as React.ReactElement)?.props
                            ?.innerProps?.onMouseOver;
                        });
                        return groupChild;
                      } else {
                        delete groupChild?.props?.innerProps?.onMouseMove;
                        delete groupChild?.props?.innerProps?.onMouseOver;
                        return (
                          <div
                            className={styles.catalystSelectGroupWithoutHeading}
                          >
                            {groupChild}
                          </div>
                        );
                      }
                    }
                  )
                : !!(children as React.ReactElement)?.key &&
                    React.Children.toArray(children).length > 0
                  ? React.Children.map(
                      children,
                      (child: React.ReactElement) => {
                        delete child?.props.innerProps.onMouseMove;
                        delete child?.props.innerProps.onMouseOver;
                        return child;
                      }
                    )
                  : children}
              {footerButtonText && (
                <div className={styles.catalystSelectFooterDummy} />
              )}
            </div>
          </components.MenuList>
        )}
        {/* The footer button is not accessible currently and is being added to unblock studio support */}
        {footerButtonText && (
          <div className={styles.catalystSelectFooterButtonWrapper}>
            <Button
              variant={ButtonVariants.FILLED}
              size={ButtonSizes.SMALL}
              onClick={handleFooterButtonClick}
            >
              {footerButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  }
) as React.FC<MenuListComponentProps<any>>;

export const MenuComponent = memo(
  <T extends SelectOption>(props: MenuProps<T>) => {
    const hasGroupedOptions = useMemo(
      () => !!props.options.find(option => (option as GroupBase<T>)?.options),
      [props.options]
    );

    return hasGroupedOptions ? (
      <components.Menu {...props}>
        <div className={styles.catalystSelectGroup}>{props.children}</div>
      </components.Menu>
    ) : (
      <components.Menu {...props}>
        <div className={styles.catalystSelectMenu}>{props.children}</div>
      </components.Menu>
    );
  }
) as typeof components.Menu;
