import {
  APIFetch,
  APIFetchV2,
  APIFetchMimir,
  RequestHeaders
} from "@certa/network";
import type {
  SelectOption,
  UserListOption,
  FilePreviewDataSource
} from "@certa/types";
import {
  regionModelCreatorV2,
  statusModelCreator
} from "../models/common.model";
import { queryClient } from "@certa/queries/utils/utils";
import { keyBy } from "lodash-es";

export const getRegionsV2 = ({
  limit,
  offset
}: {
  limit: string | number;
  offset?: number;
}) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `regions/?limit=${limit}${offset ? `&offset=${offset}` : ``}`;

  return APIFetchV2(url, requestOptions).then(regionModelCreatorV2);
};

export const getBUV2 = ({
  regions,
  limit,
  offset
}: {
  regions?: number[];
  limit: string | number;
  offset?: number;
}) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const params = new URLSearchParams({
    limit: String(limit),
    ...(regions && regions.length
      ? { region__in: regions.join(",") }
      : undefined),
    ...(offset ? { offset: String(offset) } : undefined)
  });

  const url = `business-units/?${params}`;

  return APIFetchV2(url, requestOptions).then(regionModelCreatorV2);
};

export async function getStatusesList() {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  const url = `available-workflow-statuses/`;

  return APIFetchV2(url, requestOptions).then((data: any) => {
    queryClient.setQueryData("availableStatusesKeyedById", keyBy(data, "id"));
    const status = statusModelCreator(data);
    queryClient.setQueryData(
      "availableStatusesKeyedByTag",
      keyBy(status, "tag")
    );
    return status;
  });
}

export async function getDropdownKinds(kindsToExclude: string[] = []) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  // Hard coding them here, since generally
  // these are the types that we want to ignore
  // If in future we find a use cases where
  // this API is used just to exclude some kinds
  // due to some permissions or feature requirement,
  // we can extract this out
  const params = new URLSearchParams({
    exclude: [
      "self-service",
      "stepgroups",
      "steps",
      "fields",
      "notifications",
      "alerts",
      "platform-settings",
      "client-users",
      "alert-categories",
      "comment-flag-options",
      "connectors",
      "email-templates",
      "integration-config",
      "reusable-components",
      "saved-search",
      ...kindsToExclude
    ].join(",")
  });

  const url = `workflowkind-as-dropdown/?${params}`;

  return APIFetchV2(url, requestOptions).then(
    (response: any) => response.results as { label: string; value: string }[]
  );
}

export async function fetchApiUrl(url: string) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  if (url.match(/^https?:\/\//)) {
    return await Promise.reject("Not allowed");
  }

  if (url.includes("api/v2/")) {
    return await APIFetchV2(url.replace("api/v2/", ""), requestOptions);
  }

  return await APIFetch(url.replace("api/v1/", ""), requestOptions);
}

export const getUserGroups = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch("extra-json/user_group_list/", requestOptions).then(
    (response: any) => response.results as SelectOption[]
  );
};

export const getActiveUsers = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetchV2(
    "workflow-reports/user-list-for-subscription/?limit=none",
    requestOptions
  ).then((response: unknown) => {
    return response as UserListOption[];
  });
};

export const getFileBlobURL = async (
  url: string,
  filePreviewDataSource?: FilePreviewDataSource
) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const apiUrl = url.split(/v[12]\//)?.[1] || url;
  const fetcher =
    filePreviewDataSource === "mimirV1" ? APIFetchMimir : APIFetchV2;

  return fetcher<Blob>(apiUrl, requestOptions).then(async (response: any) => {
    if (response.ok) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
  });
};

export const getFileMimeType = async (
  url: string,
  filePreviewDataSource?: FilePreviewDataSource
) => {
  const requestOptions: RequestInit = {
    method: "HEAD",
    headers: RequestHeaders.GET,
    credentials: "include"
  };
  const apiUrl = url.split(/v[12]\//)?.[1] || url;
  const fetcher =
    filePreviewDataSource === "mimirV1" ? APIFetchMimir : APIFetchV2;

  return fetcher<Blob>(apiUrl, requestOptions).then(async (response: any) => {
    if (response.ok) {
      return response.headers.get("content-type");
    }
  });
};
