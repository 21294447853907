import { forwardRef, createContext, useContext } from "react";
import styles from "./Skeleton.module.css";
import type { Width, Height } from "../../types/common";

/**
 * Variants represent the content being loaded, not just their shape
 */
type SkeletonVariant = "text" | "circular" | "rectangular";

const SkeletonGroupContext = createContext(false);

type SkeletonProps = {
  /**
   * The type of content being loaded
   * @default "text"
   */
  variant?: SkeletonVariant;
  /**
   * Width of the skeleton
   * @default "100%"
   */
  width?: Width;
  /**
   * Height of the skeleton
   */
  height?: Height;
  /**
   * Required when not inside a SkeletonGroup
   */
  loadingLabel?: string;
  style?: React.CSSProperties;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "className" | "role" | "aria-label" | "style"
>;

type SkeletonGroupProps = {
  /**
   * Label describing what is loading
   */
  label: string;
  children: React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "role" | "aria-label">;

/**
 * Container for multiple skeleton components
 */
export const SkeletonGroup = ({
  label,
  children,
  ...props
}: SkeletonGroupProps) => (
  <SkeletonGroupContext.Provider value={true}>
    <div role="status" aria-label={label} {...props}>
      {children}
    </div>
  </SkeletonGroupContext.Provider>
);

/**
 * Skeleton component for loading states
 */
export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({ variant = "text", width = "100%", height, loadingLabel }, ref) => {
    const isInGroup = useContext(SkeletonGroupContext);

    if (!isInGroup && !loadingLabel) {
      throw new Error(
        "Skeleton must have a loadingLabel prop when not used within a SkeletonGroup"
      );
    }

    return (
      <div
        ref={ref}
        className={`${styles.base} ${styles[variant]}`}
        style={{ width, height }}
        {...(!isInGroup && {
          role: "status",
          "aria-label": loadingLabel
        })}
      />
    );
  }
);
