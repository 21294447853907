//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Phone = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Phone"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3842)">
            <path
              d="M4.93472 1.48639C5.27793 1.82955 5.62266 2.17119 5.96435 2.51587C6.56686 3.12366 6.56841 3.8321 5.96816 4.43529C5.53801 4.86767 5.10938 5.30311 4.67083 5.72711C4.5549 5.83921 4.54575 5.93149 4.60448 6.07179C4.89278 6.76422 5.30921 7.37658 5.77825 7.95308C6.71941 9.11145 7.78482 10.1394 9.05628 10.937C9.32855 11.1079 9.62982 11.2322 9.91277 11.3862C10.0584 11.4655 10.1584 11.4404 10.2751 11.3191C10.7044 10.8753 11.1438 10.4414 11.5831 10.0067C12.1574 9.43779 12.8789 9.43627 13.4562 10.0082C14.1617 10.7067 14.8634 11.4083 15.5628 12.1129C16.1493 12.7039 16.1447 13.4253 15.5551 14.0209C15.157 14.4228 14.7353 14.8048 14.3585 15.2258C13.8101 15.8389 13.1222 16.0371 12.3351 15.9944C11.1911 15.9319 10.1355 15.5521 9.11959 15.058C6.86056 13.9606 4.92936 12.4393 3.31407 10.516C2.1197 9.09452 1.13197 7.54723 0.482967 5.80019C0.168734 4.9545 -0.0562507 4.08896 0.0123791 3.1731C0.0543263 2.60803 0.26712 2.12759 0.683546 1.73106C1.13049 1.30553 1.55683 0.85713 1.9969 0.424753C2.57349 -0.142599 3.29499 -0.141076 3.87312 0.426278C4.23006 0.77631 4.5809 1.13243 4.93479 1.48627L4.93472 1.48639Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3842">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
