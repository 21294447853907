import { forwardRef } from "react";
import { CATALYST_Z_INDEXES } from "../../constants/styles";
import { useScrollLocker } from "../../hooks/useScrollLocker";
import { classNames } from "../../utils/common";
import { canUseDom } from "../../utils/dom/canUseDom";

import styles from "./Dimmer.module.css";

type DimmerProps = {
  zIndex?: number;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  variant?: DimmerVariants;
  scrollLock?: boolean;
  className?: string;
};

export enum DimmerVariants {
  DEFAULT = "Default",
  TRANSPARENT = "Transparent"
}

export const Dimmer = forwardRef<HTMLDivElement, DimmerProps>((props, ref) => {
  // TODO: zIndex to be finalised based on the Toasts, dialogs and dropdowns
  const {
    zIndex = CATALYST_Z_INDEXES.DIMMER,
    onClick,
    variant = DimmerVariants.DEFAULT,
    scrollLock: shouldScrollLock = false,
    className
  } = props;

  useScrollLocker(shouldScrollLock && canUseDom());

  return (
    <div
      ref={ref}
      className={classNames({
        [styles.catalystDimmer]: true,
        [styles[`catalystDimmer${variant}`]]: true,
        ...(className ? { [className]: true } : {})
      })}
      aria-hidden={true}
      style={{ zIndex }}
      onClick={onClick}
    ></div>
  );
});
