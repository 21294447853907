import { FieldTypes } from "@certa/types";
import type { ReportCustomFilterCondition } from "@certa/types";
import { Select } from "@certa/catalyst/components/Select";
import { css } from "emotion";
import { isDateField } from "./ValueFieldInput/ValueFieldInput.utils";
import type { OperatorsDetails } from "../../../utils/filterUtils";
import { operatorsDetails } from "../../../utils/filterUtils";

type OperatorFieldProps = {
  value: ReportCustomFilterCondition["operator"] | null;
  fieldType: FieldTypes;
  useRelativeDates: boolean;
  onChange: (value: string | null) => void;
  isORMReport?: boolean;
  customOperators?: OperatorsDetails;
};

export const OperatorField = ({
  onChange,
  value,
  fieldType,
  useRelativeDates,
  isORMReport,
  customOperators
}: OperatorFieldProps) => {
  const operators = getOperators({
    fieldType,
    useRelativeDates,
    isORMReport,
    customOperators
  });

  return (
    <div className={operatorWrapperStyle}>
      <Select
        menuPortalTarget={document.body}
        label="Operator"
        options={operators}
        value={value}
        width="100%"
        isSearchable={false}
        onChange={onChange}
        isClearable={false}
      />
    </div>
  );
};

/**
 * Have a min-width of 120px for the operator field
 * so that single words like 'contains' don't break to next line
 */
const operatorWrapperStyle = css({
  minWidth: "120px"
});

export const getOperators = ({
  fieldType,
  isORMReport,
  useRelativeDates,
  customOperators
}: Pick<
  OperatorFieldProps,
  "fieldType" | "isORMReport" | "useRelativeDates" | "customOperators"
>) => {
  let newFieldType = fieldType;
  if (useRelativeDates && isDateField(newFieldType)) {
    newFieldType = FieldTypes.RELATIVE_DATE;
  }

  const operatorsToUse = customOperators || operatorsDetails;

  const operators = operatorsToUse.filter(
    operator =>
      !operator.disAllowedFields.includes(newFieldType) &&
      !(isORMReport !== true && operator.isForORMOnly)
  );
  return operators;
};
