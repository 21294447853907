import type { SLAOrderingCol } from "@certa/queries/types/workflow.types";
import { FieldTypes } from "@certa/types";
import type {
  StepGroupLevelAttribute,
  StepLevelAttributesExpanded
} from "../types";

type SLADetailsValue = {
  labelText: string;
  iconText: string;
  value: StepLevelAttributesExpanded;
  sort: boolean;
  sortCol: SLAOrderingCol | undefined;
  systemFieldType: FieldTypes;
  filter: boolean;
  path: string;
};

export const stepLevelAttributeDetails: Readonly<
  Record<StepLevelAttributesExpanded, SLADetailsValue>
> = {
  completed_by_id: {
    labelText: "Completed By (Name)",
    value: "completed_by_id",
    iconText: "user",
    sort: false,
    sortCol: undefined,
    // in workflow-reports/aggregate/ it is
    // returning name/email, due to that this is
    // set as CHAR.
    systemFieldType: FieldTypes.CHAR,
    filter: false,
    path: "steps.steps_tag.completedBy.id"
  },
  completed_by_name: {
    labelText: "Completed By (Name)",
    value: "completed_by_name",
    iconText: "user",
    sort: true,
    sortCol: "completed_by",
    systemFieldType: FieldTypes.CHAR,
    filter: false,
    path: "steps.steps_tag.completedBy"
  },
  completed_by_email: {
    labelText: "Completed By (Email)",
    iconText: "email",
    value: "completed_by_email",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.CHAR,
    filter: false,
    path: "steps.steps_tag.completedBy.email"
  },
  is_locked: {
    labelText: "Is locked",
    iconText: "lock",
    value: "is_locked",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.BOOL,
    filter: false,
    path: "steps.steps_tag.isLocked"
  },
  is_enabled: {
    labelText: "Is enabled",
    iconText: "bool",
    value: "is_enabled",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.BOOL,
    filter: false,
    path: "steps.steps_tag.isEnabled"
  },
  overdue: {
    labelText: "Overdue",
    iconText: "bool",
    value: "overdue",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.BOOL,
    filter: false,
    path: "steps.steps_tag.overdue"
  },
  initiated_at: {
    labelText: "Initiated At",
    iconText: "date",
    value: "initiated_at",
    sort: true,
    sortCol: "initiated_at",
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "steps.steps_tag.initiatedAt"
  },
  completed_at: {
    labelText: "Completed At",
    iconText: "date",
    value: "completed_at",
    sort: true,
    sortCol: "completed_at",
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "steps.steps_tag.completedAt"
  },
  updated_at: {
    labelText: "Updated At",
    iconText: "date",
    value: "updated_at",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "steps.steps_tag.updatedAt"
  },
  cycle_start: {
    labelText: "Cycle start",
    iconText: "clock",
    value: "cycle_start",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "steps.steps_tag.cycleStart"
  },
  cycle_end: {
    labelText: "Cycle end",
    iconText: "clock",
    value: "cycle_end",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "steps.steps_tag.cycleEnd"
  },
  ageing: {
    labelText: "Ageing",
    iconText: "clock",
    value: "ageing",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.DECIMAL,
    filter: false,
    path: "steps.steps_tag.ageing"
  },
  cycle_time: {
    labelText: "Cycle time",
    iconText: "clock",
    value: "cycle_time",
    sort: true,
    sortCol: "cycle_time",
    systemFieldType: FieldTypes.DECIMAL,
    filter: false,
    path: "steps.steps_tag.cycleTime"
  },
  submit_count: {
    labelText: "Submit count",
    iconText: "integer",
    value: "submit_count",
    sort: true,
    sortCol: "submit_count",
    systemFieldType: FieldTypes.INTEGER,
    filter: false,
    path: "steps.steps_tag.submitCount"
  }
};

type SGLADetailsValue = Omit<SLADetailsValue, "value"> & {
  value: StepGroupLevelAttribute;
};

export const stepGroupLevelAttributeDetails: Readonly<
  Record<StepGroupLevelAttribute, SGLADetailsValue>
> = {
  ageing: {
    labelText: "Ageing",
    iconText: "clock",
    value: "ageing",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.DECIMAL,
    filter: false,
    path: "stepGroups.steps_tag.ageing"
  },
  cycle_time: {
    labelText: "Cycle time",
    iconText: "clock",
    value: "cycle_time",
    sort: true,
    sortCol: "cycle_time",
    systemFieldType: FieldTypes.DECIMAL,
    filter: false,
    path: "stepGroups.steps_tag.cycleTime"
  },
  cycle_start: {
    labelText: "Cycle start",
    iconText: "clock",
    value: "cycle_start",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "stepGroups.steps_tag.cycleStart"
  },
  cycle_end: {
    labelText: "Cycle end",
    iconText: "clock",
    value: "cycle_end",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.NAIVE_DATE,
    filter: false,
    path: "stepGroups.steps_tag.cycleEnd"
  },
  tag: {
    labelText: "Tag",
    iconText: "char_field",
    value: "tag",
    sort: false,
    sortCol: undefined,
    systemFieldType: FieldTypes.CHAR,
    filter: false,
    path: "stepGroups.steps_tag.definition.name"
  }
};
