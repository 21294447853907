import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonVariants
} from "@certa/catalyst/components/Button";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";

import { Stack } from "@certa/catalyst/layouts/Stack";
import { css } from "emotion";
import { useIntl } from "react-intl";

export type ErrorFallbackProps = {
  title?: string;
  description?: string;
  actionText?: string;
  onRetry?: () => void;
};

const errorFallbackContainer = css({
  border: "1px solid var(--colors-red-300)",
  backgroundColor: "var(--colors-red-100)",
  width: "100%",
  minHeight: "12.5rem",
  borderRadius: "var(--space-8)"
});

export const ErrorFallback = ({
  title,
  description,
  actionText,
  onRetry
}: ErrorFallbackProps) => {
  const intl = useIntl();

  const defaultTitleText = intl.formatMessage({
    id: "errorBoundary.title",
    defaultMessage: "An error occurred"
  });

  const defaultDescriptionText = intl.formatMessage({
    id: "errorBoundary.description",
    defaultMessage: "We're working on a fix. Please try again"
  });

  const defaultDescriptionTextWithoutCallout = intl.formatMessage({
    id: "errorBoundary.descriptionWithoutCallout",
    defaultMessage: "We're working on a fix"
  });

  const tryAgainText = intl.formatMessage({
    id: "errorBoundary.tryAgain",
    defaultMessage: "Try Again"
  });

  const hasCallout = onRetry !== undefined;

  return (
    <Stack padding="4">
      <Stack
        align="center"
        justify="center"
        dangerouslySetClassName={errorFallbackContainer}
        padding="16"
      >
        <Stack align="center" justify="center" padding="0 0 8 0">
          <Typography
            variant={TypographyVariants.BODY_BOLD}
            color={TypographyColors.RED_600}
          >
            {title || defaultTitleText}
          </Typography>
        </Stack>
        <Stack align="center" justify="center" padding="0 0 16 0">
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.RED_600}
          >
            {description || hasCallout
              ? defaultDescriptionText
              : defaultDescriptionTextWithoutCallout}
          </Typography>
        </Stack>
        {hasCallout ? (
          <Stack align="center" justify="center">
            <Button
              size={ButtonSizes.SMALL}
              variant={ButtonVariants.LINK}
              color={ButtonColors.BRAND}
              onClick={onRetry}
            >
              {actionText || tryAgainText}
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
