import { useGetWorkflowDetails } from "@certa/queries/queries/workflowDetails/getWorkflowDetails.query";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import type { UseQueryExtraConfigType } from "@certa/common/types";
import {
  ToastPlacements,
  ToastTypes,
  showToast
} from "@certa/catalyst/components/Toast";

export const useProcessDetails = (
  processId: number | null | undefined,
  config: UseQueryExtraConfigType = {}
) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return useGetWorkflowDetails(processId, {
    ...config,
    onError: (error: unknown) => {
      if (error instanceof Response && error.status === 404) {
        showToast({
          title: intl.formatMessage(
            {
              id: "errorMessageInstances.ws002",
              defaultMessage: `Workflow with ID ${processId} is unavailable`
            },
            {
              workflowId: processId
            }
          ),
          description: intl.formatMessage(
            {
              id: "errorMessageInstances.errorCode",
              defaultMessage: "Error Code: WS002"
            },
            {
              // eslint-disable-next-line formatjs/enforce-placeholders
              errorCode: "WS002"
            }
          ),
          placement: ToastPlacements.BOTTOM_LEFT,
          type: ToastTypes.ERROR
        });

        // The above notification will render inside the design layout container
        // which will be unmounted when the user go back one route
        // hence adding a setTimeout of 2sec to
        // let the user see the notification
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
      config.onError?.(error);
    }
  });
};
