import {
  useFloating,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
  FloatingPortal,
  FloatingFocusManager,
  offset,
  flip,
  shift,
  autoUpdate
} from "@floating-ui/react";
import type { FloatingContext, Placement } from "@floating-ui/react";
import { useId } from "../../hooks/useId";
import React, { useState, createContext, useContext } from "react";
import styles from "./PopoverDialog.module.css";

type PopoverDialogContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  labelId: string;
  descriptionId: string;
  dialogId: string;
  getReferenceProps: (props?: any) => any;
  getFloatingProps: (props?: any) => any;
  refs: {
    setReference: (node: HTMLElement | null) => void;
    setFloating: (node: HTMLElement | null) => void;
  };
  floatingStyles: React.CSSProperties;
  context: FloatingContext;
} | null;

const PopoverDialogContext = createContext<PopoverDialogContextType>(null);

const usePopoverDialogContext = () => {
  const context = useContext(PopoverDialogContext);
  if (!context) {
    throw new Error(
      "PopoverDialog components must be used within a PopoverDialog.Root"
    );
  }
  return context;
};

type RootProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  placement?: Placement;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
};

const GAP = 4;

const Root = ({
  children,
  isOpen: controlledIsOpen,
  onOpenChange,
  placement = "bottom",
  closeOnEscape = true,
  closeOnOutsideClick = true
}: RootProps) => {
  const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);
  const isOpen = controlledIsOpen ?? isOpenUncontrolled;
  const setIsOpen = onOpenChange ?? setIsOpenUncontrolled;

  const dialogId = useId();
  const labelId = useId();
  const descriptionId = useId();

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    middleware: [
      shift({ crossAxis: false, padding: GAP }),
      flip({ padding: GAP, fallbackAxisSideDirection: "end" }),
      offset(GAP)
    ]
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePress: closeOnOutsideClick,
    escapeKey: closeOnEscape
  });
  const role = useRole(context, { role: "dialog" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role
  ]);

  const value = {
    isOpen,
    setIsOpen,
    labelId,
    descriptionId,
    dialogId,
    getReferenceProps,
    getFloatingProps,
    refs,
    floatingStyles,
    context
  };

  return (
    <PopoverDialogContext.Provider value={value}>
      {children}
    </PopoverDialogContext.Provider>
  );
};

type TriggerProps = {
  children: React.ReactElement;
  className?: string;
};

const Trigger = ({ children }: TriggerProps) => {
  const { getReferenceProps, refs, isOpen, dialogId } =
    usePopoverDialogContext();

  const referenceProps = getReferenceProps({
    "aria-expanded": isOpen,
    "aria-haspopup": "dialog",
    "aria-controls": isOpen ? dialogId : undefined
  });

  return React.cloneElement(children, {
    ref: refs.setReference,
    ...referenceProps
  });
};

type ContentProps = {
  children: React.ReactNode;
  label: string;
  description?: string;
  initialFocusRef?: React.RefObject<HTMLElement>;
  className?: string;
};

const Content = ({
  children,
  label,
  description,
  initialFocusRef,
  className
}: ContentProps) => {
  const {
    isOpen,
    labelId,
    descriptionId,
    dialogId,
    getFloatingProps,
    refs,
    floatingStyles,
    context
  } = usePopoverDialogContext();

  if (!isOpen) return null;

  const floatingProps = getFloatingProps({
    "aria-labelledby": labelId,
    "aria-describedby": description ? descriptionId : undefined,
    "aria-modal": true,
    role: "dialog",
    className: `${styles.popoverDialog} ${className || ""}`
  });

  return (
    <FloatingPortal>
      <FloatingFocusManager
        context={context}
        initialFocus={initialFocusRef}
        modal={true}
        order={["content"]}
        returnFocus={true}
      >
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...floatingProps}
          id={dialogId}
        >
          <div id={labelId} className="catalyst-screen-reader-only">
            {label}
          </div>
          {description && (
            <div id={descriptionId} className="catalyst-screen-reader-only">
              {description}
            </div>
          )}
          {children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};

export const PopoverDialog = {
  Root,
  Trigger,
  Content
};

export type {
  RootProps as PopoverDialogRootProps,
  TriggerProps as PopoverDialogTriggerProps,
  ContentProps as PopoverDialogContentProps
};
