import { forwardRef, useLayoutEffect, useRef } from "react";
import { TextInput } from "../Text";
import { mergeRefs } from "../../../utils/common";

export const FormattedNumberInput = forwardRef<HTMLInputElement, any>(
  (props, ref) => {
    const { onChange, onBlur, value, customInputOnBlur, ...restProps } = props;
    const numberInputRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
      // This is to make sure that the screen reader reads the currency field as a spin button.
      // Reason: The number with commas in text input are not read properly by the screen reader.
      if (numberInputRef.current) {
        numberInputRef.current.role = "spinbutton";
      }
    }, [value]);

    const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      customInputOnBlur?.();
      onBlur?.(evt);
    };

    return (
      <TextInput
        {...restProps}
        value={value}
        ref={mergeRefs([ref, numberInputRef])}
        // This onChange is from NumberFormat and it requires the evt to be passed
        // evt must contain the target and currentTarget.
        onChange={(_, evt) => onChange(evt)}
        onBlur={handleOnBlur}
      />
    );
  }
);

FormattedNumberInput.displayName = "FormattedNumberInput";
