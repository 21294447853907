import {
  getDashboardsGenericRoute,
  getTasksRoute,
  getHomeRoute,
  getStudioGenericRoute
} from "../../utils/routes";
import { PermissionTypes } from "@certa/types";
import { Tasks } from "@certa/icons/components/Tasks";
import { App } from "@certa/icons/components/App";
import { Chart } from "@certa/icons/components/Chart";
import { Bell } from "@certa/icons/components/Bell";
import { House } from "@certa/icons/components/House";
import { Search } from "@certa/icons/components/Search";
import type { LinkProps } from "../Header/types";
import { Database } from "@certa/icons/components/Database";
import { AssignedTaskCount } from "../Header/AssignedTaskCount";

// TODO: INTL

export const headerNavLinks: LinkProps[] = [
  {
    route: "/search",
    icon: Search,
    label: "Search",
    hideForSuppliers: false,
    id: "search"
  },
  {
    route: getHomeRoute(),
    icon: House,
    label: "Home",
    hideForSuppliers: false,
    id: "home"
  },
  {
    route: getDashboardsGenericRoute(),
    icon: Chart,
    label: "Dashboards",
    alias: [/\/dashboard\/\d+/],
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_DASHBOARD],
    id: "dashboards"
  },
  {
    route: getTasksRoute(),
    icon: Tasks,
    label: "Tasks",
    extra: AssignedTaskCount,
    hideForSuppliers: false,
    checkPermissions: [PermissionTypes.CAN_VIEW_TASKS],
    id: "tasks"
  },
  {
    // TODO: Ideally the route is not needed here
    // but removing it not showing selected state for third party nav items
    route: "/notifications",
    icon: Bell,
    label: "Notifications",
    hideForSuppliers: false,
    id: "notifications"
  },
  {
    route: "/records",
    icon: Database,
    label: "Records",
    hideForSuppliers: false,
    id: "records"
  }
];

export const studioNavLink: LinkProps = {
  route: getStudioGenericRoute(),
  icon: App,
  label: "Studio",
  checkPermissions: [PermissionTypes.CAN_ACCESS_STUDIO],
  hideForSuppliers: true,
  target: "_blank",
  id: "studio"
};
