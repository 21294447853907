//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const House = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="House"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3241)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.8245 6.504L13.464 4.40624V0.86694C13.464 0.708183 13.3442 0.57877 13.1989 0.57877L11.0985 0.578017C10.9525 0.578017 10.8334 0.708182 10.8334 0.866188V2.06853L8.90339 0.353814C8.37245 -0.117938 7.62691 -0.117938 7.096 0.353814L3.57107 3.48688L0.175674 6.504C0.017152 6.6447 -0.0403127 6.86891 0.028919 7.07809C0.0974506 7.288 0.271895 7.42117 0.476788 7.42117H1.87095V14.827C1.87095 15.4718 2.35622 16 2.94945 16H13.0499C13.6431 16 14.1284 15.4726 14.1284 14.827L14.129 7.42194H15.5232C15.7281 7.42194 15.9026 7.28802 15.9711 7.07886C16.0403 6.86895 15.9829 6.64548 15.8243 6.50402L15.8245 6.504Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3241">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
