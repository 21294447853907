//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Bot = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Bot"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1C7.44772 1 7 1.44772 7 2V3H4.5C3.67157 3 3 3.67157 3 4.5V11.5C3 12.3284 3.67157 13 4.5 13H11.5C12.3284 13 13 12.3284 13 11.5V4.5C13 3.67157 12.3284 3 11.5 3H9V2C9 1.44772 8.55229 1 8 1ZM11 7C11 7.55228 10.5523 8 10 8C9.44771 8 9 7.55228 9 7C9 6.44772 9.44771 6 10 6C10.5523 6 11 6.44772 11 7ZM6 8C6.55228 8 7 7.55228 7 7C7 6.44772 6.55228 6 6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8ZM6 10.5C6 10.2239 6.22386 10 6.5 10H9.5C9.77614 10 10 10.2239 10 10.5C10 10.7761 9.77614 11 9.5 11H6.5C6.22386 11 6 10.7761 6 10.5ZM14 6C14 5.44772 14.4477 5 15 5C15.5523 5 16 5.44772 16 6V10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10V6ZM1 5C0.447715 5 0 5.44772 0 6V10C0 10.5523 0.447715 11 1 11C1.55228 11 2 10.5523 2 10V6C2 5.44772 1.55228 5 1 5Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
