import type { HashMap } from "@certa/types";

export const sortByKey = (
  key: string,
  mentionA: HashMap,
  mentionB: HashMap
) => {
  const textA = mentionA[key].toUpperCase();
  const textB = mentionB[key].toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};
