//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Hidden = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Hidden"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3585)">
            <path
              d="M12.9746 4.68859L14.9419 2.66023C15.4213 2.1665 15.4213 1.36453 14.9419 0.870789C14.4626 0.376404 13.6863 0.376404 13.2069 0.870789L10.7013 3.45491C9.83858 3.1394 8.93363 2.96713 8.00057 2.96713C4.81211 2.96713 1.95197 4.92622 0 8.0291C0.85183 9.38353 1.8771 10.5194 3.02659 11.3702L1.05987 13.3986C0.580513 13.893 0.580513 14.6936 1.05987 15.188C1.53859 15.6824 2.31555 15.6824 2.79492 15.188L5.30117 12.6032C6.16324 12.9187 7.06751 13.091 8.00074 13.091C11.1892 13.091 14.0493 11.1319 16 8.02905C15.1488 6.67462 14.1242 5.53938 12.9747 4.68843L12.9746 4.68859ZM4.93368 8.02854C4.93368 6.28405 6.30902 4.86483 8.00057 4.86483C8.3948 4.86483 8.76984 4.94866 9.11609 5.08925L8.09721 6.14007C8.06456 6.13875 8.03384 6.13017 8.0012 6.13017C6.98681 6.13017 6.16179 6.98165 6.16179 8.02775C6.16179 8.06141 6.17011 8.09309 6.17139 8.12676L5.15187 9.17757C5.01491 8.82048 4.93364 8.43502 4.93364 8.02841L4.93368 8.02854ZM8.00057 11.1929C7.60634 11.1929 7.23258 11.1091 6.88634 10.9685L7.90522 9.91769C7.93722 9.91967 7.96794 9.92759 8.00058 9.92759C9.01433 9.92759 9.84049 9.07611 9.84049 8.02933C9.84049 7.99567 9.83217 7.96465 9.83089 7.93165L10.8498 6.88017C10.9854 7.23726 11.0674 7.62273 11.0674 8.02933C11.0674 9.77315 9.69074 11.193 8.00046 11.193L8.00057 11.1929Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3585">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
