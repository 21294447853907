import { useAppSelector } from "@certa/common/hooks";
import { Godaam } from "@certa/common/utils/Godaam";
import { useGodaamSyncState } from "@certa/common/hooks/useGodaamSyncState";
import type { SearchItem } from "../GlobalSearchNext.schema";
import { RecentSearchesSchema } from "../GlobalSearchNext.schema";

const STORAGE_KEY = "recentSearchesNext";
const MAX_SEARCHES_TO_STORE = 15;

/**
 * Custom hook for managing recent searches with type safety and data validation
 * @returns {Object} Object containing recent searches and methods to update them
 */
export const useRecentSearches = (): {
  recentSearches: SearchItem[];
  setRecentSearches: (item: SearchItem) => void;
} => {
  const [recentSearches, setRecentSearches] = useGodaamSyncState<
    Record<string, SearchItem[]> | undefined
  >(STORAGE_KEY);

  const authentication = useAppSelector(state => state?.authentication);
  const userId = authentication.user.id;

  /**
   * Updates recent searches for the current user
   * @param newSearch - Search item to add
   */
  const setUserRecentSearches = (newSearch: SearchItem) => {
    if (!userId) return;

    const existingSearches = recentSearches?.[userId] || [];
    // Remove the item if it exists (to reorder)
    const filteredSearches = existingSearches.filter(
      item => !(item.type === newSearch.type && item.id === newSearch.id)
    );
    const uniqueSearches = [newSearch, ...filteredSearches];

    const updatedRecentSearches = {
      ...recentSearches,
      [userId]: uniqueSearches.slice(0, MAX_SEARCHES_TO_STORE)
    };

    /**
     * The following code make sure to store the updated value
     * in the local storage synchronously.
     * Whereas the `setState`(setRecentSearches) and `useEffect`(useGodaamSyncState)
     * do it asynchronously. This is the default React's behavior.
     * Asynchronous behavior is a problem in this case because we are
     * routing the user to the search page on on the click which
     * unmounts the current component thereby discarding the async tasks.
     */
    Godaam[STORAGE_KEY] = JSON.stringify(updatedRecentSearches);
    setRecentSearches(updatedRecentSearches);
  };

  // Get user's recent searches with validation
  const getUserRecentSearches = (): SearchItem[] => {
    if (!userId || !recentSearches?.[userId]) return [];

    const validatedSearches = RecentSearchesSchema.safeParse(recentSearches);
    if (!validatedSearches.success) {
      const updatedSearches = { ...recentSearches };
      delete updatedSearches[userId];
      setRecentSearches(updatedSearches);
      return [];
    }

    return validatedSearches.data[userId] || [];
  };

  return {
    recentSearches: getUserRecentSearches(),
    setRecentSearches: setUserRecentSearches
  };
};
