import type { Spacing } from "./types";

// Regular expression pattern to match the padding values
const SPACING_PATTERN = /(\d+)/g;

/**
 * Replaces the spacing values with equivalent CSS variables
 * @param spacing The spacing values
 * @returns The transformed spacing values
 * @example
 * getTransformedSpacingWithCSSVariables("4 8 12 16") => "var(--space-4) var(--space-8) var(--space-12) var(--space-16)"
 */
const getTransformedSpacingWithCSSVariables = (spacing?: Spacing) => {
  // Replace the padding values with equivalent CSS variables
  const transformedSpacing = spacing?.replace(
    SPACING_PATTERN,
    function (match) {
      return "var(--space-" + match + ")";
    }
  );

  return transformedSpacing;
};

export const getTransformedPaddingWithCSSVariables =
  getTransformedSpacingWithCSSVariables;

export const getTransformedMarginWithCSSVariables =
  getTransformedSpacingWithCSSVariables;
