import { classNames } from "../../../utils/common";
import styles from "./NavbarLogo.module.css";

type NavbarLogoBaseProps = {
  /**
   * The path to be used for navigation when the logo is clicked
   */
  path: string;
};

type NavbarLogoImageProps = {
  /**
   * The image source of the logo
   * @default ""
   */
  imageSrc?: string;
  /**
   * The alt text for the logo
   * @default ""
   */
  imageAlt?: string;
} & { imageSrc: string; imageAlt: string };

type NavbarLogoProps = NavbarLogoBaseProps & NavbarLogoImageProps;

type NavbarItemInternalProps = {
  expanded: boolean;
  isActive: boolean;
};

export const NavbarLogo = (props: NavbarLogoProps) => {
  const { path, imageSrc, imageAlt, ...internalProps } = props;
  // Typecasting here to use the internal props because it is added only in Navbar component
  const { expanded: isExpanded } = internalProps as NavbarItemInternalProps;

  const handleClick = () => {
    window.location.href = path;
  };

  return (
    <li
      className={classNames({
        [styles.catalystNavbarLogo]: true,
        [styles.catalystNavbarLogoCollapsed]: !isExpanded
      })}
      aria-label="brand logo"
    >
      <button onClick={handleClick}>
        <img src={imageSrc} alt={imageAlt} />
      </button>
    </li>
  );
};
