import type { Kind } from "@certa/types";

export const getKindIdFromTag = ({
  kinds,
  kindTag
}: {
  kinds: Kind[];
  kindTag: string | undefined;
}) => {
  // Preserves type of sent element (For Jsx File Usages)
  if (!kindTag) return kindTag;

  return String(kinds.find(kindObj => kindObj.tag === kindTag)?.id ?? "");
};
