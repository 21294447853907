import { useQuery } from "react-query";
import { commentsServices } from "@certa/queries/services/comments.services";
/**
 * Fetches all the satatic data required by the app (blocking / non blocking)
 * Later should be divided into categories
 * 1. Blocking calls,
 * 2. Non blocking calls
 * there should be separate status, error for both the categories
 * @returns {status} status - Combined status of all the fetch calls
 * @returns {error} error - Combined error of all the fetch calls
 */
const useLoadStaticData = () => {
  // TODO: Now this already exist in certa queries so we should use that
  const { status, error } = useQuery(
    "commentFlagOptions",
    commentsServices.getCommentFlags,
    {
      refetchOnWindowFocus: false
    }
  );

  return [status, error];
};

export { useLoadStaticData };
