import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent
} from "../../../components/Dropdown";
import { NavbarDropdownMenuTrigger } from "./NavbarDropdownMenuTrigger";

export type NavbarDropdownMenuProps = {
  id: string;
  children: React.ReactElement | React.ReactElement[] | React.ReactFragment;
  onClick?: () => void;
  dropdownContentWidth?: string;
};

type NavbarDropdownMenuInternalProps = {
  expanded: boolean;
};

export const NavbarDropdownMenu = (props: NavbarDropdownMenuProps) => {
  const {
    id,
    children,
    onClick,
    dropdownContentWidth = "12.5rem",
    ...internalProps
  } = props;
  const { expanded: isExpanded } =
    internalProps as NavbarDropdownMenuInternalProps;
  const [isActive, setIsActive] = useState(false);

  // Filter out null or undefined children
  const validChildren = React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  );

  let dropdownTriggerChildren: React.ReactElement | undefined;

  const dropdownContentChildren: React.ReactElement[] = [];

  React.Children.forEach(validChildren, (child: any) => {
    const clonedChild = React.cloneElement(child, {
      expanded: isExpanded,
      isActive
    });
    if (child.type === NavbarDropdownMenuTrigger) {
      dropdownTriggerChildren = clonedChild;
    } else {
      dropdownContentChildren.push(clonedChild);
    }
  });

  const handleOnClick = (open: boolean) => {
    setIsActive(open);
    onClick?.();
  };

  if (!dropdownTriggerChildren) {
    return null;
  }

  return (
    <li>
      <DropdownMenu onOpenChange={handleOnClick}>
        {dropdownTriggerChildren}
        <DropdownMenuContent
          sideOffset={-24}
          alignOffset={32}
          side="right"
          align="end"
          width={dropdownContentWidth}
        >
          <>{dropdownContentChildren}</>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};
