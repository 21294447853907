import { css } from "emotion";

export const overviewListRowStyles = {
  row: css({
    minHeight: "2.625rem",
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      borderBottom: "1px solid var(--colors-neutral-300)"
    }
  }),
  label: css({
    maxWidth: "45%",
    overflow: "hidden"
  }),
  overflowHidden: css({
    overflow: "hidden"
  }),
  gridItem: css({
    overflow: "hidden",
    display: "flex",
    alignItems: "center"
  }),
  valueContainer: css({
    maxWidth: "55%",
    overflow: "hidden"
  }),
  value: css({
    maxWidth: "100%",
    overflow: "hidden"
  })
};
