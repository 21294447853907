import { Stack } from "@certa/catalyst/layouts/Stack";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { css } from "emotion";
import { extractAttachmentName } from "../../comments.utils";
import { Download } from "@certa/icons/components/Download";

type AttachmentViewProps = {
  attachment: string;
  isPostedByCurrentUser: boolean;
};

const AttachmentView = ({
  attachment,
  isPostedByCurrentUser
}: AttachmentViewProps) => {
  if (!attachment) return null;
  return (
    <Stack
      direction="horizontal"
      justify={isPostedByCurrentUser ? "flex-end" : "flex-start"}
      dangerouslySetClassName={css({
        margin: "var(--space-4) 0"
      })}
    >
      <a
        href={attachment}
        className={css({
          textDecoration: "none !important",
          color: "unset !important"
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Stack
          direction="horizontal"
          align="center"
          gap="4"
          dangerouslySetClassName={css({
            padding: "4px 6px",
            backgroundColor: "var(--neutral-20)",
            borderRadius: "var(--small-border-radius)"
          })}
        >
          <Download size={12} />
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
          >
            {extractAttachmentName(attachment)}
          </Typography>
        </Stack>
      </a>
    </Stack>
  );
};

export { AttachmentView };
