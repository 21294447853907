//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ArrowRightLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ArrowRightLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.8422 7.52321L11.4627 3.27125C11.1827 2.94688 10.7348 2.91561 10.4406 3.16573C10.1464 3.41585 10.0974 3.91998 10.3388 4.22482L12.7451 7.24971H1.72417C1.32438 7.24971 1 7.58582 1 8.00006C1 8.4143 1.32438 8.7504 1.72417 8.7504H12.7451L10.3388 11.7753C10.0974 12.0801 10.1521 12.5774 10.4406 12.8344C10.7386 13.0992 11.2223 13.0337 11.4627 12.7289L14.8422 8.47691C15.0619 8.15646 15.0431 7.83306 14.8422 7.52334V7.52321Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
