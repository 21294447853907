import React from "react";
import { useIntl } from "react-intl";
import { Stack, Text } from "@certa/blocks/thanos";
import { Info } from "@certa/icons/components/Info";
import { useWhiteLabelled } from "@certa/common/hooks/useWhiteLabelled";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";

const CommentCareLine = () => {
  const intl = useIntl();
  const SUPPORT_EMAIL = "care@getcerta.com";
  const whiteLabelled = useWhiteLabelled();
  const { isMobileResolution } = useScreenResolution();

  return !isMobileResolution && !whiteLabelled ? (
    <Stack>
      <Info color="teal" />
      <Text variant="p1-regular" color="neutral-70">
        {intl.formatMessage(
          {
            id: "comments.certaSupportTag",
            defaultMessage: "Mail {supportEmail} for support"
          },
          {
            supportEmail: SUPPORT_EMAIL
          }
        )}
      </Text>
    </Stack>
  ) : null;
};

export { CommentCareLine };
