//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Input = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Input"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_4006)">
            <path
              d="M3.20003 11.2H1.60002V4.79994H3.20003C3.64098 4.79994 4.00002 4.44088 4.00002 3.99995C4.00002 3.559 3.64096 3.19995 3.20003 3.19995H0.799995C0.359046 3.19995 0 3.55901 0 3.99995V11.9999C0 12.4408 0.359062 12.7999 0.799995 12.7999H3.20003C3.64098 12.7999 4.00002 12.4408 4.00002 11.9999C4.00002 11.5589 3.64096 11.1999 3.20003 11.1999V11.2Z"
              fill="currentColor"
            />
            <path
              d="M15.2 3.19992H8.00007C7.55912 3.19992 7.20007 3.55898 7.20007 3.99992C7.20007 4.44087 7.55913 4.79991 8.00007 4.79991H14.4002V11.2H8.00007C7.55912 11.2 7.20007 11.5591 7.20007 12C7.20007 12.4409 7.55913 12.8 8.00007 12.8H15.2C15.641 12.8 16 12.4409 16 12V4.00004C16 3.5591 15.6409 3.20005 15.2 3.20005V3.19992Z"
              fill="currentColor"
            />
            <path
              d="M8.00011 0.799995C8.00011 0.359046 7.64105 0 7.20011 0H4.00007C3.55912 0 3.20007 0.359062 3.20007 0.799995C3.20007 1.24094 3.55914 1.59999 4.00007 1.59999H4.80006V14.4H4.00007C3.55912 14.4 3.20007 14.7591 3.20007 15.2C3.20007 15.641 3.55914 16 4.00007 16H7.20011C7.64106 16 8.00011 15.6409 8.00011 15.2C8.00011 14.7591 7.64105 14.4 7.20011 14.4H6.40012V1.59999H7.20011C7.64106 1.59999 8.00011 1.24093 8.00011 0.799995Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_4006">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
