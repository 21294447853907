//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Text = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Text"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3598)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.93103 2.65517H6.62069V13.1379H8.82759V14.7931H2.75862V13.1379H4.96552V2.65517H1.65517V4.31034H0V1.82759C0 1.3707 0.370703 1 0.827586 1H10.7586C11.2155 1 11.5862 1.3707 11.5862 1.82759V4.31034H9.93103V2.65517ZM13.2414 10.3794V12.5335C13.2414 12.8676 13.5119 13.138 13.8459 13.138H16V14.7932H13.8459C12.5981 14.7932 11.5862 13.7814 11.5862 12.5335V10.3794H9.37931V8.72425H11.5862V6.51736H13.2414V8.72425H15.7241V10.3794H13.2414Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3598">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
