//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronUpLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronUpLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.52574 11.1856C3.17665 11.5337 2.61015 11.5337 2.26107 11.1856C1.91298 10.8365 1.91298 10.27 2.26107 9.92094L7.36766 4.81435C7.71675 4.46626 8.28325 4.46626 8.63234 4.81435L13.7389 9.92094C14.087 10.27 14.087 10.8365 13.7389 11.1856C13.3898 11.5337 12.8233 11.5337 12.4743 11.1856L7.99807 6.70841L3.52574 11.1856Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
