import { CatalystColors } from "@certa/catalyst/constants/styles";
import type { ChartData } from "@certa/common/types/chartConfig.types";
import type { CSSProperties } from "react";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { createUniqueXAxis, getOrderedData } from "../../utils";
import { UNIQUE_X_AXIS_IDENTIFIER } from "../../constants";

type UseChartReorderProps = {
  data: Record<string, ChartData>[];
  initialOrder: string[];
  onReorder?: (newOrder: string[]) => void;
  orientation: "vertical" | "horizontal";
};

/*
  This hook is used to reorder the data in the chart.
  It is used in the BarChart component.
*/
export const useChartReorder = ({
  data,
  initialOrder,
  onReorder,
  orientation = "vertical"
}: UseChartReorderProps) => {
  const chartRef = useRef<any>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const newItemRef = useRef<string | null>(null);
  const activeItemRef = useRef<string | null>(null);

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [dropTargetItem, setDropTargetItem] = useState<string | null>(null);

  const [dataOrder, setDataOrder] = useState<string[]>([]);

  useEffect(() => {
    const uniqueXAxis = createUniqueXAxis(data)?.map(
      item => item[UNIQUE_X_AXIS_IDENTIFIER]
    );
    setDataOrder(getOrderedData(initialOrder, uniqueXAxis));
  }, [data, initialOrder]);

  const { orderedData, orderedDataWithUniqueXAxis } = useMemo(() => {
    const dataWithUniqueXAxis = createUniqueXAxis(data);
    const uniqueXAxisMapping = dataWithUniqueXAxis.reduce<
      Record<string, Record<string, ChartData>>
    >((acc, item) => {
      acc[item[UNIQUE_X_AXIS_IDENTIFIER]] = item;
      return acc;
    }, {});
    const orderedDataWithUniqueXAxis: Record<string, ChartData>[] = [];
    dataOrder.forEach(key => {
      if (uniqueXAxisMapping[key]) {
        orderedDataWithUniqueXAxis.push(uniqueXAxisMapping[key]);
      }
    });

    const orderedData = orderedDataWithUniqueXAxis.map(item => {
      const { [UNIQUE_X_AXIS_IDENTIFIER]: _, ...rest } = item;
      return rest;
    });
    return { orderedData, orderedDataWithUniqueXAxis };
  }, [data, dataOrder]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!chartRef.current || !chartContainerRef.current) return;

      isDraggingRef.current = true;

      const { width, height, left, top } =
        chartRef.current?.state?.offset ?? {};
      const chartRect = chartContainerRef.current.getBoundingClientRect();

      const barsCount = dataOrder.length;

      if (orientation === "vertical") {
        const barWidth = width / barsCount;
        const mouseX = e.clientX - left - chartRect.left;
        const newIndex = Math.floor(mouseX / barWidth);
        const boundedIndex = Math.max(0, Math.min(newIndex, barsCount - 1));
        newItemRef.current = dataOrder[boundedIndex];
      } else {
        const barHeight = height / dataOrder.length;
        const mouseY = e.clientY - top - chartRect.top;
        const newIndex = Math.floor(mouseY / barHeight);
        const boundedIndex = Math.max(0, Math.min(newIndex, barsCount - 1));
        newItemRef.current = dataOrder[boundedIndex];
      }

      setDropTargetItem(newItemRef.current);
    },
    [orientation, dataOrder]
  );

  const handleMouseUp = useCallback(() => {
    if (
      onReorder &&
      isDraggingRef.current &&
      activeItemRef.current !== newItemRef.current
    ) {
      setDataOrder(prevOrder => {
        if (
          !activeItemRef.current ||
          !newItemRef.current ||
          activeItemRef.current === newItemRef.current
        )
          return prevOrder;

        const newOrder = [...prevOrder];
        const activeItemIndex = newOrder.findIndex(
          item => item === activeItemRef.current
        );
        const newItemIndex = newOrder.findIndex(
          item => item === newItemRef.current
        );
        newOrder[activeItemIndex] = newItemRef.current;
        newOrder[newItemIndex] = activeItemRef.current;

        onReorder(newOrder);
        return newOrder;
      });
    }

    // Remove no-select class from the container
    if (chartContainerRef.current) {
      chartContainerRef.current.style.userSelect = "";
    }

    // Reset states
    isDraggingRef.current = false;
    activeItemRef.current = null;
    newItemRef.current = null;
    setActiveItem(null);
    setDropTargetItem(null);

    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }, [onReorder, handleMouseMove]);

  const handleMouseDown = useCallback(
    (uniqueXAxisIdentifier: string) => {
      if (!onReorder) return;

      setActiveItem(uniqueXAxisIdentifier);
      setDropTargetItem(uniqueXAxisIdentifier);

      isDraggingRef.current = true;
      activeItemRef.current = uniqueXAxisIdentifier;
      newItemRef.current = uniqueXAxisIdentifier;

      // Add no-select class to the container
      if (chartContainerRef.current) {
        chartContainerRef.current.style.userSelect = "none";
      }

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [onReorder, handleMouseMove, handleMouseUp]
  );

  const getCellColor = useCallback(
    (uniqueXAxisIdentifier: string): CSSProperties => {
      const baseStyle: CSSProperties = {
        transition: "fill 0.2s ease, opacity 0.2s ease",
        userSelect: "none"
      };
      const isActive = uniqueXAxisIdentifier === activeItem;
      const isDropTarget = uniqueXAxisIdentifier === dropTargetItem;

      if (isActive)
        return {
          ...baseStyle,
          opacity: 1,
          fill: CatalystColors.GREEN_300
        };
      if (isDropTarget)
        return {
          ...baseStyle,
          opacity: 0.7,
          transform:
            orientation === "vertical" ? "scaleY(1.01)" : "scaleX(1.01)",
          fill: CatalystColors.GREEN_500
        };

      return baseStyle;
    },
    [activeItem, dropTargetItem, orientation]
  );

  const getCellCursor = useCallback(() => {
    if (onReorder) {
      return isDraggingRef.current ? "grabbing" : "grab";
    }
    return "";
  }, [onReorder]);

  return {
    chartRef,
    chartContainerRef,
    isDraggingRef,
    orderedData,
    orderedDataWithUniqueXAxis,
    handleMouseDown,
    getCellColor,
    getCellCursor
  };
};
