import type { VALUE_LABEL_ANGLE_OPTIONS } from "../constants";
import type {
  ChartConfigED,
  ChartConfigIC,
  ChartConfigORM,
  FieldTypes,
  GroupByORM
} from "@certa/types";

export type GeoMapTheme =
  | "streets"
  | "outdoors"
  | "light"
  | "dark"
  | "satellite"
  | "satellite_streets"
  | "navigation_day"
  | "navigation_night";
export type ChartIdentifiers =
  | "LINE_CHART"
  | "BAR_CHART"
  | "PIE_CHART"
  | "SPIDER_CHART"
  | "HEAT_MAP_CHART"
  | "SCATTER_CHART"
  | "GEO_MAP_CHART";

export type GroupByDateOperation =
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export type MetricDataTypes =
  | "date"
  | "datetime"
  | "float"
  | "cycle-time"
  | "integer"
  | "decimal";

export type ChartData = string | number | null;

export type AggregateModelledResponse = {
  xAxisKey: string;
  data: Record<string, ChartData>[];
  probableDataType: MetricDataTypes;
};

export type GroupByDateLabels = "" | GroupByDateOperation;

export type ShowLegend = "none" | "top" | "bottom";
export type ChartOrientation = "horizontal" | "vertical";

export type ShowValue = "always" | "hover" | "click";

// this data comes from graph and they don't have proper typings
// its partly also because we are making generic graphs
// hence the data passed to the graph does not have
// strict typings.
// So its better to keep it any and test for
// every possible types
export type AxisFormatterResult = (data: any, index: number) => any;

export type RGBColorString = `rgba(${number}, ${number}, ${number}, ${number})`;

export type ActiveColorType = "singleTone" | "spectrum" | "custom";

export type SingleToneColorType = Record<string, RGBColorString>;
export type SpectrumColorType = Record<string, RGBColorString[]>;
export type CustomColorType = Record<string, SingleToneColorType>;

export type OtherChartConfigColors = {
  activeColorType: ActiveColorType;
  singleTone?: SingleToneColorType;
  spectrum?: SpectrumColorType;
  custom?: CustomColorType;
};

export type ActiveXIdentifier = number | string;

export type ReportQueryParams = {
  xAxisFilters: Record<string, string[]>;
  activeXIdentifier?: ActiveXIdentifier;
};

export type ChartClickEventPayload = {
  [axisLabel: string]: ChartData;
};

export type ChartClickEvent = {
  activeLabel?: string;
  activePayload?: {
    payload: ChartClickEventPayload;
  }[];
  activeXIdentifier: ActiveXIdentifier | undefined;
  activeTooltipIndex?: number;
};

export type ChartSorting = {
  label: string;
  tag?: string;
  order: "ASC" | "DESC";
  isDisabled: boolean;
};

export type ChartComponentProps = {
  isAnimationActive?: boolean;
  chartData: AggregateModelledResponse;
  height?: number;
  onClick?: (e: ChartClickEvent) => void;
  xActiveIdentifier?: ActiveXIdentifier;
  otherConfig: OtherChartConfig;
  isCycleTimeReport: boolean;
  ref?: React.ForwardedRef<any>;
  xAxisDataLabels: string[];
  sorting?: ChartSorting[];
  handleSorterChange?: (sorter: ChartSorting) => void;
  groupByORM?: GroupByORM[];
  yAxisKey?: string;
  onReorder?: (newOrder: string[]) => void;
};

export type OtherChartConfig = {
  shouldShowValueLabels?: boolean;
  showValueOn?: ShowValue;
  shouldShowPercentagesInValueLabels?: boolean;
  showLegend?: ShowLegend;
  chartOrientation?: ChartOrientation;
  xAxis?: XAxisOtherChartConfig;
  secondXAxisLabels?: string[];
  yAxis?: YAxisOtherChartConfig;
  showXAxisLabel?: ShowAxisLabel;
  showYAxisLabel?: ShowAxisLabel;
  xAxisLabel?: string;
  yAxisLabel?: string;
  colors?: OtherChartConfigColors;
  valueLabelAngle?: ValueLabelAngle;
  percentageThreshold?: number;
  cycleTimePath?: string;
  isStackedBarChart?: boolean;
  isGroupedBarChart?: boolean;
  scale?: number;
  precision?: number;
  geoMapTheme?: GeoMapTheme;
  dataOrder: string[];
  shouldDisplayValueAsPercentage?: boolean;
  percentageCalculationType?: "row" | "column" | "table";
};

export type XAxisOtherChartConfig = {
  fieldTypes?: FieldTypes[];
  dataTypes?: XAxisDataTypes[];
  labelOutputTypes?: GroupByDateLabels[];
  // Only old reports have this key
  labelOutputType?: GroupByDateLabels;
};

export type YAxisOtherChartConfig = {
  outputDataType?: MetricDataTypes[];
};

export type ShowAxisLabel = "none" | "auto" | "manual";

export type ValueLabelAngle =
  (typeof VALUE_LABEL_ANGLE_OPTIONS)[keyof typeof VALUE_LABEL_ANGLE_OPTIONS];

export type XAxisDataTypes =
  | Exclude<MetricDataTypes, "cycle-time">
  | "char"
  | "text"
  | "relative_date"
  | "bool";

export type YAxisChart = (MetricAttributes | string)[];

export type ChartConfig = {
  "x-axis": FormattedProcess[];
  "y-axis": YAxisChart;
  isCycleTimeReport: boolean;
  otherConfig?: OtherChartConfig;
};

export type FormattedProcess = GroupByAttributes & {
  path?: string[];
  stepgroups?: string[];
  steps?: string[];
};

export type GroupByAttributes =
  | StepGroupByAttributes
  | FieldGroupByAttributes
  | ProcessGroupByAttributes;

type StepGroupByAttributes = {
  col_type: "step";
  col: SLAGroupBy;
  label: string;
  step_tag: string;
  operation?: GroupByDateOperation;
  data_type?: XAxisDataTypes;
};

export type ProcessGroupByAttributes = {
  col_type: "attr";
  col: ProcessColTypes;
  label: string;
  operation?: GroupByDateOperation;
  data_type?: XAxisDataTypes;
};

type FieldGroupByAttributes = {
  col_type: "field";
  col: string;
  label: string;
  operation?: GroupByDateOperation;
  data_type?: XAxisDataTypes;
};

export type ProcessColTypes =
  | "status_id"
  | "business_unit_id"
  | "region_id"
  | "created_by_id"
  | "created_by_email"
  | "created_at"
  | "updated_at";

export type StepLevelAttributes =
  | "ageing"
  | "initiated_at"
  | "completed_at"
  | "completed_by"
  | "updated_at"
  | "is_locked"
  | "is_enabled"
  | "cycle_start"
  | "cycle_end"
  | "cycle_time"
  | "submit_count"
  | "overdue";

export type StepGroupLevelAttribute =
  | "ageing"
  | "cycle_start"
  | "cycle_end"
  | "cycle_time"
  | "tag";

export type StepLevelAttributesExpanded =
  | Exclude<StepLevelAttributes, "completed_by">
  | "completed_by_name"
  | "completed_by_email"
  | "completed_by_id";

export type SLAGroupBy = Exclude<
  StepLevelAttributesExpanded,
  "cycle_start" | "cycle_end" | "cycle_time" | "submit_count" | "overdue"
>;

export enum Metric {
  "AVG" = "avg",
  "SUM" = "sum",
  "MAX" = "max",
  "MIN" = "min",
  "COUNT" = "count",
  "ALL" = "all"
}

export type SLAMetrics = Exclude<
  StepLevelAttributes,
  "completed_by" | "is_locked" | "is_enabled" | "overdue"
>;
export type SGLAMetrics = Exclude<StepGroupLevelAttribute, "tag">;

export type SLAFormattedMetric = {
  col_type: "step";
  col: SLAMetrics;
  step_tag: string;
  label: string;
  data_type: Exclude<MetricDataTypes, "cycle-time">;
};

export type SGLAFormattedMetric = {
  col_type: "step_group";
  col: SGLAMetrics;
  step_group_tag: string;
  label: string;
  data_type: Exclude<MetricDataTypes, "cycle-time">;
};

export type MetricAttributes =
  | ProcessMetricAttributes
  | FieldMetricAttributes
  | SLAMetricAttributes
  | SGLAMetricAttributes;

export type ProcessMetricAttributes = {
  mtr_type: Metric;
  col_type: "attr";
  col: "created_at" | "last_activity" | "id" | "cycle_time";
  label: string;
  data_type: MetricDataTypes;
  path: string[];
  iconType?: string;
};

export type FieldMetricAttributes = {
  mtr_type: Metric;
  col_type: "field";
  col: string;
  label: string;
  data_type: MetricDataTypes;
  path: string[];
};

type SLAMetricAttributes = SLAFormattedMetric & {
  mtr_type: Metric;
  path: string[];
};

type SGLAMetricAttributes = SGLAFormattedMetric & {
  mtr_type: Metric;
  path: string[];
};

export type ParsedChartFieldAnswer = {
  chartType: ChartIdentifiers;
  chartConfig?: ChartConfig;
  chartConfigORM?: ChartConfigORM;
  chartConfigED?: ChartConfigED;
  chartConfigIC?: ChartConfigIC;
};
