import { useMemo } from "react";
import { matchPath, useLocation } from "react-router";

export const useFlexibleMatch = (path: string) => {
  const location = useLocation();

  const match = useMemo(
    () =>
      matchPath(
        {
          path,
          end: false,
          caseSensitive: false
        },
        location.pathname
      ),
    [path, location.pathname]
  );

  return match;
};
