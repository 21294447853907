import { keyBy } from "lodash-es";
import { queryClient } from "@certa/queries/utils/utils";
import type { KindStatus } from "../types/common.types";
import type {
  ReportWorkflowResultsORM,
  ReportWorkflowsExtraDataResponse,
  ReportWorkflowsORM,
  ReportWorkflowsResponseORM
} from "../types/workflow.types";
import { getStatusesList } from "../services/common.services";
import type {
  WorkflowPlus,
  WorkflowReportORMDynamicAPIResponse,
  WorkflowReportORMDynamicDataFieldData,
  WorkflowReportORMDynamicDataRecord
} from "../types/dynamicAPI.types";
import type {
  AttributesPayloadORM,
  StatusDetails,
  StatusLabelDetails
} from "@certa/types";

import {
  createProcessValueTag,
  destructureCustomAttributeTag
} from "@certa/common/utils/report";
import {
  createCustomTag,
  type AggregateResponse
} from "@certa/common/modelCreators";

const DATA_POINT_INDEX = 1;

export const ormReportWorkflowsModelCreator = async (
  response: ReportWorkflowsResponseORM | AggregateResponse,
  ormColumns: AttributesPayloadORM[]
): Promise<ReportWorkflowsORM> => {
  const availableStatusesKeyedByTag = await getStatusesKeyedByTag();

  let dataPointsArray: ReportWorkflowResultsORM = [];
  let count = 0;
  let extraData: ReportWorkflowsExtraDataResponse = {
    data: {
      workflows: [],
      "+workflows": []
    }
  };
  if (Array.isArray(response)) {
    dataPointsArray = response.slice(DATA_POINT_INDEX);
  } else if (Array.isArray(response?.results)) {
    dataPointsArray = response.results.slice(DATA_POINT_INDEX);
    if (response?.extra_dynamic_data) {
      extraData = response.extra_dynamic_data;
    }
    count = response?.count;
  }

  const customFieldTags = ormColumns.map(
    column =>
      column?.extra_json?.custom_tag ??
      createCustomTag({
        kindId: column.kind_id,
        tag: column.tag,
        type: column.type,
        value: column.value
      })
  );

  const workflowFamily = workflowFamilyModelCreator(extraData?.data);
  return {
    count,
    results: dataPointsArray.map(dataPoints => {
      const rowRecord: ReportWorkflowResultsORM[number] = {};
      if (!Array.isArray(dataPoints)) {
        return rowRecord;
      }
      customFieldTags.forEach((customFieldTag: string, index: number) => {
        const { fieldTag } = destructureCustomAttributeTag(customFieldTag);
        if (
          fieldTag === createProcessValueTag("id") &&
          !ormColumns[index].join_relation
        ) {
          rowRecord.id = dataPoints?.[index];
          rowRecord.parents = rowRecord.id ? workflowFamily[rowRecord.id] : [];
          rowRecord[customFieldTag] = dataPoints?.[index];
        } else if (fieldTag === createProcessValueTag("status_label")) {
          const statusLabelDetails: StatusLabelDetails = {
            displayName: dataPoints?.[index] ?? "",
            statusTagDataPath: customFieldTag.replace(
              "status_label",
              "status_tag"
            )
          };
          rowRecord[customFieldTag] = statusLabelDetails;
        } else if (fieldTag === createProcessValueTag("status_id")) {
          const statusLabelDetails: StatusLabelDetails = {
            displayName: dataPoints?.[index] ?? "",
            statusTagDataPath: customFieldTag.replace("status_id", "status_tag")
          };
          rowRecord[customFieldTag] = statusLabelDetails;
        } else if (fieldTag === createProcessValueTag("status_tag")) {
          const { colorCode, id, displayName, tag } =
            availableStatusesKeyedByTag[dataPoints?.[index]] ?? {};
          const statusDetails: StatusDetails = {
            displayName,
            colorCode,
            id,
            tag,
            workflowIDDataPath: customFieldTag.replace("status_tag", "id")
          };
          rowRecord[customFieldTag] = statusDetails;
        } else {
          rowRecord[customFieldTag] = dataPoints[index];
        }
      });
      return rowRecord;
    })
  };
};

const getStatusesKeyedByTag = async () => {
  let availableStatusesKeyedByTag: Record<string, KindStatus> | undefined =
    queryClient.getQueryData("availableStatusesKeyedByTag");
  if (!availableStatusesKeyedByTag) {
    const statusData = await getStatusesList();
    availableStatusesKeyedByTag = keyBy(statusData, "tag");
  }
  return availableStatusesKeyedByTag;
};

export const ormDynamicAPIDataModelCreator = (
  results: WorkflowReportORMDynamicAPIResponse | undefined
): WorkflowReportORMDynamicDataRecord => {
  const {
    workflows = [],
    alert_mappings: alertMappings = [],
    alerts = [],
    alert_categories: alertCategories = [],
    fields = [],
    field_defs: fieldDefs = []
  } = results ?? {};

  const alertMappingsMap = keyBy(alertMappings, "id");
  const alertsMap = keyBy(alerts, "id");
  const alertCategoriesMap = keyBy(alertCategories, "id");

  const rowRecord: WorkflowReportORMDynamicDataRecord = {};

  workflows.forEach(workflow => {
    rowRecord[workflow.id] = {
      alerts: (workflow.alert_mappings ?? []).map(alertId => {
        const alertMapping = alertMappingsMap[alertId];
        const alert = alertsMap[alertMapping.alert];
        const alertCategory = alertCategoriesMap[alert.category];
        return {
          id: alert.id,
          fieldId: alertMapping.field_id,
          stepId: alertMapping.step_id,
          stepGroupId: alertMapping.step_group_id,
          workflowId: alertMapping.workflow_id,
          catColorLabel: alertCategory.color_label,
          alertTag: alert.tag
        };
      }),
      lcData: workflow.formatted_lc_data,
      logo: workflow.logo,
      myTasksCount: workflow.my_tasks_count,
      progress: workflow.progress,
      fields: workflow.fields?.reduce<
        Record<string, WorkflowReportORMDynamicDataFieldData>
      >((record, fieldId) => {
        const field = fields.find(
          responseField => responseField.id === fieldId
        );
        const fieldDef = fieldDefs.find(
          fieldDef => fieldDef.id === field?.definition
        );
        if (field?.answers?.[0]?.id && fieldDef?.tag) {
          record[fieldDef.tag] = {
            isEncrypted: fieldDef.is_encrypted,
            responseId: field.answers[0].id,
            tag: fieldDef.tag
          };
        }
        return record;
      }, {})
    };
  });
  return rowRecord;
};

const PARENT_INDEX = 0;
const workflowFamilyModelCreator = (
  data: ReportWorkflowsExtraDataResponse["data"]
) => {
  const workflowFamilyMapping: Record<number, WorkflowPlus[]> = {};

  data.workflows.forEach(({ id, parents }) => {
    if (parents.length) {
      const parentsData = getParents(
        parents[PARENT_INDEX],
        data?.["+workflows"]
      );
      workflowFamilyMapping[id] = parentsData;
    } else {
      workflowFamilyMapping[id] = [];
    }
  });

  return workflowFamilyMapping;
};

const getParents = (
  workflowId: number,
  workflowPlus: WorkflowPlus[] = []
): WorkflowPlus[] => {
  const parent = workflowPlus.find(workflow => workflow.id === workflowId);
  if (parent?.parents?.length) {
    return [...getParents(parent.parents[PARENT_INDEX], workflowPlus), parent];
  } else if (parent?.id) return [parent];
  else {
    return [];
  }
};
