import React from "react";
import type { ErrorInfo } from "react";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "./ErrorFallback";
import type { ErrorFallbackProps } from "./ErrorFallback";

export type ErrorBoundaryProps = {
  title?: string;
  description?: string;
  actionText?: string;
  Fallback?: React.ComponentType<ErrorFallbackProps>;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  onReset?: () => void;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

// Utility function to process Response and extract error
export async function extractErrorDetails(error: unknown) {
  if (error instanceof Response) {
    try {
      const errorData = await error.json();
      return new Error(
        JSON.stringify({
          status: error.status,
          statusText: error.statusText,
          details: errorData
        })
      );
    } catch {
      return new Error(`HTTP ${error.status} ${error.statusText}`);
    }
  }
  return error;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;

    if (onError) {
      try {
        onError(error, errorInfo);
      } catch (ignoredError) {
        // Ignore the error
      }
    }

    // Extract error details and send to Sentry
    extractErrorDetails(error).then(processedError => {
      Sentry.withScope(scope => {
        // Mark this error was logged by an error boundary
        scope.setTag("errorBoundary", "true");
        Sentry.captureException(processedError, {
          extra: { ...errorInfo, originalError: error.message }
        });
      });
    });

    this.setState({ hasError: true });
  }

  onRetry = () => {
    // By default we mount the component again,
    // which will reset its internal state and possibly
    // allowing user to try again
    this.setState({ hasError: false });
    this.props.onReset && this.props.onReset();
  };

  render() {
    const { title, description, actionText, Fallback } = this.props;
    if (this.state.hasError) {
      const fallbackProps = {
        title,
        description,
        actionText,
        onRetry: this.onRetry
      };

      let errorContent = <ErrorFallback {...fallbackProps} />;
      if (Fallback) {
        errorContent = <Fallback {...fallbackProps} />;
      }

      return (
        <section tabIndex={-1} role="alert" aria-live="assertive">
          {errorContent}
        </section>
      );
    }

    return this.props.children;
  }
}
