import { TextInput } from "@certa/catalyst/components/Inputs";
import { useIntl } from "react-intl";
import type { InputFieldProps } from "./ValueFieldInput.types";

export const TextInputField = ({
  value,
  disabled,
  onChange
}: InputFieldProps) => {
  const intl = useIntl();

  const placeholderText = intl.formatMessage({
    id: "common.typeAValue",
    defaultMessage: "Type a value..."
  });

  return (
    <TextInput
      label=""
      disabled={disabled}
      placeholder={placeholderText}
      value={Array.isArray(value) ? undefined : value}
      onChange={onChange}
      width="100%"
    />
  );
};
