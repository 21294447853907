import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef
} from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { Dimmer } from "../Dimmer";
import { CATALYST_Z_INDEXES } from "../../constants/styles";
import styles from "./Dialog.module.css";

type BaseDialogProps = {
  show: boolean;
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  minWidth?: string;
  maxWidth?: string;
  onClose: () => void;
  shouldDisableDialogClose?: boolean;
  "aria-label": string;
};

/**
 * TODO: [High Priority] Refactor needed
 * This component needs significant refactoring before being used by other teams:
 * - Extract list logic into a reusable headless component
 *
 * Ticket: https://thevetted.atlassian.net/browse/PLAT-28497
 * @internal Use with caution until refactoring is complete
 */
export const BaseDialog = (props: BaseDialogProps) => {
  const {
    show: shouldShow = false,
    children,
    width = "400px",
    minWidth,
    maxWidth,
    height = "fit-content",
    maxHeight,
    onClose,
    shouldDisableDialogClose = false,
    "aria-label": ariaLabel = "Dialog"
  } = props;

  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const rootElement = document.getElementById("catalyst-dialogs-wrapper");
  const dialogTriggerElementRef = useRef<HTMLElement | null>();

  useLayoutEffect(() => {
    if (rootElement) {
      rootElement.setAttribute("role", "presentation");
      rootElement.className = styles.catalystDialogWrapper;
      rootElement.style.zIndex = CATALYST_Z_INDEXES.DIALOG;
    }
  }, [rootElement, shouldShow]);

  const returnFocusToTriggeredElement = useCallback(() => {
    requestAnimationFrame(() => {
      dialogTriggerElementRef.current?.focus();
      dialogTriggerElementRef.current = null;
    });
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    returnFocusToTriggeredElement();
  }, [onClose, returnFocusToTriggeredElement]);

  useEffect(() => {
    if (shouldShow) {
      dialogTriggerElementRef.current = document.activeElement as HTMLElement;
    } else {
      returnFocusToTriggeredElement();
    }
    setShouldShowDialog(shouldShow);
  }, [returnFocusToTriggeredElement, shouldShow]);

  useEffect(() => {
    const onEscapeKeyHandler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape" && !shouldDisableDialogClose) {
        handleClose();
      }
    };

    if (shouldShow) {
      window.addEventListener("keydown", onEscapeKeyHandler);
    } else {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    }

    return () => {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    };
  }, [handleClose, shouldShow, shouldDisableDialogClose]);

  return rootElement && shouldShowDialog
    ? createPortal(
        <>
          <Dimmer onClick={handleClose} scrollLock />
          <FocusLock disabled={!shouldShow}>
            <div
              role="dialog"
              aria-modal={true}
              aria-label={ariaLabel}
              className={styles.catalystDialog}
              style={{ height, width, maxHeight, minWidth, maxWidth }}
            >
              {children}
            </div>
          </FocusLock>
        </>,
        rootElement
      )
    : null;
};
