import type {
  ColumnORM,
  FilterORM,
  GroupByORM,
  OperationORM,
  OrderByORM,
  OtherChartConfigurations,
  AttributesPayloadORM,
  FilterPayloadORM,
  GroupByPayloadORM,
  OperationArgumentPayloadORM,
  OperationPayloadORM,
  OrderByPayloadORM,
  OtherChartConfigurationsPayloadORM
} from "@certa/types";

export const attributesPayloadCreatorORM = (
  columns?: ColumnORM[]
): AttributesPayloadORM[] | undefined => {
  if (!columns) {
    return undefined;
  }
  return columns.map(column => ({
    join_relation: column.joinRelation,
    kind_id: column.kindId,
    label: column.label,
    tag: column.tag,
    type: column.type,
    value: column.value,
    extra_json: {
      custom_tag: column.extraJSON?.customTag,
      added_by: column.extraJSON?.addedBy,
      field_type: column.extraJSON?.fieldType,
      is_hidden: column.extraJSON?.isHidden
    }
  }));
};

export const operationsPayloadCreatorORM = (
  operations?: OperationORM[]
): OperationPayloadORM[] | undefined => {
  if (!operations) {
    return undefined;
  }
  return operations.map(operation => ({
    arguments: operationArgumentPayloadCreatorORM(operation.arguments),
    column_type: operation.columnType,
    data_type: operation.dataType,
    extra_args: {
      separator: operation.extraArgs?.separator,
      compare_value: operation.extraArgs?.compareValue
    },
    function: operation.function,
    kind_id: operation.kindId,
    label: operation.label,
    join_relation: operation.joinRelation,
    tag: operation.tag,
    extra_json: {
      axis_name: operation.extraJSON?.axisName,
      output_data_type: operation.extraJSON?.outputDataType,
      custom_tag: operation.extraJSON?.customTag
    }
  }));
};

const operationArgumentPayloadCreatorORM = (
  argumentsData: OperationORM["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentPayloadORM = {
      arguments: operationArgumentPayloadCreatorORM(argument.arguments),
      column_type: argument.columnType,
      label: argument.label,
      data_type: argument.dataType,
      function: argument.function,
      extra_args: {
        separator: argument.extraArgs?.separator,
        compare_value: argument.extraArgs?.compareValue
      }
    };
    return newArgument;
  });
};

export const groupByPayloadCreatorORM = (
  groupBy?: GroupByORM[]
): GroupByPayloadORM[] | undefined => {
  if (!groupBy) {
    return undefined;
  }
  return groupBy.map(groupBy => ({
    join_relation: groupBy.joinRelation,
    kind_id: groupBy.kindId,
    operations: operationsPayloadCreatorORM(groupBy?.operations),
    source: groupBy.source,
    tag: groupBy.tag,
    type: groupBy.type,
    extra_json: {
      data_sources: groupBy.extraJSON?.dataSources,
      data_type: groupBy.extraJSON?.dataType,
      field_type: groupBy.extraJSON?.fieldType,
      is_for_secondary_x_axis: groupBy.extraJSON?.isForSecondaryXAxis,
      label: groupBy.extraJSON?.label,
      label_output_type: groupBy.extraJSON?.labelOutputType,
      operation_type: groupBy.extraJSON?.operationType
    }
  }));
};

export const orderByPayloadCreatorORM = (
  orderBys?: OrderByORM[]
): OrderByPayloadORM[] | undefined => {
  if (!orderBys) {
    return undefined;
  }

  return orderBys.map(orderBy => ({
    data_type: orderBy.dataType,
    join_relation: orderBy.joinRelation,
    kind_id: orderBy.kindId,
    operations: operationsPayloadCreatorORM(orderBy.operations),
    ordering: orderBy.ordering,
    source: orderBy.source,
    tag: orderBy.tag,
    type: orderBy.type
  }));
};

const otherConfigurationsPayloadCreatorORM = (
  config?: OtherChartConfigurations
): OtherChartConfigurationsPayloadORM | undefined => {
  if (!config) {
    return undefined;
  }

  return {
    colors: {
      active_color_type: config.colors.activeColorType,
      single_tone: config.colors.singleTone,
      spectrum: config.colors.spectrum,
      custom: config.colors.custom
    },
    cycle_time_tag: config.cycleTimeTag,
    geo_map_theme: config.geoMapTheme,
    is_cycle_time_report: config.isCycleTimeReport,
    is_stacked_bar_chart: config.isStackedBarChart,
    percentage_threshold: config.percentageThreshold,
    precision: config.precision,
    scale: config.scale,
    show_legend: config.showLegend,
    chart_orientation: config.chartOrientation,
    show_percentages_in_value_labels: config.shouldShowPercentagesInValueLabels,
    show_value_labels: config.shouldShowValueLabels,
    show_value_on: config.showValueOn,
    show_x_axis_label: config.showXAxisLabel,
    show_y_axis_label: config.showYAxisLabel,
    value_label_angle: config.valueLabelAngle,
    x_axis_label: config.xAxisLabel,
    y_axis_label: config.yAxisLabel,
    is_grouped_bar_chart: config.isGroupedBarChart,
    data_order: config.dataOrder,
    display_value_as_percentage: config.shouldDisplayValueAsPercentage,
    percentage_calculation_type: config.percentageCalculationType
  };
};

export const filterPayloadCreatorORM = (
  filter: FilterORM
): FilterPayloadORM => {
  return {
    type: filter.type,
    conditions: filter.conditions.map(condition => {
      if ("type" in condition) {
        return filterPayloadCreatorORM(condition);
      }
      return {
        data_type: condition.dataType,
        kind_id: condition.kindId,
        join_relation: condition.joinRelation,
        lhs_source: condition.lhsSource,
        lhs_type: condition.lhsType,
        op: condition.operation,
        rhs_value: condition.rhsValue,
        tag: condition.tag,
        extra_json: {
          field_type: condition.extraJSON?.fieldType,
          added_by: condition.extraJSON?.addedBy
        }
      };
    })
  };
};

type Params = {
  attributes?: ColumnORM[];
  groupBy: GroupByORM[];
  operations: OperationORM[];
  orderBy?: OrderByORM[];
  otherConfigurations?: OtherChartConfigurations;
};

export const chartConfigPayloadCreatorORM = (data: Params) => {
  return {
    attributes: attributesPayloadCreatorORM(data.attributes),
    operations: operationsPayloadCreatorORM(data.operations),
    group_by: groupByPayloadCreatorORM(data.groupBy),
    order_by: orderByPayloadCreatorORM(data.orderBy),
    other_configurations: otherConfigurationsPayloadCreatorORM(
      data.otherConfigurations
    )
  };
};
