import { Tooltip } from "@certa/catalyst/components/Tooltip";
import { useIntl } from "react-intl";

type WrapWithMigrationTooltipProps = {
  show: boolean;
  children: React.ReactElement;
};

export const WrapWithMigrationTooltip = (
  props: WrapWithMigrationTooltipProps
) => {
  const { children, show: shouldShow } = props;
  const intl = useIntl();

  const tooltipContent = intl.formatMessage({
    id: "commonTextInstance.migrationFeatureNotAvailable",
    defaultMessage:
      "This feature is temporarily unavailable as we are currently migrating our workflows. Please check back in sometime"
  });

  return shouldShow ? (
    <Tooltip content={tooltipContent}>{children}</Tooltip>
  ) : (
    <>{children}</>
  );
};
