import { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import type { HeatMapProps } from "../../types";
import { CatalystColors } from "@certa/catalyst/constants/styles";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DEFAULT_HEIGHT, Y_AXIS_GROUP_LABEL } from "../../constants";
import { useHeatMapConfig } from "../../hooks/useHeatMapConfig";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import type { RectEntry, ViewRect, DragEndEvent } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { isStatsColumn, STATS_COLUMNS } from "../../utils/cellUtils";
import {
  reorderColumns,
  getAllColumnIds,
  validateColumnOrder
} from "../../utils/columnOrderUtils";

// Components
import { Heading } from "../Heading";
import { HeatMapTable } from "./HeatMapTable";

export const HeatMap = (props: HeatMapProps) => {
  const {
    yAxisKeys,
    xAxisKeys,
    height = DEFAULT_HEIGHT,
    dataCellFormatter,
    cellBaseColors,
    shouldShowColumnTypeName = false,
    shouldShowRowTypeName = false,
    columnTypeHeading = "",
    rowTypeHeading = "",
    onColumnOrderChange
  } = props;

  const {
    columns,
    dataSource,
    minValue,
    minMaxDiff,
    columnSubTotalAndAvg,
    dataColumns
  } = useHeatMapConfig(props);

  const defaultOrder = useMemo(() => getAllColumnIds(columns), [columns]);

  const yAxisKeysWithGroupLabel = useMemo(
    () => [Y_AXIS_GROUP_LABEL, ...yAxisKeys],
    [yAxisKeys]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>([]);

  const table = useReactTable({
    data: dataSource ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    state: {
      columnOrder,
      columnPinning: { left: yAxisKeysWithGroupLabel, right: STATS_COLUMNS }
    },
    filterFns: { custom: () => true }
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  /*
    Filter out columns that are not in the yAxisKeysWithGroupLabel or stats columns as they are not draggable
    @return - Closest center of the filtered entries
  */
  const customCollisionDetection = (...args: [RectEntry[], ViewRect]) => {
    const [entries, target] = args;
    const filteredEntries = entries.filter(
      entry =>
        !yAxisKeysWithGroupLabel.includes(entry[0]) && !isStatsColumn(entry[0])
    );
    return closestCenter(filteredEntries, target);
  };

  const handleColumnOrderChange = (newOrder: string[] | undefined) => {
    if (!newOrder) {
      return;
    }

    onColumnOrderChange?.(newOrder);
    setColumnOrder(newOrder);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!onColumnOrderChange) {
      return;
    }
    const { active, over } = event;
    if (!active || !over || active.id === over.id) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    const newOrder = reorderColumns(
      columnOrder,
      activeId,
      overId,
      yAxisKeysWithGroupLabel,
      xAxisKeys.length,
      dataColumns
    );

    handleColumnOrderChange(newOrder);
  };

  const yAxisKeyString = yAxisKeys.join(",");
  const xAxisKeyString = xAxisKeys.join(",");

  useEffect(() => {
    const newOrder = validateColumnOrder(
      props.columnOrder,
      defaultOrder,
      yAxisKeysWithGroupLabel
    );
    handleColumnOrderChange(newOrder);
    // We only want to run this effect when the number of yAxisKeys or xAxisKeys changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yAxisKeyString, xAxisKeyString]);

  return (
    <Col>
      <Heading
        show={shouldShowColumnTypeName}
        title={columnTypeHeading}
        titleFor="column"
      />
      <Row>
        <RowLabel>
          <Heading
            show={shouldShowRowTypeName}
            title={rowTypeHeading}
            titleFor="row"
          />
        </RowLabel>
        <TableContainer maxHeight={height}>
          <DndContext
            sensors={sensors}
            collisionDetection={customCollisionDetection}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={handleDragEnd}
          >
            <HeatMapTable
              table={table}
              yAxisKeys={yAxisKeys}
              xAxisKeys={xAxisKeys}
              cellBaseColors={cellBaseColors}
              minValue={minValue}
              minMaxDiff={minMaxDiff}
              dataCellFormatter={dataCellFormatter}
              columnSubTotalAndAvg={columnSubTotalAndAvg}
              dataColumns={dataColumns}
              columnOrder={columnOrder}
              onColumnOrderChange={onColumnOrderChange}
            />
          </DndContext>
        </TableContainer>
      </Row>
    </Col>
  );
};

const TableContainer = styled.div<{ maxHeight?: number }>`
  overflow-x: auto;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "none")};
  width: 100%;
  border: 1px solid ${CatalystColors.NEUTRAL_400};
  border-radius: 8px;
`;

const Col = styled.div`
 {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: 16px;
}
`;

const Row = styled.div`
 {
  width: 100%;
  display: flex;
  align-items: center;
  > * + * {
    margin-inline-start: 16px;
}
`;

const RowLabel = styled.div`
  writing-mode: vertical-lr;
  rotate: 180deg;
`;
