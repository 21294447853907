import { colord } from "colord";
import { SUB_TOTAL_LABEL, AVERAGE_LABEL } from "../constants";
import type { CellData, DataCellFormatter } from "../types";

export const STATS_COLUMNS = [SUB_TOTAL_LABEL, AVERAGE_LABEL];

export const getBackgroundColor = (
  ratio: number | undefined,
  showInherit: boolean,
  getBackgroundColor?: string[]
) => {
  if (showInherit || ratio === undefined) return "inherit";
  else if (Array.isArray(getBackgroundColor)) {
    if (getBackgroundColor?.length === 1) {
      const opacity = colord(getBackgroundColor[0]).alpha();
      return colord(getBackgroundColor[0])
        .alpha(opacity * (ratio / 2 + 0.4))
        .toRgbString();
    } else if (getBackgroundColor.length === 2) {
      const color1 = colord(getBackgroundColor[0]).toRgb();
      const color2 = colord(getBackgroundColor[1]).toRgb();
      return `rgba(${Math.abs(
        color2.r * ratio - color1.r * (1 - ratio)
      )}, ${Math.abs(color2.g * ratio - color1.g * (1 - ratio))},  ${Math.abs(
        color2.b * ratio - color1.b * (1 - ratio)
      )},  ${Math.abs(color2.a * ratio - color1.a * (1 - ratio))})`;
    }
  }
  return `rgb(12, 160, 44, 1)`;
};

export const isStatsColumn = (columnKey: string): boolean => {
  return STATS_COLUMNS.includes(columnKey);
};

export const formatCellValue = (
  value: CellData,
  columnKey: string,
  rowData: Record<string, CellData>,
  formatter?: DataCellFormatter
): CellData => {
  if (!formatter || value === undefined) {
    return value;
  }
  return formatter(value, columnKey, rowData);
};

export const calculateRatio = (
  value: CellData | undefined,
  minValue: number,
  minMaxDiff: number
): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return (Number(value) - minValue) / minMaxDiff;
};
