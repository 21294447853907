import React, {
  useState,
  useMemo,
  useEffect,
  memo,
  useRef,
  useCallback
} from "react";
import type {
  PropsValue,
  ActionMeta,
  SelectInstance,
  SingleValue,
  MultiValue,
  GroupBase,
  InputActionMeta
} from "react-select";
import ReactSelect from "react-select";
import {
  MenuListComponent,
  OptionComponent,
  ControlComponent,
  ClearIndicatorComponent,
  ValueContainerComponent,
  GroupComponent,
  MenuComponent,
  LoadingMessageComponent,
  IndicatorsContainerComponent,
  NoOptionsMessageComponent
} from "./Components";
import type { SelectOption, SelectProps } from "./GlobalSelect.types";
import { globalSelectStyles } from "./GlobalSelect.styles";
import { useSelectStyles } from "./useSelectStyles";
import { useKeyDownHandler } from "./useKeyDownHandler";

/**
 * The GlobalSelect component was forked from @catalyst/select as part of
 * PLAT-17767, with following reasoning:
 *
 * 1. The new Global Search has a lot of custom functionality, beyond just
 *    design changes, which would have required significant customization
 *    of @catalyst/select component, even though such customizations wouldn't
 *    be required anywhere else
 * 2. @catalyst/select already had a lot of edge cases and a11y handled, with
 *    tried and tested functionality. Rebuilding from scratch would have required
 *    rework of doing same handling again, with more risk of new bugs being
 *    introduced
 * 3. Given Global Select had already been customised multiple times before
 *    the fork gives the freedom deliver design team requirements without the
 *    risk of messing up @catalyst/select component
 */

const SelectComponent = (
  props: SelectProps,
  forwardRef: React.Ref<HTMLElement>
) => {
  const {
    id,
    label,
    "aria-describedby": ariaDescribedBy,
    defaultValue,
    noOptionsMessage,
    options = [],
    placeholder = "Select...",
    onChange,
    onBlur,
    onSearch,
    maxMenuHeight = 320,
    leftIcon,
    width = "20rem",
    minMenuWidth,
    isLoading = false,
    menuHeader: MenuHeader = null,
    menuFooter: MenuFooter = null,
    handleReturnKey,
    handleOnScroll,
    defaultInputValue,
    headerVisible: isHeaderVisible
  } = props;

  // Save input value instate to prevent React-Select from
  // forcefully clearing it out
  // https://github.com/JedWatson/react-select/issues/588#issuecomment-815133270
  const [inputValue, setInputValue] = useState(defaultInputValue);

  const selectRef =
    useRef<SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>>();

  const [selectedOption, setSelectedOption] =
    useState<PropsValue<SelectOption>>();

  const hasGroupedOptions = useMemo(
    () =>
      !!options.find(option => (option as GroupBase<SelectOption>)?.options),
    [options]
  );

  const currentOptions = useMemo(
    () =>
      hasGroupedOptions
        ? (options as GroupBase<SelectOption>[]).reduce(
            (acc: SelectOption[], group: any) => {
              if (!group.options) return [...acc, { ...group }];
              return [...acc, ...group.options];
            },
            []
          )
        : (options as SelectOption[]),
    [hasGroupedOptions, options]
  );
  const [filteredOptions, setFilteredOptions] =
    useState<SelectOption[]>(currentOptions);

  useEffect(() => {
    // Only update selected option when loading is complete
    if (!isLoading) {
      setSelectedOption(null);
      setFilteredOptions(currentOptions);
    }
  }, [currentOptions, isLoading]);

  useEffect(() => {
    // Only run this effect when selectedOption is undefined
    // This is to prevent the effect from running when the component is re-rendered
    // and to set the defaultValue only once
    if (!selectedOption && defaultValue) {
      const defaultSelectedOption = currentOptions.find(option => {
        return option.value && defaultValue === option.value;
      });
      setSelectedOption(defaultSelectedOption as PropsValue<SelectOption>);
    }
  }, [defaultValue, currentOptions, selectedOption]);

  const {
    currentFocusIndex,
    setCurrentFocusIndex,
    hasUserStartedSelectingOption,
    handleKeyDown
  } = useKeyDownHandler({
    selectRef,
    filteredOptions,
    handleReturnKey,
    menuIsOpen: true
  });

  const stylesConfig = useSelectStyles({
    width,
    minMenuWidth,
    noOptionsMessage,
    hasUserStartedSelectingOption,
    selectRef,
    headerVisible: isHeaderVisible
  });

  const handleOnChange = (
    newValue: SingleValue<SelectOption> | MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    let tranformedValue: string = "";
    if (newValue) {
      tranformedValue = (newValue as SelectOption).value;
    }

    // Only call onChange if the value has changed for single select
    if (
      (newValue as SingleValue<SelectOption>)?.value !==
      (selectedOption as SingleValue<SelectOption>)?.value
    ) {
      onChange?.(
        tranformedValue as any,
        actionMeta,
        newValue as SingleValue<SelectOption>
      );
    }

    setSelectedOption(prevState => {
      if (
        prevState &&
        (prevState as SingleValue<SelectOption>)?.value ===
          (newValue as SelectOption)?.value
      ) {
        return prevState;
      }
      return newValue;
    });
  };

  const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    setCurrentFocusIndex(-1);
    onBlur?.(evt);
  };

  const handleInputChange = useCallback(
    (input: string, actionMeta: InputActionMeta) => {
      if (
        actionMeta.action !== "input-blur" &&
        actionMeta.action !== "menu-close"
      ) {
        setInputValue(input);
        onSearch(input);
      }
    },
    [onSearch]
  );

  const handleFilterOption = (option: SelectOption, inputValue: string) => {
    return true;
  };

  const MemoizedMenuList = useCallback(
    (props: any) => (
      <MenuListComponent
        {...props}
        filteredOptions={filteredOptions}
        onScroll={handleOnScroll}
        menuWidth={
          selectRef.current?.controlRef?.getBoundingClientRect().width as number
        }
      />
    ),
    [filteredOptions, handleOnScroll]
  );

  const MemoizedMenuComponent = useCallback(
    (props: any) => {
      const menuHeader = MenuHeader ? (
        <MenuHeader currentFocusTabIndex={currentFocusIndex} />
      ) : null;
      const menuFooter = MenuFooter ? (
        <MenuFooter currentFocusTabIndex={currentFocusIndex} />
      ) : null;

      return (
        <MenuComponent
          {...props}
          menuHeader={menuHeader}
          menuFooter={menuFooter}
        />
      );
    },
    [MenuFooter, MenuHeader, currentFocusIndex]
  );

  const MemoizedOptionComponent = useCallback(
    (props: any) => <OptionComponent {...props} />,
    []
  );

  const MemoizedControlComponent = useCallback(
    (props: any) => <ControlComponent {...props} leftIcon={leftIcon} />,
    [leftIcon]
  );

  const MemoizedIndicatorsContainerComponent = useCallback(
    (props: any) => (
      <IndicatorsContainerComponent
        {...props}
        inputValue={inputValue}
        onClear={() => {
          setInputValue("");
          onSearch("");
        }}
      />
    ),
    [inputValue, onSearch]
  );

  return (
    <ReactSelect
      aria-label={label}
      aria-labelledby={!!id ? id + "-label" : undefined}
      value={null}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      options={options}
      hideSelectedOptions={false}
      unstyled
      classNamePrefix="global-select"
      className={globalSelectStyles}
      noOptionsMessage={() => noOptionsMessage}
      aria-invalid={false}
      aria-errormessage={undefined}
      aria-describedby={ariaDescribedBy}
      components={{
        DropdownIndicator: null,
        IndicatorsContainer: MemoizedIndicatorsContainerComponent,
        Option: MemoizedOptionComponent,
        ClearIndicator: ClearIndicatorComponent,
        ValueContainer: ValueContainerComponent,
        Group: GroupComponent,
        Menu: MemoizedMenuComponent,
        MenuList: MemoizedMenuList,
        Control: MemoizedControlComponent,
        LoadingMessage: LoadingMessageComponent,
        NoOptionsMessage: NoOptionsMessageComponent
      }}
      styles={stylesConfig}
      placeholder={placeholder}
      maxMenuHeight={maxMenuHeight}
      filterOption={handleFilterOption}
      menuPosition="fixed"
      isLoading={isLoading}
      ref={ref => {
        if (ref) {
          selectRef.current = ref;
        }
      }}
      menuShouldScrollIntoView
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
      menuShouldBlockScroll={false}
      inputValue={inputValue}
      menuIsOpen={true}
    />
  );
};

const SelectComponentWithRef = React.forwardRef(SelectComponent) as (
  props: SelectProps & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof SelectComponent>;

// Exports for the Select component
export const GlobalSelect = memo(
  SelectComponentWithRef
) as typeof SelectComponent;

export * from "./GlobalSelect.types";
