//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Search = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Search"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3227)">
            <path
              d="M11.7836 10.1465C12.9844 8.339 13.2128 6.04486 12.393 4.03178C11.5727 2.01869 9.81262 0.553672 7.70175 0.12634C5.59089 -0.300274 3.40837 0.368345 1.88627 1.90781C0.364171 3.44727 -0.296908 5.65477 0.124934 7.78976C0.547371 9.92475 1.9959 11.7049 3.98624 12.5347C5.97657 13.3638 8.24478 13.1328 10.0319 11.9182L13.7365 15.6639C14.2247 16.1237 14.9851 16.11 15.4566 15.6332C15.928 15.1564 15.9416 14.3873 15.4869 13.8935L11.7836 10.1465ZM6.43897 10.5203C5.38836 10.5203 4.38028 10.0983 3.63731 9.34614C2.89369 8.59468 2.47642 7.57519 2.47642 6.51246C2.47642 5.44972 2.89369 4.43024 3.63731 3.67877C4.38028 2.92666 5.38824 2.50462 6.43897 2.50462C7.48969 2.50462 8.49766 2.92666 9.24063 3.67877C9.98424 4.43024 10.4015 5.44972 10.4015 6.51246C10.4015 7.57519 9.98424 8.59468 9.24063 9.34614C8.49766 10.0983 7.48969 10.5203 6.43897 10.5203Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3227">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
