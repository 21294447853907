import { useEffect, useState } from "react";
import { useRouteError } from "react-router";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/react";
import { Button, ButtonVariants } from "@certa/catalyst/components/Button";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { MehOutlined } from "@ant-design/icons";
import { router } from "../routes";
import { stringConstants } from "main/src/constants/stringConstants";

export const RouteErrorBoundary = () => {
  const error = useRouteError();
  const intl = useIntl();

  const [eventId, setEventId] = useState<string | null>(null);

  const oopsMsg = intl.formatMessage({
    id: "commonTextInstances.oops",
    defaultMessage: "Oops!"
  });
  const errorBoundaryMsg = intl.formatMessage({
    id: "commonTextInstances.errorBoundaryMsg",
    defaultMessage:
      "Sorry! It looks like something isn’t working. Our engineering team has been notified. Thanks for your patience as they work through to get this running back up again. In the meantime please consider submitting feedback on the the actions that led you here."
  });

  const handleGoHome = () => {
    router.navigate("/", { replace: true });
    window.location.reload();
  };

  const handleSubmitFeedback = () => {
    Sentry.showReportDialog({
      eventId: eventId || "unknown",
      title: "Feedback",
      subtitle: "Please share the details about actions that led you here.",
      subtitle2:
        "Your feedback is extremely important to us, as it will help us improve the product and serve you better."
    });
  };

  useEffect(() => {
    const eventId = Sentry.captureException(error);
    setEventId(eventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <StyledPadding />
      <StyledBody>
        <StyledCard>
          <StyledHeader>
            <MehOutlined style={{ color: "#ffffff", fontSize: 64 }} />
            <StyledHeading>
              {oopsMsg}
              {stringConstants.exclamation}
            </StyledHeading>
          </StyledHeader>
          <StyledDescription>{errorBoundaryMsg}</StyledDescription>
          <Stack
            direction="horizontal"
            align="center"
            justify="center"
            gap="24"
            padding="48 12 0 12"
          >
            <Button onClick={handleGoHome} fullWidth>
              {intl.formatMessage({
                id: "commonTextInstances.goHome",
                defaultMessage: "GO HOME"
              })}
            </Button>
            <Button
              onClick={handleSubmitFeedback}
              fullWidth
              variant={ButtonVariants.FILLED}
            >
              {intl.formatMessage({
                id: "commonTextInstances.submitFeedback",
                defaultMessage: "SUBMIT FEEDBACK"
              })}
            </Button>
          </Stack>
        </StyledCard>
      </StyledBody>
      <StyledPadding />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  background-color: var(--theme-blue);
  margin: -40px -24px 24px -24px;
  padding-top: 32px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPadding = styled.div`
  flex: 1;
`;

const StyledBody = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled.div`
  width: 40%;
  min-width: 400px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 40px 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledHeading = styled.h1`
  text-align: center;
  color: #ffffff;
  margin-top: 12px;
`;

const StyledDescription = styled.p`
  text-align: center;
`;
