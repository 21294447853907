import { Navigate, useLocation } from "react-router";
import queryString from "query-string";
import { Godaam } from "@certa/common/utils/Godaam";
import { useAppSelector } from "@certa/common/hooks";

export const AnonOnlyRouteWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const userCRSFToken = useAppSelector(
    state => state.authentication.user?.csrf
  );

  const parsedSearchQuery = queryString.parse(location?.search || "");
  const pathname = parsedSearchQuery.next
    ? Array.isArray(parsedSearchQuery.next)
      ? parsedSearchQuery.next[0]
      : parsedSearchQuery.next
    : "/";

  return Godaam.user || userCRSFToken ? (
    <Navigate to={pathname} state={{ from: location }} />
  ) : (
    children
  );
};
