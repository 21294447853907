import type { CommentChannel, ConversationThread } from "@certa/types";
import { useCallback, useState } from "react";
import { resetTabValueInURL } from "../components/commentsNew/MultiChannelWithTabs";
import queryString from "query-string";
import { useNavigate } from "react-router";
import type { TUseComments } from "../CommentsDrawerContext";
import { DrawerViewStates } from "../CommentsDrawerContext";

export const useCommentsDrawerController = () => {
  const navigate = useNavigate();
  const [isMultiChannel, setIsMultiChannel] = useState(false);
  const [isMultiChannelWithTabs, setIsMultiChannelWithTabs] = useState(false);
  const [activeChannel, setActiveChannel] = useState<CommentChannel | null>(
    null
  );
  const [channels, setChannels] = useState<CommentChannel[]>();
  const [activeConversationThreadComms, setActiveConversationThreadComms] =
    useState<ConversationThread | null>(null);
  const [commentsData, setCommentsData] = useState<TUseComments>();
  const [shouldShow, setShouldShow] = useState(false);
  const [drawerStates, setDrawerStates] = useState<DrawerViewStates>(
    DrawerViewStates.NOT_OPENED
  );
  const [isWorkflowBeingMigrated, setIsWorkflowBeingMigrated] = useState(false);

  const URLParams = queryString.parse(window.location.search) as {
    message?: string;
    object_id?: string;
    thread_id?: string;
    completed?: string;
    activeTab?: string;
    group?: string;
    step?: string;
  };
  const stepId = Number(URLParams.step);

  const onClose = useCallback(() => {
    setIsMultiChannel(false);
    setIsMultiChannelWithTabs(false);
    resetTabValueInURL();
    setCommentsData(undefined);
    setActiveChannel(null);
    setActiveConversationThreadComms(null);
    setChannels(undefined);
    setShouldShow(false);
    setDrawerStates(DrawerViewStates.CLOSED);
    if (
      URLParams?.message &&
      URLParams?.object_id &&
      URLParams?.thread_id &&
      stepId
    ) {
      const urlParams = {
        activeTab: URLParams?.activeTab,
        group: URLParams?.group,
        step: stepId,
        completed: URLParams?.completed
      };
      navigate(
        {
          search: queryString.stringify(urlParams, {
            skipEmptyString: true,
            skipNull: true
          })
        },
        { replace: true }
      );
    }
    setIsWorkflowBeingMigrated(false);
  }, [
    URLParams?.activeTab,
    URLParams?.completed,
    URLParams?.group,
    URLParams?.message,
    URLParams?.object_id,
    URLParams?.thread_id,
    navigate,
    stepId
  ]);

  return {
    isMultiChannel,
    multiChannelWithTabs: isMultiChannelWithTabs,
    activeChannel,

    channels,
    commentsData,
    show: shouldShow,
    drawerStates,
    isWorkflowBeingMigrated,
    setIsMultiChannel,
    setMultiChannelWithTabs: setIsMultiChannelWithTabs,
    setActiveChannel,
    activeConversationThreadComms,
    setActiveConversationThreadComms,
    setChannels,
    setCommentsData,
    setShow: setShouldShow,
    setDrawerStates,
    setIsWorkflowBeingMigrated,
    onClose
  };
};
