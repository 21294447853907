/**
 * Contains query hooks for comments model
 */
import { useMemo, useCallback } from "react";
import {
  useQuery,
  useMutation,
  type UseQueryOptions,
  type QueryKey
} from "react-query";
import { commentsModelCreator } from "../models/comments.models";
import { commentsServices } from "../services/comments.services";
import { CommentObjectTypes } from "@certa/types";
import type { GetCommentsParams } from "../types/comments.types";
import { queryClient } from "@certa/queries/utils/utils";
import type { TUseComments } from "@certa/comments/src/CommentsDrawerContext";
import type { UseQueryReturnType } from "@certa/common/types";
import { invalidateCommsConversation } from "../queries/comms/getConversation.query";

/**
 * IMPORTANT: For anything but Integration Result, the object ID is same as the one
 * that you pass while opening the comments. However, in case of IR, the objectId
 * is different than what you pass while opening the comments, due to which, fieldId
 * (which is the objectId in this case) has to be used to make the caching work
 * properly.
 * @param objectId
 */
export const getQueryKey = (objectId: number, type: CommentObjectTypes) =>
  `comments-${type}-${objectId}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useWorkflowCommentsCountQuery = ({ objectId }: { objectId: number }) => {
  const query = useQuery({
    queryKey: [`commentsCount-${objectId}`, objectId],
    queryFn: () => commentsServices.getCommentsCount(objectId)
  });

  return query;
};

const useCommentsQuery = ({
  objectId,
  objectType,
  uid,
  config
}: {
  objectId: number;
  objectType: CommentObjectTypes;
  uid?: string;
  //TODO: create type in comments model & add here
  config?: UseQueryOptions<
    UseQueryReturnType<typeof commentsServices.getComments>
  >;
}) => {
  const payload = useMemo(() => {
    if (!objectId || !objectType) {
      // missing objectId or bad objectType
      return null;
    }

    // Prepare the payload
    const getCommentsPayload: Partial<GetCommentsParams> = {
      object_id: objectId,
      type: objectType
    };
    // comments-field-5215114
    // Add additional payload for the integration case
    if (objectType === CommentObjectTypes.INTEGRATION_RESULT) {
      getCommentsPayload["uid"] = uid;
      getCommentsPayload["field_id"] = objectId;
      getCommentsPayload["object_id"] = 1;
    }

    return getCommentsPayload;
  }, [objectId, objectType, uid]);

  const queryFn = useCallback(() => {
    if (!payload) Promise.resolve(null);
    return commentsServices.getComments(payload as GetCommentsParams);
  }, [payload]);

  const key = getQueryKey(objectId, objectType);

  const query = useQuery({
    enabled: false,
    ...config,
    queryKey: key as QueryKey,
    queryFn: queryFn
  });

  return query;
};

const useAdjudicationFlags = () => {
  const query = useQuery({
    retry: false,
    queryKey: ["adjudicationFlags"],
    queryFn: commentsServices.getCommentFlags
  });

  return query;
};

const handleMutationSuccess = (
  objectId: number,
  objectType: CommentObjectTypes,
  newData: any,
  parentThreadObject?: TUseComments | undefined
) => {
  if (parentThreadObject)
    queryClient.setQueryData(
      `comments-${parentThreadObject?.objectType}-${parentThreadObject?.objectId}`,
      (oldData: any) => {
        return commentsModelCreator(newData, oldData?.results);
      }
    );

  queryClient.setQueryData(
    getQueryKey(objectId, objectType),
    (oldData: any) => {
      return commentsModelCreator(newData, oldData?.results);
    }
  );
};

const useAddCommentMutation = (
  objectId: number,
  objectType: CommentObjectTypes,
  parentThreadObject?: TUseComments | undefined
) =>
  useMutation(commentsServices.addComment, {
    onSuccess: data => {
      handleMutationSuccess(objectId, objectType, data, parentThreadObject);
    }
  });

const useNewThreadMutation = (
  objectId: number,
  objectType: CommentObjectTypes
) =>
  useMutation(commentsServices.newThread, {
    onSuccess: data => {
      const result = handleMutationSuccess(objectId, objectType, data);
      invalidateCommsConversation();
      return result;
    }
  });

const useUpdateFlagMutation = (
  objectId: number,
  objectType: CommentObjectTypes
) =>
  useMutation(commentsServices.updateFlag, {
    onSuccess: data => handleMutationSuccess(objectId, objectType, data)
  });

const useUpdateIntegrationStatusMutation = (
  objectId: number,
  objectType: CommentObjectTypes
) =>
  useMutation(commentsServices.updateIntegrationStatus, {
    onSuccess: data => handleMutationSuccess(objectId, objectType, data)
  });

const useUpdateWorkflowStatusMutation = (objectId: number) =>
  useMutation(commentsServices.updateWorkflowStatus, {
    onSuccess: data =>
      handleMutationSuccess(objectId, CommentObjectTypes.WORKFLOW, data)
  });

const useGetUnreadCommentsCount = (
  processId: number,
  config?: UseQueryOptions<
    UseQueryReturnType<typeof commentsServices.getUnreadCommentsCount>
  >
) =>
  useQuery({
    ...config,
    queryKey: ["unread-comments-count", processId] as QueryKey,
    queryFn: () => commentsServices.getUnreadCommentsCount(processId)
  });

const useIntegrationStatuses = () => {
  const query = useQuery({
    queryKey: ["integration-statuses"],
    queryFn: commentsServices.getIntegrationStatuses
  });
  return query;
};

export {
  useAdjudicationFlags,
  useAddCommentMutation,
  useNewThreadMutation,
  useUpdateFlagMutation,
  useUpdateIntegrationStatusMutation,
  useUpdateWorkflowStatusMutation,
  useCommentsQuery,
  useGetUnreadCommentsCount,
  useIntegrationStatuses
};
