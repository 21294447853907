import React from "react";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { classNames } from "@certa/catalyst/utils/common";
import { FormattedMessage } from "react-intl";
import { Stack } from "@certa/blocks/thanos";
import {
  filterButtonStyles,
  buttonFilterContainerStyles
} from "./ButtonFilter.styles";
import type { ButtonFilterProps } from "./ButtonFilter.types";

const ButtonFilter = ({
  options,
  handleFilterClick,
  selectedFilters,
  currentFocusTabIndex
}: ButtonFilterProps) => {
  return (
    <Stack direction="horizontal" className={buttonFilterContainerStyles}>
      {options.map((option, index) => (
        <button
          className={classNames(
            filterButtonStyles,
            selectedFilters.includes(option.value) ? "active" : "",
            currentFocusTabIndex === index ? "focus-visible" : ""
          )}
          key={option.value}
          onClick={() => handleFilterClick(option.value)}
          // category buttons are first in tabIndex order
          // assigning index = tabIndex to follow the order
          // in which they are to be navigated using tab key
          tabIndex={index}
        >
          <Typography
            color={TypographyColors.NEUTRAL_600}
            variant={TypographyVariants.LABEL_SM}
          >
            <FormattedMessage
              id={option.translationKey}
              defaultMessage={option.label}
            />
          </Typography>
        </button>
      ))}
    </Stack>
  );
};

export { ButtonFilter };
