import type { IconProps } from "@certa/icons";
import type { FormInstance } from "antd/lib/form";
import type { ReportTemplateResponse } from "@certa/queries/types/reportTemplate.types";
import type {
  GetAdvancedFilterDataORMReturnType,
  TaskDetails,
  WorkflowFiltersQuery
} from "@certa/queries/types/workflow.types";
import type { ColumnORM, FilterORM } from "@certa/types";
import type {
  Metric,
  ChartConfig,
  ChartIdentifiers
} from "@certa/common/types";

export type ReportGeneralConfig = {
  name: string;
  description?: string;
};

export type ReportsMetaRef = {
  showRequiredMessage: (visible: boolean) => void;
};

export type MathAndFormulaMapper = Record<string, MathAndFormulaMapperValue>;

type MathAndFormulaMapperValue = {
  col: string;
  label: string;
  labelText: string;
  value: string;
  mtr_type: Metric;
};

export enum ReportColumnFieldTags {
  ID = "id",
  NAME = "name",
  ALERTS_COLUMN = "alerts_smart_column",
  PROCESS_PROGRESS_COLUMN = "process_progress_column",
  MY_TASK_COUNT_COLUMN = "my_task_count_column",
  LAST_UPDATED_COLUMN = "last_updated_column",
  PROCESS_STATUS_COLUMN = "process_status_column",
  AGEING = "ageing",
  PROCESS_CYCLE_TIME_COLUMN = "process_cycle_time_column",
  BADGES = "badges",
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at",
  CREATED_BY_EMAIL = "created_by_email",
  CREATED_BY_NAME = "created_by_name",
  PROCESS_TYPE = "process_type"
}

export enum ReportApiCallLogColumn {
  API_CALL_LOG_ID = "id",
  API_CALL_LOG_API_TYPE = "api_type",
  API_CALL_LOG_START_TIME = "start_time",
  API_CALL_LOG_END_TIME = "end_time",
  API_CALL_LOG_API_STATUS = "status"
}

export enum GridAction {
  FULL_WIDTH = "full_width",
  HALF_WIDTH = "half_width",
  ORDER_CHANGE = "grid_order_change"
}

type WidthAction = GridAction.FULL_WIDTH | GridAction.HALF_WIDTH;
type OrderActions = GridAction.ORDER_CHANGE;

export type PerformActionArgs =
  | {
      actionName: OrderActions;
      gridFieldId: number;
      payload?: number;
    }
  | {
      actionName: WidthAction;
      gridFieldId: number;
      payload?: never;
    };

export type FilterBarTag = {
  id: string;
  label: string;
  value: string | number;
  filterValue?: string | number;
};
export type DropdownOption = {
  title: {
    id: string;
    defaultMessage: string;
  };
  icon: React.ComponentType<IconProps>;
  value: ReportColumnFieldTags;
};

export type SmartColumnType =
  | DropdownOption
  | {
      title: {
        id: string;
        defaultMessage: string;
      };
      icon: React.ComponentType<IconProps>;
      children: DropdownOption[];
    };

export type ReportChartFieldTagType = {
  chartType: ChartIdentifiers | undefined;
  chartConfig: ChartConfig | undefined;
};

export type DisplayReportAs = "Chart" | "Table";

export type ReportsOtherDataValue = {
  id: number;
  name: string;
  description: string;
  exportable: boolean;
  displayReportAs: DisplayReportAs;
  chartType?: ChartIdentifiers;
  chartRef?: ChartRef;
};

export type ReportsOtherData = Record<string, ReportsOtherDataValue>;

export type ChartTypeOption = {
  label: string;
  value: ChartIdentifiers;
  text: string;
};

export type ColumnFiltersTag = {
  id: string | number;
  value: string | number;
  label: string;
};

export type ReportTemplateData = ReportTemplateResponse & {
  template_data: Record<string, any>;
};
export type AdhocFiltersType = WorkflowFiltersQuery | null;
export type AdhocFiltersORMType = FilterORM | undefined;
export type ChartRef = React.RefObject<HTMLDivElement>;

export type ForwardFunctionsRefType = {
  handleConfigChange?: (_: any, allFields: any) => void;
  chartConfigForm?: FormInstance;
  filterForm?: FormInstance;
  setIsStackedBar?: (value: boolean) => void;
  updateFieldsTableState?: (args: UpdateFieldsTableState) => void;
};

export type UpdateFieldsTableState = {
  newKindId: number | undefined;
  fieldTags: string[];
  columnsORM: ColumnORM[];
  fieldLabelMappings: Record<string, string>;
  fieldData?: GetAdvancedFilterDataORMReturnType;
};

/**
 * @description This type is used to define the type of the answer of the report's common field
 * use this field to store any type of additional data.
 */
export type ReportCommonAnswer = {
  kind_id?: number; // this is used by BE for exporting CSV.Don't make any changes to this.
  children_kind_ids?: number[];
  grandchildren_kind_ids?: number[];
};

export type ReportWorkflowsCustomSort = (
  firstComparator: TaskDetails,
  secondComparator: TaskDetails
) => number;
