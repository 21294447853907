import { STATS_COLUMNS } from "./cellUtils";
import type { ColumnDefinition } from "../types";
import { arrayMove } from "@dnd-kit/sortable";

type SwitchSubarraysParams = {
  array: string[];
  startA: number;
  endA: number;
  startB: number;
  endB: number;
};

/**
 * Switches two subarrays in an array.
 *
 * @param params - Object containing the parameters
 * @param params.array - The original array
 * @param params.startA - The start index of the first subarray
 * @param params.endA - The end index of the first subarray
 * @param params.startB - The start index of the second subarray
 * @param params.endB - The end index of the second subarray
 * @returns The new array with the subarrays switched
 */
const switchSubarrays = ({
  array,
  startA,
  endA,
  startB,
  endB
}: SwitchSubarraysParams): string[] => {
  if (startA > startB) {
    // If startA is greater, swap A and B parameters to maintain order
    return switchSubarrays({
      array,
      startA: startB,
      endA: endB,
      startB: startA,
      endB: endA
    });
  }

  const firstPart = array.slice(0, Math.min(startA, startB));
  const subArrayA = array.slice(startA, endA + 1);
  const middlePart = array.slice(
    Math.min(endA, endB) + 1,
    Math.max(startA, startB)
  );
  const subArrayB = array.slice(startB, endB + 1);
  const lastPart = array.slice(Math.max(endA, endB) + 1);

  // Concatenate the parts in the new order
  return [...firstPart, ...subArrayB, ...middlePart, ...subArrayA, ...lastPart];
};

/**
 * Determines if a column is movable (not a y-axis or stats column).
 */
const isMovableColumn = (id: string, yAxisIds: string[], statsIds: string[]) =>
  !yAxisIds.includes(id) && !statsIds.includes(id);

/**
 * Checks if two column ids have the same parent hierarchy.
 * @param activeId - The id of the active column
 * @param overId - The id of the over column
 * @returns True if the parent hierarchies are the same, false otherwise
 */
export const hasSameParentHierarchy = (activeId: string, overId: string) => {
  const activeParts = activeId.split("___");
  const overParts = overId.split("___");

  // First item is the column name, so we need to skip it, rest gives us the parent hierarchy
  const activeParentHierarchy = activeParts.slice(1).join("___");
  const overParentHierarchy = overParts.slice(1).join("___");

  return activeParentHierarchy === overParentHierarchy;
};

/**
 * Reorders columns in a heat map based on drag-and-drop operations.
 * Here activeId and overId are column ids with the hierarchy separated by "___" and wrapped in "$$$"
 * E.g "$$$column_1___column_2___column_3$$$" where "column_3" is the parent of "column_2" and "column_2" is the parent of "column_1"
 */
export const reorderColumns = (
  columnOrder: string[],
  activeId: string,
  overId: string,
  yAxisKeysWithGroupLabel: string[],
  depth: number,
  dataColumns: ColumnDefinition[]
): string[] | undefined => {
  const activeParts = activeId.split("___");
  const overParts = overId.split("___");

  const activeDepth = activeParts.length;
  const overDepth = overParts.length;

  // Allow only reordering within the same hierarchy depth
  if (activeDepth !== overDepth) {
    return undefined;
  }

  // Allow reordering only within the same parent hierarchy
  if (!hasSameParentHierarchy(activeId, overId)) {
    return undefined;
  }

  const yAxisIds = yAxisKeysWithGroupLabel;
  const statsIds = STATS_COLUMNS;

  // Filter out fixed columns
  const movableColumns = columnOrder.filter(id =>
    isMovableColumn(id, yAxisIds, statsIds)
  );

  // Group level reordering
  if (activeDepth !== depth) {
    const activeStart = movableColumns.findIndex(id => id === activeId);
    const overStart = movableColumns.findIndex(id => id === overId);

    const columnCountMap = getColumnCountMap(dataColumns);

    const activeEnd = activeStart + (columnCountMap.get(activeId) ?? 0);
    const overEnd = overStart + (columnCountMap.get(overId) ?? 0);

    if (activeStart === -1 || overStart === -1) {
      return undefined; // Invalid operation
    }

    const reorderedColumns = switchSubarrays({
      array: movableColumns,
      startA: activeStart,
      endA: activeEnd,
      startB: overStart,
      endB: overEnd
    });

    // Reinsert fixed columns
    return [...yAxisIds, ...reorderedColumns, ...statsIds];
  }

  // Column level reordering
  const oldIndex = columnOrder.indexOf(activeId);
  const newIndex = columnOrder.indexOf(overId);

  return arrayMove(columnOrder, oldIndex, newIndex);
};

/**
 * Recursively generates a map where each key represents a column ID
 * and its value is the total number of all its descendant columns.
 *
 * @param dataColumns - Array of column definitions
 * @param columnMap - Map to store the total descendant counts for each column
 * @returns Map<string, number> - A map where keys are column IDs and values are total descendant counts
 */
const getColumnCountMap = (
  dataColumns: ColumnDefinition[],
  columnMap = new Map<string, number>()
): Map<string, number> => {
  dataColumns.forEach(column => {
    if (column.id) {
      let totalDescendants = 0;

      if ("columns" in column && column.columns) {
        const childColumns = column.columns as ColumnDefinition[];

        // Recursively calculate descendant count for children
        totalDescendants = childColumns.reduce((acc, child) => {
          if (child.id) {
            return (
              acc +
              (getColumnCountMap([child], columnMap).get(child.id) || 0) +
              1
            );
          }
          return acc;
        }, 0);
      }

      // Store total descendant count in the map
      columnMap.set(column.id, totalDescendants);
    }
  });

  return columnMap;
};

/**
 * Recursively retrieves all column IDs from a nested column structure.
 *
 * @param columns - Array of column definitions
 * @returns Array of all column IDs
 */
export const getAllColumnIds = (columns: ColumnDefinition[]): string[] => {
  return columns.flatMap(col => {
    const ids = col.id ? [col.id] : [];
    if ("columns" in col) {
      return [...ids, ...getAllColumnIds(col.columns as ColumnDefinition[])];
    }
    return ids;
  });
};

const cleanId = (id: string) => id.replace(/^\$\$\$|\$\$\$$/g, "");

/**
 * Validates the initial column order to ensure it maintains required structure.
 * Falls back to default order if validation fails.
 *
 * @param initialOrder - Saved column order to validate
 * @param defaultOrder - Default column order to fall back to
 * @param yAxisKeysWithGroupLabel - Array of y-axis column identifiers
 * @returns Valid column order array
 */
export const validateColumnOrder = (
  initialOrder: string[] | undefined,
  defaultOrder: string[],
  yAxisKeysWithGroupLabel: string[]
): string[] => {
  if (!initialOrder || initialOrder.length === 0) return defaultOrder;

  const requiredColumnsSet = new Set(defaultOrder);
  const yAxisIds = yAxisKeysWithGroupLabel;
  const statsIds = STATS_COLUMNS;

  // Check fixed columns positions
  for (let i = 0; i < yAxisIds.length; i++) {
    if (initialOrder[i] !== yAxisIds[i]) {
      return defaultOrder;
    }
  }

  // Validate stats columns at the end
  for (let i = 0; i < statsIds.length; i++) {
    if (
      initialOrder[initialOrder.length - statsIds.length + i] !== statsIds[i]
    ) {
      return defaultOrder;
    }
  }

  const initialMovableColumns = initialOrder.filter(id =>
    isMovableColumn(id, yAxisIds, statsIds)
  );
  const defaultMovableColumns = defaultOrder.filter(id =>
    isMovableColumn(id, yAxisIds, statsIds)
  );

  // Filter out columns that are no longer in defaultOrder
  const validInitialColumns = initialMovableColumns.filter(id =>
    requiredColumnsSet.has(id)
  );

  // Group columns by their parent hierarchy
  const groupColumns = (columns: string[]) => {
    const groups = new Map<string, string[]>();

    columns.forEach(col => {
      const parts = cleanId(col).split("___");
      // Determine the parent key based on the hierarchy
      const parentKey =
        parts.length === 1 ? col : `$$$${parts.slice(1).join("___")}$$$`;

      // Initialize the group if it doesn't exist
      if (!groups.has(parentKey)) {
        groups.set(parentKey, []);
      }

      if (parts.length !== 1) {
        groups.get(parentKey)?.push(col);
      }
    });

    return groups;
  };

  const initialGroups = groupColumns(validInitialColumns);
  const defaultGroups = groupColumns(defaultMovableColumns);

  // Reconstruct the order maintaining group integrity
  let reorderedColumns: string[] = [];
  const processedGroups = new Set<string>();

  const insertGroupColumns = (parentKey: string, groupColumns: string[]) => {
    const parentStartIndex = reorderedColumns.findIndex(id => id === parentKey);

    if (parentStartIndex === -1) {
      reorderedColumns.push(parentKey, ...groupColumns);
    } else {
      reorderedColumns = [
        ...reorderedColumns.slice(0, parentStartIndex + 1),
        ...groupColumns,
        ...reorderedColumns.slice(parentStartIndex + 1)
      ];
    }
    processedGroups.add(parentKey);
  };

  // First add groups in the order they appear in initialOrder
  initialGroups.forEach((initialGroupColumns, parentKey) => {
    if (!processedGroups.has(parentKey)) {
      // Get columns from both initial and default groups
      const defaultGroupColumns = defaultGroups.get(parentKey) || [];

      // Create a Set of existing columns in initialGroupColumns
      const existingColumns = new Set(initialGroupColumns);

      // Add any missing columns from defaultGroupColumns
      const mergedColumns = [
        ...initialGroupColumns,
        ...defaultGroupColumns.filter(col => !existingColumns.has(col))
      ];
      insertGroupColumns(parentKey, mergedColumns);
    }
  });

  // Add any remaining groups from defaultOrder
  defaultGroups.forEach((groupColumns, parentKey) => {
    if (!processedGroups.has(parentKey)) {
      insertGroupColumns(parentKey, groupColumns);
    }
  });

  // Reconstruct final order with fixed columns
  return [...yAxisIds, ...reorderedColumns, ...statsIds];
};
