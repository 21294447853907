import type { Ref } from "react";
import { forwardRef } from "react";
import { css } from "emotion";

import { getAvatarLetters } from "@certa/blocks/thanos";
import { Avatar } from "@certa/catalyst/components/Avatar";
import type {
  AvatarColors,
  AvatarSizes
} from "@certa/catalyst/components/Avatar";

import { BUTTON_TAB_INDEX } from "./constants";

type UserAvatarProps = {
  userName: string;
  selectable?: boolean;
  size?: AvatarSizes;
  color?: AvatarColors;
  onClick?: () => void;
};

export const UserAvatar = forwardRef(function UserAvatar(
  props: UserAvatarProps,
  ref: Ref<HTMLDivElement>
) {
  const { userName, selectable: isSelectable, size, color, ...rest } = props;

  return (
    <div
      role="button"
      tabIndex={BUTTON_TAB_INDEX}
      {...rest}
      className={css`
        &:focus {
          outline: 0.125rem solid var(--colors-brand-400);
          outline-offset: 0.125rem;
          border-radius: 0.25rem;
        }
      `}
      aria-label="User profile"
      ref={ref}
    >
      <Avatar
        aria-label={userName}
        selectable={isSelectable}
        size={size}
        color={color}
      >
        {getAvatarLetters(userName)}
      </Avatar>
    </div>
  );
});
