import React from "react";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { ChevronRightLight } from "@certa/icons/components/ChevronRightLight";
import { MobileMenuItem } from "./MobileMenuItem";

export type MenuItemType = {
  id: string;
  label: string | React.ReactNode;
  icon?: React.ReactElement;
  onClick?: () => void;
  url?: string;
  subMenuItems?: MenuItemType[];
};

type MenuListProps = {
  items: MenuItemType[];
  activeMenuId: string | null;
  onMenuSelect: (menuId: string | null) => void;
};

export const MenuList = ({
  items,
  activeMenuId,
  onMenuSelect
}: MenuListProps) => {
  const handleItemClick = (item: MenuItemType) => {
    if (item.onClick) {
      item.onClick();
      return;
    }
    onMenuSelect(item.id);
  };

  const renderMenuItem = (item: MenuItemType) => {
    return (
      <MobileMenuItem key={item.id} onClick={() => handleItemClick(item)}>
        <Stack
          direction="horizontal"
          gap="4"
          justify="space-between"
          align="center"
        >
          <Stack direction="horizontal" gap="8" align="center">
            {item.icon && item.icon}
            {item.label}
          </Stack>
          {item.subMenuItems && <ChevronRightLight size={16} />}
        </Stack>
      </MobileMenuItem>
    );
  };

  const getActiveMenuItems = () => {
    if (!activeMenuId) return items;
    const activeItem = items.find(item => item.id === activeMenuId);
    return activeItem?.subMenuItems || items;
  };

  return <>{getActiveMenuItems().map(renderMenuItem)}</>;
};
