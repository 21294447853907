import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";

import {
  getDataObjectDetails,
  getDataObjectsForWorkflow,
  getDataObjectTypes,
  getDataObjectWorkflowRelationActions,
  getDataObjectActivityLogs,
  getRelatedObjects,
  getObjectsStatuses
} from "../services/dataObjects.services";

import type {
  CreateDataObjectDetailsModelReturn,
  CreateDataObjectWorkflowRelationActionsModelReturn,
  DataObjectWorkflowRelation,
  GetDataObjectDetailsRequestParams
} from "../types/dataObjects.types";
import type { UseQueryReturnType } from "@certa/common/types";
import { queryClient } from "../utils/utils";

/**
 * @deprecated - use useGetDataObjectsDynamicAPI instead
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useGetDataObjectsForWorkflow = (
  workflowId: number,
  config: UseQueryOptions<
    UseQueryReturnType<typeof getDataObjectsForWorkflow>
  > = {
    enabled: true
  }
) => {
  return useQuery({
    enabled: !!workflowId && !!config?.enabled,
    retry: 0,
    queryKey: ["dataObjects", workflowId],
    queryFn: () => getDataObjectsForWorkflow(workflowId)
  });
};

const getObjectTypesQuery = () => {
  return {
    queryKey: ["dataObjectTypes"] as QueryKey,
    queryFn: getDataObjectTypes,
    staleTime: 1000 * 60 * 60 * 1
  };
};

export const prefetchObjectTypesQuery = () => {
  queryClient.prefetchQuery({
    ...getObjectTypesQuery()
  });
};

export const useGetDataObjectTypes = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getDataObjectTypes>>
) => {
  return useQuery({
    ...config,
    ...getObjectTypesQuery()
  });
};

type UseGetDataObjectsDynamicAPIParams = {
  filters: {
    objectId?: string;
    objectTypeTag?: string[];
    outgoingRelationshipsToObjectIds?: string[];
    outgoingRelationshipsForwardRelationName?: string[];
    workflowMappingWorkflowId?: string[];
    relatedVia?:
      | {
          relationName: string;
          relatedObjectUid: string;
          objectTypeTag: string;
          relatedObjectId?: string;
        }
      | {
          relationName: string;
          relatedObjectUid?: string;
          objectTypeTag: string;
          relatedObjectId: string;
        };
    statusTagIn?: string[];
    statusTagNotIn?: string[];
    nameIContains?: string;
    orderBy?: string;
  };
};

export const useGetDataObjectsDynamicAPI = (
  params: UseGetDataObjectsDynamicAPIParams,
  offset: number = 0,
  config: UseQueryOptions<UseQueryReturnType<typeof getDataObjectDetails>> = {
    enabled: true
  }
) => {
  const { filters } = params;

  const objectTypeTag = filters.objectTypeTag?.join(",");
  const outgoingRelationshipsToObjectIds =
    filters.outgoingRelationshipsToObjectIds?.join(",");
  const outgoingRelationshipsForwardRelationName =
    filters.outgoingRelationshipsForwardRelationName?.join(",");
  const workflowMappingWorkflowId =
    filters.workflowMappingWorkflowId?.join(",");
  const relatedVia = filters.relatedVia;
  const objectId = filters.objectId;
  // We need to sort created_at by descending order by default since the
  // recently created objects should be at the top of the list
  const orderBy = filters?.orderBy || "-created_at";
  const nameIContains = filters?.nameIContains;
  const statusTagIn = filters?.statusTagIn?.join(",");
  const statusTagNotIn = filters?.statusTagNotIn?.join(",");

  const transformedParams: GetDataObjectDetailsRequestParams = {
    data: {
      id: null, // TODO: Remove this once create workflow supports uid for object
      uid: null,
      name: null,
      data: null,
      logo: null,
      applied_badges: null,
      created_at: null,
      updated_at: null,
      object_type: {
        id: null,
        tag: null,
        schema: null,
        created_at: null,
        updated_at: null,
        ui_schema: null
      },
      status: {
        id: null,
        tag: null,
        name: null,
        color_code: null
      },
      workflow_mappings: {
        id: null,
        workflow: null,
        object_reference: null
      }
    },
    filters: {
      uid__in: objectId,
      object_type__tag__in: objectTypeTag,
      outgoing_relationships__to_object_id__in:
        outgoingRelationshipsToObjectIds,
      outgoing_relationships__forward_relation_name__in:
        outgoingRelationshipsForwardRelationName,
      workflow_mapping__wf_id__in: workflowMappingWorkflowId,
      order_by: orderBy,
      related_via: relatedVia
        ? relatedVia.relatedObjectUid
          ? {
              relation_name: relatedVia.relationName,
              related_object_uid: relatedVia.relatedObjectUid,
              object_type_tag: relatedVia.objectTypeTag
            }
          : relatedVia.relatedObjectId
            ? {
                relation_name: relatedVia.relationName,
                related_object_id: relatedVia.relatedObjectId,
                object_type_tag: relatedVia.objectTypeTag
              }
            : undefined
        : undefined,
      name__icontains: nameIContains,
      status_tag__in: statusTagIn,
      status_tag__not_in: statusTagNotIn
    }
  };

  return useQuery<CreateDataObjectDetailsModelReturn>({
    enabled: config.enabled,
    retry: 0,
    queryKey: ["dataObjectsDetails", transformedParams, offset],
    queryFn: () => getDataObjectDetails(transformedParams, offset)
  });
};

export type UseGetDataObjectWorkflowRelationActionsParams =
  | {
      objectTypeTag?: string;
      workflowKindTag: string;
      relation?: DataObjectWorkflowRelation;
    }
  | {
      workflowKindTag?: string;
      objectTypeTag: string;
      relation?: DataObjectWorkflowRelation;
    }
  | {
      objectTypeTag: string;
      workflowKindTag: string;
      relation?: DataObjectWorkflowRelation;
    };

export const useGetDataObjectWorkflowRelationActions = (
  params: UseGetDataObjectWorkflowRelationActionsParams,
  config: UseQueryOptions<
    UseQueryReturnType<typeof getDataObjectWorkflowRelationActions>
  > = {
    enabled: true
  }
) => {
  const { objectTypeTag, workflowKindTag, relation } = params;

  return useQuery({
    enabled: (!!objectTypeTag || !!workflowKindTag) && !!config?.enabled,
    queryKey: [
      "dataObjectWorkflowRelationActions",
      objectTypeTag,
      workflowKindTag,
      relation
    ],
    queryFn: () =>
      getDataObjectWorkflowRelationActions({
        objectTypeTag,
        workflowKindTag,
        relation
      })
  });
};

const getNextPayload = (
  currentResponse: CreateDataObjectWorkflowRelationActionsModelReturn[number]
): UseGetDataObjectWorkflowRelationActionsParams | null => {
  if (!currentResponse?.objectTypeTag || !currentResponse?.workflowKindTag) {
    return null;
  }

  if (currentResponse.relation === "initializer_object") {
    return {
      relation: "object_creator",
      objectTypeTag: currentResponse.objectTypeTag
    };
  }

  if (currentResponse.relation === "object_creator") {
    return {
      relation: "initializer_object",
      workflowKindTag: currentResponse.workflowKindTag
    };
  }

  return null;
};

async function fetchAllWorkflowRelations(
  payload: UseGetDataObjectWorkflowRelationActionsParams
): Promise<any[]> {
  let allRelations: any[] = [];
  let currentPayload: UseGetDataObjectWorkflowRelationActionsParams | null =
    payload;

  while (currentPayload) {
    const currentBatch =
      await getDataObjectWorkflowRelationActions(currentPayload);
    if (currentBatch.length === 0) break;

    allRelations = [...allRelations, ...currentBatch];

    // Get the next payload from the first item in the current batch
    // There will be only one item in the current batch
    const lastItem = currentBatch[0];
    currentPayload = getNextPayload(lastItem);
  }

  return allRelations;
}

export const useGetDataObjectWorkflowRelationChain = (
  params: UseGetDataObjectWorkflowRelationActionsParams,
  config: UseQueryOptions<
    UseQueryReturnType<typeof getDataObjectWorkflowRelationActions>
  > = {
    enabled: true
  }
) => {
  return useQuery({
    ...config,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      (!!params.objectTypeTag || !!params.workflowKindTag) && !!config?.enabled,
    queryKey: ["dataObjectWorkflowRelationChain", params] as QueryKey,
    queryFn: () => fetchAllWorkflowRelations(params)
  });
};

type UseGetDataObjectActivityLogsParams = {
  objectId: string;
};

export const useGetDataObjectActivityLogs = (
  params: UseGetDataObjectActivityLogsParams,
  config: UseQueryOptions<
    UseQueryReturnType<typeof getDataObjectActivityLogs>
  > = {
    enabled: true
  }
) => {
  const { objectId } = params;

  return useQuery({
    enabled: !!objectId,
    ...config,
    queryKey: ["dataObjectActivityLogs", objectId] as QueryKey,
    queryFn: () => getDataObjectActivityLogs(objectId)
  });
};

type UseGetRelatedObjectsParams = {
  objectReference: string;
  workflowId: number;
  relationPath?: string;
};

export const useGetRelatedObjects = (params: UseGetRelatedObjectsParams) => {
  const { objectReference, workflowId, relationPath } = params;

  return useQuery({
    enabled: !!objectReference && !!workflowId,
    queryKey: ["relatedObjects", objectReference, workflowId] as QueryKey,
    queryFn: () =>
      getRelatedObjects({ objectReference, workflowId, relationPath })
  });
};

export const useGetObjectsStatuses = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getObjectsStatuses>>
) => {
  return useQuery({
    // TODO: Check how statuses/last_updated_at/ endpoint
    // can be used to invalidate the cache
    cacheTime: 1000 * 60 * 60 * 1, // 1 hour
    ...config,
    queryKey: ["objectsStatuses"] as QueryKey,
    queryFn: getObjectsStatuses
  });
};
