import { get, cloneDeep, update } from "lodash-es";

export type HandleFormListChildOnChange = <T>(
  path: (string | number)[],
  value: T
) => void;

export function FormList<K extends object, T>({
  parentPath = [],
  fieldName,
  value,
  children,
  onChange
}: FormListProps<K, T>) {
  const valuePath = [...parentPath, fieldName].join(".");
  const listValue = get<K, string, T[]>(value, valuePath, []);
  if (!Array.isArray(listValue) || listValue === undefined) {
    return null;
  }

  const handleAdd = (initialGroup: T) => {
    const newValue = cloneDeep(value);
    update(newValue, valuePath, (fieldArrayValue: T[] | undefined) => {
      if (fieldArrayValue) {
        fieldArrayValue.push(cloneDeep(initialGroup));
      } else {
        fieldArrayValue = [cloneDeep(initialGroup)];
      }
      return fieldArrayValue;
    });
    onChange(newValue);
  };

  const handleRemove = (removeIndex: number) => {
    const newValue = cloneDeep(value);
    update(newValue, valuePath, (fieldArrayValue: T[] | undefined) =>
      fieldArrayValue?.filter((_, index) => index !== removeIndex)
    );
    onChange(newValue);
  };

  return (
    <>
      {children(
        listValue.map((field, index) => ({ key: index, fieldValue: field })),
        {
          add: handleAdd,
          remove: handleRemove
        }
      )}
    </>
  );
}

type FormListProps<K, T> = {
  parentPath?: (string | number)[];
  fieldName: string;
  value: K;
  onChange: (value: K) => void;
  children: (
    fields: FormListFieldData<T>[],
    operations: FormListOperation<T>
  ) => JSX.Element | React.ReactNode;
};

type FormListFieldData<T> = {
  key: number;
  fieldValue: T;
};

type FormListOperation<T> = {
  add: (defaultValue: T) => void;
  remove: (index: number) => void;
};
