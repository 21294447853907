import { useState, useCallback } from "react";
import { Stack } from "@certa/blocks";
import { ButtonVariants, Button } from "@certa/catalyst/components/Button";
import { useLocation } from "react-router";
import { autoLoginUser as _autoLoginUser } from "../../../modules/LoginPage/loginSlice";
import to from "await-to-js";
import { closeWindow } from "@certa/common/utils/helper";
import { Godaam } from "@certa/common/utils/Godaam";
import { EPHEMERAL_TOKEN_QUERY_PARAM } from "@certa/common/constants";
import { useAppDispatch as useDispatch } from "@certa/common/hooks";

export const UserInteractionRequired = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = !!Godaam.user;
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(location.search);

  const ephemeralToken = urlParams.get(EPHEMERAL_TOKEN_QUERY_PARAM);
  const autoLoginUser = useCallback(
    async (ephemeralToken: string) => {
      setIsLoading(true);
      const loginPromise = dispatch(
        // @todo by useMutation
        _autoLoginUser(ephemeralToken, true)
      ) as any as Promise<any>;
      const [error] = await to(loginPromise);
      if (!error) {
        closeWindow();
      } else {
        console.log(" error ", error);
      }
      setIsLoading(false);
    },
    [dispatch]
  );

  const onCloseThisPage = () => {
    if (ephemeralToken) {
      if (!isAuthenticated) {
        autoLoginUser(ephemeralToken);
      } else {
        closeWindow();
      }
    }
  };

  const closeThisPageLabel = "Close this page";
  return (
    <Stack
      style={{ height: "100vh", width: "100%" }}
      align="center"
      justify="center"
    >
      <Button
        variant={ButtonVariants.FILLED}
        loading={isLoading}
        disabled={isLoading}
        onClick={onCloseThisPage}
      >
        {closeThisPageLabel}
      </Button>
    </Stack>
  );
};
