import type { FC } from "react";
import { Stack } from "@certa/catalyst/layouts/Stack";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { css } from "emotion";

import { ListGroupIllustration } from "@certa/common/components/LoadingAndEmptyStatesWrapper/illustrations/ListGroupIllustration";

export const NoComments: FC<{
  heading: string;
  subHeading?: string;
  isMultiChannel?: boolean;
}> = ({ heading, subHeading, isMultiChannel }) => {
  if (!isMultiChannel) {
    return (
      <div
        className={css({
          borderTop: "1px solid var(--colors-neutral-400)"
        })}
      />
    );
  }

  return (
    <Stack
      direction="vertical"
      align="center"
      justify="center"
      gap="8"
      dangerouslySetClassName={noCommentsWrapperStyles}
    >
      <ListGroupIllustration />
      <Stack>
        <Typography
          variant={TypographyVariants.TITLE_SM}
          color={TypographyColors.NEUTRAL_800}
        >
          {heading}
        </Typography>
      </Stack>
      {subHeading ? (
        <Stack>
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
          >
            {subHeading}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};

const noCommentsWrapperStyles = css({
  width: "100%",
  height: "100%",
  backgroundColor: "var(--colors-neutral-200)"
});
