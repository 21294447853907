//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const User = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="User"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.19508 16H8.02833H8.86159C11.7497 16 14 15.8623 14 12.8612C14 10.1903 12.2228 7.93253 9.75012 7.18791C11 6.5823 11.8333 5.26027 11.8333 3.80049C11.8333 1.70718 10.1385 0 8 0C5.88864 0 4.16674 1.68024 4.16674 3.80049C4.16674 5.28709 5.02822 6.58236 6.24988 7.18791C3.80544 7.93121 2 10.189 2 12.8612C2.05642 15.8623 4.30665 16 7.1948 16H7.19508Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
