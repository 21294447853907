import { FieldTypes } from "@certa/types/src/fieldTypes";
import { useAppSelector } from "../hooks";

// This hook controls whether to show labels in reports
// based on configuration settings
export const useLabelsInReport = () => {
  const shouldShowLabelsInReport: boolean = !!useAppSelector(
    state =>
      state?.config?.feature_toggles?.[
        "DISPLAY_LABELS_FOR_SELECTABLE_FIELDS_IN_REPORTS"
      ]
  );

  return shouldShowLabelsInReport;
};

export const SHOW_LABELS_SUPPORTED_FIELD_TYPES = [
  FieldTypes.SELECT,
  FieldTypes.RADIO,
  FieldTypes.BOOL
];
