//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const NewPage = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="NewPage"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 6V14.5H3.5V1.5H8V6H12.5ZM2 1C2 0.447715 2.44772 0 3 0H8.58579C8.851 0 9.10536 0.105357 9.29289 0.292893L13.7071 4.70711C13.8946 4.89464 14 5.149 14 5.41421V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
