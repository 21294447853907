//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const SingleFile = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="SingleFile"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 1C2 0.447715 2.44772 0 3 0H8.58579C8.851 0 9.10536 0.105357 9.29289 0.292893L13.7071 4.70711C13.8946 4.89464 14 5.149 14 5.41421V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V1ZM12.5 6V14.5H3.5V1.5H8V6H12.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.65251 7.45382C7.96143 7.16324 8.3804 7 8.81727 7C9.25414 7 9.67311 7.16324 9.98203 7.45382C10.2909 7.74439 10.4645 8.13849 10.4645 8.54943C10.4645 8.96036 10.2909 9.35447 9.98203 9.64504L8.00131 11.5082C7.81362 11.6847 7.55907 11.7839 7.29364 11.7839C7.02821 11.7839 6.77366 11.6847 6.58597 11.5082C6.39829 11.3316 6.29285 11.0922 6.29285 10.8425C6.29285 10.5929 6.39829 10.3534 6.58597 10.1769L8.56669 8.31373C8.70508 8.18355 8.92946 8.18355 9.06785 8.31373C9.20624 8.4439 9.20624 8.65496 9.06785 8.78513L7.08713 10.6483C7.03236 10.6998 7.00159 10.7697 7.00159 10.8425C7.00159 10.9154 7.03236 10.9853 7.08713 11.0368C7.1419 11.0883 7.21618 11.1172 7.29364 11.1172C7.3711 11.1172 7.44538 11.0883 7.50015 11.0368L9.48087 9.17364C9.65687 9.00809 9.75575 8.78355 9.75575 8.54943C9.75575 8.31531 9.65687 8.09077 9.48087 7.92522C9.30487 7.75967 9.06617 7.66667 8.81727 7.66667C8.56837 7.66667 8.32967 7.75967 8.15367 7.92522L6.17295 9.78836C5.87572 10.0679 5.70874 10.4471 5.70874 10.8425C5.70874 11.2379 5.87572 11.6171 6.17295 11.8967C6.47017 12.1763 6.8733 12.3333 7.29364 12.3333C7.71398 12.3333 8.1171 12.1763 8.41433 11.8967L10.3951 10.0335C10.5334 9.90337 10.7578 9.90337 10.8962 10.0335C11.0346 10.1637 11.0346 10.3748 10.8962 10.505L8.91549 12.3681C8.48534 12.7727 7.90195 13 7.29364 13C6.68533 13 6.10193 12.7727 5.67179 12.3681C5.24165 11.9635 5 11.4147 5 10.8425C5 10.2703 5.24165 9.72156 5.67179 9.31696L7.65251 7.45382Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
