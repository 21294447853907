import { useLocation, useNavigate } from "react-router";
import type { NavigateFunction, Location } from "react-router";

type WithRouterProps = {
  location: Location;
  navigate: NavigateFunction;
};

export const withRouterForClassComponents = <P extends WithRouterProps>(
  Component: React.ComponentType<P>
) => {
  function ComponentWithRouterProp(props: Omit<P, keyof WithRouterProps>) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <Component {...(props as P)} location={location} navigate={navigate} />
    );
  }

  return ComponentWithRouterProp;
};
