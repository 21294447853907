import { Checkbox } from "@certa/catalyst/components/Checkbox";
import { useIntl } from "react-intl";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const RelativeDateField = ({ value, onChange }: Props) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: "common.useRelativeDateFilter",
    defaultMessage: "Use relative date filter"
  });
  return (
    <div>
      <Checkbox checked={value} onChange={onChange} label={label} />
    </div>
  );
};
