import { css } from "emotion";

export const breadcrumbPathDisplayStyles = {
  flexWrap: css({
    flexWrap: "wrap",
    marginBottom: "var(--space-8)",
    width: "100%"
  }),
  fullWidth: css({
    width: "100%"
  }),
  maxWidth: css({
    maxWidth: "90%"
  })
};
