/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Upload } from "antd";
import { ToastTypes, showToast } from "@certa/catalyst/components/Toast";
import { ButtonVariants, Button } from "@certa/catalyst/components/Button";
import { SubscribeSwitch } from "./SubscribeSwitch";
import type { MentionItem } from "react-mentions";
import { useMentions } from "../../hooks/useMentions";
import { useAddCommentMutation } from "@certa/queries/hooks/comments.hooks";
import { useIntl } from "react-intl";
import {
  useChannelContext,
  useThreadContext,
  useCommentsContext
} from "../../comments.context";
import { Attachment } from "@certa/icons/components/Attachment";
import { Close } from "@certa/icons/components/Close";
import { Stack, Text, Tooltip } from "@certa/blocks/thanos";
import type { UploadFileBlob } from "./AttachementViewModal";
import { AttachmentViewModal } from "./AttachementViewModal";
import { CommentCareLine } from "./CommentCareLine";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { useCommentsDrawerContext } from "../../CommentsDrawerContext";
import { MentionSupportedInput } from "@certa/common/components/MentionSupportedInput/MentionSupportedInput";
import { useSubscribeThreadToggle } from "@certa/common/hooks/useCanSubscribeThread";
import { validateUploadFile } from "@certa/common/utils/file";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";
import { WrapWithMigrationTooltip } from "@certa/common/components/WrapWithMigrationTooltip";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import {
  invalidateCommsConversation,
  invalidateConversationMessages
} from "@certa/queries/queries/comms";

/**
 * TODO: Component to be broken down further, with possibly the modal part
 * extracted out.
 */

export const CommentFormNew: FC<{
  onCommentPost?: () => void;
  // From Query Param
}> = ({ onCommentPost }) => {
  const { objectType, objectId, fieldId } = useChannelContext();
  const { threadId } = useThreadContext();
  const availableMentions = useMentions(threadId);
  const { activeConversationThreadComms } = useCommentsContext();
  const activeConversationThreadCommsId =
    activeConversationThreadComms?.conversationId;
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();

  const { isWorkflowBeingMigrated, parentThreadObject } =
    useCommentsDrawerContext();

  const [message, setMessage] = useState(""); // To store message text
  const [mentions, setMentions] = useState<MentionItem[]>([]); // To store mentioned users and groups
  const [attachment, setAttachment] = useState<UploadFileBlob | null>(null); // To store the attachment that needs to be uploaded
  const [isModalVisible, setIsModalVisibility] = useState(false); //
  const { mutate: addComment, isLoading } = useAddCommentMutation(
    fieldId || objectId, // Refer to comment.hooks on why we did this.
    objectType,
    parentThreadObject
  );
  const canRenderSubscribeSwitch = useSubscribeThreadToggle();

  const intl = useIntl();
  const { isMobileResolution } = useScreenResolution();

  const setModalVisible = (value: boolean) => {
    setIsModalVisibility(value);
    if (!value) {
      /**
       *  Bug: rc-util is adding overflow: hidden on body tag,
       *  remove once antd is upgraded. For now we reset this style
       *  manually.
       */
      setTimeout(() => {
        const body: HTMLElement | null = document.querySelector("body");
        if (body) {
          body.style.removeProperty("overflow");
        }
      }, 1000);
    }
  };
  /**
   * This method helps in attaching images from clipboard, usually screenshots.
   */
  const handlePaste = useCallback((event: any) => {
    try {
      if (
        event.clipboardData.files.length &&
        event.clipboardData.files[0].type.startsWith("image/")
      ) {
        // Get the first file (if you've got multiple here)
        const file = event.clipboardData.files[0];
        // make sure to attach UID to it, as ANTD Uploader uses
        // it as key when it renders the file name
        file.uid = "image" + Date.now();

        // update the file in the state.
        setAttachment(file);
        setModalVisible(true);
      }
    } catch (err) {
      // Prevent Oops screen by handing any error here.
      console.error("Failed to attach clipboard data", err);
    }
  }, []);
  /**
   * If the message is empty or there's no attachment, we
   * disable the post button altogether
   */
  const isPostDisabled = useMemo(
    () => !message.trim() && !attachment,
    [attachment, message]
  );

  const isSendMessageDisabled =
    isPostDisabled || isLoading || isWorkflowBeingMigrated;

  /**
   * Post CTA handles the preparation of payload where user mentions,
   * group mentions, attachment and the message itself is considered
   * and pushed to the thread via thunk.
   */
  const handlePostComment = useCallback(async () => {
    MixPanelActions.track(
      MixPanelEvents.workflowDetailEvents
        .COMMENT_DETAIL_PANE_CLICK_SUBMIT_COMMENT
    );
    if (isSendMessageDisabled) {
      showToast({
        type: ToastTypes.ERROR,
        title: intl.formatMessage({
          id: "comments.errors.commentPostFailed"
        })
      });
      return;
    }
    setModalVisible(false);

    const mentionedUsers: number[] = [];
    const mentionedGroups: number[] = [];

    // Going through all the mentions, breaking them
    // into users and groups
    mentions.forEach(mention => {
      // id is in format of u123 or g123, depending
      // upon whether it's a user or a group.
      const realId = parseInt(mention.id.substr(1));
      if (mention.id.indexOf("u") === 0) {
        // it's a user mention
        mentionedUsers.push(realId);
      } else {
        // it's a group mention
        mentionedGroups.push(realId);
      }
    });

    const commentPayload = {
      object_id: objectId,
      type: objectType,
      mentioned_users: mentionedUsers,
      mentioned_groups: mentionedGroups,
      message: message.trim() || "",
      attachment: attachment as unknown as File,
      thread_id: threadId
    };

    addComment(commentPayload, {
      onError: async e => {
        try {
          const error = e as Response;
          const errorFromAPI = await error.json();

          if (errorFromAPI.errors?.non_field_errors?.length > 0) {
            showToast({
              type: ToastTypes.ERROR,
              title: errorFromAPI.errors.non_field_errors[0]
            });
            return;
          }

          showToast({
            type: ToastTypes.ERROR,
            title: errorFromAPI.errors.attachment[0]
          });
        } catch (e) {
          showToast({
            type: ToastTypes.ERROR,
            title: intl.formatMessage({
              id: "comments.errors.commentPostFailed"
            })
          });
        }
      },
      onSuccess: () => {
        setMessage("");
        setAttachment(null);
        setMentions([]);
        // Refetch conversation messages if comms endpoints are enabled
        if (isCommsNewEndpointEnabled && activeConversationThreadCommsId) {
          invalidateConversationMessages(activeConversationThreadCommsId);
          invalidateCommsConversation();
        }
        onCommentPost?.();
      }
    });
  }, [
    mentions,
    isSendMessageDisabled,
    objectId,
    objectType,
    message,
    attachment,
    threadId,
    addComment,
    intl,
    onCommentPost,
    isCommsNewEndpointEnabled,
    activeConversationThreadCommsId
  ]);

  /**
   * Update message within the component's state
   */
  const onMessageChange = useCallback(
    (
      event: any,
      value: React.SetStateAction<string>,
      rawValue: React.SetStateAction<string>,
      mentions: React.SetStateAction<MentionItem[]>
    ) => {
      setMessage(value);
      setMentions(mentions);
    },
    []
  );

  /**
   * Update attachment within the component's state
   * @returns {boolean} false, to stop backgroup upload API action by uploader component
   */
  const handleBeforeUpload = useCallback((file: any) => {
    const { valid: isFileValid, error } = validateUploadFile({ file });

    if (isFileValid) {
      setAttachment(file);
      setModalVisible(true);
    } else {
      showToast({
        type: ToastTypes.ERROR,
        title: String(error)
      });
    }

    return false; // prevent uploading
  }, []);

  /**
   * Removes attachment from the state
   */
  const handleOnRemove = useCallback((file: any) => {
    setAttachment(null);
  }, []);

  /**
   * Hides modal and removes the attachment
   */
  const handleModalCancel = useCallback(() => {
    setAttachment(null);
    setModalVisible(false);
  }, []);

  /**
   * Upload component props to handle callbacks for certain events
   * and the file list.
   */
  const uploadProps = useMemo(() => {
    return {
      onRemove: handleOnRemove,
      beforeUpload: handleBeforeUpload,
      fileList: attachment ? [attachment] : []
    };
  }, [attachment, handleOnRemove, handleBeforeUpload]);

  // We're using this common element within the Comment Drawer as well as
  // Image attachment modal preview.
  const inputElement = (
    <MentionSupportedInput
      onChange={onMessageChange}
      disabled={isLoading || !!isWorkflowBeingMigrated}
      message={message}
      mentions={availableMentions}
      onSubmit={handlePostComment}
      autoFocus
    />
  );

  // const handleMentionSupport = () => {
  //   const certaBotUser = availableMentions.find(
  //     mention => mention.isBot === true
  //   );
  //   const botTag = `~[${certaBotUser?.display}](${certaBotUser?.id})`;
  //   setMessage(prevMessage =>
  //     prevMessage ? `${prevMessage} ${botTag} ` : `${botTag} `
  //   );
  //   //@ts-expect-error
  //   setMentions(prevValue => [
  //     ...prevValue,
  //     { id: certaBotUser?.id, display: certaBotUser?.display }
  //   ]);
  // };
  const renderSwitchOrCommentCareLine = (isAtBottom: boolean) => {
    if (isAtBottom && canRenderSubscribeSwitch) {
      return <CommentCareLine />;
    } else if (!isAtBottom && canRenderSubscribeSwitch) {
      return <SubscribeSwitch />;
    } else if (!isAtBottom && !canRenderSubscribeSwitch) {
      return !isMobileResolution ? (
        <Stack align="center" gap="s2">
          <CommentCareLine />
        </Stack>
      ) : null;
    } else if (isAtBottom && !canRenderSubscribeSwitch) {
      return null;
    }
  };

  return (
    <Stack
      onPaste={handlePaste}
      direction="vertical"
      gap="s4"
      gutter={"s0 s2"}
      className="hide-print"
    >
      {attachment && isModalVisible && (
        <AttachmentViewModal
          visible={isModalVisible}
          attachment={attachment}
          onOk={() => {
            MixPanelActions.track(
              MixPanelEvents.workflowDetailEvents
                .COMMENT_DETAIL_PANE_CLICK_ATTACH_FILE
            );
            handlePostComment();
          }}
          onCancel={handleModalCancel}
          isLoading={isLoading}
          isDisabled={isPostDisabled || isLoading}
        >
          <Stack
            gap="s4"
            direction="vertical"
            css={css`
              position: relative;
              ${attachment
                ? `
                .comments-textarea-new__input,  
                .comments-textarea-new__highlighter {
                    padding-bottom: 44px !important;
                }
              `
                : ``}
            `}
          >
            {inputElement}
            {attachment ? (
              <Tooltip title={attachment.name}>
                <Stack
                  gap="s1"
                  align="center"
                  css={css`
                    padding: 4px 6px;
                    background-color: var(--neutral-20);
                    border-radius: var(--small-border-radius);
                    position: absolute;
                    bottom: 16%;
                    left: 2%;
                  `}
                >
                  <Text
                    variant="p1-bold"
                    color="neutral-100"
                    ellipsis={200}
                    useMaxWidth={true}
                  >
                    {attachment.name}
                  </Text>
                  <Close
                    disabled={isWorkflowBeingMigrated}
                    size={10}
                    color="neutral-70"
                    onClick={handleModalCancel}
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </Stack>
              </Tooltip>
            ) : null}
          </Stack>
        </AttachmentViewModal>
      )}
      <Stack
        gap="s4"
        direction="vertical"
        css={css`
          /* Required due to tooltip wrap */
          .comments-textarea-new {
            width: 100%;
          }
        `}
      >
        <WrapWithMigrationTooltip show={!!isWorkflowBeingMigrated}>
          {inputElement}
        </WrapWithMigrationTooltip>
      </Stack>
      <Stack
        align="center"
        justify="space-between"
        style={{ flexWrap: "wrap" }}
      >
        {renderSwitchOrCommentCareLine(false)}
        <Stack
          gap="s2"
          gutter="s0 s1"
          align="center"
          css={css`
            margin-top: ${isMobileResolution ? "var(--s2)" : undefined};
          `}
        >
          <Upload
            {...uploadProps}
            disabled={isLoading}
            css={css`
              .ant-upload-list-item {
                display: none;
              }
            `}
          >
            <WrapWithMigrationTooltip show={!!isWorkflowBeingMigrated}>
              <Button
                leftIcon={<Attachment />}
                disabled={isWorkflowBeingMigrated}
                aria-label="Attach File"
              />
            </WrapWithMigrationTooltip>
          </Upload>
          <WrapWithMigrationTooltip show={!!isWorkflowBeingMigrated}>
            <Button
              variant={ButtonVariants.FILLED}
              onClick={handlePostComment}
              loading={isLoading}
            >
              {intl.formatMessage({
                id: "stepBodyFormInstances.sendButtonText",
                defaultMessage: "Send"
              })}
            </Button>
          </WrapWithMigrationTooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};
