/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from "@emotion/core";
import type { FC } from "react";
import { useMemo, Fragment } from "react";
import { get as lodashGet } from "lodash-es";
import { Text } from "@certa/blocks/thanos";
import { useChannelContext, useCommentsContext } from "../../comments.context";
import { DEFAULT_INTEGRATION_TITLE_PATH } from "../../comments.constants";
import {
  Ellipsis,
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import {
  hierarchyItemStyles,
  separatorStyles,
  hierarchyFlexItemStyles
} from "./CommentsRedesigned.styles";
import { Input } from "@certa/icons/components/Input";

import { removeHTMLTagsAndTruncate } from "@certa/common/utils/sanitizeHTML";
import { useScreenResolution } from "@certa/common/hooks/useScreenResolution";

const HierarchyBreadcrumbs: FC<{
  containerWidth: string;
  isReadOnly?: boolean;
}> = () => {
  const { extra: commentsExtra, workflowFamily } = useCommentsContext();
  const channel = useChannelContext();
  const { isMobileResolution } = useScreenResolution();

  /**
   * Try to get path from the config first. If value can be derived from
   * path provided in config, mapped to the row data that we have, then we
   * use it. Otherwise we check if we the type matches one of the default paths
   * that we have defined in a constant.
   *
   * @returns {string} title of the integration row
   */
  const integrationTitle = useMemo(() => {
    const { fieldType, target } = channel;

    const defaultPath = DEFAULT_INTEGRATION_TITLE_PATH[String(fieldType)];

    // TODO: Do it without capturing extras, so it works independently
    // (like, this won't work if you open the channel from a deep-link)
    const configPath = lodashGet(
      commentsExtra,
      "integration_row_title_path",
      null
    );

    const titleFromConfigPath =
      configPath && lodashGet(target, configPath, null);
    const titleFromDefaultPath =
      defaultPath && lodashGet(target, defaultPath, null);

    return titleFromConfigPath || titleFromDefaultPath;
  }, [channel, commentsExtra]);

  const {
    stepGroupName: taskLaneName,
    stepName: taskName,
    fieldName
  } = channel;

  const renderFamily =
    workflowFamily &&
    workflowFamily.map((item, index) => {
      const shouldRenderDivider = index !== workflowFamily.length - 1;
      return (
        <Fragment key={index}>
          <Text
            variant={shouldRenderDivider ? "p1-regular" : "p1-bold"}
            color={shouldRenderDivider ? "neutral-70" : "neutral-100"}
            className="hierarchy-item"
          >
            {item.name}
          </Text>
          {shouldRenderDivider && (
            <Fragment>
              <Text variant="p1-regular" color="brand-15" className="separator">
                /
              </Text>
            </Fragment>
          )}
        </Fragment>
      );
    });

  return (
    <Stack
      direction="horizontal"
      align="center"
      gap="8"
      width={isMobileResolution ? "75%" : "80%"}
    >
      {!(workflowFamily && workflowFamily.length) ? (
        <Fragment>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={hierarchyItemStyles(isMobileResolution)}
          >
            <Ellipsis>{taskLaneName || ""}</Ellipsis>
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={separatorStyles}
          >
            /
          </Typography>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={hierarchyItemStyles(isMobileResolution)}
          >
            <Ellipsis>{taskName || ""}</Ellipsis>
          </Typography>
          {fieldName && (
            <Fragment>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={separatorStyles}
              >
                /
              </Typography>
              <Stack
                direction="horizontal"
                align="center"
                justify="center"
                gap="4"
                dangerouslySetClassName={hierarchyFlexItemStyles}
              >
                <Input size={12} />
                <Typography
                  variant={TypographyVariants.LABEL_SM_BOLD}
                  color={TypographyColors.NEUTRAL_800}
                  className={hierarchyItemStyles(isMobileResolution)}
                >
                  <Ellipsis>
                    {fieldName
                      ? removeHTMLTagsAndTruncate(fieldName)
                      : fieldName}
                  </Ellipsis>
                </Typography>
              </Stack>
            </Fragment>
          )}
          {integrationTitle && (
            <Fragment>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={separatorStyles}
              >
                /
              </Typography>
              <Typography
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={hierarchyItemStyles(isMobileResolution)}
              >
                <Ellipsis>{integrationTitle}</Ellipsis>
              </Typography>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>{renderFamily}</Fragment>
      )}
    </Stack>
  );
};

const HierarchyBreadcrumbsComms = ({
  taskLaneName,
  taskName,
  fieldName
}: {
  taskLaneName: string;
  taskName: string;
  fieldName: string;
}) => {
  //TODO check if readOnly prop makes sense here
  const { isMobileResolution } = useScreenResolution();

  return (
    <Stack
      direction="horizontal"
      align="center"
      gap="8"
      width={isMobileResolution ? "75%" : "80%"}
    >
      <Typography
        variant={TypographyVariants.LABEL_SM_BOLD}
        color={TypographyColors.NEUTRAL_800}
        className={hierarchyItemStyles(isMobileResolution)}
      >
        <Ellipsis>{taskLaneName || ""}</Ellipsis>
      </Typography>
      <Typography
        variant={TypographyVariants.LABEL_SM_BOLD}
        color={TypographyColors.NEUTRAL_800}
        className={separatorStyles}
      >
        /
      </Typography>
      <Typography
        variant={TypographyVariants.LABEL_SM_BOLD}
        color={TypographyColors.NEUTRAL_800}
        className={hierarchyItemStyles(isMobileResolution)}
      >
        <Ellipsis>{taskName || ""}</Ellipsis>
      </Typography>
      {fieldName && (
        <Fragment>
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={separatorStyles}
          >
            /
          </Typography>
          <Stack
            direction="horizontal"
            align="center"
            justify="center"
            gap="4"
            dangerouslySetClassName={hierarchyFlexItemStyles}
          >
            <Input size={12} />
            <Typography
              variant={TypographyVariants.LABEL_SM_BOLD}
              color={TypographyColors.NEUTRAL_800}
              className={hierarchyItemStyles(isMobileResolution)}
            >
              <Ellipsis>
                {fieldName ? removeHTMLTagsAndTruncate(fieldName) : fieldName}
              </Ellipsis>
            </Typography>
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};

export { HierarchyBreadcrumbs, HierarchyBreadcrumbsComms };
