import { create } from "zustand";
import { DEFAULT_SEARCH_CATEGORY } from "../GlobalSearchNext.constants";
import type { SearchState } from "../GlobalSearchNext.types";
import type {
  ModelledAPIResponse,
  SearchFilters
} from "@certa/queries/types/globalSearch.types";
import type { WorkflowFamilyResponse } from "@certa/queries/workflows/workflowFamily";
import type { ListInteractionMode } from "@certa/catalyst/hooks/useListInteraction";
import type { Kind, ReportCustomFilterCondition } from "@certa/types";

const emptySearchResults: ModelledAPIResponse.SearchResult[] = [];

const initialFilters: SearchFilters = {
  regionIds: [],
  businessUnitIds: [],
  statusIds: [],
  kindIds: [],
  startDate: undefined,
  endDate: undefined
};

export const initialState = {
  query: "",
  selectedItem: 0,
  interactionMode: "mouse" as ListInteractionMode,
  selectedCategories: DEFAULT_SEARCH_CATEGORY,
  allSearchResults: emptySearchResults,
  isLoading: false,
  isLoadingWorkflowFamily: false,
  workflowFamilyData: new Map(),
  hasSearchQuery: false,
  filters: initialFilters,
  isFilterBarVisible: false,
  isChildDialogOpen: false,
  kinds: [] as Kind[],
  isLoadingKinds: false,
  areAdvancedFiltersVisible: false,
  selectedKindIdForAdvancedFilters: "",
  advancedFilterConditions: [],
  areAdvancedFiltersApplied: false,
  hasPrefilledKinds: false,
  preSelectedKindIds: []
};

export const useSearchStore = create<SearchState>((set, get) => ({
  ...initialState,

  setQuery: query =>
    set({ query, selectedItem: 0, hasSearchQuery: query.trim().length > 0 }),
  setSelectedItem: selectedItem =>
    set(state => ({
      selectedItem:
        typeof selectedItem === "function"
          ? selectedItem(state.selectedItem)
          : selectedItem
    })),
  setSelectedCategories: categories =>
    set({ selectedCategories: categories, selectedItem: 0 }),
  setInteractionMode: interactionMode => set({ interactionMode }),
  setAllSearchResults: (results: ModelledAPIResponse.SearchResult[]) =>
    set({ allSearchResults: results }),
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setWorkflowFamilyData: (data: WorkflowFamilyResponse.FamilyMap) =>
    set({ workflowFamilyData: data }),
  setIsLoadingWorkflowFamily: (isLoading: boolean) =>
    set({ isLoadingWorkflowFamily: isLoading }),
  setFilters: (filters: Partial<SearchFilters>) =>
    set(state => ({
      filters: { ...state.filters, ...filters },
      selectedItem: 0
    })),
  toggleFilterBar: () =>
    set(state => ({ isFilterBarVisible: !state.isFilterBarVisible })),
  resetFilters: () =>
    set(state => ({
      filters: {
        ...initialFilters,
        kindIds: state.filters.kindIds
      },
      selectedItem: 0
    })),
  resetSearch: () =>
    set(state => ({
      selectedItem: 0,
      selectedCategories: DEFAULT_SEARCH_CATEGORY,
      selectedKindIdForAdvancedFilters: "",
      areAdvancedFiltersApplied: false,
      advancedFilterConditions: [],
      filters: {
        ...initialFilters,
        kindIds: state.preSelectedKindIds
      }
    })),
  setIsChildDialogOpen: (isChildDialogOpen: boolean) =>
    set({ isChildDialogOpen }),
  setKinds: (kinds: Kind[]) => set({ kinds }),
  setIsLoadingKinds: (isLoading: boolean) => set({ isLoadingKinds: isLoading }),
  toggleAdvancedFiltersVisibility: () =>
    set(state => ({
      areAdvancedFiltersVisible: !state.areAdvancedFiltersVisible
    })),
  setSelectedKindIdForAdvancedFilters: (kindId: string) =>
    set({
      selectedKindIdForAdvancedFilters: kindId,
      areAdvancedFiltersApplied: kindId !== "",
      advancedFilterConditions: [],
      selectedItem: 0
    }),
  setAdvancedFilterConditions: (conditions: ReportCustomFilterCondition[]) =>
    set({
      advancedFilterConditions: conditions,
      selectedItem: 0
    }),
  setHasPrefilledKinds: (hasPrefilledKinds: boolean) =>
    set({ hasPrefilledKinds }),
  setPreSelectedKindIds: (preSelectedKindIds: string[]) =>
    set({ preSelectedKindIds })
}));
