import { useMemo, type FC, useCallback } from "react";
import type {
  ColumnSubTotalAndAvg,
  SummaryProps,
  ColumnDefinition
} from "../../types";
import { Cell } from "../Cell";
import {
  SUB_TOTAL_LABEL,
  AVERAGE_LABEL,
  GRAND_AVERAGE_LABEL,
  GRAND_SUB_TOTAL_LABEL,
  SUB_TOTAL_INDEX,
  AVERAGE_INDEX
} from "../../constants";
import { INDEX_ZERO } from "@certa/common/constants";

export const Summary: FC<SummaryProps> = props => {
  const {
    cellBaseColors,
    dataCellFormatter,
    columnSubTotalAndAvg,
    yAxisKeys,
    lastRowIndex,
    minValue,
    minMaxDiff,
    dataColumns,
    columnOrder
  } = props;

  const subTotalRow = columnSubTotalAndAvg[
    SUB_TOTAL_INDEX
  ] as ColumnSubTotalAndAvg[typeof SUB_TOTAL_INDEX];
  const averageRow = columnSubTotalAndAvg[
    AVERAGE_INDEX
  ] as ColumnSubTotalAndAvg[typeof AVERAGE_INDEX];
  const yAxisKey = yAxisKeys[INDEX_ZERO];

  const extractDataIndexValuesRecursive = useCallback(
    (column: ColumnDefinition, result: string[] = []) => {
      if ("columns" in column && column.columns) {
        column.columns.forEach(child =>
          extractDataIndexValuesRecursive(child, result)
        );
      } else if ("id" in column) {
        result.push(column.id as string);
      }
      return result;
    },
    []
  );

  const extractDataIndexValuesFromNestedObject = useCallback(
    (columns: ColumnDefinition[]): string[] => {
      return columns.reduce((acc: string[], column) => {
        return acc.concat(extractDataIndexValuesRecursive(column));
      }, []);
    },
    [extractDataIndexValuesRecursive]
  );

  const heatMapXAxisColumnKeys = useMemo(() => {
    return [
      ...extractDataIndexValuesFromNestedObject(dataColumns),
      GRAND_SUB_TOTAL_LABEL,
      GRAND_AVERAGE_LABEL
    ];
  }, [dataColumns, extractDataIndexValuesFromNestedObject]);

  // order heatMapXAxisColumnKeys according to the occurence id in columnOrder
  const orderedColumns = useMemo(() => {
    const uniqueSet = new Set(heatMapXAxisColumnKeys);
    const validOrderedItems = columnOrder.filter(id => uniqueSet.has(id));
    const seenItems = new Set(validOrderedItems);
    const remainingItems = heatMapXAxisColumnKeys.filter(
      id => !seenItems.has(id)
    );

    return [...validOrderedItems, ...remainingItems];
  }, [columnOrder, heatMapXAxisColumnKeys]);

  if (!subTotalRow || !averageRow) {
    return null;
  }

  return (
    <>
      <tr data-row-key={SUB_TOTAL_LABEL}>
        <td colSpan={yAxisKeys.length}>{subTotalRow[yAxisKey]}</td>
        {orderedColumns
          .filter(columnHeading => !yAxisKeys.includes(columnHeading))
          .map(columnHeading => (
            <td key={columnHeading} className="summary">
              <Cell
                cellBaseColors={cellBaseColors}
                dataCellFormatter={dataCellFormatter}
                cellValue={subTotalRow[columnHeading]}
                columnHeaderKey={subTotalRow[yAxisKey] as string}
                rowRecord={subTotalRow}
                rowIndex={lastRowIndex + 1}
                ratio={
                  ((subTotalRow[columnHeading] as number) - minValue) /
                  minMaxDiff
                }
              />
            </td>
          ))}
      </tr>
      <tr data-row-key={AVERAGE_LABEL}>
        <td colSpan={yAxisKeys.length}>{averageRow[yAxisKey]}</td>
        {orderedColumns
          .filter(columnHeading => !yAxisKeys.includes(columnHeading))
          .map(columnHeading => (
            <td
              colSpan={columnHeading === GRAND_AVERAGE_LABEL ? 2 : 1}
              key={columnHeading}
              className="summary"
            >
              <Cell
                cellBaseColors={cellBaseColors}
                dataCellFormatter={dataCellFormatter}
                cellValue={averageRow[columnHeading]}
                columnHeaderKey={averageRow[yAxisKey] as string}
                rowRecord={averageRow}
                rowIndex={lastRowIndex + 1}
                ratio={
                  ((averageRow[columnHeading] as number) - minValue) /
                  minMaxDiff
                }
              />
            </td>
          ))}
      </tr>
    </>
  );
};
