import { Loader } from "@certa/blocks";
import {
  Button,
  ButtonSizes,
  ButtonTypes
} from "@certa/catalyst/components/Button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemSizes,
  DropdownMenuItemTypes,
  DropdownMenuTrigger
} from "@certa/catalyst/components/Dropdown";

import { showAlertDialog } from "@certa/catalyst/components/AlertDialog";
import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";

import { Stack } from "@certa/catalyst/layouts/Stack";
import { Bell2 } from "@certa/icons/components/Bell2";
import { Dots } from "@certa/icons/components/Dots";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import {
  type ObjectDetails,
  type SubscriptionComms
} from "@certa/queries/queries/comms";
import { useIntl } from "react-intl";
import { useRemoveSubscriptionOptUpdateComms } from "../../hooks/useRemoveSubscriptionsOptUpdateComms";
import {
  bellIconCustomStyles,
  notificationsListItemCustomStyles,
  subscriptionItemTitleStyles
} from "./notificationsItem.styles";
import { forwardRef, useImperativeHandle } from "react";
import type { TaskDetails } from "@certa/queries/types/workflow.types";
import type { QueryStatus } from "@certa/queries/types/common.types";

export type SubscriptionItemHandle = {
  getDetails: () => {
    processDetails: TaskDetails | undefined;
    processDetailsStatus: QueryStatus;
  };
};

type SubscriptionItemProps = {
  subscription: SubscriptionComms;
  isObjectDetailLoading: boolean;
  objectDetails: ObjectDetails | null;
};

// This file is identical to SubscriptionItem.tsx.
// It uses comms endpoints tho instead of krypton.
// Once we are live with comms, we can remove the krypton version.
const SubscriptionItemComms = forwardRef<
  SubscriptionItemHandle,
  SubscriptionItemProps
>(({ subscription, isObjectDetailLoading, objectDetails }, ref) => {
  const intl = useIntl();

  const removeSubscription = useRemoveSubscriptionOptUpdateComms({
    objectId: subscription.conversation.objectId,
    objectType: subscription.conversation.objectType
  });

  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(subscription?.conversation?.kryptonWorkflowId);
  const workflowType = processDetails?.definition?.name;
  const isDashboardSubscription = workflowType === "report_dashboard";
  const isReportSubscription = workflowType === "report_workflow";
  const isLoading = isObjectDetailLoading || processDetailsStatus === "loading";

  // Expose process details to parent component via imperative handle
  useImperativeHandle(
    ref,
    () => ({
      getDetails: () => {
        return {
          processDetails,
          processDetailsStatus
        };
      }
    }),
    [processDetails, processDetailsStatus]
  );

  const dashboardText = intl.formatMessage({
    id: "notifications.dashboard",
    defaultMessage: "Dashboard"
  });

  const reportText = intl.formatMessage({
    id: "notifications.report",
    defaultMessage: "Report"
  });
  const unSubscribeMessage = intl.formatMessage({
    id: "mentions.unsubscribe.confirm.okBtn",
    defaultMessage: "Unsubscribe"
  });

  const onUnsubscribe = (subscription: SubscriptionComms) => {
    const onConfirmUnsubscribe = () => {
      const threadId = subscription?.conversation?.kryptonId;
      if (threadId) {
        removeSubscription({
          threadId,
          enable: false
        });
      }
    };

    showAlertDialog({
      title: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.title",
        defaultMessage: "Unsubscribe from this thread"
      }),
      children: intl.formatMessage({
        id: "mentions.unsubscribe.confirm.content",
        defaultMessage:
          "You will not receive any notifications for future comments unless explicitly tagged. "
      }),
      onPrimaryAction: onConfirmUnsubscribe,
      primaryActionText: unSubscribeMessage,
      type: "error"
    });
  };

  const { title, subtitle, workflowName } =
    getDetailsToShowFromSubscriptionComms({
      subscription,
      isDashboardSubscription,
      isReportSubscription,
      dashboardText,
      reportText,
      objectDetails,
      processName: processDetails?.definition?.name
    });

  return (
    <Stack
      key={subscription.id}
      data-testid="subscription-list-item"
      direction="horizontal"
      justify="center"
      align="center"
      dangerouslySetClassName={notificationsListItemCustomStyles}
    >
      <Stack
        direction="horizontal"
        align="center"
        justify="space-between"
        width="100%"
        gap="8"
      >
        <Stack
          direction="horizontal"
          align="center"
          justify="space-between"
          gap="8"
          overflow="hidden"
        >
          <Stack
            direction="horizontal"
            align="center"
            justify="center"
            dangerouslySetClassName={bellIconCustomStyles}
          >
            <Bell2 />
          </Stack>

          {isLoading ? (
            <Loader size="extra-small" />
          ) : (
            <Stack direction="vertical" overflow="hidden">
              <Typography
                as="p"
                variant={TypographyVariants.LABEL_SM_BOLD}
                color={TypographyColors.NEUTRAL_800}
                className={subscriptionItemTitleStyles}
              >
                {title}
              </Typography>
              <Typography
                as="p"
                variant={TypographyVariants.LABEL_SM}
                color={TypographyColors.NEUTRAL_700}
              >
                {subtitle}
              </Typography>
              {workflowName && (
                <Typography
                  as="p"
                  variant={TypographyVariants.LABEL_SM}
                  color={TypographyColors.NEUTRAL_700}
                >
                  <Ellipsis>{workflowName}</Ellipsis>
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
        <DropdownMenu shouldStopEventPropagation>
          <DropdownMenuTrigger>
            <Button
              aria-label="unsubscribe"
              leftIcon={<Dots />}
              size={ButtonSizes.DEFAULT}
              type={ButtonTypes.ICON}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent sideOffset={-2}>
            <DropdownMenuItem
              value="unsubscribe"
              onSelect={() => onUnsubscribe(subscription)}
              size={DropdownMenuItemSizes.BIG}
              type={DropdownMenuItemTypes.DEFAULT}
            >
              <Typography variant={TypographyVariants.BODY}>
                {unSubscribeMessage}
              </Typography>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Stack>
    </Stack>
  );
});

const getDetailsToShowFromSubscriptionComms = ({
  subscription,
  isDashboardSubscription,
  isReportSubscription,
  dashboardText,
  reportText,
  objectDetails,
  processName
}: {
  subscription: SubscriptionComms;
  isDashboardSubscription: boolean;
  isReportSubscription: boolean;
  dashboardText: string;
  reportText: string;
  objectDetails: ObjectDetails | null | undefined;
  processName: string | undefined;
}) => {
  //TODO: get proper hierarchy paths and render details
  if (isDashboardSubscription || isReportSubscription) {
    return {
      title: processName,
      subtitle: isDashboardSubscription ? dashboardText : reportText,
      workflowName: null // we don't show workflow name for dashboard and report subscriptions
    };
  }
  if (objectDetails?.type === "field") {
    return {
      title: objectDetails.fieldName,
      subtitle: `${objectDetails.stepGroupName}: ${objectDetails.stepName}`,
      workflowName: processName
    };
  }

  if (objectDetails?.type === "step") {
    return {
      title: objectDetails.stepName,
      subtitle: objectDetails.stepGroupName,
      workflowName: processName
    };
  }

  // default case
  return {
    title: subscription?.conversation?.name,
    subtitle: "",
    workflowName: processName
  };
};

export { SubscriptionItemComms };
