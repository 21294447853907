import React, { useRef, useEffect, forwardRef, useMemo } from "react";

import styles from "./Checkbox.module.css";

import { classNames, generateFieldId, mergeRefs } from "../../utils/common";

export type CheckboxProps = {
  id?: string | number;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (
    checked: boolean,
    evt: React.ChangeEvent<HTMLInputElement>
  ) => void;
  error?: string;
  required?: boolean;
  "aria-describedby"?: string;
} & CheckboxLabelProps;

type CheckboxLabelProps =
  | { label: string; "aria-label"?: never }
  | { label?: never; "aria-label": string };

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      id,
      name,
      label: checkboxLabel,
      value,
      checked: isChecked,
      disabled: isDisabled = false,
      indeterminate: isIndeterminate = false,
      onChange,
      error,
      required: isRequired = false,
      "aria-label": ariaLabel,
      "aria-describedby": ariaDescribedBy
    } = props;

    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = isIndeterminate;
      }
    }, [isIndeterminate]);

    const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(evt.target.checked, evt);
    };

    const checkboxId = useMemo(() => {
      const currentCheckboxId = id?.toString();

      if (!currentCheckboxId) {
        return generateFieldId("checkbox");
      }

      return currentCheckboxId;
    }, [id]);

    const ariaDescribedById = ariaDescribedBy
      ? ariaDescribedBy
      : !!error
        ? id + "-error"
        : undefined;

    return (
      <div
        className={classNames({
          [styles.catalystCheckboxContainer]: true,
          [styles.catalystCheckboxDisabled]: isDisabled,
          [styles.catalystCheckboxError]: !!error
        })}
      >
        <input
          id={checkboxId}
          name={name}
          ref={mergeRefs([checkboxRef, ref])}
          className={classNames({
            [styles.catalystCheckbox]: true
          })}
          type="checkbox"
          disabled={isDisabled}
          onChange={handleOnChange}
          checked={isChecked}
          required={isRequired}
          value={value}
          aria-invalid={!!error}
          aria-describedby={ariaDescribedById}
          aria-label={ariaLabel}
        />
        {/*
          Do not add the input inside the label, it will break the accessibility
          by not reading the group label when used in group role in Firefox.
        */}
        {checkboxLabel && <label htmlFor={checkboxId}>{checkboxLabel}</label>}
      </div>
    );
  }
);
