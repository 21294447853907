//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Checkmark = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Checkmark"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2C11.3118 2 14 4.68823 14 8C14 11.3118 11.3118 14 8 14C4.68823 14 2 11.3118 2 8C2 4.68823 4.68823 2 8 2ZM6.88608 8.81662L10.7472 4.95553L11.8611 6.0842L6.88601 11.0446L4.1535 8.31205L5.26743 7.18338L6.88595 8.81712L6.88608 8.81662Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
