import type { FC } from "react";

import { useCallback, useMemo } from "react";
import { merge as lodashMerge, cloneDeep as lodashCloneDeep } from "lodash-es";

import { NoDataInReports } from "../noDataInReports/NoDataInReports";

import { noChartData } from "../../constants";
import { useSetTotalCount } from "../../hooks/useSetTotalCount";
import { getCharComponent } from "../../utils/getChartComponent";
import {
  getDefaultXAxisLabel,
  getDefaultYAxisLabel
} from "../../utils/chartUtils";
import {
  getDataTypesFromMetrics,
  otherChartConfigDefaultValue,
  getIsStackedBarChart,
  INDEX_ZERO,
  PROCESS_SWIMLANES_TAG
} from "@certa/common/constants";
import type {
  AggregateResponse,
  ProcessCycleChartAPIResponseType
} from "@certa/common/modelCreators";
import {
  stepCycleChartModelCreator,
  aggregateModelCreator
} from "@certa/common/modelCreators";
import type {
  MetricAttributes,
  ChartConfig,
  ChartIdentifiers,
  ChartClickEvent,
  ActiveXIdentifier
} from "@certa/common/types";

export type ChartViewProps = {
  apiData: AggregateResponse | ProcessCycleChartAPIResponseType;
  chartType: ChartIdentifiers;
  onChartClick?: (data: ChartClickEvent) => void;
  xActiveIdentifier?: ActiveXIdentifier;
  chartConfig?: ChartConfig;
  setChartConfig?: (chartConfig: ChartConfig) => void;
  setTotalCount?: (count?: number) => void;
  isLoading: boolean;
  error: string | undefined;
  isAnimationActive?: boolean;
  chartHeight?: number;
};

export const ChartView: FC<ChartViewProps> = props => {
  const {
    onChartClick,
    xActiveIdentifier,
    chartConfig,
    chartType,
    error,
    isAnimationActive = true,
    chartHeight
  } = props;

  const {
    chartData,
    newOtherConfig,
    hasData,
    xAxisDataLabels,
    yAxisKey,
    onReorder
  } = useChartView(props);

  const ChartComponent = getCharComponent(chartType);

  if (!hasData) {
    return <NoDataInReports error={error} />;
  }

  return (
    <ChartComponent
      chartData={chartData}
      onClick={onChartClick}
      height={chartHeight}
      xActiveIdentifier={xActiveIdentifier}
      otherConfig={newOtherConfig}
      isCycleTimeReport={!!chartConfig?.isCycleTimeReport}
      xAxisDataLabels={xAxisDataLabels}
      isAnimationActive={isAnimationActive}
      yAxisKey={yAxisKey}
      onReorder={onReorder}
    />
  );
};

const useChartView = (props: ChartViewProps) => {
  const {
    chartConfig,
    chartType,
    apiData,
    setTotalCount,
    isLoading,
    setChartConfig
  } = props;

  const chartData = useConvertToChartData({
    apiData,
    chartConfig,
    chartType
  });
  const isStackedBarChart = getIsStackedBarChart(chartConfig, chartType);
  const xAxis = chartConfig?.["x-axis"];
  const xAxisKey = chartData?.xAxisKey;

  const xAxisDataLabels = useMemo(() => {
    // xAxis will be [] or undefined for cycltime report at that time,
    // at that time xAxisKey will provide the correct label.
    const defaultxAxis = xAxisKey ? [{ label: xAxisKey }] : [];
    const xAxisData =
      isStackedBarChart && xAxis?.[INDEX_ZERO]
        ? [xAxis?.[INDEX_ZERO]]
        : xAxis?.length
          ? xAxis
          : defaultxAxis;
    return xAxisData?.map(x => x.label);
  }, [xAxis, isStackedBarChart, xAxisKey]);

  const newOtherConfig = lodashMerge(
    lodashCloneDeep(otherChartConfigDefaultValue),
    chartConfig?.otherConfig
  );

  if (newOtherConfig?.isStackedBarChart === undefined) {
    newOtherConfig.isStackedBarChart = getIsStackedBarChart(
      chartConfig,
      chartType
    );
  }
  // updating old labelOutputType to labelOutputTypes
  if (
    newOtherConfig?.xAxis &&
    !newOtherConfig?.xAxis?.labelOutputTypes &&
    newOtherConfig?.xAxis?.labelOutputType
  ) {
    newOtherConfig.xAxis.labelOutputTypes = [
      newOtherConfig.xAxis.labelOutputType
    ];
  }

  newOtherConfig.xAxisLabel =
    newOtherConfig?.xAxisLabel ??
    getDefaultXAxisLabel(
      chartConfig?.["x-axis"],
      chartConfig?.otherConfig?.cycleTimePath,
      newOtherConfig.isStackedBarChart,
      newOtherConfig.isGroupedBarChart
    );

  newOtherConfig.yAxisLabel =
    newOtherConfig?.yAxisLabel ??
    getDefaultYAxisLabel(
      chartConfig?.["y-axis"],
      chartConfig?.isCycleTimeReport
    );

  // for grouped bar chart
  const yAxis = chartConfig?.["y-axis"]?.[INDEX_ZERO];
  const yAxisKey = typeof yAxis === "string" ? yAxis : yAxis?.label;

  useSetTotalCount({ chartData, isLoading, setTotalCount });

  const onReorder = useCallback(
    (newOrder: string[]) => {
      if (setChartConfig) {
        setChartConfig(
          lodashMerge(chartConfig, { otherConfig: { dataOrder: newOrder } })
        );
      }
    },
    [chartConfig, setChartConfig]
  );

  return {
    xAxisDataLabels,
    chartData,
    newOtherConfig,
    hasData: !!chartData?.data?.length,
    yAxisKey,
    onReorder: setChartConfig ? onReorder : undefined
  };
};

type UseConvertToChartDataProps = Pick<
  ChartViewProps,
  "apiData" | "chartConfig" | "chartType"
> & {
  convertToChartData?: boolean;
};

export const useConvertToChartData = (props: UseConvertToChartDataProps) => {
  const { apiData, chartConfig, chartType } = props;

  // The output of this function is utilised in StackedBarAndCycleTimeCustomColorField's useEffect
  // to update the colors object of the chartConfig.
  // Because adding the chartConfig directly to the useMemo dependency array would result in an infinite loop,
  // the necessary data is extracted from the chartconfig outside of the useMemo
  const metrics = chartConfig?.["y-axis"];
  const groupByLength = chartConfig?.["x-axis"]?.length;

  const yAxisOtherConfig = chartConfig?.otherConfig?.yAxis;
  const isCycleTimeReport = !!chartConfig?.isCycleTimeReport;
  const isStackedBarChart = getIsStackedBarChart(chartConfig, chartType);
  const isGroupedBarChart = chartConfig?.otherConfig?.isGroupedBarChart;
  const isSwimlaneLevelCycleTime =
    chartConfig?.otherConfig?.cycleTimePath === undefined
      ? !!chartConfig?.["x-axis"]?.[INDEX_ZERO]?.stepgroups?.length
      : chartConfig.otherConfig.cycleTimePath === PROCESS_SWIMLANES_TAG;

  const reportChartData = useMemo(() => {
    if (
      isCycleTimeReport ||
      !metrics?.length ||
      typeof metrics?.[INDEX_ZERO] === "string"
    )
      return noChartData;
    const dataTypes = getDataTypesFromMetrics(metrics as MetricAttributes[]);

    return aggregateModelCreator(
      dataTypes,
      isStackedBarChart && !isGroupedBarChart,
      chartType,
      yAxisOtherConfig,
      groupByLength
    )(apiData as AggregateResponse);
  }, [
    isCycleTimeReport,
    metrics,
    isStackedBarChart,
    isGroupedBarChart,
    chartType,
    yAxisOtherConfig,
    groupByLength,
    apiData
  ]);

  const reportsWithCycleData = useMemo(() => {
    if (!isCycleTimeReport) return noChartData;

    return stepCycleChartModelCreator(
      apiData as ProcessCycleChartAPIResponseType,
      isSwimlaneLevelCycleTime,
      metrics
    );
  }, [apiData, isSwimlaneLevelCycleTime, isCycleTimeReport, metrics]);
  return !chartConfig?.isCycleTimeReport
    ? reportChartData
    : reportsWithCycleData;
};
