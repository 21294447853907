//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Envelope = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Envelope"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.6669 2H1.33312C0.596864 2 0 2.67152 0 3.49982V12.5002C0 12.8982 0.140626 13.2793 0.390624 13.5605C0.640624 13.8425 0.98 14 1.33312 14H14.6669C15.02 14 15.3594 13.8425 15.6094 13.5605C15.8594 13.2793 16 12.8982 16 12.5002V3.49982C16 3.10184 15.8594 2.72073 15.6094 2.43947C15.3594 2.15751 15.02 2 14.6669 2ZM8 8.54002L2.4 3.49982H13.6L8 8.54002ZM8 10.4603L9.52 9.09552L13.52 12.5009L2.44688 12.5002L6.48 9.09482L8 10.4603ZM14.6669 4.46034V11.5629L10.5938 8.12798L14.6669 4.46034ZM5.40688 8.12726L1.33312 11.5629V4.45962L5.40688 8.12726Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
