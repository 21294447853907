/**
 * NOTE: Import ordering is important in this component
 * to maintain correct CSS insertion order.
 *
 * Please follow the below order:
 * 1. Global CSS imports
 * 2. Third-party packages
 * 3. Other imports
 */

// Global CSS imports
import "./css/App.css";
import "antd/dist/antd.css";
/**
 * Importing `react-flow-renderer` and `@certa/cataylst` as side effect imports
 * so that all the global styles of the library are inserted before Emotion CSS
 * styles being used throughout platform. This is so that the library styles can
 * be overridden by Emotion styles.
 */
import "react-flow-renderer";
import "@certa/catalyst/styles/index.css";

// Third-party packages
/**
 * It's better to import third-party packages before other imports because
 * third-party package may contain CSS imports as side effects which we may
 * want to override with our own styles.
 */
import * as React from "react";
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router";
import * as Sentry from "@sentry/react";

// Other imports
import { App } from "./js/App";
import { initializeMixPanel } from "./js/_helpers/mixpanel";
import { tenant, envTag } from "./config";
import { getHostName } from "@certa/network";
import { isDev } from "@certa/common/utils/isDev";

if (import.meta.env.REACT_APP_MIXPANEL_PACKAGE_TOKEN) {
  initializeMixPanel();
}

if (import.meta.env.REACT_APP_ENABLE_SENTRY && !isDev) {
  /**
   * These are the routes that we want to monitor for performance.
   * Pageload/Navigation/Custom transactions for these routes will be sent to Sentry.
   */
  const routesToMonitorPerformance = [
    /^\/login(?:\/.*|\?.*)?$/,
    /^\/process(?:\/.*|\?.*)?$/,
    /^\/home(?:\/.*|\?.*)?$/,
    /^\/tasks(?:\/.*|\?.*)?$/,
    /^\/dashboards(?:\/.*|\?.*)?$/,
    /^\/dashboard(?:\/.*|\?.*)?$/,
    /^\/report(?:\/.*|\?.*)?$/,
    /^\/search(?:\/.*|\?.*)?$/,
    /^\/settings(?:\/.*|\?.*)?$/,
    /^\/studio(?:\/.*|\?.*)?$/,
    /^\/records(?:\/.*|\?.*)?$/
  ];

  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: envTag,

    integrations: [
      // This enables automatic instrumentation
      // The React Router instrumentation uses the React Router library to create pageload/navigation transactions
      // to ensure you collect meaningful performance data about the health of your page loads and associated requests.
      // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],

    // What fraction of transactions to send for performance monitoring
    tracesSampler: ({ name, parentSampled, attributes }) => {
      // Low usage routes
      if (
        name.match(/^\/tasks(\/(.+))?\/?$/) ||
        name.match(/^\/report(\/(.+))?\/?$/) ||
        name.match(/^\/settings(\/(.+))?\/?$/) ||
        name.match(/^\/studio(\/(.+))?\/?$/) ||
        name.match(/^\/records(\/(.+))?\/?$/)
      ) {
        return 1;
      }

      // Medium usage routes
      if (
        name.match(/^\/home(\/(.+))?\/?$/) ||
        name.match(/^\/dashboard(\/(.+))?\/?$/) ||
        name.match(/^\/dashboards(\/(.+))?\/?$/)
      ) {
        if (attributes?.["sentry.op"] === "navigation") {
          return 0.75;
        } else {
          return 1;
        }
      }

      // Heavy usage routes
      if (name.match(/^\/search(\/(.+))?\/?$/)) {
        // Navigation events are more frequent than pageload events
        if (attributes?.["sentry.op"] === "navigation") {
          return 0.5;
        } else {
          return 1;
        }
      }

      if (name.match(/^\/process(\/(.+))?\/?$/)) {
        if (attributes?.["sentry.op"] === "navigation") {
          return 0.03;
        } else {
          return 0.2;
        }
      }

      // Continue trace decision, if there is any parentSampled information
      if (typeof parentSampled === "boolean") {
        return parentSampled;
      }

      // Else, use default sample rate
      return 0.2;
    },

    // These origins will have `sentry-trace` and `baggage` headers attached for tracing.
    tracePropagationTargets: [RegExp(`https://[a-zA-Z0-9-]+.${getHostName()}`)],

    // We are using this to supress some errors from being sent to sentry
    ignoreErrors: [
      // Ignores both
      // "ResizeObserver loop limit" (deprecated) and
      // "ResizeObserver loop completed with undelivered notifications" errors
      /^ResizeObserver loop/,
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource",

      // This is an open bug on chromium project, which results in accidental closure of IndexDB.
      // Ignoring this error, because it is eating up our sentry quota.
      // Chromium Project Link: https://bugs.chromium.org/p/chromium/issues/detail?id=146284
      // DexieJS Thread Link: https://github.com/dexie/Dexie.js/issues/613#issuecomment-841608979
      "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",

      // Suppress errors that occur when accessing the web app after deployment
      // due to changed chunk names. These errors are expected and harmless
      // since the app will automatically reload with the new chunks.
      /^TypeError: error loading dynamically imported module.*/,
      "TypeError: Importing a module script failed.",

      // DatabaseClosed error is a unhandled Dexie error which can happen due to the following
      // 1. Browser Storage Limits
      // 2. Manual Database Closure using db.close() and then performing an operation
      // 3. Multiple tab scenarios: If one tab is performing an version upgrade. Ongoing operations in other tabs will fail with DatabaseClosedError
      "DatabaseClosedError: DatabaseClosedError Database has been closed"
    ],

    // This is a callback that will be called before sending the event to sentry
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (
        error &&
        (error as Error).message &&
        (error as Error).message.match(/UnhandledRejection 403/i)
      ) {
        return null;
      }

      // Handle Response objects in Sentry
      if (error instanceof Response) {
        // Extract useful information from the Response
        const errorInfo = {
          url: error.url,
          status: error.status,
          statusText: error.statusText
        };

        event.extra = {
          ...event.extra,
          responseDetails: errorInfo
        };
      }
      return event;
    },

    beforeSendTransaction(event, hint) {
      // Ignoring following transactions so that we can have more accurate web vitals
      if (event.transaction?.match(/^\/login(\/(.+))?\/?$/)) {
        // Ignore login transactions if the user is authenticated
        if (event.tags?.authenticated === true) {
          return null;
        }
      } else {
        // Ignore non-login transactions if the user is not authenticated
        if (event.tags?.authenticated === false) {
          return null;
        }
      }

      if (
        routesToMonitorPerformance.some(route =>
          event.transaction?.match(route)
        )
      ) {
        return event;
      }

      return null;
    }
  });

  const scope = Sentry.getCurrentScope();
  scope.setTag("tenant", tenant);
}

const container = document.getElementById("root");

if (import.meta.env.REACT_APP_USE_REACT_17 === "true") {
  // eslint-disable-next-line no-console
  console.info("Using React 17");
  render(<App />, container);
} else {
  // eslint-disable-next-line no-console
  console.info("Using React 18");
  const root = createRoot(container!);
  root.render(<App />);
}
