/*
    Purpose of this File:
    - Here we can write different fn which will internally use some other library like moment/date-fns to format date.
    - We can use this fn in our code to format date.

    Advantage:
    - We can change the library without changing the code.
    - We can write our own fn to format date.
    - We can easily test all fn in these files without touching the business logic on other part of code.
    - We can easily mock these fn in our test cases.

*/
import { format } from "date-fns";
import moment from "moment";

export const formatDate = (date: Date, formatStr: string) => {
  return format(date, formatStr);
};

export const isValidDate = (date: any) => {
  return !!(date && moment(date).isValid());
};
