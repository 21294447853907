import React, { useContext } from "react";
import type {
  CommentChannel,
  CommentThread,
  ConversationThread,
  WorkflowFamily
} from "@certa/types";
import type { ConversationCommsModel } from "@certa/queries/queries/comms";

type CommentsContextState = {
  onStepClick: (
    workflowId: number,
    props: {
      groupId?: number;
      stepId?: number;
      fieldId?: number;
    }
  ) => void;
  isMultiChannel: boolean;
  isReadOnly: boolean;
  defaultThreadId?: number;
  extra?: any;
  header?: React.ReactChild;
  activeChannel: null | CommentChannel;
  setActiveChannel: (channel: null | CommentChannel) => void;
  threadsComms?: ConversationCommsModel;
  threadsCommsStatus?: string;
  activeConversationThreadComms: ConversationThread | null;
  setActiveConversationThreadComms: (
    conversationThread: ConversationThread
  ) => void;
  workflowFamily?: WorkflowFamily;
};

/**
 * RoTContext holds the entire level config for all levels, as
 * well as the boolean to make the entire tree disabled
 */
export const CommentsContext = React.createContext<CommentsContextState>({
  onStepClick: () => {},
  isMultiChannel: false,
  isReadOnly: false,
  activeChannel: null,
  activeConversationThreadComms: null,
  setActiveChannel: () => {},
  setActiveConversationThreadComms: () => {}
});

export const ChannelContext = React.createContext<CommentChannel>(
  {} as CommentChannel
);

export const ThreadContext = React.createContext<CommentThread>(
  {} as CommentThread
);

export const useCommentsContext = () => useContext(CommentsContext);

export const useChannelContext = () => useContext(ChannelContext);

export const useThreadContext = () => useContext(ThreadContext);
