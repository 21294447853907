//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Gear = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Gear"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3462)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6994 6.71458L13.6837 6.34821C13.5618 5.92058 13.378 5.55422 13.1948 5.18785L14.3552 3.47725C14.4771 3.29407 14.4771 3.11088 14.3552 2.98835L12.9504 1.64419C12.8285 1.52228 12.6447 1.52228 12.5227 1.64419L10.8734 2.80455C10.4458 2.62137 10.0795 2.43818 9.65182 2.31565L9.28545 0.299988C9.28545 0.116812 9.10228 -0.0057373 8.91909 -0.0057373H7.02538C6.84221 -0.0057373 6.71966 0.116175 6.71966 0.299988L6.35329 2.31565C5.92566 2.43756 5.5593 2.62137 5.13167 2.80455L3.48236 1.64419C3.36045 1.52228 3.17664 1.52228 3.05473 1.64419L1.64993 2.98835C1.58866 3.11026 1.52802 3.29407 1.64993 3.47725L2.81029 5.12656C2.62711 5.55419 2.44392 5.92054 2.32138 6.34818L0.305726 6.71455C0.12255 6.71455 0 6.83646 0 7.02027V8.91397C0 9.09715 0.121913 9.28034 0.305726 9.28034L2.32138 9.64671C2.4433 10.0743 2.62711 10.4407 2.81029 10.8683L1.64993 12.5176C1.52802 12.6395 1.52802 12.8234 1.64993 12.9453L2.99409 14.3501C3.116 14.472 3.29981 14.472 3.48299 14.3501L5.13229 13.1897C5.55993 13.3729 5.92628 13.5561 6.35392 13.6786L6.72028 15.6943C6.72028 15.8774 6.8422 16 7.02601 16H8.91971C9.10289 16 9.28608 15.8781 9.28608 15.6943L9.65245 13.6786C10.0801 13.5567 10.4464 13.3729 10.8741 13.1897L12.5847 14.3501C12.7066 14.472 12.8904 14.472 13.0123 14.3501L14.3565 12.9453C14.4784 12.8234 14.4784 12.6395 14.4177 12.5176L13.1961 10.807C13.3793 10.4407 13.5625 10.0737 13.685 9.64668L15.7007 9.28031C15.8838 9.28031 16.0064 9.09713 16.0064 8.91394V7.02024C16.0064 6.83706 15.8845 6.71451 15.7007 6.71451L15.6994 6.71458ZM8.00263 4.39323C10.0183 4.39323 11.6681 5.98122 11.6681 7.99753C11.6681 10.0132 10.0188 11.663 8.00263 11.663C5.98697 11.663 4.33719 10.0137 4.33719 7.99753C4.33719 5.98187 5.98649 4.39323 8.00263 4.39323Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3462">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
