import type { HashMap } from "@certa/types/src/common";
import { uniq } from "lodash-es";

/**
 * Transforms an array into HashMap with given prop as the key,
 * and the rest of the object as the value.
 * input:
 *   arr: [
 *          { name: "A", permissions: ["p1", "p2"], enabled: true  },
 *          { name: "B", permissions: ["p1", "p3"], enabled: false }
 *        ]
 *   keyProp: "name"
 *
 * output:
 *   {
 *      "A": { name: "A", permissions: ["p1", "p2"], enabled: true  },
 *      "B": { name: "B", permissions: ["p1", "p3"], enabled: false }
 *   }
 * @param arr
 * @param keyProp
 * @returns
 */
export const arrayToHashmap = <T extends HashMap>(
  arr: T[],
  keyProp: keyof T
): HashMap<T> => (arr || []).reduce((a, c) => ({ ...a, [c[keyProp]]: c }), {});

export function dedupeArray<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function convertArrayToMap<T, K extends keyof T>(
  array: T[],
  key: K
): Map<T[K], T> {
  const map = new Map<T[K], T>();

  for (const item of array) {
    const itemKey = item[key];
    map.set(itemKey, item);
  }

  return map;
}

type Key = number | string;
/**
 * Convert an array to a string using a specified delimiter.
 *
 * @param {Array} array - The array to be converted to a string.
 * @param {string} delimiter - The delimiter to use between array elements.
 * @returns {string} The string representation of the array.
 */
export function arrayToString(
  array: Key[] | null = [],
  delimiter = "~"
): string {
  if (!array) return "";
  return array.join(delimiter);
}

/**
 * Convert a string to an array using a specified delimiter.
 *
 * @param {string} str - The string to be converted to an array.
 * @param {string} delimiter - The delimiter used to separate elements in the string.
 * @returns {Array} The array representation of the string.
 */
export function stringToArray(str: string = "", delimiter = "~"): string[] {
  if (str === "") return [];
  return str.split(delimiter);
}

export const insertItemAtIndex = <T extends any>({
  array,
  item,
  index
}: {
  array: T[];
  item: T;
  index?: number;
}): T[] => {
  // Insert into the last index if index is not provided or invalid
  if (index === undefined || index < 0 || index > array.length) {
    return [...array, item];
  } else {
    const newArr = [...array];
    newArr.splice(index, 0, item);
    return newArr;
  }
};

/** Reorder item within the same array */
export const reorderItems = <T extends unknown>({
  array,
  item,
  targetIndex
}: {
  array: T[];
  item: T;
  targetIndex: number;
}): T[] => {
  const newArr = [...array];
  const sourceIndex = newArr.indexOf(item);
  if (sourceIndex === -1) return newArr;

  newArr.splice(sourceIndex, 1);
  newArr.splice(targetIndex, 0, item);

  return newArr;
};

/** Removes item from source array and move to target array */
export const moveItemToAnotherArray = <T extends unknown>({
  sourceArr,
  item,
  targetArr,
  targetIndex
}: {
  sourceArr: T[];
  targetArr: T[];
  item: T;
  targetIndex: number;
}): [sourceArr: T[], targetArr: T[]] => {
  const newSourceArr = sourceArr.filter(i => i !== item);
  const newTargetArr = insertItemAtIndex({
    array: targetArr,
    item,
    index: targetIndex
  });

  return [newSourceArr, newTargetArr];
};

/**
 * Object.keys() type-casted to get correct typed keys.
 * @param obj - The object to get keys from.
 * @returns An array of keys from the object.
 */
export const typedKeys = <T extends object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

/**
 * Get unique array from two arrays
 * @param array1 - The first array
 * @param array2 - The second array
 * @returns A unique array from the two arrays
 */
export const getUniqueArray = (
  array1: string[] | undefined,
  array2: string[] | undefined
) => {
  return uniq([...(array1 ?? []), ...(array2 ?? [])]);
};

/**
 * Removes elements from specific positions in an array by mutating it.
 * Removes from end to start to avoid index shifting problems.
 *
 * @template T - The type of elements in the array
 * @param {T[]} array - The array to modify
 * @param {number[]} indices - Positions to remove elements from
 *
 */
export const removeItemFromIndices = <T>(
  array: T[],
  indices: number[]
): void => {
  // Sort indices in descending order
  const sortedIndices = [...indices].sort((a, b) => b - a);

  // Remove elements from end to start
  for (const index of sortedIndices) {
    array.splice(index, 1);
  }
};
