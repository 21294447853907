import { APIFetch, RequestHeaders } from "@certa/network";

export const checkAuth$$ = () => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: RequestHeaders.GET,
    credentials: "include"
  };

  return APIFetch<any>("users/me/?format=json", requestOptions);
};
