import type { SelectProps, SelectValue } from "antd/lib/select";
import type { WorkflowFiltersQuery } from "@certa/queries/types/workflow.types";

import type {
  ReportCustomFilter,
  WorkflowDateRange,
  WorkflowRelativeDateRange,
  WorkflowFilters
} from "@certa/types";
import { CompareType } from "@certa/types";

import moment from "moment";
import { pickBy } from "lodash-es";
import type { FilterPropsOption } from "@certa/table/src/readonlyTable/types";
import { getLastArrayElement } from "@certa/common/utils/helper";
import { INDEX_ZERO, ONE } from "@certa/common/constants";
import type { JSONAnswer } from "@certa/common/utils/filterUtils";
import {
  isValidAnswerQuery,
  transformBEAnswerQueryToFE,
  isValidAnswerForAndOrQuery,
  transformBEJSONAnswerQueryToFE,
  CREATED_RANGE_AFTER_INDEX,
  CREATED_RANGE_BEFORE_INDEX,
  getAnswerQueryProperties,
  replaceOldRelativeDateFormat,
  backwardCompatibleAnswerSplit
} from "@certa/common/utils/filterUtils";
import type { FieldMap } from "@certa/common/utils/fieldHierarchy";

/**
 * NOTE:
 * Don't add new functions here if they can be used for platform wide filters
 * Instead add it in @certa/common/filterUtils
 *
 * TODO: Move other common utils to @certa/common
 */

export type CustomSelectProps = SelectProps<SelectValue>;

/**
 * Remove keys with value either undefined or <empty string>
 * NOTE: Empty means undefined or <empty string>
 */
const removeEmptyValues = <T extends Record<string, any>>(obj: T) => {
  return Object.keys(obj)
    .filter(key => ![undefined, ""].includes(obj[key]))
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {} as Partial<T>);
};

/**
 * Returns an array of fields tags from the answer array
 */
export const getFieldTagsFromAnswer = (answers: any) => {
  if (Array.isArray(answers)) {
    return answers?.filter(isValidAnswerQuery).map(ans => {
      return ans.field[ans.field.length - 1] as string;
    });
  } else if (isValidAnswerForAndOrQuery(answers)) {
    return Array.from(getUniqueFieldValuesFromAndOrQuery(answers));
  }
  return [];
};

const getUniqueFieldValuesFromAndOrQuery = (
  filter: ReportCustomFilter
): Set<string> => {
  const values = new Set<string>();

  filter.conditions.filter(isValidAnswerQuery).forEach(condition => {
    const field = getLastArrayElement(condition?.field?.path);
    if (field) {
      values.add(field);
    }
  });

  filter.groups.forEach(group => {
    const uniqueFields = getUniqueFieldValuesFromAndOrQuery(group);
    uniqueFields.forEach(field => values.add(field));
  });

  return values;
};

export const transformOldRelativeDateRange = (
  beQuery: WorkflowFiltersQuery
) => {
  const { json_answer: JSONAnswer, relative_date_range: relativeDateRange } =
    beQuery;
  const query = beQuery;

  // this is for backward compatibility
  // old report config has relative_date_range
  if (
    relativeDateRange &&
    relativeDateRange?.[CREATED_RANGE_AFTER_INDEX] &&
    relativeDateRange?.[CREATED_RANGE_BEFORE_INDEX]
  ) {
    query.created_range_after =
      relativeDateRange[CREATED_RANGE_AFTER_INDEX]?.replace(" ", "") + "L";
    query.created_range_before =
      relativeDateRange[CREATED_RANGE_BEFORE_INDEX]?.replace(" ", "") + "U";
  }
  // JSONAnswer is object version of answer supported by BE
  if (JSONAnswer) {
    delete query.answer;
  }
  if (query.answer) {
    let newAnswer = query.answer;
    let { isAndOr, hasBar } = getAnswerQueryProperties(newAnswer);
    if (hasBar) {
      newAnswer = `${CompareType.AND},[,${newAnswer?.replaceAll("|", ",")},]`;
      isAndOr = true;
      hasBar = false;
    }
    if (isAndOr) {
      const jsonAnswer = transformBECommaSeparatedStringToJSONAnswer(newAnswer);
      query.json_answer = jsonAnswer;
      query.filter_by_group = "true";
      delete query.answer;
    }
  }
  return query;
};

/**
 * Reverse transforms BE query to form readable record
 */
export const transformBEQueryToFEQuery = (
  query: Partial<WorkflowFiltersQuery>,
  kindTag: string,
  fieldMapping: FieldMap | undefined
): Partial<WorkflowFilters> => {
  const values: WorkflowFilters = {
    SEARCH: query.q,
    STATUS: query.status__in?.split(","),
    REGION: query.region__in?.split(","),
    BUSINESS_UNIT: query.business_unit__in?.split(","),
    KIND_TAG: kindTag,
    ...createdAtDateFilter(query),
    ANSWER: query?.json_answer
      ? transformBEJSONAnswerQueryToFE(query?.json_answer, fieldMapping)
      : transformBEAnswerQueryToFE(query?.answer, fieldMapping, true)
  };

  return removeEmptyValues(values);
};

export const getValidColumnFilterObj = (filterObj: FilterPropsOption) => {
  return pickBy(filterObj, value => !!value?.length);
};

const createdAtDateFilter = (query: Partial<WorkflowFiltersQuery>) => {
  let DATE_FILTER_TYPE = query.date_filter_type ?? "betweenDate";
  let RELATIVE_DATE_RANGE: WorkflowRelativeDateRange | undefined = undefined;
  let DATE_RANGE: WorkflowDateRange | undefined = undefined;
  const createdRangeAfter = query?.created_range_after ?? null;
  const createdRangeBefore = query?.created_range_before ?? null;

  const relativeDateAfter = query?.relative_date_range?.[
    CREATED_RANGE_AFTER_INDEX
  ]?.replace(" ", ""); // - 1d to -1d
  const relativeDateBefore = query?.relative_date_range?.[
    CREATED_RANGE_BEFORE_INDEX
  ]?.replace(" ", "");

  if (relativeDateAfter && relativeDateBefore) {
    // old report config has relative_date_range
    RELATIVE_DATE_RANGE = [relativeDateAfter, relativeDateBefore];
    DATE_RANGE = undefined;
    DATE_FILTER_TYPE = "withinTheLastDate";
  } else if (
    // search CREATED_RANGE_WITH_RELATIVE_DATE to see how it is encoded
    // new report config has relative date range in created_range_after and created_range_before
    // possible values are -<n>dL, -<n>wL, -<n>ML, -<n>h, -<n>m; for minutes and hours, there is no L or U
    createdRangeAfter?.match(new RegExp(/^-[0-9]+(m|h|d|w|M)/)) &&
    createdRangeBefore?.match(new RegExp(/^0(m|h|d|w|M)/))
  ) {
    RELATIVE_DATE_RANGE = [
      createdRangeAfter.replace(/L/, ""),
      createdRangeBefore?.replace(/U/, "")
    ];
    DATE_RANGE = undefined;
    DATE_FILTER_TYPE = "withinTheLastDate";
  } else if (createdRangeAfter || createdRangeBefore) {
    // if created_range_after and created_range_before don't have relative date range format
    // then it is an absolute date range
    DATE_RANGE = [
      createdRangeAfter && moment(createdRangeAfter, true).isValid()
        ? moment.utc(createdRangeAfter).local()
        : null,
      createdRangeBefore && moment(createdRangeBefore, true).isValid()
        ? moment.utc(createdRangeBefore).local()
        : null
    ];
  }
  return {
    DATE_FILTER_TYPE,
    RELATIVE_DATE_RANGE,
    DATE_RANGE
  };
};

const QueryNode = ({
  type = CompareType.OR,
  operands = []
}: Partial<JSONAnswer>) => ({
  type,
  operands
});
type QueryNodeType = ReturnType<typeof QueryNode>;

const transformBECommaSeparatedStringToJSONAnswer = (string: string) => {
  // Split the string into an array of tokens
  const data: string[] = string.split(",");

  // Create root Node
  let node = QueryNode({
    type: data[INDEX_ZERO] as CompareType
  });

  // this condition never happens, as we have never save report config
  // which have only condition like: - "field_tag__data_type__op__answer"
  // it always have type at the start: - "AND,[,field_tag__data_type__op__answer,]"
  if (data.length === ONE) {
    const operands = [transformStringToOperands(data[INDEX_ZERO])];
    node = QueryNode({ operands });
  }

  const deserialize = (
    data: string[],
    index: number[],
    node: QueryNodeType
  ) => {
    const total = data.length;
    while (index[INDEX_ZERO] < total) {
      if (data[index[INDEX_ZERO]] === "]") {
        // Child path covered increment the reference/data index val
        index[INDEX_ZERO]++;
        return;
      } else if (data[index[INDEX_ZERO]] === "[") {
        // Child-node creations starts after this index
        index[INDEX_ZERO]++;
      } else if (
        [CompareType.AND, CompareType.OR].includes(
          data[index[INDEX_ZERO]] as CompareType
        )
      ) {
        // Create child-main node
        const child = QueryNode({
          type: data[index[INDEX_ZERO]] as CompareType
        });
        node.operands.push(child);
        index[INDEX_ZERO]++;
        // Recursive create child-nodes
        deserialize(data, index, child);
      } else {
        // deserialize query value from str to Dict
        // field_tag__op__answer -> {"field": "some tag", "operator": "eq", "compare_value": "some value"}
        node.operands.push(transformStringToOperands(data[index[INDEX_ZERO]]));
        index[INDEX_ZERO]++;
      }
    }
  };
  // [1] array used (by-reference) here to keep track of last data covered in recursive order
  deserialize(data, [ONE], node);
  return node;
};

const transformStringToOperands = (string: string) => {
  const [filedTag, fieldType, operator, value = ""] =
    backwardCompatibleAnswerSplit(string);

  const newValue = replaceOldRelativeDateFormat(value);
  return {
    field: filedTag,
    operator,
    value: newValue,
    data_type: fieldType
  };
};
