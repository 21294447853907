/** @jsx jsx */
/** @jsxRuntime classic */
import type { ReactElement } from "react";
import { jsx, css } from "@emotion/core";
import { Stack, Text } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";

const OverdueTag = (): ReactElement => {
  const intl = useIntl();

  return (
    <Stack justify="center">
      <Text
        variant="p2-bold-upper"
        color="var(--neutral-0)"
        align="center"
        css={css`
          position: absolute;
          width: 100%;
          margin-top: 8px;
          background: var(--red);
          border-radius: var(--normal-border-radius) !important;

          width: 68px;
          height: 24px;
          padding: 6px 0px;
        `}
      >
        {intl.formatMessage({
          id: "tasklaneProgress.delayed",
          defaultMessage: "Delayed"
        })}
      </Text>
    </Stack>
  );
};

export { OverdueTag };
