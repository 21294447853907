import { useState, useCallback } from "react";
import { render } from "react-dom";
import { Dialog } from "../Dialog";
import { ButtonColors } from "../Button";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "../Typography";

import styles from "./AlertDialog.module.css";

const getButtonColor = (type: AlertType) => {
  switch (type) {
    case "error":
      return ButtonColors.RED;
    case "warning":
      return ButtonColors.ORANGE;
    default:
      return ButtonColors.BRAND;
  }
};

export type AlertType = "info" | "error" | "warning";

export type AlertDialogProps = {
  width?: string;
  title: string;
  children: string;
  onPrimaryAction: () => void | Promise<void>;
  onSecondaryAction?: () => void | Promise<void>;
  type?: AlertType;
  secondaryType?: AlertType;
  primaryActionText?: string;
  secondaryActionText?: string;
  preventCloseOnClickOutside?: boolean;
  onClose?: () => void;
  showCloseIcon?: boolean;
};

const AlertDialog = (props: AlertDialogProps) => {
  const {
    title,
    children,
    onClose,
    onPrimaryAction,
    onSecondaryAction,
    type = "info",
    secondaryType = "info",
    preventCloseOnClickOutside: shouldPreventCloseOnClickOutside = true,
    width = "400px",
    showCloseIcon: shouldShowCloseIcon = false,
    primaryActionText,
    secondaryActionText
  } = props;

  const [shouldShowAlertDialog, setShouldShowAlertDialog] =
    useState<boolean>(true);

  const [isPrimaryActionLoading, setIsPrimaryActionLoading] =
    useState<boolean>(false);
  const [isSecondaryActionLoading, setIsSecondaryActionLoading] =
    useState<boolean>(false);

  const handlePrimaryAction = async () => {
    setIsPrimaryActionLoading(true);
    await onPrimaryAction();
    setIsPrimaryActionLoading(false);
    setShouldShowAlertDialog(false);
  };

  const handleSecondaryAction = async () => {
    setIsSecondaryActionLoading(true);
    await onSecondaryAction?.();
    setIsSecondaryActionLoading(false);
    setShouldShowAlertDialog(false);
  };

  const handleClose = useCallback(() => {
    setShouldShowAlertDialog(false);
    onClose?.();
  }, [onClose]);

  const isActionButtonLoading =
    isPrimaryActionLoading || isSecondaryActionLoading;

  return (
    <Dialog
      role="alertdialog"
      title={title}
      show={shouldShowAlertDialog}
      onPrimaryAction={handlePrimaryAction}
      onSecondaryAction={handleSecondaryAction}
      primaryButtonProps={{
        color: getButtonColor(type),
        loading: isPrimaryActionLoading,
        disabled: isActionButtonLoading
      }}
      secondaryButtonProps={{
        color: getButtonColor(secondaryType),
        loading: isSecondaryActionLoading,
        disabled: isActionButtonLoading
      }}
      preventCloseOnClickOutside={shouldPreventCloseOnClickOutside}
      onClose={handleClose}
      width={width}
      showCloseIcon={shouldShowCloseIcon}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
    >
      <div className={styles.catalystAlertDialog}>
        <Typography
          variant={TypographyVariants.BODY}
          color={TypographyColors.NEUTRAL_800}
        >
          {children}
        </Typography>
      </div>
    </Dialog>
  );
};

export function showAlertDialog(params: Omit<AlertDialogProps, "show">): void {
  const {
    title,
    children,
    onClose,
    onPrimaryAction,
    onSecondaryAction,
    type,
    secondaryType,
    preventCloseOnClickOutside: shouldPreventCloseOnClickOutside = true,
    width = "400px",
    showCloseIcon: shouldShowCloseIcon = false,
    primaryActionText,
    secondaryActionText = "Cancel"
  } = params;

  const alertDialogContainer = document.createDocumentFragment();

  render(
    <AlertDialog
      title={title}
      onPrimaryAction={onPrimaryAction}
      primaryActionText={primaryActionText}
      onSecondaryAction={onSecondaryAction}
      secondaryActionText={secondaryActionText}
      preventCloseOnClickOutside={shouldPreventCloseOnClickOutside}
      onClose={onClose}
      showCloseIcon={shouldShowCloseIcon}
      width={width}
      type={type}
      secondaryType={secondaryType}
    >
      {children}
    </AlertDialog>,
    alertDialogContainer
  );
}
