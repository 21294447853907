import { Tag } from "@certa/icons/components/Tag";
import { Info } from "@certa/icons/components/Info";
import { Section } from "@certa/icons/components/Section";
import { Vendor } from "@certa/icons/components/Vendor";
import { Code } from "@certa/icons/components/Code";
import { Number } from "@certa/icons/components/Number";
import { Gear } from "@certa/icons/components/Gear";
import { Globe } from "@certa/icons/components/Globe";
import { AddUser } from "@certa/icons/components/AddUser";
import { User } from "@certa/icons/components/User";
import { Lock } from "@certa/icons/components/Lock";
import { Text } from "@certa/icons/components/Text";
import { Boolean } from "@certa/icons/components/Boolean";
import { Download } from "@certa/icons/components/Download";
import { Date } from "@certa/icons/components/Date";
import { Envelope } from "@certa/icons/components/Envelope";
import { Upload } from "@certa/icons/components/Upload";
import { Package } from "@certa/icons/components/Package";
import { Array } from "@certa/icons/components/Array";
import { Cascader } from "@certa/icons/components/Cascader";
import { Characters } from "@certa/icons/components/Characters";
import { Checkbox } from "@certa/icons/components/Checkbox";
import { Currency } from "@certa/icons/components/Currency";
import { IFrame } from "@certa/icons/components/IFrame";
import { Paragraph } from "@certa/icons/components/Paragraph";
import { Phone } from "@certa/icons/components/Phone";
import { Radio } from "@certa/icons/components/Radio";
import { Location } from "@certa/icons/components/Location";
import { PDF } from "@certa/icons/components/PDF";
import { Multiselect } from "@certa/icons/components/Multiselect";
import { Table } from "@certa/icons/components/Table";
import { Visualisation } from "@certa/icons/components/Visualisation";
import { Word } from "@certa/icons/components/Word";
import { Kanban } from "@certa/icons/components/Kanban";
import { SectionJump } from "@certa/icons/components/SectionJump";
import { Clock } from "@certa/icons/components/Clock";
import { Queue } from "@certa/icons/components/Queue";
import { Sum } from "@certa/icons/components/Sum";

export const FieldToIcon = (fieldType: string) => {
  switch (fieldType) {
    case "math-and-formula":
      return Sum;
    case "all-steps":
      return Queue;
    case "clock":
      return Clock;
    case "stepGroup":
      return Kanban;
    case "step":
      return SectionJump;
    case "bool":
      return Boolean;
    case "file":
      return Upload;
    case "date":
    case "naive_date":
      return Date;
    case "email":
      return Envelope;
    case "attachment":
      return Download;
    case "char_field":
      return Characters;
    case "array":
      return Array;
    case "text":
      return Text;
    case "business_unit":
      return Vendor;
    case "cascader":
      return Cascader;
    case "checkbox":
    case "grouped_checkbox":
      return Checkbox;
    case "currency":
      return Currency;
    case "values":
    case "decimal":
    case "integer":
      return Number;
    case "multi_select":
    case "single_select":
      return Multiselect;
    case "iframe":
      return IFrame;
    case "metadata":
    case "alert":
      return Info;
    case "paragraph":
      return Paragraph;
    case "s3_object":
      return PDF;
    case "phone":
      return Phone;
    case "radio":
      return Radio;
    case "region":
      return Location;
    case "html":
      return Code;
    case "lock":
      return Lock;
    case "user":
      return User;
    case "user_workflow":
      return AddUser;
    case "system":
      return Gear;
    case "table":
      return Table;
    case "url":
      return Globe;
    case "visualization":
      return Visualisation;
    case "break":
      return Section;
    case "word_document":
      return Word;
    case "tag":
      return Tag;
    case "screening":
      return Lock;
    default:
      return Package;
  }
};
