//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Chart = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Chart"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3234)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 0C12.8954 0 12 0.895431 12 2V14C12 15.1046 12.8954 16 14 16C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0ZM6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8V14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14V8ZM0 11C0 9.89543 0.895431 9 2 9C3.10457 9 4 9.89543 4 11V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V11Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3234">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
