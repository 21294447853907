import type { ChartViewICProps } from "../types";
import { NoDataInReports } from "../../noDataInReports/NoDataInReports";
import { useChartViewIC } from "../hooks/useChartViewIC";
import { getCharComponent } from "../../../utils/getChartComponent";

export const ChartViewIC = (props: ChartViewICProps) => {
  const {
    onChartClick,
    chartType,
    xActiveIdentifier,
    error,
    isAnimationActive = true
  } = props;
  const {
    chartData,
    otherConfig,
    chartHeight,
    hasData,
    xAxisDataLabels,
    yAxisKey
  } = useChartViewIC(props);
  const ChartComponent = getCharComponent(chartType);

  if (!hasData) {
    return <NoDataInReports error={error} />;
  }

  return (
    <ChartComponent
      chartData={chartData}
      onClick={onChartClick}
      height={chartHeight}
      xActiveIdentifier={xActiveIdentifier}
      otherConfig={otherConfig}
      isCycleTimeReport={false}
      xAxisDataLabels={xAxisDataLabels}
      isAnimationActive={isAnimationActive}
      yAxisKey={yAxisKey}
    />
  );
};
