import * as _ from "lodash-es";
import { userUtilities } from "./user";
import { apiBaseURL, auditLogBaseURL, apiBaseURLV2 } from "../../config";
import {
  showToast,
  ToastTypes,
  ToastPlacements
} from "@certa/catalyst/components/Toast";
import { intl } from "../../modules/common/components/IntlCapture";

const fetchQueue = new Set();

export const getValueFromCookie = name => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (const value of cookies) {
      const cookie = _.trim(value);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const NEGATIVE_INDEX = -1;

const SHOULD_BE_STALLED_TOKEN = "SHOULD_BE_STALLED";
const fetchWrapper = (...params) => {
  const request = new Promise((resolve, reject) => {
    fetch(...params)
      .then(response => {
        if (response.status === 401) {
          // list of urls that should not be redirected to login page
          // on 401 error
          if (
            params[0].indexOf("users/me") === NEGATIVE_INDEX &&
            params[0].indexOf("users/login_using_mfa/") === NEGATIVE_INDEX &&
            params[0].indexOf("users/permissions/") === NEGATIVE_INDEX
          ) {
            // We don't want to get stuck in redirect loop if autologin
            // fails for any reason.
            userUtilities.postLogoutAction({ addNextURL: true });
          }
        } else if (response.status === 403) {
          showToast({
            title: intl.formatMessage({
              id: "permissions.insufficientPermissions",
              defaultMessage: "Insufficient Permissions"
            }),
            type: ToastTypes.WARNING,
            placement: ToastPlacements.BOTTOM_LEFT
          });
          // TODO:
          // In ideal case we would be rejecting the promising
          // and expecting that the called would handle it
          // and each called might handle it in a different way
          // but since that would require a major change (at every place where APIFetch is being used)
          // we are returning a random string instead of resolving/rejecting the promise
          // to keep the promise in a pending state always
          // and since in most of the cases the reference to the promise will be lost
          // it will be automatically garbage collected, avoiding memory leaks
          reject(SHOULD_BE_STALLED_TOKEN);
        } else if (response.status === 503) {
          // We throw error instead of redirecting to service unavailable page
          // so that nearest error boundary can catch the error and user
          // remain on the same page
          throw new Error("Service Unavailable");
        }
        resolve(response);
      })
      .catch(error => {
        if (!navigator.onLine) {
          showToast({
            title: intl.formatMessage({
              id: "notificationInstances.userOffline",
              defaultMessage:
                "You seem to be offline, please check your internet connectivity."
            }),
            type: ToastTypes.ERROR,
            placement: ToastPlacements.BOTTOM_LEFT
          });
        } else if (error.name !== "AbortError") {
          if (
            error.message === "Failed to fetch" || // Chrome
            error.message === "Load failed" || // Safari
            error.message === "NetworkError when attempting to fetch resource." // Firefox
          ) {
            // Check if it's a timeout error (504). In case of timeout,
            // fetch will reject with "Failed to fetch" error. So,
            // we don't get response object. We only handle timeout error
            // for now, as handling other network errors may have side
            // effects on existing code
            const timeoutDuration = 60000; // default 60s from BE
            if (Date.now() - requestStartTime >= timeoutDuration) {
              showToast({
                title: intl.formatMessage({
                  id: "notificationInstances.networkError"
                }),
                type: ToastTypes.ERROR,
                placement: ToastPlacements.BOTTOM_LEFT
              });
            }
          } else {
            // throw error, will be logged on sentry in error boundary
            throw new Error(
              `API call failed with error "${error}" : ${params[0]}`
            );
          }
        }
        reject(error.message);
      });
  }).finally(() => {
    // Remove call from fetch queue when the call is finished
    fetchQueue.delete(request);
  });

  const requestStartTime = Date.now();
  fetchQueue.add(request);

  return request;
};

const validateAPIEndpoint = endpoint => {
  if (endpoint.startsWith("http") || endpoint.startsWith("/")) {
    throw new Error(
      "Invalid URL provided to APIFetch, must be a relative URL not starting with /"
    );
  }
};

/**
 * A very faithful fetch function that keeps the unauthorized users out
 * (warning - doesn't bite or bark, just keeps them out)
 * @param params
 * @returns {Promise<Response | never>}
 */
export const APIFetch = (...params) => {
  validateAPIEndpoint(params[0]);
  params[0] = `${apiBaseURL}${params[0]}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(...params)
      .then(resolve)
      .catch(e => {
        if (e === SHOULD_BE_STALLED_TOKEN) {
          return "";
        } else {
          reject(e);
        }
      });
  });
};

export const APIFetchV2 = (...params) => {
  validateAPIEndpoint(params[0]);
  params[0] = `${apiBaseURLV2}${params[0]}`;
  return new Promise((resolve, reject) => {
    fetchWrapper(...params)
      .then(resolve)
      .catch(e => {
        if (e === SHOULD_BE_STALLED_TOKEN) {
          return "";
        } else {
          reject(e);
        }
      });
  });
};

/**
 * Waits for calls made before this call to get finished.
 * @param  {...any} params
 * @returns {Promise<Response | never>}
 */
export const deferredFetch = (...params) => {
  return new Promise((resolve, reject) => {
    // Wait for all the calls until this point to get finished
    // and initiate the defered call, regardless of whether any
    // past calls succeeded or failed.
    Promise.all(Array.from(fetchQueue)).finally(() => {
      APIFetchV2(...params)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  });
};

/**
 * A fetch function that keeps the unauthorized users out for serverless API calls
 * @param params
 * @returns {Promise<Response | never>}
 */
export const serverlessAPIFetch = (...params) => {
  validateAPIEndpoint(params[0]);

  let baseUrl;
  if (params[2] === "AUDIT_LOG") {
    baseUrl = auditLogBaseURL;
  }
  params[0] = `${baseUrl}${params[0]}`;

  return fetchWrapper(...params);
};
