import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { get as lodashGet } from "lodash-es";

/**
 * Hooks to check if the data objects feature is enabled
 *
 * @returns {boolean} - True if the data objects feature is enabled, false otherwise
 */
export const useEnableDataObjects = (): boolean => {
  return useSelector((state: ReduxState) =>
    lodashGet(
      state,
      "config.feature_toggles.STANDARD_DATA_MODEL_FEATURE_TOGGLE",
      false
    )
  );
};
