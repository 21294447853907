import type { KindStatus } from "../types/common.types";
import type { SelectOption } from "@certa/types";
import { getUserLanguage } from "main/src/js/components/common/intlLanguages";

export const statusModelCreator = (jsonFromAPI: any): KindStatus[] => {
  return jsonFromAPI
    .map((status: any) => ({
      id: status?.id || null,
      displayName: status?.display_name || null,
      colorCode: status?.color_code,
      tag: status?.tag
    }))
    .filter((a: KindStatus) => a.displayName || a.id)
    .sort((a: KindStatus, b: KindStatus) =>
      a.displayName
        .toUpperCase()
        .localeCompare(b.displayName.toUpperCase(), getUserLanguage())
    );
};

const getModeledDropdownList = (list: any[]) =>
  list
    .map(
      (region: any): SelectOption => ({
        value: region.id,
        label: region.name
      })
    )
    .sort((a: any, b: any) =>
      a.label
        .toUpperCase()
        .localeCompare(b.label.toUpperCase(), getUserLanguage())
    );

export const regionModelCreatorV2 = (response: any) => {
  if (Array.isArray(response)) {
    return getModeledDropdownList(response);
  }
  return {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results: getModeledDropdownList(response?.results || [])
  };
};
