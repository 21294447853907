import { get as lodashGet } from "lodash-es";

/**
 * Generator function to generate action creators
 *
 * Action Creators: https://redux.js.org/basics/actions#action-creators
 *
 * Approach: This approach make sure that
 * all the action creators have the same signature
 *
 * @param {string} type
 * @returns {function} ActionCreators
 */
export const makeActionCreator = type => payload => ({ type, payload });

export const bannerDetail = config => {
  const bannerMessage = lodashGet(
    config,
    "custom_ui_labels.banner.message",
    ""
  );
  const bannerTrigger = lodashGet(
    config,
    "custom_ui_labels.banner.trigger",
    "off"
  );

  const bannerColor = lodashGet(config, "custom_ui_labels.banner.color", null);

  return {
    bannerMessage,
    bannerTrigger,
    bannerColor
  };
};

export const checkBannerVisiblity = config => {
  const { bannerMessage, bannerTrigger } = bannerDetail(config);
  const isBannerVisible = bannerMessage && bannerTrigger === "on";

  return isBannerVisible;
};

// source: https://stackoverflow.com/a/68274426
export const getColorByBgColor = (
  bgColor,
  lightColor = "neutral-0",
  darkColor = "neutral-100"
) => {
  if (!bgColor) {
    return lightColor;
  }
  const getLuminance = function (hexColor) {
    const color =
      hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map(col =>
      col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4
    );

    return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  };

  const L = getLuminance(bgColor);
  const L1 = getLuminance("#ffffff"); // neutral-0
  const L2 = getLuminance("#00164e"); // neutral-100

  return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05
    ? darkColor
    : lightColor;
};

const hexRegex = /[0-9a-f]{6}|[0-9a-f]{3}/gi;

export const getHEXCodeFromString = (code, defaultString = "") =>
  code && code?.match(hexRegex) ? `#${code}` : defaultString;
