//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Currency = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Currency"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.04302 15.9999H8.46531C8.78818 15.9999 9.05639 15.7361 9.05639 15.4088V14.0419L9.35193 13.9629C11.7628 13.3563 12.6185 11.6975 12.6185 10.4644C12.6185 8.17918 10.6487 7.22967 8.7181 6.59943C6.9057 6.00096 6.32635 5.63819 6.32635 5.10251C6.32635 4.62225 6.88197 4.14127 8.12692 4.14127C9.49823 4.14127 10.3568 4.53139 10.7233 4.70058C10.8024 4.73604 10.8851 4.75599 10.9672 4.75599C11.1955 4.75599 11.4046 4.62226 11.5028 4.41685L12.0666 3.22287C12.1338 3.0847 12.1412 2.9192 12.0865 2.77365C12.0355 2.62366 11.9247 2.50544 11.7828 2.43895C11.1053 2.11977 10.3687 1.91512 9.53674 1.81683L9.19022 1.77324V0.587392C9.19022 0.26452 8.92646 0 8.59914 0H7.2005C6.87763 0 6.60942 0.263764 6.60942 0.587392V1.90699L6.31388 1.98161C4.36776 2.47443 3.2047 3.72308 3.2047 5.31909C3.2047 7.42334 5.28909 8.36093 7.33413 9.00308C9.22117 9.61781 9.47753 10.1498 9.47753 10.5909C9.47753 11.2728 8.59534 11.7686 7.38142 11.7686C6.34925 11.7686 5.23802 11.5048 4.41051 11.063C4.32406 11.0157 4.22949 10.9921 4.13047 10.9921C4.06767 10.9921 4.00413 11.0039 3.94133 11.0238C3.78765 11.0793 3.6613 11.193 3.59481 11.343L3.05102 12.5525C2.92911 12.8325 3.03107 13.1554 3.29927 13.3054C3.98862 13.7037 5.01341 13.9985 6.1009 14.1211L6.45186 14.1566V15.4097C6.45186 15.7362 6.71563 16 7.04294 16L7.04302 15.9999Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
