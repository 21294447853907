import { useLocation } from "react-router";
import { useGetSelectableFieldOption } from "@certa/queries/queries/reports/getSelectableFieldOption.query";
import { useGetFieldLabelValueOptions } from "@certa/queries/queries/reports/getFieldLabelValueOptions.query";
import queryString from "query-string";
import type { SelectOption } from "@certa/catalyst/components/Select";
import type { FieldTypes } from "@certa/types/src/fieldTypes";
import { useGetBoolOptions } from "../../../../../hooks/useGetBoolOptions";

type UseHandleAdvancedFilterSelectProps = {
  shouldUseNewLabelValueOptions: boolean;
  kindId: string | undefined;
  fieldTag: string;
  fieldType: FieldTypes;
  onChange?: ((value: string | string[] | undefined) => void) | undefined;
};

export const useHandleAdvancedFilterSelect = ({
  shouldUseNewLabelValueOptions,
  kindId,
  fieldTag,
  fieldType,
  onChange
}: UseHandleAdvancedFilterSelectProps) => {
  const location = useLocation();

  // "/search" sets "kind_id" in queryParams already, no need for prop drilling from there
  const { kind_id: queryParamKindId } = queryString.parse(location.search);
  const isBoolField = fieldType === "bool";
  const { boolOptions } = useGetBoolOptions();

  const { data: getSelectOptionsData = [], isLoading: isLoadingSelectOptions } =
    useGetSelectableFieldOption({
      fieldTag,
      kindId: typeof queryParamKindId === "string" ? queryParamKindId : kindId,
      config: {
        enabled:
          !isBoolField &&
          !shouldUseNewLabelValueOptions &&
          !!fieldTag &&
          !!(queryParamKindId || kindId)
      }
    });

  const {
    data: { options: getLabelValueOptionsData = [] } = {},
    isLoading: isLoadingLabelValueOptions
  } = useGetFieldLabelValueOptions({
    kindId,
    fieldTag: fieldTag,
    config: {
      enabled:
        !isBoolField && shouldUseNewLabelValueOptions && !!fieldTag && !!kindId
    }
  });

  const selectOptions: SelectOption[] =
    fieldType === "bool"
      ? boolOptions
      : shouldUseNewLabelValueOptions
        ? getLabelValueOptionsData
        : getSelectOptionsData;

  const isLoading = isLoadingSelectOptions || isLoadingLabelValueOptions;

  const handleCreateOptions = (inputValue: string) => {
    onChange?.(inputValue);
  };

  return {
    isLoading,
    selectOptions,
    handleCreateOptions
  };
};
