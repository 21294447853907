import { get as lodashGet } from "lodash-es";
import type { Kind, WorkflowKindsHeirarchy, KindHeirarchy } from "@certa/types";

export const kindsModelCreator = (jsonFromAPI: any[]): Kind[] => {
  return jsonFromAPI.map((kind: any) => ({
    id: lodashGet(kind, "id", null),
    features: lodashGet(kind, "features", []),
    isSortingFieldEnabled: lodashGet(kind, "is_sorting_field_enabled", false),
    isRelatedKind: lodashGet(kind, "is_related_kind", false),
    name: lodashGet(kind, "name", null),
    tag: lodashGet(kind, "tag", null),
    initialData: {
      objectType: lodashGet(kind, "initial_data.object_type", null)
    },
    icon: lodashGet(kind, "icon", null),
    availableStatuses: lodashGet(kind, "available_statuses", null),
    fieldTagsForFilter: lodashGet(
      kind,
      "field_tags_for_filter.0.options",
      []
    ).map((filterTag: any) => ({
      label: lodashGet(filterTag, "label", null),
      value: lodashGet(filterTag, "value", null)
    })),
    fieldFilterTag: lodashGet(kind, "field_tags_for_filter.0.tag", null),
    description: lodashGet(kind, "description"),
    staticGroups: lodashGet(kind, "static_groups", [])
  }));
};

export type KindsHeirarchyResponse = {
  root_kinds: string[];
  kind_hierarchy: KindHeirarchy;
};

export const kindsHeirarchyModelCreator = (
  jsonFromAPI: KindsHeirarchyResponse
): WorkflowKindsHeirarchy => {
  return {
    rootKinds: jsonFromAPI.root_kinds,
    kindHierarchy: jsonFromAPI.kind_hierarchy
  };
};

export type WorkflowKindsDynamicAPIResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    workflow_kinds: {
      id: number;
      name: string;
      tag: string;
      related_kind_tags: string[];
      dynamic_group_names: string[];
    }[];
  };
};

export const workflowKindsDynamicAPIModelCreator = (
  response: WorkflowKindsDynamicAPIResponse
) => {
  const {
    results: { workflow_kinds: workflowKinds }
  } = response;

  return workflowKinds.map(kind => ({
    id: kind.id,
    name: kind.name,
    tag: kind.tag,
    dynamicGroupNames: kind.dynamic_group_names
  }));
};
