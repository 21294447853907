import { css } from "emotion";

export const leftPanelStyles = {
  wrapper: css({
    borderRight: "1px solid var(--colors-neutral-300)",
    width: "17.5rem",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    wordBreak: "break-word",
    zIndex: 1
  }),
  section: css({
    marginBottom: "var(--space-24)"
  }),
  topSection: css({
    padding: "var(--space-8) var(--space-16)",
    minHeight: "2.5rem",
    display: "flex",
    alignItems: "center"
  }),
  category: (shouldShowBorder?: boolean) =>
    css({
      margin: "0 var(--space-16)",
      borderBottom: shouldShowBorder
        ? "1px solid var(--colors-neutral-300)"
        : "none"
    }),
  iconWrapper: css({
    color: "var(--colors-neutral-800)",
    flexShrink: 0
  }),
  flexGrow: css({
    flexGrow: 1
  })
};
