import { forwardRef, useImperativeHandle } from "react";
import {
  useForm as useReactHookForm,
  FormProvider as ReactHookFormProvider
} from "react-hook-form";

import type { FormProps, FormRef } from "./Form.types";

export const Form = forwardRef<FormRef, FormProps>((props, ref) => {
  const {
    className,
    initialValues,
    children,
    onSubmit,
    mode,
    autoComplete,
    resolver,
    disabled: isDisabled
  } = props;

  const formProps = useReactHookForm({
    mode,
    resolver,
    defaultValues: initialValues,
    disabled: isDisabled
  });

  useImperativeHandle(ref, () => {
    return {
      reset: formProps.reset,
      setValue: formProps.setValue
    };
  });

  return (
    <ReactHookFormProvider {...formProps}>
      <form
        className={className}
        onSubmit={formProps.handleSubmit(onSubmit)}
        noValidate
        autoComplete={autoComplete}
      >
        {children}
      </form>
    </ReactHookFormProvider>
  );
});

export const FormProvider = ReactHookFormProvider;
export const useForm = useReactHookForm;

export { useFormContext, Controller, useFieldArray } from "react-hook-form";
