//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Number = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Number"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M3.164 4V12.904H1.162V5.47H0V4H3.164Z" fill="currentColor" />
          <path
            d="M6.11888 5.82V6.996H4.11687V5.54C4.11687 4.51333 4.70487 4 5.88087 4H7.39288C8.58754 4 9.18488 4.51333 9.18488 5.54V6.674C9.18488 7.10333 9.11954 7.47667 8.98888 7.794C8.86754 8.102 8.66687 8.44267 8.38688 8.816L6.59488 11.434H9.21288V12.904H4.15887V11.546L6.62288 8.242C6.85621 7.95267 7.01021 7.696 7.08488 7.472C7.16888 7.248 7.21088 6.97267 7.21088 6.646V5.82C7.21088 5.58667 7.08488 5.47 6.83288 5.47H6.48287C6.24021 5.47 6.11888 5.58667 6.11888 5.82Z"
            fill="currentColor"
          />
          <path
            d="M11.9574 5.82V6.87H9.96942V5.54C9.96942 4.51333 10.5574 4 11.7334 4H13.2874C14.4728 4 15.0654 4.51333 15.0654 5.54V7.066C15.0654 7.75667 14.8554 8.19067 14.4354 8.368C14.8554 8.52667 15.0654 8.928 15.0654 9.572V11.35C15.0654 12.386 14.4728 12.904 13.2874 12.904H11.7334C10.5574 12.904 9.96942 12.386 9.96942 11.35V10.048H11.9574V11.084C11.9574 11.3173 12.0834 11.434 12.3354 11.434H12.7134C12.9654 11.434 13.0914 11.3173 13.0914 11.084V9.432C13.0914 9.30133 13.0634 9.21267 13.0074 9.166C12.9514 9.11 12.8534 9.082 12.7134 9.082H11.6214V7.696H12.7134C12.8534 7.696 12.9514 7.67267 13.0074 7.626C13.0634 7.57 13.0914 7.48133 13.0914 7.36V5.82C13.0914 5.58667 12.9654 5.47 12.7134 5.47H12.3354C12.0834 5.47 11.9574 5.58667 11.9574 5.82Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
