import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { useCallback } from "react";
import type { KeyboardEvent } from "react";
import { useSearchStore } from "../store/useSearchStore";
import type { SearchItem } from "../GlobalSearchNext.schema";
import type { ListInteractionMode } from "@certa/catalyst/hooks/useListInteraction";
type KeyboardHandlers = {
  onSelectSearchResult: (
    result: ModelledAPIResponse.SearchResult,
    index: number,
    interactionMode: ListInteractionMode
  ) => void;
  onSelectRecentSearch: (
    item: SearchItem,
    index: number,
    interactionMode: ListInteractionMode
  ) => void;
  recentSearches: SearchItem[];
};

export const useSearchKeyboard = ({
  onSelectSearchResult,
  onSelectRecentSearch,
  recentSearches
}: KeyboardHandlers) => {
  const {
    setSelectedItem,
    setInteractionMode,
    selectedItem,
    allSearchResults,
    isLoading,
    hasSearchQuery
  } = useSearchStore();

  const activeItems: ModelledAPIResponse.SearchResult[] | SearchItem[] =
    hasSearchQuery ? allSearchResults : recentSearches;
  const totalActiveItems = activeItems.length;
  const hasResults = totalActiveItems > 0;

  const handleArrowNavigation = useCallback(
    (event: KeyboardEvent<HTMLElement>, delta: number) => {
      event.preventDefault();
      setInteractionMode("keyboard");
      setSelectedItem(prev => {
        const next = prev + delta;
        return Math.max(0, Math.min(next, totalActiveItems - 1));
      });
    },
    [totalActiveItems, setInteractionMode, setSelectedItem]
  );

  const handleEnter = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const isValidSelection =
        selectedItem >= 0 && selectedItem < activeItems.length;
      if (!isValidSelection) return;

      event.preventDefault();
      const selectedActiveItem = activeItems[selectedItem];
      if (!selectedActiveItem) return;
      if (hasSearchQuery) {
        onSelectSearchResult(
          selectedActiveItem as ModelledAPIResponse.SearchResult,
          selectedItem,
          "keyboard"
        );
      } else {
        onSelectRecentSearch(
          selectedActiveItem as SearchItem,
          selectedItem,
          "keyboard"
        );
      }
    },
    [
      selectedItem,
      activeItems,
      hasSearchQuery,
      onSelectSearchResult,
      onSelectRecentSearch
    ]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const keyHandlers: Record<string, () => void> = {
        ArrowDown: () => handleArrowNavigation(event, 1),
        ArrowUp: () => handleArrowNavigation(event, -1),
        Enter: () => handleEnter(event)
      };

      // Don't handle navigation if no results or loading
      if (
        (event.key === "ArrowDown" || event.key === "ArrowUp") &&
        (!hasResults || isLoading)
      ) {
        event.preventDefault();
        return;
      }

      const handler = keyHandlers[event.key];
      if (handler) {
        handler();
      }
    },
    [handleArrowNavigation, handleEnter, hasResults, isLoading]
  );

  return { handleKeyDown };
};
