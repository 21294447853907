import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { Text } from "../Typography";
import type { NotificationProps } from "./types";

type CustomDescriptionProps = {
  description?: NotificationProps["description"];
};

const CustomDescription: FC<CustomDescriptionProps> = props => {
  const { description } = props;

  if (!description) {
    return null;
  }

  return (
    <Text variant="h3-regular" color="neutral-100">
      {description}
    </Text>
  );
};

export const Description = styled(CustomDescription)`
  --label: NotificationDescription;
`;
