import { FieldTypes } from "@certa/types";
import type { OperationArgumentORM } from "@certa/types";
import type { GroupByDateOperation } from "@certa/common/types";

export const groupByOnDate: Record<
  GroupByDateOperation,
  Omit<OperationArgumentORM, "columnType">
> = {
  day: {
    function: "extract_day",
    arguments: ["created_at"],
    label: "day",
    dataType: FieldTypes.DATE
  },
  week: {
    function: "extract_week",
    arguments: ["created_at"],
    label: "week",
    dataType: FieldTypes.DATE
  },
  month: {
    function: "extract_month",
    arguments: ["created_at"],
    label: "month",
    dataType: FieldTypes.DATE
  },
  quarter: {
    function: "extract_quarter",
    arguments: ["created_at"],
    label: "quarter",
    dataType: FieldTypes.DATE
  },
  year: {
    function: "extract_year",
    arguments: ["created_at"],
    label: "year",
    dataType: FieldTypes.DATE
  }
};
