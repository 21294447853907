import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { useList } from "@certa/catalyst/hooks/useList";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { classNames } from "@certa/catalyst/utils/common";
import { NewPage } from "@certa/icons/components/NewPage";
import { css } from "emotion";
import { useIntl } from "react-intl";
import { RECENT_SEARCHES_LISTBOX_ID } from "../GlobalSearchNext.constants";
import type { SearchItem } from "../GlobalSearchNext.schema";
import { useSearchStore } from "../store/useSearchStore";
import { IconContainer, WorkflowLogo } from "./Logo";
import { SearchResultItem } from "./SearchResultItem";
import { mainContentStyles } from "./MainContent.styles";
import type { ListInteractionMode } from "@certa/catalyst/hooks/useListInteraction";

const styles = {
  container: css({
    height: "100%",
    overflow: "auto",
    flexGrow: 1,
    padding: "var(--space-16)",
    backgroundColor: "var(--background-color)",
    borderRadius: "8px"
  })
};

type RecentSearchListProps = {
  items: SearchItem[];
  onItemClick: (
    item: SearchItem,
    index: number,
    interactionMode: ListInteractionMode
  ) => void;
};

export const getRecentSearchId = (index: number) => `recent-search-${index}`;

const RecentSearchList = ({ items, onItemClick }: RecentSearchListProps) => {
  const { selectedItem, setSelectedItem, interactionMode, setInteractionMode } =
    useSearchStore();
  const intl = useIntl();

  const { getListboxProps, getItemProps } = useList({
    activeIndex: selectedItem,
    setActiveIndex: setSelectedItem,
    selectedIndex: selectedItem,
    getOptionId: getRecentSearchId,
    onSelect: (index, interactionMode) =>
      onItemClick(items[index], index, interactionMode),
    interactionMode,
    setInteractionMode,
    totalItemsCount: items.length
  });
  const listboxProps = getListboxProps({ label: "Recent searches" });

  const noRecentSearchesMessage = intl.formatMessage({
    id: "globalSearch.noRecentSearches",
    defaultMessage: "Search for workflows, or documents to get started."
  });
  const recentSearchesMessage = intl.formatMessage({
    id: "globalSearch.recentSearches",
    defaultMessage: "Recent searches"
  });

  if (items.length === 0) {
    return (
      <Stack height="100%" justify="center" align="center" width="100%">
        <Typography
          variant={TypographyVariants.BODY}
          color={TypographyColors.NEUTRAL_700}
        >
          {noRecentSearchesMessage}
        </Typography>
      </Stack>
    );
  }

  return (
    <div
      {...listboxProps}
      id={RECENT_SEARCHES_LISTBOX_ID}
      className={classNames(styles.container, listboxProps.className)}
    >
      <Stack justify="center" width="100%" padding="16 0">
        <Typography
          variant={TypographyVariants.LABEL_SM_BOLD}
          color={TypographyColors.NEUTRAL_600}
        >
          {recentSearchesMessage}
        </Typography>
      </Stack>
      {items.map((item, index) => {
        const { optionProps } = getItemProps(index);
        return (
          <div
            key={`${item.type}-${item.id}`}
            className={mainContentStyles.row}
          >
            <div {...optionProps} className={mainContentStyles.option}>
              <SearchResultItem
                title={item.title}
                description={item.description}
                icon={
                  item.type === "workflow" ? (
                    <WorkflowLogo logoUrl={item.logo} name={item.title} />
                  ) : (
                    <IconContainer icon={<NewPage size={16} />} />
                  )
                }
                isSelected={selectedItem === index}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { RecentSearchList };
