//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Clock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Clock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C5.51472 12.5 3.5 10.4853 3.5 8C3.5 5.51472 5.51472 3.5 8 3.5C10.4853 3.5 12.5 5.51472 12.5 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8.19339 8.25493L9.92007 9.01299L9.92008 9.01303C10.2181 9.13727 10.3549 9.48525 10.2054 9.79536C10.1065 10.0065 9.88272 10.1433 9.65899 10.1433C9.58475 10.1433 9.49787 10.1312 9.42313 10.0938L7.34846 9.18678C7.13735 9.08778 7.00049 8.87618 7.00049 8.64032L7 5.59645C7 5.2611 7.2611 5 7.59645 5C7.9318 5 8.1929 5.27323 8.1929 5.59645L8.19339 8.25493Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
