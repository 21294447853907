//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Paragraph = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Paragraph"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.47363 3H3.90185C2.30137 3 1 4.2971 1 5.89234C1 7.48758 2.30137 8.78468 3.90185 8.78468H4.6186V12.6183C4.6186 12.9863 4.91899 13.2857 5.28826 13.2857C5.65884 13.2857 5.95792 12.9863 5.95792 12.6183V4.33493H7.26063V12.6183C7.26063 12.9863 7.56101 13.2857 7.93028 13.2857C8.29956 13.2857 8.59994 12.9863 8.59994 12.6183L8.59951 4.33493H9.4732C9.84378 4.33493 10.1429 4.03552 10.1429 3.66746C10.1433 3.2994 9.84421 3 9.47363 3H9.47363ZM4.61861 7.44975H3.90186C3.04037 7.44975 2.33933 6.75101 2.33933 5.89234C2.33933 5.03367 3.04037 4.33493 3.90186 4.33493H4.61861V7.44975Z"
            fill="currentColor"
          />
          <path
            d="M13.1772 11.7143L12.8987 10.2678H11.0633L10.7848 11.7143H9L10.8228 3.71429H13.1266L15 11.7143H13.1772ZM11.3165 8.95958H12.6329L11.9747 5.57593L11.3165 8.95958Z"
            fill="currentColor"
          />
          <rect
            x={1}
            y={13.2143}
            width={14}
            height={1.5}
            rx={0.75}
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
