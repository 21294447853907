//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const IFrame = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="IFrame"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3846)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.04135 15.7557L1.76138 14.4398C1.83082 14.4778 1.91057 14.5 2 14.5H2.95455V16H2C1.65265 16 1.32599 15.9115 1.04135 15.7557ZM13.0455 16V14.5H14C14.0894 14.5 14.1692 14.4778 14.2386 14.4398L14.9587 15.7557C14.674 15.9115 14.3473 16 14 16H13.0455ZM16 2.95455H14.5V2C14.5 1.91057 14.4778 1.83082 14.4398 1.76138L15.7557 1.04135C15.9115 1.32599 16 1.65265 16 2V2.95455ZM2.95455 0H2C1.65265 0 1.32599 0.0885461 1.04135 0.244297L1.76138 1.56018C1.83082 1.52219 1.91057 1.5 2 1.5H2.95455V0ZM0.244297 14.9587L1.56018 14.2386C1.52219 14.1692 1.5 14.0894 1.5 14V13.0455H0V14C0 14.3473 0.0885461 14.674 0.244297 14.9587ZM0 11.9545H1.5V10.0455H0V11.9545ZM0 8.95455H1.5V7.04545H0V8.95455ZM0 5.95455H1.5V4.04545H0V5.95455ZM0 2.95455H1.5V2C1.5 1.91057 1.52219 1.83082 1.56018 1.76138L0.244297 1.04135C0.0885462 1.32599 0 1.65265 0 2V2.95455ZM4.04545 0V1.5H5.95455V0H4.04545ZM7.04545 0V1.5H8.95455V0H7.04545ZM10.0455 0V1.5H11.9545V0H10.0455ZM13.0455 0V1.5H14C14.0894 1.5 14.1692 1.52219 14.2386 1.56018L14.9587 0.244297C14.674 0.0885462 14.3473 0 14 0H13.0455ZM16 4.04545H14.5V5.95455H16V4.04545ZM16 7.04545H14.5V8.95455H16V7.04545ZM16 10.0455H14.5V11.9545H16V10.0455ZM16 13.0455H14.5V14C14.5 14.0894 14.4778 14.1692 14.4398 14.2386L15.7557 14.9587C15.9115 14.674 16 14.3473 16 14V13.0455ZM11.9545 16V14.5H10.0455V16H11.9545ZM8.95455 16V14.5H7.04545V16H8.95455ZM5.95455 16V14.5H4.04545V16H5.95455Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3846">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
