import { useMutation, useInfiniteQuery } from "react-query";
import { changeSubscriptionFromThreadModel } from "../models/comments.models";
import {
  addSubscriptionToThread,
  getUserSubscriptions,
  removeSubscriptionToThread
} from "../services/threadSubscription.services";
import type { CommentObjectTypes } from "@certa/types";
import { queryClient } from "@certa/queries/utils/utils";
import { getQueryKey } from "./comments.hooks";
import type {
  UseInfiniteQueryExtraConfigType,
  UseMutationExtraConfigType
} from "@certa/common/types";
import { SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY } from "@certa/queries/queries/comms/getSubscriptions.query";
const addCurrentUserIdToSubscribedBy = (
  objectId: number,
  objectType: CommentObjectTypes,
  threadId: number,
  userId: number,
  shouldAdd: boolean
) => {
  queryClient.setQueryData(
    getQueryKey(objectId, objectType),
    (oldData: any) => {
      return (
        oldData?.results &&
        changeSubscriptionFromThreadModel(oldData, threadId, userId, shouldAdd)
      );
    }
  );
};

const useRemoveSubscription = (
  objectId: number,
  objectType: CommentObjectTypes,
  userId: number,
  config?: UseMutationExtraConfigType
) =>
  useMutation(removeSubscriptionToThread, {
    onSuccess: (_, payload) => {
      addCurrentUserIdToSubscribedBy(
        objectId,
        objectType,
        payload.threadId,
        userId,
        payload.enable
      );
      // This is to re-fetch subscription list after subscribe/unsubsribe
      queryClient.invalidateQueries("subscriptions");
      queryClient.invalidateQueries(SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY);
    },
    ...(config || {})
  });

const useAddSubscription = (
  objectId: number,
  objectType: CommentObjectTypes,
  userId: number
) =>
  useMutation(addSubscriptionToThread, {
    onSuccess: (_, payload) => {
      addCurrentUserIdToSubscribedBy(
        objectId,
        objectType,
        payload.threadId,
        userId,
        payload.enable
      );
      // This is to re-fetch subscription list after subscribe/unsubsribe
      queryClient.invalidateQueries("subscriptions");
      queryClient.invalidateQueries(SUBSCRIPTIONS_LIST_COMMS_QUERY_KEY);
    }
  });

const DEFAULT_LIMIT_SUBSCRIPTIONS = 20;

const useSubscriptions = (config?: UseInfiniteQueryExtraConfigType) =>
  useInfiniteQuery(
    "subscriptions",
    ({ pageParam = 1 }) =>
      getUserSubscriptions(
        DEFAULT_LIMIT_SUBSCRIPTIONS,
        (pageParam - 1) * DEFAULT_LIMIT_SUBSCRIPTIONS
      ),
    {
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.next) {
          return allPage.length + 1;
        }
        return false;
      },
      ...config
    }
  );

export { useAddSubscription, useRemoveSubscription, useSubscriptions };
