//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Multiselect = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Multiselect"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4.5H14C14.2761 4.5 14.5 4.72386 14.5 5V11C14.5 11.2761 14.2761 11.5 14 11.5H2C1.72386 11.5 1.5 11.2761 1.5 11V5C1.5 4.72386 1.72386 4.5 2 4.5ZM0 5C0 3.89543 0.895431 3 2 3H14C15.1046 3 16 3.89543 16 5V11C16 12.1046 15.1046 13 14 13H2C0.895431 13 0 12.1046 0 11V5ZM10.378 9.56356C10.1786 9.7938 9.82142 9.7938 9.62204 9.56356L8.11841 7.82733C7.83797 7.5035 8.068 7 8.49637 7H11.5036C11.932 7 12.162 7.5035 11.8816 7.82733L10.378 9.56356Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
