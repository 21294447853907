import { FieldTypes } from "@certa/types";
import type { OperatorsTypesValues } from "@certa/types";
import { numberFilterTypes } from "../../../../constants";
import { WithinTheLastValueField } from "./WithinTheLastValueField";
import { TextInputField } from "./TextInputField";
import { NumberInputField } from "./NumberInputField";
import { DatePickerInputField } from "./DatePickerInputField";
import { RelativeDateInputField } from "./RelativeDateInputField";
import { IsBetweenDateInputField } from "./IsBetweenDateInputField";

export const isDateField = (fieldType: FieldTypes | null | undefined) =>
  !!fieldType && [FieldTypes.DATE, FieldTypes.NAIVE_DATE].includes(fieldType);

export const getFieldComponent = (type: string) => {
  switch (type) {
    case "text":
      return TextInputField;
    case "number":
      return NumberInputField;
    case "absoluteDates":
      return DatePickerInputField;
    case "relativeDates":
      return RelativeDateInputField;
    case "isBetween":
      return IsBetweenDateInputField;
    case "isWithinLast":
      return WithinTheLastValueField;
    default:
      return TextInputField;
  }
};

export const getValueFieldType = (
  fieldType: FieldTypes,
  operatorValue: OperatorsTypesValues | null,
  useRelativeDates: boolean,
  isEnableFieldOptions?: boolean
) => {
  if (isDateField(fieldType)) {
    if (useRelativeDates) {
      return "relativeDates";
    } else if (operatorValue === "range") {
      return "isBetween";
    } else if (operatorValue === "is_within_last") {
      return "isWithinLast";
    } else {
      return "absoluteDates";
    }
  } else if (numberFilterTypes.includes(fieldType)) {
    return "number";
  } else if (
    isEnableFieldOptions &&
    ["single_select", "checkbox", "bool", "multi_select", "radio"].includes(
      fieldType
    )
  ) {
    return "single_select";
  }
  return "text";
};

export type ValueFieldType = ReturnType<typeof getValueFieldType>;
