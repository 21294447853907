import type { FilterConditionORM } from "@certa/types";

import { getDataType } from "@certa/common/utils/filterUtils";

import {
  destructureCustomAttributeTag,
  destructureProcessValueTag,
  destructureStepGroupValueTag,
  destructureStepValueTag,
  createProcessValueTag,
  getCustomAttributeTag
} from "@certa/common/utils/report";

import {
  FETCH_PROCESS_ATTR,
  FETCH_STEPGROUP_ATTR,
  FETCH_STEP_ATTR,
  processAttrDataORM
} from "@certa/common/constants";

export const getDetailsFromCustomAttributeTag = (tag: string) => {
  const { kindId = "", fieldTag } = destructureCustomAttributeTag(tag);
  let attributeType: FilterConditionORM["lhsType"] = "field";
  let attribute: FilterConditionORM["lhsSource"] | undefined = "answer";
  let stepTag = undefined;
  if (fieldTag.includes(FETCH_PROCESS_ATTR)) {
    const attributeTag = destructureProcessValueTag(fieldTag);
    if (attributeTag) {
      attributeType = "attr";
      attribute = attributeTag;
    }
  } else if (fieldTag.includes(FETCH_STEPGROUP_ATTR)) {
    const { stepGroupTag, stepGroupAttribute } =
      destructureStepGroupValueTag(fieldTag);
    if (stepGroupTag && stepGroupAttribute) {
      attributeType = "step_group";
      attribute = stepGroupAttribute;
      stepTag = stepGroupTag;
    }
  } else if (fieldTag.includes(FETCH_STEP_ATTR)) {
    const { stepTag: stepTagTemp, stepAttribute } =
      destructureStepValueTag(fieldTag);
    if (stepTagTemp && stepAttribute) {
      attributeType = "step";
      attribute = stepAttribute;
      stepTag = stepTagTemp;
    }
  }
  return {
    kindId: Number(kindId),
    fieldTag,
    attributeType,
    attribute,
    stepTag
  };
};

type ProcessAttrFilterCondition = {
  value: string;
  joinRelation?: FilterConditionORM["joinRelation"];
  lhsSource: "status_tag" | "region_id" | "business_unit_id";
  kindTag?: string;
  kindId: number;
};

export const createProcessAttrFilterCondition = ({
  value,
  joinRelation,
  kindTag,
  lhsSource,
  kindId
}: ProcessAttrFilterCondition): FilterConditionORM => {
  const customTag = getCustomAttributeTag(
    kindId,
    createProcessValueTag(lhsSource)
  );
  const fieldType = processAttrDataORM[lhsSource].fieldType;

  return {
    dataType: getDataType(fieldType),
    lhsType: "attr",
    lhsSource,
    tag: kindTag,
    operation: "eq",
    rhsValue: lhsSource === "status_tag" ? value : Number(value),
    kindId,
    joinRelation,
    extraJSON: {
      addedBy: "processType",
      fieldType,
      customTag
    }
  };
};
