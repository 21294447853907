import { css } from "emotion";
import { Stack } from "@certa/blocks/thanos";
import { RelativeDateField } from "./RelativeDateField";
import { RemoveIconButton } from "../AdvancedFilterButtons";
import { CascaderField } from "./CascaderField";
import type { RCFConditionProps } from "../AdvancedFilters.types";
import { getOperators, OperatorField } from "./OperatorField";
import { ValueField } from "./ValueFieldInput/ValueFieldInput";
import { getLastArrayElement } from "../../../utils/helper";
import {
  getValueFieldType,
  isDateField
} from "./ValueFieldInput/ValueFieldInput.utils";
import { useGetShowFilterFieldOptions } from "../../../toggles/useGetShowFilterFieldOptions";
import { getDetailsFromCustomAttributeTag } from "@certa/dashboards/src/components/utils/ormReportAdvancedFilterUtils";
import type { IntlShape } from "react-intl";
import { useIntl } from "react-intl";
import { useLabelsInReport } from "../../../toggles";
import { SHOW_LABELS_SUPPORTED_FIELD_TYPES } from "../../../toggles/useLabelsInReport";
import type { OperatorsDetails } from "../../../utils/filterUtils";

type AdvancedFilterConditionProps = RCFConditionProps & {
  customOperators?: OperatorsDetails;
};

export const AdvancedFilterCondition = ({
  conditionIndex,
  conditionAriaLabel,
  getFieldType,
  cascaderOptions,
  disabledCascader,
  optionsIsLoading,
  onChange,
  parentPath,
  isORMReport,
  value: condition,
  remove,
  kindId,
  isGroupQuery,
  customOperators
}: AdvancedFilterConditionProps) => {
  const intl = useIntl();
  const isEnabledFieldOptions = useGetShowFilterFieldOptions();
  const isShowLabelsInReportEnabled = useLabelsInReport();
  const shouldUseNewLabelValueOptions =
    isGroupQuery &&
    SHOW_LABELS_SUPPORTED_FIELD_TYPES.includes(condition.field?.fieldType) &&
    isShowLabelsInReportEnabled;

  const valueFieldType = getValueFieldType(
    condition.field?.fieldType,
    condition.operator,
    !!condition?.useRelativeDates,
    isEnabledFieldOptions || shouldUseNewLabelValueOptions
  );

  function handleChange(fieldName: string) {
    return function <T>(fieldValue: T) {
      const newCondition = { ...condition, [fieldName]: fieldValue };

      if (
        newCondition.field.fieldType !== condition.field.fieldType ||
        fieldName === "useRelativeDates"
      ) {
        const newOperators = getOperators({
          fieldType: newCondition.field.fieldType,
          useRelativeDates: !!newCondition.useRelativeDates,
          isORMReport
        });

        if (!newOperators.find(op => op.value === condition.operator)) {
          newCondition.operator = null;
        }
      }

      if (
        newCondition.field.path.join(".") !== condition.field.path.join(".")
      ) {
        // Clear the value field when the field changes
        newCondition.value = "";
      } else if (
        fieldName === "operator" &&
        (newCondition.operator === "is_empty" ||
          newCondition.operator === "is_set")
      ) {
        // Clear the value field when the user switches to 'Has Value' or 'Is Empty'
        newCondition.value = "";
      } else {
        const newValueFieldType = getValueFieldType(
          newCondition.field?.fieldType,
          newCondition.operator,
          !!newCondition?.useRelativeDates,
          isEnabledFieldOptions || shouldUseNewLabelValueOptions
        );
        // Clear the value field when the value field type changes
        if (newValueFieldType !== valueFieldType) {
          newCondition.value = "";
        }
      }
      onChange([...parentPath], newCondition);
    };
  }
  const { kindId: kindIdFromCustomTag, fieldTag } =
    getDetailsFromCustomAttributeTag(
      getLastArrayElement(condition.field?.path) || ""
    );
  return (
    <Stack
      direction="vertical"
      gap="s2"
      className={fieldsWrapperCss}
      role="gridcell"
    >
      <Stack gap="s2" align="center">
        <CascaderField
          value={condition.field}
          onChange={handleChange("field")}
          getFieldType={getFieldType}
          cascaderOptions={cascaderOptions}
          disabledCascader={disabledCascader}
          optionsIsLoading={optionsIsLoading}
        />
        <OperatorField
          value={condition.operator}
          fieldType={condition.field?.fieldType}
          useRelativeDates={!!condition.useRelativeDates}
          onChange={handleChange("operator")}
          isORMReport={isORMReport}
          customOperators={customOperators}
        />
        <ValueField
          fieldType={condition.field?.fieldType}
          operatorValue={condition.operator}
          fieldTag={fieldTag}
          onChange={handleChange("value")}
          value={condition.value}
          kindId={isORMReport ? `${kindIdFromCustomTag}` : kindId}
          valueFieldType={valueFieldType}
          id={conditionAriaLabel + " value"}
          shouldUseNewLabelValueOptions={shouldUseNewLabelValueOptions}
        />
        <RemoveIconButton
          onClick={() => remove(conditionIndex)}
          ariaLabel={buildRemoveConditionAriaLabel(conditionAriaLabel, intl)}
        />
      </Stack>
      {isDateField(condition.field?.fieldType) ? (
        <RelativeDateField
          value={!!condition.useRelativeDates}
          onChange={handleChange("useRelativeDates")}
        />
      ) : null}
    </Stack>
  );
};

const fieldsWrapperCss = css({
  flexGrow: 1,
  minWidth: "524px",
  maxWidth: "700px"
});

const buildRemoveConditionAriaLabel = (
  conditionLabel: string,
  intl: IntlShape
) => {
  return intl.formatMessage(
    {
      id: "report.removeCondition",
      defaultMessage: "Remove {conditionLabel}"
    },
    {
      conditionLabel
    }
  );
};
