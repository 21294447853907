import { GlobalSearch } from "./GlobalSearch";
import { SearchDialog } from "./SearchDialog/SearchDialog";
import { useNavigationDrawer } from "../NavigationSidebar";
import { ErrorBoundary } from "@certa/common/components/ErrorBoundary";

export const GlobalSearchWrapper = () => {
  const { isSearchModalOpen, setIsSearchModalOpen } = useNavigationDrawer();

  const handleSearchDialogClose = () => {
    setIsSearchModalOpen(false);
  };

  return (
    <SearchDialog
      show={isSearchModalOpen}
      onClose={handleSearchDialogClose}
      width="640px"
      height="min(619px, 95vh)"
    >
      <ErrorBoundary>
        <GlobalSearch hideSearchModal={handleSearchDialogClose} />
      </ErrorBoundary>
    </SearchDialog>
  );
};
