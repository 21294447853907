/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css, ClassNames } from "@emotion/core";
import type { FC, ReactElement } from "react";
import { useState, useMemo } from "react";
import { UserGroup } from "@certa/icons/components/UserGroup";
import {
  Avatar as BlocksAvatar,
  getAvatarLetters,
  Stack,
  Text,
  Tooltip
} from "@certa/blocks/thanos";
import type { TooltipProps } from "@certa/blocks/thanos";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import type { UserGroup as UserGroupType } from "@certa/types";
import { get as lodashGet } from "lodash-es";
import { AvatarColors, Avatar } from "@certa/catalyst/components/Avatar";
import { ButtonSizes, Button } from "@certa/catalyst/components/Button";
import type { PopoverPlacements } from "@certa/catalyst/components/Popover";
import { Popover } from "@certa/catalyst/components/Popover";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { Tag } from "@certa/catalyst/components/Tag";

type UserData = {
  id: number;
  fullName: string | null;
  email: string | null;
  groups: UserGroupType[];
};

/**
 * Since the tooltip width is fixed so we according to
 * the view we can use a char limit
 *
 * TODO: Umer to see if we need to use popover component instead of tooltip
 */
const MAX_CHAR_LIMIT = 40;

type UserDetailsTooltipProps = {
  user: UserData;
  children: ReactElement;
  placement?: PopoverPlacements;
  overlayWidth?: number;
  openInPortal?: boolean;
};

export const UserDetailsTooltip = ({
  user,
  children,
  placement = "auto",
  overlayWidth,
  openInPortal
}: UserDetailsTooltipProps) => {
  const intl = useIntl();
  const currentUserId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );
  const [shouldShowAllGroups, setShouldShowAllGroups] = useState(false);

  const userName = user?.fullName ? user?.fullName : user?.email;
  const charLimit = user.groups.map(group => group.name).join("").length;

  const groupsAssignedLabel =
    user.groups.length === 0
      ? ""
      : `Groups Assigned: ${user.groups.map(group => group.name).join(", ")}`;
  const userDetailsAriaDescripton = `User Details - User Name: ${userName} ${groupsAssignedLabel}`;

  /**
   * Here we are calculating exactly how much tags can be
   * rendered in a single row
   */
  const tagLimit = user.groups.reduce(
    ({ limit, name }, group) =>
      name.length + group.name.length < MAX_CHAR_LIMIT
        ? { limit: limit + 1, name: `${name}${group.name}` }
        : { limit, name },

    { limit: 0, name: "" }
  );

  const groups =
    charLimit > MAX_CHAR_LIMIT && !shouldShowAllGroups
      ? user.groups.slice(0, tagLimit.limit)
      : user.groups;

  const toggleShowAll = () => setShouldShowAllGroups(!shouldShowAllGroups);

  const fixedOverlayWidth = useMemo(() => {
    if (overlayWidth !== undefined) {
      return overlayWidth;
    }
    return charLimit > MAX_CHAR_LIMIT ? 547 : 400;
  }, [overlayWidth, charLimit]);

  return (
    <Popover
      trigger="hover"
      ariaDescription={userDetailsAriaDescripton}
      openInPortal={openInPortal}
      placement={placement}
      showArrow
      content={
        <Stack
          onClick={e => e?.stopPropagation?.()}
          gutter={user.groups.length ? "s6 s6 s4 s6" : "s6"}
          gap="s4"
          direction="vertical"
          css={css`
            width: ${fixedOverlayWidth}px;
          `}
          data-testid="userDetailsTooltip"
        >
          <Typography
            variant={TypographyVariants.LABEL_SM_BOLD}
            color={TypographyColors.NEUTRAL_600}
          >
            {intl.formatMessage({
              id: "tasks.userTooltip.heading",
              defaultMessage: "User Details"
            })}
          </Typography>
          <Stack align="center" justify="space-between">
            <Stack gap="s2" align="center">
              <Avatar
                aria-label={userName || ""}
                color={
                  user.id === currentUserId
                    ? AvatarColors.BRAND
                    : AvatarColors.NEUTRAL
                }
              >
                {getAvatarLetters(userName || "")}
              </Avatar>
              <Stack
                gap="s2"
                align="center"
                css={css`
                  margin-bottom: 0.25rem;
                `}
              >
                <Typography
                  color={TypographyColors.NEUTRAL_800}
                  variant={TypographyVariants.TITLE_SM}
                >
                  {userName}
                </Typography>
                {user.id === currentUserId ? (
                  <Typography
                    variant={TypographyVariants.TITLE_SM}
                    color={TypographyColors.NEUTRAL_600}
                  >
                    (
                    {intl.formatMessage({
                      id: "tasks.userTooltip.you",
                      defaultMessage: "You"
                    })}
                    )
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            {/* Add Send Message button here */}
          </Stack>
          <Stack align="flex-start" justify="space-between" gap="s6">
            <Stack
              css={css`
                display: inline-block;
                max-height: 150px;
                overflow-y: auto;
              `}
            >
              {groups.map(group => (
                <Stack
                  css={css`
                    display: inline-flex;
                    margin: 0 var(--s2) var(--s2) 0;
                  `}
                  key={`${group.id}`}
                  justify="center"
                  align="center"
                >
                  <Tag
                    icon={<UserGroup autoSize color="neutral-100" />}
                    label={group.name}
                    multiline
                  >
                    <Typography variant={TypographyVariants.LABEL_SM_BOLD}>
                      {group.name}
                    </Typography>
                  </Tag>
                </Stack>
              ))}
            </Stack>
            {charLimit > MAX_CHAR_LIMIT && (
              <div style={{ flexShrink: 0 }}>
                <Button onClick={toggleShowAll} size={ButtonSizes.SMALL}>
                  {shouldShowAllGroups
                    ? intl.formatMessage({
                        defaultMessage: "View Less",
                        id: "comments.viewLessGroups"
                      })
                    : `+ ${
                        user.groups.length - groups.length
                      } ${intl.formatMessage({
                        id: "tasks.userTooltip.moreGroups",
                        defaultMessage: "More"
                      })}`}
                </Button>
              </div>
            )}
          </Stack>
        </Stack>
      }
    >
      {children}
    </Popover>
  );
};

/**
 * @deprecated This component is deprecated and should not be used.
 * Preferred use UserDetailsTooltipNew
 *
 * Reason - why this is not deleted ?
 * In ImpersonationUserList where it is used inside dropdown component
 * its not allowing for mouse event to trigger for popover,
 * causing bug, user not able to move mouse in popover.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UserDetailsTooltipOld: FC<{
  user: UserData;
  children: ReactElement;
  placement?: TooltipProps["placement"];
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  overlayWidth?: number;
  zIndex?: number | string;
}> = ({
  user,
  children,
  placement,
  getPopupContainer,
  overlayWidth,
  zIndex = ""
}) => {
  const intl = useIntl();

  const [shouldShowAllGroups, setShouldShowAllGroups] = useState(false);

  const currentUserId = useSelector(state =>
    lodashGet(state, "authentication.user.id")
  );

  const avatarConfig = {
    background: user.id === currentUserId ? "var(--brand)" : "#EAEAEA",
    color: user.id === currentUserId ? "" : "#00164E"
  };

  const userName = user?.fullName ? user?.fullName : user?.email;

  const charLimit = user.groups.map(group => group.name).join("").length;

  /**
   * Here we are calculating exactly how much tags can be
   * rendered in a single row
   */
  const tagLimit = user.groups.reduce(
    ({ limit, name }, group) =>
      name.length + group.name.length < MAX_CHAR_LIMIT
        ? { limit: limit + 1, name: `${name}${group.name}` }
        : { limit, name },

    { limit: 0, name: "" }
  );

  const groups =
    charLimit > MAX_CHAR_LIMIT && !shouldShowAllGroups
      ? user.groups.slice(0, tagLimit.limit)
      : user.groups;

  const toggleShowAll = () => setShouldShowAllGroups(!shouldShowAllGroups);

  const fixedOverlayWidth = useMemo(() => {
    if (overlayWidth !== undefined) {
      return overlayWidth;
    }

    return charLimit > MAX_CHAR_LIMIT ? 547 : 400;
  }, [overlayWidth, charLimit]);

  return (
    <ClassNames>
      {({ css: stringifiedCSS }) => (
        <Tooltip
          getPopupContainer={
            getPopupContainer
              ? getPopupContainer
              : (triggerNode: HTMLElement) =>
                  triggerNode.parentNode as HTMLElement
          }
          placement={placement}
          destroyTooltipOnHide={true}
          overlayClassName={stringifiedCSS`
            z-index: ${zIndex || ""};
            .ant-tooltip-arrow:before {
              background: var(--neutral-0) !important;
            }
            .ant-tooltip-inner {
              background: var(--neutral-0) !important;
              box-shadow: 0px 2px 16px rgba(0, 22, 78, 0.1);
              border-radius: var(--big-border-radius) !important;
              padding: 0px !important;
            }
          `}
          overlay={
            <Stack
              onClick={e => e?.stopPropagation?.()}
              gutter={user.groups.length ? "s6 s6 s4 s6" : "s6"}
              gap="s4"
              direction="vertical"
              css={css`
                width: ${fixedOverlayWidth}px;
              `}
              data-testid="userDetailsTooltip"
            >
              <Text color="neutral-70" variant="p2-bold-upper">
                {intl.formatMessage({
                  id: "tasks.userTooltip.heading",
                  defaultMessage: "User Details"
                })}
              </Text>
              <Stack align="center" justify="space-between">
                <Stack gap="s2" align="center">
                  <BlocksAvatar
                    size="small"
                    alt={userName || ""}
                    backgroundColor={avatarConfig.background}
                    color={avatarConfig.color}
                  >
                    {getAvatarLetters(userName || "")}
                  </BlocksAvatar>
                  <Stack
                    gap="s2"
                    align="flex-start"
                    css={css`
                      margin-bottom: 3px;
                    `}
                  >
                    <Text variant="h3-bold" color="neutral-100">
                      {userName}
                    </Text>
                    {user.id === currentUserId ? (
                      <Text
                        variant="h3-bold"
                        color="neutral-70"
                        transform="uppercase"
                      >
                        (
                        {intl.formatMessage({
                          id: "tasks.userTooltip.you",
                          defaultMessage: "You"
                        })}
                        )
                      </Text>
                    ) : null}
                  </Stack>
                </Stack>
                {/* Add Send Message button here */}
              </Stack>
              <Stack align="flex-start" justify="space-between" gap="s6">
                <Stack
                  css={css`
                    display: inline-block;
                    max-height: 150px;
                    overflow-y: auto;
                  `}
                >
                  {groups.map(group => (
                    <Stack
                      css={css`
                        color: var(--neutral-100);
                        padding: 6px;
                        border-radius: var(--small-border-radius);
                        background-color: var(--neutral-20);
                        display: inline-flex;
                        margin: 0 var(--s2) var(--s2) 0;
                      `}
                      key={`${group.id}`}
                      justify="center"
                      align="center"
                    >
                      <UserGroup
                        size={12}
                        css={css`
                          margin-right: 6px;
                        `}
                      />
                      <Text variant="p1-semibold" color="neutral-100">
                        {group.name}
                      </Text>
                    </Stack>
                  ))}
                </Stack>
                {charLimit > MAX_CHAR_LIMIT && (
                  <Stack
                    onClick={toggleShowAll}
                    css={css`
                      width: 68px;
                      cursor: pointer;
                    `}
                  >
                    <Text
                      variant="p1-regular"
                      css={css`
                        &:hover {
                          color: var(--brand);
                        }
                      `}
                      color="brand-15"
                    >
                      {shouldShowAllGroups
                        ? intl.formatMessage({
                            defaultMessage: "View Less",
                            id: "comments.viewLessGroups"
                          })
                        : `+ ${
                            user.groups.length - groups.length
                          } ${intl.formatMessage({
                            id: "tasks.userTooltip.moreGroups",
                            defaultMessage: "More"
                          })}`}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          }
        >
          {children}
        </Tooltip>
      )}
    </ClassNames>
  );
};
