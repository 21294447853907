import React, { forwardRef } from "react";

import { classNames } from "../../utils/common";
import { getTransformedPaddingWithCSSVariables } from "../utils";

import type { StackProps } from "./types";

import styles from "./Stack.module.css";

export const Stack = forwardRef<HTMLElement, StackProps>((props, ref) => {
  const {
    as = "div",
    direction,
    gap = 0,
    role,
    align,
    justify,
    children,
    dangerouslySetClassName = "",
    padding,
    width,
    maxWidth,
    height,
    maxHeight,
    backgroundColor,
    overflow,
    overflowX,
    overflowY,
    onClick,
    onKeyDown,
    tabIndex,
    id,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    "aria-hidden": isAriaHidden,
    "aria-busy": isAriaBusy,
    "aria-disabled": isAriaDisabled
  } = props;

  const overflowXValue = overflowX || overflow;
  const overflowYValue = overflowY || overflow;

  return (
    <>
      {React.createElement(as, {
        role,
        ref,
        children,
        onClick,
        onKeyDown,
        tabIndex,
        id,
        "aria-label": ariaLabel,
        "aria-labelledby": ariaLabelledBy,
        "aria-describedby": ariaDescribedBy,
        "aria-details": ariaDetails,
        "aria-hidden": isAriaHidden,
        "aria-busy": isAriaBusy,
        "aria-disabled": isAriaDisabled,
        style: {
          gap: `var(--space-${gap})`,
          justifyContent: justify,
          alignItems: align,
          padding: getTransformedPaddingWithCSSVariables(padding),
          width,
          maxWidth,
          height,
          maxHeight,
          backgroundColor,
          overflow,
          overflowX: overflowXValue,
          overflowY: overflowYValue
        },
        className: classNames({
          [dangerouslySetClassName]: !!dangerouslySetClassName,
          [styles.catalystStackContainer]: true,
          [styles.catalystStackContainerHorizontal]: direction === "horizontal"
        })
      })}
    </>
  );
});
