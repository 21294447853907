import { routeList, router, type CustomRouteObject } from "./routes";

const getPathToExtraPropsMap = () =>
  routeList.reduce(
    (acc, route) => {
      acc[route.path ?? ""] = route.extraProps;
      if (route.children) {
        route.children.forEach(child => {
          acc[child.path ?? ""] = child.extraProps;
        });
      }
      return acc;
    },
    {} as Record<string, CustomRouteObject["extraProps"]>
  );

/**
 * Function to preload auth modules
 */
export const preloadAuthModules = () => {
  const pathToExtraPropsMap = getPathToExtraPropsMap();
  router.routes[0].children?.forEach(route => {
    const extraProps = pathToExtraPropsMap[route.path ?? ""];
    if (extraProps?.routeType === "public") {
      route.lazy?.();
    }
  });
};

/**
 * Function to preload private modules
 */
export const preloadPrivateModules = () => {
  const pathToExtraPropsMap = getPathToExtraPropsMap();
  router.routes[0].children?.forEach(route => {
    const extraProps = pathToExtraPropsMap[route.path ?? ""];
    if (extraProps?.routeType === "private") {
      route.lazy?.();
    }
  });
};
