import {
  Drawer,
  DrawerHeader,
  DrawerPlacements
} from "@certa/catalyst/components/Drawer";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { useIntl } from "react-intl";
import { getUserProfileDrawerPositionInMobile } from "@certa/common/utils/getPositionUtils";
import { ErrorBoundary } from "@certa/common/components/ErrorBoundary";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { userLogout } from "main/src/modules/LoginPage/loginSlice";
import { Language } from "@certa/icons/components/Language";
import { QuestionOutline } from "@certa/icons/components/QuestionOutline";
import { ChevronLeftLight } from "@certa/icons/components/ChevronLeftLight";
import { useLanguages } from "../NavigationSidebar/hooks/useLanguages";
import { useShowHeaderDropdownOptions } from "../../hooks/useShowHeaderDropdownOptions";
import type { DropdownOption } from "../../hooks/useFilterHeaderOptionBasedOnUserGroup";
import {
  TypographyColors,
  Typography,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import styles from "./MobileUserDrawer.module.css";
import { MixPanelEvents, MixPanelActions } from "main/src/js/_helpers/mixpanel";
import { useState } from "react";
import { type OptionItem } from "@certa/types";
import { getIntlBody } from "main/src/js/_helpers/intl-helpers";
import { MenuList, type MenuItemType } from "./MenuList";

type MobileUserDrawerProps = {
  show: boolean;
  onClose: () => void;
};

type Link = {
  label: string;
  url: string;
}[];

export const MobileUserDrawer = ({ show, onClose }: MobileUserDrawerProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector(state => state?.authentication);
  const [activeMenuId, setActiveMenuId] = useState<string | null>(null);

  const { supportedLanguages, handleLanguageChange, renderLanguageMenuItem } =
    useLanguages();

  const isSupplierUser = authentication?.user?.kind === 2;
  const showHeaderDropdownOptions = useShowHeaderDropdownOptions();
  const headerLinks: Link =
    useAppSelector(state => state.config?.custom_ui_labels?.header_links) || [];
  const shouldShowHeaderLinks = headerLinks?.length > 0 && !isSupplierUser;

  const languageText = intl.formatMessage({
    id: "userProfileMenu.languageText",
    defaultMessage: "Languages"
  });

  const helpText = intl.formatMessage({
    id: "userProfileMenu.help",
    defaultMessage: "Help"
  });

  const logOutText = intl.formatMessage({
    id: "userProfileMenu.logOutMessage",
    defaultMessage: "Log out"
  });

  const onSelectLanguage = (language: OptionItem) => {
    MixPanelActions.track(
      MixPanelEvents.navbar.NAV_PROFILE_LANGUAGE_CLICK_SWITCH_LANGUAGE,
      { language: language.value }
    );
    handleLanguageChange(language.value);
    setActiveMenuId(null);
  };

  const onSelectLink = (value: string, index: number) => {
    MixPanelActions.track(
      MixPanelEvents.navbar.NAV_PROFILE_HELP_CLICK_OPEN_USER_HELP_LINK,
      { helpLinkIndex: index }
    );
    window.open(value);
  };

  const handleLogout = () => {
    dispatch(userLogout());
    onClose();
  };

  const getMenuItems = (): MenuItemType[] => {
    const languageMenuItems = supportedLanguages.map(language => ({
      id: `language-${language.value}`,
      label: renderLanguageMenuItem(language),
      onClick: () => onSelectLanguage(language)
    }));
    // Initialize the menu items array with the language menu
    const menuItems: MenuItemType[] = [
      {
        id: "language",
        label: languageText,
        icon: <Language size={16} />,
        subMenuItems: languageMenuItems
      }
    ];
    // Add help menu if we have any help items
    const hasHelpItems =
      showHeaderDropdownOptions.length > 0 ||
      (shouldShowHeaderLinks && headerLinks.length > 0);
    if (hasHelpItems) {
      const helpMenuItems: MenuItemType[] = [];

      // Add header dropdown options
      showHeaderDropdownOptions.forEach(
        (option: DropdownOption, index: number) => {
          helpMenuItems.push({
            id: `help-${index}`,
            label: option.label,
            onClick: () => window.open(option?.url || "")
          });
        }
      );
      // Add header links if applicable
      if (shouldShowHeaderLinks) {
        headerLinks.forEach((link, index) => {
          helpMenuItems.push({
            id: `help-link-${index}`,
            label: getIntlBody(link, "label") || getIntlBody(link, "label_en"),
            onClick: () => onSelectLink(link.url, index)
          });
        });
      }
      menuItems.push({
        id: "help",
        label: helpText,
        icon: <QuestionOutline size={16} />,
        subMenuItems: helpMenuItems
      });
    }
    // Add logout menu item
    menuItems.push({
      id: "logout",
      label: logOutText,
      onClick: handleLogout
    });
    return menuItems;
  };

  const getActiveMenuTitle = () => {
    if (!activeMenuId) return "Account";
    const activeItem = getMenuItems().find(item => item.id === activeMenuId);
    return activeItem?.label || "Account";
  };

  return (
    <ErrorBoundary>
      <Drawer
        title=""
        aria-label="User profile drawer"
        show={show}
        width="100%"
        height="100%"
        onClose={() => {
          setActiveMenuId(null);
          onClose();
        }}
        showCloseIcon={true}
        getPositioning={getUserProfileDrawerPositionInMobile}
        placement={DrawerPlacements.LEFT}
        canShowDimmer={false}
        padding="20"
      >
        <DrawerHeader>
          <Typography
            variant={TypographyVariants.BODY_BOLD}
            color={TypographyColors.NEUTRAL_800}
            className={styles.mobileUserDrawerHeaderTitle}
          >
            <Stack direction="horizontal" align="center" justify="flex-start">
              {activeMenuId && (
                <button
                  onClick={() => setActiveMenuId(null)}
                  className={styles.mobileUserDrawerHeaderBackButton}
                >
                  <ChevronLeftLight size={16} />
                </button>
              )}
              {getActiveMenuTitle()}
            </Stack>
          </Typography>
        </DrawerHeader>

        <Stack direction="vertical" width="100%">
          <MenuList
            items={getMenuItems()}
            activeMenuId={activeMenuId}
            onMenuSelect={setActiveMenuId}
          />
        </Stack>
      </Drawer>
    </ErrorBoundary>
  );
};
