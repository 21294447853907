import type { FC, PropsWithChildren } from "react";
import React from "react";
import { Timeline as AntTimeline } from "antd";
import type {
  TimelineProps as AntTimelineProps,
  TimeLineItemProps as AntTimeLineItemProps
} from "antd/lib/timeline/index";
import { css, cx } from "emotion";
import styled from "@emotion/styled";
import type { IconProps } from "@certa/icons";
import { TIMELINE_ACTION_MAPPER } from "./constants";
import { TimelineNode } from "./Node";
import type { ActionType } from "./types";
import { TimelineEdges } from "./types";

type TimeLineItemProps = PropsWithChildren<
  {
    /** type of node which matches the styles in actionMapper */
    actionType?: ActionType;
    /** Custom node -> styles mapper can be passed */
    actionMapper?: {
      [key in ActionType]: {
        color: string;
        bgColor: string;
        icon: FC<IconProps>;
      };
    };
  } & AntTimeLineItemProps
>;

const timelineItemTheme = css`
  &.ant-timeline-item {
    padding-left: var(--s4);
  }
  --label: CustomTimelineItem;
`;

export const TimelineItem: FC<TimeLineItemProps> = props => {
  const {
    actionType,
    actionMapper = TIMELINE_ACTION_MAPPER,
    className,
    children,
    ...restProps
  } = props;
  const {
    color,
    icon: Icon,
    bgColor
  } = actionMapper[actionType || "viewed"] || actionMapper.viewed;
  return (
    <AntTimeline.Item
      className={cx(timelineItemTheme, className)}
      dot={
        <TimelineNode
          icon={Icon}
          defaultBackground={bgColor}
          hoverBackground={bgColor}
          defaultSVGColor={color}
          hoverSVGColor={color}
          padding="var(--s1)"
          circular
        />
      }
      {...restProps}
    >
      {children}
    </AntTimeline.Item>
  );
};
TimelineItem.defaultProps = {
  actionType: "viewed",
  actionMapper: TIMELINE_ACTION_MAPPER
};

type TimelineProps = {
  edges?: "tail" | "node" | "tail-top" | "tail-bottom";
  // Specify start and end of timeline (node | tail)
  // defaultValue = node
  children?: React.ReactNode;
} & AntTimelineProps;

const CustomTimeline: FC<TimelineProps> = props => {
  const { children, edges, ...restProps } = props;
  const isReady = Array.isArray(children) && children.length > 0;

  return (
    <AntTimeline {...restProps}>
      {(edges === TimelineEdges.TAIL || edges === TimelineEdges.TAILTOP) &&
        isReady && (
          <AntTimeline.Item className={timelineItemTheme} dot={<div />}>
            <div style={{ height: "var(--s6)" }} />
          </AntTimeline.Item>
        )}
      {children}
    </AntTimeline>
  );
};

export const Timeline = styled(CustomTimeline)`
  &.ant-timeline {
    padding-left: 6px;
    padding-top: ${props =>
      (props.edges === TimelineEdges.NODE ||
        props.edges === TimelineEdges.TAILBOTTOM) &&
      "var(--s3)"};
    .ant-timeline-item {
      .ant-timeline-item-tail {
        border-width: var(--s1);
        border-color: var(--neutral-20);
      }
      ${props =>
        props?.edges &&
        (props.edges === TimelineEdges.TAIL ||
          props.edges === TimelineEdges.TAILTOP) &&
        `
          &:first-child > .ant-timeline-item-tail {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }
        `}
      ${props =>
        props?.edges &&
        (props.edges === TimelineEdges.NODE ||
          props.edges === TimelineEdges.TAILTOP) &&
        `
          &:last-child > .ant-timeline-item-tail {
            display: none;
            height: 34px;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        `}
    }
    &.ant-timeline-left .ant-timeline-item-tail {
      left: 3px;
    }
    &.ant-timeline-alternate .ant-timeline-item-tail {
      left: calc(50% - 1px);
    }
    &.ant-timeline-right .ant-timeline-item-tail {
      left: calc(100% - 4px - 3px);
    }
  }
  --label: CustomTimeline;
`;
