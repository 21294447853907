// TODO: Tree shake
import { FILTERS_ENUM } from "./constants";

const supportedFiltersForSeachParams = [
  FILTERS_ENUM.SEARCH_FILTER.name,
  FILTERS_ENUM.STATUS_FILTER.name,
  FILTERS_ENUM.BUSINESS_UNIT_FILTER.name,
  FILTERS_ENUM.REGION_FILTER.name,
  FILTERS_ENUM.ADVANCED_FILTER.name,
  FILTERS_ENUM.ADVANCED_FILTER.name,
  FILTERS_ENUM.KIND_FILTER.name,
  FILTERS_ENUM.START_DATE_FILTER.name,
  FILTERS_ENUM.END_DATE_FILTER.name
];
// Private function to get Rank
const getRank = ({ page, index, count, isAscending }) => {
  if (isAscending) {
    return (page - 1) * 20 + index + 1;
  } else {
    return count - (page - 1) * 20 - index;
  }
};

export const appendRankInWorkflowItem = (
  { results: workflows = [], next, count, ...rest },
  filterParams
) => {
  if (!Array.isArray(workflows)) {
    console.warn(
      `appendRankInWorkflowItem: Expected an array recieved ${typeof workflows}`
    );
    return workflows;
  }

  const page = filterParams[FILTERS_ENUM.PAGE_FILTER.key] || 1;
  const sortingOrder = filterParams[FILTERS_ENUM.ORDERING_FILTER.key];
  const workflowWithRank = workflows.map((workflow, index) => ({
    ...workflow,
    rank: sortingOrder
      ? getRank({
          page,
          index,
          count,
          isAscending: !sortingOrder.startsWith("-")
        })
      : null
  }));
  return {
    next,
    count,
    results: workflowWithRank,
    ...rest
  };
};

export function createFilterParams(selectedFilters) {
  const params = {};

  for (const filterName in selectedFilters) {
    const { key, value, meta } = selectedFilters[filterName];
    params[key] = value;

    /**
     * Since the filters are storing kind tag instead of kind id every where so
     * applying this check to make export worflow api work which is a v1 api
     * and still used kind id instead of kind tag as payload
     */
    if (key === FILTERS_ENUM.KIND_FILTER.key) {
      params["kind"] = meta.id;
    }
  }

  // Removing kind filter param for search and my task
  // It needs excluded only at the time of filtering, not from the UI state
  if (
    params[FILTERS_ENUM.MY_TASK_FILTER.key] ||
    // Only remove the kind_tag filter when
    // using text search and REMOVE_KIND_FILTER is applied
    (params[FILTERS_ENUM.SEARCH_FILTER.key] &&
      params[FILTERS_ENUM.REMOVE_KIND_FILTER.key])
  ) {
    delete params[FILTERS_ENUM.KIND_FILTER.key];
  }

  // Not required in the API
  delete params[FILTERS_ENUM.REMOVE_KIND_FILTER.key];

  return params;
}

export function createSearchParams(selectedFilters) {
  const params = {};

  for (const filterName in selectedFilters) {
    const { key, value, name } = selectedFilters[filterName];
    if (supportedFiltersForSeachParams.includes(name) && key && value) {
      params[encodeURIComponent(key)] = encodeURIComponent(value);
    }
  }

  return new URLSearchParams(params).toString();
}

export function getRatingFromLCData(lcData) {
  return (
    lcData
      .map(item => ({
        ...item,
        label: item.translated_label || item.label
      }))
      .find(item => item.format === "rating") || null
  );
}

/**
 * Parent Alert color code logic:
 * Check visibility priority of all items in sub-category of alert is 0 or not, if yes
 * then use the parent color label. If no then sort the sub category array in
 * ascending order according to visibility priority and then get the color
 * of the child whose count is greater than 0 and has the highest
 * vibility priority(1 > 2 > 3 > ————— ∞)
 */
