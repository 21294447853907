import type {
  ChartConfigurationsFormValuesORM as FORM_VALUES_ORM,
  ChartConfigurationsFormValues as FORM_VALUES
} from "../createReport/chartConfiguration/constants";

import { chartConfigFormNames as formNames } from "../createReport/chartConfiguration/constants";

import type { FormInstance } from "antd/lib/form/index";

import {
  KEY_FIELDS_TAG,
  MATH_AND_FORMULAS_TAG,
  METADATA_TAG,
  VALUES_TAG,
  xAxisSystemAttributes
} from "../constants";
import type { XAxisSystemAttributes } from "../constants";
import { X_AXIS_DATA_TYPE_INDEX } from "@certa/chartreport/src/constants";
import { getLastArrayElement } from "@certa/common/utils/helper";
import {
  ALL_FIELDS_TAG,
  ALL_STEPS_TAG,
  getAggregateMetricType,
  getIsStackedBarChart,
  getPathFromCycleTimePath,
  INDEX_ONE,
  INDEX_ZERO,
  ONE,
  PROCESS_SWIMLANES_TAG
} from "@certa/common/constants";
import type {
  YAxisChart,
  MetricDataTypes,
  ChartConfig,
  ChartIdentifiers,
  GroupByDateOperation,
  Metric,
  MetricAttributes,
  FormattedProcess
} from "@certa/common/types";

export const getYAxisOtherConfig = (yAxisChart: YAxisChart) => {
  const yAxisOtherConfig = {
    outputDataType: [] as MetricDataTypes[]
  };
  yAxisChart.forEach(yAxisChart => {
    if (
      typeof yAxisChart === "string" ||
      (typeof yAxisChart !== "string" &&
        (yAxisChart.col_type === "step" ||
          yAxisChart.col_type === "step_group") &&
        yAxisChart.col === "cycle_time")
    ) {
      yAxisOtherConfig.outputDataType.push("cycle-time");
    } else {
      yAxisOtherConfig.outputDataType.push(yAxisChart.data_type);
    }
  });
  return yAxisOtherConfig;
};

export const getHasCycleTimeAttributes = (
  xAxisOption?: FORM_VALUES["x-axis"]
) => {
  return !!xAxisOption?.some(xAxis => {
    const tag = getLastArrayElement(xAxis?.fieldName);
    return !!(
      tag?.endsWith(PROCESS_SWIMLANES_TAG) || tag?.includes(ALL_STEPS_TAG)
    );
  });
};

type ResetAxesDataInChartConfig = {
  handleConfigChange: (_: any, allFields: any) => void;
  form: FormInstance;
};
export const resetChartConfigAxesData = ({
  handleConfigChange,
  form
}: ResetAxesDataInChartConfig) => {
  const formValues: Partial<FORM_VALUES> = {
    [formNames.X_AXIS]: [],
    [formNames.Y_AXIS]: [],
    [formNames.SECOND_X_AXIS]: [],
    [formNames.X_AXIS_LABEL]: "",
    [formNames.Y_AXIS_LABEL]: "",
    [formNames.SHOW_X_AXIS_LABEL]: "auto",
    [formNames.SHOW_Y_AXIS_LABEL]: "auto",
    [formNames.SHOW_PERCENTAGE_IN_VALUE_LABELS]: false,
    [formNames.COLORS]: {
      activeColorType: "singleTone"
    }
  };

  const newFormValues = {
    ...form.getFieldsValue(true),
    ...formValues
  };

  form.setFieldsValue(newFormValues);
  handleConfigChange(undefined, newFormValues);
};

type ResetFormYAxis = {
  yAxis: FORM_VALUES["y-axis"] | FORM_VALUES_ORM["y-axis"] | undefined;
  hasCycleTimeAttributes: boolean;
};

export const resetFormYAxis = ({
  hasCycleTimeAttributes,
  yAxis
}: ResetFormYAxis) => {
  if (!yAxis) return false;
  // reset the y axis if fields more than 1 or not has cycle-time on axis
  // when hasCycleTimeAttributes is true
  const hasMoreThanOneOptionForCycleTIme =
    hasCycleTimeAttributes &&
    (yAxis?.length > ONE ||
      (yAxis?.length === ONE &&
        yAxis[INDEX_ZERO]?.fieldName?.length &&
        !yAxis[INDEX_ZERO].fieldName?.includes("cycle-time")));

  // reset the y axis if cycle time option on x-axis is cleared but y had cycle time selected
  const hasCycleTimeOnYWhenXisCleared =
    !hasCycleTimeAttributes &&
    yAxis?.some(option => option?.fieldName?.includes("cycle-time"));

  return hasMoreThanOneOptionForCycleTIme || hasCycleTimeOnYWhenXisCleared;
};

export const prepareInitialChartConfigFormValues = (
  chartConfig: Partial<ChartConfig> | undefined,
  chartType: ChartIdentifiers
): Partial<FORM_VALUES> => {
  const xAxisParm = chartConfig?.["x-axis"] ?? [];

  const xAxisArray = Array.isArray(xAxisParm) ? xAxisParm : [xAxisParm];
  let xAxisMultiPaths: string[][] = [];
  let xAxisMultiOperations: GroupByDateOperation[] = [];

  xAxisArray?.forEach(xAxis => {
    if (xAxis?.path) {
      const xAxisPrefix = getXAxisPrefix(xAxis.path);
      xAxisMultiPaths.push([...xAxisPrefix, ...xAxis.path]);
      const operation = getOperationFromXAxis(xAxis);
      xAxisMultiOperations.push((operation as GroupByDateOperation) ?? "");
    }
  });
  const otherConfig = chartConfig?.otherConfig;

  if (otherConfig?.cycleTimePath && !xAxisMultiPaths?.length) {
    xAxisMultiPaths = [getPathFromCycleTimePath(otherConfig.cycleTimePath)];
  }
  // remove the second x-axis if the chart stacked or heat-map
  else if (
    getIsStackedBarChart(chartConfig, chartType) ||
    chartType === "HEAT_MAP_CHART"
  ) {
    xAxisMultiPaths = [xAxisMultiPaths[INDEX_ZERO]];
    xAxisMultiOperations = [xAxisMultiOperations[INDEX_ZERO]];
  }
  // remove the last x-axis if the chart is grouped
  else if (otherConfig?.isGroupedBarChart) {
    xAxisMultiPaths.pop();
    xAxisMultiOperations.pop();
  }

  if (chartConfig?.isCycleTimeReport) {
    return {
      [formNames.X_AXIS]: [
        {
          [formNames.X_AXIS_FIELD_NAME]: xAxisMultiPaths[INDEX_ZERO],
          [formNames.X_AXIS_OPERATION]: xAxisMultiOperations[INDEX_ZERO]
        }
      ],
      [formNames.Y_AXIS]: [
        {
          fieldName: [METADATA_TAG, "cycle-time"],
          metric: getAggregateMetricType(chartConfig?.["y-axis"]) as Metric
        }
      ],
      ...chartConfig?.otherConfig
    };
  }

  const initialValues: Record<string, any> = {
    [formNames.X_AXIS]: xAxisMultiPaths.map((path, index) => ({
      [formNames.X_AXIS_FIELD_NAME]: path,
      [formNames.X_AXIS_OPERATION]: xAxisMultiOperations[index]
    })),
    [formNames.Y_AXIS]:
      (chartConfig?.["y-axis"] as MetricAttributes[])?.map(config => {
        const { mtr_type: mtrType, col, ...restConfig } = config;

        let yAxisPrefix: string[] = [];
        const pathPrefix = restConfig.path?.[0];

        /**
         *  To honour prev chart config hierarchy for y-axis, else user will
         *  see blank fields on x and y-axis
         *  TODO: remove code block once the fix reaches PROD
         */
        if (
          ![METADATA_TAG, VALUES_TAG, MATH_AND_FORMULAS_TAG].includes(
            pathPrefix
          )
        ) {
          if (col === "id" && mtrType === "count") {
            yAxisPrefix = [MATH_AND_FORMULAS_TAG];
          } else if (["created_at", "last_activity"].includes(col)) {
            yAxisPrefix = [METADATA_TAG];
          } else {
            yAxisPrefix = [VALUES_TAG];
          }
        }
        const fieldName = [...yAxisPrefix];
        // This check is to avoid the page blank error since some of the
        // old chart config does not have the path values
        /* 
          TODO: Handle - x-axis or y-axis field should display the value in the UI
          (cascader) based on the config (without the path values)
        */
        if (restConfig?.path?.length) {
          fieldName.push(...restConfig.path);
        }
        return {
          fieldName,
          metric: mtrType
        };
      }) || [],
    ...chartConfig?.otherConfig
  };

  // for second x-axis
  if (
    getIsStackedBarChart(chartConfig, chartType) ||
    otherConfig?.isGroupedBarChart ||
    chartType === "HEAT_MAP_CHART"
  ) {
    const secondAxisIndex = otherConfig?.isGroupedBarChart
      ? xAxisParm?.length - 1
      : INDEX_ONE;
    const secondXAxisPath = xAxisParm?.[secondAxisIndex]?.path || [];
    const secondXAxisOperation = getOperationFromXAxis(
      xAxisParm?.[secondAxisIndex]
    );

    const secondXAxisPrefix =
      secondXAxisPath.length &&
      ![KEY_FIELDS_TAG, ALL_FIELDS_TAG].includes(secondXAxisPath[INDEX_ZERO])
        ? xAxisSystemAttributes.includes(
            secondXAxisPath[X_AXIS_DATA_TYPE_INDEX] as XAxisSystemAttributes
          )
          ? []
          : [secondXAxisPath.length === ONE ? KEY_FIELDS_TAG : ALL_FIELDS_TAG]
        : [];
    const secondXAxis = [...secondXAxisPrefix, ...secondXAxisPath];
    initialValues["secondXAxis"] = [
      {
        [formNames.X_AXIS_FIELD_NAME]: secondXAxis,
        [formNames.X_AXIS_OPERATION]: secondXAxisOperation
      }
    ];
  }

  return initialValues;
};
/**
 *  To honour prev chart config hierarchy for y-axis, else user will
 *  see blank fields on x and y-axis
 *  TODO: remove once the fix reaches PROD
 */

const getXAxisPrefix = (xAxisPath: string[] | undefined) => {
  return xAxisPath?.length &&
    ![KEY_FIELDS_TAG, ALL_FIELDS_TAG].includes(
      xAxisPath[X_AXIS_DATA_TYPE_INDEX]
    )
    ? xAxisSystemAttributes.includes(
        xAxisPath[X_AXIS_DATA_TYPE_INDEX] as XAxisSystemAttributes
      )
      ? []
      : [xAxisPath.length === ONE ? KEY_FIELDS_TAG : ALL_FIELDS_TAG]
    : [];
};

// Index for xAxisOperatoin after splitting will be 1
const X_AXIS_OPERATION_INDEX = 1;

export const getOperationFromXAxis = (xAxis: FormattedProcess | undefined) => {
  // to honour the inital value for xAxis date operation
  // this is for old chart config
  if (xAxis?.operation) {
    return xAxis.operation;
    // this is the new chart config
  } else if (xAxis?.col?.includes("__")) {
    return xAxis.col.split("__")?.[
      X_AXIS_OPERATION_INDEX
    ] as GroupByDateOperation;
  }
  return undefined;
};
