import type { LegacyRef } from "react";
import { useState, useEffect, forwardRef } from "react";
import { css, cx } from "emotion";
import {
  RadarChart as ReSpiderChart,
  ResponsiveContainer,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList,
  Tooltip
} from "recharts";
import { CustomDot } from "../customDot/CustomDot";
import {
  CustomLegendWrapper,
  useGetLegendDataCommon
} from "../customLegend/CustomLegend";
import { X_AXIS_DATA_TYPE_INDEX, commonChartMargin } from "../../constants";
import { CustomTooltip } from "../tooltip/Tooltip";
import {
  axisFormatter,
  tooltipFormatter,
  xAxisFormatter
} from "../../utils/chartUtils";
import { type ChartComponentProps } from "@certa/common/types";

export const SpiderChart = forwardRef(
  (
    props: ChartComponentProps,
    ref: LegacyRef<typeof ReSpiderChart.prototype>
  ) => {
    const {
      chartData,
      height = 500,
      onClick,
      otherConfig,
      xActiveIdentifier,
      sorting,
      handleSorterChange,
      isAnimationActive = false
    } = props;
    const { shouldShowValueLabels, showValueOn, showLegend, xAxis, colors } =
      otherConfig;
    const { data, probableDataType, xAxisKey } = chartData;
    const [showValueFor, setShowValueFor] = useState<string | null>(null);
    const legendData = useGetLegendDataCommon({
      colors: colors,
      labels: Object.keys(data[0]),
      xAxisKey
    });

    useEffect(() => {
      setShowValueFor(null);
    }, [showValueOn]);

    const xAxisLabelFormatter = xAxisFormatter(
      xAxis?.dataTypes?.[X_AXIS_DATA_TYPE_INDEX],
      xAxis?.labelOutputTypes?.[X_AXIS_DATA_TYPE_INDEX]
    );

    const DOT_RADIUS = 5;
    const DOT_STROKE_WIDTH = 3;
    const ACTIVE_DOT_STROKE_WIDTH = 0;

    return (
      <CustomLegendWrapper
        data={legendData}
        sorting={sorting}
        handleSorterChange={handleSorterChange}
        showLegend={showLegend}
        chartType="SPIDER_CHART"
      >
        <ResponsiveContainer
          width="100%"
          height={height}
          className={cx(
            customSpiderStyle,
            showValueOn === "hover" ? onHoverStyle : ""
          )}
        >
          <ReSpiderChart
            ref={ref}
            data={data}
            // @ts-expect-error - The type of onClick is not compatible with the type of onClick in ReBarChart
            onClick={(data: ChartClickEvent) => {
              if (onClick && data) {
                onClick({
                  ...data,
                  activeXIdentifier: data.activeLabel
                });
              }
            }}
            style={onClick ? { cursor: "pointer" } : {}} // need better way
            margin={commonChartMargin}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey={xAxisKey}
              tick={
                <CustomTick
                  fill="var(--neutral-70)"
                  tickFormatter={xAxisLabelFormatter}
                />
              }
            />
            <PolarRadiusAxis
              angle={90}
              tickFormatter={axisFormatter(probableDataType)}
            />
            <Tooltip
              formatter={tooltipFormatter("LINE_CHART", probableDataType)}
              content={
                <CustomTooltip xAxisLabelFormatter={xAxisLabelFormatter} />
              }
              cursor={{
                stroke: "var(--teal)",
                strokeDasharray: "4"
              }}
            />
            {legendData.map(legend => (
              <Radar
                key={legend.label}
                dataKey={legend.label}
                stroke={legend.background}
                strokeWidth={3}
                dot={props => (
                  <CustomDot
                    {...props}
                    fill={
                      props.payload.name === xActiveIdentifier
                        ? props.stroke
                        : "#fff"
                    }
                    r={DOT_RADIUS}
                    strokeWidth={DOT_STROKE_WIDTH}
                    showValueOn={showValueOn}
                    onClick={() => setShowValueFor(legend.label)}
                  />
                )}
                activeDot={props => (
                  <CustomDot
                    {...props}
                    r={DOT_RADIUS}
                    strokeWidth={ACTIVE_DOT_STROKE_WIDTH}
                    showValueOn={showValueOn}
                    onClick={() => setShowValueFor(legend.label)}
                  />
                )}
                fillOpacity={0}
                isAnimationActive={isAnimationActive}
                style={showValueOn === "click" ? { cursor: "pointer" } : {}}
                onClick={() => setShowValueFor(legend.label)}
              >
                {shouldShowValueLabels &&
                (showValueOn === "always" ||
                  (showValueOn === "click" && showValueFor === legend.label) ||
                  showValueOn === "hover") ? (
                  <LabelList
                    offset={10}
                    angle={0}
                    fill=""
                    position="top"
                    dataKey={legend.label}
                    stroke="var(--neutral-100)"
                    formatter={tooltipFormatter("LINE_CHART", probableDataType)}
                  />
                ) : null}
              </Radar>
            ))}
          </ReSpiderChart>
        </ResponsiveContainer>
      </CustomLegendWrapper>
    );
  }
);

const ellipsisLimit = 50;
// AngleAxis label is overlapping with radiusAxis's last label, to resolve this issue angleAxis's labels,
// need to be moved further away in outside direction.
// To do this we have created custom tick element.
// In that we have only changed tspan's dy value from "0em" to "0.5em" (labels above center line)/"-0.5em"(labels below center line).
function CustomTick(props: any) {
  const {
    payload,
    cx,
    cy,
    x,
    y,
    type,
    textAnchor,
    stroke,
    orientation,
    fill,
    index,
    tickFormatter
  } = props;
  const deltaY = y > cy ? "0.5em " : "-0.5em";

  let label = tickFormatter
    ? tickFormatter(payload.value, index)
    : payload.value;

  if (label.length > ellipsisLimit) {
    label = label.slice(0, ellipsisLimit) + "...";
  }

  return (
    <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text
        type={type}
        cx={cx}
        cy={cy}
        orientation={orientation}
        stroke={stroke}
        fill={fill}
        x={x}
        y={y}
        className="recharts-text recharts-polar-angle-axis-tick-value"
        textAnchor={textAnchor}
      >
        <tspan x={x} dy={deltaY}>
          {label}
        </tspan>
      </text>
    </g>
  );
}

const customSpiderStyle = css({
  "& .recharts-polar-grid-concentric": {
    strokeDasharray: "5, 5",
    stroke: "#d3d6dd"
  },
  "& .recharts-polar-grid-angle": {
    stroke: "#d3d7da"
  }
});

const onHoverStyle = css({
  "& .recharts-radar:not(:hover) .recharts-label-list text": {
    display: "none"
  }
});
