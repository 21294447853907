import { useCallback, useContext } from "react";

import type {
  UpdateFlagParams,
  UpdateIntegrationStatusParams,
  UpdateWorkflowStatusParams
} from "@certa/queries/types/comments.types";

import {
  useUpdateFlagMutation,
  useUpdateIntegrationStatusMutation,
  useUpdateWorkflowStatusMutation
} from "@certa/queries/hooks/comments.hooks";

import { get as lodashGet, isNil } from "lodash-es";
import { useIntl } from "react-intl";
import {
  useChannelContext,
  useCommentsContext
} from "@certa/comments/src/comments.context";
import { WorkflowContext } from "main/src/modules/workflowDetails/workflowDetails.context";
import type { HashMap } from "@certa/types";
import { showMessage } from "@certa/common/components/showMessage";
import type {
  AdjducationRiskCodeChange,
  ChangeType,
  IntegrationStatusChange,
  WorkflowStatusChange
} from "@certa/common/hooks/useAdjudicationRiskAndStatusOnChange";

export type SubmitType = {
  type: ChangeType;
  payload:
    | AdjducationRiskCodeChange
    | WorkflowStatusChange
    | IntegrationStatusChange;
}[];

const useAdjudicationApis = (
  threadId: number,
  onSuccessCallback: (changeType: ChangeType) => void,
  processId?: number
) => {
  const intl = useIntl();
  // TODO: Technically this is a dependency on WorkflowContext and shouldn't be here
  // However, the reason it is being used for, shouldn't be there as well. There was a
  // workaround discussed to make this work, without using this hack, but there still
  // has not been any confirmation on that yet.
  const { workflow: currentWorkflow } = useContext(WorkflowContext);
  const currentWorkflowId = currentWorkflow?.id;
  const { extra: commentsExtra } = useCommentsContext();
  const { mutate: updateWorkflowStatus, isLoading: isUpadtingWorkflowStatus } =
    useUpdateWorkflowStatusMutation(processId!);
  const channel = useChannelContext();
  const {
    fieldId,
    workflowId,
    childWorkflowId,
    integrationUID,
    isIntegrationType,
    objectId,
    objectType
  } = channel;
  const {
    mutate: updateIntegrationStatus,
    isLoading: isUpdatingIntegrationStatus
  } = useUpdateIntegrationStatusMutation(fieldId || objectId, objectType); // Refer to comment.hooks on why we did this.
  const { mutate: updateFlag, isLoading: isUpdatingFlags } =
    useUpdateFlagMutation(
      fieldId || objectId, // Refer to comment.hooks on why we did this.
      objectType
    );
  const handleWorkflowStatusChange = useCallback(
    async (option: WorkflowStatusChange["id"]) => {
      const payload: Partial<UpdateWorkflowStatusParams> = {
        workflowId: processId,
        statusId: option,
        addComment: true,
        thread_id: threadId
      };

      // Passing from extra params in payload for calling integration if relevant config is there
      // Hoping to avoid that when VET-9601 reaches a conclusion
      if (
        lodashGet(commentsExtra, "call_integration.integration_field_tag", null)
      ) {
        // Theoretically this is supposed to set off another integration associated
        // with this workflow's status change.
        payload["integration_field_tag"] =
          commentsExtra["call_integration"]["integration_field_tag"];
        payload["parent_workflow_id"] = currentWorkflowId;
      }

      updateWorkflowStatus(payload as UpdateWorkflowStatusParams, {
        onError: () => {
          showMessage(
            "error",
            intl.formatMessage({
              id: "comments.errors.changeStatusFailed",
              defaultMessage: "Unable to change status."
            })
          );
        },
        onSuccess: () => {
          onSuccessCallback("STATUS");
        }
      });
    },
    [
      processId,
      commentsExtra,
      updateWorkflowStatus,
      currentWorkflowId,
      intl,
      onSuccessCallback,
      threadId
    ]
  );

  const handleIntegrationStatusChange = useCallback(
    async (option: IntegrationStatusChange["updatedStatus"]) => {
      const { integrationUID, fieldId, parentFieldId, parentRowUID } = channel;

      const payload: UpdateIntegrationStatusParams = {
        parent_field_id: parentFieldId,
        parent_row_uid: parentRowUID,
        krypton_status: option,
        field_id: fieldId as number,
        row_uid: integrationUID as string,
        changed_key: "krypton_status",
        display_item: "Status",
        thread_id: threadId
      };

      updateIntegrationStatus(payload, {
        onError: () => {
          showMessage(
            "error",
            intl.formatMessage({
              id: "comments.errors.changeIntegrationStatusFailed",
              defaultMessage: "Unable to change status."
            })
          );
        },
        onSuccess: () => {
          onSuccessCallback("INTEGRATION_STATUS");
        }
      });
    },
    [channel, threadId, updateIntegrationStatus, intl, onSuccessCallback]
  );

  const handleAdjudicationRiskCodeChange = useCallback(
    async (
      option: [
        AdjducationRiskCodeChange["flag"],
        AdjducationRiskCodeChange["reasonCode"]
      ]
    ) => {
      const flag = option[0];

      // Commented out because we've disabled clear anyway.
      // If the user clicks to clear the field the API is called on that action too as for cascader the onChange is same
      // for selecting the value and on clearing. Now when the API is called as there
      // is no flag selected the API throws an error which is shown in the view, to avoid this the check is added.
      // if (!flag) {
      //   // Do nothing if no flag is picked.
      //   return null;
      // }

      let payload: UpdateFlagParams | HashMap = {};

      const reasonCodes =
        option.length > 1 && !isNil(option[1]) ? [option[1]] : [];

      if (fieldId) {
        // Like in the case of RDC events integration
        payload = {
          workflow: workflowId,
          field: fieldId,
          flag: flag,
          reason_codes: reasonCodes,
          thread_id: threadId
        };
      } else if (childWorkflowId) {
        // Adjudicating Child Workflows
        payload = {
          workflow: childWorkflowId,
          flag: flag,
          reason_codes: reasonCodes,
          thread_id: threadId
        };
      }

      // isIntegrationType is checked because integrationUID
      // is also available for child workflows apparently
      // and we don't want that to be passed here in case of
      // adjudication update on a child-workflow.
      if (isIntegrationType && integrationUID) {
        payload["integration_uid"] = integrationUID;
      }

      updateFlag(payload as UpdateFlagParams, {
        onError: () => {
          showMessage(
            "error",
            intl.formatMessage({
              id: "comments.errors.changeFlagFailed",
              defaultMessage: "Unable to change flag."
            })
          );
        },
        onSuccess: () => {
          onSuccessCallback("RISK_CODE");
        }
      });
    },
    [
      updateFlag,
      workflowId,
      fieldId,
      childWorkflowId,
      isIntegrationType,
      integrationUID,
      threadId,
      intl,
      onSuccessCallback
    ]
  );

  const onSubmit = (allIncludedPayload: SubmitType) => {
    for (const item of allIncludedPayload) {
      switch (item.type) {
        case "INTEGRATION_STATUS": {
          handleIntegrationStatusChange(
            (item.payload as IntegrationStatusChange)["updatedStatus"]
          );
          break;
        }
        case "STATUS": {
          handleWorkflowStatusChange(
            (item.payload as WorkflowStatusChange)["id"]
          );
          break;
        }
        case "RISK_CODE": {
          const payload = item.payload as AdjducationRiskCodeChange;
          handleAdjudicationRiskCodeChange([
            payload["flag"],
            payload?.["reasonCode"]
          ]);
          break;
        }
      }
    }
  };
  return {
    onSubmit,
    isUpadtingWorkflowStatus,
    isUpdatingFlags,
    isUpdatingIntegrationStatus
  };
};

export { useAdjudicationApis };
