import type { FieldTypes, ReportCustomFilterCondition } from "@certa/types";
import type { RCFConditionProps } from "../../AdvancedFilters.types";
import { operatorsDetails } from "../../../../utils/filterUtils";
import { SelectValueInputField } from "./SelectValueInputField/SelectValueInputField";
import type { ValueFieldType } from "./ValueFieldInput.utils";
import { getFieldComponent } from "./ValueFieldInput.utils";

export type ValueFieldProps = {
  fieldType: FieldTypes;
  operatorValue: RCFConditionProps["value"]["operator"];
  fieldTag: string;
  value: ReportCustomFilterCondition["value"];
  onChange: (value: string | string[] | undefined) => void;
  valueFieldType: ValueFieldType;
  kindId: string | undefined;
  id: string;
  shouldUseNewLabelValueOptions: boolean;
};

/**
 * value: string | "YYYY-MM-DD" | "(\d+)d" | ["YYYY-MM-DD", "YYYY-MM-DD"] | ["(\d+)d", "(\d+)d"]
 * onChange: (value: typeof value) => void;
 */
export const ValueField = ({
  value,
  id,
  fieldType,
  operatorValue,
  fieldTag,
  kindId,
  onChange,
  valueFieldType,
  shouldUseNewLabelValueOptions
}: ValueFieldProps) => {
  const isDisabled = isDisabledValueInput(operatorValue);

  const handleChange = (value: string | string[] | undefined) => {
    if (
      valueFieldType === "relativeDates" &&
      value !== "" &&
      !Number.isNaN(Number(value))
    ) {
      onChange?.(`${value}d`);
    } else {
      onChange?.(value);
    }
  };

  const parsedRelativeDate =
    valueFieldType === "relativeDates" && value && !Array.isArray(value)
      ? value.trim().replace("d", "")
      : undefined;
  // currently only single select is supported
  if (valueFieldType === "single_select" && !Array.isArray(value)) {
    return (
      <SelectValueInputField
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
        kindId={kindId}
        fieldTag={fieldTag}
        fieldType={fieldType}
        shouldUseNewLabelValueOptions={shouldUseNewLabelValueOptions}
      />
    );
  }

  const FieldComponent = getFieldComponent(valueFieldType);

  return (
    <FieldComponent
      value={valueFieldType === "relativeDates" ? parsedRelativeDate : value}
      onChange={handleChange}
      disabled={isDisabled}
      id={id}
    />
  );
};

const isDisabledValueInput = (
  operator: RCFConditionProps["value"]["operator"]
) => {
  return !!operatorsDetails.find(op => operator === op.value)?.disableInput;
};
