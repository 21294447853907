import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { useSearchStore } from "../store/useSearchStore";
import { useKinds } from "@certa/queries/hooks/kinds.hooks";

export const usePreSelectedWorkflowKindTags = () => {
  const preSelectedKindTags: string[] = useSelector((state: ReduxState) =>
    lodashGet(
      state,
      "config.es_configuration.client_config.searchable_workflow_kind_tags",
      []
    )
  );

  return preSelectedKindTags;
};

export const useExcludedWorkflowKindTags = () => {
  const excludedWorkflowKindTags: string[] = useSelector((state: ReduxState) =>
    lodashGet(
      state,
      "config.es_configuration.client_config.exclude_workflow_kind_tags",
      []
    )
  );
  return excludedWorkflowKindTags;
};

export const usePreSelectedWorkflowKinds = () => {
  const {
    setFilters,
    hasPrefilledKinds,
    setHasPrefilledKinds,
    setPreSelectedKindIds
  } = useSearchStore();
  const { data: kindsList } = useKinds();
  const excludedWorkflowKindTags = useExcludedWorkflowKindTags();
  const preSelectedKindTags = usePreSelectedWorkflowKindTags();

  // Only process pre-selected workflow kinds if:
  // 1. We haven't prefilled kinds yet
  // 2. We have kind data available
  // 3. We have pre-selected tags to apply
  if (!hasPrefilledKinds && kindsList && preSelectedKindTags.length > 0) {
    // Filter out excluded kinds and get IDs of pre-selected kinds
    const preSelectedKindIds = kindsList
      .filter(
        kind =>
          !excludedWorkflowKindTags.includes(kind.tag) &&
          preSelectedKindTags.includes(kind.tag)
      )
      .map(kind => String(kind.id));

    // Only set filters if we have IDs to set
    if (preSelectedKindIds.length > 0) {
      setFilters({ kindIds: preSelectedKindIds });
    }

    setPreSelectedKindIds(preSelectedKindIds);

    // Mark as initialized so we don't run this logic again
    setHasPrefilledKinds(true);
  }
};
