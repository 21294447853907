import { Stack } from "@certa/catalyst/layouts/Stack";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import { Clock } from "@certa/icons/components/Clock";
import { css } from "emotion";

type RecentSearchResultProps = {
  value: string;
};

const borderStyle = css({
  borderBottom: `1px solid ${TypographyColors.NEUTRAL_400}`
});

export const RecentSearchResult = ({ value }: RecentSearchResultProps) => {
  return (
    <Stack
      direction="horizontal"
      align="center"
      justify="flex-start"
      padding="12 16"
      dangerouslySetClassName={borderStyle}
    >
      <Clock size={12} color={TypographyColors.NEUTRAL_500} />
      <Stack padding="0 0 0 12">
        <Typography
          variant={TypographyVariants.LABEL_SM_BOLD}
          color={TypographyColors.NEUTRAL_800}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
