import {
  getProcessDetailRoute,
  getTaskRoute
} from "@certa/common/utils/routes";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { useNavigate } from "react-router";
import type { SearchItem } from "../GlobalSearchNext.schema";
import { transformSearchResult } from "../utils/searchTransformers";
import { useCallback } from "react";
import type { ListInteractionMode } from "@certa/catalyst/hooks/useListInteraction";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";

type UseSearchNavigationProps = {
  setRecentSearches: (item: SearchItem) => void;
  onClose: () => void;
};

export const useSearchNavigation = ({
  setRecentSearches,
  onClose
}: UseSearchNavigationProps) => {
  const navigate = useNavigate();

  const onSelectSearchResult = useCallback(
    (
      result: ModelledAPIResponse.SearchResult,
      index: number,
      interactionMode: ListInteractionMode
    ) => {
      if (!result) return;
      const transformedResult = transformSearchResult(result);

      if (result.type === "workflow") {
        navigate(getProcessDetailRoute(result.id));
      } else if (result.type === "file") {
        navigate(
          getTaskRoute({
            processId: result.workflow.id,
            taskId: result.step.id
          })
        );
      }
      if (transformedResult) {
        setRecentSearches(transformedResult);
      }
      MixPanelActions.track(
        MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_RESULT_CLICK,
        {
          result_type: result.type,
          interaction_mode: interactionMode,
          result_index: index + 1
        }
      );
      onClose();
    },
    [navigate, setRecentSearches, onClose]
  );

  const onSelectRecentSearch = useCallback(
    (item: SearchItem, index: number, interactionMode: ListInteractionMode) => {
      if (item.type === "workflow") {
        navigate(getProcessDetailRoute(item.id));
      } else if (item.type === "file") {
        navigate(
          getTaskRoute({
            processId: item.workflowId,
            taskId: item.stepId
          })
        );
      }
      setRecentSearches(item);
      MixPanelActions.track(
        MixPanelEvents.globalSearchEvents.GLOBAL_SEARCH_RECENT_SEARCH_CLICK,
        {
          result_type: item.type,
          interaction_mode: interactionMode,
          result_index: index + 1
        }
      );
      onClose();
    },
    [navigate, setRecentSearches, onClose]
  );

  return { onSelectSearchResult, onSelectRecentSearch };
};
