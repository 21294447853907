//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Package = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Package"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3457)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3827 0H3.6172C2.8594 -0.000974834 2.167 0.427726 1.82715 1.10548L0 4.76472V14C0 14.5302 0.210937 15.039 0.585949 15.414C0.960962 15.789 1.46975 16 2 16H14C14.5303 16 15.039 15.789 15.414 15.414C15.789 15.039 16 14.5302 16 14V4.76472L14.1699 1.10548C13.831 0.427751 13.1396 0 12.3828 0H12.3827ZM13.3827 4H8.99994V2H12.3827L13.3827 4ZM6.99994 2V4H2.6172L3.6172 2H6.99994ZM1.99995 14V6H13.9999V14H1.99995ZM10 7.99999H11.5C11.7764 7.99999 12 8.27636 12 8.49999V9.99999C12 10.2764 11.7764 10.5 11.5 10.5H10C9.72366 10.5 9.50004 10.2236 9.50004 9.99999V8.49999C9.50004 8.22361 9.72367 7.99999 10 7.99999ZM7.50003 10.5H3.50004V12.5H7.50003V10.5Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3457">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
