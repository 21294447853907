import { css } from "emotion";

export const searchContainerStyles = {
  searchContainer: css({
    borderBottom: "1px solid var(--colors-neutral-400)",
    color: "var(--colors-neutral-500)"
  }),

  input: css({
    width: "100%",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.016rem",
    color: "var(--colors-neutral-800)",
    border: "none",
    "&:focus": {
      outline: "none",
      border: "none"
    },
    "&::placeholder": {
      color: "var(--colors-neutral-500)"
    }
  }),

  rightContainer: css({
    flexShrink: 0
  }),

  filterButton: ({ isActive }: { isActive: boolean }) =>
    css({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2rem",
      height: "2rem",
      padding: "0.5rem",
      borderRadius: "0.375rem",
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      color: isActive ? "var(--colors-brand-500)" : "var(--colors-neutral-700)",
      "&:hover": {
        backgroundColor: isActive
          ? "var(--colors-brand-50)"
          : "var(--colors-neutral-200)"
      },
      "&:focus": {
        border: "none",
        outline: "none"
      },
      "&:focus-visible": {
        border: "none",
        outline: isActive
          ? "1px solid var(--colors-brand-500)"
          : "1px solid var(--colors-neutral-700)"
      }
    })
};
