import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Tooltip, TooltipPlacements } from "@certa/catalyst/components/Tooltip";
import { Stack } from "@certa/catalyst/layouts/Stack";
import React, { useMemo } from "react";
import { breadcrumbPathDisplayStyles as styles } from "./BreadcrumbPathDisplay.styles";
import { classNames } from "@certa/catalyst/utils/common";
type BreadcrumbPathDisplayProps = {
  items: string[];
};

const SEPARATOR = "/";
const ELLIPSIS = "...";
const MAX_TOTAL_CHARS = 300;

const PathItem = ({
  children,
  isLast = false,
  shouldTruncate = false
}: {
  children: React.ReactNode;
  isLast?: boolean;
  shouldTruncate?: boolean;
}) => (
  <Typography
    variant={TypographyVariants.LABEL_SM}
    color={isLast ? TypographyColors.NEUTRAL_800 : TypographyColors.NEUTRAL_700}
    as="span"
    className={
      shouldTruncate ? classNames(styles.maxWidth, "ellipsis") : undefined
    }
  >
    {children}
  </Typography>
);

const Separator = () => (
  <Typography
    variant={TypographyVariants.LABEL_SM}
    color={TypographyColors.NEUTRAL_700}
    as="span"
  >
    {SEPARATOR}
  </Typography>
);

const Ellipsis = () => (
  <Typography
    variant={TypographyVariants.LABEL_SM}
    color={TypographyColors.NEUTRAL_700}
    as="span"
  >
    {ELLIPSIS}
  </Typography>
);

const PathContainer = ({ children }: { children: React.ReactNode }) => (
  <Stack
    direction="horizontal"
    dangerouslySetClassName={styles.flexWrap}
    align="center"
    gap="4"
  >
    {children}
  </Stack>
);

export const BreadcrumbPathDisplay = ({
  items = []
}: BreadcrumbPathDisplayProps) => {
  const totalCharLength = useMemo(() => items.join("").length, [items]);
  const shouldShowFull = totalCharLength <= MAX_TOTAL_CHARS;
  const fullPath = items.join(" / ");

  if (!items.length) return null;

  if (shouldShowFull) {
    return (
      <PathContainer>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Separator />}
            <PathItem isLast={index === items.length - 1}>{item}</PathItem>
          </React.Fragment>
        ))}
      </PathContainer>
    );
  }

  // Handle truncated view based on number of items
  if (items.length === 1) {
    return (
      <Tooltip content={fullPath} placement={TooltipPlacements.TOP}>
        <PathContainer>
          <PathItem isLast shouldTruncate>
            {items[0]}
          </PathItem>
        </PathContainer>
      </Tooltip>
    );
  }

  if (items.length === 2) {
    return (
      <Tooltip content={fullPath} placement={TooltipPlacements.TOP}>
        <PathContainer>
          <PathItem shouldTruncate>{items[0]}</PathItem>
          <Separator />
          <PathItem isLast shouldTruncate>
            {items[1]}
          </PathItem>
        </PathContainer>
      </Tooltip>
    );
  }

  // More than 2 items: show first, ellipsis, and last
  return (
    <Tooltip content={fullPath} placement={TooltipPlacements.TOP}>
      <PathContainer>
        <PathItem shouldTruncate>{items[0]}</PathItem>
        <Separator />
        <Ellipsis />
        <Separator />
        <PathItem isLast shouldTruncate>
          {items[items.length - 1]}
        </PathItem>
      </PathContainer>
    </Tooltip>
  );
};
