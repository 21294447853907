//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const DoubleChevronLeft = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="DoubleChevronLeft"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7389 12.4743C14.087 12.8233 14.087 13.3898 13.7389 13.7389C13.3898 14.087 12.8233 14.087 12.4743 13.7389L7.36766 8.63234C7.01957 8.28325 7.01957 7.71675 7.36766 7.36766L12.4743 2.26107C12.8233 1.91298 13.3898 1.91298 13.7389 2.26107C14.087 2.61016 14.087 3.17666 13.7389 3.52574L9.26172 8.00193L13.7389 12.4743Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.8455 12.4743C9.19359 12.8233 9.19359 13.3898 8.8455 13.7389C8.49641 14.087 7.92991 14.087 7.58082 13.7389L2.47423 8.63234C2.12614 8.28325 2.12614 7.71675 2.47423 7.36766L7.58082 2.26107C7.92991 1.91298 8.49641 1.91298 8.8455 2.26107C9.19359 2.61016 9.19359 3.17666 8.8455 3.52574L4.36829 8.00193L8.8455 12.4743Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
