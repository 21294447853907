import type { Moment } from "moment";
import type { FieldTypes } from "./fieldTypes";

export type AnswerFilter = {
  field: { path: string[]; fieldType: FieldTypes };
  operator: string;
  value: string | undefined;
  useRelativeDates: boolean;
}[];

export enum FilterParamName {
  KIND_TAG = "KIND_TAG",
  SEARCH = "SEARCH",
  REGION = "REGION",
  BUSINESS_UNIT = "BUSINESS_UNIT",
  STATUS = "STATUS",
  DATE_RANGE = "DATE_RANGE",
  DATE_FILTER_TYPE = "DATE_FILTER_TYPE",
  RELATIVE_DATE_RANGE = "RELATIVE_DATE_RANGE",
  ANSWER = "ANSWER",
  FILTER_BY_GROUP = "FILTER_BY_GROUP"
}
export type WorkflowDateFilterType = "betweenDate" | "withinTheLastDate";

export type WorkflowDateRange = [
  Moment | null | string,
  Moment | null | string
];
export type WorkflowRelativeDateRange = [string | null, string | null];

export type WorkflowFilters = {
  [FilterParamName.KIND_TAG]: string;
  [FilterParamName.SEARCH]?: string;
  [FilterParamName.REGION]?: string[];
  [FilterParamName.BUSINESS_UNIT]?: string[];
  [FilterParamName.STATUS]?: string[];
  [FilterParamName.DATE_RANGE]?: WorkflowDateRange;
  [FilterParamName.FILTER_BY_GROUP]?: boolean;
  // if FILTER_BY_GROUP is true, then ANSWER should have REPORT_CUSTOM_FILTER type
  [FilterParamName.ANSWER]?: AnswerFilter | ReportCustomFilter;
  [FilterParamName.DATE_FILTER_TYPE]?: WorkflowDateFilterType;
  [FilterParamName.RELATIVE_DATE_RANGE]?: WorkflowRelativeDateRange;
};

export type HandleFormValuesChange = (_: any, values: any) => void;

export type OperatorsTypesValues =
  | "eq"
  | "not_eq"
  | "lt"
  | "lte"
  | "gt"
  | "gte"
  | "is_set"
  | "contains"
  | "in"
  | "not_in"
  | "range"
  | "is_within_last"
  | "not_contains"
  | "is_empty";

export enum CompareType {
  "OR" = "OR",
  "AND" = "AND"
}

export type ReportCustomFilterConditionField = {
  path: string[];
  fieldType: FieldTypes;
};

export type ReportCustomFilterCondition = {
  field: ReportCustomFilterConditionField;
  operator: OperatorsTypesValues;
  value: string | string[];
  useRelativeDates?: boolean;
};

export type ReportCustomFilter = {
  groups: ReportCustomFilter[];
  conditions: ReportCustomFilterCondition[];
  compareType: CompareType;
};

type ReportConfigurationErrorsCode =
  | "KIND_TAG"
  | "withinTheLast"
  | "metaNameError"
  | "xAxisError"
  | "yAxisError"
  | "stackedError";

export type ReportConfigurationErrors = {
  [key in ReportConfigurationErrorsCode]?: string;
};
export type SetRequiredMessage = React.Dispatch<
  React.SetStateAction<ReportConfigurationErrors>
>;

export type QueryConfigErrorsRef = React.RefObject<ReportConfigurationErrors>;

export type ReportsConfigurationRef = {
  setRequiredMessage: SetRequiredMessage;
  queryConfigErrorsRef: QueryConfigErrorsRef;
};

type DateRangeValue = [moment.Moment | null, moment.Moment | null] | null;

export type DateRangeFieldProps = {
  value?: DateRangeValue;
  onChange?: (dateRange: DateRangeValue, dateString: string) => void;
};

export type WithinTheLastTimePeriod = "M" | "w" | "d" | "h" | "m";
export type WithinTheLastErrorCode =
  | "negativeNumberNotAllowed"
  | "decimalNumberNotAllowed"
  | "onlyDigitsAllowed"
  | "maxLimitForMinute"
  | "maxLimitForHours"
  | "maxLimitForDays"
  | "maxLimitForWeeks"
  | "maxLimitForMonths";

export type FormOnValuesChange = (changedValues: any, values: any) => void;
