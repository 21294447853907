import { cloneDeep as lodashCloneDeep, merge as lodashMerge } from "lodash-es";
import type { IntlShape } from "react-intl";
import {
  getDefaultXAxisLabel,
  getDefaultYAxisLabel
} from "@certa/chartreport/src/utils/chartUtils";
import {
  getIsStackedBarChart,
  otherChartConfigDefaultValue
} from "@certa/common/constants";
import type {
  ChartConfig,
  ShowAxisLabel,
  ChartIdentifiers,
  ActiveColorType,
  RGBColorString
} from "@certa/common/types";
import { showToast, ToastTypes } from "@certa/catalyst/components/Toast";

export const generateRandomRGBAColor = (
  opacity: number = 1
): RGBColorString => {
  const o = Math.round,
    r = Math.random,
    s = 255;
  return `rgba(${o(r() * s)}, ${o(r() * s)}, ${o(r() * s)}, ${opacity})`;
};

type GetYAxisLabel = (
  axisLabel: string | undefined,
  yAxisListValue: ChartConfig["y-axis"] | undefined,
  isCycleTimeReport?: boolean,
  showAxisLabel?: ShowAxisLabel
) => string;

export const getYAxisLabel: GetYAxisLabel = (
  axisLabel,
  yAxisListValue,
  isCycleTimeReport = false,
  showAxisLabel = "auto"
) => {
  if (showAxisLabel === "auto" || axisLabel === undefined) {
    return getDefaultYAxisLabel(yAxisListValue, isCycleTimeReport);
  }
  return axisLabel;
};

type GetXAxisLabel = (
  axisLabel: string | undefined,
  xAxisChartConfig?: ChartConfig["x-axis"],
  showAxisLabel?: ShowAxisLabel,
  cycleTimePath?: string | undefined,
  isStackedBarChartOrHeadMap?: boolean,
  isGroupedBarChart?: boolean
) => string;

export const getXAxisLabel: GetXAxisLabel = (
  axisLabel,
  xAxisChartConfig,
  showAxisLabel = "auto",
  cycleTimePath,
  isStackedBarChartOrHeadMap = false,
  isGroupedBarChart = false
) => {
  if (showAxisLabel === "auto" || axisLabel === undefined) {
    return getDefaultXAxisLabel(
      xAxisChartConfig,
      cycleTimePath,
      isStackedBarChartOrHeadMap,
      isGroupedBarChart
    );
  }
  return axisLabel;
};

export const getAxisLabels = (chartType: ChartIdentifiers) => {
  switch (chartType) {
    case "PIE_CHART":
      return {
        xLabel: "Group by",
        yLabel: "Measure",
        xPlaceHolder: "Select Data for Group by",
        yPlaceHolder: "Select Data for Measure"
      } as const;
    case "SPIDER_CHART":
      return {
        xLabel: "Split by",
        yLabel: "Measure",
        xPlaceHolder: "Select Data for Split by",
        yPlaceHolder: "Select Data for Measure"
      } as const;
    case "BAR_CHART":
      return {
        xLabel: "X-axis",
        yLabel: "Y-axis",
        xPlaceHolder: "Select Data for X-Axis",
        yPlaceHolder: "Select Data for Y-Axis",
        xLabel_2: "Legend for stacks",
        xPlaceHolder_2: "Select Item",
        split_by: "Split by",
        splitByPlaceHolder: "Select Data for Split by"
      } as const;
    case "HEAT_MAP_CHART":
      return {
        xLabel: "X-axis",
        yLabel: "Measure",
        xPlaceHolder: "Select Data for X-Axis",
        yPlaceHolder: "Select Data for Measure",
        xLabel_2: "Y-axis",
        xPlaceHolder_2: "Select Data for Y-Axis"
      } as const;
    case "GEO_MAP_CHART":
      return {
        xLabel: "Source",
        yLabel: "Measure",
        xPlaceHolder: "Select Source Data",
        yPlaceHolder: "Select Data for Measure"
      } as const;
    default:
      return {
        xLabel: "X-axis",
        yLabel: "Y-axis",
        xPlaceHolder: "Select Data for X-Axis",
        yPlaceHolder: "Select Data for Y-Axis"
      } as const;
  }
};

export const getDefaultActiveColorType = (
  chartConfig: Partial<ChartConfig> | undefined,
  chartType: ChartIdentifiers
): ActiveColorType => {
  if (
    chartConfig?.isCycleTimeReport ||
    getIsStackedBarChart(chartConfig, chartType)
  ) {
    return "custom";
  }
  return "singleTone";
};

export const getMergedChartData = (
  chartConfig: ChartConfig | undefined,
  chartType: ChartIdentifiers
) => {
  if (!chartConfig) {
    return {
      "x-axis": [],
      "y-axis": [],
      isCycleTimeReport: false,
      otherConfig: {
        ...otherChartConfigDefaultValue,
        colors: {
          activeColorType: getDefaultActiveColorType(chartConfig, chartType)
        }
      }
    };
  }

  const newOtherConfig = lodashMerge(
    lodashCloneDeep(otherChartConfigDefaultValue),
    chartConfig?.otherConfig
  );

  const isStackedBarChart = getIsStackedBarChart(chartConfig, chartType);
  const isHeatMapChart = chartType === "HEAT_MAP_CHART";
  newOtherConfig.isStackedBarChart = isStackedBarChart;
  const {
    xAxisLabel,
    yAxisLabel,
    showXAxisLabel,
    showYAxisLabel,
    colors,
    cycleTimePath
  } = newOtherConfig;
  newOtherConfig.xAxisLabel = getXAxisLabel(
    xAxisLabel,
    chartConfig?.["x-axis"],
    showXAxisLabel,
    cycleTimePath,
    isStackedBarChart || isHeatMapChart
  );
  newOtherConfig.yAxisLabel = getYAxisLabel(
    yAxisLabel,
    chartConfig?.["y-axis"],
    chartConfig?.isCycleTimeReport,
    showYAxisLabel
  );
  if (colors === undefined) {
    newOtherConfig.colors = {
      activeColorType: getDefaultActiveColorType(chartConfig, chartType)
    };
  }
  return {
    ...chartConfig,
    otherConfig: newOtherConfig
  };
};

export const onClickChartShowInfoMessage = ({
  intl,
  invalidFilterLabels,
  unsupportedChartTypes
}: {
  intl: IntlShape;
  invalidFilterLabels?: string[];
  unsupportedChartTypes?: string;
}) => {
  if (unsupportedChartTypes) {
    const messageText = intl.formatMessage({
      id: "reportCharts.unsupportedChartType",
      defaultMessage:
        "The following chart types are not supported for chart to table mapping:"
    });
    showToast({
      type: ToastTypes.INFO,
      title: `${messageText} ${unsupportedChartTypes}`
    });
  }
  if (invalidFilterLabels) {
    const messageText = intl.formatMessage({
      id: "reportCharts.invalidColumnFilter",
      defaultMessage:
        "The following columns are not supported for chart to table mapping:"
    });
    showToast({
      type: ToastTypes.INFO,
      title: `${messageText} ${invalidFilterLabels.join(", ")}`
    });
  }
};
