import { WorkflowStatusNew } from "@certa/common/components/WorkflowStatus";
import { ChokidarWFStatusPermissionBasedOnToggle } from "@certa/common/components/ChokidarWFStatusPermissionBasedOnToggle";
import { useState } from "react";
import { TaskStepgroupOverlay } from "../../TaskStepGroupOverlay";
import { BaseDialog } from "@certa/catalyst/components/Dialog/BaseDialog";

type WorkflowStatusProps = {
  processId: number;
  status?: string;
  statusColorCode?: string | null;
  width?: number | string;
  onDialogOpenChange?: (isOpen: boolean) => void;
  onOpenDialog?: () => void;
};

const WorkflowStatus = ({
  processId,
  status,
  statusColorCode,
  width = "true", // to enable white-space: nowrap;
  onDialogOpenChange,
  onOpenDialog
}: WorkflowStatusProps) => {
  const [shouldShowDialog, setShouldShowDialog] = useState(false);

  const onOpen = () => {
    setShouldShowDialog(true);
    onDialogOpenChange?.(true);
    onOpenDialog?.();
  };

  const onClose = () => {
    setShouldShowDialog(false);
    onDialogOpenChange?.(false);
  };

  return (
    <>
      <ChokidarWFStatusPermissionBasedOnToggle processId={processId}>
        <WorkflowStatusNew
          onClick={onOpen}
          ellipsis={width}
          label={status}
          customColorCode={statusColorCode}
          size="Medium"
        />
      </ChokidarWFStatusPermissionBasedOnToggle>

      <BaseDialog
        show={shouldShowDialog}
        onClose={onClose}
        width="85%"
        aria-label={status || ""}
      >
        <TaskStepgroupOverlay
          processId={processId}
          hideOverlay={onClose}
          shouldHideCloseButton={false}
        />
      </BaseDialog>
    </>
  );
};

export { WorkflowStatus };
