//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const UserGroup = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="UserGroup"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3594)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.02833 16H5.19508H5.1948C2.30665 16 0.056417 15.8623 0 12.8612C0 10.189 1.80544 7.93121 4.24988 7.18791C3.02822 6.58236 2.16674 5.28709 2.16674 3.80049C2.16674 1.68024 3.88864 0 6 0C8.13852 0 9.83326 1.70718 9.83326 3.80049C9.83326 5.26027 9 6.5823 7.75012 7.18791C10.2228 7.93253 12 10.1903 12 12.8612C12 15.8623 9.74965 16 6.86159 16H6.02833ZM16 11.3844C16 13.2707 14.9332 13.7995 13.3761 13.9455C13.4573 13.6337 13.5 13.2746 13.5 12.8612C13.5 10.1903 11.7228 7.93253 9.25012 7.18791C10.5 6.5823 11.3333 5.26027 11.3333 3.80049C11.3333 2.61185 10.7868 1.54771 9.923 0.849928C10.2597 0.731157 10.6224 0.666663 11 0.666663C12.7821 0.666663 14.1944 2.08931 14.1944 3.83374C14.1944 5.05022 13.5 6.15191 12.4584 6.65659C14.519 7.27711 16 9.15858 16 11.3844Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3594">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
