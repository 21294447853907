//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Messages = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Messages"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3240)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.846081 15.8109C1.36588 16.089 1.99715 16.0588 2.48778 15.7307L5.28466 13.8639H13.334C14.8059 13.8639 15.9997 12.6722 16.0007 11.2003V2.66674C16.0007 1.95944 15.7194 1.28129 15.2194 0.781276C14.7194 0.281262 14.0413 0 13.334 0H2.66699C1.19404 0 0.000244141 1.19379 0.000244141 2.66674V14.4004C0.000244141 14.991 0.325267 15.5327 0.846108 15.8109L0.846081 15.8109ZM1.60026 12.267V2.66676C1.60026 2.07717 2.07737 1.60007 2.66696 1.60007H13.3339C13.6173 1.60007 13.8881 1.71257 14.0881 1.91258C14.2881 2.11259 14.4006 2.38342 14.4006 2.66676V11.2003C14.4006 11.4837 14.2881 11.7545 14.0881 11.9545C13.8881 12.1545 13.6173 12.267 13.3339 12.267H4.80035L1.60026 14.4004V12.267ZM4.00029 4.00011H12.0005V5.60016H4.00029V4.00011ZM12.0005 8.2669H4.00029V9.86694H12.0005V8.2669Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3240">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
