/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import type { FC } from "react";
import { UserGroup } from "@certa/icons/components/UserGroup";
import { getAvatarLetters, Stack, Text, Avatar } from "@certa/blocks/thanos";

const MentionListItem: FC<{
  label: string;
  isUserGroup: boolean;
}> = ({ label, isUserGroup }) => {
  return (
    <Stack align="center" justify="space-between">
      <Stack align="center" gutter="s2" gap="s2">
        {isUserGroup ? (
          <Stack
            align="center"
            justify="center"
            css={css`
              background-color: var(--neutral-20);
              height: 32px;
              width: 32px;
              border-radius: 50px;
            `}
          >
            <UserGroup />
          </Stack>
        ) : (
          <Avatar alt={label} backgroundColor="#EAEAEA" color="#00164E">
            {getAvatarLetters(label)}
          </Avatar>
        )}
        <Text variant="p1-regular" color="black">
          {label}
        </Text>
      </Stack>
      {/* TODO: Add Group support for user here */}
      <Stack />
    </Stack>
  );
};

export { MentionListItem };
