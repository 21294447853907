//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Date = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Date"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3331)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 0.75C4 0.335786 4.33579 0 4.75 0C5.16421 0 5.5 0.335786 5.5 0.75V2H10.5V0.75C10.5 0.335786 10.8358 0 11.25 0C11.6642 0 12 0.335786 12 0.75V2H13C14.6569 2 16 3.34315 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V5C0 3.34315 1.34315 2 3 2H4V0.75ZM10.5 3.5V4.25C10.5 4.66421 10.8358 5 11.25 5C11.6642 5 12 4.66421 12 4.25V3.5H13C13.8284 3.5 14.5 4.17157 14.5 5V6.5H1.5V5C1.5 4.17157 2.17157 3.5 3 3.5H4V4.25C4 4.66421 4.33579 5 4.75 5C5.16421 5 5.5 4.66421 5.5 4.25V3.5H10.5ZM1.5 8V13C1.5 13.8284 2.17157 14.5 3 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V8H1.5Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3331">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
