//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Spinner3 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Spinner3"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g id="prefix__Group 324">
            <path
              id="prefix__Ellipse 1"
              d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM2.08704 6C2.08704 8.16107 3.83893 9.91296 6 9.91296C8.16107 9.91296 9.91296 8.16107 9.91296 6C9.91296 3.83893 8.16107 2.08704 6 2.08704C3.83893 2.08704 2.08704 3.83893 2.08704 6Z"
              fill="#EEEFEF"
            />
            <path
              id="prefix__Ellipse 1_2"
              d="M6 0C7.18669 1.41511e-08 8.34673 0.351894 9.33342 1.01118C10.3201 1.67047 11.0892 2.60754 11.5433 3.7039C11.9974 4.80026 12.1162 6.00666 11.8847 7.17054C11.6532 8.33443 11.0818 9.40353 10.2426 10.2426C9.40353 11.0818 8.33443 11.6532 7.17054 11.8847C6.00666 12.1162 4.80026 11.9974 3.7039 11.5433C2.60754 11.0892 1.67047 10.3201 1.01118 9.33342C0.351894 8.34673 -2.83022e-08 7.18669 0 6H2.08704C2.08704 6.77391 2.31653 7.53044 2.74649 8.17393C3.17645 8.81741 3.78757 9.31894 4.50257 9.61511C5.21757 9.91127 6.00434 9.98876 6.76338 9.83778C7.52242 9.68679 8.21964 9.31412 8.76688 8.76688C9.31412 8.21964 9.68679 7.52242 9.83778 6.76338C9.98876 6.00434 9.91127 5.21757 9.61511 4.50257C9.31894 3.78757 8.81741 3.17645 8.17393 2.74649C7.53044 2.31653 6.77391 2.08704 6 2.08704V0Z"
              fill="#1F5EFF"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 6 6"
              to="360 6 6"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};
