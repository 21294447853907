import React, { Component } from "react";
import { connect, batch, useSelector } from "react-redux";
import {
  getAllTaskQueuesThunk,
  getAllAlertsThunk,
  getAdvancedFilterDataThunk,
  getWorkflowListThunk
} from "../../thunks";
import {
  setWorkflowFilter,
  setMultipleWorkflowFilters,
  removeWorkflowFilter,
  resetWorkflowFilters
} from "../../actionCreators";
import { FILTERS_ENUM, MY_STUFF_FILTERS_ENUM } from "../../constants";
import {
  selectedBasicFiltersSelector,
  selectedAdvancedFiltersSelector
} from "../../selectors";
import { PermissionTypes } from "@certa/types";
import { useUserProfileData } from "@certa/queries/hooks/user.hooks";
import { checkPermission } from "@certa/common/utils/permissions/Chowkidar";
import { withRouterForClassComponents } from "../../../router/routerHocForClassComponents";

/**
 * type filter = {
 *  name: FILTER_ENUM.<any>.name => filterName
 *  key: FILTER_ENUM.<any>.key => key to be sent in query params
 *  value: any => value to be sent in the query params
 *  meta: any => actual value of the filter
 * }
 *
 * type filterName = FILTER_ENUM.<any>.name
 */

class WorkflowFitlers extends Component {
  get allFiltersName() {
    return Object.values(FILTERS_ENUM).map(value => value.name);
  }

  get allFiltersNameExceptKind() {
    return this.allFiltersName.filter(
      filterName => filterName !== FILTERS_ENUM.KIND_FILTER.name
    );
  }

  removeMyStuffFilters = filters => {
    // loop over filters and check if current filter is a my stuff filter
    // if current filter is a mystuff filter then remove all other my stuff filters.
    filters.forEach(filter => {
      if (MY_STUFF_FILTERS_ENUM[filter.name]) {
        Object.keys(MY_STUFF_FILTERS_ENUM).forEach(key => {
          if (key !== filter.name) {
            this.props.removeWorkflowFilter(key);
          }
        });
      }
    });
  };

  /**
   * @param {filters} Array<filter>
   * List of filters to be applied
   *
   * @param {removeRest} boolean, default false
   * Boolean to remove rest of the applied filters
   *
   * @param {removeFilters} Array<filterNames>
   * List of filter names to be removed selectively
   *
   * @param {callback} function
   * Function to be called just after the filters has been applied
   *
   * @def
   * Apply workflow filters
   */
  addFilters = async filterDetail => {
    const {
      filters = [],
      removeRest = false,
      removeFilters = [],
      // callback = null,
      hideLoaders = false,
      removeAllExceptKind = false
    } = filterDetail;
    this.validateFitlerData(filters);
    // Set/Remove all the filters in the state
    // Using batch to optimize re-rendering
    batch(() => {
      if (removeRest) {
        this.props.resetWorkflowFilters();
      } else if (removeAllExceptKind) {
        this.allFiltersNameExceptKind.forEach(filter =>
          this.props.removeWorkflowFilter(filter)
        );

        // TODO: Fix this
        // This block will always be executed 🤔
      } else if (Array.isArray(removeFilters)) {
        removeFilters.forEach(filter =>
          this.props.removeWorkflowFilter(filter)
        );
      }
      this.props.setMultipleWorkflowFilters(filters);

      // Check if current filter is a my stuff filter then remove other my stuff filters
      this.removeMyStuffFilters(filters);
    });

    // Custom business logic in case of some specific filters
    filters.forEach(filter => {
      if (filter.name === FILTERS_ENUM.KIND_FILTER.name) {
        this.props.getAllTaskQueuesThunk(filter.meta.tag, hideLoaders);
        if (
          checkPermission({
            permissionsAllowed: this.props.permissions.permissions,
            permissionName: PermissionTypes.CAN_VIEW_ALERTS
          })
        ) {
          this.props.getAllAlertsThunk(filter.meta.tag, hideLoaders);
        }
        this.props.getAdvancedFilterDataThunk(filter.meta.tag);
      }

      if (filter.name === FILTERS_ENUM.REGION_FILTER.name) {
        // Clear the currently selected business unit as well because most of the time
        // selected BU won't be a part of newly selected region filter
        if (!this.hasFilter(filters, FILTERS_ENUM.BUSINESS_UNIT_FILTER.name)) {
          this.props.removeWorkflowFilter(
            FILTERS_ENUM.BUSINESS_UNIT_FILTER.name
          );
        }
      }
    });

    this.props.getWorkflowListThunk(hideLoaders, {
      navigate: this.props.navigate
    });
  };

  hasFilter = (filters, filterName) => {
    return filters.map(filter => filter.name).includes(filterName);
  };

  validateFitlerData = filters => {
    filters.forEach(filter => {
      if (!filter.name) {
        // Putting this as a hard check because
        // w/o this applying filters doesn't make sense
        throw new Error(
          "WorkflowFilters: filter name is mandatory for addFilter function"
        );
      }
      if (!filter.value || !filter.key) {
        console.warn(
          `WorkflowFitlers: Unexpected arguments for addFilters functions, only recieved for ${filter.name}`
        );
      }
    });
  };

  createFilterParams = () => {
    const params = {};
    const { selectedWorkflowFilters: filters } = this.props;

    for (const filterName in filters) {
      const { key, value } = filters[filterName];
      params[key] = value;
    }

    if (params[FILTERS_ENUM.MY_TASK_FILTER.key]) {
      delete params[FILTERS_ENUM.KIND_FILTER.key];
    }

    return params;
  };

  /**
   * @param {filters} Array<filterName>
   * Names of the filters to be removed
   *
   * @def
   * Remove the workflow filters
   */
  removeFilters = filters => {
    const filtersToBeRemoved = [...filters];
    if (filters.includes(FILTERS_ENUM.REGION_FILTER.name)) {
      filtersToBeRemoved.push(FILTERS_ENUM.BUSINESS_UNIT_FILTER.name);
    }
    this.addFilters({
      filters: [
        {
          name: FILTERS_ENUM.PAGE_FILTER.name,
          key: FILTERS_ENUM.PAGE_FILTER.key,
          value: 1,
          meta: 1
        }
      ],
      removeRest: false,
      removeFilters: filtersToBeRemoved
    });
  };

  /**
   * @def
   * Remove all the workflow filters
   */
  resetFilters = () => {
    const filters = [
      FILTERS_ENUM.STATUS_FILTER.name,
      FILTERS_ENUM.REGION_FILTER.name,
      FILTERS_ENUM.BUSINESS_UNIT_FILTER.name,
      FILTERS_ENUM.START_DATE_FILTER.name,
      FILTERS_ENUM.END_DATE_FILTER.name,
      FILTERS_ENUM.ADVANCED_FILTER.name
    ];
    this.addFilters({
      filters: [],
      removeRest: false,
      removeFilters: filters
    });
  };

  /**
   * @param {filterName} filterName
   * Name of the filter whose applied value of is required
   *
   * @param {key} string => defaults to 'meta'
   * Key whose value is required from the applied filter.
   * Applied filter has name, key, value, meta keys.
   *
   * @def
   * Returns the applied value of a particular filter
   */
  getSelectedFilterValue = (filterName, key = "meta") => {
    const filter = this.props.selectedWorkflowFilters[filterName];
    return filter ? filter[key] : null;
  };

  /**
   * This function sets the filters to redux state
   * If filter is a page filter then only that filter is set
   * If it is not a page filter than that filter is always set with page number 1
   *
   * Creating this seprate set filter method in order to provide these components directly
   * as it handles the page logic itself.
   * Also this previous add filter is having lot of redux actions and not maintainable so any custom logic can be added here.
   * addfilters accetps filter is array format which makes it difficult to set filter as we have to create a data structure in
   * in array format to set the filter but handleSetfilter accepts single filter at a time.
   */
  handleSetFilter = filterData => {
    if (filterData.name === FILTERS_ENUM.PAGE_FILTER.name) {
      this.addFilters({
        filters: [filterData]
      });
    } else {
      const filterWithInitialPage = [
        filterData,
        {
          name: FILTERS_ENUM.PAGE_FILTER.name,
          key: FILTERS_ENUM.PAGE_FILTER.key,
          value: 1,
          meta: 1
        }
      ];
      this.addFilters({
        filters: filterWithInitialPage
      });
    }
  };

  /**
   * Creating this seprate remove filter method in order to provide these components directly
   * removefilters accetps filter is array format which makes it difficult to remove filter as we have to create a data structure in
   * in array format to set the filter but handleRemovefilter accepts single filter at a time.
   */
  handleRemoveFilter = filterName => this.removeFilters([filterName]);

  render() {
    const { children } = this.props;
    if (!React.isValidElement(children)) {
      console.warn(
        `WorkflowFitlers: Expected a valid react element found ${typeof children}`
      );
      return null;
    }

    const filterProps = {
      addFilters: this.addFilters,
      removeFilters: this.removeFilters,
      resetFilters: this.resetFilters,
      selectedFilters: this.props.selectedWorkflowFilters,
      getSelectedFilterValue: this.getSelectedFilterValue,
      selectedBasicWorkflowFilters: this.props.selectedBasicWorkflowFilters,
      selectedAdvancedWorkflowFilter: this.props.selectedAdvancedWorkflowFilter,
      userPreferredRegion: this.props.userProfileData.preferredRegion,
      handleRemoveFilter: this.handleRemoveFilter,
      handleSetFilter: this.handleSetFilter
    };

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, filterProps)
    );

    return <>{childrenWithProps}</>;
  }
}

const mapStateToProps = state => ({
  selectedWorkflowFilters: state.workflowList.selectedWorkflowFilters,
  selectedBasicWorkflowFilters: selectedBasicFiltersSelector(state),
  selectedAdvancedWorkflowFilter: selectedAdvancedFiltersSelector(state),
  permissions: state.permissions
});

const WrappedWorkflowFitlers = connect(mapStateToProps, {
  setWorkflowFilter,
  getAllTaskQueuesThunk,
  getAllAlertsThunk,
  getAdvancedFilterDataThunk,
  getWorkflowListThunk,
  setMultipleWorkflowFilters,
  removeWorkflowFilter,
  resetWorkflowFilters
})(withRouterForClassComponents(WorkflowFitlers));

// creating an HOC
export function workFlowFilterHOC(ChildComponent) {
  return props => {
    const userProfile = useSelector(
      state => state.authentication.user.user_profile_workflow
    );
    const userProfileDataQuery = useUserProfileData(userProfile);

    return (
      <WrappedWorkflowFitlers userProfileData={userProfileDataQuery.data}>
        <ChildComponent {...props} />
      </WrappedWorkflowFitlers>
    );
  };
}
