//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronLeft = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronLeft"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.58353 9.46654L8.73145 13.6131C9.00661 13.886 9.38629 14.0256 9.77208 13.9961C10.1583 13.9666 10.5127 13.7711 10.7429 13.4599C11.1282 12.911 11.0574 12.1633 10.576 11.6965L6.87758 7.99937L10.6121 4.26485C10.8858 3.98969 11.026 3.60908 10.996 3.22235C10.9665 2.83563 10.7696 2.48079 10.4574 2.25063C9.90851 1.86532 9.16129 1.9361 8.69449 2.4175L4.58353 6.53218C4.1954 6.92171 3.97696 7.44905 3.97696 7.99942C3.97696 8.54926 4.1954 9.07708 4.58353 9.46666V9.46654Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
