import { css } from "emotion";
import { MainContent } from "./MainContent";
import { SearchContainer } from "./SearchContainer";
import { FilterBar } from "./filters/FilterBar";
import { LeftPanel } from "./LeftPanel";

import { useSearchKeyboard } from "../hooks/useSearchKeyboard";
import { useSearchNavigation } from "../hooks/useSearchNavigation";
import { useSearchStore } from "../store/useSearchStore";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { ResultPreview } from "./ResultPreview";
import { useRecentSearches } from "../hooks/useRecentSearches";
import { RecentSearchList } from "./RecentSearchList";
import { usePreSelectedWorkflowKinds } from "../hooks/usePreSelectedWorkflowKinds";

const styles = {
  wrapper: css({
    height: "100%",
    display: "flex",
    flexDirection: "column"
  }),
  contentWrapper: css({
    flex: 1,
    overflowY: "auto"
  }),
  flexGrow: css({
    flexGrow: 1
  }),
  mainSection: css({
    display: "flex",
    flexDirection: "column",
    flex: 1
  }),
  mainContentWrapper: (shouldShowLeftPanel: boolean) =>
    css({
      position: "relative",
      ...(shouldShowLeftPanel && {
        paddingLeft: "17.5rem"
      })
    })
};

type SearchLayoutProps = {
  onClose: () => void;
};

export const SearchLayout = ({ onClose }: SearchLayoutProps) => {
  const { hasSearchQuery, areAdvancedFiltersApplied } = useSearchStore();
  const { recentSearches, setRecentSearches } = useRecentSearches();
  const { onSelectSearchResult, onSelectRecentSearch } = useSearchNavigation({
    setRecentSearches,
    onClose
  });
  usePreSelectedWorkflowKinds();
  const shouldShowLeftPanel = !areAdvancedFiltersApplied;

  const { handleKeyDown } = useSearchKeyboard({
    onSelectSearchResult,
    onSelectRecentSearch,
    recentSearches
  });

  return (
    <div className={styles.wrapper}>
      <SearchContainer onKeyDown={handleKeyDown} />

      <Stack
        direction="horizontal"
        width="100%"
        dangerouslySetClassName={styles.contentWrapper}
      >
        {hasSearchQuery ? (
          <>
            <Stack
              width="100%"
              height="100%"
              dangerouslySetClassName={styles.mainContentWrapper(
                shouldShowLeftPanel
              )}
            >
              <FilterBar />

              <LeftPanel />

              <Stack
                direction="horizontal"
                width="100%"
                dangerouslySetClassName={styles.contentWrapper}
              >
                <MainContent onResultClick={onSelectSearchResult} />
                <ResultPreview />
              </Stack>
            </Stack>
          </>
        ) : (
          <RecentSearchList
            items={recentSearches}
            onItemClick={onSelectRecentSearch}
          />
        )}
      </Stack>
    </div>
  );
};
