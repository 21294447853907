//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const UndoSquare = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="UndoSquare"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.28589 3.84291H4.92773L6.64185 2.17798L5.42907 1L2.25193 4.08678C2.0904 4.24287 2 4.45424 2 4.67536C2 4.89648 2.09039 5.10785 2.25193 5.26393L5.42907 8.35072L6.64185 7.17274L4.92773 5.5078H9.28589C10.9423 5.5078 12.2856 6.81259 12.2856 8.42144C12.2856 10.0303 10.9423 11.3351 9.28589 11.3351H5.42911V13H9.28589C10.9699 13 12.5258 12.1277 13.3679 10.7107C14.2107 9.29374 14.2107 7.54922 13.3679 6.13215C12.5259 4.71517 10.97 3.84287 9.28589 3.84287V3.84291Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
