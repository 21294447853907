import { Stack } from "@certa/blocks";
import { Text } from "@certa/blocks/thanos";

export const ReloadRequiredPage = () => {
  const reloadRequiredText =
    "Something went wrong. Please reload your browser.";
  return (
    <Stack
      style={{ height: "100vh", width: "100%" }}
      align="center"
      justify="center"
    >
      <Stack justify="center">
        <Text variant="h1-bold">{reloadRequiredText}</Text>
      </Stack>
    </Stack>
  );
};
