import type {
  FieldTypes,
  FilterConditionORM,
  ReportCustomFilter
} from "@certa/types";
import { CompareType } from "@certa/types";

export const ORM_REPORT_FORM_NAME = {
  KIND_TAG: "KIND_TAG",
  CHILDREN_KIND_IDS: "children_kind_ids",
  GRANDCHILDREN_KIND_IDS: "grandchildren_kind_ids",
  STATUS: "status",
  BUSINESS_UNIT: "business_unit",
  REGION: "region",
  ADVANCED_FILTERS: "advanced_filters"
} as const;

export const ORM_REPORT_FILTER_STATUS_PATH_LENGTH = 3;

export type OrmReportFilterStatusPath = [
  ORMCascaderCommonValuesType,
  // kind id
  string,
  // status tag
  string
];

export type ORMReportQuery = {
  [ORM_REPORT_FORM_NAME.KIND_TAG]: string;
  [ORM_REPORT_FORM_NAME.CHILDREN_KIND_IDS]: string[];
  [ORM_REPORT_FORM_NAME.GRANDCHILDREN_KIND_IDS]: string[];
  [ORM_REPORT_FORM_NAME.STATUS]: FilterConditionORM[];
  [ORM_REPORT_FORM_NAME.REGION]: FilterConditionORM[];
  [ORM_REPORT_FORM_NAME.BUSINESS_UNIT]: FilterConditionORM[];
  [ORM_REPORT_FORM_NAME.ADVANCED_FILTERS]: ReportCustomFilter;
};

export type KindsIdsQuery = Pick<
  ORMReportQuery,
  "KIND_TAG" | "children_kind_ids" | "grandchildren_kind_ids"
>;

export const defaultORMReportFilters: ORMReportQuery = {
  KIND_TAG: "",
  children_kind_ids: [],
  grandchildren_kind_ids: [],
  status: [],
  region: [],
  business_unit: [],
  advanced_filters: {
    compareType: CompareType.AND,
    conditions: [],
    groups: []
  }
};

export const ormReportProcessAttr = [
  "id",
  "name",
  "definition_id",
  "created_at",
  "kind_id",
  "kind_tag",
  "status_label",
  "status_tag",
  "status_id",
  "created_by_email",
  "created_by_name",
  "ageing",
  "cycle_time",
  "is_cycle_running",
  "cycle_start",
  "cycle_end",
  "business_unit_name",
  "business_unit_id",
  "region_name",
  "region_id",
  "logo",
  "lc_data",
  "parents",
  "alerts",
  "progress",
  "badges",
  "my_tasks_count"
] as const;

export const ormReportDynamicAPIProcessAttr: ORMReportProcessAttr[] = [
  "parents",
  "alerts",
  "progress",
  "badges",
  "my_tasks_count"
] as const;

export type ORMReportProcessAttr = (typeof ormReportProcessAttr)[number];
export type ORMReportFieldAttr =
  | "id"
  | "definition_id"
  | "is_enabled"
  | "is_required"
  | "tag"
  | "body"
  | "answer"
  | "submitted_by_email"
  | "submitted_at"
  | "extra_json"
  | "attachment"
  | "files"
  | "field_type";

export type ORMReportStepAttr =
  | "id"
  | "completed_at"
  | "created_at"
  | "initiated_at"
  | "submit_count"
  | "is_enabled"
  | "is_locked"
  | "updated_at"
  | "tag"
  | "name"
  | "ageing"
  | "cycle_time"
  | "is_cycle_running"
  | "cycle_start"
  | "cycle_end"
  | "sla_start_time"
  | "sla_breach_time"
  | "sla_elapsed_time"
  | "is_sla_breached"
  | "resolution_time"
  | "assignee_names"
  | "assignee_emails"
  | "completed_by_name"
  | "completed_by_email";

export type ORMReportStepGroupAttr =
  | "id"
  | "tag"
  | "name"
  | "ageing"
  | "cycle_time"
  | "is_cycle_running"
  | "cycle_start"
  | "cycle_end";

export type ORMReportDataType =
  | FieldTypes.TEXT
  | FieldTypes.INTEGER
  | FieldTypes.DECIMAL
  | FieldTypes.DATE
  | FieldTypes.BOOL
  | FieldTypes.RELATIVE_DATE;

export type ORMReportFilterConditionOperator =
  | "eq"
  | "not_eq"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "in"
  | "not_in"
  | "contains"
  | "not_contains"
  | "range"
  | "is_set"
  | "is_empty";

export const VALUE_REQUIRED_FOR_OPERATORS = [
  "eq",
  "not_eq",
  "gt",
  "gte",
  "lt",
  "lte",
  "in",
  "not_in",
  "contains",
  "not_contains"
] as const;

export type ValueRequiredForOperators =
  (typeof VALUE_REQUIRED_FOR_OPERATORS)[number];

export type ORMReportFilterConditionJoinRelation =
  | "children"
  | "children::children";

export type OrmReportFilterConditionRHSValue =
  | string
  | number
  | undefined
  | (string | number | boolean)[]
  | boolean;

const ormReportFilterTypeOperators = ["all", "any"] as const;
export type OrmReportFilterAdvancedType =
  (typeof ormReportFilterTypeOperators)[number];

export type ORMReportColumnIsAddedBy =
  | "filter"
  | "column"
  | "xAxis"
  | "yAxis"
  | "secondaryXAxis";

export type AddedByFilterTypeOrm = "processType" | "advancedFilter";

export const ORM_CASCADER_COMMON_LABELS = {
  PARENTS: "Parent",
  CHILDREN: "Children",
  GRANDCHILDREN: "Grandchildren"
} as const;
export const ORM_CASCADER_COMMON_VALUES = {
  PARENTS: "parents",
  CHILDREN: "children",
  GRANDCHILDREN: "grandchildren"
} as const;
export type OrmCascaderCommonLabelsType =
  (typeof ORM_CASCADER_COMMON_LABELS)[keyof typeof ORM_CASCADER_COMMON_LABELS];
export type ORMCascaderCommonValuesType =
  (typeof ORM_CASCADER_COMMON_VALUES)[keyof typeof ORM_CASCADER_COMMON_VALUES];

export type OrmReportSortingOrder = "ASC" | "DESC";
export type ORMAttributeType = "field" | "step" | "step_group" | "attr";
