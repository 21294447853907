//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronDownLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronDownLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4743 4.81438C12.8233 4.46629 13.3898 4.46629 13.7389 4.81438C14.087 5.16347 14.087 5.72997 13.7389 6.07906L8.63234 11.1857C8.28325 11.5337 7.71675 11.5337 7.36766 11.1857L2.26107 6.07906C1.91298 5.72997 1.91298 5.16347 2.26107 4.81438C2.61016 4.46629 3.17666 4.46629 3.52574 4.81438L8.00193 9.29159L12.4743 4.81438Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
