//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Badge = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Badge"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_4247)">
            <path
              d="M4 -0.00125122C2.89563 -0.00125122 2 0.89438 2 1.99875V10.6656C2 10.8575 2.08312 11.0406 2.2275 11.1669L7.56126 15.8337C7.81251 16.0537 8.18752 16.0537 8.43876 15.8337L13.7725 11.1669C13.9169 11.0406 14 10.8575 14 10.6656V1.99875C14 0.89438 13.1044 -0.00125122 12 -0.00125122L4 -0.00125122Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_4247">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
