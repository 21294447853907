import { useCommsNewEndpoints } from "@certa/common/toggles";
import { APIFetchComms, tenant } from "@certa/network";
import { useQuery } from "react-query";
import { queryClient } from "../../utils/utils";

type Attachment = {
  uid: string;
  filename: string;
  path: string;
};

type ConversationMessages = {
  krypton_id: number;
  attachments: Attachment[];
  body: string;
  conversation_id: {
    $oid: string;
  };
  mentioned_groups: number[];
  mentioned_users: number[];
  posted_at: {
    $date: string;
  };
  posted_by: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
    groups: number[];
  };
  read_by?: number[];
};
type ConversationMessagesResponse = {
  next: boolean;
  results: ConversationMessages[];
  total: number;
  count: number;
};
type ConversationMessagesModel = {
  readBy?: number[];
  attachments: Attachment[];
  body: string;
  conversationId: string;
  kryptonId: number;
  mentionedGroups: number[];
  mentionedUsers: number[];
  postedAt: string;
  postedBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
    groups: number[];
  };
};

type ConversationMessagesModelResponse = {
  total: number;
  count: number;
  next: boolean;
  results: ConversationMessagesModel[];
};
const getConversationMessagesComms = async (conversationId: string) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "x-tenant": tenant,
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  return APIFetchComms<ConversationMessagesResponse>(
    `message?conversation_id=${conversationId}`,
    requestOptions
  ).then(conversationMessageModelCreator);
};

const conversationMessageModelCreator = (
  messages: ConversationMessagesResponse
): ConversationMessagesModelResponse => {
  return {
    total: messages.total,
    count: messages.count,
    next: messages.next,
    results: messages.results.map(conversation => ({
      kryptonId: conversation.krypton_id,
      conversationId: conversation.conversation_id.$oid,
      body: conversation.body,
      postedBy: {
        id: conversation.posted_by.id,
        firstName: conversation.posted_by.first_name,
        lastName: conversation.posted_by.last_name,
        email: conversation.posted_by.email,
        fullName:
          conversation.posted_by.full_name?.trim() ||
          conversation.posted_by.email,
        groups: conversation.posted_by.groups
      },
      postedAt: conversation.posted_at.$date,
      mentionedGroups: conversation.mentioned_groups,
      mentionedUsers: conversation.mentioned_users,
      readBy: conversation.read_by,
      attachments: conversation.attachments
    }))
  };
};
const useGetConversationMessagesComms = (conversationId: string) => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  return useQuery(
    ["useGetConversationMessagesComms", conversationId],
    () => getConversationMessagesComms(conversationId),
    {
      enabled: !!conversationId && isCommsNewEndpointEnabled
    }
  );
};

/**
 * Invalidates conversation messages queries for a specific conversation ID
 * @param conversationId - The ID of the conversation to invalidate
 */
const invalidateConversationMessages = (conversationId: string) => {
  queryClient.invalidateQueries([
    "useGetConversationMessagesComms",
    conversationId
  ]);
};

export { useGetConversationMessagesComms, invalidateConversationMessages };
