import { useIntl } from "react-intl";

export const useImpersonationIntlMessages = () => {
  const intl = useIntl();

  const searchUsersPlaceholderText = `${intl.formatMessage({
    id: "impersonatee.searchUsers",
    defaultMessage: "Search Users..."
  })}`;

  const noUsersText = intl.formatMessage({
    id: "impersonatee.noUsers",
    defaultMessage: "No Users"
  });

  const usersErrorText = intl.formatMessage({
    id: "impersonation.genericAPIFailure",
    defaultMessage:
      "Error while trying to impersonate a user! Please contact the administrator."
  });

  const specific434ErrorText = ({
    impersonator,
    impersonatee,
    errorTag
  }: {
    impersonator: string;
    impersonatee: string;
    errorTag: string;
  }) =>
    intl.formatMessage(
      {
        id: `impersonation.${errorTag}`,
        defaultMessage: "An error occurred! Please contact the administrator."
      },
      {
        // eslint-disable-next-line formatjs/enforce-placeholders
        impersonator,
        // eslint-disable-next-line formatjs/enforce-placeholders
        impersonatee
      }
    );

  return {
    searchUsersPlaceholderText,
    noUsersText,
    usersErrorText,
    specific434ErrorText
  };
};
