//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Progress = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Progress"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3845)">
            <path
              d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM3.2 8C3.2 10.651 5.34903 12.8 8 12.8C10.651 12.8 12.8 10.651 12.8 8C12.8 5.34903 10.651 3.2 8 3.2C5.34903 3.2 3.2 5.34903 3.2 8Z"
              fill="white"
            />
            <path
              d="M8 0C9.71302 2.04275e-08 11.3808 0.549858 12.7579 1.56866C14.1351 2.58746 15.1488 4.0214 15.65 5.65946C16.1511 7.29753 16.1133 9.05321 15.5419 10.6681C14.9706 12.2831 13.896 13.672 12.4763 14.6305C11.0565 15.589 9.36655 16.0664 7.65512 15.9926C5.9437 15.9187 4.30117 15.2975 2.96925 14.2203C1.63732 13.143 0.686342 11.6667 0.256252 10.0086C-0.173838 8.35043 -0.0603262 6.59801 0.580084 5.00921L3.54805 6.20552C3.1638 7.15881 3.0957 8.21026 3.35375 9.20514C3.61181 10.2 4.18239 11.0858 4.98155 11.7322C5.7807 12.3785 6.76622 12.7512 7.79307 12.7955C8.81993 12.8398 9.83389 12.5534 10.6858 11.9783C11.5376 11.4032 12.1824 10.5698 12.5252 9.60089C12.868 8.63193 12.8907 7.57852 12.59 6.59568C12.2893 5.61284 11.681 4.75248 10.8548 4.1412C10.0285 3.52991 9.02781 3.2 8 3.2V0Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3845">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
