import { css } from "emotion";
import { FieldStatus } from "@certa/catalyst/components/types";

import { NumberInput } from "@certa/catalyst/components/Inputs";
import { Popover } from "@certa/catalyst/components/Popover";
import { Select } from "@certa/catalyst/components/Select";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";

import { Stack } from "@certa/catalyst/layouts/Stack";
import type { WithinTheLastValueFieldProps } from "./WithinTheLastValueField.types";
import { useWithinTheLastValueField } from "./hooks/useWithinTheLastValueField";

export const WithinTheLastValueField = (
  props: WithinTheLastValueFieldProps
) => {
  const { disabled: isDisabled, id } = props;
  const {
    numberInputLabel,
    withinTheLast,
    handleChange,
    timePeriod,
    timePeriodOptions,
    errorMessage
  } = useWithinTheLastValueField(props);

  return (
    <Popover
      visible={!!errorMessage}
      placement="top-start"
      content={
        <ErrorPopoverContent
          errorId={id + " within the last"}
          errorMessage={errorMessage}
        />
      }
      showArrow
    >
      <Stack gap="8" justify="flex-end" direction="horizontal">
        <NumberInput
          label={numberInputLabel}
          disabled={isDisabled}
          css={inputNumberFieldCSS}
          value={withinTheLast}
          allowClear={true}
          onChange={value => {
            handleChange(
              "withinTheLast",
              value !== undefined ? value?.toString() : undefined
            );
          }}
          error={errorMessage}
          status={Boolean(errorMessage) ? FieldStatus.ERROR : undefined}
          id={id + " within the last"}
        />
        <Select
          label="Time Period"
          disabled={isDisabled}
          options={timePeriodOptions}
          defaultValue={timePeriod}
          menuPortalTarget={document.body}
          value={timePeriod}
          onChange={value => handleChange("timePeriod", value)}
          width="100%"
          isClearable={false}
        />
      </Stack>
    </Popover>
  );
};

type ErrorPopoverContentProps = {
  errorMessage: string;
  errorId: string;
};

const ErrorPopoverContent = (props: ErrorPopoverContentProps) => {
  const { errorMessage, errorId } = props;
  return (
    <div className={popoverContentCSS}>
      <Typography
        variant={TypographyVariants.LABEL_SM}
        color={TypographyColors.RED_400}
        aria-live="polite"
        aria-atomic="true"
        id={errorId}
      >
        {errorMessage}
      </Typography>
    </div>
  );
};

const popoverContentCSS = css({
  padding: "var(--s2)"
});

const inputNumberFieldCSS = css({
  "& input": {
    textAlign: "right",
    /* Firefox */
    MozAppearance: "textfield"
  },
  /* Chrome, Safari, Edge, Opera */
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  }
});
