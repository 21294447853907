import {
  Tooltip,
  TooltipPlacements,
  TooltipVariants
} from "@certa/catalyst/components/Tooltip";
import {
  Ellipsis,
  Typography,
  TypographyColors,
  TypographyVariants
} from "@certa/catalyst/components/Typography";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { classNames } from "@certa/catalyst/utils/common";
import { overviewListRowStyles as styles } from "./OverviewListRow.styles";

type BaseListRowProps = {
  label: string;
  value: string | React.ReactNode;
  onHover?: () => void;
  shouldWrapReactNodeWithTypography?: boolean;
};

type WithoutTooltipProps = BaseListRowProps & {
  shouldShowCustomTooltip?: false;
  tooltip?: never;
};

type WithTooltipProps = BaseListRowProps & {
  shouldShowCustomTooltip: true;
  tooltip: React.ReactNode;
};

type ListRowProps = WithTooltipProps | WithoutTooltipProps;

const OverviewListRow = ({
  label,
  value,
  onHover,
  shouldShowCustomTooltip,
  tooltip,
  shouldWrapReactNodeWithTypography
}: ListRowProps) => {
  const getContent = () => {
    // Case 1: Custom tooltip
    if (shouldShowCustomTooltip) {
      return (
        <Tooltip
          content={tooltip}
          variant={TooltipVariants.LIGHT}
          targetClassName={styles.value}
          placement={TooltipPlacements.LEFT_END}
        >
          <Typography
            variant={TypographyVariants.LABEL_SM}
            color={TypographyColors.NEUTRAL_600}
            className={classNames(styles.value, "ellipsis")}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    }

    // Case 2: String with auto-tooltip on truncation
    if (typeof value === "string") {
      return (
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_600}
          className={styles.value}
        >
          <Ellipsis>{value}</Ellipsis>
        </Typography>
      );
    }

    if (shouldWrapReactNodeWithTypography) {
      return (
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_600}
          className={classNames(styles.value, "ellipsis")}
        >
          {value}
        </Typography>
      );
    }
    return value;
  };

  return (
    <div onMouseEnter={onHover} className={styles.row}>
      <Stack
        direction="horizontal"
        gap="16"
        overflow="hidden"
        justify="space-between"
        align="center"
        width="100%"
      >
        <Typography
          variant={TypographyVariants.LABEL_SM}
          color={TypographyColors.NEUTRAL_600}
          className={styles.label}
        >
          <Ellipsis>{label}</Ellipsis>
        </Typography>
        <div className={styles.valueContainer}>{getContent()}</div>
      </Stack>
    </div>
  );
};

export { OverviewListRow };
