import { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { css, cx } from "emotion";
import styled from "@emotion/styled";
import { Dropdown, Menu } from "antd";
import { EllipsisOutlined, MenuOutlined } from "@ant-design/icons";
import { isEmpty, get as lodashGet } from "lodash-es";
import { PermissionTypes } from "@certa/types";
import { Chowkidaar } from "@certa/common/utils/permissions/Chowkidar";
import { TABLEAU_PATH } from "@certa/common/utils/routes";
import { Image } from "@certa/blocks";
import { withFilters } from "../../dashboard/filters";
import { SelectLanguage } from "./SelectLanguage";
import { Banner } from "./Banner";
import { BetaIcon } from "./BetaIcon";
import { ExportWorkflow } from "./ExportWorkflow";
import { MOBILE_DEVICE } from "../../common/viewPort";
import { isSupplierUser, isCERTA2Enabled } from "../../common/selectors";
import { bannerDetail, checkBannerVisiblity } from "../../common/utils";
import { authHeader } from "../../../js/_helpers";
import { userLogout } from "../../LoginPage/loginSlice";
import { withRouterForClassComponents } from "../../router/routerHocForClassComponents";
import { IntlTooltip } from "../../../js/components/common/IntlTooltip";
import { getIntlBody } from "../../../js/_helpers/intl-helpers";
import { toggleLeftSidebar } from "../../../js/actions";
import { UserMentions } from "../../consolidatedUserMentions/components/UserMentions";
import Godaam from "../../../js/utils/storage";

class HeaderComponent extends Component {
  state = {
    searchValue: "",
    showUserMentions: false,
    userMentionsCount: 0
  };

  setUserMentionsCount = count => {
    if (this.state.userMentionsCount !== count) {
      this.setState({ userMentionsCount: count });
    }
  };

  onLogout = event => {
    this.props.userLogout();
  };

  supportLinks = () => {
    const footerLinks = this.props.config.custom_ui_labels
      ? this.props.config.custom_ui_labels.header_links
      : [];
    if (this.props.isSupplierUser) {
      return null;
    }
    if (footerLinks && footerLinks.length) {
      return [
        ...footerLinks.map(links => (
          <Menu.Item key={links.label}>
            <a href={links.url} target="_blank" rel="noopener noreferrer">
              {getIntlBody(links, "label")}
            </a>
          </Menu.Item>
        )),
        <Menu.Divider key="__menu-divider__" style={{ margin: "6px 0px" }} />
      ];
    } else {
      return null;
    }
  };

  toggleUserMentions = () => {
    this.setState(({ showUserMentions }) => ({
      showUserMentions: !showUserMentions
    }));
  };

  renderOnlyOnDashboard = component => {
    const { location } = this.props;
    return location.pathname === "/workflows/instances/" ||
      location.pathname === "/workflows/instances"
      ? component
      : null;
  };

  get headerLogoOrName() {
    const { config } = this.props;
    return !config.loading && config.logo ? (
      <Image
        alt={config.name}
        src={config.logo}
        height="34px"
        style={{
          marginLeft: "27px"
        }}
      />
    ) : !config.loading ? (
      <h3>{config.name}</h3>
    ) : (
      <h3>{authHeader.tenant}</h3>
    );
  }

  render() {
    const { userMentionsCount } = this.state;
    const { workflowList, authentication, config } = this.props;
    const user = authentication.user;
    const supportedLaguanges = config.supported_languages;
    const regexForUrl = /\/instances\/[\d]+/;
    const kinds = lodashGet(workflowList, "kinds.data", null);
    const showExportOption =
      workflowList.currentDashboardView === "KIND_VIEW" && !!kinds;
    const alternateEmailIcon = "alternate_email";
    const trendingUpIcon = "trending_up";
    const countOverflowMsg = "9+";
    const { bannerMessage, bannerColor } = bannerDetail(config);

    const isBannerVisible = checkBannerVisiblity(config);
    let showInsights = false;
    const userProfileId = user
      ? authentication.user.user_profile_workflow
      : null;
    if (
      user &&
      user.features &&
      user.features.includes("view_reports") &&
      config &&
      config.report_embed_url
    ) {
      showInsights = true;
    }
    return (
      <div
        id="site-header"
        className={css({
          background: "#fff",
          position: "fixed",
          width: "100%",
          left: 0,
          zIndex: 1099,
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07)",
          height: isBannerVisible ? "85px" : "60px",
          paddingTop: isBannerVisible ? "0px" : "8px"
        })}
      >
        {isBannerVisible ? (
          <Banner message={bannerMessage} color={bannerColor} />
        ) : null}
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: isBannerVisible ? 0 : "4px"
          })}
        >
          {Godaam.user &&
            this.renderOnlyOnDashboard(
              <UserMentions
                toggleUserMentions={this.toggleUserMentions}
                shouldShowUserMentions={this.state.showUserMentions}
                setUserMentionsCount={this.setUserMentionsCount}
              />
            )}

          {this.renderOnlyOnDashboard(
            <MenuOutlined
              className={css(`
               color: #104774 !important;
               margin-left: 20px;
               font-size: 20px;

               @media (min-width: ${MOBILE_DEVICE}px) {
                 display: none !important;
               }
             `)}
              onClick={this.props.toggleLeftSidebar}
            />
          )}
          <div style={{ flexBasis: "300px" }}>
            <Chowkidaar
              check={PermissionTypes.CAN_VIEW_DASHBOARD}
              deniedElement={this.headerLogoOrName}
            >
              <Link to="/">{this.headerLogoOrName}</Link>
            </Chowkidaar>
          </div>

          <div
            style={{
              marginRight: "29.66px",
              width: regexForUrl.test(document.location.pathname)
                ? isEmpty(supportedLaguanges)
                  ? "180px"
                  : "180px"
                : "350px",
              flexGrow: 1,
              textAlign: "right",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            {this.renderOnlyOnDashboard(
              <span
                className={css`
                  display: flex;
                  position: relative;
                  margin-right: 24px;
                `}
              >
                <i
                  className={cx([
                    "material-icons",
                    "material-icons-24",
                    css(`
                    cursor: pointer;
                    color: #000;
                    opacity: 0.3
                  `)
                  ])}
                  onClick={this.toggleUserMentions}
                >
                  {alternateEmailIcon}
                </i>
                {userMentionsCount > 0 ? (
                  <StyledUserMentionsCount>
                    {userMentionsCount > 9
                      ? countOverflowMsg
                      : userMentionsCount}
                  </StyledUserMentionsCount>
                ) : null}
              </span>
            )}
            {showInsights ? (
              <span className="pd-ard-sm mr-right-lg ">
                <IntlTooltip
                  title={"tooltips.showReportsText"}
                  placement="left"
                >
                  <Link to={TABLEAU_PATH}>
                    <i
                      className="material-icons text-middle text-anchor"
                      style={{ fontSize: 24, color: "#000000", opacity: 0.3 }}
                    >
                      {trendingUpIcon}
                    </i>
                  </Link>
                </IntlTooltip>
              </span>
            ) : null}

            {this.props.isCERTA2Enabled ? <BetaIcon /> : null}

            {showExportOption ? (
              <Chowkidaar check={Permissions.CAN_EXPORT_WORKFLOW_DATA}>
                <ExportWorkflow />
              </Chowkidaar>
            ) : null}

            {user ? (
              <Dropdown
                getPopupContainer={() => document.getElementById("site-header")}
                trigger={["click", "hover"]}
                overlay={
                  <Menu
                    getPopupContainer={() =>
                      document.getElementById("site-header")
                    }
                    style={{ minWidth: 300 }}
                  >
                    {this.supportLinks()}
                    <SelectLanguage
                      trigger={this.props.triggerLanguageMenuAction || "hover"}
                    />
                    {userProfileId ? (
                      <Menu.Item key="profile">
                        <Link to={`/workflows/instances/${userProfileId}`}>
                          <FormattedMessage
                            id={"workflowsInstances.profileText"}
                          />
                        </Link>
                      </Menu.Item>
                    ) : null}
                    <Menu.Item key="logout" onClick={this.onLogout}>
                      <FormattedMessage id={"loginPageInstances.logoutText"} />
                    </Menu.Item>
                  </Menu>
                }
              >
                <EllipsisOutlined
                  style={{
                    fontSize: 30,
                    color: "#000000",
                    opacity: 0.3
                  }}
                />
              </Dropdown>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    workflowList,
    authentication,
    config,
    languageSelector,
    showFilterMenu
  } = state;
  return {
    workflowList,
    authentication,
    config,
    languageSelector,
    showFilterMenu,
    isSupplierUser: isSupplierUser(state),
    isCERTA2Enabled: isCERTA2Enabled(state)
  };
}

export const Header = connect(mapStateToProps, {
  toggleLeftSidebar,
  userLogout
})(withRouterForClassComponents(injectIntl(withFilters(HeaderComponent))));

const StyledUserMentionsCount = styled.span`
  position: absolute;
  left: 70%;
  bottom: 55%;
  height: 14.25px;
  width: 14.19px;
  border-radius: 11.25px;
  background-color: #00bcff;
  color: #ffffff;
  font-family: Cabin, "system-ui";
  font-size: 9px;
  font-weight: bold;
  letter-spacing: -0.02px;
  line-height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
