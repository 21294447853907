import React, {
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
  Fragment
} from "react";
import { type ReactNode } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { nanoid } from "nanoid";

import { ArrowLeftLight } from "@certa/icons/components/ArrowLeftLight";
import { Close } from "@certa/icons/components/Close";

import type { ButtonProps } from "../Button";
import { Button, ButtonSizes, ButtonTypes, ButtonVariants } from "../Button";
import { Dimmer } from "../Dimmer";

import { CATALYST_Z_INDEXES } from "../../constants/styles";

import styles from "./Drawer.module.css";
import { classNames } from "../../utils/common";
import { getTransformedPaddingWithCSSVariables } from "../../layouts/utils";
import type { Padding } from "../../layouts/types";

// please make sure if you are using preventCloseOnClickOutside = true,
// then on closing modal you should focus on the element from where you opened the modal
type DrawerCloseProps =
  | {
      preventCloseOnClickOutside?: boolean;
      onClose: () => void;
      showCloseIcon: boolean;
    }
  | {
      preventCloseOnClickOutside: boolean;
      onClose: () => void;
      showCloseIcon?: boolean;
    }
  | {
      preventCloseOnClickOutside: boolean;
      onClose: () => void;
      showCloseIcon: boolean;
    }
  | {
      preventCloseOnClickOutside?: undefined;
      onClose?: undefined;
      showCloseIcon?: undefined;
    };

export enum DrawerPlacements {
  LEFT = "Left",
  RIGHT = "Right"
}

type DrawerProps = {
  show: boolean;
  title: string | React.ReactNode;
  description?: string;
  "aria-label": string;
  width?: string;
  minWidth?: string;
  height?: string;
  padding?: Padding;
  transform?: string;
  children: React.ReactNode;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  secondaryButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  placement?: DrawerPlacements;
  getPositioning?: () => { left?: string; right?: string; top?: string };
  onBack?: () => void;
  canShowDimmer?: boolean;
} & DrawerCloseProps;

const Drawer = (props: DrawerProps) => {
  const {
    show: shouldShow = false,
    "aria-label": ariaLabel,
    title,
    description,
    children,
    width = "400px",
    minWidth,
    height = "100%",
    padding,
    preventCloseOnClickOutside: shouldPreventCloseOnClickOutside = false,
    primaryActionText = "Ok",
    onPrimaryAction,
    primaryButtonProps = {
      loading: false,
      disabled: false
    },
    secondaryActionText = "Cancel",
    onSecondaryAction,
    secondaryButtonProps = {
      loading: false,
      disabled: false
    },
    onClose,
    onBack,
    showCloseIcon: shouldShowCloseIcon = true,
    placement = DrawerPlacements.RIGHT,
    transform,
    getPositioning,
    canShowDimmer = true
  } = props;
  const rootElement = document.getElementById("catalyst-drawers-wrapper");

  const drawerTriggerElementRef = useRef<HTMLElement | null>();

  const descriptionId = description
    ? "catalyst-drawer-description-" + nanoid(6)
    : undefined;

  useLayoutEffect(() => {
    if (!rootElement) {
      return;
    }

    if (shouldShow) {
      rootElement.setAttribute("role", "presentation");
      rootElement.style.zIndex = CATALYST_Z_INDEXES.DRAWER;
    } else {
      rootElement.removeAttribute("role");
      rootElement.style.zIndex = "";
    }
  }, [rootElement, shouldShow]);

  const returnFocusToTriggeredElement = useCallback(() => {
    requestAnimationFrame(() => {
      drawerTriggerElementRef.current?.focus();
      drawerTriggerElementRef.current = null;
    });
  }, []);

  const handleClose = useCallback(() => {
    if (!shouldPreventCloseOnClickOutside) {
      onClose?.();
      returnFocusToTriggeredElement();
    }
  }, [
    onClose,
    shouldPreventCloseOnClickOutside,
    returnFocusToTriggeredElement
  ]);

  const onClickCloseIcon = useCallback(() => {
    onClose?.();
    returnFocusToTriggeredElement();
  }, [onClose, returnFocusToTriggeredElement]);

  // This is to focus the trigger element on drawer close.
  // Also to avoid losing the trigger element reference.
  useEffect(() => {
    if (shouldShow) {
      drawerTriggerElementRef.current = document.activeElement as HTMLElement;
    }
  }, [shouldShow]);

  useEffect(() => {
    const onEscapeKeyHandler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        handleClose();
      }
    };

    if (shouldShow) {
      window.addEventListener("keydown", onEscapeKeyHandler);
    } else {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    }

    return () => {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    };
  }, [handleClose, shouldShow]);

  // Extract custom header if present
  let customHeader: ReactNode | null = null;
  const filteredChildren = React.Children.toArray(children).filter(child => {
    if (React.isValidElement(child) && child.type === DrawerHeader) {
      customHeader = child;
      return false;
    }
    return true;
  });

  const defaultPositioning = {
    left: placement === DrawerPlacements.LEFT ? 0 : undefined,
    right: placement === DrawerPlacements.RIGHT ? 0 : undefined,
    top: 0
  };

  const positioningResult = getPositioning ? getPositioning() : {};

  const {
    left = defaultPositioning.left,
    right = defaultPositioning.right,
    top = defaultPositioning.top
  } = positioningResult;

  return rootElement && shouldShow
    ? createPortal(
        <>
          {canShowDimmer && <Dimmer onClick={handleClose} scrollLock />}
          <FocusLock disabled={!shouldShow}>
            <div
              role="dialog"
              aria-modal={true}
              aria-label={ariaLabel}
              aria-describedby={descriptionId}
              className={classNames({
                [styles.catalystDrawer]: true,
                [styles[`catalystDrawer${placement}`]]: true
              })}
              style={{
                width,
                height,
                transform,
                left,
                right,
                top,
                minWidth
              }}
            >
              {customHeader ? (
                customHeader
              ) : (
                <div className={styles.catalystDrawerHeader}>
                  {/* This is to maintain the space */}
                  {onBack && (
                    <div className={styles.catalystDrawerIconPlaceholder}></div>
                  )}
                  <div className={styles.catalystDrawerHeaderContent}>
                    {description && (
                      <div
                        className={styles.catalystDrawerDescription}
                        id={descriptionId}
                      >
                        {description}
                      </div>
                    )}
                    <div className={styles.catalystDrawerTitle}>{title}</div>
                  </div>
                  {shouldShowCloseIcon && (
                    <div className={styles.catalystDrawerIconPlaceholder}></div>
                  )}
                </div>
              )}
              <div
                className={styles.catalystDrawerContent}
                style={{
                  padding:
                    padding !== undefined
                      ? getTransformedPaddingWithCSSVariables(padding)
                      : `0 var(--space-24)`
                }}
              >
                {filteredChildren}
              </div>
              {(onPrimaryAction || onSecondaryAction) && (
                <div className={styles.catalystDrawerFooter}>
                  {onSecondaryAction && (
                    <Button
                      variant={ButtonVariants.TEXT}
                      {...secondaryButtonProps}
                      onClick={onSecondaryAction}
                    >
                      {secondaryActionText}
                    </Button>
                  )}
                  <Button
                    variant={ButtonVariants.FILLED}
                    {...primaryButtonProps}
                    onClick={onPrimaryAction}
                  >
                    {primaryActionText}
                  </Button>
                </div>
              )}
              {onBack && (
                <div className={styles.catalystDrawerBackIcon}>
                  <Button
                    aria-label="Go back"
                    type={ButtonTypes.ICON}
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.SMALL}
                    leftIcon={<ArrowLeftLight />}
                    onClick={onBack}
                  />
                </div>
              )}
              {shouldShowCloseIcon && (
                <div className={styles.catalystDrawerCloseIcon}>
                  <Button
                    leftIcon={<Close />}
                    type={ButtonTypes.ICON}
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.SMALL}
                    onClick={onClickCloseIcon}
                    aria-label="Close"
                  />
                </div>
              )}
            </div>
          </FocusLock>
        </>,
        rootElement
      )
    : null;
};

const DrawerHeader = ({ children }: { children: React.ReactNode }) => (
  <Fragment>{children}</Fragment>
);

export { DrawerHeader, Drawer };
