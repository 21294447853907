import React, { useState, forwardRef } from "react";

import { classNames } from "../../../utils/common";

import styles from "./NavbarItem.module.css";
import { Badge, BadgeColors, BadgeSizes } from "../../../components/Badge";
import {
  Tooltip,
  TooltipPlacements,
  TooltipVariants
} from "../../../components/Tooltip";
import {
  Typography,
  TypographyColors,
  TypographyVariants
} from "../../../components/Typography";

type NavbarItemBaseProps = {
  /**
   * The label of the navlink to be displayed
   */
  label: string;
  /**
   * The id of the item to be used as a key
   */
  id: string;
  /**
   * The icon to be displayed left of the name
   */
  icon?: React.ReactElement;
  /**
   * The count to be displayed in the badge if any
   */
  badgeCount?: number;
  /**
   * The icon to be displayed right to the name
   */
  rightIcon?: React.ReactElement;
  /**
   * The tag to be used for the item
   * @default "li"
   */
  as?: "li" | "div";
};

type NavbarItemStrictActionProps =
  | {
      /**
       * The path to be used for navigation when the item is clicked.
       */
      path: string;
      /**
       * The function to be called when the item is clicked.
       */
      onClick?: () => void;
    }
  | {
      path?: string;
      onClick: () => void;
    };

export type NavbarItemProps = NavbarItemBaseProps & NavbarItemStrictActionProps;

export type NavbarItemAltProps = NavbarItemProps & {
  expanded: boolean;
  isActive: boolean;
};

type NavbarItemInternalProps = {
  expanded: boolean;
  isActive: boolean;
  // These two events are majorly used for dropdown menu trigger
  onPointerDown?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (evt: React.KeyboardEvent<HTMLButtonElement>) => void;
};

export const NavbarItem = forwardRef<HTMLButtonElement, NavbarItemProps>(
  (props, ref) => {
    const {
      label,
      id,
      path,
      icon,
      onClick,
      badgeCount,
      rightIcon,
      as = "li",
      ...internalProps
    } = props;
    const Component = as;

    // Typecasting here to use the internal props because it is added only in Navbar component
    const {
      expanded: isExpanded,
      isActive,
      onKeyDown,
      onPointerDown
    } = internalProps as NavbarItemInternalProps;

    const shouldShowDotInCollapsedState =
      !isExpanded && badgeCount !== undefined && badgeCount > 0;

    const handleOnClick = () => {
      onClick?.();
    };

    const [isHovered, setIsHovered] = useState(false);

    // Extracting aria props majorly used by dropdown menu trigger
    const ariaProps: React.AriaAttributes = Object.entries(internalProps)
      .filter(([restPropKey]) => restPropKey.includes("aria-"))
      .reduce((ariaProps, [ariaKey, ariaValue]) => {
        return { ...ariaProps, [ariaKey]: ariaValue };
      }, {});

    // Extracting data props majorly used by dropdown menu trigger
    const dataProps = Object.entries(internalProps)
      .filter(([restPropKey]) => restPropKey.includes("data-"))
      .reduce((ariaProps, [ariaKey, ariaValue]) => {
        return { ...ariaProps, [ariaKey]: ariaValue };
      }, {});

    return (
      <Component
        key={id}
        className={classNames({
          [styles.catalystNavbarItem]: true,
          [styles.catalystNavbarItemActive]: isActive,
          [styles.catalystNavbarItemCollapsed]: !isExpanded
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Tooltip
          content={label}
          variant={TooltipVariants.LIGHT}
          placement={TooltipPlacements.RIGHT}
          show={!isExpanded}
          targetClassName={styles.catalystNavbarItemTooltip}
        >
          {/**
           * We are using button instead of anchor tag here
           * because using anchor tag will cause full page to reload
           * which is not idle in SPA. Also using anchor tag was causing
           * style overrides from bootstrap-reboot.min.less file when used
           * without an href attribute
           *  */}
          <button
            onClick={handleOnClick}
            aria-label={label}
            ref={ref}
            {...ariaProps}
            {...dataProps}
            onPointerDown={onPointerDown}
            onKeyDown={onKeyDown}
          >
            <span
              className={classNames({
                [styles.catalystNavbarItemIcon]: true,
                [styles.catalystNavbarItemIconCollapsed]:
                  shouldShowDotInCollapsedState
              })}
            >
              {shouldShowDotInCollapsedState && (
                <span
                  className={classNames({
                    [styles.catalystNavbarItemNotifyDot]: true,
                    [styles.catalystNavbarItemNotifyDotActive]: isActive
                  })}
                />
              )}
              {icon}
            </span>
            {isExpanded && label && (
              <Typography
                variant={TypographyVariants.LABEL_SM}
                as="span"
                color={
                  isActive
                    ? TypographyColors.NEUTRAL_100
                    : TypographyColors.NEUTRAL_600
                }
              >
                {label}
              </Typography>
            )}
            {isExpanded && badgeCount !== undefined && (
              <span className={styles.catalystNavbarItemBadge}>
                <Badge
                  label={badgeCount}
                  size={BadgeSizes.DEFAULT}
                  aria-label={`${badgeCount} results`}
                  color={
                    isHovered
                      ? BadgeColors.NEUTRAL_100
                      : BadgeColors.LIGHT_NEUTRAL
                  }
                  role="status"
                  overflowCount={99}
                  showZero={false}
                />
              </span>
            )}
            {isExpanded && rightIcon && (
              <span className={styles.catalystNavbarItemRightIcon}>
                {rightIcon}
              </span>
            )}
          </button>
        </Tooltip>
      </Component>
    );
  }
);
