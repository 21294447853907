/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import { Stack, Text, Tag, getAvatarLetters } from "@certa/blocks/thanos";
import {
  AvatarColors,
  AvatarSizes,
  Avatar
} from "@certa/catalyst/components/Avatar";
import { UserGroup } from "@certa/icons/components/UserGroup";
import type { Step, StepGroup } from "@certa/types";
import { Fragment, useMemo } from "react";
import { useIntl } from "react-intl";
import { AssignedToTagsPopover } from "./AssignedToTagsPopover";
import { useCurrentUser } from "@certa/common/hooks/useCurrentUser";

const AssignedToTags = ({
  group,
  hideAssignedToLabel
}: {
  group: StepGroup;
  hideAssignedToLabel?: boolean;
}) => {
  const intl = useIntl();

  const currentUser = useCurrentUser();

  const { groups, stepAssignedDirectly, allUserDetails } = useMemo(() => {
    const swimlaneAssignedGroups = group?.assignedTo?.map(assignee => ({
      value: assignee.value,
      label: assignee.label
    }));

    const stepAssignedUserGroups = group.steps.flatMap(
      (step: Step) => step?.assignedUserGroups || []
    );

    const stepAssignedGroups = stepAssignedUserGroups.map(
      (data: { name?: string; id?: number }) => ({
        value: `${data.id}`,
        label: `${data.name}`
      })
    );

    const stepAssignedDirectly = group.steps
      .map((step: Step) => step.assignedUserEmail)
      .filter(email => email) as string[];

    const allUsers = group.steps.flatMap((step: Step) => step.allUsers || []);

    const allUserDetails = stepAssignedDirectly.map(email => {
      return allUsers.find(user => user.user_email === email)!;
    });

    return {
      groups: [...swimlaneAssignedGroups, ...stepAssignedGroups],
      stepAssignedDirectly,
      allUserDetails
    };
  }, [group]);

  if (
    !groups.length &&
    !stepAssignedDirectly.length &&
    !allUserDetails.length
  ) {
    return null;
  }

  const isFirstUserCurrentUser =
    allUserDetails[0]?.user_email === currentUser.email;
  const firstUserNameOrEmail =
    allUserDetails[0]?.user_full_name?.trim() || allUserDetails[0]?.user_email;
  const hasMoreThanOneAssignee = stepAssignedDirectly.length > 1;
  const hasMoreThanOneAssigneeLabel = `+${hasMoreThanOneAssignee}`;

  return (
    <Stack gap="s1" direction="vertical" align="flex-start">
      {!hideAssignedToLabel && (
        <Text variant="p2-bold-upper" color="neutral-70" align="left">
          {intl.formatMessage({
            id: "tasklaneProgress.assignedTo",
            defaultMessage: "Assigned To"
          })}
        </Text>
      )}
      <Stack justify="flex-start" align="center" style={{ flexWrap: "nowrap" }}>
        {groups.length > 0 && (
          <Fragment>
            <Tag
              icon={<UserGroup color="neutral-100" autoSize />}
              key={groups[0].value}
            >
              <Text variant="p1-medium" color="neutral-100">
                {groups[0].label}
              </Text>
            </Tag>
            {groups.length > 1 && (
              <AssignedToTagsPopover
                assignedToGroups={groups}
                directAssigneeList={allUserDetails}
              />
            )}
          </Fragment>
        )}
        {stepAssignedDirectly.length > 0 && groups.length <= 1 && (
          <Stack
            css={css`
              display: inline-flex;
            `}
          >
            <Avatar
              aria-label={firstUserNameOrEmail}
              color={
                isFirstUserCurrentUser
                  ? AvatarColors.BRAND
                  : AvatarColors.NEUTRAL
              }
              size={AvatarSizes.SMALL}
            >
              {getAvatarLetters(firstUserNameOrEmail)}
            </Avatar>
            {hasMoreThanOneAssignee && (
              <Avatar
                aria-label={hasMoreThanOneAssigneeLabel}
                color={AvatarColors.NEUTRAL}
                size={AvatarSizes.SMALL}
              >
                {hasMoreThanOneAssigneeLabel}
              </Avatar>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export { AssignedToTags };
