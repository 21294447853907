//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Queue = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Queue"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_21705_3602)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 1.5C10 0.671573 9.32843 0 8.5 0H1.5C0.671573 0 0 0.671573 0 1.5V8.5C0 9.32843 0.671573 10 1.5 10H1.99997V3.5C1.99997 2.67157 2.67155 2 3.49997 2H10V1.5ZM11.5 3C12.3284 3 13 3.67157 13 4.5V5H6.49997C5.67155 5 4.99997 5.67157 4.99997 6.5V13H4.5C3.67157 13 3 12.3284 3 11.5V4.5C3 3.67157 3.67157 3 4.5 3H11.5ZM16 7.5C16 6.67157 15.3284 6 14.5 6H7.5C6.67157 6 6 6.67157 6 7.5V14.5C6 15.3284 6.67157 16 7.5 16H14.5C15.3284 16 16 15.3284 16 14.5V7.5Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_21705_3602">
              <rect width={16} height={16} fill="white" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
