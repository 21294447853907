import type { ChannelUser, ChannelUserGroup } from "@certa/types";
import { getDynamicGroupName, sortMentions } from "@certa/common/utils/mention";

export const extractAttachmentName = (attachmentURL: string) => {
  const parts = attachmentURL.split("/");
  return parts[parts.length - 1].split("?")[0];
};

export const prepareMentionOptions = ({
  threadUsers,
  threadUserGroups
}: {
  threadUsers: ChannelUser[];
  threadUserGroups: ChannelUserGroup[];
}) => {
  // Here we prepare a single array with groups first, followed by users, each
  // of them sorted alphabetically. Also, ID is appended with `u` or `g` for
  // users and groups respectively.

  return [
    ...threadUserGroups
      .map((group: ChannelUserGroup) => ({
        id: "g" + group.id,
        display: getDynamicGroupName(group.name),
        isBot: false
      }))
      .sort(sortMentions),
    ...threadUsers
      .map((user: ChannelUser) => ({
        id: "u" + user.id,
        display: user.full_name || user.email,
        isBot: user.email === "certa_bot@certa.in"
      }))
      .sort(sortMentions)
  ];
};
