import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash-es";

/**
 *
 * @param value
 * @param time
 * @returns debouncedValue
 */
export const useDebouncedValue = <T>(value: T, time: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, time);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value, time]);

  return debouncedValue;
};

export const useDebounceWithCancel = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  const debouncedFuncRef = useRef<ReturnType<typeof debounce>>();

  const debouncedFunc = (...args: any) => {
    if (debouncedFuncRef.current) {
      debouncedFuncRef.current.cancel();
    }
    debouncedFuncRef.current = debounce(callback, delay);
    debouncedFuncRef.current(...args);
  };

  return debouncedFunc;
};
