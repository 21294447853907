import { useCallback, useEffect } from "react";
import { get as lodashGet } from "lodash-es";
import { useSelector } from "react-redux";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { languageConstants } from "main/src/js/constants";
import { languageActions } from "main/src/js/actions";
import type { OptionItem } from "@certa/types";
import { renderLanguageNativeName } from "main/src/js/utils/languageHelpers";
import { FormattedMessage, useIntl } from "react-intl";
import { useGetSupportedLanguages } from "../../../hooks/useGetSupportedLanguages";
import { useAppSelector } from "../../../hooks";
import { Godaam } from "../../../utils/Godaam";
import { removeBracketsAndAppendDash } from "../../../utils/helper";
import { useAppDispatch as useDispatch } from "@certa/common/hooks";

/**
 * Custom hook for managing language-related functionality.
 *
 * @returns An object containing the following properties:
 *   - supportedLanguages: An array of supported languages.
 *   - preferredLanguage: The selected language for translations.
 *   - renderLanguageMenuItem: A function that renders the language menu item.
 *   - renderLanguageMenuTitle: A function that renders the language menu title.
 *   - handleLanguageChange: A function that handles language change on select.
 */
const useLanguages = () => {
  const supportedLanguages = useGetSupportedLanguages();
  const _supportedLanguagesJson = useAppSelector(state =>
    lodashGet(state, "config.supported_languages_json", {})
  );

  const preferredLanguage = useSelector(
    (state: ReduxState) =>
      state.languageSelector.language || languageConstants.DEFAULT_LOCALE
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Godaam.preferredLanguage) {
      dispatch(languageActions.setLanguage(languageConstants.DEFAULT_LOCALE));
    }
  }, [dispatch]);

  const renderLanguageMenuTitle = () => {
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        <FormattedMessage id={"loginPageInstances.languageText"} />:{" "}
        <span style={{ textTransform: "uppercase" }}>{preferredLanguage}</span>
      </span>
    );
  };

  const handleLanguageChange = useCallback(
    (value: string) => {
      dispatch(languageActions.updateUserLanguage(value));
    },
    [dispatch]
  );
  const intl = useIntl();

  const renderLanguageMenuItem = (language: OptionItem) => {
    const translatedLanguage = intl.formatMessage({
      id: `language.${_supportedLanguagesJson[language.value]}`,
      defaultMessage: _supportedLanguagesJson[language.value] as string
    });
    const nativeLanguageWithBrackets = renderLanguageNativeName(language.value);
    const formattedTranslatedLanguage =
      removeBracketsAndAppendDash(translatedLanguage);
    if (nativeLanguageWithBrackets) {
      const nativeLanguage = removeBracketsAndAppendDash(
        nativeLanguageWithBrackets
      );
      return `${formattedTranslatedLanguage} (${nativeLanguage} - ${language.value})`;
    }
    return `${formattedTranslatedLanguage} (${language.value})`;
  };

  return {
    supportedLanguages,
    preferredLanguage,
    renderLanguageMenuItem,
    renderLanguageMenuTitle,
    handleLanguageChange
  };
};

export { useLanguages };
