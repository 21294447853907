import type { SearchFilters } from "@certa/queries/types/globalSearch.types";

export const checkIfAnyFilterIsApplied = (filters: SearchFilters): boolean => {
  return (
    filters.regionIds.length > 0 ||
    filters.businessUnitIds.length > 0 ||
    filters.statusIds.length > 0 ||
    filters.startDate !== undefined ||
    filters.endDate !== undefined
  );
};
