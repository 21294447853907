import { DatePicker } from "@certa/catalyst/components/DatePicker";
import { PopperPlacements } from "@certa/catalyst/components/types";
import moment from "moment";
import type { InputFieldProps } from "./ValueFieldInput.types";
import { isValidDate } from "../../../../utils/dateFormatter";
import { POPPER_MODIFIER_TOP_BOTTOM_FLIP } from "../../../../constants";
import { useIntl } from "react-intl";

export const DatePickerInputField = ({
  value,
  disabled,
  onChange
}: InputFieldProps) => {
  const intl = useIntl();
  const placeholderText = intl.formatMessage({
    id: "common.selectADate",
    defaultMessage: "Select a date..."
  });
  return (
    <DatePicker
      aria-label={placeholderText}
      disabled={disabled}
      placeholder={placeholderText}
      value={isValidDate(value) ? moment(value) : undefined}
      onChange={(_, dateString) => {
        onChange(dateString);
      }}
      inputWidth="9.375rem"
      placement={PopperPlacements.AUTO}
      popperModifiers={POPPER_MODIFIER_TOP_BOTTOM_FLIP}
    />
  );
};
