import { useMemo } from "react";
import type { ChartViewORMProps } from "../types";
import { noChartData } from "../../../constants";
import {
  aggregateModelCreatorORM,
  cycleTimeModelCreatorORM
} from "@certa/common/modelCreators";
import {
  INDEX_ZERO,
  LENGTH_ZERO,
  PROCESS_SWIMLANES_TAG
} from "@certa/common/constants";

type Props = Pick<ChartViewORMProps, "apiData" | "chartConfigORM">;

export const useConvertToChartDataORM = (props: Props) => {
  const { apiData, chartConfigORM } = props;
  const {
    isCycleTimeReport = false,
    isStackedBarChart = false,
    isGroupedBarChart = false,
    cycleTimeTag
  } = chartConfigORM?.otherConfigurations ?? {};

  const yAxis = useMemo(
    () =>
      chartConfigORM?.operations?.filter(
        operation => operation.extraJSON.axisName === "yAxis"
      ),
    [chartConfigORM?.operations]
  );

  const xAxisLength = chartConfigORM?.groupBy?.length;
  const xAxisKey =
    chartConfigORM?.groupBy?.[INDEX_ZERO]?.extraJSON?.label ?? "";

  const reportChartData = useMemo(() => {
    if (yAxis?.length === LENGTH_ZERO) {
      return noChartData;
    } else if (isCycleTimeReport) {
      if (cycleTimeTag === undefined) {
        return noChartData;
      }
      const isSwimlaneLevelCycleTime = cycleTimeTag.includes(
        PROCESS_SWIMLANES_TAG
      );
      const metrics = yAxis?.map(operation => operation.function);

      return cycleTimeModelCreatorORM(
        apiData,
        isSwimlaneLevelCycleTime,
        metrics
      );
    }
    const outputDataTypes = yAxis?.map(
      operation => operation.extraJSON.outputDataType
    );
    return aggregateModelCreatorORM(
      isStackedBarChart && !isGroupedBarChart,
      outputDataTypes,
      xAxisLength,
      xAxisKey
    )(apiData);
  }, [
    yAxis,
    isCycleTimeReport,
    isStackedBarChart,
    isGroupedBarChart,
    xAxisLength,
    xAxisKey,
    apiData,
    cycleTimeTag
  ]);

  return reportChartData;
};
