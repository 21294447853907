import { useState, useEffect, useMemo } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart as ReBarChart,
  Cell,
  LabelList,
  ReferenceArea,
  CartesianGrid
} from "recharts";
import { TYPOGRAPHY_VARIANTS } from "@certa/blocks/thanos";
import { CustomAxesTick } from "../../customAxesTick/CustomAxesTick";
import { activeXLabel, lineStyle, tooltipCSS } from "./Barchart.styles";
import { UNIQUE_X_AXIS_IDENTIFIER } from "../constants";
import {
  CustomLegendWrapper,
  useGetLegendDataCommon
} from "../../customLegend/CustomLegend";
import {
  SHOW_ALL_TICKS,
  Y_AXIS_WIDTH,
  commonChartMargin
} from "../../../constants";
import {
  CustomXAxesLabel,
  CustomYAxesLabel
} from "../../customAxesLabel/CustomAxesLabel";
import { MultiXAxisCustomTooltip } from "../../tooltip/Tooltip";
import {
  axisFormatter,
  getLabelsFromChartData,
  getValueLabelTextAnchor,
  tooltipFormatter,
  xAxisFormatter
} from "../../../utils/chartUtils";
import type { ChartClickEvent, ChartComponentProps } from "@certa/common/types";
import { INDEX_ZERO } from "@certa/common/constants";
import { useChartReorder } from "./hooks/useChartReorder";

export const SimpleStackedBarChart = (props: ChartComponentProps) => {
  const {
    // The default value of isAnimationActive is set to false, therefore the
    // isAnimationActive parameter is not required in all test cases.
    // Only in test scenarios, if isAnimationActive is true, the value label will not be shown.
    isAnimationActive = false,
    xAxisDataLabels,
    chartData,
    height = 500,
    onClick,
    xActiveIdentifier,
    otherConfig,
    sorting,
    handleSorterChange,
    onReorder
  } = props;

  const { probableDataType, xAxisKey } = chartData;

  const {
    chartRef,
    chartContainerRef,
    isDraggingRef,
    orderedData,
    orderedDataWithUniqueXAxis,
    handleMouseDown,
    getCellColor,
    getCellCursor
  } = useChartReorder({
    data: chartData.data,
    initialOrder: otherConfig.dataOrder,
    onReorder,
    orientation: "vertical"
  });

  const [chartXLabels, setChartXLabels] = useState<string[]>([]);
  const [showValueFor, setShowValueFor] = useState<string | null>(null);
  const xAxisDataLabelsLength = xAxisDataLabels.length;
  const isMultiXAxis = xAxisDataLabelsLength > 1;

  const {
    colors,
    shouldShowValueLabels,
    showValueOn,
    showLegend,
    xAxis: otherConfigXAxis,
    xAxisLabel,
    yAxisLabel,
    showXAxisLabel,
    showYAxisLabel,
    valueLabelAngle
  } = otherConfig;

  useEffect(() => {
    setChartXLabels(
      getLabelsFromChartData(orderedData).filter(
        label => !xAxisDataLabels.includes(label)
      )
    );
  }, [orderedData, xAxisDataLabels]);

  useEffect(() => {
    setShowValueFor(null);
  }, [showValueOn]);

  const legendData = useGetLegendDataCommon({
    colors: colors,
    labels: chartXLabels,
    xAxisKey,
    fillOpacity: 0.6
  });

  const multiXAxisLabelFormatter = useMemo(
    () =>
      xAxisDataLabels.reduce((acc, label, index) => {
        Object.assign(acc, {
          [label]: xAxisFormatter(
            otherConfigXAxis?.dataTypes?.[index],
            otherConfigXAxis?.labelOutputTypes?.[index]
          )
        });
        return acc;
      }, {}),
    [
      otherConfigXAxis?.dataTypes,
      otherConfigXAxis?.labelOutputTypes,
      xAxisDataLabels
    ]
  );

  return (
    <CustomLegendWrapper
      sorting={sorting}
      handleSorterChange={newSorting => {
        // Clear reorder when sorting
        if (newSorting) {
          onReorder?.([]);
        }
        handleSorterChange?.(newSorting);
      }}
      data={legendData}
      showLegend={showLegend}
    >
      <ResponsiveContainer
        width="100%"
        height={height}
        className={showValueOn === "hover" ? lineStyle : activeXLabel}
        ref={chartContainerRef}
      >
        <ReBarChart
          ref={chartRef}
          data={orderedDataWithUniqueXAxis}
          onClick={(data: Omit<ChartClickEvent, "activeXIdentifier">) => {
            if (onClick && data) {
              onClick({
                ...data,
                activeXIdentifier:
                  data.activePayload?.[INDEX_ZERO]?.payload?.[
                    UNIQUE_X_AXIS_IDENTIFIER
                  ] ?? data.activeLabel
              });
            }
          }}
          margin={commonChartMargin}
        >
          <CartesianGrid
            strokeDasharray="4"
            stroke="#DFE2E7"
            vertical={false}
          />
          {xAxisDataLabels.map((label, index) => (
            <XAxis
              key={label}
              fontSize="12"
              xAxisId={index}
              dataKey={label}
              tickSize={15}
              tickLine={false}
              fontWeight={400}
              tickFormatter={xAxisFormatter(
                otherConfigXAxis?.dataTypes?.[index],
                otherConfigXAxis?.labelOutputTypes?.[index],
                label,
                orderedData,
                xAxisDataLabels[xAxisDataLabels.length - 1]
              )}
              interval={isMultiXAxis ? SHOW_ALL_TICKS : "preserveStartEnd"}
              stroke="#DFE2E7"
              // SYNC: color not in design system
              label={
                <CustomXAxesLabel
                  label={xAxisLabel}
                  showAxisLabel={
                    xAxisDataLabelsLength !== index + 1
                      ? "none"
                      : showXAxisLabel
                  }
                />
              }
              tick={CustomAxesTick}
            />
          ))}
          <XAxis
            xAxisId={xAxisDataLabels.length}
            hide
            dataKey={UNIQUE_X_AXIS_IDENTIFIER}
          />
          <YAxis
            fontSize="12"
            tickLine={false}
            tickSize={15}
            tickFormatter={axisFormatter(probableDataType)}
            fontWeight={400}
            width={Y_AXIS_WIDTH}
            interval="preserveStartEnd"
            tick={{ fill: "var(--neutral-70)" }}
            stroke="#DFE2E7"
            label={
              <CustomYAxesLabel
                label={yAxisLabel}
                showAxisLabel={showYAxisLabel}
              />
            }
          />
          <Tooltip
            active={isDraggingRef.current ? false : undefined}
            formatter={tooltipFormatter("BAR_CHART", probableDataType)}
            content={
              <MultiXAxisCustomTooltip
                multiXAxisLabelFormatter={multiXAxisLabelFormatter}
                xAxisDataLabels={xAxisDataLabels}
              />
            }
            wrapperClassName={tooltipCSS(height)}
            cursor={{
              fill: "var(--neutral-10)",
              style: onClick ? { cursor: "pointer", pointerEvents: "all" } : {}
            }}
          />
          <ReferenceArea
            xAxisId={xAxisDataLabels.length}
            x1={xActiveIdentifier}
            x2={xActiveIdentifier}
            fill="var(--neutral-50)"
            fillOpacity={1}
            label={{
              value: "",
              position: "center",
              angle: -90,
              offset: 12,
              style: TYPOGRAPHY_VARIANTS["p2-medium-upper"] as {}
            }}
          />
          {legendData.map(legend => (
            <Bar
              isAnimationActive={isAnimationActive}
              key={legend.label}
              dataKey={legend.label}
              fill={legend.background}
              fillOpacity={legend.fillOpacity}
              stackId={xAxisKey}
              style={showValueOn === "click" ? { cursor: "pointer" } : {}}
              onMouseDown={data =>
                handleMouseDown(data.payload[UNIQUE_X_AXIS_IDENTIFIER])
              }
              onClick={() => {
                if (!isDraggingRef.current) {
                  setShowValueFor(legend.label);
                }
              }}
            >
              {shouldShowValueLabels &&
              (showValueOn !== "click" || showValueFor === legend.label) ? (
                <LabelList
                  dataKey={legend.label}
                  angle={valueLabelAngle}
                  formatter={tooltipFormatter("BAR_CHART", probableDataType)}
                  fill=""
                  style={{
                    // 0deg angle need not have textAnchor
                    textAnchor: getValueLabelTextAnchor(valueLabelAngle)
                  }}
                />
              ) : null}
              {orderedDataWithUniqueXAxis.map((item, itemIndex) => (
                <Cell
                  key={`cell-${itemIndex}`}
                  cursor={getCellCursor()}
                  style={getCellColor(item[UNIQUE_X_AXIS_IDENTIFIER] as string)}
                />
              ))}
            </Bar>
          ))}
        </ReBarChart>
      </ResponsiveContainer>
    </CustomLegendWrapper>
  );
};
