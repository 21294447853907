import type {
  AttributesPayloadED,
  ColumnED,
  FilterConditionPayloadED,
  FilterED,
  FilterPayloadED,
  GroupByED,
  GroupByPayloadED,
  OperationArgumentPayloadED,
  OperationED,
  OperationPayloadED,
  OrderByED,
  OrderByPayloadED,
  OtherChartConfigurationsED,
  OtherChartConfigurationsPayloadED
} from "@certa/types";

type Params = {
  attributes?: ColumnED[];
  groupBy: GroupByED[];
  operations: OperationED[];
  orderBy?: OrderByED[];
  otherConfigurations?: OtherChartConfigurationsED;
};

type ParamsReportED = {
  attributes?: ColumnED[];
  groupBy?: GroupByED[];
  operations?: OperationED[];
  orderBy?: OrderByED[];
  filters?: FilterED;
};

type PayloadResult = {
  attributes?: AttributesPayloadED[];
  operations?: OperationPayloadED[];
  group_by?: GroupByPayloadED[];
  order_by?: OrderByPayloadED[];
  filters?: FilterPayloadED;
};

export const chartConfigPayloadCreatorED = (data: Params) => {
  return {
    attributes: attributesPayloadCreatorED(data.attributes),
    operations: operationPayloadCreatorED(data.operations),
    group_by: groupByPayloadCreatorED(data.groupBy),
    order_by: orderByPayloadCreatorED(data.orderBy),
    other_configurations: otherConfigurationsPayloadCreatorED(
      data.otherConfigurations
    )
  };
};

export const payloadCreatorED = (data: ParamsReportED) => {
  const result: PayloadResult = {};
  result.attributes = attributesPayloadCreatorED(data.attributes);
  if (data.operations) {
    result.operations = operationPayloadCreatorED(data.operations);
  }
  if (data.groupBy) {
    result.group_by = groupByPayloadCreatorED(data.groupBy);
  }
  if (data.orderBy) {
    result.order_by = orderByPayloadCreatorED(data.orderBy);
  }
  if (data.filters) {
    result.filters = filterPayloadCreatorED(data.filters);
  }
  return result;
};

const attributesPayloadCreatorED = (
  columns?: ColumnED[]
): AttributesPayloadED[] | undefined => {
  if (!columns) {
    return undefined;
  }
  return columns.map(attribute => ({
    type: attribute.type,
    value: attribute.value,
    label: attribute.label,
    extra_json: {
      field_type: attribute.extraJSON.fieldType,
      is_hidden: attribute.extraJSON.isHidden,
      added_by: attribute.extraJSON.addedBy
    }
  }));
};

const groupByPayloadCreatorED = (groupBys: GroupByED[]): GroupByPayloadED[] => {
  return groupBys.map(groupBy => ({
    type: groupBy.type,
    source: groupBy.source,
    operations: groupBy.operations
      ? operationPayloadCreatorED(groupBy.operations)
      : undefined,
    extra_json: {
      label: groupBy.extraJSON.label,
      field_type: groupBy.extraJSON.fieldType,
      data_type: groupBy.extraJSON.dataType,
      label_output_type: groupBy.extraJSON.labelOutputType,
      is_for_secondary_x_axis: groupBy.extraJSON.isForSecondaryXAxis,
      operation_type: groupBy.extraJSON.operationType,
      data_sources: groupBy.extraJSON.dataSources
    }
  }));
};

const orderByPayloadCreatorED = (
  orderBys?: OrderByED[]
): OrderByPayloadED[] | undefined => {
  if (!orderBys) {
    return undefined;
  }
  return orderBys.map(orderBy => ({
    type: orderBy.type,
    ordering: orderBy.ordering,
    source: orderBy.source,
    operations: orderBy.operations
      ? operationPayloadCreatorED(orderBy.operations)
      : undefined
  }));
};

export const otherConfigurationsPayloadCreatorED = (
  config?: OtherChartConfigurationsED
): OtherChartConfigurationsPayloadED | undefined => {
  if (!config) {
    return undefined;
  }
  return {
    colors: {
      active_color_type: config.colors.activeColorType,
      single_tone: config.colors.singleTone,
      spectrum: config.colors.spectrum,
      custom: config.colors.custom
    },
    show_legend: config.showLegend,
    chart_orientation: config.chartOrientation,
    show_value_labels: config.shouldShowValueLabels,
    show_value_on: config.showValueOn,
    show_x_axis_label: config.showXAxisLabel,
    show_y_axis_label: config.showYAxisLabel,
    y_axis_label: config.yAxisLabel,
    x_axis_label: config.xAxisLabel,
    value_label_angle: config.valueLabelAngle,
    is_stacked_bar_chart: config.isStackedBarChart,
    is_grouped_bar_chart: config.isGroupedBarChart,
    percentage_threshold: config.percentageThreshold,
    show_percentages_in_value_labels: config.shouldShowPercentagesInValueLabels,
    precision: config.precision,
    scale: config.scale,
    geo_map_theme: config.geoMapTheme,
    data_order: config.dataOrder
  };
};

const operationPayloadCreatorED = (
  operations: OperationED[]
): OperationPayloadED[] => {
  return operations.map(operation => ({
    type: operation.type,
    label: operation.label,
    data_type: operation.dataType,
    function: operation.function,
    arguments: operationArgumentPayloadCreatorED(operation.arguments),
    extra_args: {
      separator: operation.extraArgs.separator,
      compare_value: operation.extraArgs.compareValue
    },
    extra_json: {
      output_data_type: operation.extraJSON.outputDataType,
      axis_name: operation.extraJSON.axisName,
      field_type: operation.extraJSON.fieldType,
      tag: operation.extraJSON.tag
    }
  }));
};

const operationArgumentPayloadCreatorED = (
  argumentsData: OperationED["arguments"]
) => {
  return argumentsData.map(argument => {
    if (typeof argument === "string") {
      return argument;
    }
    const newArgument: OperationArgumentPayloadED = {
      label: argument.label,
      data_type: argument.dataType,
      function: argument.function,
      arguments: operationArgumentPayloadCreatorED(argument.arguments),
      extra_args: {
        separator: argument.extraArgs.separator,
        compare_value: argument.extraArgs.compareValue
      }
    };
    return newArgument;
  });
};

export const filterPayloadCreatorED = (filter: FilterED) => {
  const conditions: (FilterPayloadED | FilterConditionPayloadED)[] =
    filter.conditions.map(condition => {
      if ("type" in condition) {
        return filterPayloadCreatorED(condition);
      }
      return {
        lhs_type: condition.lhsType,
        data_type: condition.dataType,
        lhs_source: condition.lhsSource,
        op: condition.operation,
        rhs_value: condition.rhsValue,
        extra_json: {
          field_type: condition.extraJSON.fieldType,
          field_value: condition.extraJSON.fieldValue
        }
      };
    });
  return {
    type: filter.type,
    conditions
  };
};
