import type { KeyboardEvent } from "react";
import { useCallback, useRef } from "react";
import { throttle } from "../utils/common";
import { useCallbackRef } from "./useCallbackRef";

export type ListInteractionMode = "mouse" | "keyboard";

type UseListInteractionProps = {
  totalItemsCount: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  interactionMode: ListInteractionMode;
  setInteractionMode: (mode: ListInteractionMode) => void;
  getOptionId: (index: number) => string;
  onSelect?: (index: number, interactionMode: ListInteractionMode) => void;
};

const MOUSE_DEBOUNCE_MS = 150;

export function useListInteraction({
  totalItemsCount,
  activeIndex,
  setActiveIndex,
  interactionMode,
  setInteractionMode,
  getOptionId,
  onSelect
}: UseListInteractionProps) {
  const getOptionIdCallbackRef = useCallbackRef(getOptionId);
  const activeIndexRef = useRef(activeIndex);
  activeIndexRef.current = activeIndex;

  const handleFocus = (index: number, mode: ListInteractionMode) => {
    if (index >= totalItemsCount) return;
    setActiveIndex(index);
    setInteractionMode(mode);

    // Focus the DOM element when using keyboard
    if (mode === "keyboard") {
      const element = document.getElementById(getOptionIdCallbackRef(index));
      if (element) {
        element.focus();
      }
    }
  };

  const handleItemMouseEnter = (index: number) => {
    // Only handle mouse events when not in keyboard navigation mode
    if (interactionMode === "mouse") {
      setActiveIndex(index);
    }
  };

  const handleSelect = (
    index: number,
    interactionMode: ListInteractionMode
  ) => {
    if (index >= totalItemsCount) return;
    setInteractionMode(interactionMode);
    setActiveIndex(index);
    onSelect?.(index, interactionMode);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!totalItemsCount) return;

    const actions: Record<string, () => void> = {
      ArrowDown: () => {
        event.preventDefault();
        handleFocus(Math.min(activeIndex + 1, totalItemsCount - 1), "keyboard");
      },
      ArrowUp: () => {
        event.preventDefault();
        handleFocus(Math.max(activeIndex - 1, 0), "keyboard");
      },
      Home: () => {
        event.preventDefault();
        handleFocus(0, "keyboard");
      },
      End: () => {
        event.preventDefault();
        handleFocus(totalItemsCount - 1, "keyboard");
      },
      Enter: () => {
        event.preventDefault();
        handleSelect(activeIndex, "keyboard");
      },
      " ": () => {
        event.preventDefault();
        handleSelect(activeIndex, "keyboard");
      }
    };

    actions[event.key]?.();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseMove = useCallback(
    throttle(() => {
      if (interactionMode === "keyboard") {
        const element = document.getElementById(
          getOptionIdCallbackRef(activeIndexRef.current)
        );
        if (element) {
          element.blur();
        }
        setInteractionMode("mouse");
      }
    }, MOUSE_DEBOUNCE_MS),
    [interactionMode, setInteractionMode]
  );

  return {
    handleKeyDown,
    handleMouseMove,
    handleItemMouseEnter,
    handleSelect
  };
}
