import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef
} from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { Dimmer } from "@certa/catalyst/components/Dimmer";
import { CATALYST_Z_INDEXES } from "@certa/catalyst/constants/styles";
import { cx } from "emotion";
import {
  catalystDialogStyles,
  catalystDialogContentStyles
} from "./SearchDialog.styles";

type SearchDialogProps = {
  show: boolean;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  children: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  onClose: () => void;
};

export const SearchDialog = (props: SearchDialogProps) => {
  const {
    show: shouldShow = false,
    children,
    width = "400px",
    minWidth,
    maxWidth,
    height = "fit-content",
    maxHeight,
    onClose
  } = props;

  const [shouldShowDialog, setShouldShowDialog] = useState(false);

  const rootElement = document.getElementById("catalyst-dialogs-wrapper");
  const dialogTriggerElementRef = useRef<HTMLElement | null>();

  useLayoutEffect(() => {
    if (rootElement) {
      rootElement.setAttribute("role", "presentation");
      rootElement.style.zIndex = CATALYST_Z_INDEXES.DIALOG;
    }
  }, [rootElement, shouldShow]);

  const returnFocusToTriggeredElement = useCallback(() => {
    setImmediate(() => {
      dialogTriggerElementRef.current?.focus();
      dialogTriggerElementRef.current = null;
    });
  }, []);

  const handleClose = useCallback(() => {
    onClose?.();
    returnFocusToTriggeredElement();
  }, [onClose, returnFocusToTriggeredElement]);

  // This is to focus the trigger element on drawer close.
  // Also to avoid losing the trigger element reference.
  useEffect(() => {
    if (shouldShow) {
      dialogTriggerElementRef.current = document.activeElement as HTMLElement;
    } else {
      returnFocusToTriggeredElement();
    }
    setShouldShowDialog(shouldShow);
  }, [returnFocusToTriggeredElement, shouldShow]);

  useEffect(() => {
    const onEscapeKeyHandler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        handleClose();
      }
    };

    if (shouldShow) {
      window.addEventListener("keydown", onEscapeKeyHandler);
    } else {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    }

    return () => {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    };
  }, [handleClose, shouldShow]);

  return rootElement && shouldShowDialog
    ? createPortal(
        <>
          <Dimmer onClick={handleClose} scrollLock />
          <FocusLock disabled={!shouldShow}>
            <div
              role="dialog"
              aria-modal={true}
              aria-label="Search"
              aria-describedby={"global-search-dialog"}
              className={catalystDialogStyles}
              style={{ height, width, maxHeight, minWidth, maxWidth }}
            >
              <div className={cx(catalystDialogContentStyles)}>{children}</div>
            </div>
          </FocusLock>
        </>,
        rootElement
      )
    : null;
};
