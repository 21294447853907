import type { IntlShape } from "react-intl";

// Recursive function to generate the aria-label for each nested group
export const buildGroupAriaLabel = (
  groupPathIndexes: number[],
  intl: IntlShape
) => {
  return groupPathIndexes
    .map((groupIndex, index) =>
      intl.formatMessage(
        {
          id: "report.groupNumber",
          defaultMessage: "Group {number}"
        },
        { number: groupIndex + 1 }
      )
    )
    .join(" → ");
};

// Recursive function to generate the aria-label for each nested condition
export const buildConditionAriaLabel = (
  groupPathIndexes: number[],
  conditionIndex: number,
  intl: IntlShape
) => {
  const groupLabel = buildGroupAriaLabel(groupPathIndexes, intl);
  // Add the condition number and other details
  return intl.formatMessage(
    {
      id: "report.advancedFilterConditionLabel",
      defaultMessage: "{groupLabel}, Condition {conditionNumber}"
    },
    {
      groupLabel,
      conditionNumber: conditionIndex + 1 // Condition index within the group
    }
  );
};

export const buildRemoveGroupConditionAriaLabel = (
  groupPathIndexes: number[],
  intl: IntlShape
) => {
  return intl.formatMessage(
    {
      id: "report.removeGroupCondition",
      defaultMessage: "Remove {groupLabel} group condition"
    },
    {
      groupLabel: buildGroupAriaLabel(groupPathIndexes, intl)
    }
  );
};
