import type { FC } from "react";
import type { Title } from "../types";
import { css } from "emotion";

type HeadingProps = {
  show: boolean;
  title: Title;
  titleFor: "column" | "row";
};

export const Heading: FC<HeadingProps> = ({ show, title, titleFor }) => {
  if (!show) {
    return null;
  }

  const isPrimitiveTitle = ["string", "number", "undefined"].includes(
    typeof title
  );

  if (!isPrimitiveTitle) {
    return <>{title}</>;
  }

  return <h3 className={getHeadingClassName(titleFor)}>{title}</h3>;
};

const getHeadingClassName = (titleFor: HeadingProps["titleFor"]): string => {
  return `${titleFor}-type-heading ${headingStyles}`;
};

const headingStyles = css({
  textAlign: "center",
  maxHeight: "200px",
  maxWidth: "400px",
  wordBreak: "break-word",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});
