import { useMemo } from "react";
import type {
  ColumnDefinition,
  DataPlusUndefined,
  HeatMapProps
} from "../types";
import { getAxesLabels, getTranslatedData } from "../utils/HeatMapUtils";
import { createDataColumns, columnHelper } from "../utils/dataColumns";
import { Cell } from "../components/Cell";
import { ColumnTitle } from "../components/ColumnTitle";
import {
  SUB_TOTAL_LABEL,
  AVERAGE_LABEL,
  FIRST_COLUMN_WIDTH,
  Y_AXIS_GROUP_LABEL
} from "../constants";
import type { ColumnMeta } from "@tanstack/react-table";
import { calculateRatio } from "../utils/cellUtils";
import { percentageFormatter } from "../../../../utils/chartUtils";

export const useHeatMapConfig = ({
  data,
  xAxisKeys,
  yAxisKeys,
  valueKey,
  dataCellFormatter,
  cellBaseColors,
  activeColumnIdentifier,
  onClick,
  shouldDisplayValueAsPercentage = false,
  percentageCalculationType = "row"
}: HeatMapProps) => {
  const multipleXAxisLabels = useMemo(
    () => getAxesLabels(xAxisKeys, data),
    [data, xAxisKeys]
  );

  const yAxesLabels = useMemo(
    () => getAxesLabels(yAxisKeys, data),
    [data, yAxisKeys]
  );

  const {
    dataSource,
    minValue = 0,
    maxValue = 0,
    columnSubTotalAndAvg
  } = useMemo(
    () =>
      getTranslatedData({
        data,
        xAxisKeys,
        yAxisKeys,
        multipleXAxisLabels,
        valueKey,
        shouldDisplayValueAsPercentage,
        percentageCalculationType
      }),
    [
      data,
      valueKey,
      multipleXAxisLabels,
      xAxisKeys,
      yAxisKeys,
      shouldDisplayValueAsPercentage,
      percentageCalculationType
    ]
  );

  const minMaxDiff = maxValue - minValue === 0 ? 1 : maxValue - minValue;

  const yAxisGroup: ColumnDefinition = columnHelper.group({
    id: Y_AXIS_GROUP_LABEL,
    meta: { draggable: false } as ColumnMeta<DataPlusUndefined, any>,
    header: () => <ColumnTitle title="Y-Axis" width={FIRST_COLUMN_WIDTH} />,
    columns: yAxisKeys.map(key =>
      columnHelper.accessor(key, {
        id: key,
        size: FIRST_COLUMN_WIDTH,
        meta: { rowSpan: xAxisKeys.length - 1, draggable: false } as ColumnMeta<
          DataPlusUndefined,
          any
        >,
        header: () => <ColumnTitle width={FIRST_COLUMN_WIDTH} title={key} />,
        cell: info => info.getValue()
      })
    )
  });

  const statsRowSpan = xAxisKeys.length === 1 ? 2 : xAxisKeys.length;

  const statsGroup: ColumnDefinition[] = [
    columnHelper.accessor(SUB_TOTAL_LABEL, {
      id: SUB_TOTAL_LABEL,
      meta: { rowSpan: statsRowSpan, draggable: false } as ColumnMeta<
        DataPlusUndefined,
        any
      >,
      header: () => <ColumnTitle title={SUB_TOTAL_LABEL} width={100} />,
      cell: info => {
        const value = info.getValue();
        const ratio = calculateRatio(value, minValue, minMaxDiff);

        return (
          <Cell
            cellBaseColors={cellBaseColors}
            dataCellFormatter={dataCellFormatter}
            cellValue={value}
            columnHeaderKey={SUB_TOTAL_LABEL}
            rowRecord={info.row.original}
            rowIndex={info.row.index}
            columnIndex={info.column.getIndex()}
            ratio={ratio}
          />
        );
      }
    }),
    columnHelper.accessor(AVERAGE_LABEL, {
      id: AVERAGE_LABEL,
      meta: { rowSpan: statsRowSpan, draggable: false } as ColumnMeta<
        DataPlusUndefined,
        any
      >,
      header: () => <ColumnTitle title={AVERAGE_LABEL} width={100} />,
      cell: info => {
        const value = info.getValue();
        const ratio = calculateRatio(value, minValue, minMaxDiff);

        return (
          <Cell
            dataCellFormatter={dataCellFormatter}
            cellValue={value}
            columnHeaderKey={AVERAGE_LABEL}
            rowRecord={info.row.original}
            rowIndex={info.row.index}
            columnIndex={info.column.getIndex()}
            ratio={ratio}
          />
        );
      }
    })
  ];

  const dataColumns: ColumnDefinition[] = useMemo(
    () =>
      createDataColumns({
        data,
        xAxisKeys,
        yAxisKeys,
        multipleXAxisLabels,
        dataCellFormatter: shouldDisplayValueAsPercentage
          ? percentageFormatter
          : dataCellFormatter,
        minValue,
        minMaxDiff,
        renderColumnTitle: ColumnTitle,
        renderDataCell: Cell,
        cellBaseColors,
        onClick,
        activeColumnIdentifier
      }),
    [
      data,
      xAxisKeys,
      yAxisKeys,
      dataCellFormatter,
      shouldDisplayValueAsPercentage,
      cellBaseColors,
      minValue,
      minMaxDiff,
      activeColumnIdentifier,
      onClick,
      multipleXAxisLabels
    ]
  );

  const columns = [yAxisGroup, ...dataColumns, ...statsGroup];

  return {
    columns,
    dataSource,
    minValue,
    maxValue,
    minMaxDiff,
    columnSubTotalAndAvg,
    dataColumns,
    yAxesLabels
  };
};
