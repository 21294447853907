import { css } from "emotion";

export const listBoxWrapperCSS = css({
  "&:focus": {
    // Hide outline for entire list box since we are focussing individual items
    outline: "none"
  }
});

export const listItemWrapperCSS = css({
  borderBottom: "1px solid var(--neutral-20)",
  borderRadius: "0.125rem"
});

export const listItemCSS = ({ focussed }: { focussed: boolean }) =>
  css({
    "&:focus-visible": {
      outline: focussed ? "0.125rem solid var(--colors-brand-400)" : "none",
      outlineOffset: "-2px",
      borderRadius: "var(--space-4)"
    },
    margin: "var(--space-8) var(--space-0)"
  });
