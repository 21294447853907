export const stringConstants = {
  comma: ",",
  exclamation: "!",
  emptySpace: " ",
  colon: ":",
  colonWithSpace: ": ",
  question: "?",
  hyphen: "-",
  doubleHyphen: "--",
  percentage: "%",
  divider: "/",
  dividerWithSpaces: " / ",
  plus: "+",
  percent: "%",
  dot: ".",
  doubleDot: "..",
  ellipsis: "...",
  infoIconHTMLEntity: "&#9432;",
  crossIconHTMLEntity: "&#10005;",
  leftDoubleQuote: "&#8220;",
  rightDoubleQuote: "&#8221;"
};
