import { Stack } from "@certa/catalyst/layouts/Stack";
import { Spinner } from "@certa/icons/components/Spinner";
import loadable from "@loadable/component";

export const InstanceSettingsComponent = loadable(
  () =>
    import(
      "@certa/studio/src/components/InstanceSettings/InstanceSettingsContextWrapper"
    ),
  {
    fallback: (
      <Stack width="100%" align="center" justify="center">
        <Spinner size={24} color="brand" label="Loading..." />
      </Stack>
    )
  }
);
