import { css } from "emotion";

export const logoAndBreadcrumbsContainer = css({
  flexGrow: 1,
  overflow: "hidden",
  gap: "var(--s1)"
});

export const nonWorkflowGutter = css({
  paddingLeft: "2.85rem",
  borderTop: "1px solid var(--colors-neutral-100)"
});

export const rightIconContainer = css({
  marginRight: "var(--s1)"
});

export const innerStackStyles = css({
  alignItems: "center"
});
