import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "@certa/catalyst/components/Typography";
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from "@emotion/core";
import { useIntl } from "react-intl";
import { FORMATTED_DATE_VALUES } from "../../comments.constants";

type DateHeaderProps = {
  date: string;
};

export const DateHeader = ({ date }: DateHeaderProps) => {
  const intl = useIntl();

  const getFormattedDate = (date: string) => {
    if (date === FORMATTED_DATE_VALUES.TODAY) {
      return intl.formatMessage({
        id: "comments.todayDate",
        defaultMessage: "Today"
      });
    }
    if (date === FORMATTED_DATE_VALUES.YESTERDAY) {
      return intl.formatMessage({
        id: "comments.yesterdayDate",
        defaultMessage: "Yesterday"
      });
    }
    return date;
  };

  return (
    <div
      css={css`
        padding: var(--space-24) 0;
        top: 0;
        position: sticky;
        background-color: var(--neutral-0);
        text-align: center;
      `}
    >
      <Typography
        variant={TypographyVariants.LABEL_SM}
        color={TypographyColors.NEUTRAL_600}
      >
        {getFormattedDate(date)}
      </Typography>
    </div>
  );
};
