import { useCommsNewEndpoints } from "@certa/common/toggles";
import { APIFetchComms, tenant } from "@certa/network";
import { useQuery } from "react-query";
import { queryClient } from "../../utils/utils";

type ConversationComms = {
  _id: {
    $oid: string;
  };
  krypton_id: number;
  access_groups: number[];
  business_unit: null;
  created_at: {
    $date: string;
  };
  created_by: null;
  krypton_workflow_id: number;
  metadata: {
    is_public: boolean;
  };
  name: string;
  object_id: number;
  object_type: string;
  region: null;
  last_message: {
    _id: {
      $oid: string;
    };
    krypton_id: number;
    attachments: any[];
    body: string;
    conversation_id: {
      $oid: string;
    };
    mentioned_groups: number[];
    mentioned_users: number[];
    posted_at: {
      $date: string;
    };
    posted_by: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
      full_name: string;
      groups: {
        id: number;
        name: string;
      }[];
    };
    read_by?: number[];
  };
  message_count: number;
};

export type LatestMessage = {
  id: string;
  conversationId: string;
  mentionedGroups: number[];
  mentionedUsers: number[];
  postedAt: string;
  body: string;
  postedBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    fullName: string;
    groups: {
      id: number;
      name: string;
    }[];
  };
  readBy?: number[];
};

type ConversationsCommsResponse = {
  results: ConversationComms[];
  total: number;
  count: number;
  next: boolean;
};

export type ConversationMessageCommsModel = {
  id: string;
  kryptonId: number;
  kryptonWorkflowId: number;
  isPublic: boolean;
  objectId: number;
  objectType: string;
  latestMessage: LatestMessage;
  messageCount: number;
};

export type ConversationCommsModel = {
  results: ConversationMessageCommsModel[];
  total: number;
  count: number;
  next: boolean;
};

const getConversationsComms = async (conversationId?: number) => {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "x-tenant": tenant,
      "Content-Type": "application/json"
    },
    credentials: "include"
  };

  return APIFetchComms<ConversationsCommsResponse>(
    `conversation?krypton_workflow_id=${conversationId}`,
    requestOptions
  ).then(conversationModelCreator);
};

const conversationModelCreator = (
  conversations: ConversationsCommsResponse
): ConversationCommsModel => {
  return {
    total: conversations.total,
    count: conversations.count,
    next: conversations.next,
    results: conversations.results.map(conversation => ({
      id: conversation._id.$oid,
      kryptonId: conversation.krypton_id,
      kryptonWorkflowId: conversation.krypton_workflow_id,
      isPublic: conversation.metadata.is_public,
      objectId: conversation.object_id,
      objectType: conversation.object_type,
      latestMessage: {
        id: conversation?.last_message?._id?.$oid,
        conversationId: conversation?.last_message?.conversation_id?.$oid,
        mentionedGroups: conversation?.last_message?.mentioned_groups,
        mentionedUsers: conversation?.last_message?.mentioned_users,
        postedAt: conversation?.last_message?.posted_at?.$date,
        body: conversation?.last_message?.body,
        postedBy: {
          id: conversation?.last_message?.posted_by?.id,
          firstName: conversation?.last_message?.posted_by?.first_name,
          lastName: conversation?.last_message?.posted_by?.last_name,
          email: conversation?.last_message?.posted_by?.email,
          fullName: conversation?.last_message?.posted_by?.full_name,
          groups: conversation?.last_message?.posted_by?.groups
        },
        readBy: conversation?.last_message?.read_by
      },
      messageCount: conversation.message_count
    }))
  };
};

const useGetConversationsComms = (conversationId?: number) => {
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  return useQuery(
    ["useGetConversationsComms", conversationId],
    () => getConversationsComms(conversationId),
    {
      enabled: !!conversationId && isCommsNewEndpointEnabled
    }
  );
};

const invalidateCommsConversation = () => {
  queryClient.invalidateQueries(["useGetConversationsComms"]);
};

export { useGetConversationsComms, invalidateCommsConversation };
