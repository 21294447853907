import { css } from "emotion";

export const mainContentStyles = {
  container: css({
    height: "100%",
    flexGrow: 1,
    padding: "0 var(--space-16) var(--space-16) var(--space-16)",
    borderRadius: "var(--space-8)"
  }),

  loadingLayout: css({
    overflow: "hidden"
  }),

  highlight: css({
    background: "inherit",
    color: "var(--colors-neutral-800)"
  }),

  row: css({
    "&:not(:last-child)": {
      borderBottom: "1px solid var(--colors-neutral-400)"
    }
  }),

  option: css({
    margin: "var(--space-4) 0",
    borderRadius: "var(--space-4)",
    "&:focus-visible": {
      outline: "1px solid var(--colors-neutral-700)"
    }
  })
};
