import { useQuery, type QueryKey, type UseQueryOptions } from "react-query";
import {
  getStatusesList,
  getDropdownKinds,
  getRegionsV2,
  getBUV2,
  getUserGroups,
  getActiveUsers,
  getFileMimeType
} from "../services/common.services";
import type { UseQueryReturnType } from "@certa/common/types";
import type { FilePreviewDataSource } from "@certa/types";

export const useGetStatusesListQuery = () =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    queryKey: "statusesList",
    queryFn: getStatusesList
  });

export const useDropdownKinds = (kindsToExclude: string[] = []) =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    queryKey: ["dropdownKinds", kindsToExclude],
    queryFn: () => getDropdownKinds(kindsToExclude)
  });

export const useGetRegionsV2 = (...params: Parameters<typeof getRegionsV2>) =>
  useQuery({
    retry: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    queryKey: ["regions-list-v2", ...params],
    queryFn: () => getRegionsV2(...params)
  });

export const useGetBUV2 = (...params: Parameters<typeof getBUV2>) => {
  const payload = JSON.parse(JSON.stringify(params || [{}]));
  return useQuery({
    retry: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    queryKey: ["business-unit-list-v2", ...params],
    queryFn: () => getBUV2(payload[0])
  });
};

export const useGetUserGroups = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getUserGroups>>
) =>
  useQuery({
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...config,
    queryKey: "user-group-list" as QueryKey,
    queryFn: getUserGroups
  });

export const useGetActiveUsers = (
  config?: UseQueryOptions<UseQueryReturnType<typeof getActiveUsers>>
) =>
  useQuery({
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...config,
    queryKey: "active-user-list" as QueryKey,
    queryFn: getActiveUsers
  });

export const useGetFileMimeType = (
  url: string,
  filePreviewDataSource?: FilePreviewDataSource,
  config?: UseQueryOptions<UseQueryReturnType<typeof getFileMimeType>>
) =>
  useQuery({
    ...config,
    queryKey: `file-mime-type-${url}` as QueryKey,
    queryFn: () => getFileMimeType(url, filePreviewDataSource)
  });
