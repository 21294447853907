//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Star = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Star"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.7564 9.61889L14.7763 7.00994C15.2033 6.63964 14.9823 5.91349 14.423 5.88274L10.5343 5.63546C10.2983 5.61978 10.0923 5.46538 10.0037 5.23379L8.57433 1.40528C8.36832 0.864905 7.64614 0.864905 7.44014 1.40528L6.01132 5.21866C5.92271 5.45025 5.7167 5.60464 5.48076 5.62033L1.57698 5.86761C1.01765 5.89837 0.796679 6.62389 1.22366 6.9942L4.24357 9.58818C4.42023 9.74257 4.50885 10.0049 4.44957 10.2365L3.4627 14.2043C3.33035 14.7603 3.90463 15.2078 4.37592 14.899L7.66113 12.6911C7.86714 12.5523 8.11804 12.5523 8.30907 12.6911L11.6086 14.899C12.0799 15.2078 12.6547 14.7603 12.5218 14.2043L11.5349 10.2515C11.4912 10.0205 11.5648 9.77322 11.7565 9.61882L11.7564 9.61889Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
