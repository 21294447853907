//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Gauge = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Gauge"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 9.8C16 5.39273 12.4073 1.8 8 1.8C3.59273 1.8 0 5.39273 0 9.8C0 11.4657 0.517043 13.0167 1.39394 14.2999L2.90909 13.1029C2.2945 12.154 1.93939 11.0196 1.93939 9.79988C1.93939 6.44109 4.64121 3.73928 8 3.73928C11.3588 3.73928 14.0606 6.44109 14.0606 9.79988C14.0606 11.0196 13.7055 12.154 13.0909 13.1029L14.6061 14.2999C15.483 13.017 16 11.4657 16 9.8ZM5.43311 6.16556C5.17749 6.3304 5.06405 6.64609 5.15629 6.93593L6.97913 12.6634C7.25273 13.5231 8.22927 13.9354 9.03618 13.5319C9.81784 13.1411 10.0876 12.1585 9.615 11.4233L6.36476 6.36741C6.16339 6.05417 5.74607 5.96375 5.43311 6.16556Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
