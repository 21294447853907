//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const ChevronRight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronRight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.4165 9.46654L7.26855 13.6131C6.99339 13.886 6.61371 14.0256 6.22792 13.9961C5.84166 13.9666 5.48729 13.7711 5.25714 13.4599C4.87184 12.911 4.94261 12.1633 5.42402 11.6965L9.12242 7.99937L5.3879 4.26485C5.11415 3.98969 4.97399 3.60908 5.00399 3.22235C5.03352 2.83563 5.2304 2.48079 5.54259 2.25063C6.09149 1.86532 6.83871 1.9361 7.30551 2.4175L11.4165 6.53218C11.8046 6.92171 12.023 7.44905 12.023 7.99942C12.023 8.54926 11.8046 9.07708 11.4165 9.46666V9.46654Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
