import { css } from "emotion";

export const searchResultItemStyles = {
  container: (isSelected?: boolean) =>
    css({
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "8px 12px",
      gap: "12px",
      backgroundColor: isSelected ? "var(--colors-neutral-200)" : "transparent",
      cursor: "pointer",
      "&.selected .listing-item-icon": {
        color: "var(--colors-neutral-800)"
      },
      "&:focus": {
        outline: "none"
      },
      "&:focus-visible": {
        outline: "none"
      }
    }),
  titleContainer: css({
    flex: 1,
    overflow: "hidden"
  })
};
