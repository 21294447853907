import Godaam from "./storage";
import { disconnectPrasaran } from "@certa/prasaran";
import { basename } from "../../config";
import {
  ToastPlacements,
  ToastTypes,
  showToast
} from "@certa/catalyst/components/Toast";
import { intl } from "../../modules/common/components/IntlCapture";

const removeCookies = () => {
  const expireCookie = key => {
    document.cookie = key + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
  };

  const cookies = document.cookie;
  const splitCookies = cookies.split(";");
  for (let i = 0; i < splitCookies.length; i++) {
    const key = splitCookies[i].split("=");
    expireCookie(key[0]);
  }
};

const postLogoutAction = ({ addNextURL = false, redirectURL = "" }) => {
  const preferredLanguage = Godaam.preferredLanguage;
  const recentlyViewed = Godaam.recentlyViewed;

  // TODO: Should be taken from a constant
  // Not able to import now because of circular dependency
  Godaam.clear(["taskFilters", "recentSearches"]);
  Godaam.preferredLanguage = preferredLanguage;
  Godaam.recentlyViewed = recentlyViewed;
  removeCookies();
  disconnectPrasaran();
  if (redirectURL) {
    // In case of SSO, we get a redirect URL, that needs to be hit to ensure
    // user is logged out from the IdP as well.
    showToast({
      type: ToastTypes.WARNING,
      title: intl.formatMessage({
        id: "notificationInstances.loggedOutSSO",
        defaultMessage: "You're being logged out..."
      }),
      placement: ToastPlacements.BOTTOM_RIGHT,
      toastKey: "logoutSSOToast"
    });
    window.location.href = redirectURL;
  } else {
    showToast({
      type: ToastTypes.WARNING,
      title: intl.formatMessage({
        id: "notificationInstances.loggedOut",
        defaultMessage: "You've been logged out. Redirecting to login page..."
      }),
      placement: ToastPlacements.BOTTOM_RIGHT,
      toastKey: "logoutToast"
    });
    const nextUrl = window.location.search
      ? `/login/?next=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`
      : `/login/?next=${window.location.pathname}`;
    const newURL = addNextURL ? nextUrl : `/login/`;
    setTimeout(() => {
      window.location.href = `${basename}${newURL}`;
    }, 1000);
  }
};

export const userUtilities = {
  postLogoutAction
};
