import React, { useState, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useIntl } from "react-intl";
import type { SelectOnChange } from "@certa/catalyst/components/Select";
import { Select } from "@certa/catalyst/components/Select";
import {
  TypographyColors,
  TypographyVariants,
  Typography
} from "@certa/catalyst/components/Typography";
import {
  showToast,
  ToastTypes,
  ToastPlacements
} from "@certa/catalyst/components/Toast";
import { Search } from "@certa/icons/components/Search";
import { Download } from "@certa/icons/components/Download";
import { css } from "emotion";
import { useNavigate } from "react-router";
import moment from "moment";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import type { ReduxState } from "main/src/modules/common/interfaces";
import { PermissionTypes } from "@certa/types";

import type { Kind } from "@certa/types";
import {
  useGetSearchFileResults,
  useGetSearchResults
} from "@certa/queries/hooks/search.hooks";
import * as Sentry from "@sentry/react";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { useDebouncedValue } from "@certa/common/hooks/useDebounce";
import { useCheckPermission } from "@certa/common/utils/permissions/Chowkidar";
import { getSearchRoute } from "@certa/common/utils/routes";

import {
  FileDownloadTypes,
  fileDownload
} from "@certa/common/utils/fileDownload";
import { useGodaamSyncState } from "@certa/common/hooks/useGodaamSyncState";

const SEARCH_CHAR_LENGTH = 3;

type SearchOption =
  | {
      label: string;
      value?: string;
      type?: DefaultSearchTypes | SearchedTypes;
      options: {
        value: string;
        label: string | React.ReactElement;
        type: DefaultSearchTypes | SearchedTypes;
      }[];
    }
  | {
      label: string;
      value: string;
      type: DefaultSearchTypes | SearchedTypes;
      options?: ({
        value: string;
        label: string | React.ReactElement;
        type: DefaultSearchTypes | SearchedTypes;
      } & Record<string, any>)[];
    };

enum DefaultSearchTypes {
  ADVANCED_SEARCH = "advancedSearch",
  RECENT_SEARCHES = "recentSearches",
  CREATE_NEW = "createNew"
}

enum SearchedTypes {
  WORKFLOW_SEARCH = "workflowSearch",
  WORKFLOW_FILE_SEARCH = "workflowFileSearch"
}

export type SearchDropdownBaseProps = {
  hideSearchModal: () => void;
  createWorkflowParams: {
    kinds: Kind[];
    kindsStatus: string;
    onCreateNewWorkflow: (kindTag: string, name?: string, parent?: any) => void;
  };
};

export const SearchDropdownBase = ({
  hideSearchModal,
  createWorkflowParams: { kinds, kindsStatus, onCreateNewWorkflow }
}: SearchDropdownBaseProps) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const canCreateWorkflows =
    useCheckPermission(PermissionTypes.CAN_ADD_WORKFLOW) ||
    (kindsStatus !== "loading" && kinds.length === 0);

  const authentication = useSelector(
    (state: ReduxState) => state?.authentication
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [recentSearches, setRecentSearches] = useGodaamSyncState<
    Record<string, string[] | undefined> | undefined
  >("recentSearches");

  const userRecentSearches = useMemo(
    () => recentSearches?.[authentication.user.id] || [],
    [authentication.user.id, recentSearches]
  );

  const debouncedSearchKeyword = useDebouncedValue(searchKeyword, 600);

  const isSearchEnabled =
    !!debouncedSearchKeyword &&
    debouncedSearchKeyword.length >= SEARCH_CHAR_LENGTH;

  const { data: workflowResults, isLoading: isSearchResultsLoading } =
    useGetSearchResults(
      {
        page: 1,
        urlParams: `?q=${encodeURIComponent(debouncedSearchKeyword)}`
      },
      {
        enabled: isSearchEnabled
      }
    );

  const { results: workflowSearchResults } = workflowResults
    ? workflowResults
    : { results: [] };

  const { data: workflowFileSearch, isLoading: isFileResultsLoading } =
    useGetSearchFileResults(
      {
        searchText: debouncedSearchKeyword,
        page: 1
      },
      {
        enabled: isSearchEnabled
      }
    );

  const isLoading =
    isSearchResultsLoading || isFileResultsLoading || kindsStatus === "loading";

  const handleWorkflowFileDownload = (url: string, fileName: string) => {
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        fileDownload({
          downloadType: FileDownloadTypes.BLOB_DOWNLOAD,
          url,
          blob,
          fileName
        });
      })
      .catch(error => {
        showToast({
          type: ToastTypes.ERROR,
          title: intl.formatMessage({
            id: "errorMessageInstances.somethingWentWrong"
          }),
          description: intl.formatMessage({
            id: "errorMessageInstances.downloadFailed"
          }),
          placement: ToastPlacements.BOTTOM_LEFT
        });
        Sentry.captureException(error);
      });
  };

  /*
    This function takes care of navigating to the advanced search page 
    with and without query. If navigated to the advanced search page with 
    the query then the searched text should be stored in the local storage.
    Upto 6 recent searches (FIFO) will be stored in the local storage based on user session
  */
  const navigateToAdvancedSearch = useCallback(
    (keyword: string) => {
      if (keyword) {
        setRecentSearches(prevState => {
          const userRecentSearches = prevState?.[authentication.user.id];
          const userRecentSearchKeywordIndex = userRecentSearches?.findIndex(
            userRecentSearch => userRecentSearch === keyword
          );
          if (userRecentSearches && userRecentSearches.length === 6) {
            userRecentSearches.splice(5, 1);
          }
          if (
            userRecentSearchKeywordIndex !== undefined &&
            userRecentSearchKeywordIndex !== -1
          ) {
            userRecentSearches?.splice(userRecentSearchKeywordIndex, 1);
          }
          if (prevState && userRecentSearches) {
            return {
              ...prevState,
              [authentication.user.id]: [keyword, ...userRecentSearches]
            };
          } else {
            return { ...prevState, [authentication.user.id]: [keyword] };
          }
        });
        navigate(`${getSearchRoute()}?q=${encodeURIComponent(keyword)}`);
      } else {
        navigate(getSearchRoute());
      }
    },
    [authentication.user.id, navigate, setRecentSearches]
  );

  const onNavigateToAdvancedSearch = (keyword: string) => {
    MixPanelActions.track(
      MixPanelEvents.searchPageEvents.GLOBAL_SEARCH_CLICK_ADVANCE_SEARCH,
      {
        value: keyword
      }
    );
    navigateToAdvancedSearch(keyword);
  };

  const navigateToWorkflow = (workflowId: string | number) => {
    navigate(`/process/${workflowId}`);
  };

  const getSearchOptions = () => {
    const searchOptions: SearchOption[] = [
      {
        label: intl.formatMessage({
          id: "homepage.searchbar.goToAdvancedSearch",
          defaultMessage: "Go to Advanced Search"
        }),
        value: searchKeyword,
        type: DefaultSearchTypes.ADVANCED_SEARCH
      }
    ];

    if (searchKeyword.length < SEARCH_CHAR_LENGTH) {
      searchOptions.push({
        label: intl.formatMessage({
          id: "homepage.searchbar.recentSearches",
          defaultMessage: "Recent searches"
        }),
        options: userRecentSearches?.map((recentSearch, index) => ({
          value: recentSearch,
          label: recentSearch,
          type: DefaultSearchTypes.RECENT_SEARCHES
        }))
      });
    }

    if (canCreateWorkflows) {
      searchOptions.push({
        label: intl.formatMessage({
          id: "homepage.searchbar.createNewWF",
          defaultMessage: "Create new"
        }),
        options: kinds.map((kind, index) => ({
          value: kind.tag,
          label: kind.name,
          type: DefaultSearchTypes.CREATE_NEW
        }))
      });
    }

    if (searchKeyword.length >= SEARCH_CHAR_LENGTH) {
      searchOptions[1] = {
        label: intl.formatMessage({
          id: "homepage.searchbar.processes",
          defaultMessage: "Processes"
        }),
        options: workflowSearchResults?.map((workflow: any) => ({
          value: workflow.id.toString(),
          label: (
            <Highlighter
              searchWords={[searchKeyword]}
              textToHighlight={workflow.name}
              highlightClassName={css`
                padding: 0;
                background: var(--colors-orange-300);
              `}
              autoEscape={true}
            />
          ),
          type: SearchedTypes.WORKFLOW_SEARCH
        }))
      };

      searchOptions.push({
        label: intl.formatMessage({
          id: "homepage.searchbar.files",
          defaultMessage: "Files"
        }),
        options:
          workflowFileSearch?.results?.map((file: any) => ({
            value: file.id.toString(),
            label: (
              <div
                className={css`
                  display: flex;
                  flex-direction: column;
                  row-gap: 8px;
                `}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      width: 12px !important;
                      height: 12px !important;
                    `}
                  >
                    <Download size={12} />
                  </div>
                  <Highlighter
                    searchWords={[searchKeyword]}
                    textToHighlight={file.attachment.name}
                    highlightClassName={css`
                      padding: 0;
                      background: var(--colors-orange-300);
                    `}
                    autoEscape={true}
                  />
                </div>
                <Typography
                  variant={TypographyVariants.LABEL_SM}
                  color={TypographyColors.NEUTRAL_700}
                >
                  {moment(file.submittedAt).format("DD MMM, YYYY")}
                </Typography>
              </div>
            ),
            name: file.attachment.name,
            url: file.attachment.url,
            type: SearchedTypes.WORKFLOW_FILE_SEARCH
          })) || []
      });
    }

    return searchOptions;
  };

  const handleOnChange: SelectOnChange = (value, action, originalOption) => {
    MixPanelActions.track(MixPanelEvents.homepageEvents.HOME_CLICK_SEARCH, {
      value: originalOption?.value
    });
    switch (originalOption?.type) {
      case DefaultSearchTypes.ADVANCED_SEARCH:
        onNavigateToAdvancedSearch(originalOption.value);
        break;
      case DefaultSearchTypes.RECENT_SEARCHES:
        onNavigateToAdvancedSearch(originalOption.value);
        break;
      case DefaultSearchTypes.CREATE_NEW:
        onCreateNewWorkflow(originalOption.value);
        break;
      case SearchedTypes.WORKFLOW_SEARCH:
        navigateToWorkflow(originalOption.value);
        break;
      case SearchedTypes.WORKFLOW_FILE_SEARCH:
        handleWorkflowFileDownload(originalOption.url, originalOption.name);
        break;
    }

    hideSearchModal();
  };

  const onSearch = useCallback(
    (searchText: string) => {
      // To Cancel any previous searches and to avoid showing the previous search results
      queryClient.cancelQueries("search-results");
      queryClient.cancelQueries("attachment-search");
      setSearchKeyword(searchText);
    },
    [queryClient]
  );

  return (
    <Select
      onSearch={onSearch}
      width="100%"
      minMenuWidth={320}
      options={getSearchOptions()}
      placeholder={intl.formatMessage({
        id: "homepage.searchbar.typeToSearch",
        defaultMessage: "Search..."
      })}
      // value is set to null to avoid the select component to show the selected value
      // This is because the select component is used as a autocomplete component
      value={null}
      onChange={handleOnChange}
      label="Search"
      isLoading={isLoading}
      maxMenuHeight={440}
      leftIcon={useCallback(
        () => (
          <Search color="neutral-70" />
        ),
        []
      )}
      openMenuOnFocus={true}
      menuShouldBlockScroll={false}
      menuIsOpen={true}
    />
  );
};
