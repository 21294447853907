import { useState, useEffect, useRef } from "react";
import type { SelectOnChange } from "@certa/catalyst/components/Select";
import {
  GlobalSelect,
  type MenuFooterProps,
  type MenuHeaderProps
} from "./GlobalSelect/GlobalSelect";
import { Search } from "@certa/icons/components/Search";
import { useIntl } from "react-intl";
import { QUERY_KEY_PREFIX } from "@certa/queries/hooks/globalSearch.hook";
import { MenuHeader } from "./MenuHeader/MenuHeader";
import { MenuFooter } from "./MenuFooter/MenuFooter";
import { useNavigate } from "react-router";
import { useRecentSearches } from "./useRecentSearches";
import {
  DEBOUNCE_TIME,
  SearchResultType,
  MIN_CHAR_LENGTH_TO_SEARCH,
  CATEGORIES
} from "./constants";
import { useQueryClient } from "react-query";
import type { ModelledAPIResponse } from "@certa/queries/types/globalSearch.types";
import { useOptions } from "./useOptions";
import { searchIconWrapper } from "./GlobalSearch.styles";
import { MixPanelActions, MixPanelEvents } from "main/src/js/_helpers/mixpanel";
import { Stack } from "@certa/blocks";
import { useDebouncedValue } from "@certa/common/hooks/useDebounce";
import {
  getProcessDetailRoute,
  getSearchRoute,
  getTaskRoute
} from "@certa/common/utils/routes";

type GlobalSearchProps = {
  hideSearchModal: () => void;
};

export const GlobalSearch = ({ hideSearchModal }: GlobalSearchProps) => {
  const [_query, setQuery] = useState("");
  const query = useDebouncedValue(_query, DEBOUNCE_TIME);

  // Count number of queries before user clicks on an option
  // using ref to prevent rerenders
  const queriesBeforeClick = useRef(0);
  const categoriesBeforeClick = useRef(0);
  const didUserScroll = useRef(false);
  const [selectedCategories, setSelectedCategories] = useState(["workflows"]);

  const intl = useIntl();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { recentSearches, setRecentSearches } = useRecentSearches();
  const { options, count, isLoading } = useOptions(
    query,
    selectedCategories,
    recentSearches
  );

  useEffect(() => {
    if (query.length > 0) {
      queriesBeforeClick.current = queriesBeforeClick.current + 1;
      // Track search keywords. We don't wait for request to succeed because
      // request once sent is executed on backend, irrespective of it being
      // cancelled on frontend
      MixPanelActions.track(MixPanelEvents.homepageEvents.HOME_SEARCH_KEYWORD, {
        query
      });
    }
  }, [query]);

  const handleOnScroll = () => {
    didUserScroll.current = true;
  };

  const handleSearch = (query: string) => {
    queryClient.cancelQueries(QUERY_KEY_PREFIX);
    setQuery(query);
  };

  const handleCategoryClick = (value: string) => {
    categoriesBeforeClick.current = categoriesBeforeClick.current + 1;
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter(item => item !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
    MixPanelActions.track(
      MixPanelEvents.homepageEvents.HOME_SEARCH_CATEGORY_CLICK,
      {
        value,
        selectedCategories
      }
    );
  };

  const handleSearchDialogClose = () => {
    hideSearchModal();
  };

  const handleOptionSelect: SelectOnChange = (_, unused, option) => {
    switch (option?.type) {
      case SearchResultType.RECENT_SEARCH:
        setRecentSearches([option.value]);
        navigate(`${getSearchRoute()}?q=${encodeURIComponent(option.value)}`);
        MixPanelActions.track(
          MixPanelEvents.homepageEvents.HOME_CLICK_RECENT_SEARCHES,
          {
            query: option.value
          }
        );
        break;
      case SearchResultType.WORKFLOW_RESULT:
        navigate(getProcessDetailRoute(option.value));
        MixPanelActions.track(
          MixPanelEvents.homepageEvents.HOME_CLICK_SEARCH_RESULTS,
          {
            query,
            value: option?.value,
            type: "workflow",
            rank: option?.index,
            queriesBeforeClick: queriesBeforeClick.current,
            categoriesBeforeClick: categoriesBeforeClick.current,
            didUserScroll: didUserScroll.current
          }
        );
        setRecentSearches([query]);
        break;
      case SearchResultType.FIELD_RESPONSE_RESULT:
      case SearchResultType.LC:
        const searchResult:
          | ModelledAPIResponse.FieldResponseSearchResult
          | undefined = option?.searchResult;
        if (searchResult) {
          // TODO: Navigate to exact field in next sprint
          navigate(
            getTaskRoute({
              processId: searchResult.workflow.id,
              taskId: searchResult.step.id
            })
          );
        }

        MixPanelActions.track(
          MixPanelEvents.homepageEvents.HOME_CLICK_SEARCH_RESULTS,
          {
            query,
            value: option?.value,
            type: "field",
            rank: option?.index,
            queriesBeforeClick: queriesBeforeClick.current,
            categoriesBeforeClick: categoriesBeforeClick.current,
            didUserScroll: didUserScroll.current
          }
        );
        setRecentSearches([query]);
        break;
      case SearchResultType.FILE_RESULT:
        const fileSearchResult:
          | ModelledAPIResponse.FileSearchResult
          | undefined = option?.searchResult;

        if (fileSearchResult) {
          // TODO: Navigate to exact field in next sprint
          navigate(
            getTaskRoute({
              processId: fileSearchResult.workflow.id,
              taskId: fileSearchResult.step.id
            })
          );
        }

        MixPanelActions.track(
          MixPanelEvents.homepageEvents.HOME_CLICK_SEARCH_RESULTS,
          {
            query,
            value: option?.value,
            type: "file",
            rank: option?.index,
            queriesBeforeClick: queriesBeforeClick.current,
            categoriesBeforeClick: categoriesBeforeClick.current,
            didUserScroll: didUserScroll.current
          }
        );
        setRecentSearches([query]);
        break;
      default:
        break;
    }

    // Reset values on successful option click
    queriesBeforeClick.current = 0;
    categoriesBeforeClick.current = 0;
    didUserScroll.current = false;
    handleSearchDialogClose();
  };

  const handleReturnKey = () => {
    if (_query.length > 0) {
      // Dont save empty string in recent searches
      setRecentSearches([_query]);
    }
    MixPanelActions.track(
      MixPanelEvents.homepageEvents.HOME_SEARCH_RETURN_KEY,
      {
        _query
      }
    );
    navigate(`${getSearchRoute()}?q=${encodeURIComponent(_query)}`);

    // Reset values on navigate to advanced search
    queriesBeforeClick.current = 0;
    categoriesBeforeClick.current = 0;
    didUserScroll.current = false;
    handleSearchDialogClose();
  };

  const handleFooterClick = () => {
    MixPanelActions.track(
      MixPanelEvents.searchPageEvents.GLOBAL_SEARCH_CLICK_ADVANCE_SEARCH
    );
    if (!!query && query.length > 0) {
      setRecentSearches([query]);
    }
    navigate(`${getSearchRoute()}?q=${encodeURIComponent(query)}`);
    handleSearchDialogClose();
  };

  const placeholder = intl.formatMessage({
    id: "globalSearch.placeholderNew",
    defaultMessage: "Search for workflows, documents, and more..."
  });

  const showHeader = query && query.length > MIN_CHAR_LENGTH_TO_SEARCH;

  // This should be empty string. If its null, footer is not rendered
  // when query is empty and there are no recent searches
  let noOptionsMessage = "";

  if (query) {
    if (selectedCategories.length === 0) {
      noOptionsMessage = intl.formatMessage({
        id: "globalSearch.messages.noCategoriesSelected",
        defaultMessage: "Please select at least one search category"
      });
    } else {
      noOptionsMessage = intl.formatMessage({
        id: "globalSearch.messages.noOptionsMessage",
        defaultMessage: "No records found"
      });
    }
  }

  return (
    <Stack style={{ width: "100%" }}>
      <GlobalSelect
        id="global-search"
        onSearch={handleSearch}
        width="100%"
        minMenuWidth={320}
        options={options}
        // value is set to null to avoid the select component to show the selected value
        // This is because the select component is used as a autocomplete component
        value={null}
        onChange={handleOptionSelect}
        label={placeholder}
        placeholder={placeholder}
        isLoading={isLoading}
        maxMenuHeight={440}
        leftIcon={SearchIcon}
        noOptionsMessage={noOptionsMessage}
        menuHeader={(props: MenuHeaderProps) =>
          showHeader ? (
            <MenuHeader
              {...props}
              categories={CATEGORIES}
              numberOfResults={count}
              loading={isLoading}
              handleCategoryClick={handleCategoryClick}
              selectedCategories={selectedCategories}
            />
          ) : null
        }
        menuFooter={(props: MenuFooterProps) => (
          <MenuFooter {...props} onFooterClick={handleFooterClick} />
        )}
        handleReturnKey={handleReturnKey}
        handleOnScroll={handleOnScroll}
        defaultInputValue={query}
        headerVisible={!!showHeader}
      />
    </Stack>
  );
};

const SearchIcon = () => (
  <div className={searchIconWrapper}>
    <Search color="neutral-50" />
  </div>
);
