import React, { useMemo, useState, useRef } from "react";
import type { UserMention } from "@certa/queries/types/mentions.types";
import {
  useUnreadMentionsCountComms,
  useGetMentionsListCommsInfiniteQuery
} from "@certa/queries/queries/comms";
import type { UserMentionComms } from "@certa/queries/queries/comms";
import {
  useGetMentionsList,
  useMarkMentionRead,
  useGetUnReadMentionCount
} from "@certa/queries/hooks/mentions.hooks";
import { MentionItem } from "./MentionsItem";
import { Stack } from "@certa/catalyst/layouts/Stack";
import { Spinner3 } from "@certa/icons/components/Spinner3";
import { useNavigate, useLocation } from "react-router";
import { useProcessDetails } from "@certa/processdetails/src/taskDetail/hooks/useProcessDetails.hook";
import { useMentionsContext } from "../../MentionsContext";
import type { CommentData, CommentDataComms } from "../../MentionsContext";
import { useIntl } from "react-intl";
import {
  MixPanelActions,
  MixPanelEvents
} from "../../../../../main/src/js/_helpers/mixpanel";
import {
  getProcessDetailRoute,
  getDashboardsRoute,
  getFullScreenReportRoute
} from "@certa/common/utils/routes";
import { SomethingWentWrong } from "@certa/common/components/SomethingWentWrong";
import { LoadingAndEmptyStatesWrapper } from "@certa/common/components/LoadingAndEmptyStatesWrapper";
import { ListWrapper } from "@certa/common/components/ListWrapper";
import { MentionItemComms, type MentionItemHandle } from "./MentionsItemComms";
import { useCommsNewEndpoints } from "@certa/common/toggles";
import { useObjectDetailsMap } from "../../hooks/useObjectDetailsMap";

type MentionRefsMap = Record<string, React.RefObject<MentionItemHandle>>;

type MentionsContainerProps = {
  showUnreadOnly: boolean;
};

const getKey = (mention: UserMention) => mention.mentionId?.toString();
const getLabel = (mention: UserMention) => mention.message;

// TODO: [PLAT-20792] Remove this once the migration comms are in place
const renderMentionItem = (mention: UserMention) => (
  <MentionItem mention={mention} />
);

const getKeyComms = (mention: UserMentionComms) =>
  mention.kryptonId?.toString();
const getLabelComms = (mention: UserMentionComms) => mention.body;

const MentionsContainer = ({ showUnreadOnly }: MentionsContainerProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const isCommsNewEndpointEnabled = useCommsNewEndpoints();
  const { updateMode, updateCommentData } = useMentionsContext();

  const [processId, setProcessId] = useState<number | null>(null);

  const mentionRefsMap = useRef<MentionRefsMap>({});

  const { status, data, isFetching, hasNextPage, fetchNextPage } =
    useGetMentionsList({
      enabled: !isCommsNewEndpointEnabled
    });
  const { data: processDetails, status: processDetailsStatus } =
    useProcessDetails(processId);

  const { refetch: unreadMentionsCountRefetch } = useGetUnReadMentionCount();
  const { refetch: unreadMentionsCountCommsRefetch } =
    useUnreadMentionsCountComms();

  const { mutate: markAsRead } = useMarkMentionRead({
    onSuccess: () =>
      isCommsNewEndpointEnabled
        ? unreadMentionsCountCommsRefetch()
        : unreadMentionsCountRefetch()
  });

  const {
    data: mentionsListDataComms,
    isFetching: isFetchingComms,
    hasNextPage: hasNextPageComms,
    fetchNextPage: fetchNextPageComms
  } = useGetMentionsListCommsInfiniteQuery();

  const mentions = data?.pages.flatMap(page => page.results);

  const mentionsComms = useMemo(() => {
    return mentionsListDataComms?.pages.flatMap(page => page.results);
  }, [mentionsListDataComms?.pages]);

  const { isItemLoading, getObjectDetailsForItem } =
    useObjectDetailsMap(mentionsComms);

  const listItems = showUnreadOnly
    ? mentions?.filter(mention => !mention.isRead)
    : mentions;

  const listItemsComms = showUnreadOnly
    ? mentionsComms?.filter(mention => !mention.isRead)
    : mentionsComms;

  const processName = processDetails?.definition.name;

  const isDashboardMention = processName === "report_dashboard";
  const isReportMention = processName === "report_workflow";

  const allSetMessage = intl.formatMessage({
    id: "notifications.allSetMessage",
    defaultMessage: "You’re all set!"
  });
  const noUnreadMentionsMessage = intl.formatMessage({
    id: "notifications.noUnreadMessage",
    defaultMessage: "You have no unread notifications"
  });
  const noNewMentionsMessge = intl.formatMessage({
    id: "notifications.noNewMentionsMessage",
    defaultMessage: "You have no new notifications"
  });
  const noNotificationsMessage = showUnreadOnly
    ? noUnreadMentionsMessage
    : noNewMentionsMessge;
  const loadingNotificationsMessage = intl.formatMessage({
    id: "notifications.loadingNotificationsMessage",
    defaultMessage: "Loading notifications, please wait..."
  });

  if (status === "error") return <SomethingWentWrong />;

  const handleOnMentionClickComms = async (mentionItem: UserMentionComms) => {
    const {
      conversation: { objectId, objectType, kryptonWorkflowId },
      isRead,
      kryptonId,
      conversationId,
      id: uid
    } = mentionItem;

    const mentionId = kryptonId?.toString();
    const mentionRef = mentionId ? mentionRefsMap.current[mentionId] : null;
    const mentionsRefData = mentionRef?.current?.getDetails();

    setProcessId(kryptonWorkflowId);

    !isRead && markAsRead(kryptonId);

    if (!mentionsRefData || mentionsRefData.processDetailsStatus === "loading")
      return;

    const processName = mentionsRefData.processDetails?.definition?.name;

    const isDashboardsMention = processName === "report_dashboard";
    const isReportsMention = processName === "report_workflow";

    const commentData: CommentDataComms = {
      objectId,
      objectType,
      conversationId: conversationId?.$oid,
      uid: uid,
      processId: kryptonWorkflowId,
      processName: processName,
      workflowFamily:
        isDashboardsMention || isReportsMention
          ? mentionsRefData.processDetails?.workflowFamily
          : []
    };

    const [, type, id] = location?.pathname?.split("/") || [];

    // If the user is already on the same page as that of the mention
    // then we don't need to redirect the user
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        kryptonWorkflowId &&
        kryptonWorkflowId === Number(id)
      )
    ) {
      if (isDashboardsMention) {
        navigate(
          `${getDashboardsRoute(kryptonWorkflowId)}?redirectFromComments=true`
        );
        return;
      }

      if (isReportsMention) {
        navigate(
          `${getFullScreenReportRoute(
            kryptonWorkflowId
          )}?redirectFromComments=true`
        );
        return;
      }

      const redirectCommentData = encodeURIComponent(
        JSON.stringify(commentData)
      );

      const isObjectDetailsLoading = isItemLoading(mentionItem);
      const objectDetails = getObjectDetailsForItem(mentionItem);

      if (!isObjectDetailsLoading && objectDetails) {
        navigate(
          `${getProcessDetailRoute(
            kryptonWorkflowId
          )}?group=${objectDetails.stepGroupId}&step=${objectDetails.stepId}&redirectFromComments=true&redirectCommentData=${redirectCommentData}`
        );
      } else {
        navigate(
          `${getProcessDetailRoute(
            kryptonWorkflowId
          )}?redirectFromComments=true&redirectCommentData=${redirectCommentData}`
        );
      }
    }

    // changing drawer mode to comments drawer
    updateMode("comments");
    // setting comment data for drawer
    updateCommentData(commentData);
  };

  // TODO: [PLAT-20792] Remove this once the migration comms are in place
  const handleOnMentionClick = (mentionItem: UserMention) => {
    MixPanelActions.track(
      MixPanelEvents.notificationEvents
        .NOTIFICATIONS_LEFTPANE_ALL_NOTIFICATIONS_CLICK_OPEN_COMMENT_NOTIFICATION
    );
    const { mentionId, process, isRead, mentionData } = mentionItem;
    setProcessId(process?.processId);

    !isRead && markAsRead(mentionId);

    if (processDetailsStatus === "loading") return;

    const {
      object_id: objectId,
      type: objectType,
      thread_id: threadId,
      uid
    } = mentionData;

    const commentData: CommentData = {
      objectId,
      objectType,
      threadId,
      uid,
      processId: process.processId,
      processName: process.processName,
      workflowFamily:
        isDashboardMention || isReportMention
          ? processDetails?.workflowFamily
          : []
    };

    const [, type, id] = location?.pathname?.split("/") || [];

    // If the user is already on the same page as that of the mention
    // then we don't need to redirect the user
    if (
      !(
        ["process", "dashboard", "report"].includes(type) &&
        process?.processId &&
        process.processId === Number(id)
      )
    ) {
      if (isDashboardMention) {
        navigate(
          `${getDashboardsRoute(process?.processId)}?redirectFromComments=true`
        );
        return;
      }

      if (isReportMention) {
        navigate(
          `${getFullScreenReportRoute(
            process?.processId
          )}?redirectFromComments=true`
        );
        return;
      }

      const redirectCommentData = encodeURIComponent(
        JSON.stringify(commentData)
      );

      navigate(
        `${getProcessDetailRoute(
          process?.processId
        )}?group=${mentionData?.group}&step=${mentionData?.step}&redirectFromComments=true&redirectCommentData=${redirectCommentData}`
      );
    }

    // changing drawer mode to comments drawer
    updateMode("comments");
    // setting comment data for drawer
    updateCommentData(commentData);
  };

  const renderMentionItemComms = (mention: UserMentionComms) => {
    const id = mention.kryptonId?.toString();
    if (id && !mentionRefsMap.current[id]) {
      mentionRefsMap.current[id] = React.createRef<MentionItemHandle>();
    }

    return (
      <MentionItemComms
        ref={id ? mentionRefsMap.current[id] : undefined}
        mention={mention}
      />
    );
  };

  if (isCommsNewEndpointEnabled) {
    return (
      <Stack direction="vertical">
        {listItemsComms && listItemsComms.length > 0 ? (
          <ListWrapper
            options={listItemsComms}
            aria-label="mentions list box"
            getKey={getKeyComms}
            getLabel={getLabelComms}
            onClick={mentionItem => handleOnMentionClickComms(mentionItem)}
            render={renderMentionItemComms}
            loadMore={fetchNextPageComms}
            hasMore={!!hasNextPageComms}
          />
        ) : (
          <LoadingAndEmptyStatesWrapper
            primaryMessage={!isFetchingComms ? allSetMessage : ""}
            secondaryMessage={
              !isFetchingComms
                ? noNotificationsMessage
                : loadingNotificationsMessage
            }
            secondaryIcon={isFetchingComms ? <Spinner3 size={12} /> : undefined}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="vertical">
      {listItems && listItems.length > 0 ? (
        <ListWrapper
          options={listItems}
          aria-label="mentions list box"
          getKey={getKey}
          getLabel={getLabel}
          onClick={mentionItem => handleOnMentionClick(mentionItem)}
          render={renderMentionItem}
          loadMore={fetchNextPage}
          hasMore={hasNextPage || false}
        />
      ) : (
        <LoadingAndEmptyStatesWrapper
          primaryMessage={!isFetching ? allSetMessage : ""}
          secondaryMessage={
            !isFetching ? noNotificationsMessage : loadingNotificationsMessage
          }
          secondaryIcon={isFetching ? <Spinner3 size={12} /> : undefined}
        />
      )}
    </Stack>
  );
};

export { MentionsContainer };
