import type { FC } from "react";
import { useRef, useEffect } from "react";
import type { CellProps } from "../types";
import {
  formatCellValue,
  getBackgroundColor,
  isStatsColumn
} from "../utils/cellUtils";

export const Cell: FC<CellProps> = ({
  activeColumnIdentifier,
  cellBaseColors,
  cellValue,
  cellFiltersData,
  columnHeaderKey,
  dataCellFormatter,
  onClick,
  rowRecord,
  rowIndex,
  columnIndex,
  ratio,
  style
}) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const isStatisticsColumn = isStatsColumn(columnHeaderKey);

  // Parse active cell identifier (format: "rowIndex---columnKey")
  const [activeRowIndex, activeColumnKey] =
    `${activeColumnIdentifier}`?.split("---") ?? [];
  const isActiveCell =
    Number(activeRowIndex) === rowIndex && activeColumnKey === columnHeaderKey;

  const backgroundColor = getBackgroundColor(
    ratio,
    isStatisticsColumn,
    cellBaseColors
  );

  const formatedCellValue = formatCellValue(
    cellValue,
    columnHeaderKey,
    rowRecord,
    dataCellFormatter
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(
      {
        columnIndex,
        rowIndex,
        columnHeaderKey,
        cellValue,
        cellFiltersData,
        formatedCellValue,
        rowRecord
      },
      event
    );
  };

  // Handle active cell highlighting
  useEffect(() => {
    const parentElement = cellRef.current?.parentElement;
    if (!parentElement) {
      return;
    }

    parentElement.style.backgroundColor = isActiveCell
      ? "var(--neutral-50)"
      : "var(--neutral-0)";
  }, [isActiveCell]);

  return (
    <div
      ref={cellRef}
      className="heat-map-cell-div"
      style={{
        color: `rgba(0, 0, 0, 0.8)`,
        cursor: onClick ? "pointer" : "initial",
        background: backgroundColor,
        ...style?.(cellValue, columnHeaderKey, rowRecord, rowIndex)
      }}
      onClick={handleClick}
    >
      {formatedCellValue}
    </div>
  );
};
