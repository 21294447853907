export const SEARCH_CATEGORIES = {
  WORKFLOWS: "workflows",
  FILES: "files"
} as const;

export const DEFAULT_SEARCH_CATEGORY = [
  SEARCH_CATEGORIES.WORKFLOWS,
  SEARCH_CATEGORIES.FILES
];

export const CATEGORIES_WITH_ADVANCED_FILTERS = [SEARCH_CATEGORIES.WORKFLOWS];

export const SEARCH_QUERY_STALE_TIME = 10 * 60 * 1000; // 10 minutes
export const MIN_CHAR_LENGTH_TO_SEARCH = 0;
export const SEARCH_DEBOUNCE_TIME = 600; // milliseconds
export const SEARCH_LISTBOX_ID = "search-listbox";
export const RECENT_SEARCHES_LISTBOX_ID = "recent-searches";
export const SEARCH_FILTERS_ID = "search-filters";
