import React from "react";
import { WorkflowLevelPermissionType } from "@certa/queries/types/permissions.types";
import { Chokidar } from "../Chokidar";
import { useShouldCheckWFStatusPermission } from "../hooks/useShouldCheckWFStatusPermission";
import { PermissionTypes } from "@certa/types";
import { Chowkidaar } from "@certa/common/utils/permissions/Chowkidar";

type WizardStatusChokidarProps = {
  processId?: number;
  children: React.ReactChild;
};

// Delete this component once the useShouldCheckWFStatusPermission toggle is deprecated
export const ChokidarWFStatusPermissionBasedOnToggle = (
  props: WizardStatusChokidarProps
) => {
  const { children, processId } = props;

  const shouldCheckWFStatusPermission = useShouldCheckWFStatusPermission();

  if (shouldCheckWFStatusPermission) {
    return (
      <Chowkidaar check={PermissionTypes.CAN_VIEW_WORKFLOW_STATUS}>
        {children}
      </Chowkidaar>
    );
  } else {
    if (processId) {
      return (
        <Chokidar
          checkFor={WorkflowLevelPermissionType.CAN_VIEW_WORKFLOW_PROFILE}
          processId={processId}
        >
          {children}
        </Chokidar>
      );
    }
    return (
      <Chowkidaar check={PermissionTypes.CAN_VIEW_WORKFLOW_PROFILE}>
        {children}
      </Chowkidaar>
    );
  }
};
