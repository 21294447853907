import { useState, useMemo, useCallback, useEffect } from "react";
import queryString from "query-string";
import type { Step, StepGroup } from "@certa/types";
import { useNavigate, useLocation } from "react-router";
import type { ActiveTaskPanel } from "../../types";
import { TaskLaneTaskType } from "../../types";
import type { StepGroupsData } from "@certa/queries/types/workflow.types";
import { Godaam } from "@certa/common/utils/Godaam";

/**
 * @param stepGroups
 * @param selectedStep
 *
 * This hook is used to get the stepgroup id from the current selected step.
 * The selected step is fetched from url params and then compared with the values of
 * all the step ids in the stepgroup api response
 */
export const useGetTaskLane = (
  taskLanes: StepGroup[] | undefined = [],
  selectedTask: number | null | undefined
) => {
  const selectedGroupData = useMemo(() => {
    return taskLanes.find(group =>
      group.steps.find(step => step.id === selectedTask)
    );
  }, [selectedTask, taskLanes]);

  return {
    selectedGroupId: selectedGroupData?.id || null,
    selectedGroupTag: selectedGroupData?.definitionTag,
    selectedGroupName: selectedGroupData?.name || "",
    selectedTaskLaneTasks: selectedGroupData?.steps || [],
    selectedGroupAccessibleBy: selectedGroupData?.accessibleBy || []
  };
};

/**
 * @param taskLanesData
 *
 * This hook is used to handle my task and all task visiblity conditions
 * Case 1:
 *  - When the url does not contain group and step id i.e. no step is selected then in that case
 *    show both my task and all tasks
 * Case 2:
 *  - When there are no my task in that case my task panel is closed/hidden and all task tab is opened by default
 * Case 3:
 *  - When the current selected step is present in my tasks then in that case only my task tab will be open/active
 *    and all task tab will be closed/inactive
 */
export const useGetActiveTaskPanel = (
  // TODO: To remove this props as part of the default step behaviour fix
  taskLanesData: StepGroupsData | undefined,
  selectedGroup: number | null,
  selectedStep: number | null | undefined,
  persistActiveTaskPanelInURL?: boolean
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTaskPanel, setActiveTaskPanel] = useState(
    queryString.parse(location.search)?.activeTab as string
  );

  useEffect(() => {
    setActiveTaskPanel(queryString.parse(location.search)?.activeTab as string);
  }, [location.search]);

  const updateActiveTaskPanel = (panelName: ActiveTaskPanel) => {
    if (persistActiveTaskPanelInURL) {
      const searchParams = queryString.parse(location.search);
      searchParams["activeTab"] = panelName;
      navigate(`?${queryString.stringify(searchParams)}`, {
        replace: true
      });
    }
    setActiveTaskPanel(panelName);
  };

  return {
    activeTaskPanel,
    onPanelClick: updateActiveTaskPanel
  };
};

export const useGetTasklaneStepProgressCount = (
  steps: Step[],
  shouldCalcPercentageOfAllSteps: boolean = true
) => {
  const tasklaneProgressFields = useMemo(() => {
    const progressContributingSteps = steps.filter(step => {
      if (shouldCalcPercentageOfAllSteps) {
        return step.stepType === TaskLaneTaskType.DOABLE;
      } else {
        return step.stepType === TaskLaneTaskType.DOABLE && !step.isHidden;
      }
    });

    const completedCount =
      progressContributingSteps?.filter(step => step.isCompleted)?.length ?? 0;

    const notCompletedCount =
      progressContributingSteps?.filter(step => !step.isCompleted)?.length ?? 0;

    const percent = Math.round(
      (completedCount / progressContributingSteps.length) * 100
    );

    const isTasklaneProgressVisible = progressContributingSteps.length > 0;

    return {
      completedCount,
      notCompletedCount,
      percent,
      isTasklaneProgressVisible
    };
  }, [steps, shouldCalcPercentageOfAllSteps]);

  return tasklaneProgressFields;
};

export const useHistoryClone = () => {
  const historyClone = JSON.parse(Godaam.historyClone || "[]");
  const navigate = useNavigate();
  const location = useLocation();

  // const permissions = useSelector(
  //   (state: Record<string, any>) => state.permissions
  // );
  // const canGoToDashboard = !!checkPermission({
  //   permissionsAllowed: permissions.permissions,
  //   permissionName: PERMISSION_TYPES.CAN_VIEW_DASHBOARD
  // });

  const currentStep = new URLSearchParams(location.search).get("step");
  const currentPath = location.pathname;

  const goBackFromWizardStep = useCallback(() => {
    // User is not on the wizard step,
    // which means the function is called from the wrong site

    if (!currentStep) {
      return null;
    }

    const previousStepEntry = [...historyClone]
      .reverse()
      .find(entry => entry.extra.step !== currentStep);

    if (!previousStepEntry) {
      // Router will redirect to the root route when the user goes to `/`
      return navigate("/", { replace: true });
    } else {
      navigate(
        previousStepEntry.location.pathname.replace("/wizard/", "") +
          previousStepEntry.location.search,
        {
          replace: true
        }
      );
    }
  }, [currentStep, navigate, historyClone]);

  const goBackFromWFStep = useCallback(() => {
    if (!currentPath) {
      return null;
    }
    const previousRouteIndex = [...historyClone]
      .reverse()
      .findIndex(route => route.pathname !== currentPath);

    if (previousRouteIndex > 0) {
      navigate(-1 * previousRouteIndex);
    } else {
      return navigate("/", { replace: true });
    }
  }, [currentPath, navigate, historyClone]);

  return {
    goBackFromWizardStep,
    goBackFromWFStep
  };
};
