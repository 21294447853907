import { z } from "zod";

const BaseSearchItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().optional()
});

const WorkflowSearchItemSchema = BaseSearchItemSchema.extend({
  type: z.literal("workflow"),
  logo: z.string().url().optional()
});

const FileSearchItemSchema = BaseSearchItemSchema.extend({
  type: z.literal("file"),
  workflowId: z.number(),
  stepId: z.number()
});

const SearchItemSchema = z.discriminatedUnion("type", [
  WorkflowSearchItemSchema,
  FileSearchItemSchema
]);

export type SearchItem = z.infer<typeof SearchItemSchema>;

export const RecentSearchesSchema = z.record(z.array(SearchItemSchema));
