//----------------------------------------------------------------------------
// THIS IS A GENERATED REACT ICON COMPONENT. DO NOT EDIT MANUALLY AT ANY COST!
//----------------------------------------------------------------------------;

import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const DelayedClock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="DelayedClock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3334 3.01118C10.7697 2.63454 10.1495 2.35822 9.5 2.19052C9.0126 2.06468 8.50875 2 8 2V3.42969C8.90392 3.42969 9.78754 3.69774 10.5391 4.19993C11.2907 4.70212 11.8765 5.41591 12.2224 6.25102C12.5683 7.08613 12.6588 8.00507 12.4825 8.89162C12.3061 9.77818 11.8709 10.5925 11.2317 11.2317C10.5925 11.8709 9.77817 12.3061 8.89162 12.4825C8.00507 12.6588 7.08613 12.5683 6.25102 12.2224C5.4159 11.8765 4.70212 11.2907 4.19993 10.5391C3.69774 9.78754 3.42969 8.90392 3.42969 8H2C2 8.50875 2.06468 9.0126 2.19052 9.5C2.35822 10.1495 2.63454 10.7697 3.01118 11.3334C3.67047 12.3201 4.60754 13.0892 5.7039 13.5433C6.80025 13.9974 8.00665 14.1162 9.17054 13.8847C10.3344 13.6532 11.4035 13.0818 12.2426 12.2426C13.0818 11.4035 13.6532 10.3344 13.8847 9.17054C14.1162 8.00666 13.9974 6.80026 13.5433 5.7039C13.0891 4.60754 12.3201 3.67047 11.3334 3.01118ZM8.19338 8.25493L9.92007 9.01299L9.92008 9.01303C10.2181 9.13727 10.3549 9.48525 10.2054 9.79536C10.1065 10.0065 9.88272 10.1433 9.65899 10.1433C9.58475 10.1433 9.49787 10.1312 9.42313 10.0938L7.34846 9.18678C7.13735 9.08778 7.00049 8.87618 7.00049 8.64032L7 5.59645C7 5.2611 7.2611 5 7.59645 5C7.9318 5 8.1929 5.27323 8.1929 5.59645L8.19338 8.25493ZM5.3713 2.60649C4.93363 2.8198 4.52187 3.08641 4.14584 3.40158L5.06422 4.49731C5.64721 4.00867 6.34294 3.67328 7.08834 3.52154L6.80315 2.12058C6.32236 2.21845 5.85732 2.37453 5.41783 2.58406L5.3713 2.60649ZM2.10836 6.86482L2.1085 6.8641C2.20135 6.38257 2.35249 5.91615 2.5573 5.47471L2.57924 5.42796C2.78796 4.98808 3.05024 4.57354 3.36145 4.19423L4.46674 5.10108L4.46621 5.10172C3.984 5.68966 3.6561 6.38864 3.51224 7.13531L2.10836 6.86482Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
